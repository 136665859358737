import { Platform, Pressable } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';

const rates = [1, 2, 3, 4, 5];

export default function RatingInput({
  rate,
  onChange,
}: {
  rate: number;
  onChange?: (value: number) => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentRow style={{ alignContent: 'space-between' }}>
      {rates.map((rateValue) => (
        <Pressable
          key={`rating-input-${rateValue}`}
          testID={`rating-input-${rateValue}`}
          onPress={() => onChange?.(rateValue)}
          ref={(el) => {
            if (Platform.OS !== 'web' || !el) return;
            (el as unknown as HTMLDivElement).classList.add(
              'rating-input-container'
            );
          }}
        >
          <div
            className={[
              'rating-icon-holder',
              rate === rateValue ? 'active' : '',
            ].join(' ')}
            style={{ height: 24, overflow: 'hidden' }}
          >
            <Icon
              name={
                // (hoveringRate > 0 && rateValue <= hoveringRate) ||
                // rateValue <= rate
                'starOutlined'
              }
              color={Colors[theme].accent}
              style={{
                width: 24,
                height: 24,
              }}
            />

            <Icon
              name={'star'}
              color={Colors[theme].accent}
              style={{
                width: 24,
                height: 24,
                top: 24,
              }}
            />
          </div>
        </Pressable>
      ))}
    </TransparentRow>
  );
}
