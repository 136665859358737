import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { PickTimePopupContent } from './PickTimesPopup';
import { TextBodySmall } from './StyledText';
import { TransparentView } from './Themed';
import { useCopyLink } from './booking_calendar/sharedParams';
import { DayTimeSlot } from '../contexts/checkout';
import ComponentAnimator from '../features/booking-calendar/components/ComponentAnimator';
import PickTimesHeader from '../features/booking-calendar/components/PickTimesHeader';

interface CalendarSessionsProviderProps {
  children: (showSelected: () => void) => React.ReactNode;
  showingPeriodSessions: boolean;
  onClosePeriodSessions: () => void;
  periodSessionProps: {
    dateString: string;
    chosenSlots: string[];
    slots?: Record<string, DayTimeSlot>;
  } | null;
  onSessionsSelect: (dayTimes: string[]) => void;
  selectedEntriesComponent: (closeSelected: () => void) => React.ReactNode;
  getSharedParam: () => string;
}

function CalendarSessionsProvider({
  children,
  showingPeriodSessions,
  onClosePeriodSessions,
  periodSessionProps,
  onSessionsSelect,
  selectedEntriesComponent,
  getSharedParam,
}: CalendarSessionsProviderProps) {
  const [showingSelected, setShowingSelected] = useState(false);

  const showSelected = () => setShowingSelected(true);
  const closeSelected = () => setShowingSelected(false);

  const rightComponent = showingPeriodSessions ? (
    <TransparentView>
      <PickTimesHeader onBack={onClosePeriodSessions} title="Pick Sessions" />
      {periodSessionProps ? (
        <PickTimePopupContent
          dateString={periodSessionProps.dateString}
          onAdd={onSessionsSelect}
          chosenSlots={periodSessionProps.chosenSlots}
          slots={periodSessionProps.slots || {}}
        />
      ) : null}
    </TransparentView>
  ) : showingSelected ? (
    <TransparentView style={{ height: '100%', flexDirection: 'column' }}>
      <PickTimesHeader title="Selected Date(s)" onBack={closeSelected} />
      {selectedEntriesComponent(closeSelected)}

      <ShareSelectionButton getSharedParam={getSharedParam} />
    </TransparentView>
  ) : (
    <TransparentView />
  );

  return (
    <ComponentAnimator
      left={
        <TransparentView style={{ flex: 1 }}>
          {children(showSelected)}
        </TransparentView>
      }
      right={rightComponent}
      visibleMode={showingPeriodSessions || showingSelected ? 'RIGHT' : 'LEFT'}
    />
  );
}

function ShareSelectionButton({
  getSharedParam,
}: {
  getSharedParam: () => string;
}) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const listener = (e: KeyboardEvent) => {
      if (e.key !== 'Shift') return;
      setVisible(true);
    };
    const listenerUp = (e: KeyboardEvent) => {
      if (e.key !== 'Shift') return;
      setVisible(false);
    };

    window.addEventListener('keydown', listener);
    window.addEventListener('keyup', listenerUp);
    return () => {
      window.removeEventListener('keydown', listener);
      window.removeEventListener('keyup', listenerUp);
    };
  }, []);

  const copyLink = useCopyLink();
  const getShareUrl = () =>
    `${window.location.href}?s=${encodeURIComponent(getSharedParam())}`;

  if (!visible) return null;
  return (
    <TextBodySmall
      style={{
        fontSize: 10,
        color: '#aaa',
        position: 'absolute',
        textDecorationColor: '#ddd',
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',
        bottom: 0,
        right: 0,
        userSelect: 'none',
      }}
      onPress={() => copyLink(getShareUrl())}
    >
      Share selection
    </TextBodySmall>
  );
}

export default CalendarSessionsProvider;
