import { getAgeInMonths } from './date';
import { notNull } from './notnull';
import { BookingSlot } from '../api/search';
import { Kid } from '../components/KidsInput';
import {
  Capacity,
  DateSlots,
  DayTimeSlot,
  DayTimeSlots,
} from '../contexts/checkout';

export function transformSlots(
  slots: BookingSlot[],
  kids: Kid[],
  isLoggedIn: boolean
): DateSlots {
  let dateSlots = slots.reduce((acc, slot) => {
    const {
      id,
      dropoff,
      pickup,
      date,
      capacity,
      ageFrom,
      ageTo,
      dayTime,
      dependent,
    } = slot;
    const dayTimeObject = acc[date]?.[dayTime];
    const newDayTimeObject = {
      ...dayTimeObject,
      ...(!dayTimeObject && {
        dropoff,
        pickup,
        dependent,
      }),
      capacities: [
        ...(dayTimeObject?.capacities || []),
        { ids: [id], ageFrom, ageTo, capacity, taken: 0 },
      ],
    } as DayTimeSlot;

    acc[date] = {
      ...(acc[date] || {}),
      [dayTime]: newDayTimeObject,
    };
    return acc;
  }, {} as DateSlots);

  if (isLoggedIn) {
    dateSlots = filterByKids(dateSlots, kids);
  }
  const slotsWithFullDays = addFullDays(dateSlots);

  const filteredSlots = filterDependentSlots(slotsWithFullDays);

  return filteredSlots;
}

function filterByKids(slots: DateSlots, kids: Kid[]) {
  const chosenKids = kids.filter((kid) => kid.isChosen && kid.isEligible);

  if (!chosenKids.length) {
    return {};
  }
  const filteredSlots = Object.keys(slots).reduce((acc, dateString) => {
    const date = new Date(dateString);
    const ages = chosenKids.map((kid) => getAgeInMonths(kid.birthdate, date));
    const dateSlots = Object.keys(slots[dateString]!).reduce((acc, dayTime) => {
      let totalMatching = 0;
      const isEnoughSlots = slots[dateString]![dayTime]!.capacities.every(
        (capacity) => {
          // this approach fails if capacities are overlapping!
          const matchingKids = ages.filter(
            (age) => capacity.ageFrom <= age && age < capacity.ageTo
          );
          totalMatching += matchingKids.length;
          return matchingKids.length <= capacity.capacity;
        }
      );

      if (totalMatching === ages.length && isEnoughSlots) {
        acc[dayTime] = slots[dateString]![dayTime]!;
      }
      return acc;
    }, {} as DayTimeSlots);
    if (Object.keys(dateSlots).length) {
      acc[dateString] = dateSlots;
    }
    return acc;
  }, {} as DateSlots);
  return filteredSlots;
}

function mergeCapacities(morningCaps: Capacity[], afternoonCaps: Capacity[]) {
  const caps = morningCaps.map(({ ids, ageFrom, ageTo, capacity }) => {
    const afternoonCap = afternoonCaps.find((afternoonCap) => {
      return afternoonCap.ageFrom === ageFrom && afternoonCap.ageTo === ageTo;
    }) || { ids: [], capacity: Infinity };
    return {
      ageFrom,
      ageTo,
      capacity: Math.min(capacity, afternoonCap.capacity),
      ids: [...ids, ...afternoonCap.ids],
      taken: 0,
    };
  });
  return caps;
}

function addFullDays(dateSlots: DateSlots) {
  return Object.keys(dateSlots).reduce((acc, dateString) => {
    const morning = acc[dateString]!.morning;
    const afternoon = acc[dateString]!.afternoon;
    if (morning && afternoon) {
      acc[dateString]!['fullDay'] = {
        dropoff: morning.dropoff,
        pickup: afternoon.pickup,
        dependent: 'independent',
        capacities: mergeCapacities(morning.capacities, afternoon.capacities),
      };
    }
    return acc;
  }, dateSlots);
}

function filterDependentSlots(dateSlots: DateSlots) {
  const entries = Object.entries(dateSlots);

  const result = entries
    .map(([key, sessionsObject]) => {
      const filteredSessionObject = Object.entries(sessionsObject)
        .map(([sessionName, sessionInfo], idx, allSessions) => {
          if (sessionInfo.dependent === 'after') {
            const independentMatchingSession = allSessions.find(
              ([, s]) =>
                s.dependent === 'independent' &&
                s.dropoff === sessionInfo.pickup
            );

            if (!independentMatchingSession) {
              return null;
            }
          }

          if (sessionInfo.dependent === 'before') {
            const independentMatchingSession = allSessions.find(
              ([, s]) =>
                s.dependent === 'independent' &&
                s.pickup === sessionInfo.dropoff
            );

            if (!independentMatchingSession) {
              return null;
            }
          }

          return [sessionName, sessionInfo] as const;
        })
        .filter(notNull);
      return filteredSessionObject.length === 0
        ? null
        : ([key, Object.fromEntries(filteredSessionObject)] as const);
    })
    .filter(notNull);

  return Object.fromEntries(result);
}
