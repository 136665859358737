import { Loader } from '@googlemaps/js-api-loader';
import Constants from 'expo-constants';
import { useEffect } from 'react';

let sharedResolveMaps: Function;
export const MapLibraryState = {
  loaded: false,
  loaderPromise: new Promise((resolve) => {
    sharedResolveMaps = resolve;
  }),
};

let sharedResolveGeocoding: (library: google.maps.GeocodingLibrary) => void;
export const GeocodingLibraryState = {
  loaded: false,
  loaderPromise: new Promise<google.maps.GeocodingLibrary>((resolve) => {
    sharedResolveGeocoding = resolve;
  }),
};

export default function GoogleMapLoader() {
  useEffect(() => {
    const loader = new Loader({
      apiKey: Constants?.expoConfig?.extra?.MAPS_KEY || '',
      libraries: ['places', 'geocoding'],
    });
    const fn = () => {};
    loader.importLibrary('maps').then(() => {
      (window.initAutocomplete || fn)();
      MapLibraryState.loaded = true;
      sharedResolveMaps();
    });

    loader.importLibrary('geocoding').then((library) => {
      GeocodingLibraryState.loaded = true;
      sharedResolveGeocoding(library);
    });
  }, []);

  return null;
}
