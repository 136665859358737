import { BookingSlot } from '../../api/search';
import { ChosenSlotRowProps } from '../../components/booking_calendar/ChosenSlotRow';
import { ChosenSlots, DateSlots, DayTime } from '../../contexts/checkout';

export function prepareChosenSlotsRows(
  chosenSlots: ChosenSlots,
  dateSlots: DateSlots,
  slots: BookingSlot[]
): (undefined | ChosenSlotRowProps)[] {
  return Object.keys(chosenSlots)
    .sort()
    .map((dateString) => {
      return chosenSlots[dateString]!.sort((keyA, keyB) => {
        if (keyA === 'fullDay') return -1;
        else if (keyB === 'fullDay') return 1;
        else {
          const slotA = dateSlots[dateString]?.[keyA];
          const slotB = dateSlots[dateString]?.[keyB];
          if (!slotA || !slotB) {
            return -1;
          }
          return new Date(slotA.dropoff) > new Date(slotB.dropoff) ? 1 : -1;
        }
      }).map((dayTime: DayTime) => {
        const availableSlot = dateSlots[dateString]?.[dayTime];

        const commonProps = {
          dateString,
          dayTime,
        } as const;

        if (availableSlot) {
          return {
            ...commonProps,
            slot: availableSlot,
          };
        }

        if (dayTime === 'fullDay') {
          const morning = slots.find(({ date, dayTime: slotDayTime }) => {
            return date === dateString && slotDayTime === 'morning';
          });
          const afternoon = slots.find(({ date, dayTime: slotDayTime }) => {
            return date === dateString && slotDayTime === 'afternoon';
          });
          if (!morning || !afternoon) {
            return undefined;
          }
          return {
            ...commonProps,
            slot: {
              dropoff: morning.dropoff,
              pickup: afternoon.pickup,
            },
            disabled: true,
          };
        }

        const slot = slots.find(({ date, dayTime: slotDayTime }) => {
          return date === dateString && dayTime === slotDayTime;
        });
        if (slot) {
          return {
            ...commonProps,
            slot,
            disabled: true,
          };
        }

        return undefined;
      });
    })
    .flat();
}
