import { useState } from 'react';
import {
  ImageBackground,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';

import Colors from '../../constants/Colors';
import { useAuthenticate } from '../../contexts/authFlow';
import useColorScheme from '../../hooks/useColorScheme';
import { useMinWidth } from '../../hooks/useResponsive';
import { getImageUrl } from '../../utils/common';
import { PrimaryButton } from '../Button';
import { Icon } from '../Icon';
import { InnerContainer } from '../Layout';
import { TransparentRow } from '../Row';
import { TextBody, TextH2 } from '../StyledText';
import { StyleProps, TransparentView, View } from '../Themed';

const useVideoHeight = () => {
  const { width } = useWindowDimensions();
  return (Math.min(width, 860) - 40) / (820 / 459);
};

type PlayButtonProps = StyleProps & {
  onPress: () => void;
};

export function PlayButton({ onPress, style }: PlayButtonProps) {
  const [hovering, setHovering] = useState(false);
  const theme = useColorScheme();
  return (
    <Pressable
      style={styles.playButton}
      onPress={onPress}
      onHoverIn={() => setHovering(true)}
      onHoverOut={() => setHovering(false)}
    >
      <Icon
        name="play"
        color={hovering ? Colors[theme].accent : Colors[theme].accentTertiary}
        style={{
          width: 100,
          height: 100,
        }}
      />
    </Pressable>
  );
}

export default function GetStarted({
  headerText,
  contentText,
  buttonContent,
}: {
  headerText?: string;
  contentText?: string;
  buttonContent?: React.ReactNode;
}) {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const authenticate = useAuthenticate();
  const isLargeDevice = useMinWidth(1200);
  return (
    <View>
      <InnerContainer>
        <TransparentRow
          style={[
            styles.getStartedMobile,
            isLargeDevice && styles.getStartedLarge,
          ]}
        >
          <TransparentView
            style={[
              styles.innerTextMobile,
              isLargeDevice && styles.innerTextLarge,
            ]}
          >
            <TextH2 style={{ marginBottom: 10 }}>
              {headerText ? headerText : <>Get started</>}
            </TextH2>
            <TextBody>
              {contentText ? (
                contentText
              ) : (
                <>
                  Create a free account and start searching for child care near
                  you.
                </>
              )}
            </TextBody>
            {buttonContent ? (
              buttonContent
            ) : (
              <PrimaryButton
                title="Start Free Account"
                style={{ marginTop: 40 }}
                onPress={() => authenticate()}
              />
            )}
          </TransparentView>
          <TransparentView
            style={{ width: '100%', maxWidth: 820, height: useVideoHeight() }}
          >
            {isVideoVisible ? (
              <iframe
                src="https://fast.wistia.net/embed/iframe/vrahb9nxyz?videoFoam=true&autoPlay=true"
                title="How it Works Video"
                allow="autoplay; fullscreen"
                frameBorder="0"
                style={{
                  height: '100%',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                }}
              />
            ) : (
              <ImageBackground
                source={
                  getImageUrl('bumo-video-thumb.webp') as ImageSourcePropType
                }
                accessibilityLabel="Get Started Preview"
                style={{
                  maxWidth: 820,
                  width: '100%',
                  height: '100%',
                }}
              >
                <PlayButton onPress={() => setVideoVisible(true)} />
              </ImageBackground>
            )}
          </TransparentView>
        </TransparentRow>
      </InnerContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  whiteBackground: {
    backgroundColor: '#fff',
  },
  getStartedMobile: {
    paddingVertical: 62,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  getStartedLarge: {
    paddingVertical: 75,
    flexDirection: 'row',
  },
  innerTextMobile: {
    flex: 1,
    alignItems: 'center',
    paddingBottom: 24,
  },
  innerTextLarge: {
    alignItems: 'flex-start',
    paddingRight: 50,
  },
  playButton: {
    width: 100,
    height: 100,
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: [
      {
        translateX: -50,
      },
      {
        translateY: -50,
      },
    ],
  },
});
