import { API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultAuthedRequest,
  defaultAuthedGetRequest,
} from './headers';
import { BookingMode, Location } from './search';
import { buildURLQuery } from './utils';
import { Booking, BookingResponseObject, parseBooking } from '../utils/api';

const BOOKINGS_V1_URL = `${API_V1_URL}bookings/`;

export async function getUpcomingBookings() {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${BOOKINGS_V1_URL}upcoming`, request);
  if (response.status === 401) {
    return [] as Booking[];
  }
  const upcoming = await response.json();
  return upcoming.map(parseBooking) as Booking[];
}

export async function getBookings() {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${BOOKINGS_V1_URL}`, request);
  if (response.status === 401) {
    return { upcoming: [], past: [] };
  } else {
    const { upcoming, past } = (await response.json()) as {
      upcoming: BookingResponseObject[];
      past: BookingResponseObject[];
    };
    return {
      upcoming: upcoming.map(parseBooking),
      past: past.map(parseBooking),
    };
  }
}

export async function getBooking(id: number): Promise<
  | {
      ok: false;
      status: 401 | 404;
    }
  | {
      ok: true;
      booking: Booking;
    }
> {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${BOOKINGS_V1_URL}${id}`, request);
  if (response.status === 401) {
    return { ok: false, status: 401 };
  } else if (response.status === 404) {
    return { ok: false, status: 404 };
  } else {
    return { ok: true, booking: parseBooking(await response.json()) };
  }
}

export type CartPriceInfo = {
  days: Record<
    string,
    {
      total: string;
      children: Record<
        number,
        {
          category: null | string;
          days: number;
          dropoff: string;
          pickup: string;
          hourly_rate: string;
          length: number;
          total: string;
        }[]
      >;
      discounts: (
        | {
            type: 'sibling';
            rate: string;
            hours: number;
            total: string;
          }
        | {
            type: 'weekly';
            base: string;
            rate: number;
            total: string;
          }
      )[];
    }
  >;
  total: string;
};
export async function getCartPrice(
  bookingMode: BookingMode,
  slotIds: number[],
  childIds: number[]
): Promise<
  | {
      ok: true;
      body: CartPriceInfo;
    }
  | {
      ok: false;
      status: 401;
      body?: any;
    }
> {
  const response = await fetch(
    `${BOOKINGS_V1_URL}price?${buildURLQuery({
      ...(slotIds ? { slot_ids: slotIds } : null),
      ...(childIds ? { child_ids: childIds } : null),
      booking_mode: bookingMode,
    })}`,
    { ...defaultAuthedGetRequest, ...getAuthedRequestHeaders() }
  );
  if (response.status === 401) {
    return { ok: false as const, status: 401 };
  }
  if (!response.ok) {
    return { ok: false as const, status: 401, body: await response.json() };
  }
  return { ok: true as const, body: await response.json() };
}

type CancelBookingResponse =
  | { status: 401 }
  | { status: 422 }
  | { status: 500 }
  | {
      status: 200;
      refundedAmount: number;
      careCreditBalance: number;
    };

export async function cancelBooking(
  id: string
): Promise<CancelBookingResponse> {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
  };
  const response = await fetch(`${BOOKINGS_V1_URL}${id}/cancel`, request);
  if (response.status === 401) {
    return { status: 401 };
  } else if (response.status === 422) {
    return { status: 422 };
  } else if (response.status === 500) {
    return { status: 500 };
  } else {
    const { refunded_amount, care_credit_balance } = await response.json();
    return {
      status: 200,
      refundedAmount: parseFloat(refunded_amount),
      careCreditBalance: parseFloat(care_credit_balance),
    };
  }
}

export async function failBooking(sessionId: string) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
  };
  const response = await fetch(
    `${BOOKINGS_V1_URL}fail?session_id=${sessionId}`,
    request
  );
  if (response.status === 401) {
    return { status: 401 };
  } else if (response.status === 422) {
    return { status: 422 };
  } else {
    return response;
  }
}

interface PostBookingsPayload {
  slots: { slots: { ids: string[]; amount: number }[] }[];
  children: number[];
  useCareCredit: boolean;
  bookingMode: 'date_range' | 'weekly';
}

type PostBookingsResponse =
  | { url: string }
  | { checkoutId: string; careCreditBalance: number }
  | { code: string; id: number };

export async function postBookings(
  payload: PostBookingsPayload
): Promise<PostBookingsResponse> {
  const { useCareCredit, bookingMode, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      use_care_credit: useCareCredit,
      booking_mode: bookingMode,
      ...rest,
    }),
  };
  const response = await fetch(`${BOOKINGS_V1_URL}`, request);
  const { care_credit_balance, checkout_id, ...restResponse } =
    await response.json();
  return {
    careCreditBalance: parseFloat(care_credit_balance),
    checkoutId: checkout_id,
    ...restResponse,
  };
}

export interface Confirmation {
  id: number;
  amount: string;
  paymentType: 'BumoCredit' | 'Stripe';
  hours: string;
  location: Location;
  ordersNumber: number;
}

export async function getConfirmation(
  sessionId: string
): Promise<{ status: 401 } | { status: 400 } | { status: 422 } | Confirmation> {
  const request = {
    ...defaultAuthedRequest,
    method: 'GET',
  };
  const response = await fetch(
    `${BOOKINGS_V1_URL}confirmation?session_id=${sessionId}`,
    request
  );
  if (response.status === 401) {
    return { status: 401 } as const;
  } else if (response.status === 422) {
    return { status: 422 } as const;
  } else {
    return parseBookingConfirmation(await response.json());
  }
}

type ConfirmationResponse = {
  id: number;
  amount: string;
  payment_type: 'BumoCredit' | 'Stripe';
  hours: string;
  location: Location;
  orders_number: number;
};

export function parseBookingConfirmation(
  summary: ConfirmationResponse
): Confirmation {
  const { location, orders_number, payment_type, ...rest } = summary;
  return {
    location,
    paymentType: payment_type,
    ordersNumber: orders_number,
    ...rest,
  };
}
