import ChosenCalendarRow from './ChosenCalendarRow';
import { DayTime } from '../../contexts/checkout';
import { dateStringToNumeric } from '../../utils/date';
import { formatDateHoursRange } from '../../utils/locations';

export interface ChosenSlotRowProps {
  dateString: string;
  dayTime: DayTime;
  slot: {
    dropoff: string;
    pickup: string;
  };
  removeSlot?: (
    dateString: string,
    dayTime: string,
    compound?: boolean
  ) => void;
  disabled?: boolean;
  compound?: boolean;
}

export default function ChosenSlotRow({
  dateString,
  dayTime,
  slot,
  removeSlot,
  disabled,
  compound,
}: ChosenSlotRowProps) {
  return (
    <ChosenCalendarRow
      dayLabel={dateStringToNumeric(dateString, {
        month: 'short',
        day: 'numeric',
      })}
      hoursLabel={formatDateHoursRange(slot.dropoff, slot.pickup) || ''}
      onRemove={() => removeSlot?.(dateString, dayTime, compound)}
      disabled={disabled}
    />
  );
}
