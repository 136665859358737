import { StyleProp, TextStyle } from 'react-native';

import { TextButton } from '../components/Button';
import { Icon } from '../components/Icon';
import { TransparentRow } from '../components/Row';
import { TextBodySmall } from '../components/StyledText';
import TextWithLinks from '../components/TextWithLinks';
import { StaticTexts } from '../constants/StaticTexts';
import { navigateFromRef } from '../hooks/useAppNavigation';
import { useMaxWidth } from '../hooks/useResponsive';
import { formatPrice } from '../utils/locations';

export function getBumoCreditAppliedSnackbar(reward: number) {
  return (
    <TransparentRow style={{ width: '100%' }}>
      <ExploreIcon />

      <TextBodySmall>
        Yay! You applied {formatPrice(Number(reward))} in BumoCredit to{' '}
        <TextButton onPress={() => navigateFromRef('Profile')}>
          your account
        </TextButton>
        . 🎉 Start booking child care now!
      </TextBodySmall>
    </TransparentRow>
  );
}

export function getAccountCreatedBumoCreditAppliedSnackbar(reward: number) {
  return (
    <TransparentRow style={{ width: '100%' }}>
      <ExploreIcon />

      <TextBodySmall>
        Congrats! 🎉{' '}
        <TextButton onPress={() => navigateFromRef('Profile')}>
          Your account
        </TextButton>{' '}
        has been created and {formatPrice(Number(reward))} in BumoCredit has
        been added to it. Start booking child care now!
      </TextBodySmall>
    </TransparentRow>
  );
}

export function getBumoCreditAlreadyAppliedSnackbar() {
  return (
    <ExploreIconWrapper>
      <TextBodySmall>
        Yay! This BumoCredit has already been applied to your account. 🎉 Start
        booking child care now!
      </TextBodySmall>
    </ExploreIconWrapper>
  );
}

function ExploreIcon() {
  const isMobileDevice = useMaxWidth(768);
  return isMobileDevice ? null : (
    <Icon name="explore" style={{ marginRight: 10 }} />
  );
}

function ExploreIconWrapper({ children }: { children: React.ReactNode }) {
  return (
    <TransparentRow style={{ width: '100%' }}>
      <ExploreIcon />
      {children}
    </TransparentRow>
  );
}

export function getReferralAppliedSnackbar() {
  return (
    <ExploreIconWrapper>
      <TextBodySmall>
        Congrats! Your account has been created and{' '}
        <TextButton onPress={() => navigateFromRef('Profile')}>
          $25 in BumoCredit
        </TextButton>{' '}
        has been added to it! 🎉
      </TextBodySmall>
    </ExploreIconWrapper>
  );
}

export const getAccountCreatedSnackbar = () => (
  <TextBodySmall>Congrats! Your account has been created!</TextBodySmall>
);

export const getAlreadyHaveAccountSnackbar = () => (
  <ExploreIconWrapper>
    <TextBodySmall>
      Yay! 🎉 You already have an account with us. Book Child Care Today!
    </TextBodySmall>
  </ExploreIconWrapper>
);

export const getCancelErrorSnackbar = (
  textStyle: StyleProp<TextStyle>,
  onClick: () => void
) => (
  <TextWithLinks
    textStyle={textStyle}
    text={`We could not cancel this booking automatically. Please "contact support|0" to do so.`}
    links={[]}
    clickFn={onClick}
    textComponent={TextBodySmall}
    linkComponent={TextButton}
  />
);

export const getCancelSuccessSnackbar = (
  amount: number,
  navigate: (value: string) => void
) => {
  if (amount > 0) {
    return (
      <TextWithLinks
        text={`Your booking has been cancelled and ${formatPrice(
          amount
        )} has been added to your BumoCredit balance. View details "here|0".`}
        links={['Profile']}
        clickFn={navigate}
        textComponent={TextBodySmall}
        linkComponent={TextButton}
      />
    );
  } else {
    return (
      <TextWithLinks
        text={`Your booking has been cancelled. "Keep on booking!|0".`}
        links={['Explore']}
        clickFn={navigate}
        textComponent={TextBodySmall}
        linkComponent={TextButton}
      />
    );
  }
};

export const getFormsReuseConsentUpdatedSnackbar = (consent: boolean) => (
  <ExploreIconWrapper>
    <TextBodySmall>
      {consent
        ? StaticTexts.formsReuseConsent.enabledSnackbar
        : StaticTexts.formsReuseConsent.disabledSnackbar}
    </TextBodySmall>
  </ExploreIconWrapper>
);
