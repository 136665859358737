import { useState } from 'react';
import { Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import {
  FloatingLabelInput,
  FloatingLabelProps,
} from 'react-native-floating-label-input';

import { TextFinePrint } from './StyledText';
import { TextInputProps, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';

export type FormInputProps = TextInputProps &
  Omit<FloatingLabelProps, 'label'> & {
    error?: string;
    message?: string;
    inputAction?: React.ReactNode;
    disabled?: boolean;
    inputStyles?: TextStyle & ViewStyle;
    ammendment?: React.ReactNode;
  };

export function FormInput({
  style,
  error,
  message,
  placeholder,
  onFocus,
  onBlur,
  onEndEditing,
  testID,
  inputAction,
  inputStyles,
  ammendment = null,
  ...otherProps
}: FormInputProps) {
  const theme = useColorScheme();
  const [isFocused, setIsFocused] = useState(false);
  const isMobileDevice = useMaxWidth(700);

  return (
    <View
      style={[
        styles.container,
        (!!error || !!message) && { marginBottom: 0 },
        style,
      ]}
      testID={testID ? `${testID}-container` : undefined}
    >
      <FloatingLabelInput
        testID={testID}
        {...otherProps}
        accessibilityLabel={placeholder || ''}
        label={placeholder || ''}
        isFocused={isFocused}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          const f = onBlur || onEndEditing;
          f?.(e);
        }}
        customLabelStyles={{
          colorBlurred: Colors[theme].textSecondary,
          colorFocused: Colors[theme].textSecondary,
          fontSizeFocused: 12,
          fontSizeBlurred: 15,
        }}
        labelStyles={{
          fontFamily: 'Mont',
        }}
        inputStyles={{
          color: Colors[theme].text,
          marginTop: 5,
          fontFamily: 'Mont',
          ...Platform.select({
            web: {
              outlineColor: 'transparent',
            },
          }),
          ...((isMobileDevice || Platform.OS !== 'web') && {
            fontSize: 16,
          }),
        }}
        containerStyles={{
          ...styles.inputContainer,
          borderWidth: 2,
          borderColor: Colors[theme].lines,
          backgroundColor: Colors[theme].background,
          ...inputStyles,
        }}
      />
      {inputAction ? (
        <View style={styles.inputAction}>{inputAction}</View>
      ) : null}
      {ammendment}
      <TextFinePrint
        testID="message"
        style={[
          (!!error || !!message) && styles.error,
          !!message && { color: Colors[theme].textSecondary },
          !!error && { color: Colors[theme].error },
        ]}
      >
        {error || message}
      </TextFinePrint>
    </View>
  );
}

const errorMarginBottom = 5;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: '100%',
          maxWidth: 500,
        }),
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
        // fine print line height + errorMarginBottom
        marginBottom: 15 + errorMarginBottom,
      },
      native: {
        // RN adds 1pt to empty text height so errorMarginBottom - 1 here
        marginBottom: errorMarginBottom - 1,
        alignSelf: 'stretch',
      },
    }),
  },
  inputContainer: {
    height: 55,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 11,
    paddingVertical: 10,
  },
  error: {
    marginLeft: 5,
    marginBottom: errorMarginBottom,
    alignSelf: 'stretch',
  },
  inputAction: {
    position: 'absolute',
    right: 27,
    top: 0,
    height: 55,
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});
