import Defaults from './Defaults';

export default {
  about: 'https://book.bumo.com/pages/about',
  accessibility: 'https://bumo.com/accessibility',
  blackfriday: 'https://bumo.com/blackfriday/',
  blog: 'https://bumo.com/blog',
  bumoCreditTerms: 'https://bumo.com/terms#bumo-credit',
  campaigns: {
    laborDay: 'https://bumo.com/sale',
  },
  collegebound: 'https://collegebound.bumo.com/',
  events: 'https://bumo.com/events',
  safety: 'https://bumo.com/safety-trust',
  health: 'https://bumo.com/safety-trust/#health-safety',
  careers: 'https://bumo.com/careers/',
  faqs: 'https://bumo.com/faqs',
  toc: 'https://bumo.com/terms',
  promo: 'https://bumo.com/promo',
  privacy: 'https://bumo.com/privacy-policy',
  providers: 'https://provider.bumo.com/',
  instagram: 'https://www.instagram.com/bumoparent/',
  facebook: 'https://www.facebook.com/bumoparent/',
  linkedin: 'https://www.linkedin.com/company/bumocare/',
  supportMail: 'mailto:support@bumo.com',
  telephone: `tel:${Defaults.telephone}`,
  sms: `sms:${Defaults.telephone}`,
  forms: {
    physician: 'https://form.jotform.com/222226503742044',
    immunization: 'https://bumo.com/immunizations',
    getAll: 'https://bumo.com/licensing-form/',
    claim: 'https://bumo.com/claim',
    customCare: 'https://bumobrain.wufoo.com/forms/custom-care-request',
    fixedCare: 'https://bumobrain.wufoo.com/forms/qryfq0q0m8n458/',
  },
  momsFirst: 'https://momsfirst.us/childcare-report/',
  businessForm: 'https://form.jotform.com/230507071397051',
  mobileApps: {
    googleplay: 'https://play.google.com/store/apps/details?id=com.bumo.bumo',
    appStore:
      'https://apps.apple.com/us/app/bumo-book-trusted-child-care/id6737350222',
  },
};
