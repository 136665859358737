import { Link } from '@react-navigation/native';
import { useState, useEffect, useRef, useMemo } from 'react';
import {
  StyleSheet,
  ViewProps,
  Platform,
  ScrollView,
  Pressable,
  ImageStyle,
  Modal,
  TouchableOpacity,
} from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { PrimaryButton } from './Button';
import {
  ShouldIgnoreCampaignHeaderFn,
  useHelloMessageBarHeight,
} from './HelloMessageBar';
import { Icon } from './Icon';
import { LogoCare } from './Logo';
import ModalCloseButton from './ModalCloseButton';
import Row, { TransparentRow } from './Row';
import { TextBodySmall, TextCta, TextH2 } from './StyledText';
import {
  View,
  SecondaryView,
  Divider,
  StyleProps,
  TransparentView,
} from './Themed';
import HeaderContainer, { useHeaderHeight } from './layouts/HeaderContainer';
import { SearchIcon } from './search/SearchIcon';
import { logOut } from '../api/parents';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import Links from '../constants/Links';
import { useAuthenticate } from '../contexts/authFlow';
import { useAuthState, useAuthDispatch } from '../contexts/authentication';
import { useSnackbarDispatch } from '../contexts/snackbar';
import useAppNavigation from '../hooks/useAppNavigation';
import { useOpenAvochatoAction } from '../hooks/useAvochato';
import useBlockBodyScroll from '../hooks/useBlockBodyScroll';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import ChildrenProps from '../types/ChildrenProps';
import { handleLink } from '../utils/common';

type HeaderProps = {
  style?: ViewProps['style'];
  shadow?: boolean;
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  hideSearchIcon?: boolean;
  customHelloBar?: React.ReactNode;
  ignoreCampaignHeader?: ShouldIgnoreCampaignHeaderFn;
};

export function MenuElement({ style }: { style?: ImageStyle }) {
  const { isLoggedIn } = useAuthState();
  const isMobile = useMaxWidth(500);

  const Menu = isLoggedIn ? LoggedInDesktopMenu : LoggedOutDesktopMenu;

  return isMobile ? (
    <ModalMenu loggedIn={isLoggedIn} style={style} />
  ) : (
    <Menu />
  );
}

function ModalMenu({
  loggedIn,
  style,
}: {
  loggedIn: boolean;
  style?: ImageStyle;
}) {
  const [isModalVisible, setModalVisible] = useState(false);
  const closeMenu = () => setModalVisible(false);

  useBlockBodyScroll(isModalVisible);

  const MenuItems = loggedIn ? LoggedInMenuItems : LoggedOutMenuItems;
  return (
    <>
      <Pressable onPress={() => setModalVisible(true)}>
        <Icon name="hamburger" style={style} />
      </Pressable>
      {isModalVisible ? (
        <Modal style={{ zIndex: 1000000 }}>
          <TransparentRow
            style={{
              justifyContent: 'space-between',
              paddingHorizontal: 20,
              paddingTop: 12,
              paddingBottom: 32,
            }}
          >
            <LogoCare />
            <ModalCloseButton onPress={() => setModalVisible(false)} />
          </TransparentRow>
          <View style={styles.modalMenuItems}>
            <MenuItems closeMenu={closeMenu} big />
          </View>
        </Modal>
      ) : null}
    </>
  );
}

export function AvochatoIcon({ style }: { style?: ViewProps['style'] }) {
  const theme = useColorScheme();
  const openAvochato = useOpenAvochatoAction();

  return (
    <Pressable
      onPress={() => openAvochato()}
      style={style}
      accessibilityLabel="Open support chat"
      accessibilityRole="button"
    >
      <Icon name="call" color={Colors[theme].accentTertiary} />
    </Pressable>
  );
}

export function Header({
  center,
  shadow,
  hideSearchIcon = false,
  customHelloBar,
  ignoreCampaignHeader,
}: HeaderProps) {
  return (
    <HeaderContainer
      shadow={!!shadow}
      customHelloBar={customHelloBar}
      ignoreCampaignHeader={ignoreCampaignHeader}
    >
      <InnerContainer>
        <Row style={[styles.header, styles.transparent]}>
          <TransparentView style={styles.flexNoGrow}>
            <LogoCare style={styles.flex as ImageStyle} />
          </TransparentView>
          {Layout.isLargeDevice && center && (
            <TransparentView style={styles.flex}>{center}</TransparentView>
          )}
          <TransparentView
            style={[
              styles.flex,
              styles.flexNoGrow,
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            ]}
          >
            <AvochatoIcon style={{ marginRight: 10 }} />
            {!hideSearchIcon ? (
              <SearchIcon style={{ marginRight: 10 }} />
            ) : null}
            <MenuElement />
          </TransparentView>
        </Row>
      </InnerContainer>
    </HeaderContainer>
  );
}

function HeaderContent({
  center,
  hideSearchIcon,
  style,
}: Pick<HeaderProps, 'center' | 'hideSearchIcon' | 'style'>) {
  const headerHeight = useHeaderHeight();
  return (
    <InnerContainer
      style={[
        {
          backgroundColor: '#fff',
          height: headerHeight,
          justifyContent: 'center',
        },
        style,
      ]}
    >
      <Row style={[styles.header, styles.transparent]}>
        <TransparentView style={styles.flexNoGrow}>
          <LogoCare style={styles.flex as ImageStyle} />
        </TransparentView>
        {Layout.isLargeDevice && center && (
          <TransparentView style={styles.flex}>{center}</TransparentView>
        )}
        <TransparentView
          style={[
            styles.flex,
            styles.flexNoGrow,
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            },
          ]}
        >
          <AvochatoIcon style={{ marginRight: 10 }} />
          {!hideSearchIcon ? <SearchIcon style={{ marginRight: 10 }} /> : null}
          <MenuElement />
        </TransparentView>
      </Row>
    </InnerContainer>
  );
}

export function ExpandableHeader({
  style,
  children,
  shadow,
  center,
  expanded,
  onBackgroundPress,
  customHelloBar,
  ignoreCampaignHeader,
}: {
  style?: ViewProps['style'];
  children: React.ReactNode;
  shadow?: boolean;
  expanded?: boolean;
  onBackgroundPress?: () => void;
  center?: React.ReactNode;
  customHelloBar?: React.ReactNode;
  ignoreCampaignHeader?: ShouldIgnoreCampaignHeaderFn;
}) {
  const theme = useColorScheme();
  return (
    <HeaderContainer
      shadow={!!shadow}
      additionalHeight={expanded ? 70 : 0}
      style={{ backgroundColor: Colors[theme].background }}
      customHelloBar={customHelloBar}
      ignoreCampaignHeader={ignoreCampaignHeader}
      secondaryChildren={
        expanded ? (
          <TouchableOpacity onPress={onBackgroundPress}>
            <View
              nativeID="background-opacity"
              //@ts-ignore
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1,
                width: '100%',
                height: '100vh',
              }}
            />
          </TouchableOpacity>
        ) : null
      }
    >
      <View>
        <HeaderContent center={center} style={{ zIndex: 101 }} />
      </View>
      <View
        style={[
          { zIndex: 101, height: expanded ? 70 : 0, justifyContent: 'center' },
        ]}
      >
        {children}
      </View>
    </HeaderContainer>
  );
}

export function ExpandedMenu() {
  const authenticate = useAuthenticate();
  const openAvochato = useOpenAvochatoAction();
  const { navigate } = useAppNavigation();
  return (
    <Row style={styles.expandedMenu} testID="expanded-menu">
      <Pressable onPress={() => handleLink(Links.providers)}>
        <TextBodySmall>For Providers</TextBodySmall>
      </Pressable>
      <Pressable onPress={() => navigate('EmployerLead')}>
        <TextBodySmall>For Employers</TextBodySmall>
      </Pressable>
      {FeatureFlags.CARE_CREDIT_MENU && (
        <Pressable onPress={() => navigate('BumoCreditLanding')}>
          <TextBodySmall>BumoCredit</TextBodySmall>
        </Pressable>
      )}
      <Pressable onPress={() => openAvochato()}>
        <TextBodySmall>Chat with Us</TextBodySmall>
      </Pressable>
      <Pressable onPress={() => authenticate('LOGIN')} testID="login-button">
        <TextBodySmall>Log In</TextBodySmall>
      </Pressable>
      <PrimaryButton
        testID="register-button"
        title="Start Free Account"
        style={{
          paddingHorizontal: 20,
          paddingVertical: 10,
        }}
        onPress={() => authenticate()}
      />
    </Row>
  );
}

function LoggedOutMenuItems({
  closeMenu,
  big,
}: {
  closeMenu: () => void;
  big?: boolean;
}) {
  const theme = useColorScheme();
  const openAvochato = useOpenAvochatoAction();
  const authenticate = useAuthenticate();
  const { navigate } = useAppNavigation();

  const Text = big ? TextH2 : TextCta;

  const menuItemStyles = big
    ? [styles.menuItem, styles.menuItemBig]
    : styles.menuItem;
  return (
    <>
      <Link to={{ screen: 'Explore' }} onPress={() => closeMenu()}>
        <View style={menuItemStyles}>
          <Icon
            name="explore"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Find Care</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Pressable
        onPress={() => {
          closeMenu();
          handleLink(Links.providers);
        }}
      >
        <View style={menuItemStyles}>
          <Icon
            name="shield"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>For Providers</Text>
        </View>
      </Pressable>
      <Divider style={styles.divider} />
      <Pressable
        onPress={() => {
          closeMenu();
          navigate('EmployerLead');
        }}
      >
        <View style={menuItemStyles}>
          <Icon
            name="work"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>For Employers</Text>
        </View>
      </Pressable>
      {FeatureFlags.CARE_CREDIT_MENU && (
        <>
          <Divider style={styles.divider} />
          <Pressable
            onPress={() => {
              navigate('BumoCreditLanding');
              closeMenu();
            }}
          >
            <View style={menuItemStyles}>
              <Icon
                name="wallet"
                color={Colors[theme].accent}
                style={styles.icon as ImageStyle}
              />
              <Text>BumoCredit</Text>
            </View>
          </Pressable>
        </>
      )}
      <Divider style={styles.divider} />
      <Pressable
        onPress={() => {
          closeMenu();
          openAvochato();
        }}
      >
        <View style={menuItemStyles}>
          <Icon
            name="call"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Chat with Us</Text>
        </View>
      </Pressable>
      <Divider style={styles.divider} />
      <Pressable
        onPress={() => {
          authenticate('LOGIN');
          closeMenu();
        }}
      >
        <View style={menuItemStyles}>
          <Icon
            name="profile"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Log In</Text>
        </View>
      </Pressable>
      <Divider style={styles.divider} />
      <Pressable
        onPress={() => {
          authenticate();
          closeMenu();
        }}
      >
        <View style={menuItemStyles}>
          <Icon
            name="plus"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Start Free Account</Text>
        </View>
      </Pressable>
    </>
  );
}

function LoggedOutDesktopMenu() {
  const ref = useRef<PopupActions>(null);
  const theme = useColorScheme();

  const closeMenu = () => ref?.current?.close();

  useEffect(() => {
    window.addEventListener('scroll', closeMenu);

    return () => window.removeEventListener('scroll', closeMenu);
  }, []);

  return (
    <Popup
      ref={ref}
      trigger={
        <Pressable
          accessibilityLabel="Show menu"
          onPress={() => ref?.current?.open()}
          accessibilityRole="button"
        >
          <Icon name="hamburger" style={{ cursor: 'pointer' } as ImageStyle} />
        </Pressable>
      }
      arrow={false}
      contentStyle={{ top: 20 }}
      position="bottom right"
    >
      <View
        accessibilityRole="menu"
        style={[
          styles.menu,
          {
            shadowRadius: 12,
            shadowOpacity: 0.8,
            backgroundColor: Colors[theme].background,
            shadowColor: Colors[theme].shadow,
          },
        ]}
      >
        <LoggedOutMenuItems closeMenu={closeMenu} />
      </View>
    </Popup>
  );
}

function LoggedInMenuItems({
  closeMenu,
  big,
}: {
  closeMenu: () => void;
  big?: boolean;
}) {
  const theme = useColorScheme();
  const authDispatch = useAuthDispatch();
  const dispatchSnackbar = useSnackbarDispatch();

  const onLogout = () => {
    logOut().then(() => {
      authDispatch({ type: 'logout' });
      dispatchSnackbar({
        message: <TextBodySmall>You are logged out.</TextBodySmall>,
      });
      closeMenu();
    });
  };

  const Text = big ? TextH2 : TextCta;

  const menuItemStyles = big
    ? [styles.menuItem, styles.menuItemBig]
    : styles.menuItem;

  return (
    <>
      <Link to={{ screen: 'Explore' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="explore"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Find Care</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Link to={{ screen: 'Favorites' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="heart"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Favorites</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Link to={{ screen: 'Bookings' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="bumo"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Bookings</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Link to={{ screen: 'Profile' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="profile"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Profile</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Link to={{ screen: 'ReferAFriend' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="group"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Refer Friends</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Link to={{ screen: 'BumoCreditLanding' }} onPress={closeMenu}>
        <View style={menuItemStyles}>
          <Icon
            name="wallet"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>BumoCredit</Text>
        </View>
      </Link>
      <Divider style={styles.divider} />
      <Pressable onPress={onLogout}>
        <View style={menuItemStyles}>
          <Icon
            name="logout"
            color={Colors[theme].accent}
            style={styles.icon as ImageStyle}
          />
          <Text>Log Out</Text>
        </View>
      </Pressable>
    </>
  );
}

function LoggedInDesktopMenu() {
  const ref = useRef<PopupActions | null>(null);
  const theme = useColorScheme();

  const closeMenu = () => ref?.current?.close();

  useEffect(() => {
    window.addEventListener('scroll', closeMenu);

    return () => window.removeEventListener('scroll', closeMenu);
  }, []);

  return (
    <Popup
      ref={ref}
      trigger={
        <Pressable
          onPress={() => ref?.current?.open()}
          accessibilityLabel="Show menu"
          accessibilityRole="button"
        >
          <Icon name="hamburger" style={{ cursor: 'pointer' } as ImageStyle} />
        </Pressable>
      }
      arrow={false}
      contentStyle={{ top: 20 }}
      position="bottom right"
    >
      <View
        style={[
          styles.menu,
          {
            shadowRadius: 12,
            shadowOpacity: 0.8,
            backgroundColor: Colors[theme].background,
            shadowColor: Colors[theme].shadow,
          },
        ]}
      >
        <LoggedInMenuItems closeMenu={closeMenu} />
      </View>
    </Popup>
  );
}

type ContainerProps = ChildrenProps &
  StyleProps & {
    withoutTopMargin?: boolean;
    forcedHelloBar?: boolean;
    ignoreCampaignHeaderFn?: ShouldIgnoreCampaignHeaderFn;
  };

const useOuterMarginStyles = (
  forcedHelloBar = false,
  shouldIgnoreCampaignHeaderFn?: ShouldIgnoreCampaignHeaderFn
) => {
  const isMobileDevice = useMaxWidth(430);
  const hellobarHeight = useHelloMessageBarHeight(
    forcedHelloBar,
    shouldIgnoreCampaignHeaderFn
  );
  const headerHeight =
    Layout.navbar.height[isMobileDevice ? 'mobile' : 'desktop'];
  return useMemo(
    () => ({
      marginTop: headerHeight + hellobarHeight,
    }),
    [isMobileDevice]
  );
};

export function Container({
  children,
  style,
  withoutTopMargin,
  forcedHelloBar,
  ignoreCampaignHeaderFn,
}: ContainerProps) {
  const Outer = Platform.OS === 'web' ? View : ScrollView;
  const outerMarginStyles = useOuterMarginStyles(
    forcedHelloBar,
    ignoreCampaignHeaderFn
  );
  return (
    <Outer
      style={[
        styles.outer,
        withoutTopMargin ? styles.withoutTopMargin : outerMarginStyles,
        style,
      ]}
    >
      {children}
    </Outer>
  );
}

export function SecondaryContainer({
  children,
  style,
  withoutTopMargin,
}: ContainerProps) {
  const Outer = Platform.OS === 'web' ? SecondaryView : ScrollView;
  const outerMarginStyles = useOuterMarginStyles();

  return (
    <Outer
      style={[
        styles.outer,
        withoutTopMargin ? styles.withoutTopMargin : outerMarginStyles,
        style,
      ]}
    >
      {children}
    </Outer>
  );
}

export function InnerContainer({
  children,
  style,
}: ChildrenProps & StyleProps) {
  return <View style={[styles.inner, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  outer: {
    flex: 1,
    ...Platform.select({
      web: {
        overflowX: 'clip',
        flexShrink: 0,
        flexBasis: 'auto',
      },
    }),
  },
  withoutTopMargin: {
    marginTop: 0,
  },
  inner: {
    backgroundColor: 'transparent',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 1240,
    paddingHorizontal: 20,
  },
  header: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  button: {
    paddingHorizontal: 20,
    height: 40,
  },
  middleButton: {
    paddingHorizontal: 0,
    marginHorizontal: 30,
  },
  menuTrigger: {
    alignItems: 'flex-end',
  },
  icon: {
    marginRight: 20,
  },
  menu: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    width: 220,
    borderRadius: 10,
  },
  menuItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingVertical: 10,
  },
  menuItemBig: {
    paddingHorizontal: 20,
    paddingVertical: 24,
  },
  textMenuItem: {
    paddingVertical: 10,
  },
  divider: {
    width: '100%',
  },
  expandedMenu: {
    marginLeft: 25,
    flex: 1,
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    gap: 30,
  },
  modalMenuItems: {
    flex: 1,
    overflow: 'scroll',
  },
  flexNoGrow: { flexGrow: 0, flexBasis: 'auto', width: 130 },
});
