import React from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { postForgotPassword } from '../../api/passwords';
import Colors from '../../constants/Colors';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useColorScheme from '../../hooks/useColorScheme';
import { useForm } from '../../hooks/useForm';
import { useMaxWidth } from '../../hooks/useResponsive';
import { PrimaryButton } from '../Button';
import ModalCloseButton from '../ModalCloseButton';
import { TextBodySmall, TextH3 } from '../StyledText';
import { FormInput } from '../StyledTextInput';
import { SecondaryView, StyleProps, View } from '../Themed';

export function ForgotPasswordModal({
  isVisible,
  hide,
}: {
  isVisible: boolean;
  hide: () => void;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(430);

  const dispatchSnackbar = useSnackbarDispatch();
  return (
    <Modal
      useNativeDriver
      isVisible={isVisible}
      onBackdropPress={hide}
      style={{ margin: 0 }}
      hideModalContentWhileAnimating
      testID="forgot-password-modal-container"
    >
      <View
        style={[
          styles.modalContainer,
          isMobileDevice && styles.modalContainerMobile,
        ]}
        testID="forgot-password-modal"
      >
        <ModalCloseButton onPress={hide} absolute />
        <ForgotPasswordContent
          style={{ backgroundColor: Colors[theme].background, width: '100%' }}
          onSuccess={() => {
            dispatchSnackbar({
              message: (
                <TextBodySmall>
                  Password reset instructions were sent to the email you
                  provided
                </TextBodySmall>
              ),
            });
          }}
        />
      </View>
    </Modal>
  );
}

export function ForgotPasswordContent({
  style,
  onSuccess,
}: StyleProps & { onSuccess: () => void }) {
  const { state, dispatch } = useForm({
    email: '',
  });

  const onSubmit = async () => {
    dispatch({ type: 'requestSent' });
    await postForgotPassword({
      email: state.values.email,
    });
    onSuccess();
  };
  return (
    <SecondaryView style={[styles.container, style]}>
      <TextH3 style={styles.title}>Forgot Password?</TextH3>
      <FormInput
        placeholder="Enter email"
        onChangeText={(value) =>
          dispatch({ type: 'field', field: 'email', value })
        }
        onEndEditing={() => dispatch({ type: 'validate', field: 'email' })}
        value={state.values.email}
        error={state.errors.email}
        onFocus={() => dispatch({ type: 'error', field: 'email', value: '' })}
        textContentType="emailAddress"
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <PrimaryButton
        style={styles.button}
        title="Send instructions"
        disabled={!state.dirty || state.isInvalid}
        onPress={onSubmit}
      />
    </SecondaryView>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 500,
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
  },
  modalContainer: {
    padding: 40,
    borderRadius: 10,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 580,
  },
  modalContainerMobile: {
    padding: 20,
    paddingTop: 60,
    flex: 1,
    width: '100%',
  },
  title: {
    marginBottom: 20,
  },
  button: {
    width: '100%',
  },
});
