import { Link } from '@react-navigation/native';
import { GestureResponderEvent, StyleSheet } from 'react-native';

import BumoVerified from './BumoVerified';
import CallOut from './CallOut';
import Favorite from './Favorite';
import ImageCarousel from './ImageCarousel';
import { LocationBlockProps } from './LocationBlocks';
import Row, { TransparentRow } from './Row';
import SeeLocationButton from './SeeLocationButton';
import { TextH4 } from './StyledText';
import { View } from './Themed';
import Details from './location_blocks/Details';
import HoursDetails from './location_blocks/HoursPriceDetails';
import { LocationSlotsButtons } from './location_blocks/LocationSlots';
import { VerificationStatus } from '../api/search';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useOpenLocationPDP } from '../hooks/useOpenLocationPDP';

export default function HorizontalLocationBlockLarge({
  location,
  style,
  openInNewTab,
  imageDimensions = { width: 270, height: 200 },
}: LocationBlockProps) {
  const theme = useColorScheme();
  const {
    id,
    name,
    distance,
    ageFrom,
    ageTo,
    hours,
    pricing,
    slots,
    nameId,
    callouts,
    verificationStatus,
    images = [],
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    reviews,
    price,
  } = location;
  const openPDPLink = useOpenLocationPDP(nameId);
  const handlePress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openPDPLink(openInNewTab);
  };
  const handleMorePhotosPress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openPDPLink(openInNewTab, { photos: true });
  };

  const hasWeekly = location.bookingMode.includes('weekly');
  const onlyWeekly = hasWeekly && location.bookingMode.length === 1;

  return (
    <Link
      to={{
        screen: 'Location',
        params: {
          nameId,
        },
      }}
      onPress={(e) => handlePress(e as GestureResponderEvent)}
    >
      <Row
        style={[
          styles.container,
          { borderBottomColor: Colors[theme].lines },
          style,
        ]}
        testID={location.nameId}
      >
        <View>
          <ImageCarousel
            style={[styles.image, imageDimensions]}
            images={images}
            dimensions={imageDimensions}
            showMaximum={6}
            onMoreButtonPress={handleMorePhotosPress}
          />
          <BumoVerified style={styles.verified} status={verificationStatus} />
        </View>
        <View style={styles.data}>
          <Row style={styles.header}>
            <TextH4>{name}</TextH4>
            <Favorite locationId={id} style={styles.favoriteIcon} />
          </Row>
          <Details
            {...{
              ageFrom,
              ageTo,
              city,
              neighbourhood,
              distance,
              reviews,
              bookByWeek: onlyWeekly,
              price,
              pricing,
            }}
          />
          <HoursDetails hours={hours} />
          {callouts && (
            <Row style={styles.callouts}>
              {callouts.slice(0, 3).map((callout, idx) => (
                <CallOut key={idx} style={styles.callout} title={callout} />
              ))}
            </Row>
          )}

          {slots && (
            <Row style={styles.buttons}>
              {verificationStatus !== VerificationStatus.VERIFIED ? (
                <TransparentRow style={styles.buttonSlot}>
                  <SeeLocationButton
                    locationNameId={nameId}
                    title="Learn More"
                  />
                </TransparentRow>
              ) : (
                <LocationSlotsButtons
                  location={location}
                  handleAllPress={handlePress}
                />
              )}
            </Row>
          )}
        </View>
      </Row>
    </Link>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 580,
    borderBottomWidth: 1,
    paddingBottom: 30,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingRight: 50,
    minHeight: 52,
  },
  favoriteIcon: {
    top: 0,
  },
  data: {
    flex: 1,
    marginLeft: 20,
  },
  details: {
    marginTop: 10,
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
  },
  image: {
    borderRadius: 10,
  },
  verified: {
    left: 10,
    top: 10,
    position: 'absolute',
  },
  callouts: {
    backgroundColor: 'transparent',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 10,
    minHeight: 60,
  },
  callout: {
    marginRight: 15,
    maxWidth: '100%',
    alignItems: 'flex-start',
  },
  buttons: {
    marginTop: 10,
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: 5,
    marginRight: 5,
  },

  buttonSlot: {
    padding: 2,
    width: '50%',
  },
});
