import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DimensionValue,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { postEmployerLead } from '../api/employerLead';
import ChartEmpower from '../assets/images/employer-chart-empower.svg';
import ChartRecruit from '../assets/images/employer-chart-recruiting.svg';
import ChartRetain from '../assets/images/employer-chart-retain.svg';
import BumoInNumbers from '../components/BumoInNumbers';
import { PrimaryButton, TextButton } from '../components/Button';
import { Footer } from '../components/Footer';
import { Icon } from '../components/Icon';
import { Image } from '../components/Image';
import { Container, InnerContainer } from '../components/Layout';
import { PhoneNumberInput } from '../components/PhoneInput';
import { TransparentRow } from '../components/Row';
import {
  MontRegularText,
  QuincyRegularText,
  TextBody,
  TextBodySmall,
  TextCtaButton,
  TextFinePrint,
  TextH1,
  TextH2,
  TextH3,
  TextH4,
} from '../components/StyledText';
import { FormInput } from '../components/StyledTextInput';
import {
  StyleProps,
  TransparentView,
  ViewWithClassname,
} from '../components/Themed';
import { WebLink } from '../components/WebLink';
import WhyYouNeedBumo from '../components/WhyYouNeedBumo';
import { HeroContent } from '../components/explore/HeroWithImage';
import { ResponsiveCarousel } from '../components/explore/HowItWorks';
import Testimonials from '../components/explore/Testimonials';
import CommonHeader from '../components/layouts/CommonHeader';
import Colors from '../constants/Colors';
import Links from '../constants/Links';
import useColorScheme from '../hooks/useColorScheme';
import { useForm } from '../hooks/useForm';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';
import { handleLink } from '../utils/common';
import { loadRecaptchaScript, runRecaptchaSubmit } from '../utils/recaptcha';

const AloYogaLogo = React.lazy(() => import('../assets/logos/alo-yoga.svg'));
const HopSkipLogo = React.lazy(() => import('../assets/logos/hop-skip.svg'));
const LandingLogo = React.lazy(() => import('../assets/logos/landing.svg'));
const HelprLogo = require('../assets/logos/helpr.png');

const useScrollToForm = () =>
  useCallback(() => {
    document
      .querySelector('.lead-form-start')
      ?.scrollIntoView({ inline: 'start' });
  }, []);

export default function EmployerLead() {
  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const theme = useColorScheme();
  const isLargeDevice = useMinWidth(750);

  const scrollToForm = useScrollToForm();
  return (
    <>
      <CommonHeader />
      <Container>
        <InnerContainer style={{ maxWidth: 1440, paddingHorizontal: 0 }}>
          <TransparentRow>
            <HeroContent.Text
              wideBelowWidth={900}
              style={{ justifyContent: 'flex-start', paddingVertical: 25 }}
            >
              <ViewWithClassname className="lead-form-start" />
              <TextH1 style={{ maxWidth: 530 }}>
                Help your team thrive at work and at home
              </TextH1>

              <QuincyRegularText
                style={{
                  fontSize: 25,
                  marginBottom: 25,
                  maxWidth: 500,
                  marginTop: 10,
                }}
              >
                Reliable child care increases working parents' happiness and
                productivity. Be part of the solution by partnering with Bumo.
              </QuincyRegularText>
              <LeadForm />
            </HeroContent.Text>
            <HeroContent.Image
              imageFileName="hero-image-sfa.webp"
              style={{ height: '100%', minHeight: 682 }}
              hiddenBelowWidth={900}
            />
          </TransparentRow>
        </InnerContainer>
        <CompaniesBanner />
        <Stats />
        <InnerContainer>
          <TransparentView
            style={{ alignItems: 'center', paddingVertical: 40 }}
          >
            <Image
              source={require('../assets/images/asterisk.png')}
              style={{
                tintColor: Colors[theme].backgroundTertiary,
                width: 140,
                height: 138,
                marginBottom: 40,
              }}
            />
            <TextH2 style={{ textAlign: 'center' }}>
              Why your employees need Bumo
            </TextH2>
            <QuincyRegularText
              style={[
                {
                  fontSize: 25,
                  maxWidth: 1000,
                  marginTop: 8,
                  marginBottom: 40,
                },
                style.tc,
              ]}
            >
              The modern, working parent needs both fixed and on-demand care for
              peace of mind.
            </QuincyRegularText>
            <WhyYouNeedBumo />
          </TransparentView>
        </InnerContainer>
        <AppScreens />
        <InnerContainer
          style={isLargeDevice ? { position: 'relative', marginTop: 50 } : {}}
        >
          {isLargeDevice ? (
            <Image
              source={require('../assets/images/ribbon.png')}
              style={{
                height: 172,
                width: 173,
                transform: [{ rotateY: '180deg' }],
                position: 'absolute',
                top: -50,
                left: 'calc(50% + 150px)' as DimensionValue,
              }}
            />
          ) : null}
          <BumoInNumbers />
        </InnerContainer>
        <Testimonials
          style={{ backgroundColor: Colors[theme].backgroundTertiary }}
          endAdornment={
            <PrimaryButton
              title="Request a Demo"
              style={{ alignSelf: 'center' }}
              onPress={scrollToForm}
            />
          }
        />
        <FAQs />
        <Footer />
      </Container>
    </>
  );
}

function LeadForm() {
  const theme = useColorScheme();

  const isMobileDevice = useMaxWidth(700);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputSizeStyle = useMemo<ViewStyle>(
    () =>
      isMobileDevice
        ? { width: '100%' }
        : { width: 'calc(50% - 13px)' as DimensionValue },
    [isMobileDevice]
  );

  const { state, dispatch, validateFields, getSerializedObject } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companySize: '',
  });

  const inputHandlers = useMemo(
    () => ({
      firstName: (value: string) =>
        dispatch({
          field: 'firstName',
          value,
          type: 'field',
        }),

      lastName: (value: string) =>
        dispatch({
          field: 'lastName',
          value,
          type: 'field',
        }),
      email: (value: string) =>
        dispatch({
          field: 'email',
          value,
          type: 'field',
        }),
      phone: (value: string) =>
        dispatch({
          field: 'phone',
          value,
          type: 'field',
        }),
      companyName: (value: string) =>
        dispatch({
          field: 'companyName',
          value,
          type: 'field',
        }),
      companySize: (value: string) =>
        dispatch({
          field: 'companySize',
          value,
          type: 'field',
        }),
    }),
    []
  );

  const submit = async () => {
    if (isSubmitting || isSubmitted) {
      return;
    }
    const { ok } = validateFields([
      'firstName',
      'lastName',
      'email',
      'phone',
      'companyName',
      'companySize',
    ]);
    if (!ok) {
      return;
    }

    setIsSubmitting(true);
    const captcha = await runRecaptchaSubmit();
    const response = await postEmployerLead({
      ...getSerializedObject(),
      captcha,
    });

    if (response.ok) {
      setIsSubmitted(true);
      setIsSubmitting(false);
    } else {
      if (
        response.body?.errors.email?.includes('has already been taken') &&
        Object.keys(response.body?.errors || {}).length === 1
      ) {
        setIsSubmitted(true);
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
      setErrorMessage(
        'There was an error while sending the form. Please try again later.'
      );
    }
  };

  if (isSubmitted) {
    return (
      <TextBodySmall>
        Your request for a demo has been submitted. We will be in touch with you
        shortly. 🎉{' '}
      </TextBodySmall>
    );
  }

  return (
    <>
      <TransparentRow
        style={{
          width: '100%',
          flexWrap: 'wrap',
          gap: 10,
          columnGap: 25,
          marginBottom: 25,
          justifyContent: 'flex-start',
        }}
      >
        <FormInput
          placeholder="First Name"
          style={inputSizeStyle}
          value={state.values.firstName}
          onChangeText={inputHandlers.firstName}
          error={state.errors.firstName}
        />
        <FormInput
          placeholder="Last Name"
          style={inputSizeStyle}
          value={state.values.lastName}
          onChangeText={inputHandlers.lastName}
          error={state.errors.lastName}
        />
        <FormInput
          placeholder="Work Email"
          onChangeText={inputHandlers.email}
          value={state.values.email}
          style={inputSizeStyle}
          error={state.errors.email}
        />
        <PhoneNumberInput
          placeholder="Phone Number"
          style={inputSizeStyle}
          value={state.values.phone}
          onChangeText={inputHandlers.phone}
          error={state.errors.phone}
        />
        <FormInput
          placeholder="Company Name"
          style={inputSizeStyle}
          value={state.values.companyName}
          onChangeText={inputHandlers.companyName}
          error={state.errors.companyName}
        />
        <CompanySizeInput
          style={inputSizeStyle}
          onChange={inputHandlers.companySize}
          value={state.values.companySize}
          error={state.errors.companySize}
        />
      </TransparentRow>
      <TransparentView style={{ alignItems: 'flex-start' }}>
        <PrimaryButton
          title="Request a Demo"
          onPress={submit}
          loading={isSubmitting}
        />
        {errorMessage ? (
          <TextFinePrint style={{ color: Colors[theme].error }}>
            {errorMessage}
          </TextFinePrint>
        ) : null}
        <TextFinePrint
          style={{
            fontSize: 12,
            color: Colors[theme].textSecondary,
            maxWidth: 400,
            marginTop: 10,
          }}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <TextButton
            textStyle={{ fontSize: 12, color: Colors[theme].textSecondary }}
            onPress={() =>
              handleLink('https://policies.google.com/privacy', true)
            }
          >
            Privacy Policy
          </TextButton>{' '}
          and{' '}
          <TextButton
            textStyle={{ fontSize: 12, color: Colors[theme].textSecondary }}
            onPress={() =>
              handleLink('https://policies.google.com/terms', true)
            }
          >
            Terms of Service
          </TextButton>{' '}
          apply.
        </TextFinePrint>
      </TransparentView>
    </>
  );
}

const sizeOptions = [
  '1 - 10',
  '11 - 50',
  '51 - 200',
  '201 - 500',
  '501 - 1000',
  '1001 - 5000',
  '5001 - 10000',
  '10001+',
];
function CompanySizeInput({
  style,
  value,
  onChange,
  error,
  message,
}: {
  style?: StyleProps['style'];
  value: string;
  onChange: (val: string) => void;
  error?: string;
  message?: string;
}) {
  const theme = useColorScheme();
  const popupRef = useRef<PopupActions>(null);

  const divRef = useRef(null);

  const [elemWidth, setElemWidth] = useState(100);

  useLayoutEffect(() => {
    if (!divRef.current) {
      return;
    }
    const { width } = (
      divRef.current as HTMLDivElement
    ).getBoundingClientRect();
    setElemWidth(width);
  });

  useEffect(() => {
    if (!divRef.current || !popupRef.current) {
      return;
    }
    function enterListener(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        popupRef.current?.open();
      }
    }
    (divRef.current as HTMLDivElement).addEventListener(
      'keypress',
      enterListener
    );
    return () => {
      if (!divRef.current) {
        return;
      }
      (divRef.current as HTMLDivElement).removeEventListener(
        'keypress',
        enterListener
      );
    };
  }, []);

  useEffect(() => {
    if (!popupRef.current) {
      return;
    }
    popupRef.current.close();
  }, [value]);

  return (
    <Popup
      ref={popupRef}
      arrow={false}
      offsetX={0}
      offsetY={-20}
      trigger={(isOpen) => (
        <TransparentView style={[style, { maxWidth: 500 }]}>
          <TransparentRow
            focusable
            ref={divRef}
            style={{
              borderColor: Colors[theme].lines,
              borderWidth: 1,
              borderRadius: 10,
              height: 55,
              paddingHorizontal: 11,
              paddingVertical: 10,
              marginBottom: !error ? 20 : 0,
              justifyContent: 'space-between',
              borderBottomLeftRadius: isOpen ? 0 : 10,
              borderBottomRightRadius: isOpen ? 0 : 10,
            }}
          >
            <TransparentView>
              <TextBodySmall
                style={[
                  {
                    color: Colors[theme].textSecondary,
                  },
                  sizeOptions.includes(value)
                    ? { fontSize: 12, marginTop: -4 }
                    : {
                        paddingLeft: 6,
                      },
                ]}
              >
                Company Size
              </TextBodySmall>
              {sizeOptions.includes(value) ? (
                <TextBodySmall>{value}</TextBodySmall>
              ) : null}
            </TransparentView>
            <Icon name={isOpen ? 'arrowUp' : 'arrowDown'} />
          </TransparentRow>
          <TextFinePrint
            testID="message"
            style={[
              (!!error || !!message) && {
                marginLeft: 5,
                marginBottom: 5,
                alignSelf: 'stretch',
              },
              !!message && { color: Colors[theme].textSecondary },
              !!error && { color: Colors[theme].error },
            ]}
          >
            {error || message}
          </TextFinePrint>
        </TransparentView>
      )}
    >
      {sizeOptions.map((size, idx) => (
        <Pressable
          focusable
          key={size}
          style={{
            width: elemWidth,
            backgroundColor: Colors[theme].background,
            borderWidth: 1,
            borderBottomWidth: 0.5,
            borderTopWidth: 0,
            borderColor: Colors[theme].lines,
            padding: 10,
            ...(idx === sizeOptions.length - 1
              ? {
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }
              : undefined),
          }}
          onPress={() => onChange(size)}
        >
          <TransparentView style={{ width: '100%' }}>
            <TextBodySmall>{size}</TextBodySmall>
          </TransparentView>
        </Pressable>
      ))}
    </Popup>
  );
}

function CompaniesBanner() {
  const theme = useColorScheme();
  return (
    <TransparentView
      style={{ backgroundColor: Colors[theme].accent, padding: 40 }}
    >
      <InnerContainer style={{ alignItems: 'center' }}>
        <TextH3 style={[style.tc, { maxWidth: 1000, marginBottom: 25 }]}>
          Join these pioneering companies that put their trust in Bumo for their
          employees' child care needs
        </TextH3>
        <TransparentRow style={{ gap: 60, flexWrap: 'wrap' }}>
          <Suspense fallback={<TransparentView />}>
            <Image
              source={HelprLogo}
              style={{ width: 137.5, height: 137.5 / (500 / 246) }}
            />
            <AloYogaLogo style={{ width: 135, height: 45 }} />
            <HopSkipLogo style={{ width: 100.22, height: 50 }} />
            <LandingLogo style={{ width: 176.07, height: 50 }} />
          </Suspense>
        </TransparentRow>
      </InnerContainer>
    </TransparentView>
  );
}

function Stats() {
  return (
    <InnerContainer style={{ paddingVertical: 40 }}>
      <TextH2
        style={[
          {
            maxWidth: 880,
            marginBottom: 70,
            paddingHorizontal: 20,
            alignSelf: 'center',
          },
          style.tc,
        ]}
      >
        Why your company needs to provide employer-sponsored child care
      </TextH2>
      <TransparentRow style={{ gap: 80, marginBottom: 40, flexWrap: 'wrap' }}>
        <StatItem
          stat={<ChartRecruit style={{ width: 341, height: 341 }} />}
          percent={69}
          subtitle="Recruit Better Talent"
          content="69% of working mothers* indicated that employers offering child care benefits would sway their choice in favor of that employer."
          append="*with children ages 0y to 5y"
        />
        <StatItem
          stat={<ChartRetain style={{ width: 341, height: 341 }} />}
          percent={83}
          subtitle="Retain Working Parents"
          content="83% of women & 81% of men* said that child care benefits would be an important factor in deciding whether to stay at their current employer or switch employers."
          append="*with children ages 0y to 5y"
        />
        <StatItem
          stat={<ChartEmpower style={{ width: 341, height: 341 }} />}
          percent={53}
          subtitle="Empower Career Advancement"
          content="53% of women cited child care as one of the reasons they left the workforce temporarily, took on fewer hours, or moved to a less demanding job."
        />
      </TransparentRow>
      <TextBody style={style.tc}>
        Source: MOMS FIRST,{' '}
        <WebLink
          href={Links.momsFirst}
          target="_blank"
          style={{ textDecoration: 'underline', color: 'inherit' }}
        >
          The Bussiness Case for Child Care Report
        </WebLink>
      </TextBody>
    </InnerContainer>
  );
}

function StatItem({
  stat,
  percent,
  subtitle,
  content,
  append,
}: {
  stat: React.ReactNode;
  percent: number;
  subtitle: string;
  content: string;
  append?: string;
}) {
  const theme = useColorScheme();
  return (
    <TransparentView style={{ position: 'relative' }}>
      <TransparentView style={{ marginBottom: 10 }}>
        <TransparentView>{stat}</TransparentView>
        <TransparentView
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MontRegularText
            bold
            style={[
              {
                fontSize: 56,
                lineHeight: 56,
                color: Colors[theme].accentTertiary,
                fontWeight: '900',
              },
              style.tc,
            ]}
          >
            {percent}%
          </MontRegularText>
          <TextCtaButton
            style={[
              style.tc,
              {
                color: Colors[theme].accentTertiary,
                maxWidth: 230,
              },
            ]}
          >
            {subtitle}
          </TextCtaButton>
          <TextBodySmall bold style={[style.tc, { maxWidth: 200 }]}>
            {content}
          </TextBodySmall>
        </TransparentView>
      </TransparentView>
      {append ? <TextBodySmall style={style.tc}>{append}</TextBodySmall> : null}
    </TransparentView>
  );
}

function ScreenCaption({ idx, text }: { idx: number; text: string }) {
  const theme = useColorScheme();
  const isLargeDevice = useMinWidth(920);
  return (
    <TransparentRow style={{ marginTop: 24 }}>
      <MontRegularText
        style={{
          fontSize: isLargeDevice ? 25 : 20,
          backgroundColor: Colors[theme].accentTertiary,
          width: 40,
          height: 40,
          borderRadius: 20,
          display: 'flex',
          color: Colors[theme].background,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 16,
        }}
      >
        {idx}
      </MontRegularText>
      <TextH4 style={{ fontSize: isLargeDevice ? 25 : 20 }}>{text}</TextH4>
    </TransparentRow>
  );
}

function ScreenItem({
  image,
  children,
}: {
  image: ImageSourcePropType;
  children: React.ReactNode;
}) {
  const isLargeDevice = useMinWidth(920);

  const imageSize = isLargeDevice
    ? { width: 300, height: 591 }
    : { width: 200, height: 393 };
  return (
    <TransparentView style={{ alignItems: 'center' }}>
      <Image source={image} style={imageSize} />
      {children}
    </TransparentView>
  );
}

const AppScreensContainer = ({ children }: { children: React.ReactNode }) => {
  const isLargeDevice = useMinWidth(920);
  const shouldRenderCarousel = useMaxWidth(700);
  if (shouldRenderCarousel) {
    return (
      <ResponsiveCarousel
        height={450}
        dotsProps={{
          dotProps: { inactiveColor: '#D9D9D9' },
        }}
      >
        {children}
      </ResponsiveCarousel>
    );
  }
  return (
    <TransparentRow style={{ columnGap: isLargeDevice ? 40 : 20 }}>
      {children}
    </TransparentRow>
  );
};

function AppScreens() {
  return (
    <InnerContainer style={{ paddingVertical: 40 }}>
      <TextH2 style={[style.tc, { marginBottom: 30 }]}>
        A simple, tech-enabled solution for the modern, working parent
      </TextH2>
      <TextH4 style={[style.tc, { marginBottom: 50 }]}>
        Bumo is the first-ever, instantly bookable child care platform. No other
        child care platform has this capability.
      </TextH4>
      <AppScreensContainer>
        <ScreenItem image={require('../assets/images/search-screen.png')}>
          <ScreenCaption idx={1} text="Search" />
        </ScreenItem>
        <ScreenItem image={require('../assets/images/pdp-screen.png')}>
          <ScreenCaption idx={2} text="Select Day and Time" />
        </ScreenItem>
        <ScreenItem image={require('../assets/images/pay-screen.png')}>
          <ScreenCaption idx={3} text="Book Child Care" />
        </ScreenItem>
      </AppScreensContainer>
    </InnerContainer>
  );
}

const FaqItem = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useColorScheme();
  return (
    <TransparentView
      style={{
        paddingVertical: 12,
        borderBottomColor: Colors[theme].lines,
        borderBottomWidth: 1,
        marginBottom: 24,
      }}
    >
      <Pressable
        style={{ marginBottom: 10 }}
        onPress={() => setIsOpen(!isOpen)}
      >
        <TransparentRow style={{ alignItems: 'flex-start' }}>
          <TransparentView style={{ flex: 1 }}>{title}</TransparentView>
          <Icon name={isOpen ? 'arrowUp' : 'arrowDown'} />
        </TransparentRow>
      </Pressable>
      {isOpen ? children : null}
    </TransparentView>
  );
};

function FAQs() {
  const scrollToForm = useScrollToForm();
  return (
    <InnerContainer
      style={{ paddingVertical: 40, maxWidth: 940, paddingHorizontal: 20 }}
    >
      <TextH2 style={[style.tc, { marginBottom: 40 }]}>FAQs</TextH2>
      <FaqItem
        title={
          <TextCtaButton>
            Does this benefit work for companies of all sizes?
          </TextCtaButton>
        }
      >
        <TextBody>
          We support companies with employees from 20 to 10,000 so we have broad
          and expansive coverage. Fill out{' '}
          <TextButton onPress={scrollToForm} TextComponent={TextBody}>
            the form above
          </TextButton>{' '}
          and schedule a call with us to learn how we can help you expand your
          child care benefits program.{' '}
        </TextBody>
      </FaqItem>

      <FaqItem
        title={
          <TextCtaButton>
            My company has employees in different cities. How does that work?
          </TextCtaButton>
        }
      >
        <TextBody>
          We create a custom network for your company to ensure that your
          employees across different cities have coverage within their locality.
        </TextBody>
      </FaqItem>

      <FaqItem
        title={
          <TextCtaButton>
            Do you only provide benefits for parents with young children? What
            about parents with older children?
          </TextCtaButton>
        }
      >
        <TextBody>
          We aim to provide support for parents throughout their entire
          parenthood journey. In addition, to child care and daycare for infants
          and young children, we provide tutoring, test prep, college admissions
          coaching, school age camps and more. You can learn more about our
          tutoring and college prep service{' '}
          <WebLink
            href={Links.collegebound}
            target="_blank"
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            here
          </WebLink>
          .
        </TextBody>
      </FaqItem>

      <FaqItem
        title={<TextCtaButton>Where can I get more information?</TextCtaButton>}
      >
        <TextBody>
          Please submit{' '}
          <TextButton onPress={scrollToForm} TextComponent={TextBody}>
            the form above
          </TextButton>{' '}
          and we'll get back to you soon. We look forward to working with you!
        </TextBody>
      </FaqItem>
    </InnerContainer>
  );
}

const style = StyleSheet.create({
  tc: { textAlign: 'center' },
});
