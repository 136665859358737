import { API_V1_URL } from './config';
import { defaultAuthedRequest, getAuthedRequestHeaders } from './headers';
import { GiftCard, parseGiftCard, parseRedeemedGiftCard } from '../utils/api';

const GIFT_CARDS_V1_URL = `${API_V1_URL}gift_cards/`;

interface PostGiftCardPayload {
  amount: string;
  email: string;
  name: string;
  mobile?: boolean;
}

export async function postGiftCard(payload: PostGiftCardPayload) {
  const { email, name, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      receiver_email: email,
      receiver_name: name,
      ...rest,
    }),
  };
  const response = await fetch(`${GIFT_CARDS_V1_URL}`, request);
  return await response.json();
}

export async function redeemGiftCard(code: string) {
  const request = {
    ...defaultAuthedRequest,
    method: 'POST',
  };
  const response = await fetch(`${GIFT_CARDS_V1_URL}${code}/redeem`, request);
  if (response.status === 401) {
    return { status: 401 } as const;
  } else if (response.status === 400) {
    return { status: 400 } as const;
  }
  return parseRedeemedGiftCard(await response.json());
}

export async function getGiftCard(
  code: string
): Promise<{ ok: true; card: NonNullable<GiftCard> } | { ok: false }> {
  const request = {
    ...defaultAuthedRequest,
    method: 'GET',
  };
  const response = await fetch(`${GIFT_CARDS_V1_URL}${code}`, request);
  const card = parseGiftCard(await response.json());

  if (!card) {
    return { ok: false };
  }
  return {
    ok: true,
    card,
  };
}
