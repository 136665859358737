/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, getStateFromPath } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      LogIn: 'log-in',
      ForgotPassword: 'forgot-password',
      ResetPassword: 'reset-password',
      Onboarding: 'join',
      Explore: '',
      BookAgain: 'book-again',
      ComingSoon: 'coming-soon',
      Profile: 'profile',
      DeleteAccount: 'profile/delete',
      PersonalInfo: 'profile/info',
      ChangePassword: 'profile/password',
      Bookings: 'bookings',
      ConfirmBooking: 'booking/confirm',
      BookingDetails: 'booking/:id',
      Favorites: 'favorites',
      Search: 'search',
      AddChild: 'profile/child/add',
      ChildBasicsForm: 'profile/:name/basics',
      ChildDetailsForm: 'profile/:name/details',
      ChildPickupsForm: 'profile/:name/pickups',
      ChildEmergencyContactsForm: 'profile/:name/contacts',
      Child: 'profile/:name',
      FormsReuseConsent: 'profile/forms-reuse',
      ReferAFriend: 'profile/referral',
      Location: 'location/:nameId',
      AllLocations: 'a/locations',
      PNO: 'pno',
      Vote: 'vote',
      BuyGiftCard: 'gift-cards/buy',
      ClaimGiftCard: 'gift-cards/claim',
      NotFound: '*',
      // Programs
      ProgramMusic: 'program/music',
      ProgramWinterWonderland: 'program/winter-wonderland/:tab?',
      ProgramSummerCamps: 'program/summer',
      // SEO
      SEOPasadena: 'city/pasadena-ca',
      SEOSanFrancisco: 'city/san-francisco-ca',
      SEOLosAngeles: 'city/los-angeles-ca',
      SEOBurbank: 'city/burbank-ca',
      SEOGlendale: 'city/glendale-ca',
      SEOSantaMonica: 'city/santa-monica-ca',
      SEOCenturyCity: 'city/century-city-ca',

      StartFreeAccount: 'start-free-account',
      EmployerLead: 'for-employers',

      BumoCreditLanding: 'bumo-credit',
      BumoCreditPackagesList: 'bumo-credit/packages',

      ReferralLink: 'r/:refferalCode',

      LAFamilySupport: 'la-family-support',
    },
  },
  getStateFromPath: (path, options) => {
    if (path.includes('find-care')) {
      path = path.replace('find-care', '').replace('//', '/');
      return getStateFromPath(path, options);
    }
    return getStateFromPath(path, options);
  },
};

export default linking;
