import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DimensionValue,
  Image,
  ImageStyle,
  Platform,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';

import HeaderContainer from './HeaderContainer';
import { Location, VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import Defaults from '../../constants/Defaults';
import Layout from '../../constants/Layout';
import { LocationFilter } from '../../contexts/search';
import { useSearchButtonState } from '../../contexts/threeparamsearch';
import useColorScheme from '../../hooks/useColorScheme';
import { ResultsObject } from '../../hooks/useFetchSearchPage';
import { useMaxWidth, useMinWidth } from '../../hooks/useResponsive';
import { useShouldDisplayMobileSearch } from '../../hooks/useShouldDisplayNewSearch';
import { pluralize } from '../../utils/common';
import { resultsBucket } from '../../utils/number';
import { FiltersPure } from '../Filters';
import GoogleMap from '../GoogleMap';
import HorizontalLocationBlockLarge from '../HorizontalLocationBlockLarge';
import { Icon } from '../Icon';
import { Container, InnerContainer, MenuElement } from '../Layout';
import Loader from '../Loader';
import { LocationBlockLarge } from '../LocationBlocks';
import { ColumnSizerLocationLarge } from '../LocationSkeleton';
import {
  PreviewLocation,
  PreviewLocationContainer,
} from '../MapPreviewLocation';
import { LocationMarker, SearchLocationMarker } from '../Marker';
import Pagination from '../Pagination';
import Row, { TransparentRow } from '../Row';
import { TextBody, TextH2 } from '../StyledText';
import { TransparentView, View } from '../Themed';
import { NoLocationResults, NoSlotResults } from '../search/NoResults';
import { SearchButtonMobile } from '../search/SearchButtonMobile';

export type OnFiltersChange = (
  v:
    | { location: LocationFilter }
    | { ages: number[] }
    | { dates: { rangeStart: string | null; rangeEnd: string | null } }
) => void;

interface LayoutProps {
  results: ResultsObject;
  title?: string;
  withPureFilters?: boolean;
  onFiltersChange?: OnFiltersChange;
  isFetching: boolean;
  pagination: {
    current: number;
    total: number;
    onPageClick: (page: number) => void;
    goToNextPage: () => void;
    goToPrevPage: () => void;
  };
  customContent?: React.ReactNode;
}

export default function LocationsListWithMapLayout(props: LayoutProps) {
  const isMediumDevice = useMaxWidth(1024);
  const LayoutComponent = isMediumDevice ? MobileLayout : DesktopLayout;

  return <LayoutComponent {...props} />;
}

function MobileLayout({
  results,
  title,
  withPureFilters,
  isFetching: isLoading,
  pagination: paginationProps,
  onFiltersChange,
}: LayoutProps) {
  const theme = useColorScheme();

  const containerRef = useRef(null);
  const [currentPreviewLocation, setCurrentPreviewLocation] =
    useState<null | Location>(null);

  useEffect(() => {
    setCurrentPreviewLocation(null);
  }, [results]);

  useEffect(() => {
    (containerRef.current as unknown as HTMLElement).scrollTo({ top: 0 });
  }, [results]);

  const [isMapVisible, setMapVisible] = useState(false);

  const shouldDisplayMobileSearch = useShouldDisplayMobileSearch();

  const isSmallDevice = useMaxWidth(640);
  const isLargerDevice = useMinWidth(900);
  const LocationComponent = isSmallDevice
    ? ColumnSizerLocationLarge
    : HorizontalLocationBlockLarge;

  return (
    <Container>
      {isLargerDevice ? null : (
        <HeaderContainer shadow>
          <TransparentRow style={{ paddingVertical: 16 }}>
            <TransparentView style={styles.headerIcon}>
              <Pressable onPress={() => setMapVisible(!isMapVisible)}>
                <Icon
                  name="locationOutlined"
                  color={Colors[theme].textSecondary}
                  style={{ width: 24, height: 24 }}
                />
              </Pressable>
            </TransparentView>
            <TransparentView style={[styles.flex, styles.contentCenter]}>
              {shouldDisplayMobileSearch ? <SearchButtonMobile /> : null}
            </TransparentView>
            <TransparentView style={styles.headerIcon}>
              <MenuElement style={{ tintColor: Colors[theme].textSecondary }} />
            </TransparentView>
          </TransparentRow>
          {isMapVisible && withPureFilters ? (
            <TransparentRow>
              {withPureFilters && (
                <FiltersPure
                  refreshResults
                  style={{ width: '100%', paddingHorizontal: 10 }}
                  onFiltersChange={onFiltersChange}
                />
              )}
            </TransparentRow>
          ) : null}
        </HeaderContainer>
      )}
      <InnerContainer style={styles.flex}>
        <View
          style={[
            styles.mobileViewSection,
            {
              overflow: 'hidden',
              zIndex: isMapVisible ? 10002 : 5,
            },
          ]}
        >
          <MapComponent
            results={results}
            currentPreviewLocation={currentPreviewLocation}
            setCurrentPreviewLocation={setCurrentPreviewLocation}
          />
          {results.data.length === 0 ? (
            <PreviewLocationContainer>
              <TextBody>No results</TextBody>
            </PreviewLocationContainer>
          ) : (
            <PreviewLocation location={currentPreviewLocation} />
          )}
        </View>
        <View
          ref={containerRef}
          style={[
            styles.mobileViewSection,
            {
              padding: 20,
              zIndex: isMapVisible ? 5 : 10002,
              overflow: 'scroll',
            },
          ]}
          testID="search-results-container"
        >
          {isLoading && results.data.length === 0 ? (
            <View style={{ padding: 20, paddingVertical: 50 }}>
              <Loader />
            </View>
          ) : results.hasErrored ? (
            <NoLocationResults onFiltersChange={onFiltersChange} />
          ) : results.data.length === 0 ? (
            <NoSlotResults onFiltersChange={onFiltersChange} />
          ) : (
            <>
              <TransparentView
                style={{ alignItems: 'center', marginBottom: 20 }}
              >
                <TextH2 style={styles.marginBottom10}>
                  {title ||
                    pluralize(
                      results.data.length,
                      `${resultsBucket(results.data.length)} Result`
                    )}
                </TextH2>
                {withPureFilters && (
                  <FiltersPure
                    style={styles.marginBottom10}
                    refreshResults
                    onFiltersChange={onFiltersChange}
                  />
                )}
                <TextBody>Child Care Reimagined, Near You</TextBody>
              </TransparentView>
              <TransparentView
                style={[
                  {
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 40,
                  },
                  isLoading && styles.decreasedOpacity,
                ]}
              >
                {results.data.map((location) => (
                  <LocationComponent
                    key={location.id}
                    location={location}
                    style={{ marginBottom: 0 }}
                    openInNewTab
                  />
                ))}
                {isLoading ? <Loader /> : null}
                {/* {(canLoadMore && !isLoading) ? <PrimaryButton title='Load more' onPress={loadMore} style={{
                backgroundColor: Colors[theme].accentSecondary
              }} /> : null} */}

                <View style={styles.paginationContainer}>
                  <Pagination {...paginationProps} />
                </View>
              </TransparentView>
            </>
          )}
        </View>
      </InnerContainer>
    </Container>
  );
}

function DesktopLayout({
  results,
  title,
  withPureFilters,
  onFiltersChange,
  isFetching: isLoading,
  pagination: paginationProps,
  customContent,
}: LayoutProps) {
  // const { currentPage, results, goToNextPage, goToPrevPage, goToPage, isLoading: hookIsLoading } = usePageFetcher(fetchPage);

  const theme = useColorScheme();
  const hasSpaceForHorizontal = useMinWidth(1240);
  const LocationComponent = hasSpaceForHorizontal
    ? HorizontalLocationBlockLarge
    : LocationBlockLarge;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [results]);

  const { width } = useWindowDimensions();

  const columnWidth = width - 40 - 520;

  return (
    <Container>
      {customContent ? (
        <TransparentRow>{customContent}</TransparentRow>
      ) : (
        <TransparentRow>
          <InnerContainer
            style={[styles.flex, isLoading && styles.decreasedOpacity]}
          >
            {isLoading && results.data.length === 0 ? (
              <View style={{ padding: 20, paddingVertical: 50 }}>
                <Loader />
              </View>
            ) : results.hasErrored ? (
              <NoLocationResults onFiltersChange={onFiltersChange} />
            ) : results.data.length === 0 ? (
              <NoSlotResults onFiltersChange={onFiltersChange} />
            ) : (
              <Row
                style={[styles.flex, styles.row]}
                testID="search-results-wrapper"
              >
                <View style={styles.flex}>
                  <View style={styles.resultsSection} testID="search-results">
                    <TextH2 style={styles.marginBottom10}>
                      {title ||
                        pluralize(
                          results.data.length,
                          `${resultsBucket(results.data.length)} Result`
                        )}
                    </TextH2>
                    {withPureFilters && (
                      <FiltersPure
                        style={styles.marginBottom15}
                        onFiltersChange={onFiltersChange}
                      />
                    )}
                    {Platform.OS === 'web' && (
                      <>
                        <Image
                          style={[
                            styles.sparkles as ImageStyle,
                            { tintColor: Colors[theme].accentSecondary },
                          ]}
                          source={require('../../assets/images/sparkles.png')}
                        />
                        <Image
                          style={[
                            styles.ribbon as ImageStyle,
                            { tintColor: Colors[theme].accent },
                          ]}
                          source={require('../../assets/images/ribbon.png')}
                        />
                      </>
                    )}
                    <TextBody style={styles.marginBottom15}>
                      Find and book the best child care near you
                    </TextBody>
                    <View style={styles.results}>
                      {results.data.map((location) => (
                        <LocationComponent
                          key={location.id}
                          style={{ width: '100%' }}
                          location={location}
                          width={columnWidth}
                          openInNewTab
                        />
                      ))}

                      <View
                        style={styles.paginationContainer}
                        testID="pagination-wrapper"
                      >
                        <Pagination {...paginationProps} />
                      </View>
                    </View>
                  </View>
                </View>
              </Row>
            )}
          </InnerContainer>
          {results.hasErrored || results.data.length === 0 ? null : (
            <View style={styles.map}>
              <MapComponent results={results} />
            </View>
          )}
        </TransparentRow>
      )}
    </Container>
  );
}

export function markerColorForLocation(status: VerificationStatus) {
  if (status === VerificationStatus.VERIFIED) return 'accent';
  else if (status === VerificationStatus.COMING_SOON) return 'cta';
  else if (status === VerificationStatus.UNVERIFIED) return 'accentSecondary';
  else if (status === VerificationStatus.HIDDEN) return 'shadow';
}

function MapComponent({
  results,
  currentPreviewLocation,
  setCurrentPreviewLocation,
}: {
  results: ResultsObject;
  currentPreviewLocation?: Location | null;
  setCurrentPreviewLocation?: (loc: Location | null) => void;
}) {
  const isMediumDevice = useMaxWidth(1024);
  const { location } = useSearchButtonState();
  const mapsRef = useRef<google.maps.Map | null>(null);

  const onMarkerPress = useCallback((location: Location) => {
    if (Platform.OS !== 'web' || isMediumDevice) {
      return;
    }
    const el = document.querySelector(`[data-testid="${location.nameId}"]`);
    if (!el) {
      return;
    }
    const position = el.getBoundingClientRect();
    window.scrollTo({ top: position.top + window.scrollY - 100 });
    el?.animate(
      [
        { transform: 'scale(1)' },
        { transform: 'scale(1.1)' },
        { transform: 'scale(1)' },
      ],
      {
        delay: 400,
        duration: 1000,
        iterations: 1,
      }
    );
  }, []);

  useEffect(() => {
    if (mapsRef.current) {
      const bounds = new google.maps.LatLngBounds();
      results.data.forEach((location) => {
        bounds.extend(
          new google.maps.LatLng(location.latitude, location.longitude)
        );
      });
      mapsRef.current.fitBounds(bounds);
      const zoom = mapsRef.current.getZoom();
      if (!zoom || zoom > 10) {
        mapsRef.current.setZoom(10);
      }
    }
  }, [mapsRef.current, results]);

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{
        lat: location.latitude || Defaults.latitude,
        lng: location.longitude || Defaults.longitude,
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapsRef.current = map;
      }}
      onClick={() => setCurrentPreviewLocation?.(null)}
    >
      {location.latitude && location.longitude && (
        <SearchLocationMarker
          lat={location.latitude}
          lng={location.longitude}
          onPress={() => setCurrentPreviewLocation?.(null)}
        />
      )}
      {results.data.map((location) => (
        <LocationMarker
          key={location.id}
          lat={location.latitude}
          lng={location.longitude}
          backgroundColorName={markerColorForLocation(
            location.verificationStatus
          )}
          active={location === currentPreviewLocation}
          onPress={() => {
            setCurrentPreviewLocation?.(location);
            onMarkerPress?.(location);
          }}
        />
      ))}
    </GoogleMap>
  );
}

const styles = StyleSheet.create({
  headerIcon: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  flex: {
    flex: 1,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom15: {
    marginBottom: 15,
  },
  contentCenter: {
    alignItems: 'stretch',
  },
  mobileViewSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  row: {
    alignItems: 'flex-start',
  },
  resultsSection: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginVertical: 70,
  },
  sparkles: {
    position: 'absolute',
    width: 100,
    height: 100,
    left: 410,
    top: -28,
    zIndex: -1,
  },
  ribbon: {
    position: 'absolute',
    width: 90,
    height: 90,
    left: -120,
    top: 72,
  },
  results: {
    // @ts-ignore
    gap: 30,
    width: '50%',
  },
  paginationContainer: {
    alignItems: 'center',
    paddingVertical: 20,
  },
  map: {
    // @ts-ignore
    position: 'fixed',
    right: 0,
    flex: 1,
    maxWidth: '33%',
    minWidth: 500,
    width: '100%',
    top: Layout.navbar.height.desktop,
    height: 'calc(100vh - 80px)' as DimensionValue,
  },
  decreasedOpacity: {
    opacity: 0.3,
  },
});
