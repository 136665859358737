import { useEffect, useState } from 'react';

import { Location, newlyAdded } from '../../api/search';
import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { Icon } from '../Icon';
import { LocationSection } from '../LocationSection';
import LocationSectionHeader from '../LocationSectionHeader';
import { SkeletonLoaderLarge, SkeletonLoaderMedium } from '../LocationSkeleton';
import Tag from '../Tag';
import { TransparentView } from '../Themed';

const useNewlyAddedLocations = () => {
  const [locations, setLocations] = useState<Location[] | undefined>();
  useEffect(() => {
    newlyAdded(5).then((data) => {
      setLocations(data.data);
    });
  }, []);
  return locations;
};

export default function NewlyAdded() {
  const theme = useColorScheme();
  const newlyAddedLocations = useNewlyAddedLocations();
  const isMobileDevice = useMaxWidth(600);
  if (newlyAddedLocations && newlyAddedLocations.length === 0) {
    return null;
  }
  return (
    <>
      <LocationSectionHeader
        title="New Locations In Your Area"
        subtitle={
          <Tag
            icon={<Icon name="flashOn" style={{ width: 24, height: 24 }} />}
            text="Just Added"
            color={Colors[theme].accentSecondary}
          />
        }
      />
      <LocationSection
        locations={newlyAddedLocations || []}
        amountDisplayed={6}
        Block={isMobileDevice ? SkeletonLoaderMedium : SizedSkeletonLarge}
        carousel
        withSkeleton
        style={{ marginVertical: 0 }}
        contentStyle={[
          { gap: isMobileDevice ? 10 : 40 },
          !newlyAddedLocations && { overflow: 'hidden' },
        ]}
      />
    </>
  );
}

function SizedSkeletonLarge(
  props: React.ComponentProps<typeof SkeletonLoaderLarge>
) {
  return (
    <TransparentView style={{ width: 270 }}>
      <SkeletonLoaderLarge {...props} width={270} />
    </TransparentView>
  );
}
