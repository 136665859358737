import { ImageProps, ImageStyle, Platform, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useColorScheme from '../../hooks/useColorScheme';
import { Image } from '../Image';

export default function Asterisk({ style }: { style?: ImageProps['style'] }) {
  const theme = useColorScheme();
  return (
    <Image
      style={[
        styles.asterisk as ImageStyle,
        { tintColor: Colors[theme].accentSecondary },
        style,
      ]}
      source={require('../../assets/images/asterisk.png')}
    />
  );
}

const styles = StyleSheet.create({
  asterisk: {
    position: 'absolute',
    width: 111,
    height: 110,
    zIndex: 1,
    transform: [
      {
        rotate: '51deg',
      },
    ],
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          right: -55,
        }),
        ...(Layout.isMobileDevice && {
          top: 35,
          right: -40,
        }),
      },
    }),
  },
});
