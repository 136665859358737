import { useState } from 'react';
import { Calendar, CalendarTileProperties } from 'react-calendar';

import { Icon } from './Icon';
import {
  dateToNumeric,
  getNextMonthDate,
  formatShortWeekday,
} from '../utils/date';
import { dateToString } from '../utils/locations';

const checkTileDisabled = ({ date }: { date: Date }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export function RangeCalendar({
  rangeStart,
  rangeEnd,
  onChange,
}: {
  rangeStart: string | null;
  rangeEnd: string | null;
  onChange: (change: {
    rangeStart: string | null;
    rangeEnd: string | null;
  }) => void;
}) {
  const [startDate, setStartDate] = useState(new Date());

  const attachMonthTitles = (ref: HTMLElement | null) => {
    if (!ref) {
      return;
    }

    const navigation = ref.querySelector(
      '.react-calendar__navigation'
    ) as HTMLDivElement;
    if (!navigation) {
      return;
    }
    navigation.dataset.currentMonthTitle = dateToNumeric(startDate, {
      month: 'long',
      year: 'numeric',
    });
    const nextMonthDate = getNextMonthDate(startDate);
    navigation.dataset.nextMonthTitle = dateToNumeric(nextMonthDate, {
      month: 'long',
      year: 'numeric',
    });
  };

  const checkTileActive = ({
    activeStartDate,
    date,
  }: CalendarTileProperties) => {
    const firstMonth = activeStartDate.getMonth();

    if (firstMonth !== date.getMonth()) {
      return 'react-calendar__tile--hidden';
    } else {
      const dateString: string = dateToString(date)!;
      if (
        rangeStart &&
        rangeEnd &&
        dateString > rangeStart &&
        dateString <= rangeEnd
      ) {
        return 'react-calendar__tile--chosen';
      } else if (rangeStart && dateString === rangeStart) {
        return 'react-calendar__tile--chosen';
      }
    }
    return null;
  };

  const onDateChange = (dateParam: [Date] | [Date, Date]) => {
    const [start, end] = dateParam;
    const startString = dateToString(start);
    const endString = dateToString(end);
    if (end) {
      onChange({
        rangeStart: startString,
        rangeEnd: endString,
      });
    } else {
      onChange({
        rangeStart: startString,
        rangeEnd: null,
      });
    }
  };

  return (
    <Calendar
      className="range-calendar"
      inputRef={attachMonthTitles}
      next2Label={null}
      nextLabel={<Icon name="arrowForward" />}
      prevLabel={<Icon name="arrowBack" />}
      prev2Label={null}
      minDetail="month"
      maxDetail="month"
      activeStartDate={startDate}
      selectRange
      allowPartialRange={true}
      onActiveStartDateChange={({ action, activeStartDate }) => {
        if (
          action === 'onChange' &&
          activeStartDate.getMonth() === startDate.getMonth() + 1
        ) {
          return;
        }
        setStartDate(activeStartDate);
      }}
      formatShortWeekday={formatShortWeekday}
      showFixedNumberOfWeeks={false}
      showDoubleView={true}
      tileClassName={checkTileActive}
      tileDisabled={checkTileDisabled}
      onChange={onDateChange}
      locale="en-US"
    />
  );
}
