import { useRollbar } from '@rollbar/react';
import { Dimensions, useWindowDimensions } from 'react-native';

let eventSent = false;

export default function useWindowWidth() {
  const rollbar =
    process.env.NODE_ENV === 'development' ? { debug: () => {} } : useRollbar();
  const { width } = useWindowDimensions();

  if (!eventSent && width === 0) {
    rollbar.debug('useWindowDimensions().width is 0');
    eventSent = true;
  }
  return width || Dimensions.get('screen').width;
}
