import { useEffect, useState } from 'react';

import { BumoCreditPackage, getBumoCreditsPackages } from '../api/bumoCredits';

export default function useBumoCreditPackagesList() {
  const [packages, setPackages] = useState<BumoCreditPackage[]>([]);

  useEffect(() => {
    getBumoCreditsPackages().then((list) => {
      setPackages(list);
    });
  }, []);
  return packages;
}
