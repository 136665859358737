import { Platform, StyleSheet } from 'react-native';

import Layout from '../../constants/Layout';
import Links from '../../constants/Links';
import { IconName } from '../Icon';
import LocationDetail from '../LocationDetail';
import MarkdownPreviewer from '../MarkdownPreviewer';
import Row from '../Row';
import { TextH3 } from '../StyledText';
import { View, StyleProps } from '../Themed';
import { isSummerCampVIPPackageAvailable } from '../hellobars/SummerCampsPageHellobar';

type AboutProps = StyleProps & {
  about: string;
  name: string;
  features: {
    text: string;
    icon: IconName;
  }[];
  titleSuffix?: React.ReactNode;
  customCareEnabled: boolean;
  fixedCareEnabled: boolean;
};

const ccpCareText = `
Interested in a **custom schedule** with specific dates and times? [**Request a custom care schedule here**](${Links.forms.customCare})
`;

const fixedCareText = (name: string) => {
  return `
  ${name} offers **monthly, consistent enrollment**! [**Request more info here**](${Links.forms.fixedCare})
  `;
};

const summerCampsVipPackageText = `Purchase the [**VIP Camp Package**](${Links.promo}) and unlock hundreds in savings!`;

export default function About({
  about,
  name,
  features,
  style,
  titleSuffix,
  customCareEnabled,
  fixedCareEnabled,
}: AboutProps) {
  return (
    <View style={[styles.container, style]}>
      <Row
        style={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <TextH3>About</TextH3>
        {titleSuffix}
      </Row>
      <View style={styles.description}>
        <MarkdownPreviewer>{about}</MarkdownPreviewer>
      </View>
      {isSummerCampVIPPackageAvailable() && (
        <View style={styles.description}>
          <MarkdownPreviewer>{summerCampsVipPackageText}</MarkdownPreviewer>
        </View>
      )}
      {customCareEnabled || fixedCareEnabled ? (
        <View style={styles.description}>
          {customCareEnabled && (
            <MarkdownPreviewer>{ccpCareText}</MarkdownPreviewer>
          )}
          {fixedCareEnabled && (
            <MarkdownPreviewer>{fixedCareText(name)}</MarkdownPreviewer>
          )}
        </View>
      ) : null}
      <View style={styles.features}>
        {features.map((feature, idx) => (
          <LocationDetail
            key={idx}
            style={styles.detail}
            iconStyle={styles.icon}
            icon={feature.icon}
            text={feature.text}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {}),
      },
    }),
  },
  description: {
    marginTop: 15,
  },
  features: {
    marginTop: 15,
  },
  detail: {
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
});
