import { getSpecificConfig } from '../env';

export const referralLinkPrefix = getSpecificConfig(
  {
    dev: 'http://localhost:19006/r/',
    staging: 'https://staging.bumo.com/r/',
    production: 'https://book.bumo.com/r/',
  },
  'http://localhost:19006/r/'
);
