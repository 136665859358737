import { ImageSourcePropType, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { getImageUrl } from '../../utils/common';
import { Image } from '../Image';
import { InnerContainer } from '../Layout';
import { TransparentView, View } from '../Themed';

function calculateDimensions(
  { width, height }: { width: number; height: number },
  factor: number = 1
) {
  return {
    width: width * factor,
    height: height * factor,
  };
}

export default function Logos() {
  const isMobileDevice = useMaxWidth(430);
  const logoFactor = isMobileDevice ? 18 / 30 : 1;
  return (
    <View
      style={{
        backgroundColor: Colors[useColorScheme()].accent,
      }}
    >
      <InnerContainer
        style={[styles.logos, !isMobileDevice && styles.logosLarge]}
      >
        <TransparentView
          style={{
            gap: 20,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <Image
            source={getImageUrl('fast-company.webp') as ImageSourcePropType}
            accessibilityLabel="Fast Company Logo"
            style={calculateDimensions(
              {
                width: 211,
                height: 30,
              },
              logoFactor
            )}
          />
          <Image
            source={getImageUrl('forbes.webp') as ImageSourcePropType}
            accessibilityLabel="Forbes Logo"
            style={calculateDimensions(
              {
                width: 120,
                height: 30,
              },
              logoFactor
            )}
          />
          <Image
            source={getImageUrl('vogue.webp') as ImageSourcePropType}
            accessibilityLabel="Vogue Logo"
            style={calculateDimensions(
              {
                width: 115,
                height: 30,
              },
              logoFactor
            )}
          />
          <Image
            source={
              getImageUrl('los-angeles-daily-news.webp') as ImageSourcePropType
            }
            accessibilityLabel="Los Angeles Daily News Logo"
            style={calculateDimensions(
              {
                width: 245,
                height: 30,
              },
              logoFactor
            )}
          />
          <Image
            source={getImageUrl('business-insider.webp') as ImageSourcePropType}
            accessibilityLabel="Business Insider Logo"
            style={calculateDimensions(
              {
                width: 97,
                height: 30,
              },
              logoFactor
            )}
          />
        </TransparentView>
      </InnerContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  logos: {
    paddingVertical: 15,
    justifyContent: 'center',
  },
  logosLarge: {
    paddingVertical: 35,
  },
});
