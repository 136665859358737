import { StyleSheet } from 'react-native';

import { InnerContainer } from './Layout';
import { View } from './Themed';
import Sparkles from '../assets/images/sparkles.svg';
import Colors from '../constants/Colors';
import { findCurrentCampaign } from '../contexts/campaigns';
import useColorScheme from '../hooks/useColorScheme';
import { useMinWidth } from '../hooks/useResponsive';

const currentCampaign = findCurrentCampaign();
const isCampaignActive = currentCampaign !== null;

export default function PeriodicalMessageBar() {
  const theme = useColorScheme();
  const isDesktop = useMinWidth(1024);
  if (!isCampaignActive || !currentCampaign.periodicMessage) {
    return null;
  }

  const MessageComponent = currentCampaign.periodicMessage;
  return (
    <View
      style={[
        {
          backgroundColor: Colors[theme].accentTertiary,
        },
        styles.container,
        !isDesktop && styles.mobileContainer,
      ]}
    >
      {isDesktop ? (
        <InnerContainer>
          <Sparkles
            style={{
              position: 'absolute',
              top: -30,
              left: 0,
            }}
            width={92}
            height={92}
            color={Colors[theme].accentSecondary}
          />
          <Sparkles
            style={{
              position: 'absolute',
              top: -30,
              right: 0,
              transform: [{ rotate: '-90deg' }],
            }}
            width={92}
            height={92}
            color={Colors[theme].accentSecondary}
          />
        </InnerContainer>
      ) : null}

      <MessageComponent
        style={[
          {
            color: Colors[theme].background,
            textAlign: 'center',
          },
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  mobileContainer: {
    padding: 12,
  },
});
