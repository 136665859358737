import { Booking } from './api';
import { dateRangeToNumeric, todayOrTomorrow } from './date';

export function isBookByWeek(booking: Booking) {
  const { dropoff, pickup } = booking;
  const dropoffDate = new Date(dropoff);
  const dropoffDateTime = dropoffDate.getTime();
  const pickupDate = new Date(pickup);
  const pickupDateTime = pickupDate.getTime();

  return pickupDateTime - dropoffDateTime > 24 * 60 * 60 * 1000;
}

export function formatBlackoutDates(booking: Booking) {
  const { blackoutDates } = booking;
  const bookByWeek = isBookByWeek(booking);

  return bookByWeek && blackoutDates && (blackoutDates || []).length > 0
    ? blackoutDates
        .map((d) =>
          new Date(`${d} 00:00`).toLocaleString('en-US', {
            // timeZone: timezone,
            month: 'short',
            day: 'numeric',
          })
        )
        .join(', ')
    : null;
}

export function parseBookingDates(booking: Booking) {
  const {
    dropoff,
    pickup,
    location: { timezone },
  } = booking;

  const dropoffDate = new Date(dropoff);
  const pickupDate = new Date(pickup);
  const soon = todayOrTomorrow(dropoffDate);

  const bookByWeek = isBookByWeek(booking);

  const daysFormatted = dateRangeToNumeric([dropoffDate, pickupDate], {
    // weekday: 'short',
    month: 'short',
    day: 'numeric',
    timeZone: timezone,
  });

  const dropoffFormatted = dropoffDate.toLocaleString('en-US', {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
  });

  const bookingDateFormatted = bookByWeek
    ? `${daysFormatted} at ${dropoffFormatted}`
    : `${soon ? `${soon}, ` : ''}${dropoffDate.toLocaleString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}`;

  const blackoutDatesFormatted = formatBlackoutDates(booking);
  return {
    bookByWeek,
    bookingDateFormatted,
    blackoutDatesFormatted,
  };
}
