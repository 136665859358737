import { API_V1_URL } from './config';
import { defaultAuthedRequest } from './headers';

const API_REPORTS_URL = `${API_V1_URL}reports/`;

type ReportPhotoPayload = {
  location_id: number;
  reason: 'not_helpful' | 'inappropriate';
  photo_url: string;
};
export async function reportPhoto(payload: ReportPhotoPayload) {
  const request = {
    ...defaultAuthedRequest,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${API_REPORTS_URL}photo`, request);
  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(`API Error - ${JSON.stringify(responseBody)}`, {
      cause: responseBody,
    });
  }
  return await response.json();
}
