import { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import ComingSoon from './ComingSoon';
import StartFreeAccountButton from './StartFreeAccountButton';
import { availableNow, Location } from '../../api/search';
import Colors from '../../constants/Colors';
import { useSearchStateUpdate } from '../../contexts/search';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { LocationSection } from '../LocationSection';
import {
  ColumnSizerLocationLarge,
  SkeletonLoaderMedium,
} from '../LocationSkeleton';
import {
  NavigateToSearchButton,
  useNavigateToSearch,
} from '../NavigateToSearchButton';
import { View } from '../Themed';

export function TopLocations({ limit = 12 }: { limit?: number }) {
  const updateSearchState = useSearchStateUpdate();
  const navigateToSearch = useNavigateToSearch();
  const [availableLocations, setAvailableLocations] = useState<Location[]>([]);
  useEffect(() => {
    availableNow(undefined, undefined, 1, limit).then((locations) => {
      setAvailableLocations(locations.data);
    });
  }, []);
  const isMobileDevice = useMaxWidth(430);
  const Block = isMobileDevice
    ? SkeletonLoaderMedium
    : ColumnSizerLocationLarge;
  return (
    <LocationSection
      title="Top Locations"
      Block={Block}
      locations={availableLocations}
      amountDisplayed={limit}
      withSkeleton
      contentStyle={[isMobileDevice ? styles.carousel : {}]}
      onViewAllPress={() => {
        updateSearchState({ location: {} });
        navigateToSearch();
      }}
    />
  );
}

export default function Content() {
  const theme = useColorScheme();
  const updateSearchState = useSearchStateUpdate();
  const navigateToSearch = useNavigateToSearch();

  const isMobileDevice = useMaxWidth(430);

  return (
    <View>
      <TopLocations />
      {!isMobileDevice ? (
        <NavigateToSearchButton
          title="View More Locations"
          onPress={() => {
            updateSearchState({ location: {} });
            navigateToSearch();
          }}
          style={[
            { backgroundColor: Colors[theme].accent },
            styles.viewMoreButton,
          ]}
        />
      ) : null}
      <ComingSoon />
      <StartFreeAccountButton />
    </View>
  );
}

const styles = StyleSheet.create({
  directionColumn: {
    flexDirection: 'column',
  },
  viewMoreButton: {
    alignSelf: 'center',
  },
  comingSoon: {
    width: '100%',
  },
  carousel: {
    ...Platform.select({
      web: {
        scrollbarWidth: 'none',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        gap: 10,
      },
    }),
  },
});
