import { ButtonProps, PrimaryButton } from './Button';
import useAppNavigation from '../hooks/useAppNavigation';

type NavigateToSearchButtonProps = Omit<ButtonProps, 'title'> & {
  title?: string;
};

export function useNavigateToSearch() {
  const navigation = useAppNavigation();
  return (opts?: { lat: number; lng: number }) =>
    navigation.push(
      'Search',
      opts ? { lat: String(opts.lat), lng: String(opts.lng) } : undefined
    );
}

export function NavigateToSearchButton({
  title = 'Find Locations Near Me',
  onPress,
  ...otherProps
}: NavigateToSearchButtonProps) {
  const navigateToSearch = useNavigateToSearch();
  const directNavigate = () => navigateToSearch();

  return (
    <PrimaryButton
      {...otherProps}
      onPress={onPress || directNavigate}
      title={title}
    />
  );
}
