import Checkbox from 'expo-checkbox';
import { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { useShowHideButton } from './shared';
import { LogInSuccessful, logIn } from '../../api/authentication';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../components/Button';
import ModalCloseButton from '../../components/ModalCloseButton';
import {
  TextBodySmall,
  TextFinePrint,
  TextH3,
} from '../../components/StyledText';
import { FormInput } from '../../components/StyledTextInput';
import {
  Divider,
  SecondaryView,
  StyleProps,
  View,
} from '../../components/Themed';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { AuthenticateFnParams } from '../../contexts/authFlow';
import { useAuthDispatch } from '../../contexts/authentication';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useColorScheme from '../../hooks/useColorScheme';
import { useForm } from '../../hooks/useForm';
import { useMaxWidth } from '../../hooks/useResponsive';

const useLoginFlow = () => {
  const [loading, setLoading] = useState(false);

  const login = useCallback(async (email: string, password: string) => {
    setLoading(true);
    const result = await logIn({ email, password });
    setLoading(false);
    if (result.ok) {
      return {
        ok: true as const,
        result,
      };
    } else {
      const errorMessages = {
        WRONG_CREDENTIALS: 'Email or password are incorrect',
        UNKNOWN_ERROR: 'Please try again',
      };
      return {
        ok: false as const,
        error: errorMessages[result.code],
      };
    }
  }, []);

  return {
    loading,
    login,
  };
};

type LoginContentProps = StyleProps & {
  showJoinNowModal: () => void;
  onForgotPasswordClick: () => void;
  onSuccess?: (result: LogInSuccessful) => void;
  params?: AuthenticateFnParams;
  headerStyle?: StyleProps['style'];
};

const getHeaderText = (params?: AuthenticateFnParams) => {
  if (params?.source === 'BUMO_PACKAGE_BUY') {
    return 'Apply BumoCredit to My Account';
  }
  return 'Welcome to Bumo';
};

export function LoginContent({
  showJoinNowModal,
  onForgotPasswordClick,
  style,
  params,
  onSuccess,
  headerStyle,
}: LoginContentProps) {
  const { state, dispatch } = useForm<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  const { loading, login } = useLoginFlow();

  const [rememberEmail, setRememberEmail] = useState(false);

  useEffect(() => {
    const savedEmail =
      Platform.OS === 'web' ? localStorage?.getItem('rememberedEmail') : null;
    if (savedEmail) {
      dispatch({ type: 'field', field: 'email', value: savedEmail });
      setRememberEmail(true);
    }
  }, []);

  const onSubmit = async () => {
    // Save email to localStorage if remember option is checked
    if (rememberEmail) {
      localStorage.setItem('rememberedEmail', state.values.email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }

    const result = await login(state.values.email, state.values.password);
    if (result.ok) {
      onSuccess?.(result.result);
    } else {
      dispatch({ type: 'error', field: 'password', value: result.error });
    }
  };

  const { showing: showingPassword, Button: ShowHideButton } =
    useShowHideButton();

  const headerText = getHeaderText(params);

  return (
    <>
      <SecondaryView style={[styles.inner, style]}>
        <TextH3 style={[styles.title, headerStyle]}>{headerText}</TextH3>

        <FormInput
          placeholder="Enter email"
          testID="email-address"
          value={state.values.email}
          onChangeText={(value) =>
            dispatch({ type: 'field', field: 'email', value })
          }
          error={state.errors.email}
          onEndEditing={() => {
            dispatch({ type: 'validate', field: 'email' });
          }}
          onFocus={() => dispatch({ type: 'error', field: 'email', value: '' })}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          ammendment={
            <View
              style={{
                flexDirection: 'row',
                gap: 10,
                alignItems: 'center',
                padding: 5,
              }}
            >
              <Checkbox
                value={rememberEmail}
                onChange={() => setRememberEmail((v) => !v)}
              />
              <TextFinePrint>Remember email</TextFinePrint>
            </View>
          }
        />
        <FormInput
          placeholder="Enter password"
          testID="password"
          value={state.values.password}
          onChangeText={(value) =>
            dispatch({ type: 'field', field: 'password', value })
          }
          error={state.errors.password}
          onEndEditing={() => dispatch({ type: 'validate', field: 'password' })}
          onFocus={() =>
            dispatch({ type: 'error', field: 'password', value: '' })
          }
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Enter') {
              if (state.isInvalid || loading) {
                return;
              }
              e.preventDefault();
              onSubmit();
            }
          }}
          secureTextEntry={!showingPassword}
          textContentType="password"
          inputAction={ShowHideButton}
        />
        <PrimaryButton
          testID="login-action-button"
          style={styles.button}
          title="Log in"
          loading={loading}
          disabled={state.isInvalid || loading}
          onPress={onSubmit}
        />

        <TextButton
          containerStyle={styles.textButton}
          onPress={onForgotPasswordClick}
        >
          Forgot password?
        </TextButton>
        <Divider style={styles.divider} />
        <TextH3 style={styles.newTitle}>New to Bumo?</TextH3>
        <SecondaryButton
          style={styles.button}
          title="Start Free Account"
          onPress={showJoinNowModal}
        />
      </SecondaryView>
    </>
  );
}

export function LoginModal({
  isVisible,
  hide,
  showJoinNowModal,
  onForgotPasswordClick,
  params,
}: {
  isVisible: boolean;
  hide: () => void;
  showJoinNowModal: () => void;
  onForgotPasswordClick: () => void;
  params?: AuthenticateFnParams;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(430);

  const authDispatch = useAuthDispatch();
  const snackbarDispatch = useSnackbarDispatch();

  return (
    <Modal
      useNativeDriver
      style={{ margin: 0 }}
      isVisible={isVisible}
      onBackdropPress={hide}
      // @ts-expect-error react-native-web supports this to close the modal when hitting Escape
      onRequestClose={hide}
      hideModalContentWhileAnimating
      testID="login-modal-container"
    >
      <View
        style={[
          styles.loginModalContainer,
          isMobileDevice && styles.loginModalContainerMobile,
        ]}
        testID="login-modal"
      >
        <ModalCloseButton onPress={hide} absolute />
        <LoginContent
          params={params}
          onForgotPasswordClick={onForgotPasswordClick}
          showJoinNowModal={showJoinNowModal}
          style={[
            styles.loginModalInnerContainer,
            { backgroundColor: Colors[theme].background },
          ]}
          onSuccess={(result) => {
            authDispatch({
              type: 'login_success',
              value: { parent: result.parent, refresh_token: '' },
            });
            snackbarDispatch({
              message: <TextBodySmall>You are logged in.</TextBodySmall>,
            });
          }}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  inner: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginHorizontal: 'auto',
          marginTop: 70,
          width: 500,
        }),
        ...(Layout.isMobileDevice && {
          marginHorizontal: 20,
        }),
      },
    }),
  },
  title: {
    textAlign: 'center',
    alignSelf: 'center',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginBottom: 30,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 40,
          marginBottom: 30,
        }),
      },
    }),
  },
  button: {
    marginTop: 10,
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          height: 60,
        }),
        ...(Layout.isMobileDevice && {
          height: 50,
        }),
      },
    }),
  },
  textButton: {
    alignSelf: 'center',
    marginTop: 30,
  },
  divider: {
    marginVertical: 30,
  },
  newTitle: {
    alignSelf: 'center',
  },
  loginModalContainer: {
    padding: 40,
    alignSelf: 'center',
    borderRadius: 10,
  },
  loginModalContainerMobile: {
    padding: 0,
    width: '100%',
    flex: 1,
    paddingBottom: 20,
    margin: 0,
  },
  loginModalInnerContainer: {
    marginTop: 15,
  },
});
