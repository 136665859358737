import { useEffect } from 'react';

import { setWelcomeModalShownInLocalStorage } from './WelcomeModalPopup';
import { useIsLoggedIn } from '../contexts/authentication';

export default function LoggedInActions() {
  const isLoggedIn = useIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      setWelcomeModalShownInLocalStorage();
    }
  }, [isLoggedIn]);
  return null;
}
