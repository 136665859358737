import { API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultAuthedRequest,
  defaultGetRequest,
  defaultAuthedGetRequest,
} from './headers';
import { makeSearchSlots } from './search_factory';
import { buildURLQuery } from './utils';
import { IconName } from '../components/Icon';
import { IDirector } from '../components/location/Director';
import FeatureFlags from '../constants/FeatureFlags';
import { LocationFilter } from '../contexts/search';
import { parseLocations } from '../utils/api';
import { GroupedSlots } from '../utils/weekly';

const SEARCH_V1_URL = `${API_V1_URL}search/`;

export interface Pricing {
  penalty?:
    | {
        mode: 'default';
      }
    | {
        mode: 'disabled';
      }
    | {
        mode: 'custom';
        price: number;
        minutes: number;
      };
}

export interface Address {
  street: string;
  city: string;
  neighbourhood: string;
  zip: string;
  state: string;
  country: string;
}

export type DayTimes = string;
export type HourRange = [string, string] | [];

export enum VerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  COMING_SOON = 'coming_soon',
  HIDDEN = 'hidden',
}

// TODO add opening hours and slot hours

export interface DayHours {
  fullDay: HourRange;
  morning?: HourRange;
  afternoon?: HourRange;
  evening?: HourRange;
}
export interface HoursResponse {
  full_day: HourRange;
  morning?: HourRange;
  afternoon?: HourRange;
  evening?: HourRange;
}

export type Hours = DayHours[];

export interface BookingSlot {
  id: number;
  date: string;
  dropoff: string;
  pickup: string;
  dayTime: DayTimes;
  ageFrom: number;
  ageTo: number;
  capacity: number;
  dependent: 'independent' | 'before' | 'after';
}

export interface BookingSlotResponse {
  id: number;
  date: string;
  dropoff: string;
  pickup: string;
  day_time: DayTimes;
  age_from: number;
  age_to: number;
  capacity: number;
  dependent: null | 'before' | 'after';
}

export type BookingMode = 'weekly' | 'date_range';

export type AvailableDiscounts = 'weekly';

export interface Location {
  id: number;
  name: string;
  about: string;
  features: { text: string; icon: IconName }[];
  whatToBring: string;
  bookingMode: BookingMode[];
  availableDiscounts: AvailableDiscounts[];
  pricing: Pricing;
  address?: Address;
  telephone?: string;
  distance: number;
  latitude: number;
  longitude: number;
  ageFrom: number;
  ageTo: number;
  hours: Hours;
  verificationStatus: VerificationStatus;
  timezone: string;
  nameId: string;
  slots: BookingSlot[];
  callouts: string[];
  images: string[];
  videos: string[];
  media: string[];
  scheduleCallUrl: string;
  bookTourUrl: string;
  director: IDirector;
  reviews: {
    rating: number | null;
  };
  requiresLicensingForms: boolean;
  outdoor?: boolean;
  dailySchedules:
    | { label: string; activities: { time: string; description: string }[] }[]
    | null;
  slotsWeekly: GroupedSlots | null;
  cancellationThreshold: number;
  price: number | null;
  mainImage: string;
  customCareEnabled: boolean;
  fixedCareEnabled: boolean;
  programs: 'summer_camps'[];
}

export interface LocationResponse {
  about: string;
  address?: Address;
  age_from: number;
  age_to: number;
  available_discounts: AvailableDiscounts[];
  book_tour_url: string;
  booking_modes: BookingMode[];
  callouts: string[];
  capacities: { age_from: number; age_to: number; amount: number }[];
  custom_care_enabled: boolean;
  daily_schedules:
    | null
    | { label: string; activities: { time: string; description: string }[] }[];
  director: IDirector;
  distance: number;
  features: { text: string; icon: IconName }[];
  fixed_care_enabled: boolean;
  hourly_cancellation_threshold: number;
  hours: HoursResponse[];
  id: number;
  images: string[];
  latitude: number;
  longitude: number;
  main_image: string;
  name_id: string;
  name: string;
  outdoor?: boolean;
  price: string | null;
  pricing: Pricing;
  programs: 'summer_camps'[];
  rating?: number;
  'requires_licensing_forms?': boolean;
  schedule_call_url: string;
  slots: BookingSlotResponse[];
  timezone: string;
  verification_status: VerificationStatus;
  what_to_bring: string;
}

export type CheckZipResponse =
  | { code: 'CODE_UNKNOWN' }
  | { code: 'NOT_IN_NETWORK'; city: string; state: string }
  | { code: 'IN_NETWORK'; city: string; state: string };

export async function checkZip(zip: string): Promise<CheckZipResponse> {
  const requestInfo = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API_V1_URL}search/check_zip?zip=${zip}`,
    requestInfo
  );
  return await response.json();
}

export async function availableNow(
  latitude?: number,
  longitude?: number,
  page?: number,
  per_page?: number,
  ids?: number[]
): Promise<{
  data: Location[];
  total: number;
}> {
  const requestInfo = {
    ...defaultAuthedGetRequest,
    ...getAuthedRequestHeaders(),
  };
  const response = await fetch(
    `${API_V1_URL}search/available_now?${buildURLQuery({
      ...(latitude && longitude ? { lat: latitude, lng: longitude } : null),
      ...(page ? { page } : null),
      ...(per_page ? { per_page } : null),
      ...(Array.isArray(ids) && ids.length > 0 ? { ids } : null),
    })}`,
    requestInfo
  );
  const locations = (await response.json()) as {
    data: LocationResponse[];
    total: number;
  };
  return {
    total: locations.total,
    data: parseLocations(locations.data),
  };
}

export async function comingSoon(
  latitude?: number,
  longitude?: number,
  page?: number,
  ages?: number[]
): Promise<{
  data: Location[];
  total: number;
}> {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(
    `${API_V1_URL}search/coming_soon?${buildURLQuery({
      ...(latitude && longitude ? { lat: latitude, lng: longitude } : null),
      ...(Array.isArray(ages) && ages.length > 0 ? { ages } : null),
      ...(page ? { page } : null),
    })}`,
    requestInfo
  );
  const locations = (await response.json()) as {
    data: LocationResponse[];
    total: number;
  };
  return {
    total: locations.total,
    data: parseLocations(locations.data),
  };
}

export async function popular(
  latitude: number,
  longitude: number
): Promise<{
  data: Location[];
  total: number;
}> {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(
    `${API_V1_URL}search/popular?lat=${latitude}&lng=${longitude}`,
    requestInfo
  );
  const locations = await response.json();
  return {
    total: locations.total,
    data: parseLocations(locations.data),
  };
}

export const searchSlots = makeSearchSlots(API_V1_URL, FeatureFlags.SEARCH_V2);

export async function postSearchLead(params: {
  telephone: string;
  location: LocationFilter;
  rangeStart: string | null;
  rangeEnd: string | null;
  kids: number[];
  ages: number[];
}) {
  const {
    location: { latitude, longitude, description },
    kids,
    rangeStart,
    rangeEnd,
    ...otherParams
  } = params;
  const request = {
    ...defaultAuthedRequest,
    body: JSON.stringify({
      latitude,
      longitude,
      location: description,
      range_start: rangeStart,
      range_end: rangeEnd,
      kid_ids: kids,
      ...otherParams,
    }),
  };
  const response = await fetch(`${SEARCH_V1_URL}/lead`, request);
  return await response.json();
}

export async function previouslyBooked(
  amount: number = 2,
  page = 1
): Promise<{
  data: Location[];
  total: number;
  nextPage: number | undefined;
}> {
  const response = await fetch(
    `${SEARCH_V1_URL}previously_booked?per_page=${amount}&page=${page}`,
    {
      ...defaultAuthedRequest,
      ...defaultGetRequest,
      ...getAuthedRequestHeaders(),
    }
  );
  const locations = (await response.json()) as {
    total: number;
    data: LocationResponse[];
  };

  const totalPages = Math.ceil(locations.total / amount);
  const nextPage = page < totalPages ? page + 1 : undefined;

  return {
    total: locations.total,
    nextPage,
    data: parseLocations(locations.data),
  };
}

export async function newlyAdded(amount: number = 2): Promise<{
  data: Location[];
  total: number;
}> {
  const response = await fetch(
    `${SEARCH_V1_URL}newly_added?per_page=${amount}`,
    {
      ...defaultAuthedRequest,
      ...defaultGetRequest,
      ...getAuthedRequestHeaders(),
    }
  );
  const locations = await response.json();
  return {
    total: locations.total,
    data: parseLocations(locations.data),
  };
}
