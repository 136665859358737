import { useFocusEffect } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import { Location, availableNow } from '../api/search';
import { Footer } from '../components/Footer';
import { Container, Header, InnerContainer } from '../components/Layout';
import { LocationSection } from '../components/LocationSection';
import {
  ColumnSizerLocationLarge,
  SkeletonLoaderMedium,
} from '../components/LocationSkeleton';
import { NavigateToSearchButton } from '../components/NavigateToSearchButton';
import { View } from '../components/Themed';
import { ThreeParamsSearchHeader } from '../components/ThreeParamSearch';
import HeroWithImage from '../components/explore/HeroWithImage';
import MarketingBanner from '../components/explore/MarketingBanner';
import MoreAmazingCare from '../components/explore/MoreAmazingCareIn';
import Testimonials from '../components/explore/Testimonials';
import { SearchButtonMobile } from '../components/search/SearchButtonMobile';
import Colors from '../constants/Colors';
import { useUpdateSearchState } from '../contexts/threeparamsearch';
import useAppNavigation from '../hooks/useAppNavigation';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { CdnImageName } from '../utils/common';

const useCityLocations = ({ lat, lng }: { lat: number; lng: number }) => {
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    availableNow(lat, lng, 1, 12).then((result) => {
      setLocations(result.data);
    });
  }, []);

  return locations;
};

export default function CityPageFactory(config: {
  locationName: string;
  locationParam: string;
  heroImageFilename: CdnImageName;
  heroImageAltText: string;
  lat: number;
  lng: number;
}) {
  const { lat, lng } = config;

  const searchLocationParam = {
    description: config.locationParam,
    latitude: config.lat,
    longitude: config.lng,
  };
  return function CityPage() {
    const theme = useColorScheme();
    const navigation = useAppNavigation();

    const locations = useCityLocations({ lat, lng });

    const { setButtonState, setInputState } = useUpdateSearchState();
    useFocusEffect(() => {
      setButtonState({
        location: searchLocationParam,
      });
      setInputState({
        location: searchLocationParam,
      });
    });

    const isMobileDevice = useMaxWidth(430);
    const isSmallDevice = useMaxWidth(768);
    const Block = isMobileDevice
      ? SkeletonLoaderMedium
      : ColumnSizerLocationLarge;

    const navigateToSearchResults = () =>
      navigation.push('Search', {
        location: config.locationParam,
      });

    return (
      <>
        {isSmallDevice ? <Header /> : <ThreeParamsSearchHeader />}
        <Container>
          {isSmallDevice ? (
            <View style={{ padding: 20, paddingVertical: 15 }}>
              <SearchButtonMobile wide />
            </View>
          ) : null}
          <HeroWithImage
            mainTitle={
              <>Top, Trusted, On-Demand Child Care in {config.locationParam}</>
            }
            altText={config.heroImageAltText}
            buttonText={`Find Care in ${config.locationName}`}
            onButtonPress={navigateToSearchResults}
            imageFileName={config.heroImageFilename}
          />
          <InnerContainer>
            <LocationSection
              locations={locations}
              title={`Top Child Care in ${config.locationName}`}
              Block={Block}
              withSkeleton
              amountDisplayed={12}
              onViewAllPress={navigateToSearchResults}
              carousel={isMobileDevice}
            />
            <NavigateToSearchButton
              title="View More Locations"
              onPress={navigateToSearchResults}
              style={[
                {
                  backgroundColor: Colors[theme].accent,
                  alignSelf: 'center',
                  marginBottom: 60,
                },
                isMobileDevice && {
                  marginTop: 10,
                  marginBottom: 30,
                },
              ]}
            />
          </InnerContainer>
          <Testimonials />
          <MarketingBanner />
          <MoreAmazingCare />
          <Footer />
        </Container>
      </>
    );
  };
}
