import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { Tab } from '../api/program_pages';
import { Location, availableNow } from '../api/search';
import { Footer } from '../components/Footer';
import { ShouldIgnoreCampaignHeaderFn } from '../components/HelloMessageBar';
import { Container, Header, InnerContainer } from '../components/Layout';
import { LocationSection } from '../components/LocationSection';
import LocationSectionHeader from '../components/LocationSectionHeader';
import {
  SkeletonLoaderMedium,
  ColumnSizerLocationLarge,
} from '../components/LocationSkeleton';
import {
  NavigateToSearchButton,
  useNavigateToSearch,
} from '../components/NavigateToSearchButton';
import { TextH3 } from '../components/StyledText';
import Tabs, { TabItem } from '../components/Tabs';
import { ThreeParamsSearchHeader } from '../components/ThreeParamSearch';
import HeroWithImage from '../components/explore/HeroWithImage';
import MarketingBanner from '../components/explore/MarketingBanner';
import Testimonials from '../components/explore/Testimonials';
import { SearchButtonMobile } from '../components/search/SearchButtonMobile';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { makeCached } from '../utils/cached';
import { CdnImageName } from '../utils/common';

const cachedAvailableNow = makeCached(availableNow, {
  cacheExpirationTimeInMs: 60 * 1000,
});

export const useProgramLocations = ({ ids }: { ids: number[] }) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (ids.length === 0) {
      return;
    }

    setIsFetching(true);
    cachedAvailableNow(undefined, undefined, 1, ids.length, ids).then(
      (result) => {
        setIsFetching(false);
        setLocations(result.data);
      }
    );
  }, [ids]);

  return { locations, isFetching };
};

export default function ProgramPageFactory(
  config: {
    sectionTitle: string;
    additionalSections?: 'REVIEWS'[];
    customHelloBar?: React.ReactNode;
    ignoreCampaignHeaderFn?: ShouldIgnoreCampaignHeaderFn;
    disabledMarketingBanner?: boolean;
  } & (
    | {
        HeaderComponent: React.ComponentType;
      }
    | {
        title: string;
        heroImageFilename: CdnImageName;
        heroImageAltText: string;
        LogoImage: (props: {
          width: number;
          height: number;
          style?: any;
        }) => React.ReactNode;
      }
  ) &
    (
      | { tabs?: undefined; ids: number[] }
      | {
          ids?: undefined;
          tabs: {
            title: string;
            ids: number[];
          }[];
        }
      | { tabs?: undefined; ids?: undefined }
    )
) {
  return function ProgramPage({ tabs = config.tabs }: { tabs?: Tab[] }) {
    const theme = useColorScheme();

    const [activeTab, setActiveTab] = useState(0);

    const currentTab = tabs?.[activeTab] ?? {
      title: '',
      ids: config.ids || [],
    };

    const tabsVisible = tabs && tabs.length > 1;

    const { locations } = useProgramLocations(currentTab);
    const isMobileDevice = useMaxWidth(430);
    const isSmallDevice = useMaxWidth(768);
    const navigateToSearch = useNavigateToSearch();

    // .. TODO get lat and lng from parent if logged in
    const Block = isMobileDevice
      ? SkeletonLoaderMedium
      : ColumnSizerLocationLarge;

    return (
      <>
        {isSmallDevice ? (
          <Header
            customHelloBar={config.customHelloBar}
            ignoreCampaignHeader={config.ignoreCampaignHeaderFn}
          />
        ) : (
          <ThreeParamsSearchHeader
            customHelloBar={config.customHelloBar}
            ignoreCampaignHeader={config.ignoreCampaignHeaderFn}
          />
        )}
        <Container
          forcedHelloBar={!!config.customHelloBar}
          ignoreCampaignHeaderFn={config.ignoreCampaignHeaderFn}
        >
          {isSmallDevice ? (
            <View style={{ padding: 20, paddingVertical: 15 }}>
              <SearchButtonMobile wide />
            </View>
          ) : null}
          {'HeaderComponent' in config ? (
            <config.HeaderComponent />
          ) : (
            <HeroWithImage
              mainTitle={
                config.title ||
                'Special sessions just for you and your family. Find a session and book today!'
              }
              titleComponent={TextH3}
              altText={config.heroImageAltText}
              imageFileName={config.heroImageFilename}
              logo={
                <config.LogoImage
                  style={{
                    ...(isMobileDevice && { alignSelf: 'center' }),
                    marginBottom: 25,
                  }}
                  width={isMobileDevice ? 300 : 462}
                  height={isMobileDevice ? 112 : 173}
                />
              }
              skipButton
            />
          )}
          <InnerContainer>
            <LocationSectionHeader
              title={config.sectionTitle}
              style={{ marginBottom: 20, marginTop: 45 }}
            />
            {tabsVisible ? (
              <Tabs active={activeTab} onChange={setActiveTab}>
                {tabs.map((tabInfo, idx) => (
                  <TabItem key={`summery-tab-${idx}`}>{tabInfo.title}</TabItem>
                ))}
              </Tabs>
            ) : null}
            <LocationSection
              locations={locations}
              // title={config.sectionTitle}
              Block={Block}
              withSkeleton
              amountDisplayed={currentTab.ids.length}
              onViewAllPress={navigateToSearch}
              carousel={isMobileDevice}
              skipViewAll
            />
            <NavigateToSearchButton
              title="Find On-Demand Child Care"
              style={[
                {
                  backgroundColor: Colors[theme].accent,
                  alignSelf: 'center',
                  marginBottom: 60,
                },
                isMobileDevice && {
                  marginTop: 10,
                  marginBottom: 30,
                },
              ]}
            />
          </InnerContainer>
          {config.additionalSections
            ? config.additionalSections.map((sectionCode) =>
                sectionCode === 'REVIEWS' ? (
                  <Testimonials key={sectionCode} />
                ) : null
              )
            : null}
          {!config.disabledMarketingBanner ? <MarketingBanner /> : null}
          <Footer />
        </Container>
      </>
    );
  };
}
