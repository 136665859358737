import { DimensionValue, StyleSheet } from 'react-native';

import Rating from './Rating';
import { StaticTexts } from '../../constants/StaticTexts';
import { LocationReviews } from '../../types/Reviews';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';
import { StyleProps, TransparentView } from '../Themed';

const { FeatureTitles } = StaticTexts.pdp;

export default function ReviewsRatingsSummary({
  reviews,
  shouldBeFullWidth,
  outdoor,
  notGrow = false,
  style,
}: {
  reviews?: LocationReviews | null;
  shouldBeFullWidth?: boolean;
  outdoor: boolean;
  notGrow?: boolean;
  style?: StyleProps['style'];
}) {
  return (
    <TransparentView
      style={[
        styles.featuresWide,
        shouldBeFullWidth && styles.featuresNarrow,
        style,
      ]}
    >
      <FeatureItem
        name={FeatureTitles.cleanliness}
        rate={reviews?.summary.features.cleanliness}
        notGrow={notGrow}
      />
      <FeatureItem
        name={FeatureTitles.caregivers}
        rate={reviews?.summary.features.caregivers}
        notGrow={notGrow}
      />
      <FeatureItem
        name={FeatureTitles.curriculum}
        rate={reviews?.summary.features.curriculum}
        notGrow={notGrow}
      />
      <FeatureItem
        name={FeatureTitles.professionalism}
        rate={reviews?.summary.features.professionalism}
        notGrow={notGrow}
      />
      <FeatureItem
        name={FeatureTitles.indoor}
        rate={reviews?.summary.features.indoor}
        notGrow={notGrow}
      />
      {outdoor ? (
        <FeatureItem
          name={FeatureTitles.outdoor}
          rate={reviews?.summary.features.outdoor}
          notGrow={notGrow}
        />
      ) : null}
    </TransparentView>
  );
}

export function FeatureItem({
  name,
  rate,
  notGrow,
}: {
  name: string;
  rate?: number;
  notGrow?: boolean;
}) {
  return (
    <TransparentRow
      style={[styles.featureItem, notGrow && styles.featureItemNotGrow]}
    >
      <TextBodySmall>{name}</TextBodySmall>
      <Rating rate={rate} />
    </TransparentRow>
  );
}

const styles = StyleSheet.create({
  featuresWide: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: 16,
    columnGap: 36,
  },
  featuresNarrow: {
    flexDirection: 'column',
    rowGap: 16,
  },
  featureItem: {
    minWidth: 'calc(50% - 36px / 2)' as DimensionValue,
    flex: 1,
    justifyContent: 'space-between',
  },
  featureItemNotGrow: {
    flexGrow: 0,
  },
});
