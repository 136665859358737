import Checkbox from 'expo-checkbox';
import { StyleSheet } from 'react-native';

import { TransparentRow } from './Row';
import { TextBodySmall, TextFinePrint } from './StyledText';
import { TransparentView } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

interface OptionsInputProps {
  testID?: string;
  label: string;
  value: string;
  options: string[];
  onChange: (val: string) => void;
  message?: React.ReactNode;
  allowOff?: boolean;
  error?: string;
}

export default function OptionsInput({
  testID,
  label,
  value,
  options,
  onChange,
  message,
  allowOff,
  error,
}: OptionsInputProps) {
  const theme = useColorScheme();
  return (
    <>
      <TransparentView
        style={[
          styles.container,
          (!!error || !!message) && { marginBottom: 0 },
          {
            borderColor: Colors[theme].lines,
            backgroundColor: Colors[theme].background,
          },
          error || message
            ? {
                marginBottom: 0,
              }
            : {},
        ]}
        testID={testID}
      >
        <TextFinePrint
          style={{ color: Colors[theme].textSecondary, marginBottom: 5 }}
        >
          {label}
        </TextFinePrint>
        <TransparentRow style={styles.options}>
          {options.map((opt) => (
            <TransparentRow
              style={styles.optionItem}
              key={`option-${opt.replaceAll(' ', '-')}`}
            >
              <Checkbox
                style={styles.checkbox}
                testID={`${testID + '-' || ''}option-${opt.replaceAll(
                  ' ',
                  '-'
                )}`}
                onValueChange={() => {
                  if (value === opt && allowOff) {
                    onChange('');
                    return;
                  }
                  return onChange(opt);
                }}
                color={Colors[theme].accent}
                value={value === opt}
              />
              <TextBodySmall
                onPress={() => {
                  if (value === opt && allowOff) {
                    onChange('');
                    return;
                  }
                  return onChange(opt);
                }}
              >
                {opt}
              </TextBodySmall>
            </TransparentRow>
          ))}
        </TransparentRow>
      </TransparentView>
      {message || error ? (
        <TextFinePrint
          testID="message"
          style={[
            (!!error || !!message) && styles.error,
            !!message && { color: Colors[theme].textSecondary },
            !!error && { color: Colors[theme].error },
          ]}
        >
          {message || error}
        </TextFinePrint>
      ) : null}
    </>
  );
}

const errorMarginBottom = 5;
const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderRadius: 10,
  },
  checkbox: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  error: {
    marginLeft: 5,
    marginBottom: errorMarginBottom,
    alignSelf: 'stretch',
  },
  options: { alignItems: 'flex-start', justifyContent: 'flex-start' },
  optionItem: {
    marginRight: 15,
  },
});
