import ChosenCalendarRow from './ChosenCalendarRow';
import { BookingSlot } from '../../api/search';
import { DateSlots, DayTimeSlot } from '../../contexts/checkout';
import { getUnion } from '../../utils/common';
import { formatDateHoursRange } from '../../utils/locations';
import { formatWeekDates } from '../../utils/weekly';

export function ChosenWeekViewer({
  selectedWeeks,
  onWeekRemove,
}: {
  selectedWeeks: SelectedWeekInfo[];
  onWeekRemove: (weekId: string) => void;
}) {
  if (selectedWeeks.length === 0) {
    return null;
  }
  return (
    <>
      {selectedWeeks.map((weekInfo) => (
        <ChosenCalendarRow
          key={weekInfo.id}
          dayLabel={weekInfo.daysLabel}
          hoursLabel={weekInfo.hoursLabel}
          disabled={weekInfo.disabled}
          onRemove={() => onWeekRemove(weekInfo.id)}
        />
      ))}
    </>
  );
}

export type SelectedWeekInfo = {
  id: string;
  daysLabel: string;
  hoursLabel: string;
  disabled: boolean;
};

export function formatChosenWeeks(
  chosenWeekDayTimes: Record<string, string[]>,
  {
    slotsPerWeek,
    dateSlotsPerWeek,
  }: {
    slotsPerWeek: Record<string, BookingSlot[]>;
    dateSlotsPerWeek: Record<string, DateSlots>;
  }
): SelectedWeekInfo[] {
  function formatHours(weekId: string, daytimes: string[] | undefined) {
    const slots = slotsPerWeek[weekId];
    const dateSlots = dateSlotsPerWeek[weekId];

    if (!daytimes || !slots || !dateSlots) {
      return 'unknown hours';
    }

    const daytimesInfo = Object.values(dateSlots)[0];
    if (!daytimesInfo) {
      return 'unknown hours';
    }

    const hours = daytimes
      .map((d) => daytimesInfo[d] || slots.find((s) => s.dayTime === d))
      .filter(
        (a: DayTimeSlot | BookingSlot | undefined): a is DayTimeSlot => !!a
      )
      .map((bookingSlot) => [bookingSlot.dropoff, bookingSlot.pickup])
      .flat()
      .sort();

    const firstHour = hours[0];
    const lastHour = hours[hours.length - 1];

    const formattedHours = formatDateHoursRange(
      firstHour || '',
      lastHour || ''
    );
    return formattedHours || 'unknown hours';
    // const;
  }

  return Object.keys(chosenWeekDayTimes)
    .sort()
    .map((weekId) => {
      let filteredDateSlotsKeys: null | string[] = null;

      Object.values(dateSlotsPerWeek[weekId] || {}).forEach((v) => {
        if (filteredDateSlotsKeys === null) {
          filteredDateSlotsKeys = Object.keys(v);
        } else {
          filteredDateSlotsKeys = getUnion(
            filteredDateSlotsKeys,
            Object.keys(v)
          );
        }
      });
      return {
        id: weekId,
        daysLabel: formatWeekDates(dateSlotsPerWeek[weekId]) || weekId,
        hoursLabel: formatHours(weekId, chosenWeekDayTimes[weekId]),
        disabled:
          chosenWeekDayTimes[weekId]?.some(
            (daytime) => !filteredDateSlotsKeys?.includes(daytime)
          ) || Object.keys(dateSlotsPerWeek[weekId] || {}).length === 0,
      };
    });
}
