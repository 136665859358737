import { useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { PrimaryButton } from './Button';
import { Icon } from './Icon';
import { TransparentRow } from './Row';
import { TextBody, TextCta, TextH3, TextTag } from './StyledText';
import { View } from './Themed';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import { DayTime, DayTimeSlot, DayTimeSlots } from '../contexts/checkout';
import useColorScheme from '../hooks/useColorScheme';
import { formatDateHoursRange } from '../utils/locations';

interface PickTimePopupProps {
  open: boolean;
  close: () => void;
  dateString: string;
  onAdd: (dayTimes: DayTime[]) => void;
  chosenSlots: DayTime[];
  slots: DayTimeSlots;
}

export function findDependencies(slots: DayTimeSlots) {
  const daytimesSlots = Object.entries(slots);
  const acc: Record<string, string[]> = {};

  daytimesSlots.forEach(([daytime, slot], idx, arr) => {
    if (slot.dependent === 'independent') {
      return;
    }
    const checkFn = (a: DayTimeSlot) =>
      slot.dependent === 'before'
        ? slot.dropoff === a.pickup
        : a.dropoff === slot.pickup;
    const adjacent = arr.filter(([d, el]) => {
      if (daytime === d) {
        return;
      }
      return checkFn(el);
    });

    if (adjacent) {
      acc[daytime] = adjacent.map(([d]) => d);
    }
  });

  return acc;
}

export function getTimesRange(
  dropOffPickupTimes: {
    dropoff: string;
    pickup: string;
  }[]
) {
  return dropOffPickupTimes.reduce(
    (acc, cur) => {
      const accDropoff = acc.dropoff ? new Date(acc.dropoff) : undefined;
      const accPickup = acc.pickup ? new Date(acc.pickup) : undefined;

      if (!accDropoff || new Date(cur.dropoff) < accDropoff) {
        acc.dropoff = cur.dropoff;
      }
      if (!accPickup || new Date(cur.pickup) > accPickup) {
        acc.pickup = cur.pickup;
      }
      return acc;
    },
    {
      dropoff: dropOffPickupTimes[0]?.dropoff || '',
      pickup: dropOffPickupTimes[0]?.pickup || '',
    } as {
      dropoff: string;
      pickup: string;
    }
  );
}

function getDisabledDaytimes(
  slots: DayTimeSlots,
  chosen: string[],
  dependencyMap: Record<string, string[]>
) {
  if (chosen.length === 0) {
    return [];
  }
  const dayTimes = Object.entries(slots);

  const dropOffPickupTimes = dayTimes
    .filter(([daytime]) => chosen.includes(daytime))
    .map(([daytime, slot]) => ({ dropoff: slot.dropoff, pickup: slot.pickup }));

  const selectedTimesRange = getTimesRange(dropOffPickupTimes);

  const notSelectedIndependent = dayTimes
    .filter(
      ([daytime, s]) =>
        s.dependent === 'independent' && !chosen.includes(daytime)
    )
    .map(
      ([daytime, { dropoff, pickup }]) =>
        [daytime, { dropoff, pickup }] as const
    );

  const disabledByPickup = notSelectedIndependent
    .filter(
      ([, s]) =>
        selectedTimesRange.pickup &&
        new Date(s.dropoff).getTime() !==
          new Date(selectedTimesRange.pickup).getTime()
    )
    .map(([daytime]) => daytime);

  const disabledByDropoff = notSelectedIndependent
    .filter(
      ([, s]) =>
        selectedTimesRange.dropoff &&
        new Date(s.pickup).getTime() !==
          new Date(selectedTimesRange.dropoff).getTime()
    )
    .map(([daytime]) => daytime);

  const disabled = notSelectedIndependent
    .filter(
      ([ns]) => disabledByDropoff.includes(ns) && disabledByPickup.includes(ns)
    )
    .map(([daytime]) => daytime);

  const dependentDisabled = Object.entries(dependencyMap)
    .filter(
      ([, adjacent]) =>
        adjacent.every((a) => !chosen.includes(a)) &&
        !adjacent.some((a) => !disabled.includes(a))
    )
    .map(([daytime]) => daytime);

  const allDisabled = Array.from(new Set([...disabled, ...dependentDisabled]));

  // we want to check if earlier session can be selected without creating a gap
  // we will be doing it by checking pickup times with current selected time range dropoff
  const allDisabledByPickup = allDisabled.reduce(
    (acc, cur) => {
      const slot = slots[cur];
      if (!slot) {
        return acc;
      }
      return { ...acc, [slot.pickup]: cur };
    },
    {} as Record<string, string>
  );

  const currentTimeRange = { ...selectedTimesRange };

  const disabledAbleToSelect: string[] = [];
  while (
    currentTimeRange.dropoff &&
    allDisabledByPickup[currentTimeRange.dropoff]
  ) {
    const adjacentSessionName = allDisabledByPickup[currentTimeRange.dropoff]!;
    const session = slots[adjacentSessionName];

    if (!session) {
      break;
    }

    delete allDisabledByPickup[currentTimeRange.dropoff];
    currentTimeRange.dropoff = session.dropoff;
    disabledAbleToSelect.push(adjacentSessionName);
  }

  return allDisabled.filter((d) => !disabledAbleToSelect.includes(d));
}

export function getDayTimesToRemove(
  dayTime: string,
  chosen: string[],
  dependencyMap: Record<string, string[]>
) {
  const chosenLeft = chosen.filter((c) => c !== dayTime);
  return [
    dayTime,
    ...chosen
      .filter(
        (sessionName) =>
          // get dependent sessions
          sessionName !== dayTime && sessionName in dependencyMap
      )
      .map((sessionName) => [
        sessionName,
        // check if dependent session can be left chosen
        dependencyMap[sessionName]
          ?.filter((cc) => cc !== dayTime)
          .some((cc) => chosenLeft.includes(cc)),
      ])
      .filter(([, shouldBeLeft]) => !shouldBeLeft)
      .map(([sessionName]) => sessionName),
  ];
}

const filterContinuousOnly = (arr: number[]) => {
  let shouldContinue = true;
  return arr.filter((num, index) => {
    if (index === 0) return true;
    if (!shouldContinue) {
      return false;
    }
    shouldContinue = Math.abs(num - arr[index - 1]!) <= 1;
    return shouldContinue;
  });
};

export function PickTimePopupContent({
  dateString,
  onAdd,
  slots,
  chosenSlots,
}: Omit<PickTimePopupProps, 'open' | 'close'>) {
  const theme = useColorScheme();
  const [chosen, setChosen] = useState(chosenSlots);

  const dependencyMap = findDependencies(slots);
  const disabledDaytimes = getDisabledDaytimes(slots, chosen, dependencyMap);

  function selectDayTime(dayTime: string) {
    const adjacent = dependencyMap[dayTime];
    const itemsToAdd =
      !adjacent || !adjacent[0] || adjacent.some((a) => chosen.includes(a))
        ? [dayTime]
        : [dayTime, adjacent[0]];

    setChosen([...chosen, ...itemsToAdd]);
  }

  function deselectDayTime(dayTime: string, slot: DayTimeSlot) {
    const itemsToRemove = getDayTimesToRemove(dayTime, chosen, dependencyMap);
    // we want to check if there is any gap left after removal
    const chosenSorted = chosen
      .map((s) => [s, slots[s]] as const)
      .sort(([, a], [, b]) =>
        !a
          ? -1
          : !b
            ? 1
            : new Date(a.dropoff).getTime() - new Date(b.dropoff).getTime()
      );

    const newChosenWithIndexes = chosen
      .filter((item) => !itemsToRemove.includes(item))
      .map((s) => chosenSorted.findIndex(([ss]) => ss === s))
      .sort();

    const continuousChosenIndexes = filterContinuousOnly(newChosenWithIndexes);

    const newChosen = continuousChosenIndexes
      .map((idx) => chosenSorted[idx]!)
      .map(([ss]) => ss);

    setChosen(newChosen);
  }

  return (
    <>
      <TextBody style={styles.date} testID="pick-times-date">
        {dateString}
      </TextBody>

      {Object.entries(slots)
        .sort(([keyA, slotA], [keyB, slotB]) => {
          if (keyA === 'fullDay') return -1;
          else if (keyB === 'fullDay') return 1;
          else
            return new Date(slotA.dropoff) > new Date(slotB.dropoff) ? 1 : -1;
        })
        .map(([dayTime, slot], idx, arr) => {
          const isChosen = chosen.includes(dayTime);
          const isDisabled = disabledDaytimes.includes(dayTime);

          const onPress = () => {
            if (isDisabled) {
              return;
            }
            if (isChosen) {
              deselectDayTime(dayTime, slot);
            } else {
              selectDayTime(dayTime);
            }
          };

          return [
            dayTime,
            slot,
            {
              onPress,
              isDisabled,
              isChosen,
            },
          ] as const;
        })
        .sort(([keyA, slotA], [keyB, slotB]) => {
          if (new Date(slotA.dropoff) > new Date(slotB.dropoff)) {
            return 1;
          }
          if (new Date(slotA.dropoff) < new Date(slotB.dropoff)) {
            return -1;
          }

          if (keyA === 'fullDay') return -1;
          else if (keyB === 'fullDay') return 1;
          else return -1;
        })
        .map(([dayTime, slot, props]) => {
          const { dropoff, pickup } = slot;
          return (
            <Pressable
              key={dayTime}
              {...props}
              style={[
                styles.slot,
                { backgroundColor: Colors[theme].backgroundSecondary },
                props.isChosen && { backgroundColor: Colors[theme].accent },
                props.isDisabled && {
                  backgroundColor: Colors[theme].lines,
                  cursor: 'auto',
                },
              ]}
              testID={`session-${dayTime}`}
            >
              {FeatureFlags.NAMED_SESSION_TIMES ? (
                <TransparentRow style={styles.dayTime}>
                  <TextTag>{dayTime}</TextTag>
                  <TextCta
                    style={[
                      props.isDisabled && [
                        styles.disabled,
                        { color: Colors[theme].textSecondary },
                      ],
                    ]}
                  >
                    {`${formatDateHoursRange(dropoff, pickup)}`}
                  </TextCta>
                </TransparentRow>
              ) : (
                <TextCta
                  style={[
                    props.isDisabled && [
                      styles.disabled,
                      { color: Colors[theme].textSecondary },
                    ],
                  ]}
                  testID="time-range"
                >
                  {`${formatDateHoursRange(dropoff, pickup)}`}
                </TextCta>
              )}
            </Pressable>
          );
        })}
      <PrimaryButton
        testID="add-sessions"
        style={{ alignSelf: 'center' }}
        title="Add"
        disabled={!chosen.length}
        onPress={() => {
          onAdd(chosen);
        }}
      />
    </>
  );
}

export default function PickTimePopup({
  open,
  close,
  onAdd,
  dateString,
  slots,
  chosenSlots,
}: PickTimePopupProps) {
  const theme = useColorScheme();
  const ref = useRef<PopupActions | null>(null);

  const closeMenu = () => ref?.current?.close();

  useEffect(() => {
    window.addEventListener('scroll', closeMenu);

    return () => window.removeEventListener('scroll', closeMenu);
  }, []);

  return (
    <Popup
      ref={ref}
      open={open}
      contentStyle={{ zIndex: 10000 }}
      onClose={close}
      trigger={<View />}
      arrow={false}
      offsetY={-80}
    >
      <View
        style={[
          styles.container,
          {
            shadowRadius: 12,
            shadowOpacity: 0.8,
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowColor: Colors[theme].shadow,
          },
        ]}
      >
        <Pressable onPress={close} style={styles.modalClose}>
          <Icon name="close" color={Colors[theme].text} />
        </Pressable>
        <TextH3>Pick Times</TextH3>
        <PickTimePopupContent
          onAdd={(dayTimes) => {
            onAdd(dayTimes);
            close();
          }}
          {...{
            dateString,
            slots,
            chosenSlots,
          }}
        />
      </View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 30,
    paddingVertical: 40,
    paddingHorizontal: 25,
    shadowOpacity: 0.8,
    shadowRadius: 12,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    alignItems: 'center',
  },
  disabled: {
    textDecorationLine: 'line-through',
  },
  row: {
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  slot: {
    borderRadius: 5,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // width: 285,
    height: 30,
  },
  body: {
    marginTop: 55,
    marginHorizontal: 25,
    marginBottom: 60,
  },
  date: {
    marginTop: 5,
    marginBottom: 25,
    textAlign: 'center',
  },
  modalClose: {
    position: 'absolute',
    top: 15,
    right: 25,
  },
  dayTime: {
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    paddingHorizontal: 16,
  },
});
