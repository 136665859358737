import { useNavigation } from '@react-navigation/native';
import { GestureResponderEvent, StyleSheet } from 'react-native';

import { LocationCtaButtonVertical } from './Button';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

interface SeeLocationButtonProps {
  locationNameId: string;
  title?: string;
}

export default function SeeLocationButton({
  locationNameId,
  title,
}: SeeLocationButtonProps) {
  const { navigate } = useNavigation();
  const theme = useColorScheme();

  const handlePress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('Location', { nameId: locationNameId });
  };

  return (
    <LocationCtaButtonVertical
      onPress={handlePress}
      style={[styles.button, { backgroundColor: Colors[theme].accent }]}
      top={title || 'See all'}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    // marginTop: 5,
    // marginRight: 5
  },
});
