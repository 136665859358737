import { Image } from 'react-native';

import PackageItem from './PackageItem';
import Links from '../../constants/Links';
import useBumoCreditPackagesList from '../../hooks/useBumoCreditPackagesList';
import { handleLink } from '../../utils/common';
import { TextButton } from '../Button';
import { InnerContainer } from '../Layout';
import Row, { TransparentRow } from '../Row';
import { TextH2, TextCtaButton } from '../StyledText';

export default function Packages({
  fullWidth,
  children,
  source,
  ItemsWrapper = PackagesWrapper,
  onLinkFetched,
}: {
  source: 'landing' | 'list';
  fullWidth?: boolean;
  children?: React.ReactNode;
  ItemsWrapper?: ({
    children,
  }: {
    children: React.ReactNode;
  }) => React.JSX.Element;
  onLinkFetched: (url: string) => void;
}) {
  const packages = useBumoCreditPackagesList();
  const isLoading = packages.length === 0;

  return (
    <InnerContainer
      style={{ paddingVertical: 50, maxWidth: fullWidth ? 1600 : 1200 }}
    >
      <TextH2 style={{ textAlign: 'center', marginBottom: 20 }}>
        The Packages
      </TextH2>
      <ItemsWrapper>
        {packages.map((p, idx) => (
          <PackageItem
            bumoPackage={p}
            key={idx}
            source={source}
            isLoading={isLoading}
            onLinkFetched={onLinkFetched}
          />
        ))}
      </ItemsWrapper>
      <Row
        style={{
          paddingHorizontal: 20,
          flexWrap: 'wrap',
          alignItems: 'flex-start',
        }}
      >
        <TextCtaButton
          style={{ textAlign: 'center', lineHeight: 24 }}
          forceStyles
        >
          Payment plans offered through{' '}
        </TextCtaButton>
        <Image
          source={require('../../assets/images/affirm.png')}
          style={{ width: 50.496, height: 20, marginHorizontal: 4 }}
        />
        <TextCtaButton style={{ textAlign: 'center' }}>and </TextCtaButton>
        <TextCtaButton style={{ textAlign: 'center' }}>
          <Image
            source={require('../../assets/images/klarna.png')}
            style={{
              width: 43.146,
              height: 24,
            }}
          />
        </TextCtaButton>
      </Row>
      <TextButton
        onPress={() => handleLink(Links.bumoCreditTerms, true)}
        style={{ textAlign: 'center', alignSelf: 'center', marginTop: 10 }}
      >
        View Terms and Conditions
      </TextButton>
      {children}
    </InnerContainer>
  );
}
function PackagesWrapper({ children }: { children: React.ReactNode }) {
  return (
    <TransparentRow
      style={[
        {
          padding: 20,
          paddingTop: 50,
          paddingBottom: 50,
          marginBottom: 20,
          gap: 30,
          width: '100%',
          alignItems: 'flex-start',
          overflow: 'hidden',
          flexWrap: 'wrap',
          rowGap: 70,
          justifyContent: 'center',
        },
      ]}
    >
      {children}
    </TransparentRow>
  );
}
