.shadowed-scroller {
  background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	
	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.react-calendar {
  max-width: 100%;
  font-family: 'Mont';
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.range-calendar .react-calendar__navigation {
  position: relative;
}
.range-calendar .react-calendar__navigation button:disabled {
  opacity: 0;
}
.range-calendar .react-calendar__navigation::before {
  content: attr(data-current-month-title);
  font-size: 20px;
  line-height: 25px;
  font-family: Mont;
  font-weight: bold;
  display: block;
  position: absolute;
  width: calc(50% - 55px);
  left: 44px;
  text-align: center;
  height: 25px;
}
.range-calendar .react-calendar__navigation::after {
  content: attr(data-next-month-title);
  font-size: 20px;
  line-height: 25px;
  font-family: Mont;
  font-weight: bold;

  display: block;
  position: absolute;
  right: 44px;
  width: calc(50% - 55px);
  text-align: center;
  height: 25px;
}


.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: 'Mont';
  color: #2E5394;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar button:focus {
  background-color: #D0E5CC;
}

.react-calendar__navigation {
  display: flex;
  height: 25px;
  margin-bottom: 15px;
}

.react-calendar__navigation button {
  /* min-width: 44px; */
  background: none;
  font-family: 'Mont';
  color: #000;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.react-calendar__navigation button:disabled {
  background-color: #fff;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-size: 16px;
  height: 25px;
}
.react-calendar--vertical .react-calendar__month-view__weekdays {
  display: none!important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  border: none;
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #898279;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  /* padding: 10px 6.6667px; */
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  color: rgb(137, 130, 121, 0.4);
  text-decoration: line-through;
}

.react-calendar__tile:focus,
.react-calendar__tile:enabled:hover {
  background-color: #D0E5CC;
}

@media all and (max-width: 600px) {
  .react-calendar__tile--hover {
    background-color: transparent!important;
  }
}

.react-calendar__tile--selected,
.react-calendar__tile--chosen {
  background: #F8C1D9;
  color: #000;
}

.react-calendar__tile--hidden {
  visibility: hidden;
}


.tps-calendar .react-calendar__tile--rangeStart, .tps-calendar .react-calendar__tile--rangeEnd {
  position: relative;
  background-color: transparent;
}
.tps-calendar .react-calendar__tile--rangeStart::before, .tps-calendar .react-calendar__tile--rangeEnd::before {
  content: '';
  background: #F8C1D9;
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
}
.tps-calendar .react-calendar__tile--rangeBothEnds::before {
  background-color: transparent!important;
}

.tps-calendar .react-calendar__tile--rangeStart::before {
  right: 0;
  left: auto;
}
.tps-calendar .react-calendar__tile--rangeStart abbr, .tps-calendar .react-calendar__tile--rangeEnd abbr {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #000;
  color: #fff!important;
}

.react-calendar__tile {
  height: 35px;
  line-height: 35px;
  border: 3px solid transparent!important;
  border-width: 3px 0!important;
}

.week-calendar .react-calendar__tile {
  border-color: #fff!important;
}

.week-calendar .react-calendar__tile--week-item, .week-calendar .react-calendar__tile[data-week_item] {
  background-color: #fff5e7;
}
.week-calendar .react-calendar__tile[data-week_start] {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.week-calendar .react-calendar__tile[data-week_end] {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.week-calendar .react-calendar__tile[data-week_item_disabled] {
  cursor: default!important;
}

.week-calendar .react-calendar__tile[data-week_item_unavailable] {
  text-decoration: line-through;
  color: rgb(137, 130, 121, 0.4);
}


.week-calendar .react-calendar__tile[data-weekid][data-week_item_hover] {
  background-color: #D0E5CC;
}

.week-calendar .react-calendar__tile[data-week_item_selected],
.week-calendar .react-calendar__tile[data-week_item_selected][data-week_item_hover] {
  background-color: #F8C1D9!important;
}
