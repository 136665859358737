import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { DateSlots } from '../../contexts/checkout';
import useAppNavigation from '../../hooks/useAppNavigation';
import useColorScheme from '../../hooks/useColorScheme';
import { Kid } from '../KidsInput';
import { TextFinePrint } from '../StyledText';
import TextWithLinks from '../TextWithLinks';
import { View } from '../Themed';

const ADD_KIDS_TEXT = 'You need to "add kids|0" before booking.';

const ADD_KIDS_LINKS = ['AddChild'];

export type BOOKING_CALENDAR_ERROR_STATUS =
  | 'EMPTY_KIDS'
  | 'NONE_ELIGIBLE'
  | 'SELECT_ONE'
  | 'CANNOT_ACCOMODATE_TRY_LESS'
  | 'CANNOT_ACCOMODATE'
  | null;

const cacheStatusGetter = (() => {
  let cached: BOOKING_CALENDAR_ERROR_STATUS = null;
  return (isLoaded: boolean, status: BOOKING_CALENDAR_ERROR_STATUS) => {
    if (!isLoaded) return cached;
    cached = status;
    return cached;
  };
})();

export function getCachedStatus({
  kids,
  isLoaded,
  dateSlots,
}: {
  isLoaded: boolean;
  kids: Kid[];
  dateSlots: DateSlots;
}) {
  const status =
    kids.length === 0
      ? ('EMPTY_KIDS' as const)
      : kids.length && kids.every((kid) => !kid.isEligible)
        ? ('NONE_ELIGIBLE' as const)
        : kids.length && !kids.some((kid) => kid.isChosen)
          ? ('SELECT_ONE' as const)
          : !Object.keys(dateSlots).length && kids.some((kid) => kid.isEligible)
            ? ('CANNOT_ACCOMODATE_TRY_LESS' as const)
            : kids.some((kid) => !kid.isEligible && kid.isChosen)
              ? ('CANNOT_ACCOMODATE' as const)
              : null;

  const cachedStatus = cacheStatusGetter(isLoaded, status);
  return cachedStatus;
}

export function ErrorMessageRenderer({
  status,
}: {
  status: BOOKING_CALENDAR_ERROR_STATUS;
}) {
  const theme = useColorScheme();
  const { navigate } = useAppNavigation();

  switch (status) {
    case 'EMPTY_KIDS':
      return (
        <View style={styles.textRow}>
          <TextWithLinks
            text={ADD_KIDS_TEXT}
            links={ADD_KIDS_LINKS}
            textStyle={[styles.finePrint, { color: Colors[theme].error }]}
            linkTextStyle={[
              styles.linkText,
              { fontFamily: 'Mont', fontWeight: 600 },
            ]}
            linkComponent={TextFinePrint}
            textComponent={TextFinePrint}
            clickFn={navigate}
          />
        </View>
      );
    case 'NONE_ELIGIBLE':
      return (
        <TextFinePrint
          style={[styles.finePrint, { color: Colors[theme].error }]}
        >
          None of your children can be accommodated at this location.
        </TextFinePrint>
      );
    case 'SELECT_ONE':
      return (
        <TextFinePrint
          style={[styles.finePrint, { color: Colors[theme].error }]}
        >
          You need at least one child selected.
        </TextFinePrint>
      );
    case 'CANNOT_ACCOMODATE_TRY_LESS':
      return (
        <TextFinePrint
          style={[styles.finePrint, { color: Colors[theme].error }]}
        >
          We cannot accommodate all selected children at once at this location.
          Try finding care for less children.
        </TextFinePrint>
      );
    case 'CANNOT_ACCOMODATE':
      return (
        <TextFinePrint
          style={[styles.finePrint, { color: Colors[theme].error }]}
        >
          We cannot accommodate all selected children at this location.
        </TextFinePrint>
      );
    default:
      return null;
  }
}

const styles = StyleSheet.create({
  textRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  finePrint: {},
  linkText: {
    textDecorationLine: 'underline',
  },
});
