import React from 'react';
import { Platform, Pressable, PressableProps, StyleSheet } from 'react-native';

import { TransparentRow } from './Row';
import { TextBody } from './StyledText';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type TabItemProps = {
  children: string;
  isActive?: boolean;
  onPress?: () => void;
  isLastItem?: boolean;
  activeBorderColor?: string;
};

export default function Tabs({
  children,
  active,
  onChange,
  style,
  innerStyles,
  fullWidth,
}: {
  children:
    | (React.ReactElement<TabItemProps> | null)[]
    | React.ReactElement<TabItemProps>;
  active?: number;
  onChange?: (v: number) => void;
  style?: StyleProps['style'];
  innerStyles?: StyleProps['style'];
  fullWidth?: boolean;
}) {
  const theme = useColorScheme();
  return (
    <TransparentRow style={[styles.tabs, style]}>
      <TransparentRow
        style={[
          {
            borderBottomColor: Colors[theme].lines,
          },
          styles.tabsInner,
          fullWidth && {
            minWidth: '100%',
          },
          innerStyles,
        ]}
      >
        {React.Children.map(children, (child, idx) =>
          child
            ? React.cloneElement(child, {
                isActive:
                  child.props.isActive !== undefined
                    ? child.props.isActive
                    : active === idx,
                onPress: child.props.onPress
                  ? child.props.onPress
                  : () => onChange?.(idx),
                isLastItem: idx === React.Children.count(children) - 1,
              })
            : null
        )}
      </TransparentRow>
    </TransparentRow>
  );
}
export function TabItem({
  children,
  isActive,
  onPress,
  isLastItem,
  activeBorderColor,
  ...props
}: TabItemProps & PressableProps) {
  const theme = useColorScheme();
  const activeColor = activeBorderColor || Colors[theme].cta;
  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          borderBottomColor: isActive ? activeColor : 'transparent',
          borderBottomWidth: 5,
        },
        styles.tabItem,
        isLastItem && { marginRight: 0 },
      ]}
      {...props}
    >
      <TextBody style={{ flexWrap: 'nowrap' }}>{children}</TextBody>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  tabs: {
    overflow: 'scroll',
    width: '100%',
    minWidth: '100%',
    height: 40,
    justifyContent: 'flex-start',
    ...Platform.select({
      web: {
        scrollbarWidth: 'none',
      },
    }),
  },
  // @ts-expect-error
  tabsInner: {
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    ...Platform.select({
      web: {
        width: 'max-content',
      },
    }),
  },
  tabItem: {
    paddingVertical: 5,
    marginRight: 15,
  },
});
