import React, { Suspense } from 'react';
import { StyleSheet } from 'react-native';

import { TransparentRow } from './Row';
import { TextBody, TextH2, TextH3, TextH4Big } from './StyledText';
import { StyleProps, TextProps, TransparentView } from './Themed';
import { ResponsiveCarousel } from './explore/HowItWorks';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';
import { hexToRgba } from '../utils/common';

const NumberChildCare = React.lazy(
  () => import('../assets/images/bumo-number-child-care.svg')
);
const NumberHourlyRate = React.lazy(
  () => import('../assets/images/bumo-number-hourly-rate.svg')
);
const NumberProviders = React.lazy(
  () => import('../assets/images/bumo-number-providers.svg')
);

export default function BumoInNumbers() {
  return (
    <TransparentView style={{ paddingVertical: 50 }}>
      <TextH2 style={[styles.tc, { marginBottom: 40 }]}>Bumo in Numbers</TextH2>
      <MobileCarouselContainer>
        <NumberItem
          primaryText="100,000+"
          secondaryText="hrs of childcare booked"
          contentText="More than 100,000 hours of child care has been booked on the Bumo platform."
        >
          <NumberChildCare style={{ width: 80, height: 80 }} />
        </NumberItem>
        <NumberItem
          primaryText="2,400+"
          secondaryText="searchable locations"
          contentText="Parents can search from over 2,400 daycares, preschools, and school-age programs."
        >
          <NumberProviders style={{ width: 80.74, height: 80 }} />
        </NumberItem>
        <NumberItem
          primaryText="$14 - $35/hr"
          secondaryText="hourly rate range"
          contentText="Hourly rate at network locations range from $14 to $35, providing options for parents."
        >
          <NumberHourlyRate style={{ width: 86.11, height: 80 }} />
        </NumberItem>
      </MobileCarouselContainer>
    </TransparentView>
  );
}

export function MobileCarouselContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const isLargeDevice = useMinWidth(920);
  const shouldRenderCarousel = useMaxWidth(600);
  if (shouldRenderCarousel) {
    return (
      <ResponsiveCarousel
        dotsProps={{
          dotProps: { inactiveColor: '#D9D9D9' },
        }}
      >
        {children}
      </ResponsiveCarousel>
    );
  }
  return (
    <TransparentRow style={{ columnGap: isLargeDevice ? 40 : 20 }}>
      {children}
    </TransparentRow>
  );
}

export function NumberItem({
  children,
  primaryText,
  primaryTextComponent: PrimaryText = TextH4Big,
  secondaryText,
  contentText,
  style,
}: {
  children: React.ReactNode;
  primaryText: string;
  primaryTextComponent?: React.ComponentType<TextProps>;
  secondaryText: string | null;
  contentText: string;
  style?: StyleProps['style'];
}) {
  const theme = useColorScheme();

  return (
    <TransparentView
      style={[
        {
          backgroundColor: hexToRgba(Colors[theme].accentSecondary, 0.3),
          flex: 1,
        },
        styles.numberItemWrapper,
        style,
      ]}
    >
      <Suspense>{children}</Suspense>
      <PrimaryText
        bold
        style={[
          {
            fontWeight: '900',
            marginTop: 10,
            color: Colors[theme].accentTertiary,
          },
          styles.tc,
        ]}
      >
        {primaryText}
      </PrimaryText>
      {secondaryText ? (
        <TextH3 style={styles.tc}>{secondaryText}</TextH3>
      ) : null}
      <TextBody style={styles.numberItemContent}>{contentText}</TextBody>
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  tc: { textAlign: 'center' },
  numberItemWrapper: {
    flex: 1,
    height: 350,
    borderRadius: 10,
    paddingHorizontal: 8,
    paddingVertical: 40,
    alignItems: 'center',
  },
  numberItemContent: { marginTop: 16, textAlign: 'center' },
});
