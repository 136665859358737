import { DAYS, dateStringRangeToNumeric } from './date';
import { formatDateHoursRange } from './locations';
import { transformSlots } from './slots';
import { BookingSlot } from '../api/search';

export function groupSlots(slots: BookingSlot[]) {
  const slotsPerWeek: Record<string, BookingSlot[]> = {};
  const weekIdByDate: Record<string, string> = {};
  const weeksById: Record<string, WeekInfo> = {};

  slots.forEach((s) => {
    const slotAsDate = new Date(`${s.date} 00:00`);
    const weekday = slotAsDate.getDay();

    if ((ignoreDays as number[]).includes(weekday)) {
      return;
    }

    let weekId = weekIdByDate[s.date];
    let weekInfo: WeekInfo;

    if (!weekId) {
      weekInfo = getWeekInfo(s.date);
      weekId = weekInfo.weekId;

      weeksById[weekId] = weekInfo;
      weekInfo.weekDates.forEach((d) => (weekIdByDate[d] = weekInfo.weekId));
    }

    slotsPerWeek[weekId] ||= [];
    slotsPerWeek[weekId]?.push(s);
  });

  return {
    slotsPerWeek,
    weekIdByDate,
    weeksById,
  };
}

export type GroupedSlots = ReturnType<typeof groupSlots>;

const ignoreDays = [DAYS.sunday, DAYS.saturday];

export function getWeekInfo(dateString: string) {
  // Convert the input string to a Date object
  const inputDate = new Date(dateString);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = inputDate.getDay();

  // Calculate the difference between the current day and the first day of the week (Sunday)
  const diff = dayOfWeek - 0;

  // Calculate the first day of the week (Sunday)
  const firstDay = new Date(inputDate);
  firstDay.setDate(inputDate.getDate() - diff);

  // Calculate the last day of the week (Saturday)
  const lastDay = new Date(inputDate);
  lastDay.setDate(inputDate.getDate() + (6 - diff));

  // Format the dates to the desired string format
  const formattedFirstDay = formatDate(firstDay);
  const formattedLastDay = formatDate(lastDay);

  // Initialize an array to store the dates of the week
  const weekDates: string[] = [];

  // Populate the array with date strings for each day of the week
  // Monday - Friday
  for (let i = 1; i < 6; i++) {
    const currentDay = new Date(firstDay);
    currentDay.setDate(firstDay.getDate() + i);
    weekDates.push(formatDate(currentDay));
  }

  // Return the week information
  return {
    // weekNumber: getISOWeekNumber(inputDate),
    weekId: `${formattedFirstDay}:${formattedLastDay}`,
    weekStart: formattedFirstDay,
    weekEnd: formattedLastDay,
    weekDates,
  };
}

type WeekInfo = ReturnType<typeof getWeekInfo>;

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function prepareWeekEntries(groupSlots: GroupedSlots) {
  const weekEntries = Object.keys(groupSlots.weeksById).map((weekId) => {
    const slotsGroup = groupSlots.slotsPerWeek[weekId];
    const slots = slotsGroup ? transformSlots(slotsGroup, [], false) : {};

    const formattedDaysName = formatWeekDates(slots);

    const dateEntries = Object.keys(slots).sort();

    const first = dateEntries[0];
    if (!first) {
      return null;
    }

    const [slotName, bookingSlot] = Object.entries(slots[first]!)[0] || [];

    if (!bookingSlot || !slotName) {
      return null;
    }

    const { dropoff: firstHour, pickup: lastHour } = bookingSlot;

    const formattedHours = formatDateHoursRange(firstHour, lastHour)
      ?.toLowerCase()
      .replaceAll(' am', 'a')
      .replaceAll(' pm', 'p');

    return {
      id: weekId,
      entries: groupSlots.weeksById[weekId]!.weekDates,
      slots,
      name: formattedDaysName,
      previewHours: formattedHours,
      previewSlotName: slotName,
    };
  });

  return weekEntries.filter(notNull);
}

export type WeekEntry = ReturnType<typeof prepareWeekEntries>[number];

const notNull = <T>(el: T | null): el is T => el !== null;

export function formatWeekDates(slots: Record<string, any> = {}) {
  const dateEntries = Object.keys(slots).sort();

  const first = dateEntries[0];
  const last = dateEntries[dateEntries.length - 1];

  if (!first || !last) {
    return null;
  }

  const formattedDays = dateStringRangeToNumeric([first, last], {
    month: 'short',
    day: 'numeric',
  });
  return formattedDays;
}
