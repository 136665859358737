import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  TouchableOpacityProps,
  Platform,
  TextStyle,
  StyleProp,
  Pressable,
} from 'react-native';

import { Icon, IconName } from './Icon';
import { LoadingCircle } from './Loader';
import Row, { TransparentRow } from './Row';
import {
  TextCtaButton,
  TextCta,
  TextBodySmall,
  TextFinePrint,
  TextTag,
} from '../components/StyledText';
import { TransparentView, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ChildrenProps from '../types/ChildrenProps';

export type ButtonProps = TouchableOpacityProps & {
  title: string;
  loading?: boolean;
  loadingText?: string;
  textStyle?: TextStyle;
};

export const OPACITY = 0.6;
const DISABLED_OPACITY = 0.5;

export function PrimaryButton({
  title,
  style,
  loading,
  disabled,
  loadingText,
  ...otherProps
}: ButtonProps) {
  const theme = useColorScheme();

  return (
    <TouchableOpacity
      // TODO consider setting style last, after opacity
      style={[
        styles.button,
        { backgroundColor: Colors[theme].cta },
        style,
        disabled && { opacity: DISABLED_OPACITY },
      ]}
      activeOpacity={OPACITY}
      disabled={loading || disabled}
      accessibilityRole="button"
      {...otherProps}
    >
      {!loading ? (
        <TextCtaButton style={styles.text}>{title}</TextCtaButton>
      ) : (
        <TransparentRow>
          <LoadingCircle size={23} />
          {loadingText ? (
            <TextCtaButton style={[styles.text, { marginLeft: 15 }]}>
              {loadingText}
            </TextCtaButton>
          ) : null}
        </TransparentRow>
      )}
    </TouchableOpacity>
  );
}

export function SecondaryButton({ title, style, ...otherProps }: ButtonProps) {
  const theme = useColorScheme();

  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[
        styles.button,
        styles.secondary,
        { borderColor: Colors[theme].cta },
        style,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <TextCtaButton style={styles.text}>{title}</TextCtaButton>
    </TouchableOpacity>
  );
}

export function TertiaryButton({ title, style, ...otherProps }: ButtonProps) {
  const theme = useColorScheme();
  return (
    <TouchableOpacity
      style={[
        styles.tertiary,
        { backgroundColor: Colors[theme].accentTertiary },
        style,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <TextBodySmall
        bold
        style={[styles.text, { color: Colors[theme].background }]}
      >
        {title}
      </TextBodySmall>
    </TouchableOpacity>
  );
}

// deprecated
export function NavButton({ title, style, ...otherProps }: ButtonProps) {
  // TODO consider removing button styles to not override paddings in header
  return (
    <TouchableOpacity
      style={[
        styles.button,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
        style,
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <TextCtaButton style={styles.text}>{title}</TextCtaButton>
    </TouchableOpacity>
  );
}

export function LocationCtaButton({
  left,
  right,
  style,
  ...otherProps
}: TouchableOpacityProps & {
  left: string;
  right?: string;
}) {
  const theme = useColorScheme();
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[
        styles.button,
        { backgroundColor: Colors[theme].cta },
        styles.location,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
        style,
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <Row style={styles.locationInner}>
        <TextCta style={styles.text}>{left}</TextCta>
        {!!right && (
          <>
            <TextCta>{', '}</TextCta>
            <TextFinePrint style={styles.text}>{right}</TextFinePrint>
          </>
        )}
      </Row>
    </TouchableOpacity>
  );
}

export function LocationCtaButtonVertical({
  top,
  bottom,
  style,
  icon,
  ...otherProps
}: TouchableOpacityProps & { top: string; bottom?: string; icon?: IconName }) {
  const theme = useColorScheme();
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[
        styles.button,
        {
          backgroundColor: Colors[theme].cta,
        },
        styles.location,
        styles.locationVertical,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
        style,
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <TransparentRow>
        {icon ? (
          <Icon name={icon} style={{ width: 20, height: 20, marginRight: 5 }} />
        ) : null}
        <TransparentView>
          <TextCta style={[!icon && styles.text]}>{top}</TextCta>
          {bottom ? (
            <TextFinePrint style={[!icon && styles.text]}>
              {bottom}
            </TextFinePrint>
          ) : null}
        </TransparentView>
      </TransparentRow>
    </TouchableOpacity>
  );
}

export function UnderlinedButton({
  title,
  style,
  loading,
  textStyle,
  ...otherProps
}: ButtonProps) {
  const theme = useColorScheme();

  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[
        (otherProps.disabled || loading) && { opacity: DISABLED_OPACITY },
        style,
      ]}
      activeOpacity={OPACITY}
      {...otherProps}
    >
      <View
        style={[styles.underlinedBar, { borderBottomColor: Colors[theme].cta }]}
      >
        <TextCta style={[styles.text, textStyle]} forceStyles={!!textStyle}>
          {title}
        </TextCta>
      </View>
    </TouchableOpacity>
  );
}

export type IconButtonProps = TouchableOpacityProps & {
  icon: React.ReactNode;
  text: React.ReactNode;
};

export function IconButton({
  style,
  icon,
  text,
  ...otherProps
}: IconButtonProps) {
  return (
    <Pressable
      style={[
        styles.iconButton,
        style,
        otherProps.disabled && { opacity: DISABLED_OPACITY },
      ]}
      accessibilityRole="button"
      activeOpacity={OPACITY}
      {...otherProps}
    >
      {icon}
      {text}
    </Pressable>
  );
}

export function BackIconButton({ title, style, ...otherProps }: ButtonProps) {
  return (
    <IconButton
      style={style}
      icon={<Icon name="arrowBack" style={styles.icon} />}
      text={<TextCtaButton style={styles.text}>{title}</TextCtaButton>}
      {...otherProps}
    />
  );
}

type TextButtonProps = Omit<TouchableOpacityProps, 'style'> &
  ChildrenProps & {
    containerStyle?: TouchableOpacityProps['style'];
    style?: StyleProp<TextStyle>;
    textStyle?: StyleProp<TextStyle>;
    TextComponent?: React.ElementType;
  };

export function TextButton({
  containerStyle,
  style,
  children,
  textStyle,
  TextComponent = TextBodySmall,
  ...otherProps
}: TextButtonProps) {
  return (
    <TouchableOpacity
      style={containerStyle}
      activeOpacity={0.6}
      accessibilityRole="button"
      {...otherProps}
    >
      <TextComponent style={[styles.textButton, textStyle, style]}>
        {children}
      </TextComponent>
    </TouchableOpacity>
  );
}

export function TagButton({
  children,
  ...otherProps
}: TouchableOpacityProps & object) {
  return (
    <TouchableOpacity accessibilityRole="button" {...otherProps}>
      <TextTag style={styles.underline}>{children}</TextTag>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 100,
    paddingVertical: 14,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 40,
    ...Platform.select({
      native: {
        alignSelf: 'stretch',
      },
    }),
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  secondary: {
    borderWidth: 2,
  },
  tertiary: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 20,
  },
  icon: {
    marginHorizontal: 6,
    width: 25,
    height: 25,
    marginBottom: 3,
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textButton: {
    textDecorationLine: 'underline',
  },
  underlinedBar: {
    borderBottomWidth: 5,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
  location: {
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  locationVertical: {
    width: '100%',
    height: 35,
    paddingVertical: 2,
  },
  locationInner: {
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    backgroundColor: 'transparent',
  },
  underline: {
    textDecorationLine: 'underline',
  },
});
