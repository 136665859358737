import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Constants from 'expo-constants';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';

import { setDeviceInfo } from './api/deviceInfo';
import AccessibilityWidget from './components/AccessibilityWidget';
import GoogleMapLoader from './components/GoogleMapLoader';
import LoggedInActions from './components/LoggedInActions';
import { Splash } from './components/Splash';
import WelcomeModalPopup from './components/WelcomeModalPopup';
import SearchMobilePopup from './components/search/SearchMobilePopup';
import { AuthFlowProvider } from './contexts/authFlow';
import { AuthProvider, useAuthState } from './contexts/authentication';
import { CheckoutProvider } from './contexts/checkout';
import { HdyHauProvider } from './contexts/hdyhau';
import { LocationsProvider } from './contexts/locations';
import { SearchProvider } from './contexts/search';
import { SnackbarProvider } from './contexts/snackbar';
import ThreeParamSearchProvider from './contexts/threeparamsearch';
import { NavigationProvider } from './navigation';

import './utils/polyfills';

import '@expo/match-media';

import './css/calendar.css';
import './css/animations.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './css/bumo-carousel.css';
import './css/rating-input.css';
import './css/skeleton.css';

setDeviceInfo('web');

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (typeof window !== 'undefined') {
  // @ts-ignore
  window._frameTimestamp = null;
}

const rollbarConfig = {
  accessToken: Constants.expoConfig?.extra?.ROLLBAR_ACCESS_TOKEN,
  environment: Constants.expoConfig?.extra?.ENV,
};

const RollbarWrapper = ({ children }: { children: React.ReactNode }) =>
  process.env.NODE_ENV === 'development' ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <RollbarProvider config={rollbarConfig}>{children}</RollbarProvider>
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ErrorFallback({
  error,
  resetError,
}: {
  error: Error | null;
  resetError: () => void;
}) {
  console.error(error);
  resetError();
  return null;
}

function Content() {
  const { isLoading } = useAuthState();

  if (isLoading) {
    const [campaignKey, pageType, isLoggedIn] = window.ctx || [];
    return <Splash {...{ campaignKey, pageType, isLoggedIn }} />;
  }

  return (
    <>
      <LoggedInActions />
      <StatusBar />
      <GoogleMapLoader />
      <SearchMobilePopup />
      <WelcomeModalPopup />
      <AccessibilityWidget />
    </>
  );
}

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RollbarWrapper>
        <ErrorBoundary>
          <AuthProvider>
            <CheckoutProvider>
              <LocationsProvider>
                <SnackbarProvider>
                  <HdyHauProvider>
                    <SearchProvider>
                      <ThreeParamSearchProvider>
                        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
                          <AuthFlowProvider>
                            <NavigationProvider>
                              <Content />
                            </NavigationProvider>
                          </AuthFlowProvider>
                        </SafeAreaProvider>
                      </ThreeParamSearchProvider>
                    </SearchProvider>
                  </HdyHauProvider>
                </SnackbarProvider>
              </LocationsProvider>
            </CheckoutProvider>
          </AuthProvider>
        </ErrorBoundary>
      </RollbarWrapper>
    </QueryClientProvider>
  );
}
