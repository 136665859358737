// https://reactnavigation.org/docs/stack-navigator/#installation
import './lib/gesture-handler';

// https://github.com/expo/expo/issues/23570#issuecomment-1642555869
import '@expo/metro-runtime';
// can be removed with expo router

import MainApp from './MainApp';

export default () => <MainApp />;
