import { useLinkBuilder } from '@react-navigation/native';
import { useCallback } from 'react';

import useAppNavigation from './useAppNavigation';
import { useIsLoggedIn } from '../contexts/authentication';
import { handleLink } from '../utils/common';

export const getLocationUrl = (nameId: string, photos: boolean = false) => {
  const linkBuilder = useLinkBuilder();
  const link = linkBuilder('Location', { nameId, photos });
  return link;
};

export const useOpenLocationPDP = (nameId: string) => {
  const { push } = useAppNavigation();
  const isLoggedIn = useIsLoggedIn();
  const linkBuilder = useLinkBuilder();

  const openLink = useCallback(
    (
      openInNewTab: boolean = false,
      params: { photos?: true | undefined; weekly?: true | undefined } = {}
    ) => {
      const link = linkBuilder('Location', { nameId, ...params });
      if (!link) {
        return;
      }

      const callback = openInNewTab
        ? () => handleLink(link)
        : () => push('Location', { nameId, ...params });
      callback();
    },
    [isLoggedIn, nameId]
  );

  return openLink;
};
