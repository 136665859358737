import React from 'react';
import { DimensionValue, ImageBackground } from 'react-native';

import { PrimaryButton } from './Button';
import { TransparentRow } from './Row';
import { TextCtaButton, TextH3 } from './StyledText';
import { TransparentView } from './Themed';
import HeroWithImage from './explore/HeroWithImage';
import SummerCampsLogo from '../assets/images/summer-camps-logo.svg';
import { useOpenAvochatoAction } from '../hooks/useAvochato';
import { useMaxWidth } from '../hooks/useResponsive';
import { CdnImageName } from '../utils/common';

const imagesFilenames: CdnImageName[] = [
  'summer24-1.webp',
  'summer24-2.webp',
  'summer24-3.webp',
  'summer24-4.webp',
];

export default function SummerCampHeader() {
  const openAvochato = useOpenAvochatoAction();

  const isMobileDevice = useMaxWidth(600);

  const AddonsContainer = isMobileDevice ? TransparentRow : React.Fragment;
  return (
    <HeroWithImage
      mainTitle={'summer camp!'}
      altText={''}
      skipButton
      imageFileName={imagesFilenames}
      containerStyle={{ backgroundColor: 'transparent' }}
      content={
        <TransparentView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <AddonsContainer {...(isMobileDevice ? { style: { gap: 20 } } : {})}>
            <TransparentView
              style={
                !isMobileDevice && {
                  position: 'absolute',
                  top: 344,
                  left: 'calc(50% + 100px)' as DimensionValue,
                }
              }
            >
              <ImageBackground
                source={require('../assets/images/summer24-flasks.png')}
                imageStyle={{ resizeMode: 'contain' }}
                style={{
                  width: isMobileDevice ? 63 : 115,
                  height: isMobileDevice ? 60 : 80,
                }}
              />
            </TransparentView>
            <TransparentView
              style={
                !isMobileDevice && {
                  position: 'absolute',
                  top: 'calc(50% - 115px)' as DimensionValue,
                  left: 'calc(50% - 206px)' as DimensionValue,
                }
              }
            >
              <ImageBackground
                source={require('../assets/images/summer24-paint-brushes.png')}
                imageStyle={{ resizeMode: 'contain' }}
                style={{ width: 83, height: isMobileDevice ? 60 : 80 }}
              />
            </TransparentView>
          </AddonsContainer>

          <SummerCampsLogo
            style={[
              { maxWidth: 550, width: '100%' },
              !isMobileDevice && { marginBottom: 24 },
            ]}
          />

          <TextH3
            style={{ textAlign: 'center', marginBottom: 24, maxWidth: 518 }}
          >
            Summer care (2m to 5y) and drop-in camps (5y to 11y) are available.
            Book full-day or half-day sessions, by the week, for ages 2m to 11y.{' '}
          </TextH3>

          <TextCtaButton style={{ marginBottom: 12 }}>Need help?</TextCtaButton>
          <PrimaryButton title="Text Us" onPress={openAvochato} />
        </TransparentView>
      }
      hideAddons
    />
  );
}
