// key created under mateusz@bumo.com account
const siteKey = '6LcJ-KYpAAAAAFwA2oo0sFT88E0SSGdE7Napf18L';

let loaded = false;
export function loadRecaptchaScript() {
  if (loaded) {
    return;
  }

  const script = document.createElement('script');
  script.src =
    'https://www.google.com/recaptcha/api.js?render=6LcJ-KYpAAAAAFwA2oo0sFT88E0SSGdE7Napf18L';
  script.async = true;
  script.defer = true;
  script.onload = function () {
    loaded = true;
  };

  document.body.appendChild(script);
}

export function runRecaptchaSubmit() {
  return new Promise<string>((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action: 'submit' })
        .then((token) => resolve(token))
        .catch(reject);
    });
  });
}
