import { API_V1_URL } from './config';
import { defaultRequest, getAuthedRequestHeaders } from './headers';

const EMPLOYER_LEAD_V1_URL = `${API_V1_URL}leads/employer`;

export async function postEmployerLead(body: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  companySize: string;
  captcha: string;
}) {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    body: JSON.stringify({
      first_name: body.firstName,
      last_name: body.lastName,
      email: body.email,
      telephone: body.phone,
      company_name: body.companyName,
      company_size: body.companySize,
      captcha: body.captcha,
    }),
  };

  const response = await fetch(EMPLOYER_LEAD_V1_URL, request);
  if (response.ok) {
    return { ok: true };
  }
  return {
    ok: false,
    body: (await response.json()) as {
      errors: Partial<{
        first_name: string[];
        last_name: string[];
        email: string[];
        telephone: string[];
        company_name: string[];
        company_size: string[];
        captcha: string[];
      }>;
    },
  };
}
