import { useEffect } from 'react';

import Links from '../../constants/Links';

export function AccessibeWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
    script.async = true;
    script.onload = function () {
      window.acsbJS.init({
        statementLink: Links.accessibility,
        footerHtml: '',
        hideMobile: true,
        hideTrigger: true,
        disableBgProcess: false,
        language: 'en',
        position: 'right',
        leadColor: '#2e5394',
        triggerColor: '#2e5394',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'bottom',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: '20',
        },
      });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
