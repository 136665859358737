import { useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { Parent } from '../../utils/api';
import { referralLinkPrefix } from '../../utils/referral';
import { PrimaryButton } from '../Button';
import { TransparentRow } from '../Row';
import { ShareReferralLinks } from '../ShareReferralLinks';
import { TextBodySmall, TextH3 } from '../StyledText';
import { TransparentView, View } from '../Themed';

export default function OnboardingBonusModal({
  onSuccess,
  style,
  parent,
  onLinkCopied,
  onShareCodePress,
}: {
  onSuccess: () => void;
  style?: ViewStyle;
  parent?: Parent;
  onLinkCopied?: () => void;
  onShareCodePress?: (link: string) => void;
}) {
  const theme = useColorScheme();
  const [showingShareLinks, setShowingShareLinks] = useState(false);

  const isMobileDevice = useMaxWidth(560);
  if (!parent) {
    return null;
  }

  const { referralCode } = parent;

  const title = 'BUMO Give $25 get $25';
  const referralLink = `${referralLinkPrefix}${referralCode}`;

  const shareCode = onShareCodePress
    ? () => onShareCodePress(referralLink)
    : () => setShowingShareLinks(true);

  const content = showingShareLinks ? (
    <>
      <TextH3 style={styles.title}>Share Invite Links</TextH3>
      <ShareReferralLinks
        link={referralLink}
        title={title}
        onLinkCopied={onLinkCopied}
      />
      <PrimaryButton
        title="Done"
        style={{ width: '50%', marginTop: 40 }}
        onPress={onSuccess}
      />
    </>
  ) : (
    <>
      <TextH3 style={styles.title}>
        One last thing! Give $25 and Get $25 in child care credit when you
        invite friends.
      </TextH3>
      <TextBodySmall
        style={[
          styles.title,
          {
            color: Colors[theme].textLowContrast,
          },
        ]}
      >
        Invite your friends and give them $25 and you get $25 in BumoCredit
        which you each can use to book child care on Bumo.
      </TextBodySmall>
      <View
        style={{
          width: '100%',
          height: 65,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: Colors[theme].lines,
          justifyContent: 'center',
          padding: 15,
        }}
      >
        <TextBodySmall>{referralLink}</TextBodySmall>
      </View>

      <TransparentRow
        style={[
          { width: '100%', gap: 20, paddingTop: 50 },
          isMobileDevice && {
            flexDirection: 'column-reverse',
          },
        ]}
      >
        <PrimaryButton
          title="Skip"
          style={{
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].cta,
            borderWidth: 2,
            flex: 1,
          }}
          onPress={onSuccess}
          testID="skip-button"
        />
        <PrimaryButton
          testID="share-button"
          title="Share Invite Link"
          style={{ flex: 1 }}
          onPress={shareCode}
        />
      </TransparentRow>
    </>
  );

  return (
    <TransparentView
      style={[styles.container, style]}
      testID="onboarding-referral-modal"
    >
      {content}
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    paddingBottom: 18,
    textAlign: 'center',
    alignSelf: 'center',
  },
});
