import { useState, useEffect } from 'react';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

type PositionState = Pick<
  GeolocationPosition['coords'],
  'latitude' | 'longitude' | 'accuracy' | 'speed' | 'heading'
> &
  Pick<GeolocationPosition, 'timestamp'>;

export const useGeolocation = (watch = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings,
  };

  const [position, setPosition] = useState<null | PositionState>(null);
  const [error, setError] = useState<string | null>(null);

  const onChange: PositionCallback = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError: PositionErrorCallback = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    if (watch) {
      const watcher = navigator.geolocation.watchPosition(
        onChange,
        onError,
        settings
      );
      return () => navigator.geolocation.clearWatch(watcher);
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge]);

  return { ...position, error };
};

export const useGetGeolocation = () => {
  return () =>
    new Promise<GeolocationPosition>((resolve, reject) => {
      if (!navigator || !navigator.geolocation) {
        reject(new Error('Geolocation is not supported'));
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        () => reject(new Error('Unable to retrieve location'))
      );
    });
};
