import { Platform, StyleSheet } from 'react-native';

import { Text, TextProps } from './Themed';
import Layout from '../constants/Layout';

export function MontRegularText(props: TextProps) {
  return <Text {...props} style={[{ fontFamily: 'Mont' }, props.style]} />;
}

export function MontSemiBoldText(props: TextProps) {
  return (
    <Text
      {...props}
      style={[props.style, { fontFamily: 'Mont', fontWeight: '600' }]}
    />
  );
}

export function MontBoldText(props: TextProps) {
  return (
    <Text
      {...props}
      style={[props.style, { fontFamily: 'Mont', fontWeight: '700' }]}
    />
  );
}

export function QuincyRegularText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'QuincyCF' }]} />;
}

export function QuincyBoldText(props: TextProps) {
  return (
    <Text
      {...props}
      style={[props.style, { fontFamily: 'QuincyCF', fontWeight: 'bold' }]}
    />
  );
}

export function TextH1(props: TextProps) {
  return (
    <MontSemiBoldText
      accessibilityRole="header"
      {...props}
      style={[styles.h1, props.style]}
    />
  );
}

export function TextH2(props: TextProps) {
  return <MontSemiBoldText {...props} style={[props.style, styles.h2]} />;
}

export function TextH3(props: TextProps) {
  return <MontSemiBoldText {...props} style={[props.style, styles.h3]} />;
}

export function TextH4(props: TextProps) {
  return <QuincyRegularText {...props} style={[styles.h4, props.style]} />;
}

export function TextH4Big(props: TextProps) {
  return <QuincyRegularText {...props} style={[styles.h4big, props.style]} />;
}

export function TextCallout(props: TextProps) {
  return <QuincyRegularText {...props} style={[styles.callout, props.style]} />;
}

export function TextCtaButton({ forceStyles, ...props }: TextProps) {
  return (
    <MontSemiBoldText
      {...props}
      style={
        forceStyles
          ? [styles.ctaButton, props.style]
          : [props.style, styles.ctaButton]
      }
    />
  );
}

export function TextBody({ bold, ...props }: TextProps) {
  const TextComponent = bold ? QuincyBoldText : QuincyRegularText;
  return <TextComponent {...props} style={[props.style, styles.body]} />;
}

export function TextBodySmall({ bold, ...props }: TextProps) {
  const TextComponent = bold ? MontSemiBoldText : MontRegularText;
  return <TextComponent {...props} style={[styles.bodySmall, props.style]} />;
}

export function TextCta({ forceStyles, ...props }: TextProps) {
  return (
    <MontSemiBoldText
      {...props}
      style={
        forceStyles ? [styles.cta, props.style] : [props.style, styles.cta]
      }
    />
  );
}

export function TextFinePrint({ bold, forceStyles, ...props }: TextProps) {
  const TextComponent = bold ? MontSemiBoldText : MontRegularText;
  return (
    <TextComponent
      {...props}
      style={
        forceStyles
          ? [styles.finePrint, props.style]
          : [props.style, styles.finePrint]
      }
    />
  );
}

export function TextLargeTag(props: TextProps) {
  return <MontSemiBoldText {...props} style={[props.style, styles.largeTag]} />;
}

export function TextTag({ bold = true, forceStyles, ...props }: TextProps) {
  const TextComponent = bold ? MontSemiBoldText : MontRegularText;
  return (
    <TextComponent
      {...props}
      style={
        forceStyles ? [styles.tag, props.style] : [props.style, styles.tag]
      }
    />
  );
}

export function TextTagBold({ bold = true, ...props }: TextProps) {
  return <MontBoldText {...props} style={[props.style, styles.tag]} />;
}

const styles = StyleSheet.create({
  h1: {
    ...Platform.select({
      web: {
        fontSize: 45,
        lineHeight: 58,
        ...(Layout.isMobileDevice && {
          fontSize: 35,
          lineHeight: 49,
        }),
      },
      native: {
        fontSize: 35,
        lineHeight: 49,
      },
    }),
  },
  h2: {
    ...Platform.select({
      web: {
        fontSize: 35,
        lineHeight: 45,
        ...(Layout.isMobileDevice && {
          fontSize: 25,
          lineHeight: 32,
        }),
      },
      native: {
        fontSize: 25,
        lineHeight: 32,
      },
    }),
  },
  h3: {
    ...Platform.select({
      web: {
        fontSize: 25,
        lineHeight: 32.5,
        ...(Layout.isMobileDevice && {
          fontSize: 20,
          lineHeight: 26,
        }),
      },
      native: {
        fontSize: 20,
        lineHeight: 26,
      },
    }),
  },
  h4: {
    fontSize: 20,
    lineHeight: 26,
  },
  h4big: {
    ...Platform.select({
      web: {
        fontSize: 40,
        lineHeight: 52,
        ...(Layout.isMobileDevice && {
          fontSize: 30,
          lineHeight: 39,
        }),
      },
      native: {
        fontSize: 30,
        lineHeight: 39,
      },
    }),
  },
  callout: {
    fontSize: 25,
    lineHeight: 32,
  },
  ctaButton: {
    ...Platform.select({
      web: {
        fontSize: 18,
        lineHeight: 23.4,
        ...(Layout.isMobileDevice && {
          fontSize: 16,
          lineHeight: 20.8,
        }),
      },
      native: {
        fontSize: 16,
        lineHeight: 20.8,
      },
    }),
  },
  body: {
    ...Platform.select({
      web: {
        fontSize: 18,
        lineHeight: 23.4,
        ...(Layout.isMobileDevice && {
          fontSize: 16,
          lineHeight: 20.8,
        }),
      },
      native: {
        fontSize: 16,
        lineHeight: 20.8,
      },
    }),
  },
  bodySmall: {
    ...Platform.select({
      web: {
        fontSize: 15,
        lineHeight: 20,
        ...(Layout.isMobileDevice && {
          fontSize: 13,
          lineHeight: 17,
        }),
      },
      native: {
        fontSize: 13,
        lineHeight: 17,
      },
    }),
  },
  cta: {
    ...Platform.select({
      web: {
        fontSize: 13,
        lineHeight: 20,
        ...(Layout.isMobileDevice && {
          fontSize: 13,
          lineHeight: 17,
        }),
      },
      native: {
        fontSize: 13,
        lineHeight: 17,
      },
    }),
  },
  finePrint: {
    ...Platform.select({
      web: {
        fontSize: 12,
        lineHeight: 15,
        ...(Layout.isMobileDevice && {
          fontSize: 10,
          lineHeight: 13,
        }),
      },
      native: {
        fontSize: 10,
        lineHeight: 13,
      },
    }),
  },
  largeTag: {
    fontSize: 12,
    lineHeight: 13,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  tag: {
    fontSize: 10,
    lineHeight: 11,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
});
