import { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import BookingCalendar, { BookingCalendarProps } from './BookingCalendar';
import { Kid } from './KidsInput';
import { View } from './Themed';
import { WeekCalendarContainer } from './WeekCalendarContainer';
import { CurrentBookingMode } from './booking_calendar/CalendarTypeToggler';
import HeightProvider from './booking_calendar/HeightProvider';
import ScrollToMeIfSelected from './booking_calendar/ScrollToMeIfSelected';
import { useCheckoutState } from '../contexts/checkout';
import { useMinWidth } from '../hooks/useResponsive';
import { useUpdateKids } from '../hooks/useUpdateKids';

export default function BookingCalendarContainer({
  style,
  calendarHeight,
  ...props
}: Omit<BookingCalendarProps, 'kids' | 'onKidChosenChange'> & {
  calendarHeight?: number;
}) {
  const { kids, onKidChosenChange } = useKidsSync();

  return (
    <ScrollToMeIfSelected nameId={props.locationNameId} scroll={props.scroll}>
      <LargeDeviceAwareBookingCalendar style={style}>
        <HeightProvider height={calendarHeight}>
          <CurrentBookingMode>
            {(currentBookingMode) =>
              currentBookingMode === 'weekly' ? (
                <WeekCalendarContainer
                  {...props}
                  kids={kids}
                  onKidChosenChange={onKidChosenChange}
                />
              ) : (
                <BookingCalendar
                  {...props}
                  kids={kids}
                  onKidChosenChange={onKidChosenChange}
                />
              )
            }
          </CurrentBookingMode>
        </HeightProvider>
      </LargeDeviceAwareBookingCalendar>
    </ScrollToMeIfSelected>
  );
}

const useKidsSync = () => {
  const { kids: defaultKids } = useCheckoutState();
  const [kids, setKids] = useState<Kid[]>(defaultKids);
  const updateKids = useUpdateKids();

  // when user logs in, we change defaultKids in CheckoutState, and update local kids copy
  useEffect(() => {
    setKids(defaultKids);
  }, [defaultKids]);

  const onKidChosenChange = useCallback(
    (index: number) => {
      updateKids([
        ...kids.slice(0, index),
        { ...kids[index], isChosen: !kids[index]!.isChosen },
        ...kids.slice(index + 1),
      ] as Kid[]);
    },
    [kids, updateKids]
  );

  return {
    kids,
    onKidChosenChange,
    setKids,
  };
};

export const LargeDeviceAwareBookingCalendar = ({
  children,
  style,
}: Pick<BookingCalendarProps, 'style'> & { children: React.ReactNode }) => {
  const isLargeDevice = useMinWidth(431);

  return (
    <View
      style={[styles.container, isLargeDevice && styles.containerLarge, style]}
    >
      <View style={styles.calendar}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  containerLarge: {
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  calendar: {
    alignSelf: 'center',
    width: '100%',
    position: 'relative',
    flex: 1,
  },
});
