import { useCallback, useEffect, useState } from 'react';
import { ImageBackground, Platform, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { CSSTransition } from 'react-transition-group';

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
  UnderlinedButton,
} from './Button';
import { Image } from './Image';
import Logo from './Logo';
import ModalCloseButton from './ModalCloseButton';
import { PhoneNumberInput } from './PhoneInput';
import { TransparentRow } from './Row';
import { TextBodySmall, TextFinePrint, TextH2, TextH3 } from './StyledText';
import { FormInput } from './StyledTextInput';
import { TransparentView } from './Themed';
import { getLeadStatus, postParentLead } from '../api/parents';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import Links from '../constants/Links';
import { useAuthenticate, useCurrentAuthFlowState } from '../contexts/authFlow';
import { useIsLoggedIn } from '../contexts/authentication';
import { navigationRef } from '../hooks/useAppNavigation';
import useBlockBodyScroll from '../hooks/useBlockBodyScroll';
import useColorScheme from '../hooks/useColorScheme';
import { useForm } from '../hooks/useForm';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';
import { handleLink } from '../utils/common';

const welcomeModalDelay = 10 * 1000; // 10s

export function wasWelcomeModalShown() {
  return localStorage.getItem('welcome_modal_shown') !== null;
}

export function setWelcomeModalShownInLocalStorage() {
  if (wasWelcomeModalShown()) {
    return;
  }
  localStorage.setItem('welcome_modal_shown', Date.now().toString());
}

export default function WelcomeModalPopup() {
  const isLoggedIn = useIsLoggedIn();
  const [isOpen, setIsOpen] = useState(false);
  useBlockBodyScroll(isOpen);

  const currentAuthFlowState = useCurrentAuthFlowState();

  useEffect(() => {
    if (
      !FeatureFlags.WELCOME_MODAL ||
      new Date().toISOString() < '2024-10-28T07:00:00.000Z'
    ) {
      return;
    }
    if (isLoggedIn) {
      return;
    }
    // show after 10s if no entry in localstorage
    if (wasWelcomeModalShown()) {
      return;
    }
    if (isOpen) {
      return;
    }
    const timeoutId = setTimeout(() => {
      if (
        (navigationRef.isReady() &&
          navigationRef.getCurrentRoute()?.name === 'StartFreeAccount') ||
        navigationRef.getCurrentRoute()?.name === 'EmployerLead' ||
        navigationRef.getCurrentRoute()?.name === 'BumoCreditLanding' ||
        navigationRef.getCurrentRoute()?.name === 'BumoCreditPackagesList' ||
        currentAuthFlowState === 'login' ||
        currentAuthFlowState === 'joinNow' ||
        currentAuthFlowState === 'forgotPasswordJoin' ||
        currentAuthFlowState === 'forgotPasswordLogin' ||
        currentAuthFlowState === 'onboardingProfile' ||
        currentAuthFlowState === 'onboardingChildren'
      ) {
        return;
      }
      setIsOpen(true);
    }, welcomeModalDelay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoggedIn, isOpen, currentAuthFlowState]);

  const hide = useCallback(() => {
    setWelcomeModalShownInLocalStorage();
    setIsOpen(false);
  }, []);

  return (
    <Modal
      isVisible={isOpen}
      useNativeDriver
      onBackdropPress={hide}
      style={{ margin: 0, alignItems: 'center', justifyContent: 'center' }}
      hideModalContentWhileAnimating
      testID="welcome-modal-popup"
    >
      <WelcomeModalContent hide={hide} />
    </Modal>
  );
}

const STEPS = {
  EMAIL_NAME_FORM: 1,
  PHONE_FORM: 2,
  THANK_YOU: 3,
  GO_TO_SIGNUP: 5,
  GO_TO_LOGIN: 6,
} as const;

export function WelcomeModalContent({
  hide,
  initialStep = STEPS.EMAIL_NAME_FORM,
}: {
  hide: () => void;
  initialStep?: number;
}) {
  const theme = useColorScheme();

  const authenticate = useAuthenticate();

  const [leadToken, setLeadToken] = useState<string | undefined>(undefined);

  const goToLogin = () => {
    hide();
    authenticate('LOGIN');
  };
  const goToSignup = () => {
    if (!leadToken) {
      console.error('Missing leadToken');
    }
    hide();
    authenticate(undefined, { leadToken });
  };

  const [step, setStep] = useState<number>(initialStep);
  const showingImage = useMinWidth(650);

  const isSmallDevice = useMaxWidth(600);

  const { state, dispatch, validateFields } = useForm({
    name: '',
    email: '',
  });

  const [phoneInProgress, setPhoneInProgress] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const validateStep1 = () => {
    const { ok } = validateFields(['email', 'name']);
    if (!ok) {
      return;
    }
    getLeadStatus(state.values.email).then((leadStatus) => {
      if (leadStatus.status === 'empty') {
        setStep(STEPS.PHONE_FORM);
      } else if (leadStatus.status === 'active') {
        setLeadToken(leadStatus.token);
        setStep(STEPS.GO_TO_SIGNUP);
      } else if (
        leadStatus.status === 'claimed' ||
        leadStatus.status === 'registered'
      ) {
        setStep(STEPS.GO_TO_LOGIN);
      }
    });
  };

  const onContinueWithPhone = (telephone: string) => {
    setPhoneInProgress(true);
    const { email, name } = state.values;
    postParentLead({ email, name, telephone }).then((response) => {
      setPhoneInProgress(false);
      if (response.ok) {
        setLeadToken(response.body.token);
        setStep(STEPS.THANK_YOU);
        return;
      }
      if (response.body.errors.email) {
        dispatch({
          type: 'error',
          field: 'email',
          value: `Email ${response.body.errors.email[0]}`,
        });
        setStep(1);
        return;
      }
      console.error('Ops there was a problem');
    });
  };
  const onContinueWithoutPhone = () => {
    setInProgress(true);
    const { email, name } = state.values;

    postParentLead({ email, name }).then((response) => {
      setInProgress(false);
      if (response.ok) {
        setLeadToken(response.body.token);
        setStep(STEPS.THANK_YOU);
        return;
      }

      if (response.body.errors.email) {
        dispatch({
          type: 'error',
          field: 'email',
          value: `Email ${response.body.errors.email[0]}`,
        });
        setStep(1);
        return;
      }
      console.error('Ops there was a problem');
    });
  };

  return (
    <TransparentView
      style={[
        {
          backgroundColor: '#fff',
          maxWidth: 780,
          width: '100%',
          borderRadius: 20,
          overflow: 'hidden',
        },
        isSmallDevice && {
          height: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}
    >
      {Platform.OS === 'web' && (
        <Image
          style={[
            styles.star,
            { tintColor: Colors[theme].cta },
            showingImage && {
              zIndex: 10,
              left: 'auto',
              right: 80,
              top: -10,
            },
          ]}
          source={require('../assets/images/star.png')}
        />
      )}
      {Platform.OS === 'web' && (
        <Image
          style={[
            styles.spring,
            { tintColor: Colors[theme].accent },
            showingImage && styles.springDesktop,
          ]}
          source={require('../assets/images/spring.png')}
        />
      )}
      <ModalCloseButton
        absolute
        onPress={hide}
        style={styles.closeButton}
        testID="close-welcome-modal"
      />
      <AnimatedContainer visible={step === 1} animation="slide-right">
        <TransparentRow>
          <TransparentView
            style={[
              styles.content,
              {
                padding: 16,
              },
            ]}
          >
            <Logo color style={[styles.logo]} />
            <TextH2>Join Now</TextH2>
            <TextH3 style={{ textAlign: 'center' }}>
              and get $50 in BumoCredit*
            </TextH3>
            <TextBodySmall style={{ textAlign: 'center', marginVertical: 25 }}>
              When you create a new account, we'll add $50 in BumoCredit that
              you can use to start booking child care right away!
            </TextBodySmall>
            <FormInput
              testID="first-name"
              placeholder="First name"
              value={state.values.name}
              onChangeText={(value) =>
                dispatch({ type: 'field', field: 'name', value })
              }
              error={state.errors.name}
            />
            <FormInput
              testID="email"
              placeholder="Email"
              value={state.values.email}
              onChangeText={(value) =>
                dispatch({ type: 'field', field: 'email', value })
              }
              error={state.errors.email}
            />
            <PrimaryButton
              testID="continue"
              title="Continue"
              onPress={validateStep1}
              style={{ width: '100%' }}
            />
            <TextFinePrint
              style={{ marginTop: 8, color: Colors[theme].textSecondary }}
              bold
            >
              *Offer only applies to new accounts
            </TextFinePrint>
          </TransparentView>
          {showingImage ? (
            <TransparentView style={{ flex: 1, height: '100%' }}>
              <ImageBackground
                source={require('../assets/images/welcome-modal-step1.webp')}
                style={{ width: '100%', height: '100%' }}
                resizeMode="cover"
              />
            </TransparentView>
          ) : null}
        </TransparentRow>
      </AnimatedContainer>

      <AnimatedContainer visible={step > 1} animation="slide-left">
        <TransparentRow>
          <TransparentView style={styles.content}>
            <AnimatedContainer
              visible={step === STEPS.PHONE_FORM}
              animation="slide-right"
            >
              <Step2Form
                continueWithPhone={onContinueWithPhone}
                continueWithoutPhone={onContinueWithoutPhone}
                phoneInProgress={phoneInProgress}
                inProgress={inProgress}
              />
            </AnimatedContainer>
            <AnimatedContainer
              visible={step === STEPS.THANK_YOU}
              animation="slide-left"
            >
              <Step2Confirmation onConfirm={goToSignup} onSkip={hide} />
            </AnimatedContainer>
            <AnimatedContainer
              visible={step === STEPS.GO_TO_SIGNUP}
              animation="slide-left"
            >
              <GoToSignUp onConfirm={goToSignup} onSkip={hide} />
            </AnimatedContainer>
            <AnimatedContainer
              visible={step === STEPS.GO_TO_LOGIN}
              animation="slide-left"
            >
              <AlreadyLoggedin onConfirm={goToLogin} />
            </AnimatedContainer>
          </TransparentView>
          {showingImage ? (
            <TransparentView style={{ flex: 1, height: '100%' }}>
              <ImageBackground
                source={require('../assets/images/welcome-modal-step2.webp')}
                style={{ width: '100%', height: '100%' }}
                resizeMode="cover"
              />
            </TransparentView>
          ) : null}
        </TransparentRow>
      </AnimatedContainer>
    </TransparentView>
  );
}

function AnimatedContainer({
  visible,
  children,
  animation,
}: {
  children: React.ReactNode;
  visible: boolean;
  animation: 'slide-right' | 'slide-left';
}) {
  return (
    <TransparentView
      style={[
        { width: '100%', height: '100%', zIndex: 2, justifyContent: 'center' },
        !visible && { position: 'absolute', zIndex: 1 },
      ]}
    >
      <CSSTransition
        timeout={500}
        in={visible}
        classNames={animation}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransparentView>
  );
}

function Step2Form({
  continueWithPhone,
  continueWithoutPhone,
  inProgress,
  phoneInProgress,
}: {
  continueWithPhone: (number: string) => void;
  continueWithoutPhone: () => void;
  phoneInProgress: boolean;
  inProgress: boolean;
}) {
  const { state, dispatch, validateFields } = useForm({
    phone: '',
  });

  const onContinueWithPhonePress = () => {
    const { ok } = validateFields(['phone']);
    if (!ok) {
      return;
    }
    continueWithPhone(state.values.phone);
  };

  const onPhoneNumberChange = useCallback(
    (value: string) => dispatch({ type: 'field', field: 'phone', value }),
    []
  );

  return (
    <TransparentView
      style={{
        width: '100%',
        padding: 16,
      }}
    >
      <Logo color style={[styles.logo]} />
      <TextH2 style={{ textAlign: 'center' }}>Want SMS-only offers too?</TextH2>
      <TextBodySmall
        style={{
          textAlign: 'center',
          marginVertical: 20,
          marginHorizontal: 10,
        }}
      >
        Enter your number to get SMS-exclusive offers
      </TextBodySmall>
      <TransparentRow
        style={{ marginBottom: 46, height: 90, alignItems: 'flex-start' }}
      >
        <PhoneNumberInput
          value={state.values.phone}
          onChangeText={onPhoneNumberChange}
          error={state.errors.phone}
          placeholder="Phone Number"
          style={{ width: 'auto', margin: 0, flex: 1, borderTopRightRadius: 0 }}
          disabled={inProgress || phoneInProgress}
        />
        <TransparentView>
          <PrimaryButton
            title="Send"
            onPress={onContinueWithPhonePress}
            style={styles.sendPhoneButton}
            loading={phoneInProgress}
            disabled={phoneInProgress || inProgress}
          />
        </TransparentView>
      </TransparentRow>
      <TextBodySmall style={styles.termsText}>
        By submitting this form and signing up for texts, you consent to receive
        marketing text messages (e.g. promos, reminders) from Bumo at the number
        provided, including messages sent by autodialer. Consent is not a
        condition of purchase. Msg & data rates may apply. Msg frequency varies.
        Unsubscribe at any time by replying STOP or clicking the unsubscribe
        link (where available).{' '}
        <TextButton
          textStyle={styles.termsText}
          onPress={() => handleLink(Links.privacy)}
        >
          Privacy Policy
        </TextButton>{' '}
        &{' '}
        <TextButton
          textStyle={styles.termsText}
          onPress={() => handleLink(Links.toc)}
        >
          Terms of use
        </TextButton>{' '}
        apply.
      </TextBodySmall>
      <UnderlinedButton
        onPress={continueWithoutPhone}
        title="No, I Don't Want Texts"
        loading={inProgress}
        disabled={phoneInProgress || inProgress}
        style={{ marginTop: 20 }}
      />
    </TransparentView>
  );
}

function Step2Confirmation({
  onConfirm,
  onSkip,
}: {
  onConfirm: () => void;
  onSkip: () => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentView
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        padding: 40,
      }}
    >
      <Logo color style={[styles.logo]} />
      <TextH2 style={{ marginVertical: 8, textAlign: 'center' }}>
        Thank you for signing up
      </TextH2>
      <TextBodySmall style={{ marginBottom: 60, textAlign: 'center' }}>
        Create your account to claim $50 in BumoCredit which you can use to book
        on-demand child care right away!
      </TextBodySmall>
      <PrimaryButton
        title="Claim $50 in BumoCredit"
        style={{ marginBottom: 16 }}
        testID="sign-up"
        onPress={onConfirm}
      />
      <SecondaryButton
        title="Skip and Browse"
        style={{ marginBottom: 8 }}
        testID="skip-button"
        onPress={onSkip}
      />
      <TextFinePrint
        style={{ marginTop: 8, color: Colors[theme].textSecondary }}
        bold
      >
        If you skip and browse, you can still claim $50 in BumoCredit by
        clicking the link sent to your email.
      </TextFinePrint>
    </TransparentView>
  );
}

function GoToSignUp({
  onConfirm,
  onSkip,
}: {
  onConfirm: () => void;
  onSkip: () => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentView testID="go-to-sign-up" style={styles.step2Content}>
      <Logo color style={[styles.logo]} />
      <TextH3 style={{ marginBottom: 8, textAlign: 'center' }}>
        Looks like we already have your email
      </TextH3>
      <TextBodySmall style={{ marginBottom: 60, textAlign: 'center' }}>
        Create your account to claim $50 in BumoCredit which you can use to book
        on-demand child care right away!
      </TextBodySmall>
      <PrimaryButton
        title="Claim $50 in BumoCredit"
        style={{ marginBottom: 16 }}
        testID="sign-up"
        onPress={onConfirm}
      />
      <SecondaryButton
        title="Skip and Browse"
        style={{ marginBottom: 8 }}
        testID="skip-button"
        onPress={onSkip}
      />
      <TextFinePrint
        style={{ marginTop: 8, color: Colors[theme].textSecondary }}
        bold
      >
        If you skip and browse, you can still claim $50 in BumoCredit by
        clicking the link sent to your email.
      </TextFinePrint>
    </TransparentView>
  );
}

function AlreadyLoggedin({ onConfirm }: { onConfirm: () => void }) {
  return (
    <TransparentView testID="go-to-log-in" style={styles.step2Content}>
      <Logo color style={[styles.logo]} />
      <TextH3 style={{ marginBottom: 8, textAlign: 'center' }}>
        You already have an account with us
      </TextH3>
      <TextBodySmall style={{ marginBottom: 60, textAlign: 'center' }}>
        Book trusted child care with Bumo today!
      </TextBodySmall>
      <PrimaryButton
        title="Log In"
        style={{ marginBottom: 16 }}
        testID="log-in"
        onPress={onConfirm}
      />
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  sendPhoneButton: {
    height: '100%',
    marginLeft: 5,
    paddingHorizontal: 20,
    paddingLeft: 15,
    borderRadius: 10,
  },
  closeButton: {
    zIndex: 50,
    backgroundColor: '#ffffff66',
    borderRadius: 20,
  },
  content: {
    flex: 1,
    maxWidth: 500,
    height: 540,
    alignItems: 'center',
  },
  termsText: { fontSize: 10, lineHeight: 12 },
  step2Content: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    padding: 40,
  },
  star: {
    position: 'absolute',
    width: 80,
    height: 79,
    top: 100,
    left: 20,
  },
  spring: {
    position: 'absolute',
    width: 48,
    height: 138,
    right: 40,
    bottom: 70,
    transform: [
      {
        rotate: '46.69deg',
      },
    ],
  },
  springDesktop: {
    zIndex: 3,
    top: 'auto',
    left: 'auto',
    bottom: 0,
    right: 280,
    transform: [
      {
        rotate: '126.69deg',
      },
    ],
  },
  logo: {
    height: 40,
    width: 108,
    marginBottom: 24,
    alignSelf: 'center',
  },
});
