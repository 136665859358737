const deviceHeader: { 'X-Device': null | string } = {
  'X-Device': null,
};

export function setDeviceInfo(platformOS: null | string) {
  if (!platformOS) {
    deviceHeader['X-Device'] = null;
    return;
  }
  deviceHeader['X-Device'] = platformOS;
}

export function getDeviceHeader() {
  if (!deviceHeader['X-Device']) return {};
  return deviceHeader;
}
