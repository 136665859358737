import Checkbox from 'expo-checkbox';
import { createContext, useContext, useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { useSnackbarDispatch } from './snackbar';
import { postCustomerAcquisition } from '../api/customer';
import { PrimaryButton } from '../components/Button';
import { Icon } from '../components/Icon';
import Logo from '../components/Logo';
import ModalCloseButton from '../components/ModalCloseButton';
import { TransparentRow } from '../components/Row';
import {
  TextBodySmall,
  TextCtaButton,
  TextFinePrint,
  TextH3,
} from '../components/StyledText';
import { TextInput, View } from '../components/Themed';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';

const HdyHauContext = createContext<
  undefined | ((_sessionId?: string) => void)
>(undefined);

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 7 * 2;

export const HdyHauProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useColorScheme();
  const dispatchSnackbar = useSnackbarDispatch();

  const parent = useParent();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sessionId, setSessionId] = useState<null | string>(null);
  const hide = () => setIsModalVisible(false);
  const tryShowing = (_sessionId?: string) => {
    if (!FeatureFlags.HDYHAU) {
      return;
    }
    if (!_sessionId) {
      return;
    }

    const lastDateShown = localStorage.getItem('hdyhau_shown');
    const lastDateShownAsNum = Number(lastDateShown);
    const today = Date.now();
    const forcePeriodCheck = localStorage.getItem('hdyhau_threshold_seconds');
    const periodCheck =
      forcePeriodCheck === null || isNaN(Number(forcePeriodCheck))
        ? TWO_WEEKS
        : Number(forcePeriodCheck) * 1000;

    if (
      lastDateShown !== null &&
      lastDateShownAsNum &&
      today - lastDateShownAsNum < periodCheck
    ) {
      return;
    }
    localStorage.setItem('hdyhau_shown', Date.now().toString());
    setIsModalVisible(true);
    setSessionId(_sessionId);
  };

  const [formState, setFormState] = useState({
    google: false,
    instagram: false,
    facebook: false,
    family: false,
    other: false,
  });

  const [otherText, setOtherText] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!formState.other) {
      setOtherText('');
    }
  }, [formState.other]);

  useEffect(() => {
    setErrorMessage(undefined);
  }, [otherText]);

  const [sending, setSending] = useState(false);

  const trySubmit = async () => {
    if (!parent.id) {
      throw new Error('Missing parent id! This should not happen');
    }
    if (!sessionId) {
      throw new Error('Missing sessionId! This should not happen');
    }
    if (formState.other && otherText.trim().length === 0) {
      setErrorMessage('This text area is required when you select Other.');
      return;
    }

    if (formState.other && otherText.trim().length > 300) {
      setErrorMessage(
        'You are at 307 characters and maximum character count is 300. Please shorten your answer. '
      );
      return;
    }

    setSending(true);

    await postCustomerAcquisition(parent.id, sessionId, {
      sources: (
        ['google', 'instagram', 'facebook', 'family', 'other'] as const
      ).filter((s) => formState[s]),
      source: otherText.length > 0 ? otherText : undefined,
    });
    setSending(false);
    setIsModalVisible(false);

    dispatchSnackbar({
      message: (
        <TransparentRow style={{ width: '100%' }}>
          <Icon name="explore" style={{ marginRight: 10 }} />
          <TextBodySmall>Thank you for submitting your response!</TextBodySmall>
        </TransparentRow>
      ),
    });
  };

  const isMobileDevice = useMaxWidth(500);

  return (
    <HdyHauContext.Provider value={tryShowing}>
      {children}
      <Modal
        testID="hdyhau-modal-container"
        isVisible={isModalVisible}
        onBackdropPress={hide}
        style={{ margin: 0 }}
      >
        <View
          testID="hdyhau-modal"
          style={[
            styles.modalContainer,
            isMobileDevice && styles.modalContainerFullscreen,
          ]}
        >
          <ModalCloseButton
            onPress={hide}
            absolute
            testID="close-hdyhau-modal"
          />
          <Logo color style={{ marginBottom: 30, width: 110, height: 40 }} />
          <TextH3>How did you hear about us?</TextH3>
          <TextBodySmall style={{ marginBottom: 30 }}>
            Please check all that apply.
          </TextBodySmall>
          <Option
            label="Google"
            value={formState.google}
            onChange={() => setFormState((s) => ({ ...s, google: !s.google }))}
          />
          <Option
            label="Instagram"
            value={formState.instagram}
            onChange={() =>
              setFormState((s) => ({ ...s, instagram: !s.instagram }))
            }
          />
          <Option
            label="Facebook"
            value={formState.facebook}
            onChange={() =>
              setFormState((s) => ({ ...s, facebook: !s.facebook }))
            }
          />
          <Option
            label="Family/Friend"
            value={formState.family}
            onChange={() => setFormState((s) => ({ ...s, family: !s.family }))}
          />
          <Option
            label="Other"
            value={formState.other}
            onChange={() => setFormState((s) => ({ ...s, other: !s.other }))}
          />
          {formState.other ? (
            <>
              <TextInput
                testID="other-text"
                multiline
                style={{
                  // borderColor: Colors[theme].lines,
                  borderWidth: 1,
                  maxWidth: 300,
                  width: '100%',
                  height: 150,
                  padding: 10,
                  borderRadius: 10,
                  fontFamily: 'Mont',
                }}
                placeholder="Please specify where else you've heard about Bumo"
                onChangeText={setOtherText}
              />
              <TextFinePrint
                style={{ color: Colors[theme].error, minHeight: 20 }}
              >
                {errorMessage}
              </TextFinePrint>
            </>
          ) : null}
          <PrimaryButton
            disabled={!Object.values(formState).some((v) => !!v) || sending}
            loading={sending}
            title="Submit"
            testID="submit-button"
            onPress={trySubmit}
            style={{ maxWidth: 300, width: '100%', marginTop: 16 }}
          />
        </View>
      </Modal>
    </HdyHauContext.Provider>
  );
};

function Option({
  value,
  onChange,
  label,
}: {
  label: string;
  value: boolean;
  onChange: () => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentRow style={styles.optionItem}>
      <Checkbox
        color={Colors[theme].accentSecondary}
        style={styles.checkbox}
        value={value}
        onChange={onChange}
      />
      <Pressable onPress={onChange} testID={`${label.toLowerCase()}-option`}>
        <TextCtaButton>{label}</TextCtaButton>
      </Pressable>
    </TransparentRow>
  );
}

export const useHdyHau = () => {
  const show = useContext(HdyHauContext);
  if (!show) {
    console.warn('useHdyHau used outside HdyHauProvider.');
    return () => {};
  }
  return show;
};

const styles = StyleSheet.create({
  modalContainer: {
    width: 420,
    height: 600,
    alignSelf: 'center',
    padding: 30,
    alignItems: 'center',
    borderRadius: 10,
  },
  modalContainerFullscreen: {
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 0,
    justifyContent: 'center',
  },
  optionItem: {
    width: 300,
    justifyContent: 'flex-start',
    marginBottom: 12,
  },
  checkbox: {
    width: 30,
    height: 30,
    marginRight: 24,
  },
});
