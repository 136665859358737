import React, { Ref, forwardRef } from 'react';
import { StyleSheet, ViewProps, View as DefaultView } from 'react-native';

import { TransparentView, View } from './Themed';

type RowProps = ViewProps & {
  children?: React.ReactNode;
  style?: ViewProps['style'];
};

export default forwardRef<DefaultView, RowProps>(
  ({ children, style, ...otherProps }, ref) => {
    return (
      <View {...otherProps} style={[styles.row, style]} ref={ref}>
        {children}
      </View>
    );
  }
);

export const TransparentRow = forwardRef(
  ({ children, style, ...otherProps }: RowProps, ref: Ref<DefaultView>) => {
    return (
      <TransparentView {...otherProps} style={[styles.row, style]} ref={ref}>
        {children}
      </TransparentView>
    );
  }
);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
