import Checkbox from 'expo-checkbox';
import { useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, ViewStyle } from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import Colors from '../../constants/Colors';
import { useIsLoggedIn } from '../../contexts/authentication';
import {
  useSearchInputState,
  useUpdateSearchState,
} from '../../contexts/threeparamsearch';
import useColorScheme from '../../hooks/useColorScheme';
import { BottomSheetModalContainer } from '../BottomSheetModal';
import { Icon } from '../Icon';
import Row from '../Row';
import { TextBodySmall, TextH3 } from '../StyledText';
import { TransparentView, View } from '../Themed';

export default function KidsChooser({
  useSheetSelector,
}: {
  useSheetSelector?: boolean;
}) {
  const theme = useColorScheme();
  const isLoggedIn = useIsLoggedIn();
  const { who } = useSearchInputState();
  const { setInputState } = useUpdateSearchState();

  const setKids = (
    whoUpdate: {
      id: number;
      name: string;
      age: string | null;
      enabled: boolean;
    }[]
  ) => {
    setInputState({
      who: whoUpdate,
    });
  };

  const addNewChild = () => {
    const newId = Math.max(...who.map((k) => k.id)) + 1;

    setKids([
      ...who,
      {
        id: newId,
        name: `Child ${newId}`,
        age: null,
        enabled: false,
      },
    ]);
  };

  const updateAges = (idx: number, age: string) => {
    setKids(who.map((k, i) => (i === idx ? { ...k, age, enabled: true } : k)));
  };

  const updateEnabled = (idx: number, enabled: boolean) => {
    setKids(who.map((k, i) => (i === idx ? { ...k, enabled } : k)));
  };

  const removeKid = (idx: number) => {
    setKids(
      who.length === 1
        ? [
            {
              id: 1,
              name: `Child ${1}`,
              age: null,
              enabled: false,
            },
          ]
        : who.filter((k, i) => idx !== i)
    );
  };

  const AgeSelector = useSheetSelector ? SelectAgeSheet : SelectAge;

  return (
    <>
      {who.map((kid, idx) => (
        <Row
          key={kid.id}
          style={[
            styles.childSelectionContainer,
            { borderBottomColor: Colors[theme].lines, borderBottomWidth: 1 },
          ]}
        >
          <Row style={{ flex: 1, justifyContent: 'flex-start' }}>
            {!isLoggedIn ? (
              <Icon
                name="profile"
                color={Colors[theme].accent}
                style={{ marginRight: 10 }}
              />
            ) : (
              <Checkbox
                value={kid.enabled}
                color={Colors[theme].accent}
                // onChange={() => updateEnabled(idx, !kid.enabled)}
                onValueChange={(v) => updateEnabled(idx, v)}
                style={{ marginRight: 10, width: 30, height: 30 }}
              />
            )}
            <TextBodySmall>
              {isLoggedIn ? kid.name : `Child ${idx + 1}`}
            </TextBodySmall>
          </Row>
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            {isLoggedIn ? (
              <TextBodySmall>{kid.age}</TextBodySmall>
            ) : (
              <AgeSelector
                value={kid.age}
                onChange={(val: string) => updateAges(idx, val)}
              />
            )}
            {!isLoggedIn &&
            (who.length > 1 || (who.length === 1 && who[0]?.age)) ? (
              <Pressable onPress={() => removeKid(idx)}>
                <Icon name="removeCircle" style={{ width: 25, height: 25 }} />
              </Pressable>
            ) : null}
          </Row>
        </Row>
      ))}
      {isLoggedIn ? null : !isLoggedIn && who.length === 5 ? null : (
        <Pressable onPress={addNewChild}>
          <AddAnotherRow />
        </Pressable>
      )}
    </>
  );
}

// const renderAgeSelector = () => (
//   (
//     <AgeSelector
//       value={entry.age}
//       onChange={(val: string) => onAgeUpdate?.(idx, val)}
//     />
//   )
// )

export const KidsChooserContent = ({
  items,
  shouldShowCheckboxes,
  isAddRowVisible,
  isAgeEditable,
  onAddPress,
  renderAgeSelector,
  onCheckboxUpdate,
  shouldAllowRemoval,
  onRemove,
}: {
  items: {
    key: string | number;
    name: string;
    age: string;
    enabled: boolean;
  }[];
  isAddRowVisible: boolean;
  isAgeEditable: boolean;
  shouldShowCheckboxes: boolean;
  onAddPress?: () => void;
  onCheckboxUpdate: (idx: number, value: boolean) => void;
  shouldAllowRemoval: boolean;
  renderAgeSelector?: (entry: {
    key: string | number;
    name: string;
    age: string;
    enabled: boolean;
  }) => React.ReactNode;
  onRemove?: (idx: number) => void;
}) => {
  const theme = useColorScheme();
  return (
    <>
      {items.map((entry, idx) => (
        <Row
          key={entry.key}
          style={[
            styles.childSelectionContainer,
            { borderBottomColor: Colors[theme].lines, borderBottomWidth: 1 },
          ]}
        >
          <Row style={{ flex: 1, justifyContent: 'flex-start' }}>
            {!shouldShowCheckboxes ? (
              <Icon
                name="profile"
                color={Colors[theme].accent}
                style={{ marginRight: 10 }}
              />
            ) : (
              <Checkbox
                value={entry.enabled}
                color={Colors[theme].accent}
                onValueChange={(v) => onCheckboxUpdate(idx, v)}
                style={{ marginRight: 10, width: 30, height: 30 }}
              />
            )}
            <TextBodySmall>{entry.name}</TextBodySmall>
          </Row>
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            {!isAgeEditable ? (
              <TextBodySmall>{entry.age}</TextBodySmall>
            ) : (
              renderAgeSelector?.(entry)
            )}
            {shouldAllowRemoval ? (
              <Pressable onPress={() => onRemove?.(idx)}>
                <Icon name="removeCircle" style={{ width: 25, height: 25 }} />
              </Pressable>
            ) : null}
          </Row>
        </Row>
      ))}
      {isAddRowVisible ? (
        <Pressable onPress={onAddPress}>
          <AddAnotherRow />
        </Pressable>
      ) : null}
    </>
  );
};

const AddAnotherRow = () => {
  const theme = useColorScheme();
  return (
    <Row style={styles.childSelectionContainer}>
      <TransparentView
        style={{
          backgroundColor: Colors[theme].backgroundSecondary,
          borderRadius: 5,
          width: 30,
          height: 30,
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 10,
        }}
      >
        <Icon
          name="plus"
          style={{
            width: 22,
            height: 22,
          }}
        />
      </TransparentView>

      <TextBodySmall>Add another</TextBodySmall>
    </Row>
  );
};

/**
 *
0m up to 3m
3m+ up to 6m
6m+ up to 12m
12m+ up to 18m
18m+ up to 24m
24m+ up to 36m
3y+  up to 4y
4y+ up to 5y
5y+ up to 6y
6y+ up to 7y
7y+ up to 8y
8y+ up to 9y
9y+ up to 10y
10y+ up to 11y
 */

const ageOptions = [
  { display: '0m to 3m', value: [0, 3] as [number, number] },
  { display: '3m+ to 6m', value: [3, 6] as [number, number] },
  { display: '6m+ to 12m', value: [6, 12] as [number, number] },
  { display: '12m+ to 18m', value: [12, 18] as [number, number] },
  { display: '18m+ to 24m', value: [18, 24] as [number, number] },
  { display: '24m+ to 36m', value: [24, 36] as [number, number] },
  { display: '3y+ to 4y', value: [36, 48] as [number, number] },
  { display: '4y+ to 5y', value: [48, 60] as [number, number] },
  { display: '5y+ to 6y', value: [60, 72] as [number, number] },
  { display: '6y+ to 7y', value: [72, 84] as [number, number] },
  { display: '7y+ to 8y', value: [84, 96] as [number, number] },
  { display: '8y+ to 9y', value: [96, 108] as [number, number] },
  { display: '9y+ to 10y', value: [108, 120] as [number, number] },
  { display: '10y+ to 11y', value: [120, 132] as [number, number] },
];

export function mapToMonthsRange(value: string) {
  const v = ageOptions.find((ao) => ao.display === value);
  if (!v) {
    throw new Error('unknown age option');
  }
  return v.value;
}

function SelectAge({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (ages: string) => void;
}) {
  const theme = useColorScheme();
  const ref = useRef<PopupActions | null>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.close();
    }
  }, [value]);

  return (
    <Popup
      ref={ref}
      arrow={false}
      position="bottom left"
      offsetX={0}
      offsetY={-10}
      nested
      trigger={
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            //@ts-ignore
            cursor: 'pointer',
          }}
        >
          <TextBodySmall>{value ?? 'Select'}</TextBodySmall>
          <Icon name="arrowDown" />
        </View>
      }
    >
      <View
        style={[
          {
            borderRadius: 10,
            padding: 10,
            height: 200,
            width: 200,
            shadowColor: '#00000040',
            shadowRadius: 15,
          },
          // @ts-ignore
          {
            overflow: 'auto',
          },
        ]}
      >
        {ageOptions.map((age) => (
          <Pressable
            onPress={() => onChange(age.display)}
            key={age.display}
            style={{ outlineColor: 'transparent' } as ViewStyle}
          >
            <Row
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor:
                  age.display === value ? Colors[theme].accent : 'transparent',
              }}
            >
              <TextBodySmall>{age.display}</TextBodySmall>
              {age.display === value ? (
                <Icon name="check" style={styles.icon} />
              ) : null}
            </Row>
          </Pressable>
        ))}
      </View>
    </Popup>
  );
}

function SelectAgeSheet({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (ages: string) => void;
}) {
  const theme = useColorScheme();
  const [active, setActive] = useState(false);
  return (
    <>
      <Pressable onPress={() => setActive(true)}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextBodySmall>{value ?? 'Select'}</TextBodySmall>
          <Icon name="arrowDown" />
        </View>
      </Pressable>
      <BottomSheetModalContainer
        isVisible={active}
        onClose={() => setActive(false)}
        snapPoints={[500]}
      >
        <TransparentView>
          <TextH3 style={{ alignSelf: 'center', marginBottom: 10 }}>
            Select Age
          </TextH3>
          {ageOptions.map((age) => (
            <Pressable
              onPress={() => {
                onChange(age.display);
                setActive(false);
              }}
              key={age.display}
              style={{ outlineColor: 'transparent' } as ViewStyle}
            >
              <Row
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingHorizontal: 10,
                  height: 30,
                  backgroundColor:
                    age.display === value
                      ? Colors[theme].accent
                      : 'transparent',
                }}
              >
                <TextBodySmall>{age.display}</TextBodySmall>
                {age.display === value ? (
                  <Icon name="check" style={styles.icon} />
                ) : null}
              </Row>
            </Pressable>
          ))}
        </TransparentView>
      </BottomSheetModalContainer>
    </>
  );
}

const styles = StyleSheet.create({
  childSelectionContainer: {
    height: 50,
    justifyContent: 'flex-start',
  },
  icon: {
    width: 20,
    height: 20,
  },
});
