import { Platform, Pressable, StyleSheet } from 'react-native';

import { Icon } from './Icon';
import Row from './Row';
import { TextBody, TextH3 } from './StyledText';
import { StyleProps, View } from './Themed';
import Layout from '../constants/Layout';
import { handleLink } from '../utils/common';

type LinkProps = StyleProps & {
  title: string;
  description: string;
  url: string;
};

export function Block({ title, description, style }: Omit<LinkProps, 'url'>) {
  return (
    <View style={[styles.container, styles.text]}>
      <TextH3 style={styles.title}>{title}</TextH3>
      <TextBody>{description}</TextBody>
    </View>
  );
}

export default function Link({ style, title, description, url }: LinkProps) {
  return (
    <Pressable
      style={[styles.container, style]}
      onPress={() => handleLink(url)}
    >
      <Row style={styles.row}>
        <View style={styles.text}>
          <TextH3 style={styles.title}>{title}</TextH3>
          <TextBody>{description}</TextBody>
        </View>
        <Icon name="arrowForward" />
      </Row>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 40,
  },
  row: {
    justifyContent: 'space-between',
  },
  text: {
    flex: 1,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          maxWidth: 500,
        }),
        ...(Layout.isMobileDevice && {
          marginRight: 25,
        }),
      },
    }),
  },
  title: {
    marginBottom: 5,
  },
});
