import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { StyleSheet } from 'react-native';

import { previouslyBooked } from '../api/search';
import { PrimaryButton } from '../components/Button';
import DecoratedTitle from '../components/DecoratedTitle';
import { Footer } from '../components/Footer';
import HorizontalLocationBlockLarge from '../components/HorizontalLocationBlockLarge';
import { Container, InnerContainer } from '../components/Layout';
import Loader, { LoadingCircle } from '../components/Loader';
import { LocationBlockLarge } from '../components/LocationBlocks';
import { SkeletonBlockLarge } from '../components/LocationSkeleton';
import Redirect from '../components/Redirect';
import { View } from '../components/Themed';
import CommonHeader from '../components/layouts/CommonHeader';
import { useIsAuthEstablished } from '../contexts/authFlow';
import { useIsLoggedIn } from '../contexts/authentication';
import { useMinWidth } from '../hooks/useResponsive';

export default function BookAgain() {
  const isAuthEstablished = useIsAuthEstablished();
  const isLoggedIn = useIsLoggedIn();

  if (!isAuthEstablished) {
    return (
      <>
        <CommonHeader />
        <Container>
          <InnerContainer>
            <Loader />
          </InnerContainer>
        </Container>
      </>
    );
  }

  if (!isLoggedIn) {
    return <Redirect to="Explore" />;
  }
  return (
    <>
      <CommonHeader />
      <Container>
        <InnerContainer>
          <BookAgainContentPage />
        </InnerContainer>
        <Footer />
      </Container>
    </>
  );
}

const fetchPreviouslyBookedLocations = async ({
  pageParam,
}: {
  pageParam: number;
}) => {
  const res = await previouslyBooked(10, pageParam);
  return res;
};

function BookAgainContentPage() {
  const isLargeDevice = useMinWidth(700);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['bookAgainLocations'],
    queryFn: fetchPreviouslyBookedLocations,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnWindowFocus: false,
  });

  const BlockComponent = isLargeDevice
    ? HorizontalLocationBlockLarge
    : LocationBlockLarge;

  const content =
    status === 'pending' ? (
      <>
        <SkeletonBlockLarge horizontal={isLargeDevice} />
      </>
    ) : status === 'error' ? (
      <p>Error: {error.message}</p>
    ) : (
      <>
        {data.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.data.map((location) => (
              <BlockComponent
                key={location.id}
                location={location}
                // @ts-expect-error
                width="100%"
                style={{ width: '100%' }}
              />
            ))}
          </React.Fragment>
        ))}
      </>
    );

  return (
    <View style={styles.inner}>
      <DecoratedTitle>Book Again</DecoratedTitle>
      <View style={styles.locationsContainer}>{content}</View>

      <View style={{ alignItems: 'center', marginTop: 20 }}>
        {isFetching && !isFetchingNextPage ? <LoadingCircle /> : null}
      </View>
      {!isFetchingNextPage && !hasNextPage ? null : (
        <PrimaryButton
          style={{ alignSelf: 'center', marginTop: 20 }}
          onPress={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          title={
            isFetchingNextPage
              ? 'Loading more...'
              : hasNextPage
                ? 'Load More'
                : 'Nothing more to load'
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  inner: {
    paddingVertical: 50,
  },
  locationsContainer: {
    width: '100%',
    maxWidth: 1000,
    marginTop: 50,
    marginHorizontal: 'auto',
    paddingHorizontal: 20,
  },
});
