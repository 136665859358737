import { lazy, Suspense, useEffect, useState } from 'react';
import { Image } from 'react-native';

import ProgramPageFactory from './ProgramPage';
import { Tab, programPageSettings } from '../api/program_pages';
import { HelloMessageBarContainer } from '../components/HelloMessageBar';
import { TextCta, TextH3 } from '../components/StyledText';
import { TransparentView } from '../components/Themed';
import HeroWithImage, { styles } from '../components/explore/HeroWithImage';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { findCurrentCampaignKey } from '../utils/campaigns';

const WinterWonderlandLogoDesktop = lazy(
  () => import('../assets/images/winter-wonderland-logo-desktop.svg')
);
const WinterWonderlandLogoMobile = lazy(
  () => import('../assets/images/winter-wonderland-logo-mobile.svg')
);

export const HelloBarContent = () => (
  <TextCta style={{ textAlign: 'center' }} bold>
    Use code WINTER15 at checkout and get 15% off your fall & winter bookings!
  </TextCta>
);

const HelloBar = () => {
  const theme = useColorScheme();
  return (
    <HelloMessageBarContainer
      style={{
        backgroundColor: Colors[theme].accentSecondary,
      }}
    >
      <HelloBarContent />
    </HelloMessageBarContainer>
  );
};

const WinterWonderlandHeader = () => {
  const isMobileDevice = useMaxWidth(430);
  const LogoImage = isMobileDevice
    ? WinterWonderlandLogoMobile
    : WinterWonderlandLogoDesktop;
  return (
    <HeroWithImage
      mainTitle={''}
      imageFileName={'winter-wonderland-2024.webp'}
      altText={
        "Children engaged in holiday activities like making an ornament as part of Bumo's on-demand child care winter wonderland session"
      }
      containerStyle={{ backgroundColor: 'transparent' }}
      hideAddons
      content={
        <TransparentView
          style={!isMobileDevice && styles.introTextInnerDesktop}
        >
          <Image
            source={require('../assets/images/snow.png')}
            style={[
              {
                position: 'absolute',
                left: 405,
                top: 0,
                width: 100,
                height: 100,
              },
              isMobileDevice && {
                left: -13,
                top: 9,
                width: 50,
                height: 50,
              },
            ]}
          />
          <Image
            source={require('../assets/images/snow.png')}
            style={[
              {
                position: 'absolute',
                left: -95,
                top: 230,
                width: 100,
                height: 100,
              },
              isMobileDevice && {
                left: 'auto',
                right: -20,
                top: 135,
                width: 50,
                height: 50,
              },
            ]}
          />
          <Suspense>
            <LogoImage
              style={{
                ...(isMobileDevice && { alignSelf: 'center' }),
                marginBottom: 25,
              }}
              width={isMobileDevice ? 375 : 462}
              height={isMobileDevice ? 201 : 307}
            />
          </Suspense>
          <TextH3
            style={[
              isMobileDevice && { textAlign: 'center' },
              { maxWidth: 520 },
            ]}
          >
            Fall and winter holiday sessions just for you and your family. Find
            a session and book today!
          </TextH3>
          <Image
            source={require('../assets/images/ornament.png')}
            resizeMode="contain"
            style={{
              width: 520,
              marginTop: 40,
            }}
          />
        </TransparentView>
      }
      skipButton
    />
  );
};

const WinterWonderlandProgram = ProgramPageFactory({
  sectionTitle: 'Fall & Winter Sessions',
  HeaderComponent: WinterWonderlandHeader,
  additionalSections: ['REVIEWS'],
  customHelloBar:
    findCurrentCampaignKey() === 'WINTER_WONDERLAND_2024_COUPON' ? (
      <HelloBar />
    ) : null,
  ignoreCampaignHeaderFn: (campaign) =>
    campaign !== 'WINTER_WONDERLAND_2024_COUPON',
});

const WinterWonderlandPage = () => {
  const [tabs, setTabs] = useState<Tab[]>([{ title: '', ids: [0] }]);
  useEffect(() => {
    programPageSettings('winter_wonderland').then((settings) =>
      setTabs(settings.tabs)
    );
  }, []);

  return <WinterWonderlandProgram tabs={tabs} />;
};

export default WinterWonderlandPage;
