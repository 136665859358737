import { Pressable, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { TextBodySmall } from '../StyledText';
import { TransparentView } from '../Themed';

export default function LocationSearchResults({
  data,
}: {
  data: {
    place_id: string;
    displayText: string;
    handleSelect: () => void;
  }[];
}) {
  const theme = useColorScheme();
  return (
    <TransparentView style={styles.locationResultsWrapper}>
      {data?.map((suggestion, idx) => (
        <Pressable
          key={suggestion.place_id}
          onPress={() => suggestion.handleSelect()}
          style={{
            paddingVertical: 10,
            borderBottomWidth: idx !== data.length - 1 ? 1 : 0,
            borderBottomColor: Colors[theme].lines,
          }}
        >
          <TextBodySmall>{suggestion.displayText}</TextBodySmall>
        </Pressable>
      ))}
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  locationResultsWrapper: {
    flex: 1,
    overflow: 'scroll',
    height: '100%',
  },
});
