import { forwardRef } from 'react';
import { Pressable, View as DefaultView, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';
import { StyleProps, TransparentView } from '../Themed';

// ForwardRef used here to allow Popup to properly pass onClick events
export const InputContainer = forwardRef(
  (
    {
      primaryText,
      secondaryText,
      clearable,
      onClear,
      content,
      hasValue,
      ...props
    }: {
      primaryText?: React.ReactNode | string;
      secondaryText?: React.ReactNode | string;
      content?: React.ReactNode | string;
      clearable?: boolean;
      hasValue?: boolean;
      onClear?: () => void;
      style?: StyleProps['style'];
    },
    ref: React.ForwardedRef<DefaultView>
  ) => {
    const theme = useColorScheme();
    return (
      <TransparentView
        style={[
          styles.button,
          {
            backgroundColor: '#fff',
            elevation: 2,
            shadowRadius: 12,
            shadowOpacity: 1,
            shadowColor: Colors[theme].lines,
            marginBottom: 16,
            flexDirection: 'row',
          },
          props.style,
        ]}
        ref={ref}
        // {...props}
      >
        <Icon
          name="search"
          color={Colors[theme].accentTertiary}
          style={styles.searchIcon}
        />
        <TransparentView style={{ flex: 1, alignItems: 'stretch' }}>
          {content ? (
            content
          ) : typeof primaryText === 'string' ? (
            <TextBodySmall style={{ fontSize: 13, lineHeight: 15 }}>
              {primaryText}
            </TextBodySmall>
          ) : (
            primaryText
          )}
          <TransparentRow
            style={{ width: '100%', justifyContent: 'flex-start' }}
          >
            {typeof secondaryText === 'string' ? (
              <TextBodySmall
                style={{
                  fontSize: 16,
                  marginTop: 4,
                  lineHeight: 15,
                  color: Colors[theme][hasValue ? 'text' : 'textSecondary'],
                  paddingTop: 2,
                }}
              >
                {secondaryText}
              </TextBodySmall>
            ) : (
              secondaryText
            )}
          </TransparentRow>
        </TransparentView>

        {clearable ? (
          <Pressable style={styles.clearIcon} onPress={onClear}>
            <Icon
              name="close"
              color={Colors[theme].textSecondary}
              style={{
                width: 16,
                height: 16,
              }}
            />
          </Pressable>
        ) : null}
      </TransparentView>
    );
  }
);

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 24,
    marginHorizontal: 12,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 400,
  },
  button: {
    borderRadius: 28,
    height: 56,
    paddingHorizontal: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  searchIcon: {
    width: 34,
    height: 34,
    marginRight: 8,
  },
  clearIcon: {
    position: 'absolute',
    top: 4,
    right: 4,
    width: 48,
    height: 48,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
