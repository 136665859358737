import { useEffect } from 'react';

import { useAuthenticate, useIsAuthEstablished } from '../contexts/authFlow';
import useAppNavigation from '../hooks/useAppNavigation';

export default function LogIn() {
  const { replace } = useAppNavigation();
  const authenticate = useAuthenticate();
  const isAuthEstablished = useIsAuthEstablished();
  useEffect(() => {
    if (!isAuthEstablished) {
      return;
    }
    authenticate('LOGIN');
    replace('Explore');
  }, [isAuthEstablished]);

  return null;
}
