import { useMinWidth } from '../../hooks/useResponsive';
import { Header } from '../Layout';
import { ThreeParamsSearchHeader } from '../ThreeParamSearch';

export default function CommonHeader({
  customHelloBar,
  shadow = true,
}: {
  customHelloBar?: React.ReactNode;
  shadow?: boolean;
}) {
  const isDesktopDevice = useMinWidth(900);
  return isDesktopDevice ? (
    <ThreeParamsSearchHeader customHelloBar={customHelloBar} />
  ) : (
    <Header shadow={shadow} customHelloBar={customHelloBar} />
  );
}
