export type Action<S> =
  | { type: 'field'; field: keyof S; value: unknown }
  | { type: 'state'; value: Partial<S> };

export function reducer<S>(state: S, action: Action<S>) {
  switch (action.type) {
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'state': {
      return {
        ...state,
        ...action.value,
      };
    }
  }
}
