import { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { postLead } from '../../api/locations';
import { VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useColorScheme from '../../hooks/useColorScheme';
import { useForm } from '../../hooks/useForm';
import { PrimaryButton } from '../Button';
import { PhoneNumberInput } from '../PhoneInput';
import { TextH3, TextBody } from '../StyledText';
import { StyleProps, View } from '../Themed';

export type InterestedInBookingProps = StyleProps & {
  locationId: number;
  verificationStatus:
    | VerificationStatus.UNVERIFIED
    | VerificationStatus.COMING_SOON;
};

const config = {
  bodyText: {
    [VerificationStatus.COMING_SOON]:
      "This location is coming soon. We'll notify you when this provider is available for booking.",
    [VerificationStatus.UNVERIFIED]:
      "We'll notify you when this provider is available for booking.",
  },
};

export default function InterestedInBooking({
  locationId,
  verificationStatus,
  style,
}: InterestedInBookingProps) {
  const theme = useColorScheme();

  const { state, dispatch } = useForm({ phone: '' });

  const [submitted, setSubmitted] = useState(false);
  const backgroundColor =
    verificationStatus === VerificationStatus.COMING_SOON
      ? 'accentSecondary'
      : 'accent';

  useEffect(() => {
    setSubmitted(false);
  }, [locationId]);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: Colors[theme][backgroundColor] },
        style,
      ]}
    >
      <TextH3 style={styles.header}>Interested in booking here?</TextH3>
      {submitted ? (
        <TextBody>
          Thank you. We will text you with more information about this provider
          soon!
        </TextBody>
      ) : (
        <>
          <TextBody>{config.bodyText[verificationStatus]}</TextBody>

          <PhoneNumberInput
            testID="phone-input"
            style={styles.input}
            placeholder="Add your phone number and get notified"
            value={state.values.phone}
            error={state.errors.phone}
            onChangeText={(value) =>
              dispatch({ type: 'field', field: 'phone', value })
            }
            onBlur={() => dispatch({ type: 'validate', field: 'phone' })}
          />

          <PrimaryButton
            testID="submit-button"
            style={styles.button}
            title="Submit"
            disabled={!state.dirty || state.isInvalid}
            onPress={() => {
              postLead(locationId, state.values.phone);
              setSubmitted(true);
            }}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          padding: 30,
        }),
        ...(Layout.isMobileDevice && {
          padding: 20,
        }),
      },
    }),
  },
  input: {
    width: '100%',
    marginTop: 12,
  },
  header: {
    marginBottom: 24,
  },
  button: {
    marginTop: 10,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          height: 60,
        }),
        ...(Layout.isMobileDevice && {
          height: 50,
        }),
      },
    }),
  },
});
