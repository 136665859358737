import { Child } from './api';
import { getAgeInMonths } from './date';
import { BookingSlot } from '../api/search';
import { Kid } from '../components/KidsInput';

export function getKidFromChild(child: Child) {
  const { id, firstName, birthdate } = child;
  return { id, firstName, birthdate, isChosen: false, isEligible: false };
}

export function setEligibleKid(kid: Kid, slots: BookingSlot[]) {
  const isEligible = slots.some((slot) => {
    const ageOnSlotDate = getAgeInMonths(kid.birthdate, new Date(slot.date));
    return slot.ageFrom <= ageOnSlotDate && ageOnSlotDate < slot.ageTo;
  });
  return { ...kid, isEligible };
}

export function mapKidsWithEligibility(kids: Kid[], slots: BookingSlot[]) {
  return kids.map((k) => setEligibleKid(k, slots));
}

export function getSelectedKidsAges(kids: number[], allChildren: Child[]) {
  return allChildren
    .map((child) => {
      return kids.includes(child.id) && getAgeInMonths(child.birthdate);
    })
    .filter(Boolean) as number[];
}

export const childSexFormattedValues = {
  female: 'Girl',
  male: 'Boy',
  unspecified: 'Prefer not to answer',
};
export const formatChildSex = (
  sex: 'male' | 'female' | 'unspecified' | null
) => (sex ? childSexFormattedValues[sex] : null);
