import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { Icon } from '../Icon';
import Row from '../Row';
import { TextTag } from '../StyledText';

export default function BookingByWeekTag() {
  const theme = useColorScheme();
  return (
    <Row
      style={{
        backgroundColor: Colors[theme].accentSecondary,
        height: 26,
        borderRadius: 13,
        paddingHorizontal: 20,
        paddingVertical: 2,
      }}
    >
      <Icon
        name="dateRange"
        style={{ width: 20, height: 20, opacity: 0.5, marginRight: 2 }}
      />
      <TextTag>Weekly Booking</TextTag>
    </Row>
  );
}
