import { Link } from '@react-navigation/native';
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  TextProps,
  TextStyle,
} from 'react-native';

import { InnerContainer } from './Layout';
import Row, { TransparentRow } from './Row';
import { TextBodySmall, TextCtaButton } from './StyledText';
import { Divider, TransparentView, View, ViewProps } from './Themed';
import { WebLink } from './WebLink';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import LINKS from '../constants/Links';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { handleLink } from '../utils/common';

export function FooterView(props: ViewProps) {
  return (
    <View
      lightColor={Colors.light.accentTertiary}
      darkColor={Colors.light.accentTertiary}
      {...props}
    />
  );
}

function FTextCtaButton(props: TextProps) {
  const theme = useColorScheme();
  const textStyles: TextStyle = { color: Colors[theme].background };
  return <TextCtaButton {...props} style={textStyles} />;
}

function FTextBodySmall(props: TextProps) {
  const theme = useColorScheme();
  const textStyles: TextStyle = { color: Colors[theme].background };
  return <TextBodySmall style={textStyles} {...props} />;
}

export function Footer() {
  const theme = useColorScheme();
  return Platform.OS === 'web' ? (
    <FooterView style={styles.container}>
      <InnerContainer style={styles.inner}>
        {Layout.isLargeDevice && (
          <Divider style={[{ backgroundColor: Colors[theme].background }]} />
        )}
        <Row style={styles.row}>
          <FooterView style={styles.section}>
            <FooterView style={styles.links}>
              <FTextCtaButton>Bumo</FTextCtaButton>
              <WebLink href={LINKS.about} target="_blank">
                <FTextBodySmall>About Us</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.blog} target="_blank">
                <FTextBodySmall>Blog</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.events} target="_blank">
                <FTextBodySmall>Events</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.safety} target="_blank">
                <FTextBodySmall>Safety &amp; Trust</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.forms.getAll} target="_blank">
                <FTextBodySmall>Licensing Forms</FTextBodySmall>
              </WebLink>
              <Link to={{ screen: 'BuyGiftCard' }}>
                <FTextBodySmall>Gift Cards</FTextBodySmall>
              </Link>
              <Link to={{ screen: 'BumoCreditLanding' }}>
                <FTextBodySmall>Bumo Credit</FTextBodySmall>
              </Link>
            </FooterView>
          </FooterView>
          <FooterView style={styles.section}>
            <FooterView style={styles.links}>
              <FTextCtaButton>Legal</FTextCtaButton>
              <WebLink href={LINKS.toc} target="_blank">
                <FTextBodySmall>Terms of Use</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.privacy} target="_blank">
                <FTextBodySmall>Privacy & Cookie Policy</FTextBodySmall>
              </WebLink>
              {FeatureFlags.ACCESSIBILITY_WIDGET && (
                <WebLink href="#" data-acsb-custom-trigger="true">
                  <FTextBodySmall>Accessibility</FTextBodySmall>
                </WebLink>
              )}
            </FooterView>
          </FooterView>
          <FooterView style={styles.section}>
            <FooterView style={styles.links}>
              <FTextCtaButton>Partners</FTextCtaButton>
              <WebLink href={LINKS.providers} target="_blank">
                <FTextBodySmall>For Providers</FTextBodySmall>
              </WebLink>
              <Link to={{ screen: 'EmployerLead' }}>
                <FTextBodySmall>For Employers</FTextBodySmall>
              </Link>
            </FooterView>
          </FooterView>
          <FooterView style={styles.section}>
            <FooterView style={styles.links}>
              <FTextCtaButton>Contact Us</FTextCtaButton>
              <WebLink href={LINKS.supportMail} target="_blank">
                <FTextBodySmall>support@bumo.com</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.telephone} target="_blank">
                <FTextBodySmall>Text or Call: 650.689.3043</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.faqs} target="_blank">
                <FTextBodySmall>FAQ</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.careers} target="_blank">
                <FTextBodySmall>Careers</FTextBodySmall>
              </WebLink>
            </FooterView>
          </FooterView>
          <FooterView style={styles.section}>
            <FooterView style={styles.links}>
              <FTextCtaButton>Follow Us</FTextCtaButton>
              <WebLink href={LINKS.instagram} target="_blank">
                <FTextBodySmall>Instagram</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.facebook} target="_blank">
                <FTextBodySmall>Facebook</FTextBodySmall>
              </WebLink>
              <WebLink href={LINKS.linkedin} target="_blank">
                <FTextBodySmall>LinkedIn</FTextBodySmall>
              </WebLink>
            </FooterView>
          </FooterView>
        </Row>
        <MobileButtonsCopyContainer>
          <TransparentView style={{ flex: 1 }}>
            <FTextBodySmall>
              &copy; {new Date().getFullYear()} Bumo
            </FTextBodySmall>
          </TransparentView>
          <ButtonsWrapper>
            <Pressable onPress={() => handleLink(LINKS.mobileApps.appStore)}>
              <Image
                source={require('../assets/images/appstore.png')}
                style={{ width: 174, height: 50 }}
                width={174}
                height={50}
              />
            </Pressable>
            <Pressable onPress={() => handleLink(LINKS.mobileApps.googleplay)}>
              <Image
                source={require('../assets/images/googleplay.png')}
                width={174}
                height={50}
              />
            </Pressable>
          </ButtonsWrapper>
        </MobileButtonsCopyContainer>
      </InnerContainer>
    </FooterView>
  ) : null;
}
function ButtonsWrapper({ children }: { children: React.ReactNode }) {
  const isMobileDevice = useMaxWidth(500);
  return (
    <TransparentRow
      style={[
        { flex: 1, gap: 20, height: 50, marginTop: 20 },
        isMobileDevice && {
          flexDirection: 'column',
          height: 'auto',
        },
      ]}
    >
      {children}
    </TransparentRow>
  );
}

function MobileButtonsCopyContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const isMobileDevice = useMaxWidth(800);
  return (
    <View
      style={[
        styles.copyright,
        isMobileDevice
          ? { justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }
          : { flexDirection: 'row', gap: 20, alignItems: 'flex-end' },
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 'auto',
  },
  section: {
    flex: 1,
    minWidth: 170,
  },
  inner: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginVertical: 50,
        }),
        ...(Layout.isMobileDevice && {
          paddingHorizontal: 20,
          paddingBottom: 50,
        }),
      },
    }),
  },
  row: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    ...Platform.select({
      web: {
        gap: 46,
        ...(Layout.isLargeDevice && {
          marginVertical: 40,
        }),
        ...(Layout.isMobileDevice && {
          marginVertical: 25,
        }),
      },
    }),
  },
  links: {
    flex: 1,
    ...Platform.select({
      web: {
        gap: 12,
      },
    }),
  },
  copyright: {
    backgroundColor: 'transparent',
    flexDirection: 'column-reverse',
  },
});
