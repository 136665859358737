import React, { useMemo, useState } from 'react';
import {
  ImageStyle,
  Modal as NativeModal,
  Platform,
  Pressable,
  PressableProps,
  StyleSheet,
} from 'react-native';
import Modal from 'react-native-modal';
import Popup from 'reactjs-popup';

import { Icon } from './Icon';
import { TextBodySmall } from './StyledText';
import { View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';

export default Platform.select({
  native: InfoPopupNative,
  default: InfoPopupDefault,
});

type InfoPopupProps = {
  text: string;
  iconStyle?: ImageStyle;
  pressableProps?: PressableProps;
  useModalOnMobile?: boolean;
};

function InfoPopupNative({ text, iconStyle = {} }: InfoPopupProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Pressable onPress={() => setOpen(true)}>
        <Icon name="infoOutlined" style={[styles.icon, iconStyle]} />
      </Pressable>
      <NativeModal
        visible={open}
        transparent
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.2)',
        }}
      >
        <Pressable
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.2)',
            flex: 1,
            padding: 20,
          }}
          onPress={() => setOpen(false)}
        >
          <View style={{ padding: 20, borderRadius: 10 }}>
            <TextBodySmall>{text}</TextBodySmall>
          </View>
        </Pressable>
      </NativeModal>
    </>
  );
}

function InfoPopupDefault({
  text,
  iconStyle = {},
  pressableProps = {},
  useModalOnMobile = false,
}: InfoPopupProps) {
  const theme = useColorScheme();

  const isMobileDevice = useMaxWidth(430);
  const [isModalVisible, setModalVisible] = useState(false);

  const sharedContainerStyles = useMemo(
    () => [
      styles.container,
      {
        borderColor: Colors[theme].accent,
        shadowRadius: 12,
        shadowOpacity: 0.8,
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowColor: Colors[theme].shadow,
      },
    ],
    [theme]
  );

  if (isMobileDevice && useModalOnMobile) {
    return (
      <>
        <Pressable {...pressableProps} onPress={() => setModalVisible(true)}>
          <Icon name="infoOutlined" style={[styles.icon, iconStyle]} />
        </Pressable>
        <Modal
          useNativeDriver
          isVisible={isModalVisible}
          onBackdropPress={() => setModalVisible(false)}
        >
          <View
            style={[
              sharedContainerStyles,
              {
                width: 'auto',
              },
            ]}
          >
            <TextBodySmall>{text}</TextBodySmall>
          </View>
        </Modal>
      </>
    );
  }

  return (
    <Popup
      on={['hover', 'focus']}
      position="bottom center"
      offsetX={-20}
      arrow={false}
      trigger={
        <Pressable {...pressableProps}>
          <Icon name="infoOutlined" style={[styles.icon, iconStyle]} />
        </Pressable>
      }
      keepTooltipInside="body"
    >
      <View style={sharedContainerStyles}>
        <TextBodySmall>{text}</TextBodySmall>
      </View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  container: {
    width: Layout.isMobileDevice ? 300 : 375,
    borderRadius: 20,
    borderWidth: 2,
    padding: 18,
  },
  icon: {
    marginBottom: 10,
  },
});
