import { useCallback } from 'react';

import { useAuthDispatch, useAuthState } from '../contexts/authentication';
import { useCheckoutPropUpdate, useCheckoutState } from '../contexts/checkout';
import { useUpdateSearchState } from '../contexts/threeparamsearch';
import { Child } from '../utils/api';
import { getKidFromChild } from '../utils/children';
import { getAge, getAgeInMonths } from '../utils/date';

export const useParent = () => {
  const {
    parent: {
      id,
      firstName,
      lastName,
      email,
      telephone,
      children,
      zip,
      latitude,
      longitude,
      submittedHdyhau,
      careCreditBalance,
      bookedLocations,
    } = {},
  } = useAuthState();
  return {
    id,
    firstName,
    lastName,
    email,
    telephone,
    children,
    zip,
    latitude,
    longitude,
    submittedHdyhau,
    careCreditBalance,
    bookedLocations,
  };
};

export const useChild = (childName: string) => {
  const { children = [] } = useParent();
  const childIndex = children.findIndex(
    (c) => c.firstName.toLowerCase() === childName
  );
  const child = children[childIndex];

  return {
    child,
    childIndex,
  };
};

export const useChildActions = (childName: string) => {
  const authDispatch = useAuthDispatch();
  const { child, childIndex } = useChild(childName);
  const { kids } = useCheckoutState();
  const updateCheckoutProp = useCheckoutPropUpdate();

  const { setInputState } = useUpdateSearchState();

  if (!child || childIndex === -1) {
    throw new Error(
      'using child actions for child that not exists in parent.children array'
    );
  }

  const updateChildDetails = useCallback(
    (value: Partial<Child> & { id: Child['id'] }) => {
      authDispatch({ type: 'updateChild', id: child.id, value });
      const updatedChild = value as Child;
      updateCheckoutProp(
        kids.map((k) =>
          k.id === child.id ? getKidFromChild(updatedChild) : k
        ),
        'kids'
      );
      setInputState((s) => ({
        who: s.who.map((k) =>
          k.id === child.id
            ? {
                ...k,
                name: updatedChild.firstName,
                age: getAge(updatedChild.birthdate),
                enabled: true,
                ageInMonths: getAgeInMonths(updatedChild.birthdate),
              }
            : k
        ),
      }));
    },
    [kids]
  );

  const updateChildPickups = useCallback(
    (value: { pickups: Child['pickups'] }) => {
      authDispatch({
        type: 'updateChild',
        id: child.id,
        value: {
          pickups: value.pickups,
        },
      });
    },
    []
  );

  const updateChildEmergencyContacts = useCallback(
    (value: {
      physician: Child['physician'];
      emergencyContacts: Child['emergencyContacts'];
    }) => {
      authDispatch({
        type: 'updateChild',
        id: child.id,
        value: {
          physician: value.physician,
          emergencyContacts: value.emergencyContacts,
        },
      });
    },
    []
  );

  return {
    updateChildDetails,
    updateChildPickups,
    updateChildEmergencyContacts,
  };
};
