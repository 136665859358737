import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { GestureResponderEvent, StyleSheet } from 'react-native';

import { LocationCtaButtonVertical } from './Button';
import { BookingSlot as IBookingSlot } from '../api/search';
import { useCheckoutPropUpdate } from '../contexts/checkout';
import { dateStringToHuman } from '../utils/date';
import { formatDateHoursRange } from '../utils/locations';

interface BookingSlotButtonProps {
  slot: IBookingSlot;
  locationNameId: string;
  onPress?: () => void;
}

export default function BookingSlotButton({
  slot,
  locationNameId,
  onPress,
}: BookingSlotButtonProps) {
  const updateCheckoutProp = useCheckoutPropUpdate();
  const { navigate } = useNavigation();

  const handlePress = useCallback(
    (e: GestureResponderEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onPress) {
        onPress();
        return;
      }

      const callback = () => {
        updateCheckoutProp(
          {
            [locationNameId]: {
              [slot.date]: [slot.dayTime],
            },
          },
          'dailyState'
        );
        navigate('Location', { nameId: locationNameId });
      };
      callback();
    },
    [slot, locationNameId]
  );

  if (!slot.date) return null;

  const right = formatDateHoursRange(
    slot.dropoff.includes('+00:00') ? slot.dropoff : slot.dropoff + 'Z',
    slot.pickup.includes('+00:00') ? slot.pickup : slot.pickup + 'Z'
  )
    ?.toLowerCase()
    .replaceAll(' am', 'a')
    .replaceAll(' pm', 'p');

  return (
    <LocationCtaButtonVertical
      onPress={handlePress}
      style={styles.button}
      top={dateStringToHuman(slot.date)}
      bottom={right}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    // marginTop: 5,
    // marginRight: 5
  },
});
