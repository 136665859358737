import { Parent } from './api';

export function pushProceedToPaymentEvent({
  id: parentId,
}: Pick<Parent, 'id'>) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'proceed_to_payment',
    user_id: parentId,
  });
}

export function pushAccountCreatedEvent(parent: Parent) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'account_created',
    user_id: parent.id,
  });
}
