import { useDebugValue, useSyncExternalStore } from 'react';

const isCallback = <T>(fn: ((s: T) => T) | T): fn is (s: T) => T =>
  typeof fn === 'function';

export function makeState<T>(initial: T) {
  const callbacks = new Set<(val: T) => void>();
  let current: T = initial;
  function getValue() {
    return current;
  }
  function setValue(newValue: ((s: T) => T) | T) {
    if (current === newValue) {
      return;
    }
    current = isCallback(newValue) ? newValue(current) : newValue;
    callbacks.forEach((fn) => fn(current));
  }
  function onChange(cb: (val: T) => void) {
    if (!callbacks.has(cb)) {
      callbacks.add(cb);
    }
    return () => callbacks.delete(cb);
  }
  return {
    getValue,
    setValue,
    onChange,
  };
}

export type State<T> = ReturnType<typeof makeState<T>>;

export const useStoreState = <T>(storeState: State<T>) => {
  const storeValue = useSyncExternalStore(
    storeState.onChange,
    storeState.getValue
  );
  useDebugValue(storeValue);
  return [storeValue, storeState.setValue] as const;
};
