import { ImageBackground, View } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import BumoGiftCard from '../BumoGiftCard';
import { PrimaryButton } from '../Button';
import { TextBody, TextH3 } from '../StyledText';

export function MarketingBumoCreditContentMobile({
  onPress,
}: {
  onPress: () => void;
}) {
  const theme = useColorScheme();
  return (
    <>
      <ImageBackground
        source={require('../../assets/images/bumo-credit.png')}
        accessibilityRole="none"
        style={[
          {
            width: '100%',
            padding: 20,
            height: 100,
            flex: -1,
          },
        ]}
        imageStyle={{ resizeMode: 'contain' }}
      />
      <View style={{ alignItems: 'center', gap: 6 }}>
        <TextH3>Save with BumoCredit</TextH3>
        <TextBody style={{ textAlign: 'center' }}>
          BumoCredit is like buying a gift card for yourself that has more money
          than what you paid for it.
        </TextBody>
      </View>
      <PrimaryButton
        style={{ backgroundColor: Colors[theme].accent, alignSelf: 'center' }}
        title="Get BumoCredit"
        onPress={onPress}
      />
    </>
  );
}

export function MarketingGiftCardContentMobile({
  onPress,
}: {
  onPress: () => void;
}) {
  const theme = useColorScheme();
  return (
    <>
      <BumoGiftCard width={160} mediumCardSize calculateSize />
      <View style={{ alignItems: 'center', gap: 6 }}>
        <TextH3>Give the Gift of Child Care</TextH3>
        <TextBody style={{ textAlign: 'center' }}>
          When you give a gift card, you get 20% of the amount in BumoCredit to
          use for yourself!
        </TextBody>
      </View>
      <PrimaryButton
        style={{ backgroundColor: Colors[theme].accent, alignSelf: 'center' }}
        title="Give the Bumo Gift Card"
        onPress={onPress}
      />
    </>
  );
}
