import { useEffect, useState } from 'react';
import Modal from 'react-native-modal';

import { reportPhoto } from '../../api/reports';
import Colors from '../../constants/Colors';
import { useIsLoggedIn } from '../../contexts/authentication';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { BottomSheetModalContainer } from '../BottomSheetModal';
import { IconButton, PrimaryButton, TextButton } from '../Button';
import { Icon } from '../Icon';
import ModalCloseButton from '../ModalCloseButton';
import { TransparentRow } from '../Row';
import { TextBody, TextBodySmall, TextH3 } from '../StyledText';
import { Divider, TransparentView, View } from '../Themed';

const REASONS = {
  not_helpful: 'not_helpful',
  inappropriate: 'inappropriate',
} as const;

const getPhotoStatus = (storageKey: string) => {
  const v = localStorage.getItem(storageKey);
  if (!v) {
    return null;
  }
  if (v === REASONS.inappropriate) {
    return REASONS.inappropriate;
  }

  if (v === REASONS.not_helpful) {
    return REASONS.not_helpful;
  }
  return null;
};

export function ReportPhotoButton({
  locationId,
  photoUrl,
}: {
  locationId: number;
  photoUrl: string;
}) {
  const storageKey = `report_${locationId}_${photoUrl}`;

  const [isOpen, setIsOpen] = useState(false);
  const [reportStatus, setReportStatus] = useState(getPhotoStatus(storageKey));
  const theme = useColorScheme();

  useEffect(() => {
    setReportStatus(getPhotoStatus(storageKey));
  }, [storageKey]);

  const isLoggedIn = useIsLoggedIn();
  const dispatchSnackbar = useSnackbarDispatch();

  const showAlreadyReportedSnackbar = () =>
    dispatchSnackbar({
      message: (
        <TransparentRow style={{ width: '100%' }}>
          <Icon name="explore" style={{ marginRight: 10 }} />
          <TextBodySmall>
            You have already submitted a report for review.
          </TextBodySmall>
        </TransparentRow>
      ),
    });

  const showConfirmationSnackbar = () =>
    dispatchSnackbar({
      message: (
        <TransparentRow style={{ width: '100%' }}>
          <Icon name="explore" style={{ marginRight: 10 }} />
          <TextBodySmall>
            Your report has been submitted for review.
          </TextBodySmall>
        </TransparentRow>
      ),
    });

  const wrapReportingTrial = (fn: () => Promise<void>) => async () => {
    if (reportStatus !== null) {
      showAlreadyReportedSnackbar();
      setIsOpen(false);
      return;
    }
    try {
      await fn();
      setTimeout(() => {
        setIsOpen(false);
      }, 200);
      showConfirmationSnackbar();
    } catch (err) {
      console.error(err);
      dispatchSnackbar({
        type: 'error',
        message: (
          <TextBodySmall>
            There was a problem while reporting a photo. Please try again later.
          </TextBodySmall>
        ),
      });
    }
  };

  const reportNotHelpful = wrapReportingTrial(async () => {
    await reportPhoto({
      location_id: locationId,
      reason: REASONS.not_helpful,
      photo_url: photoUrl,
    });
    localStorage.setItem(storageKey, REASONS.not_helpful);
    setReportStatus(REASONS.not_helpful);
  });
  const reportInappropriate = wrapReportingTrial(async () => {
    await reportPhoto({
      location_id: locationId,
      reason: REASONS.inappropriate,
      photo_url: photoUrl,
    });

    localStorage.setItem(storageKey, REASONS.inappropriate);
    setReportStatus(REASONS.inappropriate);
  });

  const isMobileDevice = useMaxWidth(430);

  if (!isLoggedIn) {
    return null;
  }

  const buttons = (
    <TransparentView
      style={{
        backgroundColor: Colors[theme].background,
        margin: 10,
        marginTop: 0,
        borderRadius: 30,
        overflow: 'hidden',
      }}
    >
      <TransparentView
        style={[
          {
            borderBottomColor: Colors[theme].lines,
            borderBottomWidth: 1,
            padding: 20,
            alignItems: 'center',
          },
          !isMobileDevice && {
            marginHorizontal: -10,
          },
        ]}
      >
        <TextH3>Report photo</TextH3>
      </TransparentView>
      <TransparentView style={!isMobileDevice && { marginHorizontal: -10 }}>
        <ReportButtonItem
          onPress={reportNotHelpful}
          selected={reportStatus === REASONS.not_helpful}
          anySelected={reportStatus !== null}
        >
          Photo is not helpful
        </ReportButtonItem>
        <Divider />
        <ReportButtonItem
          onPress={reportInappropriate}
          selected={reportStatus === REASONS.inappropriate}
          anySelected={reportStatus !== null}
        >
          Photo is inappropriate
        </ReportButtonItem>
      </TransparentView>
    </TransparentView>
  );

  const buttonsWrapper = isMobileDevice ? (
    <BottomSheetModalContainer
      isVisible={isOpen}
      onClose={() => setIsOpen(false)}
      style={{ backgroundColor: 'transparent' }}
      snapPoints={[270]}
    >
      {buttons}
      <PrimaryButton
        title="Cancel"
        style={{ marginHorizontal: 10, height: 50 }}
        onPress={() => setIsOpen(false)}
      />
    </BottomSheetModalContainer>
  ) : (
    <Modal isVisible={isOpen} style={{ alignItems: 'center' }}>
      <View style={{ minWidth: 470, borderRadius: 20 }}>
        <ModalCloseButton absolute onPress={() => setIsOpen(false)} />
        {buttons}
      </View>
    </Modal>
  );

  return (
    <>
      <IconButton
        icon={<Icon name="menu" color="#fff" />}
        text={null}
        style={{ position: 'absolute', left: 25, top: 18 }}
        onPress={() => setIsOpen(true)}
      />
      {buttonsWrapper}
    </>
  );
}

function ReportButtonItem({
  children,
  selected,
  onPress,
  anySelected,
}: {
  children: React.ReactNode;
  onPress: () => void;
  selected?: boolean;
  anySelected?: boolean;
}) {
  const theme = useColorScheme();
  return (
    <TextButton
      TextComponent={TextBody}
      containerStyle={[
        { alignItems: 'center', paddingVertical: 15 },
        selected && { backgroundColor: Colors[theme].lines },
        anySelected && { opacity: 0.6 },
      ]}
      textStyle={{ textDecorationLine: 'none' }}
      onPress={onPress}
    >
      {children}
    </TextButton>
  );
}
