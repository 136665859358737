import { ReactNode, useState } from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { PrimaryButton } from '../components/Button';
import { Container } from '../components/Layout';
import Loader from '../components/Loader';
import ModalCloseButton from '../components/ModalCloseButton';
import Redirect from '../components/Redirect';
import { TransparentRow } from '../components/Row';
import { ShareReferralLinks } from '../components/ShareReferralLinks';
import { TextBodySmall, TextH3 } from '../components/StyledText';
import { View, TransparentView } from '../components/Themed';
import CommonHeader from '../components/layouts/CommonHeader';
import { BackToProfileButtonView } from '../components/profile/BackToProfileButton';
import Colors from '../constants/Colors';
import { useAuthState } from '../contexts/authentication';
import useColorScheme from '../hooks/useColorScheme';
import { useMinWidth } from '../hooks/useResponsive';
import { referralLinkPrefix } from '../utils/referral';

export const PageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <CommonHeader />
      <Container>
        <BackToProfileButtonView />
        {children}
      </Container>
    </>
  );
};

export default function ReferAFriend() {
  const theme = useColorScheme();
  const { isLoading, isLoggedIn, parent } = useAuthState();

  const [openLink, setIsOpen] = useState<string | null>(null);
  const hide = () => setIsOpen(null);

  if (isLoading) {
    return (
      <PageWrapper>
        <TransparentRow style={{ marginTop: 20, justifyContent: 'center' }}>
          <Loader />
        </TransparentRow>
      </PageWrapper>
    );
  }

  if (!parent || !isLoggedIn) {
    return <Redirect to="Explore" />;
  }

  const title = 'Get $25 in child care credit when you use my referral link!';

  const onSharePress = (link: string) => {
    if (navigator && 'share' in navigator) {
      navigator.share({
        title,
        text: `Hey, you get $25 in credit you can use to book child care on Bumo when you sign up using my referral link: ${link}. Terms apply.`,
        url: link,
      });
      return;
    }
    setIsOpen(link);
  };

  return (
    <PageWrapper>
      <ReferralContent code={parent.referralCode} onSharePress={onSharePress} />

      <Modal
        useNativeDriver
        style={{ margin: 0 }}
        isVisible={openLink !== null}
        onBackdropPress={hide}
        hideModalContentWhileAnimating
      >
        <View style={styles.modalContainer}>
          <ModalCloseButton absolute onPress={hide} />
          <TransparentView
            style={{
              paddingVertical: 20,
              borderBottomColor: Colors[theme].lines,
              borderBottomWidth: 1,
            }}
          >
            <TextH3 style={{ textAlign: 'center' }}>Share Invite Link</TextH3>
          </TransparentView>
          {openLink ? (
            <ShareReferralLinks link={openLink} title={title} />
          ) : null}
        </View>
      </Modal>
    </PageWrapper>
  );
}

export function ReferralContent({
  code,
  onSharePress,
}: {
  code: string;
  onSharePress: (link: string) => void;
}) {
  const isLargeDevice = useMinWidth(430);
  const theme = useColorScheme();

  const link = `${referralLinkPrefix}${code}`;

  return (
    <>
      <View style={isLargeDevice ? styles.innerLarge : styles.inner}>
        <TextH3
          style={[
            styles.text,
            { marginBottom: 15 },
            isLargeDevice && { marginTop: 50 },
          ]}
        >
          Refer friends. Give $25 and Get $25 in BumoCredit when you invite
          friends.
        </TextH3>
        <TextBodySmall style={[styles.text, { marginBottom: 25 }]}>
          Invite your friends and give them $25 and you get $25 in BumoCredit
          which you each can use to book child care on Bumo.
        </TextBodySmall>
        <View
          style={{
            height: 65,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: Colors[theme].lines,
            justifyContent: 'center',
            padding: 15,
          }}
        >
          <TextBodySmall>{link}</TextBodySmall>
        </View>
        <PrimaryButton
          title="Share Invite Link"
          onPress={() => onSharePress(link)}
          style={{ marginTop: 18, alignSelf: 'center' }}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  inner: {
    marginHorizontal: 20,
    marginVertical: 40,
  },
  innerLarge: {
    marginHorizontal: 'auto',
    marginBottom: 30,
    width: 500,
  },
  text: { maxWidth: 500, textAlign: 'center' },
  modalContainer: {
    borderRadius: 20,
    minHeight: 100,
    maxWidth: 500,
    width: '100%',
    alignSelf: 'center',
  },
});
