import { capitalize } from './api';

type Validation = {
  message: string;
  isValid: (value: string) => boolean;
};

// this should become obosolete as it belongs in a reducer
// @ts-ignore
export const validate =
  (dispatch: Function) =>
  (key: string, value: string, validationKey?: string) => {
    const failedValidation = (validations[validationKey || key] || []).find(
      (validation) => !validation.isValid(value)
    );
    failedValidation &&
      dispatch({
        type: 'error',
        field: key,
        value: failedValidation.message,
      });
    return !failedValidation;
  };

// rewriting to V2 because of empty object hijacking inferred return type
// see: https://github.com/microsoft/TypeScript/issues/49952#issuecomment-1189247702
export const validateFieldV2 = (
  key: keyof typeof validations,
  value: string | undefined = ''
) => {
  if (!validations[key]) {
    return null;
  }
  const failedValidation = validations[key]?.find(
    (validation: Validation) => !validation.isValid(value)
  );
  return failedValidation || null;
};

export const isValidObject = (object: any): boolean => {
  return Object.keys(object).every((key) => {
    if (Array.isArray(object[key])) {
      return isValidArray(object[key]);
    } else {
      const validationResult = validateFieldV2(key, object[key]?.value);
      return !validationResult;
    }
  });
};

export const isValidObjectV2 = (object: Record<string, string[] | string>) => {
  return Object.keys(object).every((key) => {
    const value = object[key];
    if (Array.isArray(value)) {
      return isValidArray(value);
    } else {
      const validationResult = validateFieldV2(key, value);
      return !validationResult;
    }
  });
};
export const isValidArrayV2 = (array: any[] = []) => {
  return array.every(isValidObjectV2);
};

export const isValidArray = (array: any[] = []) => {
  return array.every(isValidObject);
};

export const isPresent = (value: string) => !!value;

export const firstNameValidation = [
  {
    message: 'First name is required',
    isValid: isPresent,
  },
];

export const lastNameValidation = [
  {
    message: 'Last name is required',
    isValid: isPresent,
  },
];

export const nameValidation = [
  {
    message: 'Name is required',
    isValid: isPresent,
  },
];

export const pluralRequiredValidation = (name: string) => [
  {
    message: `${capitalize(name)} are required`,
    isValid: isPresent,
  },
];

export const requiredValidation = (name: string) => [
  {
    message: `${capitalize(name)} is required`,
    isValid: isPresent,
  },
];

export const emailValidation = [
  {
    message: 'Email is required',
    isValid: isPresent,
  },
  {
    message: 'Email is not valid',
    // as per https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    isValid: (value: string) =>
      !!value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ),
  },
];

export const phoneValidation = [
  ...requiredValidation('phone number'),
  {
    message: 'Phone number needs at least 8 characters inluding prefix',
    isValid: (value: string) => value.length >= 8,
  },
];

export const passwordValidation = [
  {
    message: 'Password is required',
    isValid: isPresent,
  },
  {
    message:
      'Password needs at least 10 characters including one uppercase, one number, one special',
    isValid: (value: string) =>
      !!value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]).{10,}$/
      ),
  },
];

export const zipValidation = [
  {
    message: 'Invalid zip code',
    isValid: (value: string) => value.match(/\d{5}/) !== null,
  },
];

export const birthdateValidation = [
  ...requiredValidation('date of birth'),
  {
    message: 'Invalid date',
    isValid: (value: string) => value.match(/[1-9]\d{3}-\d{2}-\d{2}/) !== null,
  },
  {
    message: 'Date of birth cannot be in the future',
    isValid: (value: string) => new Date(value) <= new Date(),
  },
];

export const validations: Record<string, Validation[]> = {
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  name: nameValidation,
  email: emailValidation,
  currentPassword: [passwordValidation[0]!],
  password: passwordValidation,
  passwordConfirmation: passwordValidation,
  zip: zipValidation,
  telephone: phoneValidation,
  phone: phoneValidation,
  relationship: requiredValidation('relationship'),
  allergies: pluralRequiredValidation('allergies'),
  sleep: requiredValidation('sleep'),
  potty: requiredValidation('potty trained'),
  birthdate: birthdateValidation,
  terms: requiredValidation('terms'),
  amount: requiredValidation('gift amount'),
  companyName: requiredValidation('company name'),
  companySize: requiredValidation('company size'),
};
