import { Link, useNavigation } from '@react-navigation/native';
import {
  GestureResponderEvent,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  Pressable,
  StyleSheet,
} from 'react-native';

import BumoVerified from './BumoVerified';
import { LocationCtaButton } from './Button';
import CallOut from './CallOut';
import Favorite from './Favorite';
import { Image } from './Image';
import ImageCarousel from './ImageCarousel';
import InfoPopup from './InfoPopup';
import { BookingBlockProps, BookingStatus } from './LocationBlockXL';
import LocationDetail from './LocationDetail';
import Row from './Row';
import { TextBody, TextH4 } from './StyledText';
import { StyleProps, View } from './Themed';
import BookingByWeekTag from './booking_details/BookingByWeekTag';
import Details from './location_blocks/Details';
import HoursDetails from './location_blocks/HoursPriceDetails';
import { LocationSlotsButtons } from './location_blocks/LocationSlots';
import { Location } from '../api/search';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { useOpenLocationPDP } from '../hooks/useOpenLocationPDP';
import { useMaxWidth } from '../hooks/useResponsive';
import { formatBlackoutDates, isBookByWeek } from '../utils/bookings';
import { handleLink } from '../utils/common';
import { dateRangeToNumeric, todayOrTomorrow } from '../utils/date';

export type LocationBlockProps = StyleProps & {
  location: Location;
  width?: number;
  onPress?: () => void;
  openInNewTab?: boolean;
  imageDimensions?: { width: number; height: number };
  customTag?: React.ReactNode;
};

export function BookingBlockLarge({ booking, style, type }: BookingBlockProps) {
  const theme = useColorScheme();
  const { navigate } = useNavigation();
  const {
    id,
    dropoff,
    pickup,
    children: childIds,
    status,
    paymentUrl,
    location: {
      name,
      nameId,
      timezone,
      verificationStatus,
      images,
      id: locationId,
    },
  } = booking;
  const dropoffDate = new Date(dropoff);
  const pickupDate = new Date(pickup);

  const { children = [] } = useParent();
  const soon = todayOrTomorrow(dropoffDate);
  const openPDPLink = useOpenLocationPDP(nameId);

  const bookByWeek = isBookByWeek(booking);

  const daysFormatted = dateRangeToNumeric([dropoffDate, pickupDate], {
    // weekday: 'short',
    month: 'short',
    day: 'numeric',
    timeZone: timezone,
  });

  const dropoffFormatted = dropoffDate.toLocaleString('en-US', {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
  });

  const bookingDateFormatted = bookByWeek
    ? `${daysFormatted} at ${dropoffFormatted}`
    : `${soon ? `${soon}, ` : ''}${dropoffDate.toLocaleString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}`;

  const blackoutDatesFormatted = formatBlackoutDates(booking);

  return (
    <View style={[styles.container, style]}>
      <Image
        style={[styles.image as ImageStyle, styles.imageLarge as ImageStyle]}
        source={images[0] as ImageSourcePropType}
      />
      <Favorite style={styles.favorite} locationId={locationId} />
      <BumoVerified style={styles.verified} status={verificationStatus} />
      {bookByWeek ? (
        <Row style={{ justifyContent: 'flex-start', marginTop: 10 }}>
          <BookingByWeekTag />
        </Row>
      ) : null}
      <Pressable
        onPress={() => navigate('BookingDetails', { id: id.toString() })}
      >
        <TextH4 style={[styles.title, { height: 'auto' }]}>{name}</TextH4>
      </Pressable>
      <Row style={[styles.details, styles.bookingDetails]}>
        <BookingStatus status={status} />
      </Row>
      <Row style={[styles.details, styles.bookingDetails]}>
        <LocationDetail
          icon="profile"
          text={children
            .filter((child) => childIds.includes(child.id))
            .map((child) => child.firstName)
            .join(', ')}
        />
      </Row>

      <Row style={[styles.details, styles.bookingDetails]}>
        <LocationDetail
          icon={bookByWeek ? 'dateRange' : 'calendar'}
          text={bookingDateFormatted}
        />
      </Row>
      {blackoutDatesFormatted ? (
        <Row style={[styles.details, styles.bookingDetails]}>
          <LocationDetail
            icon="dateBlackout"
            iconStyle={{ tintColor: Colors[theme].error }}
            text={`Blackout Date: ${blackoutDatesFormatted}`}
            style={{ marginRight: 10 }}
          />

          <InfoPopup
            useModalOnMobile
            text="This date is not included in this weeklong booking and therefore you were not charged."
            iconStyle={{ width: 20, height: 20, margin: 0 }}
          />
        </Row>
      ) : null}
      <Row style={styles.buttons}>
        <LocationCtaButton
          style={styles.button}
          left="View Details"
          onPress={() => navigate('BookingDetails', { id: id.toString() })}
        />
        {paymentUrl ? (
          <LocationCtaButton
            style={styles.button}
            left="Pay Now"
            onPress={() => handleLink(paymentUrl)}
          />
        ) : null}
        {type === 'past' && (
          <LocationCtaButton
            style={styles.button}
            left="Book again"
            onPress={() => openPDPLink()}
          />
        )}
      </Row>
    </View>
  );
}

export function LocationBlockLarge({
  location,
  style,
  openInNewTab,
  width = 270,
  customTag,
}: LocationBlockProps) {
  const {
    id,
    name,
    distance,
    ageFrom,
    ageTo,
    hours,
    pricing,
    verificationStatus,
    nameId,
    images = [],
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    reviews,
    price,
  } = location;

  const hasWeekly = location.bookingMode.includes('weekly');
  const onlyWeekly = hasWeekly && location.bookingMode.length === 1;

  const openPDPLink = useOpenLocationPDP(nameId);
  const handlePress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openPDPLink(openInNewTab);
  };

  const handleMorePhotosPress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openPDPLink(openInNewTab, { photos: true });
  };
  const isMobileDevice = useMaxWidth(430);

  return (
    <View
      style={[styles.container, styles.locationContainer, { width }, style]}
      testID={location.nameId}
    >
      <Link
        to={{
          screen: 'Location',
          params: {
            nameId,
          },
        }}
        style={styles.linkContainer}
        onPress={(e) => {
          e.preventDefault();
          handlePress(e as GestureResponderEvent);
        }}
        accessibilityLabel={`Link to location: ${location.name}`}
      >
        <ImageCarousel
          style={[styles.image, styles.imageLarge, { width: '100%' }]}
          images={images}
          dimensions={{ width, height: isMobileDevice ? 182 : 220 }}
          showMaximum={6}
          onMoreButtonPress={handleMorePhotosPress}
        />
        <Favorite style={styles.favorite} locationId={id} />
        {customTag || (
          <BumoVerified style={styles.verified} status={verificationStatus} />
        )}
        <TextH4 style={[styles.title, styles.smallTitle]}>{name}</TextH4>
        <Details
          {...{
            ageFrom,
            ageTo,
            city,
            neighbourhood,
            distance,
            reviews,
            bookByWeek: onlyWeekly,
            pricing,
            price,
          }}
        />
        <HoursDetails hours={hours} />
        <Row style={styles.callouts}>
          <LocationCallouts location={location} />
        </Row>

        <Row style={styles.buttons}>
          <LocationSlotsButtons
            location={location}
            handleAllPress={handlePress}
          />
        </Row>
      </Link>
    </View>
  );
}

export function LocationCallouts({ location }: { location: Location }) {
  const { callouts } = location;
  return (
    <>
      {callouts.slice(0, 3).map((callout, idx) => (
        <CallOut key={idx} style={styles.callout} title={callout} />
      ))}
    </>
  );
}

export function LocationBlockMedium({
  location,
  style,
  openInNewTab,
}: LocationBlockProps) {
  const theme = useColorScheme();
  const {
    name,
    distance,
    ageFrom,
    ageTo,
    nameId,
    images = [],
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    reviews,
    price,
    pricing,
  } = location;
  const Text = Layout.isMobileDevice ? TextBody : TextH4;

  const openPDPLink = useOpenLocationPDP(nameId);
  const handlePress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openPDPLink(openInNewTab);
  };
  const isMobileDevice = useMaxWidth(430);
  return (
    <View
      style={[
        styles.container,
        styles.locationContainer,
        styles.topPicks,
        { borderColor: Colors[theme].lines },
        style,
      ]}
    >
      <Pressable onPress={handlePress}>
        <Image
          style={[styles.image, styles.imageSmall] as ImageStyle[]}
          source={images[0] as ImageSourcePropType}
        />
        <View style={styles.smallInner}>
          <Text
            style={[
              styles.title,
              styles.smallTitle,
              isMobileDevice && styles.titleMobile,
            ]}
          >
            {name}
          </Text>
          <Details
            {...{
              ageFrom,
              ageTo,
              city,
              neighbourhood,
              distance,
              reviews,
              bookByWeek:
                location.bookingMode.includes('weekly') &&
                location.bookingMode.length === 1,
              price,
              pricing,
            }}
            containerStyle={
              isMobileDevice && {
                minHeight: 80,
                alignContent: 'flex-start',
              }
            }
          />
          <Row style={[styles.buttons, styles.smallButtons]}>
            <LocationCtaButton
              style={[styles.button, { width: '100%' }]}
              left="Book"
              onPress={handlePress}
            />
          </Row>
        </View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 270,
        }),
      },
    }),
  },
  locationContainer: {
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
      },
    }),
  },
  smallInner: {
    backgroundColor: 'transparent',
    marginHorizontal: 20,
    marginBottom: 20,
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          marginHorizontal: 10,
          marginBottom: 10,
          margin: 10,
        }),
      },
    }),
  },
  // @ts-ignore
  smallTitle: {
    overflow: 'hidden',
    minHeight: 'auto',
    ...Platform.select({
      web: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        textOverflow: 'ellipsis',
        ...(Layout.isLargeDevice && {
          height: 52,
        }),
      },
    }),
  },
  topPicks: {
    borderWidth: 1,
    borderRadius: 10,
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          width: 183,
        }),
      },
    }),
  },
  details: {
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
  },
  bookingDetails: {
    marginTop: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  favorite: {
    top: 5,
  },
  image: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 270,
          height: 220,
        }),
        ...(Layout.isMobileDevice && {
          height: 182,
        }),
      },
    }),
  },
  imageSmall: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {}),
        ...(Layout.isMobileDevice && {
          width: 183,
          height: 100,
        }),
      },
    }),
  },
  imageLarge: {
    borderRadius: 10,
  },
  // @ts-ignore
  title: {
    marginTop: 10,
    height: 52,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        textOverflow: 'ellipsis',
      },
    }),
  },
  titleMobile: {
    minHeight: 1,
    height: 42,
    marginTop: 0,
  },
  verified: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  callouts: {
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 10,
    minHeight: 62,
  },
  callout: {
    marginRight: 15,
  },
  buttonContainer: {
    padding: 2,
    width: '50%',
  },
  buttons: {
    marginTop: 10,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: 5,
    marginRight: 5,
  },
  smallButtons: {
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          marginTop: 5,
        }),
      },
    }),
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});
