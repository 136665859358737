import { ImageStyle, Platform, StyleProp, StyleSheet } from 'react-native';

import { titleize } from '../../utils/api';
import { Image } from '../Image';
import { TextBody, TextH3 } from '../StyledText';
import { View, StyleProps } from '../Themed';

export interface IDirector {
  name: string;
  role: string;
  photo?: string;
  description: string;
}

type DirectorProps = StyleProps & {
  director: IDirector;
  imageStyle?: StyleProp<ImageStyle>;
};

export const DEFAULT_ROLE = 'Director';

export default function Director({
  director,
  style,
  imageStyle,
}: DirectorProps) {
  const { name, photo, description, role = DEFAULT_ROLE } = director;
  const Container = Platform.OS === 'web' ? TextBody : View;
  return (
    <View style={style}>
      <TextH3>{`${titleize(role)} Bio - ${name}`}</TextH3>
      <Container style={styles.row}>
        {!!photo && (
          <Image source={{ uri: photo }} style={[styles.image, imageStyle]} />
        )}
        <TextBody>{description}</TextBody>
      </Container>
    </View>
  );
}

const styles = StyleSheet.create({
  description: {
    marginTop: 15,
  },
  image: {
    float: 'left',
    backgroundColor: '#ccc',
    width: 180,
    height: 180,
    borderRadius: 10,
    marginRight: 15,
    ...(Platform.OS !== 'web' && {
      marginBottom: 15,
    }),
  },
  row: {
    marginTop: 15,
  },
});
