import { useState } from 'react';
import { StyleSheet } from 'react-native';

import { IconButton, PrimaryButton, TagButton } from '../Button';
import { Icon } from '../Icon';
import { TextCtaButton } from '../StyledText';
import { View, ViewProps } from '../Themed';

type OnboardingNavigationProps = ViewProps & {
  onNext: () => void;
  next: string;
  onBack?: () => void;
  disabled: boolean;
  nextDisabled: boolean;
};
export function Navigation({
  onNext,
  onBack,
  back,
  next,
  disabled,
  nextDisabled,
  nextLoading,
  style,
}: OnboardingNavigationProps & {
  nextLoading: boolean;
  back?: string | null;
}) {
  return (
    <View style={[styles.container, style]}>
      {back === null ? null : (
        <IconButton
          testID="back-button"
          icon={<Icon name="arrowBack" />}
          style={styles.backButton}
          text={<TextCtaButton>Back</TextCtaButton>}
          disabled={disabled}
          onPress={onBack}
        />
      )}
      <PrimaryButton
        testID="next-button"
        style={styles.button}
        title={next}
        loading={nextLoading}
        disabled={nextDisabled || disabled || nextLoading}
        onPress={onNext}
      />
    </View>
  );
}

export function useShowHideButton() {
  const [showing, setShowing] = useState(false);
  const Button = showing ? (
    <TagButton onPress={() => setShowing(false)}>Hide</TagButton>
  ) : (
    <TagButton onPress={() => setShowing(true)}>Show</TagButton>
  );
  return {
    showing,
    Button,
  };
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
  },
  backButton: {
    paddingHorizontal: 0,
    marginRight: 24,
    flex: 0.8,
  },
});
