import { useRollbar } from '@rollbar/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  useAuthDispatch,
  useAuthState,
  useIsLoggedIn,
  useSetInitialParent,
} from './authentication';
import { useSnackbarDispatch } from './snackbar';
import { applyCreditToken } from '../api/bumoCredits';
import { shouldAskForData } from '../api/onboardingV2';
import { getMe } from '../api/parents';
import { TextButton } from '../components/Button';
import { Icon } from '../components/Icon';
import { TransparentRow } from '../components/Row';
import { TextBodySmall } from '../components/StyledText';
import { ForgotPasswordModal } from '../components/authentication/ForgotPasswordModal';
import { JoinNowModal } from '../components/authentication/JoinNowModal';
import { LoginModal } from '../components/authentication/LogInModal';
import OnboardingModal from '../components/authentication/OnboardingModal';
import { navigateFromRef } from '../hooks/useAppNavigation';
import useBlockBodyScroll from '../hooks/useBlockBodyScroll';
import { usePrevious } from '../hooks/usePrevious';
import {
  getAccountCreatedBumoCreditAppliedSnackbar,
  getAccountCreatedSnackbar,
  getAlreadyHaveAccountSnackbar,
  getBumoCreditAlreadyAppliedSnackbar,
  getBumoCreditAppliedSnackbar,
  getReferralAppliedSnackbar,
} from '../snackbars';
import { clearBookingsState } from '../store/bookings';

/*
  state: {
    ACTION: 'nextState'
  },
*/
const AuthenticationStateMachine = {
  loading: {
    LOADING_LOGGED_IN: 'checking',
    LOADING_LOGGED_OUT: 'hidden',
  },
  checking: {
    LOGGED_IN: 'checking',
    MISSING_DATA: 'onboardingProfile',
    OK: 'authenticated',
  },
  hidden: {
    LOGGED_OUT: 'hidden',
    LOGIN: 'login',
    JOIN: 'joinNow',
    LOGGED_IN: 'checking',
  },
  login: {
    LOGGED_OUT: 'login',
    JOIN: 'joinNow',
    LOGGED_IN: 'checking',
    HIDE: 'hidden',
    FORGOT: 'forgotPasswordLogin',
  },
  forgotPasswordLogin: {
    HIDE: 'login',
  },
  joinNow: {
    LOGGED_OUT: 'joinNow',
    LOGGED_IN: 'joinNow',
    LOGIN: 'login',
    JOINED: 'onboardingProfile',
    HIDE: 'hidden',
    FORGOT: 'forgotPasswordJoin',
    LOGIN_FALLBACK: 'checking',
  },
  forgotPasswordJoin: {
    HIDE: 'joinNow',
  },
  onboardingProfile: {
    CONTINUE: 'onboardingChildren',
    LOGGED_IN: 'onboardingProfile',
  },
  onboardingChildren: {
    BACK: 'onboardingProfile',
    CONTINUE: 'authenticated',
    CONTINUE_TO_REFERRAL: 'onboardingReferralInfo',
  },
  onboardingReferralInfo: {
    CONTINUE: 'authenticated',
  },
  authenticated: {
    LOGGED_OUT: 'hidden',
  },
} as const;

type Machine = typeof AuthenticationStateMachine;
type PossibleState = keyof Machine;
type Action = { [k in PossibleState]: keyof Machine[k] };

const loggedInStates: PossibleState[] = [
  'checking',
  'onboardingChildren',
  'onboardingProfile',
  'onboardingReferralInfo',
  'authenticated',
];

export type AuthenticateFnParams = {
  leadToken?: string | undefined;
  creditToken?: string | undefined;
  source?: 'PAID_AD' | 'BUMO_PACKAGE_BUY' | 'REFERRAL' | undefined;
  refferalCode?: string;
};

const AuthenticateMethodContext = React.createContext([
  async () => {},
  'loading',
] as const as [
  (mode?: 'LOGIN', params?: AuthenticateFnParams) => Promise<void>,
  PossibleState,
]);

export const useAuthenticate = () => {
  const [auth] = useContext(AuthenticateMethodContext);
  return auth;
};

export const useIsAuthEstablished = () => {
  const [, currentState] = useContext(AuthenticateMethodContext);
  return currentState !== 'loading';
};

export const useCurrentAuthFlowState = () => {
  const [, currentState] = useContext(AuthenticateMethodContext);
  return currentState;
};

export const AuthFlowProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatchSnackbar = useSnackbarDispatch();
  const { parent } = useAuthState();
  const paramsRef = useRef<AuthenticateFnParams | undefined>(undefined);
  const [state, send] = useReducer(
    <S extends PossibleState>(state: S, action: Action[S]): PossibleState => {
      const nextState = AuthenticationStateMachine[state][
        action
      ] as PossibleState;
      if (state === 'loading' && !action.toString().startsWith('LOADING_')) {
        return state;
      }
      if (
        !(nextState in AuthenticationStateMachine) &&
        process.env.NODE_ENV === 'development'
      ) {
        console.warn(
          `Unrecognized transition state: ${state} => ${nextState} for action ${action.toString()}`
        );
        return state;
      }
      return nextState !== undefined ? nextState : state;
    },
    'loading'
  );

  const updateAuthState = useAuthDispatch();
  const setAuthStateInitialParent = useSetInitialParent();

  // kickoff profile fetch
  useEffect(() => {
    async function loadParent() {
      try {
        updateAuthState({ type: 'loading' });
        const me = await getMe();
        send(me ? 'LOADING_LOGGED_IN' : 'LOADING_LOGGED_OUT');
        setAuthStateInitialParent(me);
      } catch (err) {
        console.trace('load parent');
        console.error('There was an error while fetching the profile.', err);
        send('LOADING_LOGGED_OUT');
      }
    }
    loadParent();
  }, []);

  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    send(isLoggedIn ? 'LOGGED_IN' : 'LOGGED_OUT');
  }, [isLoggedIn]);

  const [lead, setLead] = useState<null | {
    email: string;
    name: string;
    telephone: string;
  }>(null);

  const [resolvePromise, setResolvePromise] = useState<
    null | ((value: void | PromiseLike<void>) => void)
  >(null);

  const [rejectPromise, setRejectPromise] = useState<
    null | ((reason: any) => void)
  >(null);

  const authenticate = useCallback(
    (mode?: 'LOGIN', params?: AuthenticateFnParams) => {
      return new Promise<void>((resolve, reject) => {
        if (state === 'loading') {
          return;
        }
        if (state === 'login' || state === 'joinNow') {
          return;
        }
        if (state === 'authenticated') {
          resolve();
          return;
        }
        setResolvePromise((_: any) => resolve);
        setRejectPromise((_: any) => reject);
        paramsRef.current = params;

        send(mode || 'JOIN');
      });
    },
    [state]
  );

  const previousState = usePrevious(state);

  // apply purchased bumo credits
  useApplyBumoCredits(previousState, state, paramsRef.current);

  useReferralToasts(previousState, state, paramsRef.current);

  // clear bookings after logout
  useSyncBookingsOnLogout(previousState, state);

  // onboarding snackbar
  useEffect(() => {
    const hasFinishedOnboarding =
      (previousState === 'onboardingChildren' ||
        previousState === 'onboardingReferralInfo') &&
      state === 'authenticated';
    if (!hasFinishedOnboarding) {
      return;
    }

    if (paramsRef.current?.source === 'REFERRAL') {
      return;
    }

    if (paramsRef.current?.source === 'PAID_AD') {
      dispatchSnackbar({
        message: (
          <TransparentRow style={{ width: '100%' }}>
            <Icon name="explore" style={{ marginRight: 10 }} />

            <TextBodySmall>
              Congrats! Your account has been created and{' '}
              <TextButton
                onPress={() => navigateFromRef('Profile')}
                testID="snack-profile-link"
              >
                $50 in Child Care Credit
              </TextButton>{' '}
              has been added to it. 🎉 Start booking now!
            </TextBodySmall>
          </TransparentRow>
        ),
      });
      return;
    }

    if (!lead) {
      dispatchSnackbar({
        message: getAccountCreatedSnackbar(),
      });
      return;
    }
    dispatchSnackbar({
      message: (
        <TransparentRow style={{ width: '100%' }}>
          <Icon name="explore" style={{ marginRight: 10 }} />

          <TextBodySmall>
            Yay! You received $50 in Child Care Credit which you can view in
            your{' '}
            <TextButton onPress={() => navigateFromRef('Profile')}>
              profile
            </TextButton>{' '}
            page. Start booking now!
          </TextBodySmall>
        </TransparentRow>
      ),
    });
  }, [state, previousState, lead]);

  useEffect(() => {
    switch (state) {
      case 'checking':
        if (!!parent && shouldAskForData(parent)) {
          send('MISSING_DATA');
          return;
        }
        send('OK');
        return;
      case 'hidden': {
        rejectPromise?.('hidden');
        return;
      }
      case 'authenticated': {
        resolvePromise?.();
        setResolvePromise(null);
        setRejectPromise(null);
      }
    }
  }, [state, rejectPromise, resolvePromise]);

  useBlockBodyScroll(
    state !== 'hidden' && state !== 'authenticated' && state !== 'checking'
  );

  const hide = useCallback(() => send('HIDE'), [send]);

  const isLoggedInState = loggedInStates.includes(state);

  return (
    <>
      {!isLoggedInState ? (
        <>
          <JoinNowModal
            isVisible={state === 'joinNow'}
            hide={hide}
            onSuccess={() => send('JOINED')}
            showLoginModal={() => send('LOGIN')}
            onForgotPasswordClick={() => send('FORGOT')}
            params={paramsRef.current}
            onLeadUpdate={setLead}
            onFallbackLogin={() => send('LOGIN_FALLBACK')}
          />
          <LoginModal
            isVisible={state === 'login'}
            hide={hide}
            params={paramsRef.current}
            showJoinNowModal={() => send('JOIN')}
            onForgotPasswordClick={() => send('FORGOT')}
          />
          <ForgotPasswordModal
            isVisible={
              state === 'forgotPasswordLogin' || state === 'forgotPasswordJoin'
            }
            hide={() => send('HIDE')}
          />
        </>
      ) : (
        <>
          <OnboardingModal
            step={
              state === 'onboardingProfile'
                ? 1
                : state === 'onboardingChildren'
                  ? 2
                  : 3
            }
            isVisible={
              state === 'onboardingProfile' ||
              state === 'onboardingChildren' ||
              state === 'onboardingReferralInfo'
            }
            goBack={() => send('BACK')}
            onSuccess={() => send('CONTINUE')}
            continueToReferral={() => send('CONTINUE_TO_REFERRAL')}
            lead={lead}
          />
        </>
      )}
      <AuthenticateMethodContext.Provider value={[authenticate, state]}>
        {children}
      </AuthenticateMethodContext.Provider>
    </>
  );
};

function useApplyBumoCredits(
  previousState: PossibleState | null,
  state: PossibleState,
  params: AuthenticateFnParams | undefined = {}
) {
  const dispatchSnackbar = useSnackbarDispatch();
  const updateAuth = useAuthDispatch();
  const rollbar =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? {
          debug: (v: any) => {
            console.debug('[ROLLBAR DEBUG]', v);
          },
        }
      : useRollbar();
  useEffect(() => {
    if (!params.creditToken || params.source !== 'BUMO_PACKAGE_BUY') {
      return;
    }
    const prevStates: PossibleState[] = [
      'login',
      'joinNow',
      'checking',
      'onboardingChildren',
    ];
    const currentStates: PossibleState[] = ['authenticated'];
    if (
      previousState &&
      prevStates.includes(previousState) &&
      currentStates.includes(state)
    ) {
      const snackbarFn =
        previousState === 'onboardingChildren'
          ? getAccountCreatedBumoCreditAppliedSnackbar
          : getBumoCreditAppliedSnackbar;

      applyCreditToken(params.creditToken).then((result) => {
        if (result.ok) {
          const { balance, reward } = result.body;
          updateAuth({ type: 'balance', value: balance });
          dispatchSnackbar({
            message: snackbarFn(reward),
          });
          return;
        }
        if (result.code === 'ALREADY_APPLIED') {
          dispatchSnackbar({
            message: getBumoCreditAlreadyAppliedSnackbar(),
          });
          return;
        }
        rollbar.debug(
          `There was a problem with applying credit token: ${params.creditToken}`
        );
      });
    }
  }, [previousState, state]);
}

function useSyncBookingsOnLogout(
  previousState: PossibleState | null,
  state: PossibleState
) {
  useEffect(() => {
    if (previousState === 'authenticated' && state === 'hidden') {
      clearBookingsState();
    }
  }, [previousState, state]);
}

function useReferralToasts(
  previousState: PossibleState | null,
  state: PossibleState,
  params: AuthenticateFnParams | undefined = {}
) {
  const dispatchSnackbar = useSnackbarDispatch();
  useEffect(() => {
    if (params.source !== 'REFERRAL') {
      return;
    }
    if (state !== 'authenticated') {
      return;
    }

    // user was already logged in
    if (previousState === 'checking') {
      dispatchSnackbar({
        message: getAlreadyHaveAccountSnackbar(),
      });
      return;
    }

    // user finished onboarding process
    if (
      previousState === 'onboardingChildren' ||
      previousState === 'onboardingReferralInfo'
    ) {
      dispatchSnackbar({
        message: getReferralAppliedSnackbar(),
      });
    }
  }, [previousState, state]);
}
