import { Image, StyleProp, View, ViewStyle } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { findCurrentCampaignKey } from '../../utils/campaigns';
import { CdnImageName } from '../../utils/common';
import { TextH3, TextH1, QuincyRegularText } from '../StyledText';
import { HeroContent } from '../explore/HeroWithImage';

const BlackFridayCopy = {
  saleAlertVisible: true,
  addonsVisible: true,
  heroTitle: 'Black Friday deals are here 🎉. Save up to $1950 on Child Care!',
  image: 'BFCM-promo-hero-image.webp',
};

const CyberMondayCopy = {
  saleAlertVisible: true,
  addonsVisible: true,
  heroTitle: 'Cyber Monday deals are here 🎉. Save up to $1950 on Child Care!',
  image: 'BFCM-promo-hero-image.webp',
};

const ClassicCopy = {
  saleAlertVisible: false,
  addonsVisible: false,
  heroTitle: 'Get BumoCredit and Save on Child Care!',
  image: 'bumo-credit-hero-image.webp',
};

export function selectCurrentCampaignCopy() {
  const key = findCurrentCampaignKey();
  switch (key) {
    case 'BLACK_FRIDAY_2024':
      return BlackFridayCopy;
    case 'CYBER_MONDAY_2024':
      return CyberMondayCopy;
    default:
      return ClassicCopy;
  }
}

export default function BumoCreditHeaderTextSection({
  children,
  isMobileDevice = true,
  wrapperStyle,
  textWrapperStyle,
}: {
  children?: React.ReactNode;
  isMobileDevice?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  textWrapperStyle?: StyleProp<ViewStyle>;
}) {
  const theme = useColorScheme();
  const copy = selectCurrentCampaignCopy();

  return (
    <>
      {isMobileDevice || !copy.addonsVisible ? null : (
        <Image
          source={require('../../assets/images/star-green.png')}
          style={{
            width: 130,
            height: 130,
            position: 'absolute',
            top: 30,
            left: '50%',
            marginLeft: -65,
            zIndex: 10,
          }}
          tintColor={'#2B9B58'}
        />
      )}

      {isMobileDevice || !copy.addonsVisible ? null : (
        <Image
          source={require('../../assets/images/spring.png')}
          style={{
            width: 76,
            height: 221,
            position: 'absolute',
            top: 180,
            right: 0,
            zIndex: 10,
            transform: [{ rotate: '135deg' }],
          }}
          // tintColor={'#2B9B58'}
        />
      )}
      <HeroContent.Text
        style={textWrapperStyle}
        wrapperStyle={[{ justifyContent: 'center' }, wrapperStyle]}
      >
        {copy.saleAlertVisible ? (
          <View
            style={{
              alignSelf: 'flex-start',
              backgroundColor: Colors[theme].accent,
              padding: 10,
              marginBottom: 20,
            }}
          >
            <TextH3>SALE ALERT</TextH3>
          </View>
        ) : null}
        <TextH1>{copy.heroTitle}</TextH1>
        <QuincyRegularText
          style={{
            fontSize: 25,
            marginTop: 10,
          }}
        >
          Purchase BumoCredit in order to get more child care for less. When you
          buy more, you save more.{' '}
        </QuincyRegularText>
        {children}
      </HeroContent.Text>
    </>
  );
}

export function BumoCreditHeaderImage({
  style,
}: {
  style?: StyleProp<ViewStyle>;
}) {
  const copy = selectCurrentCampaignCopy();
  return (
    <HeroContent.Image
      forceImage
      imageFileName={copy.image as CdnImageName}
      style={style}
    />
  );
}
