import React, { useEffect, useState, createContext, useContext } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { Price, getPrices } from '../../api/locations';
import { Pricing } from '../../api/search';
import Colors from '../../constants/Colors';
import { StaticTexts } from '../../constants/StaticTexts';
import useColorScheme from '../../hooks/useColorScheme';
import {
  formatPrice,
  getAgeRangeText,
  getReadableAgeString,
} from '../../utils/locations';
import { TransparentRow } from '../Row';
import { TextBody, TextFinePrint, TextH3 } from '../StyledText';
import { View, StyleProps } from '../Themed';

type PricesProps = StyleProps & {
  locationId: number;
  onFetched?: (prices: MappedPrices) => void;
  hasWeeklyDiscount?: boolean;
  penalty: Pricing['penalty'];
};

function mapPricesToTable(pricesItems: Price[]) {
  const groupedByAgeBands = pricesItems
    .map((price) => ({ price, ageBand: `${price.ageFrom}-${price.ageTo}` }))
    .reduce((acc, cur) => {
      const ageBandObject = {
        ...(acc[cur.ageBand] || {
          ageInfo: {
            from: cur.price.ageFrom,
            to: cur.price.ageTo,
            label: getAgeRangeText(cur.price.ageFrom, cur.price.ageTo),
          },
          priceCategories: {
            weekday: {
              priceLow: Infinity,
              priceHigh: -Infinity,
            },
            weekend: {
              priceLow: Infinity,
              priceHigh: -Infinity,
            },
            special: {
              priceLow: Infinity,
              priceHigh: -Infinity,
            },
            extended: {
              priceLow: Infinity,
              priceHigh: -Infinity,
            },
          },
        }),
      };
      const category =
        ageBandObject.priceCategories[
          cur.price.category as keyof typeof ageBandObject.priceCategories
        ];

      const { price } = cur.price;
      if (price < category.priceLow) {
        category.priceLow = price;
      }
      if (price > category.priceHigh) {
        category.priceHigh = price;
      }
      return {
        ...acc,
        [cur.ageBand]: ageBandObject,
      };
    }, {} as MappedPrices);

  return groupedByAgeBands;
}

export type MappedPrices = Record<
  string,
  {
    ageInfo: { from: number; to: number; label: string };
    priceCategories: {
      weekday: { priceLow: number; priceHigh: number };
      weekend: { priceLow: number; priceHigh: number };
      special: { priceLow: number; priceHigh: number };
      extended: { priceLow: number; priceHigh: number };
    };
  }
>;

export const PricesHelperContext = createContext<MappedPrices | null>(null);

export const useLocationPricesHelpers = () => {
  const ctx = useContext(PricesHelperContext);
  if (!ctx) {
    return {
      getLowestPriceForAge: () => null,
    };
  }

  return {
    getLowestPriceForAge: (age: number) => {
      const priceRow = Object.values(ctx).find(
        (v) => v.ageInfo.from <= age && v.ageInfo.to >= age
      );
      if (!priceRow) {
        return null;
      }
      const min = Math.min(
        ...Object.values(priceRow.priceCategories).map((p) => p.priceLow)
      );
      return min;
    },
  };
};

function formatCategoryPrice(category: {
  priceLow: number;
  priceHigh: number;
}) {
  if (category.priceLow === Infinity) {
    return <>&mdash;</>;
  }
  if (category.priceLow === category.priceHigh) {
    return `${formatPrice(category.priceLow)}/hr`;
  }
  return `${formatPrice(category.priceLow)}-${formatPrice(
    category.priceHigh
  )}/hr`;
}

export function getPenaltyInfo(penalty: Pricing['penalty']) {
  if (!penalty || penalty.mode === 'default') {
    return {
      price: 25,
      minutes: 15,
    };
  }
  if (penalty.mode === 'disabled') {
    return null;
  }
  return {
    price: penalty.price,
    minutes: penalty.minutes,
  };
}

export default function Prices({
  locationId,
  onFetched,
  style,
  hasWeeklyDiscount,
  penalty,
  TableWrapper = React.Fragment,
}: PricesProps & {
  TableWrapper?: React.ComponentType<{ children: React.ReactNode }>;
}) {
  const [prices, setPrices] = useState<MappedPrices | null>({});

  useEffect(() => {
    getPrices(locationId).then((prices) => {
      if (prices.length === 0) {
        setPrices(null);
        return;
      }
      const mappedPrices = mapPricesToTable(prices);
      setPrices(mappedPrices);
      onFetched?.(mappedPrices);
    });
  }, []);

  if (!prices) {
    return null;
  }

  const penaltyInfo = getPenaltyInfo(penalty);

  const categoriesAvailable = (
    ['weekday', 'weekend', 'extended', 'special'] as const
  ).filter((categoryName) => {
    return !Object.values(prices).every(
      (priceRow) => priceRow.priceCategories[categoryName].priceLow === Infinity
    );
  });

  return (
    <View style={[styles.container, style]}>
      <TextH3>Price</TextH3>
      <TextBody style={styles.content}>
        We believe in transparent pricing. We offer different prices based on
        your child's age, day or time of week. These prices are subject to
        change and may not be comprehensive. They are as follows:
      </TextBody>

      <TableWrapper>
        <Table>
          <TransparentRow>
            <Cell header text="Age Band" noLeft />
            {categoriesAvailable.includes('weekday') && (
              <Cell header text="Weekday" />
            )}
            {categoriesAvailable.includes('weekend') && (
              <Cell header text="Weekend" />
            )}
            {categoriesAvailable.includes('extended') && (
              <Cell header text="Extended" />
            )}
            {categoriesAvailable.includes('special') && (
              <Cell header text="Special" />
            )}
          </TransparentRow>
          {Object.keys(prices)
            .sort((priceGroupIdA, priceGroupIdB) => {
              const priceRowA = prices[priceGroupIdA];
              const priceRowB = prices[priceGroupIdB];

              if (!priceRowA || !priceRowB) {
                return -1;
              }
              return priceRowA.ageInfo.from - priceRowB.ageInfo.from;
            })
            .map((priceGroupId, idx) => {
              const priceRow = prices[priceGroupId];

              if (!priceRow) {
                return null;
              }

              const { from, to } = priceRow.ageInfo;
              const label = `${getReadableAgeString(
                from
              )} to < ${getReadableAgeString(to)}`;

              return (
                <TransparentRow key={priceGroupId}>
                  <Cell text={label} noLeft thickTop={idx === 0} />
                  {categoriesAvailable.map((categoryName) => (
                    <Cell
                      key={`${priceGroupId}-${categoryName}`}
                      text={formatCategoryPrice(
                        priceRow.priceCategories[categoryName]
                      )}
                      thickTop={idx === 0}
                      textLight
                    />
                  ))}
                </TransparentRow>
              );
            })}
        </Table>
      </TableWrapper>
      {penaltyInfo ? (
        <TextBody style={styles.paragraph}>
          We also charge ${penaltyInfo.price} every {penaltyInfo.minutes}{' '}
          minutes, when parents drop off early or pick up late without advanced
          purchase of early dropoff or late pickup sessions.
        </TextBody>
      ) : null}
      {hasWeeklyDiscount ? (
        <TextBody style={styles.paragraph}>
          {StaticTexts.pdp.bookByWeekDiscountInfo}
        </TextBody>
      ) : null}
    </View>
  );
}

function Table({ children }: { children: React.ReactNode }) {
  const theme = useColorScheme();
  return (
    <TransparentRow
      // @ts-ignore
      style={{
        justifyContent: 'flex-start',
        ...Platform.select({
          web: {
            overflow: 'auto',
            scrollbarWidth: 'none',
          },
        }),
      }}
    >
      <View
        style={{
          borderWidth: 1.5,
          borderColor: Colors[theme].lines,
          borderRadius: 10,
          alignItems: 'flex-start',
          overflow: 'hidden',
        }}
      >
        {children}
      </View>
    </TransparentRow>
  );
}

function Cell({
  text,
  header,
  noLeft,
  thickTop,
  textLight,
}: {
  text: React.ReactNode;
  header?: boolean;
  noLeft?: boolean;
  thickTop?: boolean;
  textLight?: boolean;
}) {
  const theme = useColorScheme();
  return (
    <View
      style={[
        styles.cell,
        header && styles.cellHeader,
        { borderColor: Colors[theme].lines },
        noLeft && {
          borderLeftWidth: 0,
          borderRightWidth: 1,
        },
        thickTop && {
          borderTopWidth: 1,
        },
      ]}
    >
      <TextFinePrint
        style={
          textLight && {
            color: Colors[theme].textSecondary,
          }
        }
      >
        {text}
      </TextFinePrint>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  content: {
    marginVertical: 15,
  },
  cell: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    width: 140,
    height: 30,
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  cellHeader: {
    borderBottomWidth: 0.5,
    borderTopWidth: 0,
  },
  paragraph: {
    marginTop: 15,
  },
});
