import { createContext, useContext, useState } from 'react';

import { Location } from '../api/search';

const LocationsStateContext = createContext<State | undefined>(undefined);
const LocationsUpdateContext = createContext((locations: Location[]) => {});

export const LocationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [locationsState, setLocationsState] = useState<State>({
    all: {},
    available: [],
    popular: [],
    comingSoon: [],
    search: [],
  });
  const update = (locations: Location[]) => {
    const newState = locations.reduce(
      (acc, location) => {
        const key = location.nameId;
        acc.all[key] = location;
        // groupKey && (acc[groupKey] = [...new Set([...acc[groupKey], key])]);
        return acc;
      },
      { ...locationsState }
    );
    setLocationsState(newState);
  };
  return (
    <LocationsStateContext.Provider value={locationsState}>
      <LocationsUpdateContext.Provider value={update}>
        {children}
      </LocationsUpdateContext.Provider>
    </LocationsStateContext.Provider>
  );
};

export function useLocationsState() {
  const context = useContext(LocationsStateContext);
  if (context === undefined) {
    throw new Error(
      'useLocationsState must be used within a LocationsProvider'
    );
  }

  return context;
}

export function useLocationsUpdate() {
  const context = useContext(LocationsUpdateContext);
  if (context === undefined) {
    throw new Error(
      'useLocationsDispatch must be used within a LocationsProvider'
    );
  }

  return context;
}

export function useLocation(key: string | undefined) {
  const locations = useLocationsState();
  if (!key) {
    return;
  }
  return locations.all[key];
}

export function useLocations(keys: string[]) {
  const locations = useLocationsState();
  return keys.map((key) => locations.all[key]);
}

interface State {
  all: {
    [key: string]: Location;
  };
  available: string[];
  popular: string[];
  comingSoon: string[];
  search: string[];
}
