import { useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { onboardV2 } from '../../api/onboardingV2';
import { useForm } from '../../hooks/useForm';
import { Parent } from '../../utils/api';
import { PrimaryButton } from '../Button';
import { PhoneNumberInput } from '../PhoneInput';
import { TextH3 } from '../StyledText';
import { FormInput } from '../StyledTextInput';
import { TransparentView } from '../Themed';
import ZipCodeInput from '../ZipCodeInput';

export function OnboardingProfileModal({
  lead,
  onSuccess,
  style,
  parent,
}: {
  onSuccess: (parent?: Parent) => void;
  lead: null | {
    email: string;
    name: string;
    telephone: string;
  };
  style?: ViewStyle;
  parent?: Parent;
}) {
  const [loading, setLoading] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);

  const { state, dispatch, validateFields } = useForm<{
    firstName: string;
    lastName: string;
    telephone: string;
    zip: string;
  }>({
    firstName: parent?.firstName || lead?.name || '',
    lastName: parent?.lastName || '',
    telephone: parent?.telephone || lead?.telephone || '',
    zip: parent?.zip || '',
  });

  const updateProfile = async () => {
    const { ok } = validateFields([
      'firstName',
      'lastName',
      'telephone',
      'zip',
    ]);
    if (!ok) {
      return;
    }
    if (!state.dirty) {
      onSuccess(parent);
      return;
    }
    setLoading(true);
    const onboardResult = await onboardV2(state.values);
    setLoading(false);

    if (!onboardResult.ok) {
      const { errors } = onboardResult;
      if (errors.zip && errors.zip.length > 0) {
        dispatch({
          type: 'error',
          field: 'zip',
          value: `Zip Code ${errors.zip}`,
        });
      }
      if (errors.phone && errors.phone.length > 0) {
        dispatch({
          type: 'error',
          field: 'telephone',
          value: `Phone number ${errors.phone}`,
        });
      }
      return;
    }

    onSuccess(onboardResult.parent);
  };

  return (
    <TransparentView testID="onboarding-profile-modal" style={style}>
      <TextH3 style={styles.title}>Let's finish creating your account</TextH3>
      <FormInput
        testID="first-name"
        placeholder="First Name"
        value={state.values.firstName}
        error={state.errors.firstName}
        onChangeText={(value) =>
          dispatch({ type: 'field', field: 'firstName', value })
        }
      />
      <FormInput
        testID="last-name"
        placeholder="Last Name"
        value={state.values.lastName}
        error={state.errors.lastName}
        onChangeText={(value) =>
          dispatch({ type: 'field', field: 'lastName', value })
        }
      />
      <PhoneNumberInput
        testID="phone-number"
        placeholder="Enter Phone Number"
        value={state.values.telephone}
        onChangeText={(value) =>
          dispatch({ type: 'field', field: 'telephone', value })
        }
        error={state.errors.telephone}
      />
      <ZipCodeInput
        testID="zip-code"
        value={state.values.zip}
        error={state.errors.zip}
        onLoadingChange={setZipLoading}
        onValueChange={(value) =>
          dispatch({
            type: 'field',
            field: 'zip',
            value,
          })
        }
      />

      <PrimaryButton
        title="Next"
        testID="next-button"
        onPress={updateProfile}
        disabled={zipLoading || loading}
        loading={loading}
        style={styles.button}
      />
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    marginTop: 40,
  },
  title: {
    paddingBottom: 30,
  },
});
