import React from 'react';
import { ImageStyle, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';

export default function Rating({
  rate,
  showNull,
}: {
  rate?: number;
  showNull?: boolean;
}) {
  const content = !rate ? (
    showNull ? null : (
      <TextBodySmall bold>&mdash;</TextBodySmall>
    )
  ) : (
    <TransparentRow style={styles.ratingsWrapper}>
      <TransparentRow>
        <RatingStarEmpty />
        <RatingStarEmpty />
        <RatingStarEmpty />
        <RatingStarEmpty />
        <RatingStarEmpty />
      </TransparentRow>
      <TransparentRow
        style={[
          styles.ratings,
          {
            width: rate * 20,
          },
        ]}
      >
        <RatingStar />
        <RatingStar />
        <RatingStar />
        <RatingStar />
        <RatingStar />
      </TransparentRow>
    </TransparentRow>
  );
  return (
    <TransparentRow style={styles.ratingsContainer}>{content}</TransparentRow>
  );
}

function RatingStar() {
  const theme = useColorScheme();

  return (
    <Icon
      name="star"
      color={Colors[theme].accent}
      style={styles.icon as ImageStyle}
    />
  );
}

function RatingStarEmpty() {
  const theme = useColorScheme();
  return (
    <Icon
      name="starOutlined"
      color={Colors[theme].accent}
      style={styles.icon as ImageStyle}
    />
  );
}

const styles = StyleSheet.create({
  ratingsContainer: {
    width: 100,
    height: 20,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: 8,
  },
  ratingsWrapper: {
    position: 'relative',
    width: 100,
    height: 20,
  },
  ratings: {
    position: 'absolute',
    left: 0,
    height: 20,
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  icon: {
    width: 20,
    height: 20,
  },
});
