import { Platform, StyleSheet } from 'react-native';

import BackToChildButton from './BackToChildButton';
import Layout from '../../../constants/Layout';
import ChildrenProps from '../../../types/ChildrenProps';
import { PrimaryButton } from '../../Button';
import { Container } from '../../Layout';
import { TextH2 } from '../../StyledText';
import { View, TransparentView } from '../../Themed';
import CommonHeader from '../../layouts/CommonHeader';
import Asterisk from '../Asterisk';
import BackToProfileButton from '../BackToProfileButton';

type ChildFormWrapperProps = ChildrenProps & {
  childName?: string;
  title: string;
  onSubmit: () => void;
  invalid: boolean;
};

export default function ChildFormWrapper({
  children,
  title,
  onSubmit,
  invalid,
  childName,
}: ChildFormWrapperProps) {
  return (
    <>
      <CommonHeader />
      <Container>
        <View style={styles.actions}>
          <TransparentView style={styles.actionsInner}>
            {childName ? (
              <BackToChildButton name={childName} />
            ) : (
              <BackToProfileButton />
            )}
          </TransparentView>
        </View>
        <View style={styles.inner}>
          {Platform.OS === 'web' && Layout.isLargeDevice && <Asterisk />}
          <TextH2 style={styles.title}>{title}</TextH2>
          {children}
          <PrimaryButton
            style={styles.button}
            title="Save"
            disabled={invalid}
            onPress={onSubmit}
          />
        </View>
      </Container>
    </>
  );
}

const styles = StyleSheet.create({
  actions: {
    padding: 40,
    ...(Layout.isMobileDevice && {
      padding: 20,
    }),
    paddingBottom: 0,
    alignItems: 'center',
  },
  actionsInner: {
    maxWidth: 1200,
    width: '100%',
    alignItems: 'flex-start',
  },
  title: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 50,
          marginBottom: 75,
        }),
        ...(Layout.isMobileDevice && {
          marginBottom: 40,
        }),
      },
    }),
    alignSelf: 'center',
  },
  inner: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginHorizontal: 'auto',
          marginTop: 0,
          marginBottom: 30,
          width: 500,
          maxWidth: '100%',
          padding: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginHorizontal: 20,
          marginVertical: 40,
        }),
      },
    }),
  },
  button: {
    marginTop: 30,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          height: 60,
        }),
        ...(Layout.isMobileDevice && {
          height: 50,
        }),
      },
    }),
  },
});
