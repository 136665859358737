import Checkbox from 'expo-checkbox';
import { Platform, Pressable, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useColorScheme from '../../hooks/useColorScheme';
import { formatPrice } from '../../utils/locations';
import Row from '../Row';
import { TextBody } from '../StyledText';
import { TransparentView } from '../Themed';

type CareCreditCheckboxProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  total: number;
  careCreditBalance: number;
  prepend?: React.ReactNode;
};

export default function CareCreditCheckbox({
  value,
  onChange,
  total,
  careCreditBalance,
  prepend = null,
}: CareCreditCheckboxProps) {
  const theme = useColorScheme();
  const maxValue = value ? 0 : careCreditBalance;

  if (careCreditBalance === 0) {
    return null;
  }

  return (
    <>
      {prepend}
      <TransparentView>
        <Row style={styles.row}>
          <TextBody>BumoCredit Balance</TextBody>
          <TextBody>{formatPrice(careCreditBalance)}</TextBody>
        </Row>
        {Layout.isMobileDevice && (
          <Row style={[styles.row, styles.balanceAfter]}>
            <TextBody>Balance after transaction</TextBody>
            <TextBody>
              {formatPrice(Math.max(maxValue, careCreditBalance - total))}
            </TextBody>
          </Row>
        )}
        <Row style={[styles.row, styles.useBalance]}>
          <Row>
            <Pressable
              style={styles.checkboxContainer}
              onPress={() => onChange(!value)}
            >
              <Checkbox
                style={styles.checkbox}
                color={Colors[theme].textSecondary}
                value={value}
                onValueChange={() => onChange(!value)}
              />
            </Pressable>
            <TextBody>Use Balance</TextBody>
          </Row>
          {Layout.isLargeDevice && (
            <TextBody>{`Balance after transaction: ${formatPrice(
              Math.max(maxValue, careCreditBalance - total)
            )}`}</TextBody>
          )}
        </Row>
      </TransparentView>
    </>
  );
}

const styles = StyleSheet.create({
  row: {
    justifyContent: 'space-between',
  },
  balanceAfter: {
    marginTop: 10,
  },
  checkboxContainer: {
    padding: 12,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginRight: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginRight: 10,
        }),
      },
    }),
  },
  checkbox: {
    height: 24,
    width: 24,
    borderRadius: 5,
  },
  useBalance: {
    marginTop: 15,
  },
});
