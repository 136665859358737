import useAppNavigation from '../../../hooks/useAppNavigation';
import { UnderlinedButton } from '../../Button';

export default function BackToChildButton({ name }: { name: string }) {
  const { navigate } = useAppNavigation();
  return (
    <UnderlinedButton
      title="Back"
      testID="back-button"
      onPress={() => navigate('Child', { name })}
    />
  );
}
