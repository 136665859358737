import LINKS from '../../constants/Links';
import Link, { Block } from '../Link';
import { Divider, View } from '../Themed';

const LINK_DESCRIPTION = {
  health:
    "Bumo's providers must abide by specific Health and Safety guidelines, which include COVID- 19 policies.",
  safety:
    'At Bumo, your child’s safety is our greatest priority. Through our BumoTrust 50-point review process, we carefully vet each provider before they are allowed to join our network. Bumo is a curated marketplace of safe, reliable providers.',
  cancellation: (
    hasWeekly = false,
    cancelation = 72,
    days = Math.ceil(cancelation / 24)
  ) =>
    cancelation === 0
      ? `Families may cancel at this location but they will not be eligible for any BumoCredit if they do so. `
      : `Families must cancel at least ${cancelation} hours (~${days} days) before the start of their session in order to receive BumoCredit that can be applied to future sessions. Rescheduling a session within ${cancelation} hours (~${days} days) is treated as a cancellation of the original session and will not be eligible for BumoCredit. ${
          hasWeekly
            ? 'For weeklong bookings, the session start time is the first booked day of the week for that weeklong booking.'
            : ''
        }`,
};

export default function Links({
  hasWeekly,
  cancelationThreshold,
}: {
  hasWeekly?: boolean;
  cancelationThreshold?: number;
}) {
  return (
    <View>
      <Link
        title="Health and Safety"
        description={LINK_DESCRIPTION.health}
        url={LINKS.health}
      />
      <Divider />
      <Link
        title="Bumo Trust Verification"
        description={LINK_DESCRIPTION.safety}
        url={LINKS.safety}
      />
      <Divider />
      <Block
        title="Cancellation Policy"
        description={LINK_DESCRIPTION.cancellation(
          hasWeekly,
          cancelationThreshold
        )}
      />
    </View>
  );
}
