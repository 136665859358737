import { useState } from 'react';
import {
  DimensionValue,
  ImageBackground,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Carousel } from 'react-responsive-carousel';

import {
  MarketingBumoCreditContentMobile,
  MarketingGiftCardContentMobile,
} from './MarketingBannerMobile';
import Colors from '../../constants/Colors';
import useAppNavigation from '../../hooks/useAppNavigation';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import BumoGiftCard from '../BumoGiftCard';
import { IconButton, PrimaryButton } from '../Button';
import Dots from '../Dots';
import { Icon, IconStyle } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBody, TextH3 } from '../StyledText';
import { StyleProps, TransparentView, View, ViewProps } from '../Themed';

function renderArrowPrev(
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) {
  return (
    <CarouselArrow
      icon={<Icon name="arrowLeft" style={styles.icon as IconStyle} />}
      onPress={clickHandler}
      style={styles.arrowPrev}
    />
  );
}

function renderArrowNext(
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) {
  return (
    <CarouselArrow
      icon={<Icon name="arrowRight" style={styles.icon as IconStyle} />}
      onPress={clickHandler}
      style={styles.arrowNext}
    />
  );
}

function CarouselArrow({
  icon,
  onPress,
  style,
}: {
  icon: React.ReactNode;
  onPress: () => void;
  style?: ViewProps['style'];
}) {
  const isMobileDevice = useMaxWidth(900);
  if (isMobileDevice) {
    return null;
  }
  return <IconButton icon={icon} onPress={onPress} text={null} style={style} />;
}

export function MarketingCarousel({
  children,
}: {
  children: React.ReactElement[];
}) {
  const [activeIndex, setIndex] = useState(0);
  return (
    <>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop
        interval={6000}
        onChange={(index) => setIndex(index)}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        className="additions-carousel"
        autoPlay
      >
        {children}
      </Carousel>

      <Dots
        activeIndex={activeIndex}
        amount={2}
        containerStyle={{
          position: 'absolute',
          bottom: 16,
          width: '100%',
          justifyContent: 'center',
        }}
        dotProps={{
          inactiveColor: '#d9d9d9',
          style: {
            width: 10,
            height: 10,
            borderRadius: 5,
            marginHorizontal: 8,
          },
        }}
      />
    </>
  );
}

export function MarketingBumoCreditContent({
  style,
  isMobileDevice: isMobileDeviceProp,
  onGetCreditPress,
  imageBackgroundStyle,
}: StyleProps & {
  isMobileDevice?: boolean;
  imageBackgroundStyle?: StyleProps['style'];
  onGetCreditPress: () => void;
}) {
  const theme = useColorScheme();
  const minWidth = useMaxWidth(900);
  const isMobileDevice = isMobileDeviceProp || minWidth;
  return (
    <View
      style={[{ backgroundColor: Colors[theme].backgroundSecondary }, style]}
    >
      <TransparentRow
        style={[
          styles.slideContent,
          isMobileDevice && styles.slideContentMobile,
        ]}
      >
        <ImageBackground
          source={require('../../assets/images/bumo-credit.png')}
          accessibilityRole="none"
          style={[
            {
              flex: 1,
              width: '100%',
              height: '100%',
              padding: 20,
            },
            isMobileDevice && {
              height: 167,
              flex: -1,
              marginBottom: 20,
            },
            imageBackgroundStyle,
          ]}
          resizeMode="contain"
        />
        <TransparentView
          style={[
            {
              flex: 1,
              padding: 50,
              justifyContent: 'flex-start',
              alignContent: 'flex-start',
              alignItems: 'flex-start',
            },
            isMobileDevice && {
              padding: 0,
            },
          ]}
        >
          <TextH3
            style={[
              styles.textLeft,
              { marginBottom: 10 },
              isMobileDevice && styles.textCenter,
            ]}
          >
            Save on Bookings with BumoCredit
          </TextH3>
          <TextBody
            style={[
              styles.textLeft,
              { marginBottom: 30 },
              isMobileDevice && styles.textCenter,
            ]}
          >
            BumoCredit is like buying a gift card for yourself that has more
            money than what you paid for it. In addition, you can use BumoCredit
            for your Flexible Spending Account (FSA) Dependent Care.{' '}
          </TextBody>

          <PrimaryButton
            title="Get BumoCredit"
            style={[
              {
                alignSelf: 'flex-start',
                backgroundColor: Colors[theme].accent,
              },
              isMobileDevice && {
                alignSelf: 'center',
              },
            ]}
            onPress={onGetCreditPress}
          />
        </TransparentView>
      </TransparentRow>
    </View>
  );
}

export function MarketingGiftCardContent({
  style,
  isMobileDevice: isMobileDeviceProp,
  onGiveCardPress,
}: StyleProps & { isMobileDevice?: boolean; onGiveCardPress: () => void }) {
  const theme = useColorScheme();
  const smallerWindow = useMaxWidth(900);
  const isMobileDevice = isMobileDeviceProp || smallerWindow;
  return (
    <View style={[{ backgroundColor: Colors[theme].accentSecondary }, style]}>
      <TransparentRow
        style={[
          styles.slideContent,
          isMobileDevice && styles.slideContentMobile,
          isMobileDevice && { flexDirection: 'column-reverse', gap: 40 },
        ]}
      >
        <TransparentView style={{ flex: 1, maxWidth: 480 }}>
          <TextH3
            style={[
              styles.textLeft,
              { marginBottom: 10 },
              isMobileDevice && {
                alignSelf: 'center',
              },
            ]}
          >
            Give the Gift of Child Care
          </TextH3>
          <TextBody
            style={[
              styles.textLeft,
              { marginBottom: 30 },
              isMobileDevice && {
                textAlign: 'center',
              },
            ]}
          >
            Delivered directly to your gift recipient's email address, the Bumo
            Gift Card includes a unique gift code they can apply to purchase
            childcare session(s) at any Bumo location.
          </TextBody>

          <PrimaryButton
            title="Give the Bumo Gift Card"
            style={[
              {
                alignSelf: 'flex-start',
                backgroundColor: Colors[theme].accent,
              },
              isMobileDevice && {
                alignSelf: 'center',
              },
            ]}
            onPress={onGiveCardPress}
          />
        </TransparentView>
        <TransparentView
          style={[
            { flexGrow: 0, flexShrink: 0, flexBasis: 'auto' },
            isMobileDevice && { width: '100%' },
          ]}
        >
          <TransparentView
            style={[
              !isMobileDevice && { maxWidth: 500 },
              { alignItems: 'center' },
            ]}
          >
            <BumoGiftCard width={isMobileDevice ? 300 : 400} mediumCardSize />
          </TransparentView>
        </TransparentView>
      </TransparentRow>
    </View>
  );
}

function MarketingBanner({ style }: StyleProps) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(900);
  const navigation = useAppNavigation();

  const isSmallDevice = useMaxWidth(550);

  if (isSmallDevice) {
    const slideStyle: StyleProp<ViewStyle> = {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 24,
      paddingBottom: 38,
      gap: 12,
    };
    return (
      <View style={style}>
        <MarketingCarousel>
          <View
            style={[
              slideStyle,
              { backgroundColor: Colors[theme].backgroundSecondary },
            ]}
          >
            <MarketingBumoCreditContentMobile
              onPress={() => navigation.navigate('BumoCreditLanding')}
            />
          </View>
          <View
            style={[
              slideStyle,
              { backgroundColor: Colors[theme].accentSecondary },
            ]}
          >
            <MarketingGiftCardContentMobile
              onPress={() => navigation.push('BuyGiftCard')}
            />
          </View>
        </MarketingCarousel>
      </View>
    );
  }

  return (
    <View style={style}>
      <MarketingCarousel>
        <MarketingBumoCreditContent
          style={[styles.slide, isMobileDevice && styles.slideMobile]}
          onGetCreditPress={() => navigation.navigate('BumoCreditLanding')}
        />
        <MarketingGiftCardContent
          style={[styles.slide, isMobileDevice && styles.slideMobile]}
          onGiveCardPress={() => navigation.push('BuyGiftCard')}
        />
      </MarketingCarousel>
    </View>
  );
}

const styles = StyleSheet.create({
  slide: {
    padding: 20,
    paddingHorizontal: 60,
    alignItems: 'center',
  },
  slideMobile: {
    height: 'auto',
    paddingVertical: 40,
    paddingBottom: 60,
  },
  slideContent: { flex: 1, maxWidth: 1200, gap: 40 },
  slideContentMobile: {
    gap: 0,
    flexDirection: 'column',
    paddingVertical: 0,
  },
  arrowPrev: {
    position: 'absolute',
    top: 'calc(50% - 15px)' as DimensionValue,
    right: 'calc(50% + min(50% - 40px, 630px))' as DimensionValue,
    zIndex: 10,
  },
  arrowNext: {
    position: 'absolute',
    top: 'calc(50% - 15px)' as DimensionValue,
    zIndex: 10,
    left: 'calc(50% + min(50% - 40px, 630px))' as DimensionValue,
  },
  icon: { width: 16, height: 16 },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
    alignSelf: 'center',
  },
});

export default MarketingBanner;
