import { useLinkBuilder } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import { getLocationsCoords } from '../api/locations';
import { VerificationStatus } from '../api/search';
import GoogleMap from '../components/GoogleMap';
import { LocationMarker } from '../components/Marker';
import { View } from '../components/Themed';
import { WebLink } from '../components/WebLink';
import { markerColorForLocation } from '../components/layouts/LocationsListWithMap';
import Defaults from '../constants/Defaults';

type CoordsResult = Awaited<ReturnType<typeof getLocationsCoords>>;

export default function AllLocations() {
  const linkBuilder = useLinkBuilder();
  const [locations, setLocations] = useState<CoordsResult>([]);
  const [currentPreviewLocation, setCurrentPreviewLocation] = useState<
    null | CoordsResult[number]
  >();
  useEffect(() => {
    getLocationsCoords().then((locations) => setLocations(locations));
  }, []);

  const currentLocationLink = currentPreviewLocation
    ? linkBuilder('Location', {
        nameId: currentPreviewLocation.nameId,
      })
    : undefined;

  return (
    <>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: Defaults.latitude, lng: Defaults.longitude }}
        onClick={() => setCurrentPreviewLocation(null)}
      >
        {locations.map((location) => {
          if (location.verificationStatus === VerificationStatus.UNVERIFIED) {
            return null;
          }
          return (
            <LocationMarker
              key={location.id}
              lat={location.latitude}
              lng={location.longitude}
              backgroundColorName={markerColorForLocation(
                location.verificationStatus
              )}
              onPress={() => setCurrentPreviewLocation(location)}
            />
          );
        })}
      </GoogleMap>
      {currentLocationLink && currentPreviewLocation ? (
        <View
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
            backgroundColor: 'white',
            padding: 10,
            borderWidth: 1,
            borderColor: 'black',
          }}
        >
          Location:
          <WebLink href={currentLocationLink} target="_blank">
            {currentPreviewLocation.name}
          </WebLink>
        </View>
      ) : null}
    </>
  );
}
