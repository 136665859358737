import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import ProgramPageFactory from './ProgramPage';
import { programPageSettings, Tab } from '../api/program_pages';
import { TextBodySmall } from '../components/StyledText';
import { TransparentView } from '../components/Themed';
import { handleLink } from '../utils/common';

const LAFamilySupportProgram = ProgramPageFactory({
  sectionTitle: 'Child Care Providers',
  HeaderComponent: LAFamilySupportHeader,
  disabledMarketingBanner: true,
});

const LAFamilySupportPage = () => {
  const [tabs, setTabs] = useState<Tab[]>([{ title: '', ids: [0] }]);
  useEffect(() => {
    programPageSettings('la_family_support').then((settings) =>
      setTabs(settings.tabs)
    );
  }, []);

  return <LAFamilySupportProgram tabs={tabs} />;
};

function LAFamilySupportHeader() {
  return (
    <TransparentView style={styles.container}>
      <View
        style={{
          maxWidth: 780,
          gap: 20,
          alignItems: 'center',
        }}
      >
        <Image
          source={require('../assets/images/lafamilysupport-logo.png')}
          style={{ marginBottom: 20, maxWidth: '100%', width: '100%' }}
          resizeMode="contain"
        />
        <TextBodySmall style={styles.text}>
          Our Los Angeles community has lost homes, schools, and the cherished
          spaces where our children played and learned. As parents ourselves, we
          understand that having a safe place for your children is essential for
          healing. We've gathered information about verified child care
          locations ready to welcome families, and we're offering direct
          assistance through{' '}
          <TextBodySmall
            style={{
              textDecorationStyle: 'solid',
              textDecorationLine: 'underline',
              fontSize: 18,
            }}
            onPress={() => handleLink('https://lafamilysupport.com')}
          >
            lafamilysupport.com
          </TextBodySmall>{' '}
          . You're not alone—we're here to help.
        </TextBodySmall>
        <Image
          source={require('../assets/images/purple-stripe.png')}
          style={{ marginTop: 40, maxWidth: '100%', width: '100%' }}
          resizeMode="contain"
        />
      </View>
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 1240,
    width: '100%',
    padding: 20,
    marginHorizontal: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
    textAlign: 'center',
  },
  stripe: { marginTop: 40 },
});

export default LAFamilySupportPage;
