import { useNavigation } from '@react-navigation/native';
import { useEffect, useRef, useState } from 'react';
import { DimensionValue, Platform, Pressable, StyleSheet } from 'react-native';

import ImageGallery from './ImageGallery';
import { VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { findCurrentCampaign } from '../../contexts/campaigns';
import useColorScheme from '../../hooks/useColorScheme';
import { useModalState } from '../../hooks/useModalState';
import { useMaxWidth, useMinWidth } from '../../hooks/useResponsive';
import { hexToRgba, pauseAllWistiaVideos } from '../../utils/common';
import BumoCarousel, { BumoCarouselInstance } from '../BumoCarousel';
import BumoVerified from '../BumoVerified';
import { Image } from '../Image';
import { Media } from '../Media';
import { PressableBackIcon, PressableForwardIcon } from '../PressableIcon';
import Row, { TransparentRow } from '../Row';
import { TextTag } from '../StyledText';
import { View } from '../Themed';

const currentCampaign = findCurrentCampaign();

interface ImagesProps {
  locationId: number;
  media: string[];
  verificationStatus: VerificationStatus;
  forceOpen?: boolean;
}

export default function Images({
  locationId,
  media,
  verificationStatus,
  forceOpen = false,
}: ImagesProps) {
  const theme = useColorScheme();
  const { isModalVisible, showModal, closeModal } = useModalState(forceOpen);
  const navigation = useNavigation();

  useEffect(() => {
    if (isModalVisible) {
      navigation.setParams({ photos: true });
    } else {
      navigation.setParams({ photos: undefined });
    }
  }, [isModalVisible]);

  const openGallery = () => {
    pauseAllWistiaVideos();
    showModal();
  };

  const imageGallery = (
    <ImageGallery
      locationId={locationId}
      isVisible={isModalVisible}
      images={media}
      close={() => {
        closeModal();
      }}
    />
  );

  const isMobileDevice = useMaxWidth(768);
  const isMediumDevice = useMinWidth(431);
  const isLargeDevice = useMinWidth(1100);

  if (isMobileDevice) {
    return (
      <Row style={{ marginHorizontal: -20 }}>
        {imageGallery}
        <MobileImageCarousel
          media={media}
          onActiveIndexPress={openGallery}
          large={isMediumDevice}
        />
        <BumoVerified style={styles.verified} status={verificationStatus} />
      </Row>
    );
  }

  const campaignBannerImage =
    verificationStatus === VerificationStatus.VERIFIED &&
    currentCampaign?.pdpBanner ? (
      <Image
        source={currentCampaign?.pdpBanner}
        style={{
          position: 'absolute',
          left: (isLargeDevice
            ? 'calc(50% - 378px)'
            : 'calc(50% - 378px + 153px)') as DimensionValue,
          bottom: 11,
          width: 368,
          height: 107,
        }}
      />
    ) : null;

  return (
    <Row>
      {imageGallery}
      <Media
        style={styles.mainImage}
        source={media[0]}
        onPress={media[0] ? openGallery : undefined}
      />
      {campaignBannerImage}
      <View>
        <Row style={isLargeDevice ? styles.row : styles.column}>
          <Media
            style={styles.image}
            source={media[1]}
            onPress={media[0] ? openGallery : undefined}
          />
          <Media
            style={[styles.image]}
            source={media[2]}
            onPress={media[0] ? openGallery : undefined}
          />
        </Row>
        {isLargeDevice ? (
          <Row style={[styles.row, { marginBottom: 0 }]}>
            <Media
              style={styles.image}
              source={media[3]}
              onPress={media[0] ? openGallery : undefined}
            />
            <Media
              style={[styles.image]}
              source={media[4]}
              onPress={media[0] ? openGallery : undefined}
            />
          </Row>
        ) : null}
      </View>
      <Pressable
        onPress={() => {
          pauseAllWistiaVideos();
          showModal();
        }}
        style={[
          styles.imageTag,
          Layout.isMobileDevice && media.length >= 10 && { width: 60 },
          Layout.isMobileDevice && media.length >= 100 && { width: 70 },
          { backgroundColor: hexToRgba(Colors[theme].text, 0.8) },
        ]}
      >
        <TextTag
          style={{ color: Colors[theme].background }}
        >{`See all media (${media.length})`}</TextTag>
      </Pressable>
      <BumoVerified style={styles.verified} status={verificationStatus} />
    </Row>
  );
}

function MobileImageCarousel({
  media,
  onActiveIndexPress,
  large,
}: {
  media: string[];
  onActiveIndexPress: () => void;
  large?: boolean;
}) {
  const theme = useColorScheme();
  const [activeIndex, setIndex] = useState(0);
  const carouselRef = useRef<BumoCarouselInstance>(null);
  const height = large ? 310 : 210;
  return (
    <>
      <TransparentRow style={{ width: '100%' }}>
        <BumoCarousel
          renderedElements={1}
          showArrows={false}
          containerStyle={{ height, width: '100%' }}
          itemContainerStyle={{ height }}
          onIndexChange={setIndex}
          ref={carouselRef}
          activeIndex={activeIndex}
        >
          {media.map((item, index) => (
            <View
              key={`m-img-carousel-item-${index}`}
              style={{
                width: '100%',
                height,
                overflow: 'hidden',
              }}
            >
              <Media
                style={[styles.mainImageMobile, { height }]}
                source={item}
                width={390}
                height={height}
                visible={Math.abs(index - activeIndex) <= 1}
                onPress={onActiveIndexPress}
              />
            </View>
          ))}
        </BumoCarousel>

        <PressableBackIcon
          disabled={activeIndex === 0}
          onPress={() => carouselRef.current?.goToPrev()}
        />
        <PressableForwardIcon
          disabled={activeIndex === media.length - 1}
          onPress={() => carouselRef.current?.goToNext()}
        />
      </TransparentRow>
      {media.length > 0 ? (
        <Pressable
          onPress={onActiveIndexPress}
          style={[
            styles.imageTag,
            Layout.isMobileDevice && media.length >= 10 && { width: 60 },
            Layout.isMobileDevice && media.length >= 100 && { width: 70 },
            { backgroundColor: hexToRgba(Colors[theme].text, 0.8) },
          ]}
        >
          <TextTag style={{ color: Colors[theme].background }}>{`${
            activeIndex + 1
          } / ${media.length}`}</TextTag>
        </Pressable>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  image: {
    borderRadius: 10,
    width: 285,
    height: 160,
    overflow: 'hidden',
  },
  mainImage: {
    borderRadius: 10,
    width: 590,
    height: 332,
    marginRight: 20,
    overflow: 'hidden',
  },
  mainImageMobile: {
    width: '100%',
    height: 210,
  },
  row: {
    marginBottom: 12,
    gap: 20,
  },
  column: {
    flexDirection: 'column',
    gap: 12,
  },
  imageTag: {
    right: 10,
    position: 'absolute',
    borderRadius: 100,
    textTransform: 'uppercase',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          bottom: 10,
          paddingVertical: 9,
          paddingHorizontal: 15,
        }),
        ...(Layout.isMobileDevice && {
          top: 10,
          alignItems: 'center',
          width: 50,
          paddingVertical: 5,
          paddingHorizontal: 10,
        }),
      },
    }),
  },
  verified: {
    left: 10,
    top: 10,
    position: 'absolute',
    height: 30,
  },
});
