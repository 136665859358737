import { Pressable, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { RemoveCircleIcon } from '../../native/icons';
import Row from '../Row';
import { TextCta, TextFinePrint } from '../StyledText';

export default function ChosenCalendarRow({
  dayLabel,
  hoursLabel,
  onRemove,
  disabled,
}: {
  dayLabel: string;
  hoursLabel: string;
  onRemove: () => void;
  disabled?: boolean;
}) {
  const theme = useColorScheme();

  return (
    <>
      <Row
        style={{ justifyContent: 'space-between', marginTop: 4 }}
        testID={`chosen-calendar-row-${dayLabel}`}
      >
        <Row style={{ justifyContent: 'flex-start' }}>
          <TextCta style={disabled && styles.textDisabled}>
            {dayLabel}
            {': '}
          </TextCta>
          <TextCta
            style={[
              { color: Colors[theme].textSecondary },
              disabled && styles.textDisabled,
            ]}
          >
            {hoursLabel}
          </TextCta>
        </Row>

        <Pressable onPress={onRemove} testID="remove-slot">
          <RemoveCircleIcon
            width={20}
            height={20}
            fill={Colors[theme].textSecondary}
          />
        </Pressable>
      </Row>
      {disabled && (
        <TextFinePrint style={{ color: Colors[theme].error }}>
          This slot is not available for the selected kids
        </TextFinePrint>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textDisabled: {
    textDecorationLine: 'line-through',
  },
});
