import React, { useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import {
  useMaxWidth,
  useMinWidth,
  useResponsive,
} from '../../hooks/useResponsive';
import BumoCarousel from '../BumoCarousel';
import Dots, { DotsProps } from '../Dots';
import { InnerContainer } from '../Layout';
import { TransparentRow } from '../Row';
import { TextBody, TextH2, TextH3 } from '../StyledText';
import { StyleProps, TransparentView, View } from '../Themed';

export default function HowItWorks({ style }: { style?: StyleProps['style'] }) {
  return (
    <HowItWorksContainer>
      <BlockRenderer index={0} />
      <BlockRenderer index={1} />
      <BlockRenderer index={2} />
    </HowItWorksContainer>
  );
}

export function HowItWorksContainer({
  style,
  children,
}: {
  style?: StyleProps['style'];
  children: React.ReactNode;
}) {
  const theme = useColorScheme();
  const shouldRenderCarousel = useMaxWidth(600);

  const isLargeDevice = useMinWidth(920);

  return (
    <View
      style={[
        {
          backgroundColor: Colors[theme].backgroundSecondary,
        },
        style,
      ]}
    >
      <InnerContainer
        style={[
          styles.howItWorks,
          !shouldRenderCarousel && styles.howItWorksDesktop,
        ]}
      >
        <TextH2 style={styles.heading}>How it Works</TextH2>
        {shouldRenderCarousel ? (
          <ResponsiveCarousel>{children}</ResponsiveCarousel>
        ) : (
          <TransparentRow
            style={[
              styles.blocksRow,
              !shouldRenderCarousel && styles.blocksRowDesktop,
              isLargeDevice && styles.blockRowLarge,
            ]}
          >
            {children}
          </TransparentRow>
        )}
      </InnerContainer>
    </View>
  );
}

function BlockRenderer({ index }: { index: number }) {
  switch (index) {
    case 0:
      return (
        <Block index="1">
          <TextH3 style={styles.blockHeading}>Find Vetted Care</TextH3>
          <TextBody style={styles.blockText}>
            Find vetted providers available for the days + times you need child
            care.
          </TextBody>
        </Block>
      );
    case 1:
      return (
        <Block index="2">
          <TextH3 style={styles.blockHeading}>Book Instantly</TextH3>
          <TextBody style={styles.blockText}>
            Book child care, in advance or last minute!
          </TextBody>
        </Block>
      );
    case 2:
      return (
        <Block index="3">
          <TextH3 style={styles.blockHeading}>Pay-As-You-Book</TextH3>
          <TextBody style={styles.blockText}>
            No subscription or membership fees required. Simply pay for what you
            book.
          </TextBody>
        </Block>
      );
    default:
      return null;
  }
}

export function ResponsiveCarousel({
  children,
  height = 250,
  dotsProps = {},
}: {
  children: React.ReactNode;
  height?: number;
  dotsProps?: Partial<DotsProps>;
}) {
  const [index, setIndex] = useState(0);
  const onIndexChange = useCallback((index: number) => {
    setIndex(index);
  }, []);

  const childrenAmount = React.Children.count(children);
  return (
    <TransparentView style={{ marginHorizontal: -30 }}>
      <TransparentView style={{ height, justifyContent: 'center' }}>
        <BumoCarousel
          renderedElements={1}
          onIndexChange={onIndexChange}
          itemContainerStyle={{ paddingHorizontal: 30, height }}
          showArrows={false}
        >
          {children}
        </BumoCarousel>
      </TransparentView>
      <Dots
        activeIndex={index}
        amount={childrenAmount}
        containerStyle={{ marginTop: 30 }}
        {...dotsProps}
      />
    </TransparentView>
  );
}

export function HIWBlockItem({
  index,
  title,
  content,
  style,
}: {
  title: string;
  content: string;
  index: string;
  style?: StyleProps['style'];
}) {
  return (
    <Block index={index} style={style}>
      <TextH3 style={styles.blockHeading}>{title}</TextH3>
      <TextBody style={styles.blockText}>{content}</TextBody>
    </Block>
  );
}

function Block({
  children,
  index,
  style,
}: {
  children?: React.ReactNode;
  index: string;
  style?: StyleProps['style'];
}) {
  const theme = useColorScheme();
  const { isDesktopDevice } = useResponsive();
  return (
    <View style={[styles.block, isDesktopDevice && styles.blockDesktop, style]}>
      <View
        style={[
          styles.dot,
          {
            backgroundColor: Colors[theme].accentTertiary,
          },
        ]}
      >
        <TextH3 style={{ color: '#fff', textAlign: 'center' }}>{index}</TextH3>
      </View>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  heading: {
    alignSelf: 'center',
    marginBottom: 30,
  },
  howItWorks: {
    paddingVertical: 24,
    paddingHorizontal: 30,
  },
  howItWorksDesktop: {
    maxWidth: 1240,
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  blocksRow: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: 20,
  },
  block: {
    borderRadius: 10,
    padding: 30,
    paddingHorizontal: 10,
    flex: 1,
  },
  blocksRowDesktop: {
    flexDirection: 'row',
  },
  blockRowLarge: {
    gap: 45,
  },
  blockDesktop: {
    paddingHorizontal: 30,
  },
  blockHeading: {
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 10,
  },
  blockText: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  dot: {
    width: 50,
    height: 50,
    marginBottom: 20,
    borderRadius: 25,
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
});
