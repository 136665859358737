import { IconButton } from './Button';
import { Icon } from './Icon';
import { TextBody } from './StyledText';
import { TransparentView } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { handleLink } from '../utils/common';
import { copyToClipboard } from '../utils/copyToClipboard';

export function ShareReferralLinks({
  link,
  title,
  onLinkCopied,
}: {
  link: string;
  title: string;
  onLinkCopied?: () => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentView style={{ padding: 20, gap: 15 }}>
      <IconButton
        icon={
          <Icon
            name="facebook"
            color={Colors[theme].accent}
            style={{ marginRight: 20 }}
          />
        }
        text={<TextBody>Facebook</TextBody>}
        onPress={() =>
          handleLink(
            `https://www.facebook.com/sharer/sharer.php?u=${link}`,
            true
          )
        }
      />
      <IconButton
        icon={
          <Icon
            name="linkedin"
            color={Colors[theme].accent}
            style={{ marginRight: 20 }}
          />
        }
        text={<TextBody>LinkedIn</TextBody>}
        onPress={() =>
          handleLink(
            `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
            true
          )
        }
      />
      <IconButton
        icon={
          <Icon
            name="email"
            color={Colors[theme].accent}
            style={{ marginRight: 20 }}
          />
        }
        text={<TextBody>Email</TextBody>}
        onPress={() => {
          handleLink(
            `mailto:?subject=${encodeURIComponent(
              title
            )}&body=${encodeURIComponent(`Visit following link: ${link}`)}`,
            true
          );
        }}
      />
      <IconButton
        icon={
          <Icon
            name="cloud"
            color={Colors[theme].accent}
            style={{ marginRight: 20 }}
          />
        }
        text={<TextBody>Copy link</TextBody>}
        onPress={async () => {
          await copyToClipboard(link);
          onLinkCopied?.();
        }}
      />
    </TransparentView>
  );
}
