import React from 'react';
import { GestureResponderEvent, StyleSheet } from 'react-native';

import { BookingSlot, Location, VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import { useCheckoutPropUpdate } from '../../contexts/checkout';
import useColorScheme from '../../hooks/useColorScheme';
import { useOpenLocationPDP } from '../../hooks/useOpenLocationPDP';
import { WeekEntry, prepareWeekEntries } from '../../utils/weekly';
import BookingSlotButton from '../BookingSlotButton';
import { LocationCtaButtonVertical } from '../Button';
import { TransparentRow } from '../Row';

export function LocationSlotsButtons({
  location,
  handleAllPress,
  handleSlotButtonPress,
  handleBookByWeekPress,
  allButtonText = 'See All',
}: {
  location: Location;
  handleAllPress: (e: GestureResponderEvent) => void;
  handleBookByWeekPress?: () => void;
  handleSlotButtonPress?: (
    params:
      | { mode: 'daily'; slot: BookingSlot }
      | { mode: 'weekly'; slot: WeekEntry }
  ) => void;
  allButtonText?: string;
}) {
  const theme = useColorScheme();

  const { slots, nameId } = location;

  const hideBookByWeek =
    location.verificationStatus !== VerificationStatus.VERIFIED;

  const hasWeekly = location.bookingMode.includes('weekly');
  const onlyWeekly = hasWeekly && location.bookingMode.length === 1;

  const openPDPLink = useOpenLocationPDP(nameId);
  const onBookByWeekPress = (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (handleBookByWeekPress) {
      handleBookByWeekPress();
      return;
    }
    openPDPLink(false, { weekly: true });
  };

  const updateCheckoutProp = useCheckoutPropUpdate();
  const onWeeklySlotPress = handleSlotButtonPress
    ? (slot: WeekEntry) => handleSlotButtonPress({ mode: 'weekly', slot })
    : (slot: WeekEntry) => {
        updateCheckoutProp(
          {
            [nameId]: {
              [slot.id]: [slot.previewSlotName],
            },
          },
          'weeklyState'
        );
        openPDPLink(false);
      };

  return (
    <>
      {onlyWeekly && location.slotsWeekly
        ? prepareWeekEntries(location.slotsWeekly)
            .slice(0, 5)
            .sort((a, b) => a.id.localeCompare(b.id))
            .filter((s) => Object.keys(s.slots).length > 1)
            .map((slot) => (
              <TransparentRow style={styles.buttonContainer} key={slot.id}>
                <WeekSlotButton
                  slot={slot}
                  onPress={() => onWeeklySlotPress(slot)}
                />
              </TransparentRow>
            ))
        : slots
            .filter((s) => s.dependent === 'independent')
            .slice(0, hasWeekly ? 4 : 5)
            .map((slot) => (
              <TransparentRow style={styles.buttonContainer} key={slot.id}>
                <BookingSlotButton
                  slot={slot}
                  locationNameId={nameId}
                  onPress={
                    handleSlotButtonPress
                      ? () => handleSlotButtonPress({ mode: 'daily', slot })
                      : undefined
                  }
                />
              </TransparentRow>
            ))}
      {!hideBookByWeek && hasWeekly && !onlyWeekly ? (
        <TransparentRow style={styles.buttonContainer}>
          <LocationCtaButtonVertical
            icon="dateRange"
            top="Book by Week"
            onPress={onBookByWeekPress}
          />
        </TransparentRow>
      ) : null}

      <TransparentRow style={styles.buttonContainer}>
        <LocationCtaButtonVertical
          style={{ backgroundColor: Colors[theme].accent }}
          top={allButtonText}
          onPress={handleAllPress}
        />
      </TransparentRow>
    </>
  );
}

function WeekSlotButton({
  slot,
  onPress,
}: {
  slot: WeekEntry;
  onPress: (e: GestureResponderEvent) => void;
}) {
  return (
    <LocationCtaButtonVertical
      icon="dateRange"
      top={slot.name!}
      bottom={slot.previewHours}
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onPress(e);
      }}
    />
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    padding: 2,
    width: '50%',
  },
});
