import { StyleSheet } from 'react-native';

import { Hours, Pricing } from '../../api/search';
import FeatureFlags from '../../constants/FeatureFlags';
import { pluralize } from '../../utils/common';
import {
  formatHours,
  formatPricePerPeriod,
  formatRating,
  getAgeRangeText,
} from '../../utils/locations';
import LocationDetail from '../LocationDetail';
import Row from '../Row';
import { TextBodySmall } from '../StyledText';
import { View } from '../Themed';

interface DetailsProps {
  ageFrom: number;
  ageTo: number;
  price: number | null;
  pricing: Pricing;
  address?: {
    city: string;
    state: string;
  };
  hours: Hours;
  reviews?: {
    rating: number;
    count: number;
  } | null;
  onRatingPress?: () => void;
  hasWeeklyDiscount?: boolean;
}

export default function Details({
  ageFrom,
  ageTo,
  price,
  pricing = {},
  address,
  hours = [],
  reviews,
  onRatingPress,
  hasWeeklyDiscount,
}: DetailsProps) {
  const formattedHours = formatHours(hours);
  return (
    <View style={styles.details}>
      {FeatureFlags.REVIEWS && reviews !== null && reviews?.rating !== 0 ? (
        reviews ? (
          <LocationDetail
            icon="star"
            style={styles.locationDetail}
            text={
              <TextBodySmall onPress={onRatingPress}>
                {reviews.rating === 0 ? 'NA' : formatRating(reviews.rating)}
                &nbsp;
                <TextBodySmall style={{ textDecorationLine: 'underline' }}>
                  ({reviews.count} {pluralize(reviews.count, 'review')})
                </TextBodySmall>
              </TextBodySmall>
            }
          />
        ) : null
      ) : null}
      <Row>
        <LocationDetail
          style={styles.locationDetail}
          icon="profile"
          text={getAgeRangeText(ageFrom, ageTo)}
        />
        {price ? (
          <LocationDetail
            style={styles.locationDetail}
            icon="priceTag"
            text={formatPricePerPeriod(price, 'hour', '+')}
          />
        ) : pricing.group ? (
          <LocationDetail
            style={styles.locationDetail}
            icon="priceTag"
            text={formatPricePerPeriod(pricing.group, 'hour')}
          />
        ) : null}
      </Row>
      {address ? (
        <LocationDetail
          style={styles.locationDetail}
          icon="location"
          text={`${address.city}, ${address.state}`}
        />
      ) : null}
      {formattedHours && (
        <LocationDetail
          style={styles.locationDetail}
          icon="clock"
          text={`Today ${formattedHours}`}
        />
      )}
      {hasWeeklyDiscount ? (
        <LocationDetail
          style={styles.locationDetail}
          icon="dateRange"
          text={`Book by Week with 20% discount`}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  details: {
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 5,
  },
  locationDetail: {
    marginTop: 5,
  },
  icon: {
    width: 30,
    height: 30,
  },
});
