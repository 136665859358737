import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import HelloMessageBar, {
  ShouldIgnoreCampaignHeaderFn,
  useHelloMessageBarHeight,
} from '../HelloMessageBar';
import { View, ViewProps } from '../Themed';

export const useHeaderHeight = () => {
  const isMobileDevice = useMaxWidth(430);
  const headerHeight =
    Layout.navbar.height[isMobileDevice ? 'mobile' : 'desktop'];
  return headerHeight;
};

export default function HeaderContainer({
  shadow,
  children,
  secondaryChildren,
  style,
  additionalHeight = 0,
  customHelloBar,
  ignoreCampaignHeader: ignoreCampaignHeaderFn,
}: {
  children: React.ReactNode;
  secondaryChildren?: React.ReactNode;
  shadow: boolean;
  style?: ViewProps['style'];
  additionalHeight?: number;
  customHelloBar?: React.ReactNode;
  ignoreCampaignHeader?: ShouldIgnoreCampaignHeaderFn;
}) {
  const theme = useColorScheme();
  const helloBarHeight = useHelloMessageBarHeight(
    !!customHelloBar,
    ignoreCampaignHeaderFn
  );
  const headerHeight = useHeaderHeight();

  return (
    <View
      style={[
        // @ts-ignore
        {
          position: 'fixed',
        },
        styles.container,
        shadow && {
          shadowRadius: 12,
          shadowOpacity: 0.8,
          shadowColor: Colors[theme].shadow,
        },
        {
          height: headerHeight + helloBarHeight + additionalHeight,
        },
      ]}
    >
      {customHelloBar ? (
        customHelloBar
      ) : customHelloBar === null ? null : (
        <HelloMessageBar />
      )}
      <View
        style={[
          {
            height: headerHeight + additionalHeight,
            justifyContent: 'center',
            zIndex: 100,
          },

          style,
        ]}
      >
        {children}
      </View>
      {secondaryChildren}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    top: 0,
    zIndex: 101,
    width: '100%',
    justifyContent: 'flex-start',
  },
});
