const suffixes: Record<Intl.LDMLPluralRule, string> = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  zero: 'th',
  many: '',
};
export function ordinal(n: number) {
  const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });
  const category = english_ordinal_rules.select(n);
  const suffix = suffixes[category];
  return n + suffix;
}

export function resultsBucket(n: number) {
  if (n >= 1000) {
    return '1000+';
  } else if (n < 100) {
    return n;
  } else {
    const multiplier = Math.floor(n / 100);
    return `${multiplier * 100}+`;
  }
}
