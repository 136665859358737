import { API_V1_URL } from './config';
import { defaultAuthedRequest, getAuthedRequestHeaders } from './headers';

export async function postCustomerAcquisition(
  parentId: number,
  sessionId: string,
  acquisitionBody: {
    sources: string[];
    source?: string;
  }
): Promise<{ ok: true } | { ok: false; errors: any }> {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      ...acquisitionBody,
      parent_id: parentId,
      session_id: sessionId,
    }),
  };
  const response = await fetch(
    `${API_V1_URL}customer_acquisitions/`,
    requestInfo
  );

  if (response.ok) {
    return { ok: true };
  }

  const errors = await response.json();
  return { ok: false, errors };
}
