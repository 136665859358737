import { StyleSheet } from 'react-native';

import { Hours } from '../../api/search';
import { formatHours } from '../../utils/locations';
import Row from '../Row';
import { TextBodySmall } from '../StyledText';
import { StyleProps } from '../Themed';

type HoursPriceDetailsProps = StyleProps & {
  hours: Hours;
};

export default function HoursDetails({ hours, style }: HoursPriceDetailsProps) {
  if (!hours) return null;

  const hoursFormatted = formatHours(hours);
  return (
    <Row style={[styles.hours, style]}>
      {hoursFormatted && (
        <TextBodySmall>{`Hours open ${formatHours(hours)}`}</TextBodySmall>
      )}
    </Row>
  );
}

const styles = StyleSheet.create({
  hours: {
    marginTop: 10,
    justifyContent: 'flex-start',
  },
});
