import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DimensionValue, LayoutChangeEvent, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import {
  LocationBlockLarge,
  LocationBlockMedium,
  LocationBlockProps,
} from './LocationBlocks';
import Row from './Row';
import { View, ViewProps } from './Themed';
import SkeletonBlockMedium from './location_blocks/SkeletonBlockMedium';
import useWindowWidth from '../hooks/useWindowWidth';

export function withSkeletonLoading(
  SkeletonComponent: React.FC,
  LocationComponent: React.FC<LocationBlockProps>
) {
  return function (props: LocationBlockProps & { requiresWidth?: boolean }) {
    const ImageRef = useRef<HTMLImageElement | null>(null);
    const [loading, setLoading] = useState(true);

    const { requiresWidth, ...restProps } = props;

    useEffect(() => {
      if (props.location && props.location?.images?.length > 0) {
        const { images } = props.location;
        const firstImageUrl = images[0]!;
        ImageRef.current = new Image();
        ImageRef.current.onload = function () {
          setLoading(false);
          ImageRef.current = null;
        };
        ImageRef.current.src = firstImageUrl;
      }
    }, [props.location]);

    const isWidthAvailable = requiresWidth ? !!restProps.width : true;

    return loading || !isWidthAvailable ? (
      <SkeletonComponent />
    ) : (
      <LocationComponent {...restProps} />
    );
  };
}

export const SkeletonLoaderLarge = withSkeletonLoading(
  SkeletonBlockLarge,
  LocationBlockLarge
);

export const SkeletonLoaderMedium = withSkeletonLoading(
  SkeletonBlockMedium,
  LocationBlockMedium
);

export const SkeletonLoaderHorizontalLarge = withSkeletonLoading(
  () => <SkeletonBlockLarge horizontal />,
  LocationBlockLarge
);

export function SkeletonBlockLarge({
  horizontal,
  photoStyle,
  detailsStyle,
}: {
  horizontal?: boolean;
  photoStyle?: ViewProps['style'];
  detailsStyle?: ViewProps['style'];
}) {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    (containerRef.current as unknown as HTMLElement).classList.add(
      'skeleton-container'
    );
  }, []);
  return (
    <View
      style={[styles.container, horizontal && styles.containerHorizontal]}
      ref={containerRef}
    >
      <View style={[horizontal && styles.rowOverride, { width: '100%' }]}>
        <View
          style={[
            styles.image,
            styles.skeleton,
            horizontal && { marginTop: 10, marginRight: 30 },
            photoStyle,
          ]}
        />
        <View
          style={[
            styles.container,
            horizontal && styles.columnWide,
            detailsStyle,
          ]}
        >
          <View style={[styles.title, styles.skeleton]} />
          <View style={[styles.details, styles.skeleton]} />
          <View style={[styles.hours, styles.skeleton]} />
          <View style={[styles.callouts, styles.skeleton]} />
          <Row style={[styles.buttons]}>
            {Array(6)
              .fill(1)
              .map((slot, idx) => (
                <View key={idx} style={[styles.buttonItem, styles.skeleton]} />
              ))}
          </Row>
        </View>
      </View>
    </View>
  );
}

const useColumnsAmount = () => {
  const is4Columns = useMediaQuery({
    minWidth: 1240,
  });

  const is3Columns = useMediaQuery({
    maxWidth: 1240,
    minWidth: 930,
  });

  const is2Columns = useMediaQuery({
    maxWidth: 930,
    minWidth: 620,
  });

  if (is4Columns) {
    return 4;
  }
  if (is3Columns) {
    return 3;
  }
  if (is2Columns) {
    return 2;
  }
  return 1;
};

export function ColumnSizerLocationLarge({
  component: LoaderComponent = SkeletonLoaderLarge,
  ...props
}: LocationBlockProps & { component?: React.ElementType }) {
  const width = useWindowWidth();
  const columnsAmount = useColumnsAmount();
  const [columnWidth, setColumnWidth] = useState(
    (width - columnsAmount * 40) / columnsAmount
  );
  const onLayout = useCallback(
    (e: LayoutChangeEvent) => setColumnWidth(e.nativeEvent.layout.width),
    []
  );
  return (
    <View
      style={{
        width:
          `calc( (100% - (${columnsAmount} - 1 ) * 40px) / ${columnsAmount} )` as DimensionValue,
      }}
      onLayout={onLayout}
    >
      <LoaderComponent {...props} width={columnWidth} requiresWidth />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  containerHorizontal: {
    width: 580,
  },
  rowOverride: {
    flexDirection: 'row',
  },
  fullWidth: {
    width: '100%',
  },
  columnWide: {
    width: 410,
  },
  alignTop: {
    alignItems: 'flex-start',
  },
  image: {
    width: '100%',
    height: 220,
    borderRadius: 10,
  },
  skeleton: {
    backgroundColor: '#eee',
    borderRadius: 5,
  },
  title: {
    height: 52,
    marginTop: 10,
  },
  hours: {
    height: 20,
    marginTop: 10,
  },
  details: {
    marginTop: 5,
    height: 40,
  },
  callouts: {
    marginTop: 10,
    flexWrap: 'wrap',
    height: 62,
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  buttons: {
    flexWrap: 'wrap',
    marginTop: 10,
  },
  buttonItem: {
    width: 'calc(50% - 4px)' as DimensionValue,
    height: 35,
    margin: 2,
  },
});
