import {
  Pressable,
  StyleSheet,
  Platform,
  ImageSourcePropType,
  ImageStyle,
} from 'react-native';

import BumoVerified from './BumoVerified';
import { LocationCtaButton, LocationCtaButtonVertical } from './Button';
import Favorite from './Favorite';
import { Image } from './Image';
import ImageCarousel from './ImageCarousel';
import { LocationBlockProps } from './LocationBlocks';
import { TransparentRow } from './Row';
import { TextBody, TextH4 } from './StyledText';
import { StyleProps, View, TransparentView } from './Themed';
import Details from './location_blocks/Details';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';
import { useOpenLocationPDP } from '../hooks/useOpenLocationPDP';

export function UnverifiedLocationBlockMini({
  location,
  style,
}: LocationBlockProps) {
  const theme = useColorScheme();
  const {
    name,
    nameId,
    ageFrom,
    ageTo,
    distance,
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    images,
  } = location;
  const openPDPLink = useOpenLocationPDP(nameId);
  const handlePress = () => openPDPLink();
  return (
    <View
      style={[
        styles.containerShared,
        styles.containerMini,
        { backgroundColor: Colors[theme].accentSecondary },
        style,
      ]}
    >
      <Pressable onPress={handlePress}>
        <Image
          source={images[0] as ImageSourcePropType}
          style={styles.imageMini as ImageStyle}
        />
        <TransparentView style={styles.innerMini}>
          <TransparentView>
            <TextBody style={[styles.titleShared, styles.titleMini]}>
              {name}
            </TextBody>
            <Details
              {...{ ageFrom, ageTo, city, neighbourhood, distance }}
              detailStyle={styles.detailText}
            />
          </TransparentView>
          <TransparentRow style={[styles.buttons, styles.buttonsMini]}>
            <LocationCtaButton
              style={{ backgroundColor: Colors[theme].accent, width: '100%' }}
              left="Learn More"
              onPress={handlePress}
            />
          </TransparentRow>
        </TransparentView>
      </Pressable>
    </View>
  );
}

export function UnverifiedLocationBlockMedium({
  location,
  style,
}: LocationBlockProps) {
  const theme = useColorScheme();
  const {
    id,
    name,
    nameId,
    ageFrom,
    ageTo,
    distance,
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    images,
    verificationStatus,
  } = location;
  const openPDPLink = useOpenLocationPDP(nameId);
  const handlePress = () => openPDPLink();
  const handleMorePhotosPress = () => openPDPLink(undefined, { photos: true });
  return (
    <View
      style={[
        styles.containerShared,
        styles.containerMedium,
        { backgroundColor: Colors[theme].accentSecondary },
        style,
      ]}
    >
      <Pressable onPress={handlePress}>
        <ImageCarousel
          images={images}
          dimensions={{
            width: styles.imageMedium.width as number,
            height: styles.imageMedium.height as number,
          }}
          showMaximum={6}
          style={[styles.imageShared, styles.imageMedium]}
          onMoreButtonPress={handleMorePhotosPress}
        />
        <Favorite style={styles.favorite} locationId={id} />
        <BumoVerified style={styles.verified} status={verificationStatus} />

        <TransparentView style={styles.innerMedium}>
          <TransparentView>
            <TextH4 style={[styles.titleShared, styles.titleMedium]}>
              {name}
            </TextH4>
            <Details
              {...{ ageFrom, ageTo, city, neighbourhood, distance }}
              detailStyle={styles.detailText}
            />
          </TransparentView>
          <TransparentRow style={[styles.buttons, styles.buttonsMedium]}>
            <LocationCtaButtonVertical
              style={{ backgroundColor: Colors[theme].accent }}
              top="Learn More"
              onPress={handlePress}
            />
          </TransparentRow>
        </TransparentView>
      </Pressable>
    </View>
  );
}

export function UnverifiedContainer({
  children,
  style,
  size,
}: {
  children: React.ReactNode;
  size: 'medium' | 'mini';
  style?: StyleProps['style'];
}) {
  const theme = useColorScheme();
  return (
    <View
      style={[
        styles.containerShared,
        size === 'medium' && styles.containerMedium,
        size === 'mini' && styles.containerMini,
        { backgroundColor: Colors[theme].accentSecondary },
        style,
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 10,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 270,
        }),
        ...(Layout.isMobileDevice && {
          width: 183,
        }),
      },
    }),
  },
  containerShared: {
    overflow: 'hidden',
    borderRadius: 10,
  },
  containerMini: {
    width: 183,
  },
  containerMedium: {
    width: 270,
  },
  image: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 270,
          height: 220,
        }),
        ...(Layout.isMobileDevice && {
          width: 183,
          height: 100,
        }),
      },
    }),
  },
  imageShared: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  imageMini: {
    width: 183,
    height: 100,
  },
  imageMedium: {
    width: 270,
    height: 220,
  },
  innerMini: {
    justifyContent: 'space-between',
    height: 127,
    margin: 10,
  },
  innerMedium: {
    justifyContent: 'space-between',
    height: 150,
    margin: 12,
  },
  titleShared: {
    overflow: 'hidden',
    ...Platform.select({
      web: {
        textOverflow: 'ellipsis',
      },
    }),
  },
  titleMini: {
    height: 42,
    marginBottom: -4,
  },
  // @ts-ignore
  titleMedium: {
    ...Platform.select({
      web: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        textOverflow: 'ellipsis',
      },
    }),
    height: 52,
  },
  buttons: {
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  buttonsMini: {
    marginTop: 10,
  },
  buttonsMedium: {
    marginTop: 12,
  },
  favorite: {
    top: 5,
  },
  verified: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  detailText: {
    marginRight: 10,
  },
  detailTextMini: {
    maxWidth: 163,
  },
  detailTextMedium: {
    maxWidth: 246,
  },
});
