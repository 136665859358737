import { ChosenSlotRowProps } from './ChosenSlotRow';
import { getTimesRange } from '../PickTimesPopup';

type ChosenSlotsRowProps = undefined | Omit<ChosenSlotRowProps, 'removeSlot'>;

const truthy = <T>(a: T | undefined): a is T => !!a;

export function mergeSameDateSlots(
  slots: ChosenSlotsRowProps[]
): ChosenSlotsRowProps[] {
  const slotsByDate = slots.reduce(
    (acc, cur) => {
      if (!cur) {
        return acc;
      }

      acc[cur.dateString] ||= [];
      acc[cur.dateString] = [...acc[cur.dateString]!, cur];
      return acc;
    },
    {} as Record<string, ChosenSlotsRowProps[]>
  );

  const entries = Object.keys(slotsByDate)
    .map((dateString) => {
      if (slotsByDate[dateString]!.some((p) => p?.disabled)) {
        return slotsByDate[dateString];
      }
      const timesRange = getTimesRange(
        slotsByDate[dateString]!.filter(truthy).map(
          ({ slot: { dropoff, pickup } }) => ({ dropoff, pickup })
        )
      );
      const dayTime = slotsByDate[dateString]!.filter(truthy)
        .map(({ dayTime }) => dayTime)
        .join('-');

      return [{ dateString, slot: timesRange, dayTime, compound: true }];
    })
    .flat();

  return entries;
}
