import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import { BottomSheetModalContainer } from './BottomSheetModal';
import { PrimaryButton } from './Button';
import ModalCloseButton from './ModalCloseButton';
import { FormInput } from './StyledTextInput';
import { TextBodySmall, TextH3 } from '../components/StyledText';
import { Divider, View } from '../components/Themed';
import Layout from '../constants/Layout';
import { LocationFilter } from '../contexts/search';
import { useMaxWidth } from '../hooks/useResponsive';

type LocationAutoCompleteModalProps = {
  isVisible: boolean;
  close: () => void;
  onSubmit: (value: LocationFilter) => void;
};

export default function LocationAutoCompleteModal({
  isVisible,
  close,
  onSubmit,
}: LocationAutoCompleteModalProps) {
  const isMobileDevice = useMaxWidth(430);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ['us'],
      },
    },
    callbackName: 'initAutocomplete',
  });
  const [chosen, setChosen] = useState<LocationFilter>();

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (isVisible && event.key === 'Escape') {
        close();
      }
    },
    [isVisible, close]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleSelect =
    ({
      description,
      structured_formatting: { main_text },
      terms,
    }: google.maps.places.AutocompletePrediction) =>
    () => {
      const displayValue = `${main_text}${
        terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
      }`;
      setValue(displayValue, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        if (!results[0]) {
          return;
        }
        const { lat, lng } = getLatLng(results[0]);
        setChosen({ latitude: lat, longitude: lng, description: displayValue });
      });
    };

  const closeHandler = () => {
    close();
  };

  const CurrentDeviceContainer = isMobileDevice
    ? BottomSheetModalContainer
    : ModalContainer;
  const isCloseButtonVisible = !isMobileDevice;

  return (
    <CurrentDeviceContainer
      isVisible={isVisible}
      onClose={closeHandler}
      snapPoints={[500]}
    >
      <View style={styles.container}>
        {isCloseButtonVisible ? (
          <ModalCloseButton absolute onPress={close} />
        ) : null}
        <TextH3 style={styles.header}>Where do you need care?</TextH3>
        <Divider />
        <View style={styles.body}>
          <FormInput
            placeholder="City or Zip Code"
            autoFocus={!isMobileDevice}
            value={value}
            onChangeText={(value) => setValue(value)}
            textContentType="addressCity"
            onKeyPress={(event) =>
              event.nativeEvent.key === 'Escape' ? close() : null
            }
            rightComponent={
              value && value.length > 0 ? (
                <ModalCloseButton
                  iconStyle={styles.clearIcon}
                  onPress={() => {
                    setValue('');
                  }}
                />
              ) : undefined
            }
            autoCapitalize="none"
          />
          {status === 'OK' && (
            <View style={styles.suggestions}>
              {data.map((suggestion) => {
                const {
                  place_id,
                  structured_formatting: { main_text },
                  terms,
                } = suggestion;
                const description = `${main_text}${
                  terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
                }`;
                return (
                  <Fragment key={place_id}>
                    <TextBodySmall onPress={handleSelect(suggestion)}>
                      {description}
                    </TextBodySmall>
                    <Divider style={styles.divider} />
                  </Fragment>
                );
              })}
            </View>
          )}
          <PrimaryButton
            title="Apply"
            style={[!isMobileDevice && styles.apply]}
            onPress={() => {
              if (chosen) {
                onSubmit(chosen);
              }
              closeHandler();
            }}
          />
        </View>
      </View>
    </CurrentDeviceContainer>
  );
}

interface ModalContainerProps {
  children: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
}

function ModalContainer({ children, isVisible, onClose }: ModalContainerProps) {
  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={onClose}
      useNativeDriver={true}
      hideModalContentWhileAnimating={true}
    >
      {children}
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 550,
    borderRadius: 20,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          alignSelf: 'center',
        }),
      },
    }),
  },
  divider: {
    marginTop: 10,
    marginBottom: 15,
  },
  suggestions: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 500,
        }),
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
      },
    }),
  },
  body: {
    alignItems: 'center',
    marginVertical: 25,
    marginHorizontal: 25,
  },
  header: {
    alignSelf: 'center',
    marginVertical: 20,
    paddingHorizontal: 25,
  },
  clearIcon: {
    width: 20,
    height: 20,
  },
  apply: {
    alignSelf: 'flex-end',
  },
});
