import { DimensionValue, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { BookingCalendarProps } from './BookingCalendar';
import BookingCalendarContainer from './BookingCalendarContainer';
import ModalCloseButton from './ModalCloseButton';
import { View } from './Themed';
import useBlockBodyScroll from '../hooks/useBlockBodyScroll';
import { useMaxWidth } from '../hooks/useResponsive';

type BookingCalendarModalProps = Omit<
  BookingCalendarProps,
  'kids' | 'onKidChosenChange'
> & {
  isVisible: boolean;
  close: () => void;
};

export default function BookingCalendarModal({
  isVisible,
  close,
  ...otherProps
}: BookingCalendarModalProps) {
  useBlockBodyScroll(isVisible);

  const isMobileDevice = useMaxWidth(430);

  return (
    <Modal
      style={{ margin: 0 }}
      isVisible={isVisible}
      onBackdropPress={close}
      useNativeDriver={true}
      hideModalContentWhileAnimating={true}
    >
      <View
        style={[
          styles.container,
          isMobileDevice ? styles.containerMobile : styles.containerLarge,
        ]}
      >
        <ModalCloseButton absolute onPress={close} />
        <BookingCalendarContainer
          onSubmit={close}
          submitLabel="Save"
          style={styles.calendar}
          {...otherProps}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    // @ts-expect-error
    overflowY: 'auto',
    maxHeight: 'calc(100% - 40px)' as DimensionValue,
  },
  containerLarge: { width: 375, alignSelf: 'center' },
  containerMobile: {
    padding: 20,
  },
  calendar: {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    paddingTop: 60,
  },
});
