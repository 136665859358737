import { useNavigation } from '@react-navigation/native';
import { ImageStyle, Platform, Pressable, StyleSheet } from 'react-native';

import { Icon } from './Icon';
import { Image } from './Image';
import { TransparentRow } from './Row';
import { TextH4 } from './StyledText';
import { View } from './Themed';
import Details from './location_blocks/Details';
import HoursDetails from './location_blocks/HoursPriceDetails';
import { Location } from '../api/search';

export function PreviewLocationContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <View style={styles.previewLocation}>{children}</View>;
}

export function PreviewLocation({ location }: { location: null | Location }) {
  const { navigate } = useNavigation();
  if (!location) {
    return null;
  }
  return (
    <PreviewLocationContainer>
      <Pressable
        onPress={() => navigate('Location', { nameId: location.nameId })}
      >
        <MapPreviewLocationContent location={location} />
      </Pressable>
    </PreviewLocationContainer>
  );
}

export function MapPreviewLocationContent({
  location,
}: {
  location: Location;
}) {
  const {
    name,
    distance,
    ageFrom,
    ageTo,
    hours,
    price,
    pricing,
    images = [],
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    reviews,
  } = location;

  const hasWeekly = location.bookingMode.includes('weekly');
  const onlyWeekly = hasWeekly && location.bookingMode.length === 1;

  return (
    <TransparentRow style={{ gap: 10 }}>
      <View style={styles.imageContainer}>
        {!images[0] ? (
          <View
            style={[
              styles.listItemImage,
              {
                backgroundColor: '#f2f2f2',
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            <Icon name="bumo" color="#ddd" style={{ width: 50, height: 50 }} />
          </View>
        ) : (
          <Image
            key={images[0]}
            style={styles.listItemImage as ImageStyle}
            source={{ uri: images[0] }}
          />
        )}
      </View>
      <View style={styles.flex}>
        <TextH4 style={styles.title}>{name}</TextH4>
        <Details
          {...{
            ageFrom,
            ageTo,
            city,
            neighbourhood,
            distance,
            reviews,
            bookByWeek: onlyWeekly,
            price,
            pricing,
          }}
        />
        <HoursDetails hours={hours} />
      </View>
    </TransparentRow>
  );
}

const styles = StyleSheet.create({
  previewLocation: { bottom: 0, left: 0, width: '100%', padding: 20 },
  imageContainer: {
    alignSelf: 'flex-start',
  },
  flex: {
    flex: 1,
  },
  listItemImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
    backgroundColor: '#eee',
  },
  // @ts-ignore
  title: {
    marginTop: 10,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        textOverflow: 'ellipsis',
      },
    }),
  },
});
