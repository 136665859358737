import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const isDesktopDevice = useMediaQuery({
    minWidth: 769,
  });

  const isTabletDevice = useMediaQuery({
    maxWidth: 768,
    minWidth: 431,
  });

  const isMobileDevice = useMediaQuery({
    maxWidth: 430,
  });

  return {
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice,
  };
};

export const useMaxWidth = (maxWidth: number) => {
  return useMediaQuery({ maxWidth });
};

export const useMinWidth = (minWidth: number) => {
  return useMediaQuery({ minWidth });
};
