import {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform, StyleSheet } from 'react-native';

import { Location } from '../../api/search';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { PDPTabId, PDPTabs } from '../../constants/PDPTabs';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { useHelloMessageBarHeight } from '../HelloMessageBar';
import Tabs, { TabItem } from '../Tabs';
import { View } from '../Themed';
import { useHeaderHeight } from '../layouts/HeaderContainer';

export const useScrollToIndicator = () => {
  const isMobileDevice = useMaxWidth(430);
  const helloBarHeight = useHelloMessageBarHeight();
  return (id: string) => scrollToIndicator(id, isMobileDevice, helloBarHeight);
};

export const indicatorIDs = PDPTabs.map((e) => e.pointerID);

export type TabbedMenuRef = {
  triggerItem: (id: PDPTabId) => void;
};

export function useEnabledItems(
  ref: React.ForwardedRef<TabbedMenuRef>,
  locationId: Location['id'] | undefined
) {
  const [enabledItems, setEnabledItems] = useState<PDPTabId[]>([]);
  useEffect(() => {
    setEnabledItems([]);
  }, [locationId]);

  useImperativeHandle(ref, () => ({
    triggerItem(id) {
      setEnabledItems((items) => {
        const s = new Set(items);
        s.add(id);
        return Array.from(s);
      });
    },
  }));

  return enabledItems;
}

const TabbedMenu = forwardRef<
  TabbedMenuRef,
  {
    currentActive: string | null;
    location: Location;
  }
>(function TabbedMenu({ currentActive, location }, ref) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(430);
  const containerRef = useRef(null);
  const headerHeight = useHeaderHeight();
  const helloBarHeight = useHelloMessageBarHeight(false);

  const enabledItems = useEnabledItems(ref, location.id);

  useEffect(() => {
    if (containerRef.current) {
      const containerEl = containerRef.current as unknown as HTMLElement;
      const { left: containerLeftOffset, width: containerWidth } =
        containerEl.getBoundingClientRect();

      const triggerEl = document.getElementById(`${currentActive}-trigger`);
      if (triggerEl) {
        const rect = triggerEl.getBoundingClientRect();
        if (
          rect.left > containerEl.scrollLeft &&
          rect.left + rect.width <
            containerEl.scrollLeft + containerWidth - containerLeftOffset
        ) {
          return;
        }
        containerEl.scrollTo({
          left: containerEl.scrollLeft + rect.left - containerLeftOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [currentActive]);
  return (
    <View
      style={[
        styles.tabbedMenuContainer,
        {
          top: headerHeight + helloBarHeight,
        },
      ]}
      ref={containerRef}
    >
      <Tabs fullWidth>
        {PDPTabs.map((item) =>
          (item.shouldBeVisible && !item.shouldBeVisible(location)) ||
          (item.id &&
            item.requiresTrigger &&
            !enabledItems.includes(item.id)) ? null : (
            <TabItem
              activeBorderColor={Colors[theme].accent}
              key={item.pointerID}
              nativeID={`${item.pointerID}-trigger`}
              isActive={item.pointerID === currentActive}
              onPress={() => {
                const containerElTopOffset =
                  (
                    containerRef.current as HTMLElement | null
                  )?.getBoundingClientRect().top || 0;

                if (
                  isMobileDevice &&
                  item.mobileStrategy !== 'pointer' &&
                  item.forcedOffset !== undefined
                ) {
                  window.scrollTo({
                    behavior: 'smooth',
                    top:
                      containerElTopOffset > 0
                        ? containerElTopOffset + window.scrollY
                        : 0,
                  });
                  return;
                }
                if (item.forcedOffset !== undefined && !isMobileDevice) {
                  window.scrollTo({
                    behavior: 'smooth',
                    top: item.forcedOffset,
                  });
                  return;
                }
                if (!item.pointerID) {
                  return;
                }
                scrollToIndicator(
                  item.pointerID,
                  isMobileDevice,
                  helloBarHeight
                );
              }}
            >
              {item.tabText(location, isMobileDevice)}
            </TabItem>
          )
        )}
      </Tabs>
    </View>
  );
});
export default TabbedMenu;

function scrollToIndicator(
  indicatorId: string,
  isMobileDevice: boolean,
  helloBarHeight: number
) {
  const el = document.getElementById(indicatorId);
  if (!el) {
    return;
  }
  const position = el.getBoundingClientRect();
  window.scrollTo({
    behavior: 'smooth',
    top:
      window.scrollY +
      position.top -
      Layout.navbar.height.desktop -
      (isMobileDevice ? 20 : 50) -
      helloBarHeight +
      5,
  });
}

const styles = StyleSheet.create({
  // @ts-ignore
  tabbedMenuContainer: {
    paddingTop: 20,
    height: 70,
    zIndex: 100,
    ...Platform.select({
      web: {
        position: 'sticky',
        scrollbarWidth: 'none',
      },
    }),
    overflow: 'scroll',
  },
});
