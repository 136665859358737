import { ImageSourcePropType, Platform } from 'react-native';

import { TextButton } from '../components/Button';
import { TextCta, TextH3 } from '../components/StyledText';
import { TextProps } from '../components/Themed';
import Links from '../constants/Links';
import { CAMPAIGN_KEY, findCurrentCampaignKey } from '../utils/campaigns';
import { handleLink } from '../utils/common';

const CAMPAIGNS = {
  BLACK_FRIDAY: {
    periodicMessage: ({ style }) => (
      <TextH3 style={style}>
        Black Friday Blow Out BumoCredit Packages Sale.{' '}
        <TextButton
          TextComponent={TextH3}
          style={style}
          onPress={() => handleLink(Links.blackfriday)}
        >
          Buy Now
        </TextButton>
        !
      </TextH3>
    ),
    helloBarMessage: ({ style }) => (
      <TextCta style={style}>
        Black Friday Special. Save up to 15% on BumoCredit Packages today.{' '}
        <TextButton
          TextComponent={TextCta}
          style={style}
          onPress={() => handleLink(Links.blackfriday)}
        >
          Buy now!
        </TextButton>
      </TextCta>
    ),
    pdpBanner: null,
    onlyWeb: false,
  },
  CYBER_MONDAY: {
    periodicMessage: ({ style }) => (
      <TextH3 style={style}>
        Bumo’s Biggest Sale Ever! 20% off with code THANKS20.
      </TextH3>
    ),
    helloBarMessage: ({ style }) => (
      <TextCta style={style}>
        Cyber Monday Sale! 20% off when you enter THANKS20 at checkout.
      </TextCta>
    ),
    pdpBanner:
      require('../assets/images/banner_cyber_monday.png') as ImageSourcePropType,
    onlyWeb: false,
  },
  CYBER_EXTENDED: {
    periodicMessage: ({ style }) => (
      <TextH3 style={style}>
        Bumo’s Biggest Sale Ever! 20% off with code THANKS20.
      </TextH3>
    ),
    helloBarMessage: ({ style }) => (
      <TextCta style={style}>
        Cyber Extended Sale! 20% off when you enter THANKS20 at checkout.
      </TextCta>
    ),
    pdpBanner:
      require('../assets/images/banner_cyber_extended.png') as ImageSourcePropType,
    onlyWeb: false,
  },
  WINTER_WONDERLAND: {
    periodicMessage: null,
    helloBarMessage: ({ style }) => (
      <TextCta style={style}>
        Don't forget to book{' '}
        <TextButton
          TextComponent={TextCta}
          style={style}
          onPress={() =>
            handleLink(`${window.location.origin}/program/winter-wonderland`)
          }
        >
          winter sessions
        </TextButton>{' '}
        today!
      </TextCta>
    ),
    pdpBanner: null,
    onlyWeb: false,
  },
  WINTER_WONDERLAND_COUPON: {
    periodicMessage: null,
    helloBarMessage: ({ style }) => (
      <TextCta style={style}>
        Don't forget to book{' '}
        <TextButton
          TextComponent={TextCta}
          style={style}
          onPress={() =>
            handleLink(`${window.location.origin}/program/winter-wonderland`)
          }
        >
          winter sessions
        </TextButton>{' '}
        today and use code WINTER15 at checkout for 15% off
      </TextCta>
    ),
    pdpBanner: null,
    onlyWeb: false,
  },
  SUMMER_CAMPS_SPRING: {
    periodicMessage: null,
    helloBarMessage: SummerCampWithSpringHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  SUMMER_CAMPS: {
    periodicMessage: null,
    helloBarMessage: SummerCampHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  LABOR_DAY: {
    periodicMessage: null,
    helloBarMessage: LaborDayHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  PNO: {
    periodicMessage: null,
    helloBarMessage: PNOHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  VOTE: {
    periodicMessage: null,
    helloBarMessage: VoteHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },

  BLACK_FRIDAY_2024: {
    periodicMessage: null,
    helloBarMessage: BF2024HelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },

  CYBER_MONDAY_2024: {
    periodicMessage: null,
    helloBarMessage: CM2024HelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  WINTER_WONDERLAND_2024_COUPON: {
    periodicMessage: null,
    helloBarMessage: WinterWonderlandHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  WINTER_WONDERLAND_2024: {
    periodicMessage: null,
    helloBarMessage: WinterWonderland2024HelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  LA_FAMILY_SUPPORT: {
    periodicMessage: null,
    helloBarMessage: LAFamilySupportHelloBar,
    pdpBanner: null,
    onlyWeb: false,
  },
  SUMMER_CAMPS_2025: {
    helloBarMessage: Summer2025HelloBar,
    periodicMessage: null,
    pdpBanner: null,
    onlyWeb: false,
  },
} satisfies Record<
  CAMPAIGN_KEY,
  {
    periodicMessage: React.ComponentType<{ style?: TextProps['style'] }> | null;
    helloBarMessage: React.ComponentType<{ style?: TextProps['style'] }> | null;
    pdpBanner: ImageSourcePropType | null;
    onlyWeb: boolean;
  }
>;

function SummerCampWithSpringHelloBar({
  style,
}: {
  style?: TextProps['style'];
}) {
  return (
    <TextCta style={style}>
      Book{' '}
      <TextButton
        TextComponent={TextCta}
        onPress={() => handleLink(`${window.location.origin}/program/summer`)}
      >
        spring or summer care
      </TextButton>{' '}
      today! Use code SUMMER24 at checkout and get 10% off your spring and
      summer bookings.
    </TextCta>
  );
}
function SummerCampHelloBar({ style }: { style?: TextProps['style'] }) {
  return (
    <TextCta style={style}>
      Book{' '}
      <TextButton
        TextComponent={TextCta}
        onPress={() => handleLink(`${window.location.origin}/program/summer`)}
      >
        summer care
      </TextButton>{' '}
      today!
    </TextCta>
  );
}

function LaborDayHelloBar({ style }: { style?: TextProps['style'] }) {
  return (
    <TextCta style={style}>
      Labor Day Sale: Get up to $2300 in free child care credit! Offer ends
      9/2/2024. Learn more{' '}
      <TextButton
        TextComponent={TextCta}
        onPress={() => handleLink(Links.campaigns.laborDay)}
      >
        here
      </TextButton>
      .
    </TextCta>
  );
}

function PNOHelloBar({ style }: { style?: TextProps['style'] }) {
  return (
    <TextCta style={style}>
      You deserve a kid-free night out. Evening and weekend child care
      available.{' '}
      <TextButton TextComponent={TextCta} onPress={() => handleLink(`/pno`)}>
        Learn more.
      </TextButton>
    </TextCta>
  );
}

function VoteHelloBar({ style }: { style?: TextProps['style'] }) {
  return (
    <TextCta style={style}>
      Free child care on Election Day (Nov 5) only. Use code VOTE at checkout.{' '}
      <TextButton
        TextComponent={TextCta}
        onPress={() =>
          handleLink('https://bumo.com/terms-of-use-election-day/')
        }
      >
        Terms apply.
      </TextButton>
    </TextCta>
  );
}

function BF2024HelloBar({
  style,
  onPress,
}: {
  style?: TextProps['style'];
  onPress?: () => void;
}) {
  const pressHandler = onPress || (() => handleLink('/bumo-credit', false));
  return (
    <TextCta style={style}>
      Get your Black Friday savings today! Save 20% on child care sessions with
      code BFCM2024 + 10% off{' '}
      <TextButton
        TextComponent={TextCta}
        onPress={pressHandler}
        style={Platform.select({
          native: {
            marginBottom: -2,
          },
        })}
      >
        BumoCredit packages
      </TextButton>
      !
    </TextCta>
  );
}

function CM2024HelloBar({
  style,
  onPress,
}: {
  style?: TextProps['style'];
  onPress?: () => void;
}) {
  const pressHandler = onPress || (() => handleLink('/bumo-credit', false));
  return (
    <TextCta style={style}>
      Get your Cyber Monday savings today! Save 20% on child care sessions with
      code BFCM2024 + 10% off{' '}
      <TextButton TextComponent={TextCta} onPress={pressHandler}>
        BumoCredit packages
      </TextButton>
      !
    </TextCta>
  );
}

function WinterWonderlandHelloBar({
  style,
  onPress,
}: {
  style?: TextProps['style'];
  onPress?: () => void;
}) {
  const pressHandler =
    onPress || (() => handleLink('/program/winter-wonderland', false));
  return (
    <TextCta style={style}>
      Use code WINTER15 at checkout and get 15% off your{' '}
      <TextButton TextComponent={TextCta} onPress={pressHandler}>
        winter bookings
      </TextButton>
      !
    </TextCta>
  );
}

function WinterWonderland2024HelloBar({
  style,
  onPress,
}: {
  style?: TextProps['style'];
  onPress?: (code?: CampaignEventCodes) => void;
}) {
  const pressHandler = onPress
    ? () => onPress(CampaignEventCodes.WINTER_WONDERLAND_2024)
    : () => handleLink('/program/winter-wonderland', false);
  return (
    <TextCta style={style}>
      Don't forget to book{' '}
      <TextCta
        style={[
          style,
          { textDecorationStyle: 'solid', textDecorationLine: 'underline' },
        ]}
        onPress={pressHandler}
      >
        winter sessions
      </TextCta>{' '}
      today!
    </TextCta>
  );
}

function LAFamilySupportHelloBar({ style }: { style?: TextProps['style'] }) {
  return (
    <TextCta style={style}>
      To LA families affected by the fires:{' '}
      <TextCta
        style={[
          style,
          { textDecorationStyle: 'solid', textDecorationLine: 'underline' },
        ]}
        onPress={() => handleLink('https://lafamilysupport.com')}
      >
        Let us help with child care
      </TextCta>{' '}
      • You're not alone, we're here for you. ❤️‍🩹
    </TextCta>
  );
}

function Summer2025HelloBar({
  style,
  onPress,
}: {
  style?: TextProps['style'];
  onPress?: (code?: CampaignEventCodes) => void;
}) {
  const pressHandler = onPress
    ? () => onPress(CampaignEventCodes.SUMMER_CAMPS_2025)
    : () => handleLink('/program/summer', false);
  return (
    <TextCta style={style}>
      Summer 😎 🏖️ will be here before you know it.{' '}
      <TextCta
        style={[
          style,
          { textDecorationStyle: 'solid', textDecorationLine: 'underline' },
        ]}
        onPress={pressHandler}
      >
        Book summer camps
      </TextCta>{' '}
      today!
    </TextCta>
  );
}

export enum CampaignEventCodes {
  WINTER_WONDERLAND_2024,
  SUMMER_CAMPS_2025,
}

export const findCurrentCampaign = () => {
  const activeCampaignKey = findCurrentCampaignKey();

  if (!activeCampaignKey) {
    return null;
  }

  return CAMPAIGNS[activeCampaignKey] || null;
};

export const shouldShowPeriodicMessageBar = () => {
  const currentCampaign = findCurrentCampaign();
  return currentCampaign !== null && currentCampaign.periodicMessage;
};
