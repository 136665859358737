import {
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { useAuthState } from './authentication';
import { Action, reducer } from '../reducers/genericContext';

const SearchStateContext = createContext({} as State);
const SearchUpdateContext = createContext<React.Dispatch<Action<State>>>(
  () => {}
);

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const { parent: { children: kids = [] } = {} } = useAuthState();

  const [searchState, dispatch] = useReducer<Reducer<State, Action<State>>>(
    reducer,
    {
      kids: kids.map((kid: { id: number }) => kid.id),
      location: {},
      rangeStart: null,
      rangeEnd: null,
      dates: null,
      page: 1,
    }
  );

  useEffect(() => {
    dispatch({
      type: 'field',
      field: 'kids',
      value: kids.map((kid: { id: number }) => kid.id),
    });
    // dispatch({
    //   type: 'field',
    //   field: 'who',
    //   value:
    //     kids.length > 0
    //       ? kids.map((k) => ({
    //           id: k.id,
    //           name: `${k.firstName || ''} ${k.lastName || ''}`,
    //           age: `${getAgeInMonths(k.birthdate)}m`,
    //           enabled: true,
    //         }))
    //       : [
    //           {
    //             id: 1,
    //             name: 'Child 1',
    //             age: null,
    //             enabled: false,
    //           },
    //         ],
    // });
  }, [kids.length]);

  return (
    <SearchStateContext.Provider value={searchState}>
      <SearchUpdateContext.Provider value={dispatch}>
        {children}
      </SearchUpdateContext.Provider>
    </SearchStateContext.Provider>
  );
};

export function useSearchState() {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchProvider');
  }

  return context;
}

export function useSearchDispatch() {
  const context = useContext(SearchUpdateContext);
  if (context === undefined) {
    throw new Error('useSearchDispatch must be used within a SearchProvider');
  }

  return context;
}

export function useSearchStateUpdate() {
  const dispatch = useSearchDispatch();
  return (value: Partial<State>) => {
    dispatch({
      type: 'state',
      value,
    });
  };
}

export interface LocationFilter {
  latitude?: number;
  longitude?: number;
  description?: string;
}

interface State {
  rangeStart: string | null;
  rangeEnd: string | null;
  dates: string[] | null;
  kids: number[];
  page: number;
  location: LocationFilter;
}
