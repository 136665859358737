import Checkbox from 'expo-checkbox';
import { Platform, Pressable, StyleSheet, View } from 'react-native';

import { TextButton } from './Button';
import Row from './Row';
import { TextBodySmall } from './StyledText';
import TextWithLinks from './TextWithLinks';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import LINKS from '../constants/Links';
import useColorScheme from '../hooks/useColorScheme';
import { handleLink } from '../utils/common';

const POLICY_TEXT = (
  weekly = false,
  cancelation = 72,
  days = Math.ceil(cancelation / 24)
) =>
  cancelation === 0
    ? `Families may cancel at this location but they will not be eligible for any BumoCredit if they do so.  Please review "Terms of Use|0" for Cancellation Policy details.`
    : `Families must cancel at least ${cancelation} hours (~${days} days) before the start of their session in order to receive ${
        FeatureFlags.CANCELLATION
          ? 'BumoCredit that can be applied to future sessions'
          : 'a refund on sessions'
      }. Rescheduling a session within ${cancelation} hours (~${days} days) is treated as a cancellation of the original session${
        FeatureFlags.CANCELLATION
          ? ' and will not be eligible for BumoCredit'
          : ''
      }. ${
        weekly
          ? 'For weeklong bookings, the session start time is the first booked day of the week for that weeklong booking.'
          : ''
      } Please review "Terms of Use|0" for Cancellation Policy details.`;

const POLICY_LINKS = [LINKS.toc];

type CancellationPolicyCheckboxProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  weekly?: boolean;
  cancelationTreshold?: number;
  onlyText?: boolean;
};

export default function CancellationPolicyCheckbox({
  value,
  onChange,
  weekly,
  cancelationTreshold,
  onlyText,
}: CancellationPolicyCheckboxProps) {
  const theme = useColorScheme();

  return (
    <Row style={styles.container}>
      {onlyText ? null : (
        <Pressable
          style={styles.checkboxContainer}
          onPress={() => onChange(!value)}
        >
          <Checkbox
            style={styles.checkbox}
            color={Colors[theme].textSecondary}
            value={value}
            onValueChange={() => onChange(!value)}
          />
        </Pressable>
      )}
      <View style={styles.text}>
        <TextBodySmall style={styles.title}>
          I agree to the Cancellation Policy
        </TextBodySmall>
        <View style={styles.row}>
          <TextWithLinks
            text={POLICY_TEXT(weekly, cancelationTreshold)}
            links={POLICY_LINKS}
            textStyle={{ color: Colors[theme].textSecondary }}
            linkComponent={TextButton}
            textComponent={TextBodySmall}
            clickFn={handleLink}
          />
        </View>
      </View>
    </Row>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          paddingVertical: 40,
        }),
        ...(Layout.isMobileDevice && {
          paddingVertical: 30,
        }),
      },
    }),
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  text: {
    flex: 1,
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          marginRight: 25,
        }),
      },
    }),
  },
  title: {
    marginBottom: 8,
  },
  checkboxContainer: {
    padding: 12,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginRight: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginRight: 10,
        }),
      },
    }),
  },
  checkbox: {
    height: 24,
    width: 24,
    borderRadius: 5,
  },
});
