import { API_V1_URL } from './config';

const PROGRAM_PAGES_V1_URL = `${API_V1_URL}program_pages/`;

export interface Tab {
  title: string;
  ids: number[];
}

interface ProgramPageTabsSettings {
  tabs: Tab[];
}

export type ProgramPageKey =
  | 'winter_wonderland'
  | 'la_family_support'
  | 'summer_camps';

export async function programPageSettings(
  pageKey: ProgramPageKey
): Promise<ProgramPageTabsSettings> {
  const response = await fetch(`${PROGRAM_PAGES_V1_URL}${pageKey}`);
  return await response.json();
}
