import { useState } from 'react';
import Modal from 'react-native-modal';

import Colors from '../../constants/Colors';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useColorScheme from '../../hooks/useColorScheme';
import { handleLink } from '../../utils/common';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { IconButton } from '../Button';
import { Icon } from '../Icon';
import ModalCloseButton from '../ModalCloseButton';
import { TextH3, TextBody, TextBodySmall } from '../StyledText';
import { TransparentView, View } from '../Themed';

export default function ShareLocationButton({ title }: { title: string }) {
  const theme = useColorScheme();
  const dispatchSnackbar = useSnackbarDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const onSharePress = () => {
    if (navigator && 'share' in navigator) {
      navigator.share({
        title: `BUMO ${title}`,
        text: `Check out ${title}`,
        url: window.location.href,
      });
      return;
    }
    setIsOpen(true);
  };
  const hide = () => setIsOpen(false);
  return (
    <>
      <IconButton
        icon={<Icon name="share" />}
        text={null}
        onPress={onSharePress}
      />
      <Modal
        useNativeDriver
        style={{ margin: 0 }}
        isVisible={isOpen}
        onBackdropPress={hide}
        hideModalContentWhileAnimating
      >
        <View
          style={{
            borderRadius: 20,
            minHeight: 100,
            maxWidth: 500,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <ModalCloseButton absolute onPress={hide} />
          <TransparentView
            style={{
              paddingVertical: 20,
              borderBottomColor: Colors[theme].lines,
              borderBottomWidth: 1,
            }}
          >
            <TextH3 style={{ textAlign: 'center' }}>Share</TextH3>
          </TransparentView>
          <TransparentView style={{ padding: 20, gap: 15 }}>
            <IconButton
              icon={
                <Icon
                  name="facebook"
                  color={Colors[theme].accent}
                  style={{ marginRight: 20 }}
                />
              }
              text={<TextBody>Facebook</TextBody>}
              onPress={() =>
                handleLink(
                  `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
                  true
                )
              }
            />
            <IconButton
              icon={
                <Icon
                  name="linkedin"
                  color={Colors[theme].accent}
                  style={{ marginRight: 20 }}
                />
              }
              text={<TextBody>LinkedIn</TextBody>}
              onPress={() =>
                handleLink(
                  `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
                  true
                )
              }
            />
            <IconButton
              icon={
                <Icon
                  name="email"
                  color={Colors[theme].accent}
                  style={{ marginRight: 20 }}
                />
              }
              text={<TextBody>Email</TextBody>}
              onPress={() => {
                handleLink(
                  `mailto:?subject=${encodeURIComponent(
                    `Check out care at ${title}`
                  )}&body=${encodeURIComponent(
                    `Visit following link: ${window.location.href}`
                  )}`,
                  true
                );
              }}
            />
            <IconButton
              icon={
                <Icon
                  name="cloud"
                  color={Colors[theme].accent}
                  style={{ marginRight: 20 }}
                />
              }
              text={<TextBody>Copy link</TextBody>}
              onPress={async () => {
                await copyToClipboard(window.location.href);
                dispatchSnackbar({
                  message: (
                    <TextBodySmall>Link copied to clipboard!</TextBodySmall>
                  ),
                });
              }}
            />
          </TransparentView>
        </View>
      </Modal>
    </>
  );
}
