import Constants from 'expo-constants';

function getApiUrl() {
  const domain = location.hostname.split('.').slice(-1)[0];
  if (domain === 'care') {
    return Constants.expoConfig?.extra?.API_URL.replace('com', 'care');
  } else {
    return Constants.expoConfig?.extra?.API_URL;
  }
}

export const API_V1_URL = `${getApiUrl()}v1/`;
export const CLIENT_ID = Constants.expoConfig?.extra?.CLIENT_ID;
