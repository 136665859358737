import React, { useState } from 'react';
import { Platform, View, Linking, Text } from 'react-native';
import Markdown from 'react-native-markdown-display';

import { TextBody, TextBodySmall } from './StyledText';
import { getLocation } from '../api/locations';
import Layout from '../constants/Layout';
import useAppNavigation, {
  useNativeAppNavigation,
} from '../hooks/useAppNavigation';

const useLinkHandler = Platform.select({
  default: useWebLinkHandler,
  native: useNativeLinkHandler,
});

function extractLocationNameId(url: string) {
  const match = url.match(/^\/location\/(.+)$/);
  return match ? match[1] : null;
}

function useWebLinkHandler() {
  const navigation = useAppNavigation();
  return (url: string) => {
    const nameId = extractLocationNameId(url);
    if (!nameId) {
      return;
    }
    navigation.push('Location', { nameId });
  };
}

function useNativeLinkHandler() {
  const navigation = useNativeAppNavigation();
  return async (url: string) => {
    const nameId = extractLocationNameId(url);
    if (!nameId) {
      return;
    }
    const location = await getLocation(nameId);
    if (!location.ok) return;
    navigation.push('SingleLocation', {
      location: location.location,
    });
  };
}

function openUrl(url: string, customCallback?: (url: string) => boolean) {
  if (customCallback) {
    const result = customCallback(url);
    if (url && result && typeof result === 'boolean') {
      Linking.openURL(url);
    }
  } else if (url) {
    Linking.openURL(url);
  }
}

function DefaultWrapper({ children }: { children: React.ReactNode }) {
  // flexDirection: 'row' based on the comment
  // https://github.com/iamacup/react-native-markdown-display/issues/226#issuecomment-2357964486
  return <View style={{ flexDirection: 'row' }}>{children}</View>;
}

const fontSizeStyles = Platform.select({
  web: {
    fontSize: 18,
    lineHeight: 23.4,
    ...(Layout.isMobileDevice && {
      fontSize: 16,
      lineHeight: 20.8,
    }),
  },
  native: {
    fontSize: 16,
    lineHeight: 20.8,
  },
});

export default function MarkdownPreviewer({
  children,
}: {
  children: React.ReactNode;
}) {
  const linkHandler = useLinkHandler();

  const onLinkPress = (url: string) => {
    if (url.startsWith('/location/')) {
      linkHandler(url);
      return false;
    }
    return true;
  };

  return (
    <DefaultWrapper>
      <Markdown
        onLinkPress={onLinkPress}
        style={{
          heading1: { fontFamily: 'Mont', marginBottom: 5, lineHeight: 45 },
          heading2: { fontFamily: 'Mont', marginBottom: 5, lineHeight: 35 },
          heading3: { fontFamily: 'Mont', marginBottom: 5 },
          heading4: { fontFamily: 'Mont', marginBottom: 5 },
          text: {
            fontFamily: 'QuincyCF',
            ...fontSizeStyles,
          },
        }}
        rules={{
          body: (node, children, parent, styles) => (
            <View
              key={node.key}
              style={[
                {
                  width: '100%',
                },
                styles.body,
              ]}
            >
              {children}
            </View>
          ),
          image: () => null,
          strong: (node, children, parent, styles, onLinkPress) => {
            // This is a workaround to apply the font family to the strong tag
            // The font family is not applied to the strong tag in the markdown display
            // As the strong tag contains another text tag, it receives the font family from the text tag
            // So instead of wrapping the strong tag around, we only apply the font family to the text tag
            const updatedChildren = React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  // @ts-expect-error
                  style: [
                    {
                      fontFamily: 'QuincyCFBold',
                      ...(Platform.OS === 'web' && { fontWeight: 'bold' }),
                      ...fontSizeStyles,
                    },
                  ],
                });
              }
              return child;
            });
            return <>{updatedChildren}</>;
          },
          text: (node, children, parent, styles, inheritedStyles = {}) => {
            // This is a workaround to apply the inherited styles to the text tag
            // Which fixes styling for headings
            return (
              <Text key={node.key} style={[styles.text, inheritedStyles]}>
                {node.content}
              </Text>
            );
          },
        }}
      >
        {children}
      </Markdown>
    </DefaultWrapper>
  );
}
