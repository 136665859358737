import { useRollbarPerson } from '@rollbar/react';
import { createContext, useCallback, useContext, useReducer } from 'react';

import { getMe } from '../api/parents';
import { Child, Parent } from '../utils/api';

interface AuthState {
  parent: Parent | undefined;
  isLoggedIn: boolean;
  isLoading: boolean;
  refreshToken: string;
}

const defaultState: AuthState = {
  parent: undefined,
  isLoggedIn: false,
  isLoading: false,
  refreshToken: '',
};

const AuthStateContext = createContext(defaultState);
const AuthDispatchContext = createContext(
  {} as React.Dispatch<AuthReducerAction>
);

export const useSetInitialParent = () => {
  const dispatch = useContext(AuthDispatchContext);
  return useCallback(
    (me: Awaited<ReturnType<typeof getMe>>) =>
      dispatch({
        type: 'state',
        value: getInitialState(me),
      }),
    []
  );
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, defaultState);

  process.env.NODE_ENV !== 'development' &&
    useRollbarPerson(state.parent as object);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

export function useIsLoggedIn() {
  const { isLoggedIn } = useAuthState();
  return isLoggedIn;
}

export const getInitialState = (
  initialParent: Parent | undefined = undefined
): AuthState => ({
  ...defaultState,
  parent: initialParent,
  isLoggedIn: !!initialParent,
  isLoading: false,
});

type AuthReducerAction =
  | { type: 'state'; value: AuthState }
  | { type: 'addChild'; value: Child }
  | { type: 'updateChild'; id: number; value: Partial<Omit<Child, 'id'>> }
  | {
      type: 'balance';
      value: number;
    }
  | {
      type: 'booked_location';
      value: number;
    }
  | {
      type: 'favorite';
      value: number;
    }
  | {
      type: 'unfavorite';
      value: number;
    }
  | {
      type: 'login_success';
      value: {
        parent: Parent;
        refresh_token: string;
      };
    }
  | { type: 'logout' }
  | { type: 'loading' }
  | {
      type: 'updatePersonalInfo';
      value:
        | {
            firstName: string;
            lastName: string;
            email: string;
            telephone: string;
          }
        | { isBeingDeleted: boolean };
    }
  | {
      type: 'updateFormsReuseConsent';
      value: boolean;
    };

function authReducer(state: AuthState, action: AuthReducerAction): AuthState {
  switch (action.type) {
    case 'state':
      return {
        ...state,
        ...action.value,
      };
    case 'addChild':
      if (!state.parent) return state;
      return {
        ...state,
        parent: {
          ...state.parent,
          children: [...state.parent.children, action.value],
        },
      };
    case 'balance':
      if (!state.parent) return state;
      return {
        ...state,
        parent: {
          ...state.parent,
          careCreditBalance: action.value,
        },
      };
    case 'booked_location':
      if (!state.parent) return state;
      return {
        ...state,
        parent: {
          ...state.parent,
          bookedLocations: [...state.parent.bookedLocations, action.value],
        },
      };
    case 'favorite':
      if (!state.parent) return state;
      return {
        ...state,
        parent: {
          ...state.parent,
          favorites: [...new Set([...state.parent.favorites, action.value])],
        },
      };
    case 'unfavorite':
      if (!state.parent) return state;
      return {
        ...state,
        parent: {
          ...state.parent,
          favorites: state.parent.favorites.filter((id) => id !== action.value),
        },
      };

    case 'login_success':
      return {
        ...state,
        parent: action.value.parent,
        refreshToken: action.value.refresh_token,
        isLoading: false,
        isLoggedIn: true,
      };
    case 'loading':
      return {
        ...state,
        isLoading: true,
      };
    case 'logout':
      return {
        ...state,
        ...defaultState,
      };
    case 'updatePersonalInfo':
      if (!state.parent) {
        return state;
      }
      return {
        ...state,
        parent: {
          ...state.parent,
          ...action.value,
        },
      };
    case 'updateChild': {
      if (!state.parent) return state;

      const childId = action.id;
      const child = state.parent.children.find((c) => c.id === childId);

      if (!child) {
        return state;
      }

      const index = state.parent.children.findIndex((c) => c === child);

      const updatedChild = {
        ...child,
        ...action.value,
      };

      const updatedChildren = [...state.parent.children];
      updatedChildren[index] = updatedChild;

      return {
        ...state,
        parent: {
          ...state.parent,
          children: updatedChildren,
        },
      };
    }
    case 'updateFormsReuseConsent': {
      if (!state.parent) return state;
      return {
        ...state,
        parent: { ...state.parent, formsReuseConsent: action.value },
      };
    }
    default:
      return state;
  }
}
