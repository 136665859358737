import { useState } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import { LocationFilter } from '../../contexts/search';

export const useLocationSearch = (initialChosen: LocationFilter) => {
  const [chosen, setChosen] = useState<LocationFilter>(initialChosen);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ['us'],
      },
    },
    callbackName: 'initAutocomplete',
  });

  const handleSelect =
    ({
      description,
      structured_formatting: { main_text },
      terms,
    }: google.maps.places.AutocompletePrediction) =>
    () => {
      const displayValue = `${main_text}${
        terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
      }`;
      setValue(displayValue, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        if (!results[0]) {
          return;
        }
        const { lat, lng } = getLatLng(results[0]);
        setChosen({ latitude: lat, longitude: lng, description: displayValue });
      });
    };

  return {
    data:
      status === 'OK'
        ? data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text },
              terms,
            } = suggestion;
            const displayText = `${main_text}${
              terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
            }`;
            return {
              place_id,
              displayText,
              handleSelect: handleSelect(suggestion),
            };
          })
        : null,
    chosen,
    handleSelect,
    value,
    setValue,
  };
};
