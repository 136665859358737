import { Platform } from 'react-native';

import { ViewWithClassname } from '../Themed';

export function CalendarPlaceholder({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ViewWithClassname
      className="shadowed-scroller"
      // @ts-expect-error
      style={{
        flex: 1,
        ...Platform.select({ web: { overflow: 'auto' } }),
      }}
    >
      {children}
    </ViewWithClassname>
  );
}
