import { useEffect, useMemo, useState } from 'react';

import { BookingCalendarProps } from './BookingCalendar';
import { PrimaryButton } from './Button';
import CalendarSessionsProvider from './CalendarSessionsProvider';
import KidsInput, { Kid } from './KidsInput';
import { TransparentView } from './Themed';
import WeekCalendar from './WeekCalendar';
import { getCachedStatus } from './booking_calendar/BookingCalendarError';
import CalendarTypeToggler, {
  useCurrentVisibleDay,
  useIsToggleAvailable,
  useUpdateCurrentVisibleDay,
} from './booking_calendar/CalendarTypeToggler';
import {
  ChosenWeekViewer,
  formatChosenWeeks,
} from './booking_calendar/ChosenWeekViewer';
import {
  ViewSelectedButton,
  ViewSelectedWrapper,
} from './booking_calendar/ViewSelectedButton';
import { WeekBookingDiscountInfo } from './booking_calendar/WeeklyDiscountInfo';
import {
  parseWeekParam,
  prepareSharedWeeksParam,
  useShareLinkParam,
} from './booking_calendar/sharedParams';
import { BookingSlot } from '../api/search';
import { useAuthenticate } from '../contexts/authFlow';
import { useIsLoggedIn } from '../contexts/authentication';
import {
  DateSlots,
  DayTimeSlot,
  useCheckoutPropUpdate,
  useCheckoutState,
  useCheckoutStateUpdate,
} from '../contexts/checkout';
import useAppNavigation from '../hooks/useAppNavigation';
import useLocationSlotsQuery from '../hooks/useLocationSlotsQuery';
import { setEligibleKid } from '../utils/children';
import { getUnion } from '../utils/common';
import { notNull } from '../utils/notnull';
import { transformSlots } from '../utils/slots';
import {
  WeekEntry,
  formatWeekDates,
  groupSlots,
  prepareWeekEntries,
} from '../utils/weekly';

export function getPickTimesFromDateSlots(
  dateSlots: Record<string, DateSlots>
) {
  return Object.fromEntries(
    Object.entries(dateSlots)
      .map(([weekId, dateSlots]) => {
        let filteredDateSlotsKeys: null | string[] = null;

        Object.values(dateSlots).forEach((v) => {
          if (filteredDateSlotsKeys === null) {
            filteredDateSlotsKeys = Object.keys(v);
          } else {
            filteredDateSlotsKeys = getUnion(
              filteredDateSlotsKeys,
              Object.keys(v)
            );
          }
        });

        const firstDateSlots = Object.values(dateSlots)[0];

        if (!filteredDateSlotsKeys || !firstDateSlots) {
          return [weekId, {}] as const;
        }

        const filteredEntries = Object.entries(firstDateSlots).filter(([key]) =>
          filteredDateSlotsKeys!.includes(key)
        );

        // Use Object.fromEntries to convert the filtered entries back into an object
        const filteredDateSlots = Object.fromEntries(filteredEntries);

        return [weekId, filteredDateSlots] as const;
      })
      .filter(([, dateSlots]) => Object.keys(dateSlots).length > 0)
  );
}

export const useWeeklySlots = (slots: BookingSlot[]) => {
  const slotsGrouped = useMemo(() => groupSlots(slots), [slots]);

  const weekEntries = useMemo(
    () => prepareWeekEntries(slotsGrouped),
    [slotsGrouped]
  );

  const weekEntriesById = useMemo(
    () =>
      weekEntries.reduce(
        (acc, entry) => ({
          ...acc,
          [entry.id]: entry,
        }),
        {} as Record<string, WeekEntry>
      ),
    [weekEntries]
  );

  return {
    slots,
    slotsGrouped,
    weekEntries,
    weekEntriesById,
  };
};

export type PickTimesPerWeek = {
  [k: string]: {
    [k: string]: DayTimeSlot;
  };
};

export type DateSlotsPerWeek = {
  [k: string]: DateSlots;
};

export const useFilteredDateSlots = (
  slotsPerWeek: Record<string, BookingSlot[]>,
  kids: Kid[],
  isLoggedIn: boolean
): {
  dateSlotsPerWeek: DateSlotsPerWeek;
  pickTimesPerWeek: PickTimesPerWeek;
} => {
  const dateSlotsPerWeek = useMemo(
    () =>
      Object.entries(slotsPerWeek).reduce(
        (acc, [weekId, slots]) => ({
          ...acc,
          [weekId]: transformSlots(slots, kids, isLoggedIn),
        }),
        {} as Record<string, DateSlots>
      ),
    [slotsPerWeek, kids, isLoggedIn]
  );

  const pickTimesPerWeek = useMemo(
    () => getPickTimesFromDateSlots(dateSlotsPerWeek),
    [dateSlotsPerWeek]
  );

  const filteredDateSlotsPerWeek = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(dateSlotsPerWeek).filter(
          ([weekid]) => weekid in pickTimesPerWeek
        )
      ),
    [dateSlotsPerWeek, pickTimesPerWeek]
  );

  return {
    dateSlotsPerWeek: filteredDateSlotsPerWeek,
    pickTimesPerWeek,
  };
};

const useSyncChosenWeekDaytimesInCheckout = (
  locationNameId: string,
  chosenWeekDaytimes: Record<string, string[]>
) => {
  const updateCheckoutProp = useCheckoutPropUpdate();
  useEffect(() => {
    updateCheckoutProp(
      {
        [locationNameId]: chosenWeekDaytimes,
      },
      'weeklyState'
    );
  }, [locationNameId, chosenWeekDaytimes]);
};

const getFirstDateFromChosen = (
  chosenWeekDaytimes: Record<string, string[]>
) => {
  const weeks = Object.keys(chosenWeekDaytimes);
  if (weeks.length === 0) return null;
  weeks.sort();
  const [date] = weeks[0]?.split(':') || [];
  return date || null;
};

export const WeekCalendarContainer = ({
  locationId,
  locationNameId,
  onSubmit,
  today,
  kids,
  onKidChosenChange,
  activeStartDate,
  onPricePress,
  hasWeeklyDiscount,
}: BookingCalendarProps) => {
  const { navigate } = useAppNavigation();
  const isLoggedIn = useIsLoggedIn();

  const authenticate = useAuthenticate();
  const onLoggedOutEdit = () => authenticate();

  const updateCheckoutState = useCheckoutStateUpdate();

  const { weeklyState } = useCheckoutState();

  const [chosenWeekDaytimes, setChosenWeekDaytimes] = useState<
    Record<string, string[]>
  >(weeklyState?.[locationNameId] || {});

  useSyncChosenWeekDaytimesInCheckout(locationNameId, chosenWeekDaytimes);

  const { data: slots, isFetching: fetching } =
    useLocationSlotsQuery(locationId);

  const { slotsGrouped, weekEntries } = useWeeklySlots(slots);

  const [shareLinkParam, resetShareLinkParam] = useShareLinkParam();
  useEffect(() => {
    if (fetching || !shareLinkParam) return;
    const parsedParam = parseWeekParam(shareLinkParam);
    const filteredParams = Object.fromEntries(
      Object.entries(parsedParam)
        .map((e) => {
          const [weekId, daytimes] = e;
          const slots = slotsGrouped.slotsPerWeek[weekId];
          const weekDaytimes = new Set(slots?.map((s) => s.dayTime));
          if (!slots || !daytimes) return null;
          const filteredDaytimes = daytimes.filter((d) => weekDaytimes.has(d));
          if (filteredDaytimes.length === 0) return null;
          return [weekId, filteredDaytimes];
        })
        .filter(notNull)
    );
    setChosenWeekDaytimes(filteredParams);
    resetShareLinkParam();
  }, [fetching, slotsGrouped, shareLinkParam]);

  const currentVisibleDay = useCurrentVisibleDay();
  const updateVisibleDay = useUpdateCurrentVisibleDay();
  useEffect(() => {
    if (currentVisibleDay) {
      return;
    }
    const firstChosenDate = getFirstDateFromChosen(chosenWeekDaytimes);
    if (firstChosenDate) {
      updateVisibleDay(new Date(firstChosenDate));
      return;
    }
    const today = new Date();
    const todayPrefix = `${today.toLocaleDateString(undefined, {
      year: 'numeric',
    })}-${today.toLocaleDateString(undefined, {
      month: '2-digit',
    })}`;
    const firstSlotDate = slots.map((s) => s.date).sort()[0];
    if (firstSlotDate && !firstSlotDate.startsWith(todayPrefix)) {
      updateVisibleDay(new Date(`${firstSlotDate.replace(/-/g, '/')} 00:00`));
    }
  }, [slots, currentVisibleDay, chosenWeekDaytimes]);

  const kidsWithEligibility = useMemo(
    () => kids.map((kid) => setEligibleKid(kid, slots)),
    [kids, slots]
  );

  const { pickTimesPerWeek, dateSlotsPerWeek } = useFilteredDateSlots(
    slotsGrouped.slotsPerWeek,
    kidsWithEligibility,
    isLoggedIn
  );

  const availableWeekEntries = useMemo(
    () => weekEntries.filter((w) => w.id in pickTimesPerWeek),
    [weekEntries, pickTimesPerWeek]
  );

  const selectedWeeks = formatChosenWeeks(chosenWeekDaytimes, {
    slotsPerWeek: slotsGrouped.slotsPerWeek,
    dateSlotsPerWeek,
  });

  const allDateSlots = weekEntries
    .map((w) => w.id)
    .reduce((acc, weekId) => ({ ...acc, ...dateSlotsPerWeek[weekId] }), {});

  const onWeekSelect = (weekId: string, daytimes: string[]) => {
    if (!dateSlotsPerWeek[weekId]) {
      throw new Error(
        `could not get dateslots with weekId:${weekId}. this should not happen`
      );
    }

    setChosenWeekDaytimes({
      ...chosenWeekDaytimes,
      [weekId]: daytimes,
    });
  };

  const onWeekRemove =
    (onEmptyChosenList: () => void) => (weekIdToRemove: string) => {
      const filteredEntries = Object.entries(chosenWeekDaytimes).filter(
        ([weekId]) => weekId !== weekIdToRemove
      );
      setChosenWeekDaytimes(Object.fromEntries(filteredEntries));
      if (filteredEntries.length === 0) {
        onEmptyChosenList();
      }
    };

  const onBookPress = () => {
    const weekDataConverted = {
      chosenSlots: Object.fromEntries(
        Object.keys(chosenWeekDaytimes)
          .map((weekId) =>
            Object.keys(dateSlotsPerWeek[weekId]!).map((dayDate) => [
              dayDate,
              chosenWeekDaytimes[weekId],
            ])
          )
          .flat()
      ),
      dateSlots: allDateSlots,
    };

    updateCheckoutState({
      locationId,
      locationNameId,
      slots,
      dateSlots: weekDataConverted.dateSlots,
      chosenSlots: {
        [locationNameId]: weekDataConverted.chosenSlots,
      },
      kids: kidsWithEligibility,
      weeklyState: {
        [locationNameId]: chosenWeekDaytimes,
      },
      weeklyInfo: Object.fromEntries(
        Object.entries(dateSlotsPerWeek).filter(([weekId]) =>
          Object.keys(chosenWeekDaytimes).includes(weekId)
        )
      ),
      weekEntries,
      bookingMode: 'weekly',
    });

    if (onSubmit) {
      onSubmit();
    } else {
      navigate('ConfirmBooking');
    }
  };

  const isToggleAvailable = useIsToggleAvailable();

  const [candidateWeek, setCandidateWeek] = useState<string | null>(null);

  const periodSessionProps = candidateWeek
    ? {
        dateString:
          formatWeekDates(dateSlotsPerWeek[candidateWeek]) || 'unknown date',
        chosenSlots: chosenWeekDaytimes[candidateWeek] || [],
        slots: pickTimesPerWeek[candidateWeek],
      }
    : null;

  const onSessionsSelect = (dayTimes: string[]) => {
    if (!candidateWeek) {
      return;
    }
    onWeekSelect(candidateWeek, dayTimes);
    setCandidateWeek(null);
  };

  return (
    <CalendarSessionsProvider
      showingPeriodSessions={!!candidateWeek}
      onClosePeriodSessions={() => setCandidateWeek(null)}
      periodSessionProps={periodSessionProps}
      onSessionsSelect={onSessionsSelect}
      getSharedParam={() => prepareSharedWeeksParam(chosenWeekDaytimes)}
      selectedEntriesComponent={(closeSelected) => (
        <ChosenWeekViewer
          selectedWeeks={selectedWeeks}
          onWeekRemove={onWeekRemove(closeSelected)}
        />
      )}
    >
      {(showSelected) => (
        <>
          <TransparentView testID="weekly-calendar-container-ref" />
          <KidsInput
            kids={kidsWithEligibility}
            onChange={onKidChosenChange}
            onLoggedOutEdit={onLoggedOutEdit}
            style={{ marginBottom: 5 }}
            isFetching={fetching}
            errorStatus={
              isLoggedIn
                ? getCachedStatus({
                    isLoaded: isLoggedIn && !fetching,
                    kids: kidsWithEligibility,
                    dateSlots: allDateSlots,
                  })
                : null
            }
            onPricePress={onPricePress}
          />
          {isToggleAvailable ? (
            <CalendarTypeToggler type="weekly" />
          ) : (
            <TransparentView style={{ height: 10 }} />
          )}
          <WeekCalendar
            today={activeStartDate}
            weeks={availableWeekEntries}
            dateSlots={dateSlotsPerWeek}
            chosenWeekDaytimes={chosenWeekDaytimes}
            onCandidateWeekSelected={setCandidateWeek}
          />
          <TransparentView
            style={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: 'auto',
              justifyContent: 'flex-end',
              marginTop: 5,
            }}
          >
            {hasWeeklyDiscount ? (
              <WeekBookingDiscountInfo onlyWeekly={!isToggleAvailable} />
            ) : null}
            <ViewSelectedWrapper>
              <PrimaryButton
                title="Book"
                onPress={isLoggedIn ? onBookPress : onLoggedOutEdit}
                disabled={
                  (isLoggedIn && kids.length === 0) ||
                  selectedWeeks.length === 0 ||
                  selectedWeeks.some((a) => a.disabled !== false)
                }
                style={{ marginTop: 10, height: 50, flex: 1 }}
                testID="book-button"
              />
              {selectedWeeks.length > 0 ? (
                <ViewSelectedButton
                  showSelected={showSelected}
                  amount={selectedWeeks.length}
                />
              ) : null}
            </ViewSelectedWrapper>
          </TransparentView>
        </>
      )}
    </CalendarSessionsProvider>
  );
};
