import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import LinkingConfiguration from './LinkingConfiguration';
import AuthHeader from '../components/AuthHeader';
import { View } from '../components/Themed';
import FeatureFlags from '../constants/FeatureFlags';
import { navigationRef } from '../hooks/useAppNavigation';
import useColorScheme from '../hooks/useColorScheme';
import AllLocations from '../screens/AllLocations';
import BookAgain from '../screens/BookAgain';
import BumoCreditLanding, {
  BumoCreditPackagesListScreen,
} from '../screens/BumoCreditPackages';
import ComingSoon from '../screens/ComingSoon';
import ConfirmBooking from '../screens/ConfirmBooking';
import EmployerLead from '../screens/EmployerLead';
import Explore from '../screens/Explore';
import JoinNow from '../screens/JoinNowScreen';
import LAFamilySupport from '../screens/LAFamilySupport';
import Location from '../screens/Location';
import LogIn from '../screens/LogInScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PNO from '../screens/PNO';
import ProgramMusic from '../screens/ProgramMusic';
import ReferralLink from '../screens/ReferralLink';
import Search from '../screens/Search';
import StartFreeAccountPage from '../screens/StartFreeAccountPage';
import SummerCampsPage from '../screens/SummerCamps';
import Vote from '../screens/Vote';
import WinterWonderland from '../screens/WinterWonderland';
import { RootStackParamList } from '../types';
import { capitalize } from '../utils/api';
import { createTitle } from '../utils/navigation';

export function lazy<T extends React.ComponentType<any>>(
  importFn: () => Promise<{ default: T }>
) {
  const LazyComponent = React.lazy(importFn);
  return (props: any) => (
    <React.Suspense fallback={<View style={{ flex: 1 }} />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}

const Favorites = lazy(() => import('../screens/Favorites'));
const Bookings = lazy(() => import('../screens/Bookings'));
const BookingDetails = lazy(() => import('../screens/BookingDetails'));
const Profile = lazy(() => import('../screens/Profile'));
const DeleteAccount = lazy(() => import('../components/profile/Delete'));
const PersonalInfo = lazy(() => import('../components/profile/PersonalInfo'));
const ChangePassword = lazy(
  () => import('../components/profile/ChangePassword')
);

const FormsReuseConsent = lazy(
  () => import('../components/profile/FormsReuseConsent')
);
const Child = lazy(() => import('../components/profile/Child'));
const AddChild = lazy(() => import('../screens/AddChild'));
const ChildBasicsForm = lazy(
  () => import('../components/profile/child/ChildBasicsForm')
);
const ChildDetailsForm = lazy(
  () => import('../components/profile/child/ChildDetailsForm')
);
const ChildEmergencyContactsForm = lazy(
  () => import('../components/profile/child/ChildEmergencyContactsForm')
);
const ChildPickupsForm = lazy(
  () => import('../components/profile/child/ChildPickupsForm')
);
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/ResetPassword'));
const ReferAFriend = lazy(() => import('../screens/ReferAFriend'));
const BuyGiftCard = lazy(() => import('../screens/BuyGiftCard'));
const ClaimGiftCard = lazy(() => import('../screens/ClaimGiftCard'));

const SeoPageBurbank = lazy(() => import('../screens/seoPages/Burbank'));
const SeoPagePasadena = lazy(() => import('../screens/seoPages/Pasadena'));
const SeoPageSanFrancisco = lazy(
  () => import('../screens/seoPages/SanFrancisco')
);
const SeoPageLosAngeles = lazy(() => import('../screens/seoPages/LosAngeles'));
const SeoPageGlendale = lazy(() => import('../screens/seoPages/Glendale'));
const SeoPageSantaMonica = lazy(
  () => import('../screens/seoPages/SantaMonica')
);
const SeoPageCenturyCity = lazy(
  () => import('../screens/seoPages/CenturyCity')
);

export function NavigationProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const colorScheme = useColorScheme();
  const routeNameRef = React.useRef<string | undefined>('');
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) => {
          return (
            options?.title ||
            createTitle(['The Most Flexible Child Care When You Need It'])
          );
        },
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
          window.document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
          });
        }
      }}
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      ref={navigationRef}
    >
      <RootNavigator />
      {children}
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator initialRouteName="Explore">
      <Stack.Screen
        name="Explore"
        component={Explore}
        options={{
          headerShown: false,
          title: createTitle(['The Most Flexible Child Care When You Need It']),
        }}
      />
      <Stack.Screen
        name="LogIn"
        component={LogIn}
        options={{
          headerShown: false,
          title: createTitle(['Log In']),
        }}
      />
      <Stack.Screen
        name="Onboarding"
        component={JoinNow}
        options={{
          headerShown: false,
          title: createTitle(['Log In']),
        }}
      />
      <Stack.Screen
        name="Favorites"
        component={Favorites}
        options={{
          headerShown: false,
          title: createTitle(['Favorites']),
        }}
      />
      <Stack.Screen
        name="BookAgain"
        component={BookAgain}
        options={{
          headerShown: false,
          title: createTitle(['Favorites']),
        }}
      />
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{ headerShown: false, title: createTitle(['Profile']) }}
      />
      <Stack.Screen
        name="DeleteAccount"
        component={DeleteAccount}
        options={{
          headerShown: false,
          title: createTitle(['Delete Profile']),
        }}
      />
      <Stack.Screen
        name="PersonalInfo"
        component={PersonalInfo}
        options={{
          headerShown: false,
          title: createTitle(['Personal Info', 'Profile']),
        }}
      />
      <Stack.Screen
        name="Child"
        component={Child}
        options={({ route }) => ({
          headerShown: false,
          title: createTitle([capitalize(route.params.name), 'Profile']),
        })}
      />
      <Stack.Screen
        name="AddChild"
        component={AddChild}
        options={{
          headerShown: false,
          title: createTitle(['Add Child']),
        }}
      />
      <Stack.Screen
        name="ChildBasicsForm"
        component={ChildBasicsForm}
        options={({ route }) => ({
          headerShown: false,
          title: createTitle([
            'Basics',
            capitalize(route.params.name),
            'Profile',
          ]),
        })}
      />
      <Stack.Screen
        name="ChildDetailsForm"
        component={ChildDetailsForm}
        options={({ route }) => ({
          headerShown: false,
          title: createTitle([
            'Details',
            capitalize(route.params.name),
            'Profile',
          ]),
        })}
      />
      <Stack.Screen
        name="ChildPickupsForm"
        component={ChildPickupsForm}
        options={({ route }) => ({
          headerShown: false,
          title: createTitle([
            'Approved Pickups',
            capitalize(route.params.name),
            'Profile',
          ]),
        })}
      />
      <Stack.Screen
        name="ChildEmergencyContactsForm"
        component={ChildEmergencyContactsForm}
        options={({ route }) => ({
          headerShown: false,
          title: createTitle([
            'Emergency Contacts',
            capitalize(route.params.name),
            'Profile',
          ]),
        })}
      />
      <Stack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{
          headerShown: false,
          title: createTitle(['Change Password']),
        }}
      />

      <Stack.Screen
        name="FormsReuseConsent"
        component={FormsReuseConsent}
        options={{ headerShown: false, title: 'Forms Reuse Consent' }}
      />

      <Stack.Screen
        name="ReferAFriend"
        component={ReferAFriend}
        options={{
          headerShown: false,
          title: 'Refer A Friend',
        }}
      />

      <Stack.Screen
        name="Bookings"
        component={Bookings}
        options={{ headerShown: false, title: createTitle(['Bookings']) }}
      />
      <Stack.Screen
        name="ConfirmBooking"
        component={ConfirmBooking}
        options={{
          headerShown: false,
          title: createTitle(['Confirm Booking']),
        }}
      />
      <Stack.Screen
        name="BookingDetails"
        component={BookingDetails}
        options={{
          headerShown: false,
          title: createTitle(['Booking details']),
        }}
      />
      <Stack.Screen
        name="BuyGiftCard"
        component={BuyGiftCard}
        options={{
          headerShown: false,
          title: createTitle(['Purchase Gift Card']),
        }}
      />
      {FeatureFlags.SEARCH && (
        <Stack.Screen
          name="Search"
          component={Search}
          options={{ headerShown: false, title: createTitle(['Search']) }}
        />
      )}
      <Stack.Screen
        name="ClaimGiftCard"
        component={ClaimGiftCard}
        options={{
          title: createTitle(['Claim Gift Card']),
          header: ({ navigation, back }) => {
            return <AuthHeader back={back} onBackPress={navigation.goBack} />;
          },
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          title: createTitle(['Forgot Password']),
          header: ({ navigation, back }) => {
            return <AuthHeader back={back} onBackPress={navigation.goBack} />;
          },
        }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{
          title: createTitle(['Reset Password']),
          header: ({ navigation, route, options, back }) => {
            return <AuthHeader back={back} onBackPress={navigation.goBack} />;
          },
        }}
      />
      <Stack.Screen
        name="ComingSoon"
        component={ComingSoon}
        options={{ headerShown: false, title: createTitle(['Coming Soon']) }}
      />
      <Stack.Screen
        name="Location"
        component={Location}
        options={{ headerShown: false, title: createTitle(['Location']) }}
      />
      <Stack.Screen
        name="AllLocations"
        component={AllLocations}
        options={{ headerShown: false, title: createTitle(['All Locations']) }}
      />
      <Stack.Screen
        name="PNO"
        component={PNO}
        options={{
          headerShown: false,
          title: createTitle(["Parent's Night Out"]),
        }}
      />
      <Stack.Screen
        name="Vote"
        component={Vote}
        options={{
          headerShown: false,
          title: createTitle(['Vote']),
        }}
      />
      {/* SEO */}
      <Stack.Screen
        name="SEOPasadena"
        component={SeoPagePasadena}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Pasadena, CA']),
        }}
      />
      <Stack.Screen
        name="SEOSanFrancisco"
        component={SeoPageSanFrancisco}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in San Francisco, CA']),
        }}
      />
      <Stack.Screen
        name="SEOLosAngeles"
        component={SeoPageLosAngeles}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Los Angeles, CA']),
        }}
      />
      <Stack.Screen
        name="SEOBurbank"
        component={SeoPageBurbank}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Burbank, CA']),
        }}
      />
      <Stack.Screen
        name="SEOGlendale"
        component={SeoPageGlendale}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Glendale, CA']),
        }}
      />
      <Stack.Screen
        name="SEOSantaMonica"
        component={SeoPageSantaMonica}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Santa Monica, CA']),
        }}
      />
      <Stack.Screen
        name="SEOCenturyCity"
        component={SeoPageCenturyCity}
        options={{
          headerShown: false,
          title: createTitle(['Top Child Care in Century City, CA']),
        }}
      />
      <Stack.Screen
        name="ProgramMusic"
        component={ProgramMusic}
        options={{
          headerShown: false,
          title: createTitle(['Music']),
        }}
      />
      <Stack.Screen
        name="ProgramSummerCamps"
        component={SummerCampsPage}
        options={{
          headerShown: false,
          title: createTitle(['Summer Camps']),
        }}
      />
      <Stack.Screen
        name="ProgramWinterWonderland"
        component={WinterWonderland}
        options={{
          headerShown: false,
          title: createTitle(['Winter Wonderland']),
        }}
      />
      <Stack.Screen
        name="LAFamilySupport"
        component={LAFamilySupport}
        options={{
          headerShown: false,
          title: createTitle(['LA Family Support']),
        }}
      />
      <Stack.Screen
        name="StartFreeAccount"
        component={StartFreeAccountPage}
        options={{
          headerShown: false,
          title: createTitle(['Start Free Account']),
        }}
      />
      <Stack.Screen
        name="EmployerLead"
        component={EmployerLead}
        options={{
          headerShown: false,
          title: createTitle(['Help your team with a child care']),
        }}
      />

      <Stack.Screen
        name="BumoCreditLanding"
        component={BumoCreditLanding}
        options={{
          headerShown: false,
          title: createTitle(['Bumo Credit Packages']),
        }}
      />

      <Stack.Screen
        name="BumoCreditPackagesList"
        component={BumoCreditPackagesListScreen}
        options={{
          headerShown: false,
          title: createTitle(['Bumo Credit Packages List']),
        }}
      />

      <Stack.Screen
        name="ReferralLink"
        component={ReferralLink}
        options={{
          headerShown: false,
          title: createTitle(['Get $25 give $25']),
        }}
      />

      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{
          headerShown: false,
          title: '404 Oops! Page not found',
        }}
      />
    </Stack.Navigator>
  );
}
