import React, { useEffect, useState } from 'react';
import {
  DimensionValue,
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { CdnImageName, getImageUrl } from '../../utils/common';
import BumoCarousel from '../BumoCarousel';
import { PrimaryButton } from '../Button';
import Dots from '../Dots';
import { Image } from '../Image';
import { InnerContainer } from '../Layout';
import Logo from '../Logo';
import { TransparentRow } from '../Row';
import { TextH2, TextH3 } from '../StyledText';
import { StyleProps, TransparentView } from '../Themed';

export default function HeroWithImage({
  mainTitle,
  titleComponent,
  imageFileName,
  onButtonPress,
  altText,
  logo,
  skipButton,
  buttonText = 'Find Location in ...',
  containerStyle = {},
  hideAddons = false,
  content,
}: {
  mainTitle: React.ReactNode;
  titleComponent?: React.ComponentType<any>;
  imageFileName?: CdnImageName | CdnImageName[];
  altText: string;
  logo?: React.ReactNode;
  skipButton?: boolean;
  buttonText?: string;
  onButtonPress?: () => void;
  containerStyle?: StyleProps['style'];
  hideAddons?: boolean;
  content?: React.ReactNode;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(768);

  const shouldHideRibbon = useMaxWidth(1024) || hideAddons;

  const TitleComponent = titleComponent || (isMobileDevice ? TextH3 : TextH2);

  const imagesAsArray = !imageFileName
    ? []
    : Array.isArray(imageFileName)
      ? imageFileName
      : [imageFileName];

  const imageComponent =
    imagesAsArray.length === 1 ? (
      <ImageBackground
        source={{
          uri: getImageUrl(imagesAsArray[0]!),
        }}
        alt={altText}
        accessibilityLabel={altText}
        style={
          isMobileDevice
            ? { width: '100%', height: 300 }
            : {
                flex: 1,
                height: 577,
              }
        }
      />
    ) : imagesAsArray.length > 1 ? (
      <HeroImagesCarousel
        altText={altText}
        imageUrlFn={getImageUrl}
        images={imagesAsArray}
      />
    ) : null;

  return (
    <View
      style={[
        {
          overflow: 'hidden',
          backgroundColor: Colors[theme].backgroundSecondary,
        },
        containerStyle,
      ]}
    >
      {shouldHideRibbon ? null : (
        <Image
          source={require('../../assets/images/spring-pink.png')}
          style={{
            position: 'absolute',
            width: 0.5 * 469,
            height: 0.5 * 389,
            left: 'calc(50% - 234px)' as DimensionValue,
          }}
        />
      )}
      <InnerContainer style={styles.introInnerContainer}>
        <TransparentRow
          style={isMobileDevice ? { flexDirection: 'column' } : {}}
        >
          {!hideAddons ? (
            <Image
              source={require('../../assets/images/star-green.png')}
              style={[
                {
                  position: 'absolute',
                  width: 260,
                  height: 260,
                  left: -100,
                  bottom: -150,
                },
                isMobileDevice && {
                  top: 0,
                  left: -30,
                  width: 86,
                  height: 86,
                },
              ]}
            />
          ) : null}
          {isMobileDevice ? imageComponent : null}
          <View
            style={[
              styles.introTextContainer,
              isMobileDevice && styles.introTextContainerMobile,
              !isMobileDevice && styles.introTextContainerDesktop,
            ]}
          >
            {content ? (
              content
            ) : (
              <View
                style={[
                  styles.introTextInner,
                  !isMobileDevice && styles.introTextInnerDesktop,
                ]}
              >
                {logo || (
                  <Logo
                    colorBig
                    style={[
                      { width: 356, height: 133, marginBottom: 25 },
                      isMobileDevice && {
                        width: 200,
                        height: 74,
                        alignSelf: 'center',
                      },
                    ]}
                  />
                )}
                <TitleComponent
                  style={
                    isMobileDevice && {
                      textAlign: 'center',
                    }
                  }
                >
                  {mainTitle}
                </TitleComponent>
                {skipButton ? null : (
                  <TransparentView
                    style={[
                      {
                        width: '100%',
                        maxWidth: 500,
                        marginTop: 16,
                      },
                      isMobileDevice && {
                        alignSelf: 'center',
                      },
                    ]}
                  >
                    <PrimaryButton title={buttonText} onPress={onButtonPress} />
                  </TransparentView>
                )}
              </View>
            )}
          </View>
          {isMobileDevice ? null : imageComponent}
        </TransparentRow>
      </InnerContainer>
    </View>
  );
}

function HeroImagesCarousel({
  images,
  altText,
  imageUrlFn,
}: {
  images: CdnImageName[];
  altText: string;
  imageUrlFn: (i: CdnImageName) => string;
}) {
  const isMobileDevice = useMaxWidth(768);
  return (
    <TransparentView
      style={[
        isMobileDevice
          ? { width: '100%', height: 300 }
          : {
              flex: 1,
              height: 577,
            },
        {
          overflow: 'hidden',
        },
      ]}
    >
      <AutoScrollImageCarousel>
        {images.map((i, idx) => (
          <ImageBackground
            key={`image-slide-${idx}`}
            source={imageUrlFn(i) as ImageSourcePropType}
            alt={altText}
            accessibilityLabel={altText}
            style={
              isMobileDevice
                ? { width: '100%', height: 300 }
                : {
                    flex: 1,
                    height: 577,
                  }
            }
          />
        ))}
      </AutoScrollImageCarousel>
    </TransparentView>
  );
}

function AutoScrollImageCarousel({ children }: { children: React.ReactNode }) {
  const [active, setActive] = useState(0);

  useEffect(() => {
    let active = true;
    let timeoutId: NodeJS.Timeout;

    function goNext() {
      if (!active) {
        return;
      }
      setActive((s) => (s + 1) % React.Children.count(children));

      timeoutId = setTimeout(goNext, 3000);
    }

    timeoutId = setTimeout(goNext, 3000);

    return () => {
      active = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <>
      <BumoCarousel
        renderedElements={1}
        showArrows={false}
        activeIndex={active}
      >
        {children}
      </BumoCarousel>
      <Dots
        activeIndex={active}
        amount={React.Children.count(children)}
        // @ts-ignore
        containerStyle={{
          position: 'absolute',
          bottom: 10,
          left: '50%',
          transform: [{ translateX: '-50%' }],
        }}
      />
    </>
  );
}

export const HeroContent = {
  Text: function HeroContentText({
    children,
    style,
    wrapperStyle,
    wideBelowWidth = 768,
  }: {
    children: React.ReactNode;
    style?: StyleProps['style'];
    wrapperStyle?: StyleProps['style'];
    wideBelowWidth?: number;
  }) {
    const isMobileDevice = useMaxWidth(wideBelowWidth);
    return (
      <TransparentView
        style={[
          {
            width: '100%',
            height: '100%',
            padding: 24,
            alignItems: 'flex-start',
          },
          !isMobileDevice && { maxWidth: '50%' },
          wrapperStyle,
        ]}
      >
        <TransparentView
          style={[
            !isMobileDevice && {
              maxWidth: 580,
              width: '100%',
              alignSelf: 'flex-end',
            },
            style,
          ]}
        >
          {children}
        </TransparentView>
      </TransparentView>
    );
  },
  Image: function HeroContentImage({
    imageFileName,
    altText,
    style,
    hiddenBelowWidth = 768,
    forceImage = false,
  }: {
    imageFileName: CdnImageName;
    altText?: string;
    style?: StyleProps['style'];
    hiddenBelowWidth?: number;
    forceImage?: boolean;
  }) {
    const isMobileDevice = useMaxWidth(hiddenBelowWidth);
    if (isMobileDevice && !forceImage) {
      return null;
    }
    return (
      <ImageBackground
        source={{ uri: getImageUrl(imageFileName) }}
        style={[
          style || {
            flex: 1,
            height: 577,
          },
        ]}
        alt={altText}
        resizeMode="cover"
      />
    );
  },
  ImagesCarousel: HeroImagesCarousel,
  Wrapper: ({ children }: { children: React.ReactNode }) => (
    <InnerContainer style={{ maxWidth: 1440, paddingHorizontal: 0 }}>
      <TransparentRow>{children}</TransparentRow>
    </InnerContainer>
  ),
};

export const styles = StyleSheet.create({
  introInnerContainer: { maxWidth: 1440, paddingHorizontal: 0 },
  introTextContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    padding: 24,
  },
  introTextContainerDesktop: {
    maxWidth: '50%',
  },
  introTextInner: {
    backgroundColor: 'transparent',
  },
  introTextContainerMobile: {
    paddingVertical: 20,
  },
  introTextInnerDesktop: {
    maxWidth: 580,
    width: '100%',
    alignSelf: 'flex-end',
  },
});
