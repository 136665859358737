export function buildURLQuery(query: Record<string, unknown>) {
  return Object.keys(query)
    .map((key) => {
      if (Array.isArray(query[key])) {
        return (query[key] as unknown[])
          .map(
            (value: unknown) =>
              `${key}[]=${Array.isArray(value) ? JSON.stringify(value) : value}`
          )
          .join('&');
      } else {
        return `${key}=${query[key]}`;
      }
    })
    .join('&');
}
