import { StyleSheet } from 'react-native';

import { Icon } from './Icon';
import Row from './Row';
import { TextFinePrint } from './StyledText';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type CallOutProps = StyleProps & {
  title: string;
};

export default function CallOut({ style, title }: CallOutProps) {
  const theme = useColorScheme();
  return (
    <Row style={[styles.container, style]}>
      <Icon
        name="check"
        style={styles.icon}
        backgroundColor={Colors[theme].backgroundSecondary}
      />
      <TextFinePrint>{title}</TextFinePrint>
    </Row>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
});
