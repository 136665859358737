import React, { forwardRef, useImperativeHandle } from 'react';
import { Platform, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useColorScheme from '../../hooks/useColorScheme';
import { PrimaryButton, TextButton } from '../Button';
import { Status } from '../Status';
import { TextBodySmall, TextFinePrint, TextH2 } from '../StyledText';
import { View } from '../Themed';

interface DeleteAccountContentProps {
  preSubmit?: () => void;
  // to remove after useProfile & useParent are merged
  onSubmit: () => void;
  postSubmit?: () => void;
  noButton?: boolean;
}

export type DeleteAccountContentRef = {
  triggerSubmit: () => void;
};

export const DeleteAccountContent = forwardRef<
  DeleteAccountContentRef,
  DeleteAccountContentProps
>(function PersonalInfoForm(
  { preSubmit, onSubmit, postSubmit, noButton = false },
  ref
) {
  const theme = useColorScheme();

  const handleSubmit = async () => {
    preSubmit?.();
    await onSubmit();
    postSubmit?.();
  };

  useImperativeHandle(ref, () => ({
    triggerSubmit: handleSubmit,
  }));

  return (
    <>
      <TextH2 style={styles.title}>Proceed with deactivation</TextH2>
      <View style={styles.statusContainer}>
        <Status
          text="The profile associated with this account will disappear."
          icon="check"
          backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
        />
        <Status
          text="You won't be able to access the account info or past bookings."
          icon="check"
          backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
        />
        <Status
          text="Your account will be deleted within 30 days."
          icon="check"
          backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
        />

        <Status
          text="Please proceed with caution. This action cannot be undone."
          icon="warning"
          // backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
          iconProps={{ color: 'red' }}
        />
      </View>
      {noButton ? null : (
        <PrimaryButton
          style={styles.button}
          title="Deactivate Account"
          onPress={handleSubmit}
        />
      )}
    </>
  );
});

interface DeleteAccountPromptProps {
  isBeingDeleted: boolean;
  onPress: () => void;
}

export function DeleteAccountPrompt({
  isBeingDeleted,
  onPress,
}: DeleteAccountPromptProps) {
  return isBeingDeleted ? (
    <TextBodySmall>Your account is scheduled for deletion!</TextBodySmall>
  ) : (
    <>
      <TextBodySmall>Need to deactivate your account?</TextBodySmall>
      <TextButton
        onPress={onPress}
        TextComponent={TextFinePrint}
        style={styles.link}
      >
        Take care of that
      </TextButton>
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    marginBottom: 40,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 25,
          marginBottom: 75,
        }),
      },
    }),
  },
  statusContainer: {
    alignItems: 'flex-start',
    gap: 18,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginLeft: 20,
        }),
      },
    }),
  },
  text: {
    alignSelf: 'center',
  },
  button: {
    marginTop: 30,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          height: 60,
        }),
        ...(Layout.isMobileDevice && {
          height: 50,
        }),
      },
    }),
  },
  link: {
    marginTop: 8,
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          marginBottom: 20,
        }),
      },
    }),
  },
});
