import { useIsFocused } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
} from 'react-native';

import { CartPriceInfo, getCartPrice, postBookings } from '../api/bookings';
import { BookingMode, Location } from '../api/search';
import BookingCalendarModal from '../components/BookingCalendarModal';
import { PrimaryButton } from '../components/Button';
import CancellationPolicyCheckbox from '../components/CancellationPolicyCheckbox';
import { Icon } from '../components/Icon';
import { Image } from '../components/Image';
import { Kid } from '../components/KidsInput';
import { Container, Header, InnerContainer } from '../components/Layout';
import LocationDetail from '../components/LocationDetail';
import Row, { TransparentRow } from '../components/Row';
import {
  TextBody,
  TextBodySmall,
  TextCta,
  TextCtaButton,
  TextFinePrint,
  TextH2,
  TextH3,
  TextH4Big,
} from '../components/StyledText';
import Tag from '../components/Tag';
import { Divider, TransparentView, View } from '../components/Themed';
import { ToggleProvider } from '../components/booking_calendar/CalendarTypeToggler';
import CareCreditCheckbox from '../components/confirm_booking/CareCreditCheckbox';
import Rating from '../components/reviews/Rating';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { useAuthDispatch, useAuthState } from '../contexts/authentication';
import {
  ChosenSlots,
  DateSlots,
  useCheckoutState,
  useCheckoutStateUpdate,
} from '../contexts/checkout';
import { useLocation } from '../contexts/locations';
import { useSnackbarDispatch } from '../contexts/snackbar';
import useAppNavigation from '../hooks/useAppNavigation';
import useHideAvochato from '../hooks/useAvochato';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { useModalState } from '../hooks/useModalState';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DateRangeIcon,
  ParentIcon,
} from '../native/icons';
import { invalidateBookings } from '../store/bookings';
import { capitalizeFirstLetter } from '../utils/common';
import {
  dateStringToNumeric,
  dateToUSFormat,
  getAge,
  getAgeInMonths,
} from '../utils/date';
import { pushProceedToPaymentEvent } from '../utils/gtm';
import {
  formatDateHoursRange,
  formatPrice,
  formatPricePerPeriod,
  formatRating,
  getStateAbbr,
} from '../utils/locations';
import { WeekEntry, formatWeekDates } from '../utils/weekly';

export default function ConfirmBookingWrapper() {
  const { navigate } = useAppNavigation();
  const { parent } = useAuthState();
  const { locationNameId, chosenSlots = {}, dateSlots } = useCheckoutState();
  const location = useLocation(locationNameId);

  const isFocused = useIsFocused();

  if (!isFocused) {
    return null;
  }
  if (
    !parent ||
    !locationNameId ||
    !chosenSlots[locationNameId] ||
    !location ||
    !dateSlots
  ) {
    navigate('Explore');
    return null;
  }

  return (
    <ConfirmBooking
      careCreditBalance={parent.careCreditBalance}
      location={location}
      locationNameId={locationNameId}
      chosenSlots={chosenSlots[locationNameId]!}
      dateSlots={dateSlots}
    />
  );
}

export function getSlotsInfo(
  chosenSlots: ChosenSlots,
  dateSlots: DateSlots,
  birthdates: string[]
) {
  return Object.keys(chosenSlots).map((dateString) => {
    let dayTimes = [...chosenSlots[dateString]!];
    if (
      dayTimes.length === 2 &&
      dayTimes.includes('morning') &&
      dayTimes.includes('afternoon')
    ) {
      dayTimes = ['fullDay'];
    }
    const idsAmounts = dayTimes.reduce(
      (acc, dayTime) => {
        return {
          ...acc,
          ...birthdates.reduce(
            (acc, birthdate) => {
              const daytimeSlots = dateSlots[dateString];
              if (!daytimeSlots) {
                return acc;
              }
              const slot = daytimeSlots[dayTime];
              if (!slot) {
                return acc;
              }
              const capacity = slot.capacities.find((capacity) => {
                const ageOnSlotDate = getAgeInMonths(
                  birthdate,
                  new Date(dateString)
                );
                return (
                  capacity.ageFrom <= ageOnSlotDate &&
                  ageOnSlotDate < capacity.ageTo
                );
              });
              if (!capacity) return acc;

              const key = capacity.ids.join('-');
              const slotTaken = acc[key] || 0;
              acc[key] = slotTaken + 1;
              return acc;
            },
            {} as Record<string, number>
          ),
        };
      },
      {} as Record<string, number>
    );
    return {
      slots: Object.keys(idsAmounts).map((key) => ({
        ids: key.split('-'),
        amount: idsAmounts[key]!,
      })),
    };
  });
}

function formatDiscountRate(value: string) {
  const asNumber = parseFloat(value);
  if (asNumber < 0) {
    return `-$${-1 * asNumber}`;
  }
  return `$${value}`;
}

export function PriceDetailsSection({
  chosenSlots,
  dateSlots,
  careCreditBalance,
  onPriceDetailsFetched,
  kids,
  bookingMode = 'date_range',
  weekEntries,
  loaderComponent,
}: {
  chosenSlots: ChosenSlots;
  dateSlots: DateSlots;
  careCreditBalance: number | undefined;
  onPriceDetailsFetched?: (priceInfo: CartPriceInfo) => void;
  weekEntries:
    | {
        id: string;
        entries: string[];
        slots: DateSlots;
        name: string | null;
        previewHours: string | undefined;
        previewSlotName: string;
      }[]
    | undefined;
  bookingMode?: BookingMode | null;
  kids: Kid[];
  loaderComponent?: React.ReactNode;
}) {
  const theme = useColorScheme();
  const isLargeDevice = useMinWidth(500);

  const [cartPricingInfo, setCartPricingInfo] = useState<CartPriceInfo | null>(
    null
  );

  const chosenKids = useMemo(
    () => kids.filter((kid) => kid.isChosen && kid.isEligible),
    [kids]
  );

  const kidsPerId = chosenKids.reduce(
    (acc, kid) => ({
      ...acc,
      [kid.id]: kid,
    }),
    {} as Record<string, Kid>
  );

  useEffect(() => {
    if (!bookingMode) return;
    const childIds = chosenKids.map((k) => k.id);

    const birthdates = chosenKids.map((kid) => kid.birthdate);
    const slotIds = getSlotsInfo(chosenSlots, dateSlots, birthdates)
      .map((s) => s.slots.map((ss) => ss.ids))
      .flat(2)
      .map(Number);
    getCartPrice(bookingMode, slotIds, childIds).then((response) => {
      if (!response.ok) {
        console.error(
          'there was a problem while fetching cart price info',
          response.body
        );
        return;
      }
      setCartPricingInfo(response.body);
      onPriceDetailsFetched?.(response.body);
    });
  }, [chosenSlots, dateSlots, chosenKids, bookingMode]);

  if (!cartPricingInfo) {
    return loaderComponent ? (
      <>{loaderComponent}</>
    ) : (
      <TextBodySmall>Loading...</TextBodySmall>
    );
  }

  const { total, days } = cartPricingInfo;
  const totalPrice = Number(total);

  function getTitleFor(daystring: string) {
    if (bookingMode === 'date_range' || !weekEntries) {
      return dateToUSFormat(new Date(`${daystring} 00:00`));
    }
    const weekEntryForDate = weekEntries.find((we) =>
      we.entries.includes(daystring)
    );

    return weekEntryForDate?.name || dateToUSFormat(new Date(daystring));
  }

  return (
    <>
      {Object.entries(days).map(([daystring, dayInfo]) => (
        <CartItemSection
          key={daystring}
          title={getTitleFor(daystring)}
          total={parseFloat(dayInfo.total)}
        >
          {Object.entries(dayInfo.children).map(([childId, childPricing]) => {
            return (
              <View
                key={`${daystring}-${childId}`}
                style={{ paddingBottom: 6 }}
              >
                <Row
                  style={[
                    styles.row,
                    isLargeDevice && { paddingLeft: 30 },
                    {
                      marginBottom: 6,
                    },
                  ]}
                >
                  <TextBodySmall
                    bold
                    style={{ color: Colors[theme].textSecondary }}
                  >
                    {kidsPerId[childId]?.firstName}
                  </TextBodySmall>
                </Row>

                {childPricing.map((cp, idx) => {
                  const categoryPrefix = cp.category
                    ? `${capitalizeFirstLetter(cp.category)}: `
                    : '';
                  const timeSuffix =
                    bookingMode === 'weekly' ? (
                      <>
                        {cp.length / cp.days} hrs &times; {cp.days} days
                      </>
                    ) : (
                      `${cp.length} hrs`
                    );
                  return (
                    <TransparentRow
                      key={`cp-${idx}`}
                      style={[
                        styles.row,
                        isLargeDevice && {
                          paddingLeft: 30,
                        },
                        {
                          justifyContent: 'space-between',
                        },
                      ]}
                    >
                      <TextBodySmall
                        style={{ color: Colors[theme].textSecondary }}
                      >
                        {categoryPrefix}
                        {formatPrice(parseFloat(cp.hourly_rate))} &times;{' '}
                        {timeSuffix}
                      </TextBodySmall>
                      <TextBodySmall
                        style={{ color: Colors[theme].textSecondary }}
                      >
                        {formatPrice(parseFloat(cp.total))}
                      </TextBodySmall>
                    </TransparentRow>
                  );
                })}
              </View>
            );
          })}
          {dayInfo.discounts.map((discount) => (
            <TransparentRow
              style={[
                styles.row,
                isLargeDevice && {
                  paddingLeft: 30,
                },
                {
                  justifyContent: 'space-between',
                },
                // @ts-ignore
                { whiteSpace: 'pre-wrap' },
              ]}
              key={`${daystring}-discount-${discount.type}`}
            >
              {discount.type === 'sibling' ? (
                <TransparentRow style={[styles.priceItem, { gap: 10 }]}>
                  <TextBodySmall style={{ color: Colors[theme].textSecondary }}>
                    Siblings Discount: {formatPrice(parseFloat(discount.rate))}{' '}
                    &times; {discount.hours} hrs
                  </TextBodySmall>
                  <Tag
                    icon={
                      <ParentIcon
                        width={18}
                        height={18}
                        style={{ marginRight: 4 }}
                      />
                    }
                    text={`${formatDiscountRate(
                      discount.rate
                    )}/hr Siblings Discount`}
                    color={Colors[theme].cta}
                    style={{ height: 21 }}
                    ignoreResponsive
                  />
                </TransparentRow>
              ) : discount.type === 'weekly' ? (
                <TransparentRow style={styles.priceItem}>
                  <TextBodySmall style={{ color: Colors[theme].textSecondary }}>
                    Book By Week Discount:
                    {formatPrice(parseFloat(discount.base))} &times;{' '}
                    {discount.rate * 100}%
                  </TextBodySmall>
                  <Tag
                    icon={
                      <DateRangeIcon />
                      // <Icon
                      //   name="dateRange"
                      //   style={{ width: 18, height: 18, marginRight: 4 }}
                      // />
                    }
                    text={`${discount.rate * 100}% Book by Week Discount`}
                    bold
                    color={Colors[theme].backgroundTertiary}
                    style={{ height: 21, marginLeft: 6 }}
                    ignoreResponsive
                  />
                </TransparentRow>
              ) : null}

              <TextBodySmall style={{ color: Colors[theme].textSecondary }}>
                {formatPrice(parseFloat(discount.total))}
              </TextBodySmall>
            </TransparentRow>
          ))}
        </CartItemSection>
      ))}
      <Row style={[styles.row, { marginTop: 12, marginBottom: 6 }]}>
        <TextBody>Subtotal (USD)</TextBody>
        <TransparentRow>
          <TextBody>{formatPrice(parseFloat(cartPricingInfo.total))}</TextBody>
        </TransparentRow>
      </Row>
      {careCreditBalance ? (
        <Row style={styles.row}>
          <TextBody>BumoCredit</TextBody>
          <TextBody>{`-${formatPrice(
            Math.min(careCreditBalance, totalPrice)
          )}`}</TextBody>
        </Row>
      ) : null}
      <Row style={[styles.row, styles.total]}>
        <TextBody bold>Total Due (USD)</TextBody>
        <TextBody bold>
          {formatPrice(
            Math.max(
              0,
              careCreditBalance ? totalPrice - careCreditBalance : totalPrice
            )
          )}
        </TextBody>
      </Row>
      <TextFinePrint style={{ color: Colors[theme].textSecondary }}>
        Includes all service fees
      </TextFinePrint>
      <Row style={[styles.row, { marginTop: 18 }]}>
        <TextBodySmall style={{ fontSize: 12 }}>
          Set up plans as low as{' '}
          <TextBodySmall bold>{formatPrice(totalPrice / 12)}</TextBodySmall> in
          12 payments available through{' '}
          <Image
            style={{ width: 32, height: 13 }}
            source={require('../assets/images/affirm.png')}
          />
          {' or '}
          <Image
            style={{
              width: 29,
              height: 16,
              // @ts-ignore
              verticalAlign: 'middle',
            }}
            source={require('../assets/images/klarna.png')}
          />{' '}
          in the next step. Fees may apply.
        </TextBodySmall>
      </Row>
    </>
  );
}

function CartItemSection({
  title,
  total,
  children,
}: {
  title: string;
  total: number;
  children: React.ReactNode;
}) {
  const theme = useColorScheme();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomColor: Colors[theme].lines,
        marginBottom: 16,
      }}
    >
      <Pressable
        style={[
          styles.row,
          {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
          },
        ]}
        onPress={() => setIsOpen(!isOpen)}
      >
        <TextBody bold>Total for {title}</TextBody>
        {Platform.OS === 'web' ? (
          <Icon
            name={isOpen ? 'arrowUp' : 'arrowDown'}
            style={styles.cartItemArrow}
          />
        ) : isOpen ? (
          <ArrowUpIcon />
        ) : (
          <ArrowDownIcon />
        )}

        <TextBody bold>{formatPrice(Number(total))}</TextBody>
      </Pressable>

      {isOpen ? (
        <TransparentView style={{ paddingBottom: 10 }}>
          {children}
        </TransparentView>
      ) : null}
    </View>
  );
}

function ConfirmBooking({
  careCreditBalance,
  locationNameId,
  location,
  chosenSlots,
  dateSlots,
}: {
  careCreditBalance: number;
  locationNameId: string;
  location: Location;
  chosenSlots: ChosenSlots;
  dateSlots: DateSlots;
}) {
  const theme = useColorScheme();
  const authDispatch = useAuthDispatch();
  const { isModalVisible, showModal, closeModal } = useModalState();
  const [terms, setTerms] = useState(false);
  const [isCareCreditUsed, setIsCareCreditUsed] = useState(
    careCreditBalance > 0
  );
  const state = useCheckoutState();
  const updateCheckoutState = useCheckoutStateUpdate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { navigate } = useAppNavigation();
  const {
    kids,
    locationId,
    slots,
    bookingMode = 'date_range',
    weeklyState,
    weeklyInfo,
    weekEntries,
  } = state;

  if (!locationId) {
    throw new Error(
      'missing locationId in checkout state. this should not happen'
    );
  }
  const dispatchSnackbar = useSnackbarDispatch();
  const { children = [], id } = useParent();

  if (!id) {
    throw new Error('missing parent. this should not happen');
  }
  useHideAvochato();

  const [cartPricingInfo, setCartPricingInfo] = useState<CartPriceInfo | null>(
    null
  );

  const chosenKids = kids.filter((kid) => kid.isChosen && kid.isEligible);

  const isMobileDevice = useMaxWidth(430);
  const isMediumDevice = useMaxWidth(1000);
  const TitleText = isMobileDevice ? TextCtaButton : TextH2;

  const selectedKidsIds = chosenKids.map((kid) => kid.id);
  const areFormsComplete = children
    .filter((child) => selectedKidsIds.includes(child.id))
    .every((child) => {
      const formData = child.locationForms.find(
        (form) => form.name === location?.name
      );
      if (formData) {
        return formData.forms.every((form) => form.status === 'complete');
      }
    });

  const onSubmit = () => {
    const birthdates = chosenKids.map((kid) => kid.birthdate);
    const transformedSlots = getSlotsInfo(chosenSlots, dateSlots, birthdates);

    setIsSubmitting(true);
    pushProceedToPaymentEvent({ id });
    postBookings({
      slots: transformedSlots,
      useCareCredit: isCareCreditUsed,
      children: chosenKids.map((kid) => kid.id),
      bookingMode,
    })
      .then((response) => {
        if ('code' in response && response.code === 'NOT_ENOUGH_CAPACITY') {
          setIsSubmitting(false);
          const slot = slots.find((slot) => slot.id === response.id);
          showModal();
          if (slot)
            dispatchSnackbar({
              type: 'error',
              message: (
                <TextBodySmall style={{ color: Colors[theme].background }}>
                  {`A session you were trying to book (${dateStringToNumeric(
                    slot.date
                  )}, ${formatDateHoursRange(
                    slot.dropoff,
                    slot.pickup
                  )}) is no longer available. Please select another session`}
                </TextBodySmall>
              ),
            });
        } else if ('url' in response) {
          window.location.replace(response.url);
        } else if ('checkoutId' in response) {
          updateCheckoutState({
            chosenSlots: {},
            dailyState: undefined,
            weeklyState: undefined,
            bookingMode: undefined,
            locationId: undefined,
            locationNameId: undefined,
            slots: undefined,
          });
          setIsSubmitting(false);
          authDispatch({ type: 'balance', value: response.careCreditBalance });
          authDispatch({ type: 'booked_location', value: locationId });
          invalidateBookings();
          navigate('Explore', { code: '10', sessionId: response.checkoutId });
        } else {
          setIsSubmitting(false);
        }
      })
      .catch(() => setIsSubmitting(false));
  };

  const requiresPayment =
    !isCareCreditUsed ||
    (cartPricingInfo && careCreditBalance < Number(cartPricingInfo.total));
  return (
    <Container>
      {Platform.OS === 'web' ? (
        <Header shadow hideSearchIcon />
      ) : (
        <StatusBar style={Platform.OS === 'ios' ? 'dark' : 'auto'} />
      )}
      <ToggleProvider bookingMode={location.bookingMode} force={bookingMode}>
        <BookingCalendarModal
          isVisible={isModalVisible}
          locationId={locationId}
          locationNameId={locationNameId}
          close={closeModal}
          hasWeeklyDiscount={location.availableDiscounts.includes('weekly')}
        />
      </ToggleProvider>
      <InnerContainer>
        <View style={styles.inner}>
          <TitleText>Confirm Booking</TitleText>
          <Row style={[styles.body, isMediumDevice && styles.bodyMedium]}>
            <View
              style={[
                !isMediumDevice && styles.detailsLarge,
                !isMediumDevice && {
                  shadowRadius: 12,
                  shadowOpacity: 0.8,
                  shadowColor: Colors[theme].shadow,
                },
              ]}
            >
              {isMobileDevice && (
                <TextH4Big style={styles.detailsTitle}>
                  {location.name}
                </TextH4Big>
              )}
              <View style={isMobileDevice && styles.location}>
                <Image
                  style={[styles.image, isMediumDevice && styles.imageMobile]}
                  source={location.images[0] as ImageSourcePropType}
                />
                {!isMobileDevice && (
                  <TextH4Big style={styles.detailsTitle}>
                    {location.name}
                  </TextH4Big>
                )}
                <LocationDetails location={location} />
              </View>
            </View>
            {isMediumDevice && (
              <Divider style={[styles.divider, { width: '100%' }]} />
            )}
            <View style={styles.grow}>
              <BookingSummary
                onEditPress={showModal}
                chosenKids={chosenKids}
                previewComponent={
                  bookingMode === 'weekly' ? (
                    <WeeklyDatesPreview
                      weeks={weeklyState?.[locationNameId]}
                      weekEntries={weekEntries}
                      dateSlotsPerWeek={weeklyInfo}
                    />
                  ) : (
                    <DailyDatesPreview
                      dateSlots={dateSlots}
                      chosenSlots={chosenSlots}
                    />
                  )
                }
              />
              <Divider style={styles.divider} />

              <TextH3 style={styles.price}>Price Details</TextH3>

              <PriceDetailsSection
                chosenSlots={chosenSlots}
                dateSlots={dateSlots}
                careCreditBalance={
                  isCareCreditUsed ? careCreditBalance : undefined
                }
                weekEntries={weekEntries}
                kids={kids}
                bookingMode={bookingMode}
                onPriceDetailsFetched={setCartPricingInfo}
              />

              {careCreditBalance > 0 && cartPricingInfo && (
                <>
                  <Divider style={styles.divider} />
                  <CareCreditCheckbox
                    value={isCareCreditUsed}
                    onChange={setIsCareCreditUsed}
                    total={Number(cartPricingInfo.total)}
                    careCreditBalance={careCreditBalance}
                  />
                </>
              )}
              <Divider style={styles.bottomDivider} />
              <CancellationPolicyCheckbox
                value={terms}
                onChange={setTerms}
                weekly={bookingMode === 'weekly'}
                cancelationTreshold={location.cancellationThreshold}
              />
              <Divider style={styles.topDivider} />
              {areFormsComplete ? null : (
                <>
                  <TextBody>
                    You must complete required forms before your session begins,
                    if you haven't previously booked at this location. We will
                    email you links to these forms when the booking is
                    confirmed.
                    {/* Please remember to complete the required forms before your
                    session begins. If you haven't been to this location, we
                    will email you links to the forms after the booking is
                    confirmed. */}
                  </TextBody>
                  <Divider style={styles.divider} />
                </>
              )}
              {requiresPayment && (
                <TextBodySmall
                  style={[
                    styles.promoCodes,
                    { color: Colors[theme].accentTertiary },
                  ]}
                >
                  Promotional and discount codes can be applied in the next step
                </TextBodySmall>
              )}
              <PrimaryButton
                style={styles.button}
                onPress={onSubmit}
                title={
                  isSubmitting
                    ? 'Booking...'
                    : !requiresPayment
                      ? 'Complete Booking'
                      : 'Proceed to Payment'
                }
                disabled={!terms || isSubmitting}
              />
            </View>
          </Row>
        </View>
      </InnerContainer>
    </Container>
  );
}

export function BookingSummary({
  onEditPress,
  previewComponent,
  chosenKids,
}: {
  onEditPress: () => void;
  previewComponent: React.ReactNode;
  chosenKids: Kid[];
}) {
  const theme = useColorScheme();
  return (
    <>
      <TextH3>Summary</TextH3>
      <Row style={[styles.subsection, styles.row]}>
        <View style={[styles.summary, { flex: 1 }]}>
          <TextBody style={styles.subsectionTitle}>Dates</TextBody>
          {previewComponent}
        </View>
        <TextCta onPress={onEditPress} style={styles.edit}>
          Edit
        </TextCta>
      </Row>
      <Row style={[styles.subsection, styles.row]}>
        <View style={styles.summary}>
          <TextBody style={styles.subsectionTitle}>Kid(s)</TextBody>
          {chosenKids.map(({ firstName, birthdate }) => (
            <TextBodySmall
              key={firstName}
              style={{ color: Colors[theme].textSecondary }}
            >
              {`${firstName}, ${getAge(birthdate)}`}
            </TextBodySmall>
          ))}
        </View>
        <TextCta onPress={onEditPress} style={styles.edit}>
          Edit
        </TextCta>
      </Row>
    </>
  );
}

function getBlackoutDays(
  weekEntry: WeekEntry,
  dateSlotsPerWeek: Record<string, DateSlots>
) {
  const blackoutDays =
    weekEntry.entries
      .map((e, idx) => ({ entry: e, idx }))
      .filter(
        (d) =>
          !Object.keys(dateSlotsPerWeek[weekEntry.id] || {}).includes(d.entry)
      ) || [];

  return blackoutDays;
}

export function WeeklyDatesPreview({
  weeks,
  weekEntries,
  dateSlotsPerWeek = {},
}: {
  weeks: Record<string, string[]> | undefined;
  weekEntries: WeekEntry[] | undefined;
  dateSlotsPerWeek: Record<string, DateSlots> | undefined;
}) {
  const theme = useColorScheme();
  if (!weeks || !weekEntries) {
    return null;
  }
  const weekEntriesById = weekEntries.reduce(
    (acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur,
      };
    },
    {} as Record<string, WeekEntry>
  );
  return (
    <>
      {Object.keys(weeks)
        .sort()
        .map((weekId) => {
          const slots = dateSlotsPerWeek[weekId] || null;
          if (!slots) {
            return null;
          }

          const formattedDays = formatWeekDates(slots);

          const firstDay = Object.values(slots)[0] || {};
          const hours = Object.entries(firstDay)
            .filter(([daytime]) => (weeks[weekId] || []).includes(daytime))
            .map(([, bookingSlot]) => [bookingSlot.dropoff, bookingSlot.pickup])
            .flat()
            .sort();

          const firstHour = hours[0];
          const lastHour = hours[hours.length - 1];

          const formattedHours =
            !firstHour || !lastHour
              ? ''
              : formatDateHoursRange(firstHour, lastHour);

          const weekEntry = weekEntriesById[weekId];
          const blackoutDays = weekEntry
            ? getBlackoutDays(weekEntry, dateSlotsPerWeek)
            : [];

          const filteredBlackoutDays =
            blackoutDays.length === 1
              ? blackoutDays.filter((d) => d.idx !== 0 && d.idx !== 4)
              : blackoutDays.length === 4
                ? [] // we have only one day selected so no need with displaying blackout days
                : blackoutDays;

          const blackoutDaysFormatted = formatBlackoutDays(
            filteredBlackoutDays.map((d) => d.entry)
          );
          return (
            <TransparentRow
              key={weekId}
              style={{
                marginBottom: 5,
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                columnGap: 16,
              }}
            >
              <TextBodySmall style={{ color: Colors[theme].textSecondary }}>
                {formattedDays}, {formattedHours}
              </TextBodySmall>
              {filteredBlackoutDays.length > 0 ? (
                <Row
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <Icon
                    name="dateBlackout"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                    }}
                    color={Colors[theme].error}
                  />
                  <TextBodySmall>
                    Blackout Date(s): {blackoutDaysFormatted}
                  </TextBodySmall>
                </Row>
              ) : null}
            </TransparentRow>
          );
        })}
    </>
  );
}

export function LocationDetails({ location }: { location: Location }) {
  const minimumPrice = location.price;
  const price = location.pricing.group;
  if (!price) {
    return null;
  }
  return (
    <View style={styles.locationDetails}>
      {location?.reviews?.rating ? (
        <TransparentRow>
          <Rating rate={location.reviews.rating} />
          <TextBodySmall>
            {formatRating(location?.reviews?.rating)}
          </TextBodySmall>
        </TransparentRow>
      ) : null}
      {minimumPrice ? (
        <LocationDetail
          style={styles.locationDetail}
          icon="priceTag"
          text={formatPricePerPeriod(minimumPrice, 'hour', '+')}
        />
      ) : (
        <LocationDetail
          style={styles.locationDetail}
          icon="priceTag"
          text={formatPricePerPeriod(price, 'hour')}
        />
      )}
      <LocationDetail
        style={styles.locationDetail}
        icon="location"
        text={`${location.address?.city}, ${getStateAbbr(location.address?.state)}`}
      />
    </View>
  );
}

function formatBlackoutDays(dateStrings: string[]) {
  const datesGrouped = dateStrings
    .map((ds) => {
      const [year, month, day] = ds.split('-');
      if (!year || !month || !day) {
        return null;
      }
      return [`${year}-${month}`, day] as const;
    })
    .reduce(
      (acc, cur) => {
        if (cur === null) {
          return acc;
        }
        const [month, day] = cur;
        acc[month] ||= [];
        acc[month]?.push(day);
        return acc;
      },
      {} as Record<string, string[]>
    );
  return Object.entries(datesGrouped)
    .map(([month, days]) => {
      return `${dateStringToNumeric(month, { month: 'short' })} ${days
        .map((d) => parseInt(d, 10))
        .join(', ')}`;
    })
    .join(', ');
}

export function DailyDatesPreview({
  chosenSlots,
  dateSlots,
}: {
  chosenSlots: ChosenSlots;
  dateSlots: DateSlots;
}) {
  const theme = useColorScheme();

  const data = getDailyDatesPreview(chosenSlots, dateSlots);

  return (
    <>
      {data.map((entry) => {
        if (!entry) {
          return null;
        }
        const { dateString, dayTime, label } = entry;
        return (
          <TextBodySmall
            key={dateString + dayTime}
            style={{ color: Colors[theme].textSecondary }}
          >
            {label}
          </TextBodySmall>
        );
      })}
    </>
  );
}

export function getDailyDatesPreview(
  chosenSlots: ChosenSlots,
  dateSlots: DateSlots
) {
  const slotsObjects = Object.keys(chosenSlots || {})
    .sort()
    .map((dateString) => {
      const daytimes = chosenSlots[dateString] || [];
      return daytimes.map((dayTime) => {
        const dateSlot = dateSlots[dateString];
        if (!dateSlot) return null;

        const slot = dateSlot[dayTime];
        if (!slot) return null;

        const { dropoff, pickup } = slot;

        return {
          dateString,
          dayTime,
          dropoff,
          pickup,
        };
      });
    })
    .flat();

  const slotsReduced = slotsObjects.reduce(
    (acc, cur) => {
      if (!cur) {
        return acc;
      }
      const { dateString, dropoff, pickup, dayTime } = cur;

      const matchingDropoff = acc[dropoff];

      if (!matchingDropoff) {
        acc[pickup] = { dropoff, pickup, dayTime: [dayTime], dateString };
      } else {
        acc[pickup] = {
          ...matchingDropoff,
          pickup,
          dayTime: [...matchingDropoff.dayTime, dayTime],
        };
        delete acc[dropoff];
      }

      return acc;
    },
    {} as Record<
      string,
      {
        dropoff: string;
        pickup: string;
        dateString: string;
        dayTime: string[];
      }
    >
  );

  const sessionsFormatted = Object.values(slotsReduced)
    .sort((a, b) => a.dateString.localeCompare(b.dateString))
    .map((s) => ({
      ...s,
      label: `${dateStringToNumeric(s.dateString)}, ${formatDateHoursRange(
        s.dropoff,
        s.pickup
      )}`,
    }));

  return sessionsFormatted;
}

const styles = StyleSheet.create({
  inner: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 50,
          marginBottom: 120,
        }),
        ...(Layout.isMobileDevice && {
          marginVertical: 30,
        }),
      },
    }),
  },
  location: {
    marginTop: 15,
    gap: 10,
    flexDirection: 'row',
  },
  body: {
    alignItems: 'flex-start',
    marginTop: 50,
    justifyContent: 'space-between',
  },
  bodyMedium: { flexDirection: 'column' },
  image: {
    borderRadius: 10,
    width: 385,
    height: 200,
  },
  imageMobile: {
    width: 150,
    height: 100,
  },
  detailsLarge: {
    width: 445,
    paddingHorizontal: 30,
    paddingVertical: 40,
    borderRadius: 10,
    marginRight: 55,
  },
  detailsTitle: {
    marginTop: 10,
  },
  locationDetails: {
    marginTop: 10,
    alignItems: 'flex-start',
  },
  locationDetail: {
    marginTop: 5,
  },
  grow: {
    flex: 1,
  },
  subsection: {
    marginTop: 5,
    alignItems: 'flex-start',
  },
  summary: {
    alignItems: 'flex-start',
  },
  subsectionTitle: {
    marginBottom: 5,
  },
  total: {
    marginTop: 40,
    marginBottom: 0,
  },
  edit: {
    textDecorationLine: 'underline',
  },
  divider: {
    marginVertical: 30,
  },
  bottomDivider: {
    marginTop: 30,
  },
  topDivider: {
    marginBottom: 30,
  },
  row: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  price: {
    marginBottom: 15,
  },
  button: {
    height: 60,
  },
  codesInfo: {
    marginTop: 5,
  },
  promoCodes: {
    alignSelf: 'center',
    marginBottom: 15,
  },
  priceItem: {
    flexBasis: '0%',
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: 5,
  },
  cartItemArrow: {
    width: 24,
    height: 24,
    position: 'absolute',
    left: '50%',
    top: 0,
  },
});
