import { useEffect, useRef, useState } from 'react';

import { FormInput } from './StyledTextInput';
import { checkZip } from '../api/search';
import { titleize } from '../utils/api';
import { validateFieldV2 } from '../utils/validations';

export default function ZipCodeInput({
  value,
  error,
  onValueChange: onValueChangeProps,
  onLoadingChange,
  testID,
}: {
  value: string;
  error: string;
  onValueChange: (value: string) => void;
  onLoadingChange?: (value: boolean) => void;
  testID?: string;
}) {
  const [v, setV] = useState(value);
  const [loading, setLoading] = useState(false);
  useEffect(() => onLoadingChange?.(loading), [loading]);
  const [message, setMessage] = useState('');

  const fetchingRef = useRef(false);

  useEffect(() => {
    if (v !== value) {
      onValueChangeProps(v);
    }

    const run = async () => {
      if (fetchingRef.current) {
        return;
      }
      fetchingRef.current = true;
      setLoading(true);
      const result = await checkZip(v);
      setLoading(false);
      fetchingRef.current = false;
      if (result.code !== 'CODE_UNKNOWN') {
        setMessage(`${titleize(result.city)}, ${result.state}`);
      }
    };

    if (v.length === 5 && validateFieldV2('zip', v) === null) {
      run();
    }
  }, [v]);

  return (
    <FormInput
      testID={testID}
      value={v}
      onChangeText={setV}
      maxLength={5}
      error={error}
      message={message}
      placeholder="Enter Zip Code"
      textContentType="postalCode"
      keyboardType="decimal-pad"
      autoCapitalize="none"
    />
  );
}
