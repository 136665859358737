import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { useCalendarElementsHeights } from './HeightProvider';
import { BookingMode } from '../../api/search';
import Row from '../Row';
import { TextBodySmall } from '../StyledText';

const ToggleContext = React.createContext<null | {
  isToggleAvailable: boolean;
  toggle: (mode?: BookingMode) => void;
  currentBookingMode: BookingMode | null;
}>(null);

const CurrentVisibleDateContext = React.createContext<
  { current: Date | undefined; update: (n: Date) => void } | undefined
>(undefined);

export const useCurrentVisibleDay = () => {
  const context = useContext(CurrentVisibleDateContext);
  if (!context) {
    return undefined;
  }
  return context.current;
};
export const useUpdateCurrentVisibleDay = () => {
  const context = useContext(CurrentVisibleDateContext);
  if (!context) {
    return () => {};
  }
  return context.update;
};

export const ToggleProvider = ({
  bookingMode,
  children,
  force,
}: {
  bookingMode?: BookingMode[];
  children: React.ReactNode;
  force?: BookingMode;
}) => {
  const [currentVisibleDate, setCurrentVisibleDate] = useState<
    undefined | Date
  >(undefined);

  const [currentBookingMode, setCurrentBookingMode] =
    useState<null | BookingMode>(force || null);

  useEffect(() => {
    if (!bookingMode) {
      return;
    }
    if (currentBookingMode === null) {
      setCurrentBookingMode(
        bookingMode.includes('date_range') ? 'date_range' : 'weekly'
      );
    }
  }, [bookingMode]);

  const toggle = useCallback(
    (mode?: BookingMode) => {
      if (currentBookingMode === null) {
        return;
      }
      setCurrentBookingMode(
        mode || (currentBookingMode === 'date_range' ? 'weekly' : 'date_range')
      );
    },
    [currentBookingMode]
  );

  return (
    <ToggleContext.Provider
      value={{
        isToggleAvailable: (bookingMode || []).length > 1,
        toggle,
        currentBookingMode,
      }}
    >
      <CurrentVisibleDateContext.Provider
        value={{
          current: currentVisibleDate,
          update: setCurrentVisibleDate,
        }}
      >
        {children}
      </CurrentVisibleDateContext.Provider>
    </ToggleContext.Provider>
  );
};

export function useCurrentBookingMode() {
  const ctx = useContext(ToggleContext);
  if (!ctx) {
    throw new Error('CurrentBookingMode used outside of ToggleProvider');
  }
  return ctx.currentBookingMode;
}

export function CurrentBookingMode({
  children,
}: {
  children: (mode: BookingMode) => React.ReactNode;
}) {
  const ctx = useContext(ToggleContext);
  if (!ctx) {
    throw new Error('CurrentBookingMode used outside of ToggleProvider');
  }

  const { currentBookingMode } = ctx;

  if (currentBookingMode === null) {
    return null;
  }
  return (
    <>{currentBookingMode === null ? null : children(currentBookingMode)}</>
  );
}

export const useIsToggleAvailable = () => {
  const ctx = useContext(ToggleContext);
  if (!ctx) {
    throw new Error('useIsToggleAvailable used outside of ToggleProvider');
  }
  return ctx.isToggleAvailable;
};

export const useToggle = () => {
  const ctx = useContext(ToggleContext);
  if (!ctx) {
    throw new Error('useToggle used outside of ToggleProvider');
  }
  return ctx.toggle;
};

function CalendarTypeToggler({ type }: { type: 'weekly' | 'date_range' }) {
  const toggle = useToggle();

  const { togglerHeight } = useCalendarElementsHeights();
  return (
    <Row
      style={[
        {
          backgroundColor: '#D9D9D9',
        },
        styles.container,
        { height: togglerHeight },
      ]}
    >
      <Pressable
        style={
          type === 'date_range'
            ? [styles.activeItem, { height: togglerHeight - 8 }]
            : styles.notActiveItem
        }
        onPress={() => (type === 'date_range' ? null : toggle())}
      >
        <TextBodySmall bold style={{ textAlign: 'center' }}>
          Book by Date
        </TextBodySmall>
      </Pressable>
      <Pressable
        style={
          type === 'weekly'
            ? [styles.activeItem, { height: togglerHeight - 8 }]
            : styles.notActiveItem
        }
        onPress={() => (type === 'weekly' ? null : toggle())}
      >
        <TextBodySmall
          bold
          style={type === 'date_range' && styles.notActiveText}
        >
          Book by Week*
        </TextBodySmall>
      </Pressable>
    </Row>
  );
}
export default CalendarTypeToggler;

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    height: 41,
    borderRadius: 20,
    marginBottom: 12,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 4,
    overflow: 'hidden',
  },
  notActiveItem: { alignItems: 'center', paddingHorizontal: 12, flex: 1 },
  activeItem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#D0E5CC',
    paddingHorizontal: 12,
    height: 33,
    borderRadius: 16,
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowRadius: 10,
  },
  notActiveText: { color: '#898279' },
});
