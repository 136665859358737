import React, { useContext, useEffect, useReducer } from 'react';

import { LocationFilter } from './search';
import { useParent } from '../hooks/useChild';
import { useUpdateKids } from '../hooks/useUpdateKids';
import { getKidFromChild } from '../utils/children';
import { getAge, getAgeInMonths } from '../utils/date';

export interface ButtonState extends InputState {
  dirty: boolean;
  isLoggedIn: boolean;
}

export interface InputState {
  location: LocationFilter;
  rangeStart: string | null;
  rangeEnd: string | null;
  who: {
    id: number;
    name: string;
    age: string | null;
    enabled: boolean;
    ageInMonths?: number;
  }[];
}
export type PopupState = {
  open: boolean;
};

type State = {
  popup: PopupState;
  button: ButtonState;
  input: InputState;
};

const PopupContext = React.createContext<PopupState | null>(null);
const ButtonContext = React.createContext<ButtonState | null>(null);
const ButtonUpdateContext = React.createContext<{
  setPopupState: React.Dispatch<Partial<PopupState>>;
  setButtonState: React.Dispatch<Partial<ButtonState>>;
  setInputState: React.Dispatch<
    Partial<InputState> | ((s: InputState) => Partial<InputState>)
  >;
  triggerSearchParamsUpdate: () => void;
  clearAllInputs: () => void;
} | null>(null);
const InputContext = React.createContext<InputState | null>(null);

const ThreeParamSearchProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [stateValue, dispatch] = useReducer(
    (
      state: State,
      action:
        | {
            type: 'UPDATE_INPUT';
            value: Partial<InputState>;
          }
        | {
            type: 'UPDATE_POPUP';
            value: Partial<PopupState>;
          }
        | {
            type: 'UPDATE_BUTTON';
            value: Partial<ButtonState>;
          }
        | {
            type: 'SAVE_INPUT';
          }
        | {
            type: 'CLEAR_INPUTS';
          }
    ) => {
      if (action.type === 'UPDATE_POPUP') {
        return {
          ...state,
          popup: {
            ...state.popup,
            ...action.value,
          },
        };
      }

      if (action.type === 'UPDATE_BUTTON') {
        return {
          ...state,
          button: {
            ...state.button,
            ...action.value,
          },
        };
      }

      if (action.type === 'UPDATE_INPUT') {
        return {
          ...state,
          input: {
            ...state.input,
            ...action.value,
          },
        };
      }

      if (action.type === 'SAVE_INPUT') {
        return {
          ...state,
          button: {
            dirty: true,
            popupOpen: false,
            isLoggedIn: state.button.isLoggedIn,
            ...state.input,
          },
        };
      }

      if (action.type === 'CLEAR_INPUTS') {
        return {
          ...state,
          input: {
            ...state.input,
            location: {},
            rangeStart: null,
            rangeEnd: null,
            ...(state.button.isLoggedIn
              ? {}
              : {
                  who: [
                    {
                      id: 1,
                      name: 'Child 1',
                      age: null,
                      enabled: false,
                    },
                  ],
                }),
          },
        };
      }
      return state;
    },
    {
      popup: {
        open: false,
      },
      button: {
        location: {},
        rangeStart: null,
        rangeEnd: null,

        who: [],
        dirty: false,
        isLoggedIn: false,
      },
      input: {
        location: {},

        rangeStart: null,
        rangeEnd: null,

        who: [
          {
            id: 1,
            name: 'Child 1',
            age: null,
            enabled: false,
          },
        ],
      },
    }
  );
  const { children: parentChildren } = useParent();
  useEffect(() => {
    const kids = parentChildren || [];
    const updateWho =
      kids.length > 0
        ? kids.map((k) => ({
            id: k.id,
            name: k.firstName,
            age: getAge(k.birthdate),
            enabled: true,
            ageInMonths: getAgeInMonths(k.birthdate),
          }))
        : [
            {
              id: 1,
              name: 'Child 1',
              age: null,
              enabled: false,
            },
          ];
    dispatch({
      type: 'UPDATE_INPUT',
      value: {
        who: updateWho,
      },
    });
    dispatch({
      type: 'UPDATE_BUTTON',
      value: {
        who: updateWho,
        isLoggedIn: kids.length > 0,
      },
    });
  }, [parentChildren]);

  const updateKidsSelection = useUpdateKids();
  useEffect(() => {
    if (!stateValue.button.isLoggedIn) {
      return;
    }

    const kidsIds = stateValue.button.who
      .filter((k) => k.enabled)
      .map((k) => k.id);
    updateKidsSelection(
      (parentChildren || []).map((child) => ({
        ...getKidFromChild(child),
        isChosen: kidsIds.includes(child.id),
      })),
      kidsIds
    );
  }, [stateValue.button.who]);

  const actions = {
    setPopupState: (value: Partial<PopupState>) =>
      dispatch({ type: 'UPDATE_POPUP', value }),
    setButtonState: (value: Partial<ButtonState>) =>
      dispatch({ type: 'UPDATE_BUTTON', value }),
    setInputState: (
      value: Partial<InputState> | ((s: InputState) => Partial<InputState>)
    ) =>
      dispatch({
        type: 'UPDATE_INPUT',
        value: typeof value === 'function' ? value(stateValue.input) : value,
      }),
    triggerSearchParamsUpdate: () => dispatch({ type: 'SAVE_INPUT' }),
    clearAllInputs: () => dispatch({ type: 'CLEAR_INPUTS' }),
  };

  return (
    <PopupContext.Provider value={stateValue.popup}>
      <ButtonContext.Provider value={stateValue.button}>
        <InputContext.Provider value={stateValue.input}>
          <ButtonUpdateContext.Provider value={actions}>
            {children}
          </ButtonUpdateContext.Provider>
        </InputContext.Provider>
      </ButtonContext.Provider>
    </PopupContext.Provider>
  );
};

export const useSearchPopupState = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error(
      'useSearchPopupState used outside of ThreeParamSearchProvider'
    );
  }
  return context;
};

export const useSearchButtonState = () => {
  const context = useContext(ButtonContext);
  if (!context) {
    throw new Error(
      'useSearchButtonState used outside of ThreeParamSearchProvider'
    );
  }
  return context;
};

export const useIsButtonEmpty = () => {
  const { location, rangeStart, who } = useSearchButtonState();
  return (
    Object.keys(location).length === 0 &&
    rangeStart === null &&
    who.filter((e) => e.enabled).length === 0
  );
};

export const useSearchInputState = () => {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error(
      'useSearchInputState used outside of ThreeParamSearchProvider'
    );
  }
  return context;
};

export const useUpdateSearchState = () => {
  const context = useContext(ButtonUpdateContext);
  if (!context) {
    throw new Error(
      'useUpdateSearchButtonState used outside of ThreeParamSearchProvider'
    );
  }
  return context;
};

export default ThreeParamSearchProvider;
