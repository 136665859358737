import React from 'react';
import { TouchableWithoutFeedback, Keyboard, Platform } from 'react-native';

export function dismissKeyboard() {
  if (Platform.OS !== 'web') {
    Keyboard.dismiss();
  }
}

export default function DismissKeyboard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard} accessible={false}>
      {children}
    </TouchableWithoutFeedback>
  );
}

// NOTE: the accessible = {false} is required to make the input form continue to be accessible through VoiceOver. Visually impaired people will thank you!
// https://stackoverflow.com/a/34779467
