import React from 'react';
import { StyleSheet } from 'react-native';

import StartRequiredForms from './StartRequiredForms';
import { VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import Links from '../../constants/Links';
import useColorScheme from '../../hooks/useColorScheme';
import { handleLink } from '../../utils/common';
import { LocationCtaButton } from '../Button';
import InfoPopup from '../InfoPopup';
import Row from '../Row';
import { StyleProps } from '../Themed';

type ButtonsProps = StyleProps & {
  scheduleCallUrl?: string;
  bookTourUrl?: string;
  verificationStatus: VerificationStatus;
  requiresLicensingForms: boolean;
};

export default function Buttons({
  scheduleCallUrl,
  bookTourUrl,
  verificationStatus,
  requiresLicensingForms,
}: ButtonsProps) {
  const theme = useColorScheme();
  return (
    <>
      <Row style={styles.buttons}>
        {!!scheduleCallUrl && (
          <LocationCtaButton
            style={styles.button}
            left="Schedule a Call"
            onPress={() => handleLink(scheduleCallUrl)}
          />
        )}
        {!!bookTourUrl && (
          <LocationCtaButton
            style={styles.button}
            left="Book a Tour"
            onPress={() => handleLink(bookTourUrl)}
          />
        )}
        {Layout.isLargeDevice &&
          verificationStatus === VerificationStatus.VERIFIED &&
          requiresLicensingForms && <StartRequiredForms />}
      </Row>
      {Layout.isMobileDevice &&
        verificationStatus === VerificationStatus.VERIFIED &&
        requiresLicensingForms && (
          <Row style={[styles.buttons, styles.licensingButtons]}>
            <StartRequiredForms />
          </Row>
        )}
      {verificationStatus === VerificationStatus.UNVERIFIED && (
        <Row style={[styles.buttons, styles.licensingButtons]}>
          <LocationCtaButton
            style={[
              styles.button,
              styles.licensingButton,
              { backgroundColor: Colors[theme].accent },
            ]}
            left="Claim This Location"
            onPress={() => handleLink(Links.forms.claim)}
          />
          <InfoPopup text="Is this your daycare? Claim this location to get access to more features and drive enrollment and bookings. Thousands of families visit this website and it's free to claim!" />
        </Row>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  buttons: {
    marginTop: 10,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: 10,
    marginBottom: 10,
  },
  licensingButtons: {
    marginTop: 0,
  },
  licensingButton: {
    marginRight: 5,
  },
});
