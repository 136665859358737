import { useEffect, useState } from 'react';

import { Location, comingSoon } from '../../api/search';
import { useSearchStateUpdate } from '../../contexts/search';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useMaxWidth } from '../../hooks/useResponsive';
import { LocationSection } from '../LocationSection';
import {
  UnverifiedLocationBlockMini,
  UnverifiedLocationBlockMedium,
} from '../UnverifiedLocationBlock';

const useComingSoonLocations = () => {
  const [comingSoonLocations, setComingSoonLocations] = useState<
    Location[] | undefined
  >(undefined);
  useEffect(() => {
    comingSoon().then((locations) => {
      setComingSoonLocations(locations.data);
    });
  }, []);
  return comingSoonLocations;
};

export default function ComingSoon() {
  const navigation = useAppNavigation();
  const updateSearchState = useSearchStateUpdate();
  const comingSoonLocations = useComingSoonLocations();

  const isMobileDevice = useMaxWidth(430);

  const ComingSoonBlock = isMobileDevice
    ? UnverifiedLocationBlockMini
    : UnverifiedLocationBlockMedium;

  if (comingSoonLocations && comingSoonLocations.length === 0) {
    return null;
  }
  return (
    <LocationSection
      title="Coming Soon"
      Block={ComingSoonBlock}
      style={{ width: '100%' }}
      amountDisplayed={4}
      locations={comingSoonLocations || []}
      carousel={isMobileDevice}
      onViewAllPress={() => {
        updateSearchState({ location: {} });
        navigation.push('ComingSoon');
      }}
    />
  );
}
