import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { Location, searchSlots } from '../../api/search';
import Layout from '../../constants/Layout';
import { useLocation } from '../../contexts/locations';
import {
  useSearchButtonState,
  useUpdateSearchState,
} from '../../contexts/threeparamsearch';
import { useParent } from '../../hooks/useChild';
import { RootStackParamList } from '../../types';
import { getAgeInMonths } from '../../utils/date';
import { LocationBlockMedium } from '../LocationBlocks';
import { LocationSection } from '../LocationSection';
import { ColumnSizerLocationLarge } from '../LocationSkeleton';

interface SimilarLocationsProps {
  lat: number;
  lng: number;
  nameId: string;
  onLocationsFetched?: () => void;
}

export default function SimilarLocations({
  lat,
  lng,
  nameId,
  onLocationsFetched,
}: SimilarLocationsProps) {
  const [loaded, setLoaded] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const { who, isLoggedIn } = useSearchButtonState();
  const kids = isLoggedIn ? who.map((c) => c.id) : [];
  const { children = [] } = useParent();
  const { push } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const location = useLocation(nameId);

  const { setButtonState, setInputState, triggerSearchParamsUpdate } =
    useUpdateSearchState();

  useEffect(() => {
    const ages = children
      .map(
        (child) => kids.includes(child.id) && getAgeInMonths(child.birthdate)
      )
      .filter(Boolean);
    searchSlots({ lat, lng, ages }).then((response) => {
      if (!('error' in response)) {
        setLocations(
          response.data.filter((location) => location.nameId !== nameId)
        );
      }
      setLoaded(true);
    });
  }, []);

  const Block = Layout.isMobileDevice
    ? LocationBlockMedium
    : ColumnSizerLocationLarge;

  useEffect(() => {
    if (!loaded) return;
    if (loaded && locations.length !== 0) {
      onLocationsFetched?.();
    }
  }, [loaded, locations]);

  if (loaded && locations.length === 0) {
    return null;
  }

  return (
    <LocationSection
      title="Other Locations Nearby"
      Block={Block}
      locations={locations}
      style={styles.section}
      contentStyle={styles.contentSection}
      withSkeleton
      amountDisplayed={4}
      onViewAllPress={() => {
        const newSearchParams = {
          latitude: lat,
          longitude: lng,
          description: location?.address?.city,
        };
        setButtonState({
          location: newSearchParams,
        });

        setInputState({
          location: newSearchParams,
        });
        triggerSearchParamsUpdate();
        push('Search');
      }}
    />
  );
}

const styles = StyleSheet.create({
  section: {
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 0,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 30,
        }),
      },
    }),
  },
  contentSection: {
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          scrollbarWidth: 'none',
          flexWrap: 'nowrap',
          overflow: 'scroll',
          gap: 10,
        }),
      },
    }),
  },
});
