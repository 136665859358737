import { Link } from '@react-navigation/native';
import { useState } from 'react';
import { DimensionValue, StyleSheet, View } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMinWidth, useMaxWidth } from '../../hooks/useResponsive';
import { UnderlinedButton } from '../Button';
import { Icon } from '../Icon';
import { InnerContainer } from '../Layout';
import { TransparentRow } from '../Row';
import { QuincyRegularText, TextBodySmall, TextBody } from '../StyledText';

const seoPlaceItems = [
  'Altadena, CA',
  { text: 'Burbank, CA', screen: 'SEOBurbank' as const },
  { text: 'Century City, CA', screen: 'SEOCenturyCity' as const },
  'Chino Hills, CA',
  { text: 'Glendale, CA', screen: 'SEOGlendale' as const },
  'Harbor City, CA',
  'Highland Park, CA',
  'Irvine, CA',
  'La Brea, CA',
  'La Mirada, CA',
  'Long Beach, CA',
  { text: 'Los Angeles, CA', screen: 'SEOLosAngeles' as const },
  'Monrovia, CA',
  'Murrieta, CA',
  'Newport Beach, CA',
  { text: 'Pasadena, CA', screen: 'SEOPasadena' as const },
  { text: 'San Francisco, CA', screen: 'SEOSanFrancisco' as const },
  { text: 'Santa Monica, CA', screen: 'SEOSantaMonica' as const },
  'Silverlake, CA',
  'Walnut, CA',
];

export default function MoreAmazingCare() {
  const theme = useColorScheme();

  const [showingMore, setShowingMore] = useState(false);

  const isLargeDevice = useMinWidth(1100);
  const isMediumDevice = useMaxWidth(850);
  const isSmallDevice = useMaxWidth(660);
  const isMobileDevice = useMaxWidth(430);

  const visibleItemsAmount =
    showingMore || !isMobileDevice ? seoPlaceItems.length : 10;

  let columnsAmount = isLargeDevice ? 5 : 4;
  if (isMediumDevice) {
    columnsAmount = 3;
  }
  if (isSmallDevice) {
    columnsAmount = 2;
  }
  if (isMobileDevice) {
    columnsAmount = 1;
  }
  return (
    <View style={{ backgroundColor: Colors[theme].background }}>
      <InnerContainer style={{ paddingVertical: isLargeDevice ? 36 : 24 }}>
        <TransparentRow>
          <QuincyRegularText style={{ fontSize: 25 }}>
            More amazing care in
          </QuincyRegularText>
        </TransparentRow>
        <TransparentRow
          style={[
            {
              marginVertical: 36,
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            },
            isMobileDevice && styles.seoInnerContainerMobile,
          ]}
        >
          {seoPlaceItems.slice(0, visibleItemsAmount).map((item, idx) => (
            <Link
              to={
                typeof item !== 'string'
                  ? {
                      screen: item.screen,
                    }
                  : {
                      screen: 'Search',
                      params: {
                        location: item,
                      },
                    }
              }
              style={[
                styles.seoPlaceItem,
                {
                  width:
                    columnsAmount === 1
                      ? 'auto'
                      : (`calc(100% / ${columnsAmount})` as DimensionValue),
                },
              ]}
              key={`place-${idx}`}
            >
              <TransparentRow>
                <Icon name="search" color={Colors[theme].accent} />
                <TextBodySmall>
                  {typeof item !== 'string' ? item.text : item}
                </TextBodySmall>
              </TransparentRow>
            </Link>
          ))}
        </TransparentRow>
        {isMobileDevice ? (
          <UnderlinedButton
            title={showingMore ? 'View Less' : 'View More'}
            style={{ marginBottom: 22 }}
            onPress={() => setShowingMore(!showingMore)}
          />
        ) : null}
        <TransparentRow>
          <TextBody>And more cities being added daily...</TextBody>
        </TransparentRow>
      </InnerContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  seoPlaceItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 40,
  },
  seoInnerContainerMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'center',
    marginVertical: 22,
  },
});
