import { useEffect } from 'react';

import useAppNavigation from '../hooks/useAppNavigation';

export default function Redirect<T extends keyof ReactNavigation.RootParamList>(
  props: undefined extends ReactNavigation.RootParamList[T]
    ? { to: T; params?: never }
    : { to: T; params: ReactNavigation.RootParamList[T] }
) {
  const { navigate } = useAppNavigation();
  useEffect(() => {
    const navigateArgs = props.params
      ? [props.to, props.params]
      : ([props.to] as const);
    // need to ignore TS checking here because of strong typings of useNavigation
    // @ts-ignore
    navigate(...navigateArgs);
  }, []);

  return null;
}
