import { useEffect, useState } from 'react';
import { Platform, Pressable, StyleSheet, ViewStyle } from 'react-native';

import { Navigation } from './shared';
import { childrenOnboard } from '../../api/onboardingV2';
import Colors from '../../constants/Colors';
import FeatureFlags from '../../constants/FeatureFlags';
import useColorScheme from '../../hooks/useColorScheme';
import { useFormArray } from '../../hooks/useFormArray';
import { Parent } from '../../utils/api';
import { ordinal } from '../../utils/number';
import AddFormButton from '../AddFormButton';
import BirthdateInput from '../BirthdateInput';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBody, TextH3 } from '../StyledText';
import { FormInput } from '../StyledTextInput';
import { TransparentView } from '../Themed';

export function OnboardingChildrenModal({
  onSuccess,
  goBack,
  initialValues,
  onFormChange,
  style,
}: {
  goBack: () => void;
  onSuccess: (children: Parent['children']) => void;
  initialValues: {
    id: undefined;
    firstName: string;
    birthdate: string;
  }[];
  onFormChange: (
    update: {
      id: undefined;
      firstName: string;
      birthdate: string;
    }[]
  ) => void;
  style?: ViewStyle;
}) {
  const theme = useColorScheme();
  const { state, dispatch, validateFieldsForObject } = useFormArray<{
    id: undefined;
    firstName: string;
    birthdate: string;
  }>(initialValues);

  useEffect(() => {
    onFormChange(state.values);
  }, [state.values]);

  const [submiting, setSubmiting] = useState(false);

  const submitChildren = async () => {
    if (state.isInvalid) {
      return;
    }
    let hasErrors = false;
    state.values.forEach((child, index) => {
      const validation = validateFieldsForObject(index, [
        'firstName',
        'birthdate',
      ]);
      if (!validation.ok) {
        hasErrors = true;
      }
    });
    if (hasErrors) {
      return;
    }

    setSubmiting(true);
    const onboardChildrenResponse = await childrenOnboard(state.values);
    setSubmiting(false);

    if (onboardChildrenResponse.ok && onboardChildrenResponse.children) {
      onSuccess(onboardChildrenResponse.children);
    }
  };

  return (
    <TransparentView
      style={[styles.container, style]}
      testID="onboarding-children-modal"
    >
      <TextH3 style={styles.title}>
        Now tell us a little about your child(ren).
      </TextH3>
      {state.values.map((child, index) => {
        const errors = state.errors[index]!;
        return (
          <TransparentView key={index} style={{ width: '100%' }}>
            <TransparentRow style={styles.row}>
              <TextBody>
                {child.firstName || `Your ${ordinal(index + 1)} child`}
              </TextBody>
              {state.values.length > 1 ? (
                <Pressable
                  onPress={() => dispatch({ type: 'remove', index })}
                  testID={`remove-child-${index}`}
                >
                  <Icon
                    name="close"
                    color={Colors[theme].text}
                    style={styles.removeIcon}
                  />
                </Pressable>
              ) : null}
            </TransparentRow>
            <FormInput
              testID={`firstname-input-${index}`}
              value={child.firstName}
              error={errors.firstName}
              placeholder="Child's First Name"
              onChangeText={(value) =>
                dispatch({
                  type: 'entryField',
                  field: 'firstName',
                  value,
                  index,
                })
              }
              onEndEditing={() =>
                dispatch({ type: 'validate', field: 'firstName', index })
              }
            />
            <BirthdateInput
              testID={`birthdate-input-${index}`}
              value={child.birthdate}
              error={errors.birthdate}
              onChange={(value) =>
                dispatch({
                  type: 'entryField',
                  field: 'birthdate',
                  value,
                  index,
                })
              }
              onEndEditing={() =>
                dispatch({ type: 'validate', field: 'birthdate', index })
              }
              onCalendarChange={(value) =>
                dispatch({
                  type: 'entryField',
                  field: 'birthdate',
                  value,
                  index,
                })
              }
            />
          </TransparentView>
        );
      })}
      <AddFormButton
        testID="add-child-button"
        title={<TextBody>Add another child</TextBody>}
        onPress={() => dispatch({ type: 'add' })}
      />
      <Navigation
        next={FeatureFlags.REFERRALS ? 'Next' : 'Complete'}
        back="Back"
        nextLoading={submiting}
        style={{
          marginTop: 40,
          width: '100%',
        }}
        onBack={goBack}
        onNext={submitChildren}
        disabled={false}
        nextDisabled={state.isInvalid || !state.dirty}
      />
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  title: {
    paddingBottom: 30,
    textAlign: 'center',
  },
  row: {
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  removeIcon: {
    width: 15,
    height: 15,
    ...Platform.select({
      native: {
        width: 20,
        height: 20,
      },
    }),
  },
});
