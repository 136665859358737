import React, { createContext, useContext, useMemo } from 'react';
import { Platform } from 'react-native';

type CalculatedHeights = {
  height: number;
  kidsInputHeight: number;
  togglerHeight: number;
};

const HeightContext = createContext({} as CalculatedHeights);

export const useCalendarElementsHeights = () => {
  if (Platform.OS !== 'web') {
    return {
      height: 530,
      kidsInputHeight: 50,
      togglerHeight: 40,
    };
  }
  return useContext(HeightContext);
};

const MAX_CALENDAR_HEIGHT = 530;

export default function HeightProvider({
  height,
  children,
}: {
  height?: number;
  children: React.ReactNode;
}) {
  const heights = useMemo<CalculatedHeights>(
    () => ({
      height: height || Infinity,
      kidsInputHeight: height ? (height * 50) / MAX_CALENDAR_HEIGHT : 50,
      togglerHeight: height ? (height * 41) / MAX_CALENDAR_HEIGHT : 41,
    }),
    [height]
  );

  return (
    <HeightContext.Provider value={heights}>{children}</HeightContext.Provider>
  );
}
