import { ImageBackground, Image, StyleProp, ViewStyle } from 'react-native';

import { TextH3, TextH4 } from '../StyledText';
import { TransparentView } from '../Themed';

export default function Recommendation({
  imageStyle,
  actionSlot,
}: {
  imageStyle: StyleProp<ViewStyle>;
  actionSlot?: React.ReactNode;
}) {
  return (
    <>
      <TransparentView style={{ flex: 1, paddingBottom: 30 }}>
        <Image
          source={require('../../assets/images/quotes.png')}
          style={{ width: 101, height: 78, marginBottom: 36 }}
        />
        <TextH3>
          The BumoCredit package that I obtained for my daughter is such a great
          deal! I am very pleased with it. Thank you so much!
        </TextH3>
        <TextH4 style={{ marginTop: 16 }}>Mitra M., a Bumo Parent</TextH4>
        {actionSlot}
      </TransparentView>
      <ImageBackground
        source={require('../../assets/images/bumo-credit-recommendation.png')}
        style={imageStyle}
        resizeMode="contain"
      />
    </>
  );
}
