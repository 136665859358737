import Constants from 'expo-constants';

function isDev() {
  return (
    !Constants.expoConfig?.extra?.ENV ||
    Constants.expoConfig?.extra?.ENV === 'dev'
  );
}

function isFlagOn(flag: string, overrides?: Record<string, boolean>) {
  if (overrides && process.env.NODE_ENV && process.env.NODE_ENV in overrides) {
    return overrides[process.env.NODE_ENV];
  }
  return (
    isDev() ||
    Constants.expoConfig?.extra?.[flag] === 'true' ||
    window.localStorage?.[flag] === 'true'
  );
}

export default {
  LOCATION_TYPES: false,
  NOTIFICATIONS: false,
  SEARCH: isFlagOn('FF_SEARCH'),
  REGISTER: false,
  ADDONS: false,
  BETA: isFlagOn('FF_BETA') || true,
  LOGGED_IN_LANDING: true,
  IMAGE_GALLERY: isFlagOn('FF_IMAGE_GALLERY'),
  RECEIPTS: false,
  GEOLOCATION: false,
  GIFT_CARDS: isFlagOn('FF_GIFT_CARDS'),
  NO_FEES: isFlagOn('FF_NO_FEES'),
  GTM: isFlagOn('FF_GTM'),
  CANCELLATION: isFlagOn('FF_CANCELLATION'),
  PROFILE_SUPPORT: false,
  JOINNOW_MODAL: isFlagOn('FF_JOINNOW_MODAL'),
  CARE_CREDIT_MENU: isFlagOn('FF_CARE_CREDIT_MENU'),
  MORE_SEARCH_LOCATIONS: isFlagOn('FF_MORE_SEARCH_LOCATIONS'),
  MEDIA_VIDEO: isFlagOn('FF_MEDIA_VIDEO'),
  REVIEWS: isFlagOn('FF_REVIEWS'),
  NEW_SEARCH: isFlagOn('FF_NEW_SEARCH'),
  NEW_LOGGED_IN_LANDING: isFlagOn('FF_NEW_LOGGED_IN_LANDING'),
  WELCOME_MODAL: isFlagOn('FF_WELCOME_MODAL'),
  NAMED_SESSION_TIMES: isFlagOn('FF_NAMED_SESSION_TIMES'),
  PRICE_TIERS: isFlagOn('FF_PRICE_TIERS'),
  HDYHAU: isFlagOn('FF_HDYHAU'),
  ACCESSIBILITY_WIDGET: isFlagOn('FF_ACCESSIBILITY_WIDGET'),
  UPDATED_TERMS: isFlagOn('FF_UPDATED_TERMS'),
  REFERRALS: isFlagOn('FF_REFERRALS', { test: false }),
  FLEXING: isFlagOn('FF_FLEXING'),
  SEARCH_V2: isFlagOn('FF_SEARCH_V2'),
  LEAD_FORM_V2: isFlagOn('FF_LEAD_FORM_V2', { test: false }),
  JUT: isFlagOn('FF_JUT'),
};
