import { GestureResponderEvent, ImageStyle, StyleSheet } from 'react-native';

import { IconButton } from './Button';
import { Icon, IconName, IconStyle } from './Icon';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type PressableIconProps = StyleProps & {
  name: IconName;
  onPress?: (e: GestureResponderEvent) => void;
  disabled?: boolean;
  iconStyle?: IconStyle;
};

export function PressableForwardIcon(props: Omit<PressableIconProps, 'name'>) {
  return (
    <PressableIcon name="arrowRight" style={styles.arrowRightIcon} {...props} />
  );
}

export function PressableBackIcon(props: Omit<PressableIconProps, 'name'>) {
  return (
    <PressableIcon name="arrowLeft" style={styles.arrowLeftIcon} {...props} />
  );
}

export default function PressableIcon({
  name,
  onPress,
  style,
  iconStyle = {},
  disabled,
}: PressableIconProps) {
  const theme = useColorScheme();

  return (
    <IconButton
      style={[styles.container, style, disabled && styles.disabled]}
      onPress={onPress}
      icon={
        <Icon
          name={name}
          color={Colors[theme].textSecondary}
          style={iconStyle as ImageStyle}
        />
      }
      text={null}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    width: 30,
    height: 30,
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    zIndex: 10,
    top: '50%',
    transform: [
      {
        translateY: -15,
      },
    ],
  },
  disabled: {
    opacity: 0.5,
  },
  arrowRightIcon: {
    right: 6,
  },
  arrowLeftIcon: {
    left: 6,
  },
});
