import { StyleSheet } from 'react-native';

import { IconButton, IconButtonProps } from './Button';
import { Icon } from './Icon';
import { View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

interface AddFormButtonProps {
  title: React.ReactNode;
  onPress: () => void;
  style?: IconButtonProps['style'];
  testID?: string;
}

export default function AddFormButton({
  title,
  onPress,
  style,
  testID,
}: AddFormButtonProps) {
  const theme = useColorScheme();
  return (
    <IconButton
      testID={testID}
      style={style}
      onPress={onPress}
      text={title}
      icon={
        <View
          style={[
            styles.iconContainer,
            { backgroundColor: Colors[theme].accentSecondary },
          ]}
        >
          <Icon name="plus" style={styles.icon} />
        </View>
      }
    />
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    marginRight: 10,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  icon: {
    width: 22,
    height: 22,
  },
});
