import { useCallback, useMemo, useReducer } from 'react';

import { isValidObjectV2, validateFieldV2 } from '../utils/validations';

export interface BaseState {
  dirty: boolean;
  isInvalid: boolean;
}

export const getEmptyStrings = <V extends object, K extends keyof V>(
  values: V
) =>
  (Object.keys(values) as K[]).reduce(
    (acc, field) => ({
      ...acc,
      [field]: '',
    }),
    {} as Record<K, string>
  );

export const getValuesOrEmptyStrings = <V extends object, K extends keyof V>(
  values: V
) =>
  (Object.keys(values) as K[]).reduce(
    (acc, field) => ({
      ...acc,
      [field]: values[field] || '',
    }),
    {} as Record<K, string>
  );

type KeyOf<T extends object> = Extract<keyof T, string>;

type GenericAction<S extends Record<string, any>, F extends keyof S> =
  | { type: 'field'; field: F; value: S[F] }
  | { type: 'error'; field: F; value: string }
  | { type: 'validate'; field: F }
  | { type: 'requestSent' };

export const useForm = <
  ValuesTypes extends Record<K, any>,
  K extends string = KeyOf<ValuesTypes>,
>(
  initialValues: Partial<ValuesTypes>
) => {
  const initialValuesState = useMemo(
    () => ({
      values: getValuesOrEmptyStrings(initialValues),
      errors: getEmptyStrings(initialValues),
    }),
    []
  );
  type ValuesState = typeof initialValuesState;

  const [state, dispatch] = useReducer(
    (
      state: ValuesState & BaseState,
      action: GenericAction<ValuesTypes, KeyOf<ValuesTypes>>
    ) => {
      switch (action.type) {
        case 'field': {
          const { values } = state;
          const newValues = {
            ...values,
            [action.field]: action.value,
          };
          return {
            ...state,
            values: newValues,
            errors: {
              ...state.errors,
              [action.field]: '',
            },
            dirty: true,
            isInvalid: !isValidObjectV2(newValues),
          };
        }

        case 'requestSent': {
          return {
            ...state,
            dirty: false,
            isInvalid: false,
          };
        }
        case 'validate': {
          const { values, errors } = state;

          const validationResult = validateFieldV2(
            action.field,
            values[action.field] || ''
          );
          if (!validationResult) {
            return state;
          }

          return {
            ...state,
            errors: {
              ...errors,
              [action.field]: validationResult.message,
            },
          };
        }
        case 'error': {
          return {
            ...state,
            errors: {
              ...state.errors,
              [action.field]: action.value,
            },
          };
        }
        default:
          return state;
      }
    },
    {
      ...initialValuesState,
      dirty: false,
      isInvalid: false,
    }
  );

  const getSerializedObject = () => state.values as ValuesTypes;

  const validateFields = useCallback(
    (fields: KeyOf<ValuesTypes>[]) => {
      let ok = true;
      fields.forEach((field) => {
        const validationResult = validateFieldV2(
          field,
          state.values[field] || ''
        );
        if (!validationResult) {
          return;
        }
        ok = false;
        dispatch({ type: 'error', field, value: validationResult.message });
      });
      return {
        ok,
      };
    },
    [state]
  );

  return {
    state,
    dispatch,
    getSerializedObject,
    validateFields,
  };
};
