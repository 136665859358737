import {
  defaultAuthedRequest,
  defaultGetRequest,
  getAuthedRequestHeaders,
} from './headers';
import { Location, LocationResponse } from './search';
import { buildURLQuery } from './utils';
import { parseLocations } from '../utils/api';

export function makeSearchSlots(API_URL: string, is_v2: boolean = false) {
  return async function searchSlots(query: Record<string, unknown>): Promise<
    | {
        data: Location[];
        total: number;
      }
    | { error: string }
  > {
    const {
      rangeStart,
      rangeEnd,
      dates,
      lat,
      lng,
      ages,
      kids,
      location,
      ...rest
    } = query;
    const readyUrl = `${API_URL}search/slots?${buildURLQuery({
      ...(rangeStart ? { range_start: rangeStart } : null),
      ...(rangeEnd ? { range_end: rangeEnd } : null),
      ...(lat && lng ? { lat, lng } : null),
      ...(location ? { location } : null),
      ...(rangeEnd ? { range_end: rangeEnd } : null),
      ...(Array.isArray(dates) && dates.length > 0 ? { dates } : null),
      ...(Array.isArray(ages) && ages.length > 0 ? { ages } : null),
      ...(Array.isArray(kids) && kids.length > 0 ? { kid_ids: kids } : null),
      ...(is_v2 ? { v: '2' } : null),
      ...rest,
    })}`;

    const response = await fetch(readyUrl, {
      ...defaultAuthedRequest,
      ...defaultGetRequest,
      ...getAuthedRequestHeaders(),
    });
    const result = (await response.json()) as
      | { data: LocationResponse[]; total: number }
      | { error: string };
    if ('error' in result) {
      return {
        error: result.error,
      };
    }
    if (Array.isArray(result.data)) {
      return {
        total: result.total,
        data: parseLocations(result.data),
      };
    }
    console.warn('Unknown body schema.', result);
    return {
      total: 0,
      data: [],
    };
  };
}
