import { useRoute } from '@react-navigation/native';
import { useCallback } from 'react';

import { DayTimes } from '../../api/search';
import { DayTime } from '../../contexts/checkout';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useAppNavigation from '../../hooks/useAppNavigation';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { notNull } from '../../utils/notnull';
import { TextBodySmall } from '../StyledText';

export function useCopyLink() {
  const dispatchSnackbar = useSnackbarDispatch();
  return useCallback(async (link: string) => {
    await copyToClipboard(link);
    dispatchSnackbar({
      message: <TextBodySmall>Link copied to clipboard!</TextBodySmall>,
    });
  }, []);
}

export function useShareLinkParam() {
  const { setParams } = useAppNavigation();
  const route = useRoute();
  const params = route.params || {};

  const s = 's' in params ? (params['s'] as string) : null;
  const reset = useCallback(() => {
    setParams({ s: undefined });
  }, []);
  return [s, reset] as const;
}

export function prepareSharedWeeksParam(
  selectedWeeks: Record<string, string[]>
) {
  const param = Object.entries(selectedWeeks)
    .map(
      ([weekId, daytimes]) =>
        `${weekId.replace(':', '.')}~${daytimes.join('_')}`
    )
    .join('!');
  return param;
}

export function parseWeekParam(param: string) {
  const weekConfigs = param.split('!');

  return Object.fromEntries(
    weekConfigs
      .map((c) => {
        const [weekIdRaw, daytimesRaw] = c.split('~');
        if (!weekIdRaw || !daytimesRaw) return null;
        const weekId = weekIdRaw.replace('.', ':');
        const daytimes = daytimesRaw.split('_');

        return [weekId, daytimes] as [string, DayTimes[]];
      })
      .filter(notNull)
  );
}

export function prepareSharedDailyParam(
  chosenSlotsEntries: {
    dateString: string;
    dayTime: DayTime;
  }[]
) {
  const param = chosenSlotsEntries
    .map(({ dateString, dayTime }) => `${dateString}~${dayTime}`)
    .join('!');
  return param;
}

export function parseDailyParam(param: string) {
  const slotEntries = param.split('!');
  return slotEntries
    .map((el) => {
      const [dateString, dayTime] = el.split('~');
      if (!dateString || !dayTime) return null;
      return { dateString, dayTime };
    })
    .filter(notNull);
}
