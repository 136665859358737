import { useEffect, useRef, useState } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { Icon } from './Icon';
import Row, { TransparentRow } from './Row';
import { TextBody, TextBodySmall } from './StyledText';
import { View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type SelectInputProps = {
  label?: string;
  value: string;
  options: string[];
  placeholder?: string;
  onChange?: (value: string) => void;
};

export default function SelectInput(props: SelectInputProps) {
  if (Platform.OS !== 'web') {
    return (
      <View>
        <TextBody>Select Input native TODO</TextBody>
      </View>
    );
  }
  return <SelectInputWeb {...props} />;
}

function SelectInputWeb({
  label,
  value,
  options,
  placeholder = '',
  onChange,
}: SelectInputProps) {
  const theme = useColorScheme();

  const popupRef = useRef<PopupActions>(null);
  useEffect(() => {
    popupRef.current?.close();
  }, [value]);

  const [inputWidth, setInputWidth] = useState(380);

  return (
    <>
      {label ? <TextBodySmall>{label}</TextBodySmall> : null}
      <Popup
        ref={popupRef}
        arrow={false}
        offsetX={0}
        offsetY={0}
        trigger={(isOpen) => (
          <Row
            style={[
              {
                // @ts-ignore
                cursor: 'pointer',
              },
              styles.input,
              {
                borderColor: Colors[theme].lines,
              },
              isOpen && {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            ]}
            onLayout={(e) => setInputWidth(e.nativeEvent.layout.width)}
          >
            <TransparentRow>
              <TextBodySmall
                style={[
                  value
                    ? {}
                    : {
                        color: Colors[theme].textSecondary,
                      },
                ]}
              >
                {value || placeholder}
              </TextBodySmall>
            </TransparentRow>
            <Icon name={isOpen ? 'arrowUp' : 'arrowDown'} />
          </Row>
        )}
      >
        {options.map((opt, idx) => (
          <Pressable onPress={() => onChange?.(opt)}>
            <Row
              style={[
                {
                  width: inputWidth,
                  borderColor: Colors[theme].lines,
                },
                styles.optionRow,
                opt === value && {
                  backgroundColor: Colors[theme].backgroundSecondary,
                },
                idx === options.length - 1 && {
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                },
              ]}
            >
              <TextBodySmall>{opt}</TextBodySmall>
            </Row>
          </Pressable>
        ))}
      </Popup>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 55,
    marginBottom: 20,
    justifyContent: 'space-between',
    maxWidth: 380,
    width: '100%',
    borderWidth: 2,
    borderRadius: 10,
    paddingHorizontal: 20,
  },
  optionRow: {
    height: 50,
    justifyContent: 'space-between',
    borderWidth: 1,
    borderTopWidth: 0,
    paddingHorizontal: 20,
  },
});
