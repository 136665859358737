import { ViewStyle } from 'react-native';

import Colors from '../../constants/Colors';
import Links from '../../constants/Links';
import useColorScheme from '../../hooks/useColorScheme';
import { isWithinDatesRange } from '../../utils/campaigns';
import { handleLink } from '../../utils/common';
import { HelloMessageBarContainer } from '../HelloMessageBar';
import { TextCta } from '../StyledText';

export const isSummerCampVIPPackageAvailable = () =>
  isWithinDatesRange({
    startDate: '2025-03-10T00:00:00.000Z',
    endDate: '2025-04-01T07:59:59.000Z',
  });

const SummerCampsPageHellobar = ({ style }: { style?: ViewStyle }) => {
  const theme = useColorScheme();
  const onPress = () => handleLink(Links.promo);
  return (
    <HelloMessageBarContainer
      style={[{ backgroundColor: Colors[theme].accentSecondary }, style]}
    >
      <TextCta>
        Purchase the{' '}
        <TextCta
          onPress={onPress}
          style={{
            textDecorationStyle: 'solid',
            textDecorationLine: 'underline',
          }}
        >
          VIP Camp Package
        </TextCta>{' '}
        and get hundreds in bonus credit 💰!
      </TextCta>
    </HelloMessageBarContainer>
  );
};

export default SummerCampsPageHellobar;
