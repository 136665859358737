import React, { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { useThreeParamSearchButtonText } from './useThreeParamSearchButtonText';
import Colors from '../../constants/Colors';
import {
  ButtonState,
  useSearchButtonState,
  useUpdateSearchState,
} from '../../contexts/threeparamsearch';
import useColorScheme from '../../hooks/useColorScheme';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';
import { TransparentView } from '../Themed';

export function SearchButtonMobile({
  wide = false,
  icon = (color: string) => (
    <Icon name="search" color={color} style={styles.searchIcon} />
  ),
}: {
  wide?: boolean;
  icon?: (color: string) => React.ReactNode;
}) {
  const theme = useColorScheme();

  const { setPopupState } = useUpdateSearchState();

  const open = useCallback(() => {
    setPopupState({
      open: true,
    });
  }, [setPopupState]);

  const buttonState = useSearchButtonState();

  return (
    <Pressable style={styles.buttonContainer} onPress={open}>
      <TransparentRow
        style={[
          styles.button,
          {
            backgroundColor: Colors[theme].background,
            shadowRadius: 12,
            shadowColor: Colors[theme].lines,
          },
        ]}
      >
        {icon(Colors[theme].accentTertiary)}
        {wide ? (
          <ButtonTextWide buttonState={buttonState} />
        ) : (
          <ButtonTextNarrow buttonState={buttonState} />
        )}
      </TransparentRow>
    </Pressable>
  );
}

export function ButtonTextWide({
  buttonState,
}: {
  buttonState: Pick<
    ButtonState,
    'location' | 'rangeStart' | 'rangeEnd' | 'who'
  >;
}) {
  const { whereText, whenText, whoText } =
    useThreeParamSearchButtonText(buttonState);
  return (
    <TransparentRow
      style={{
        alignSelf: 'center',
        justifyContent: 'space-between',
        gap: 20,
        flex: 1,
      }}
    >
      <TextBodySmall bold>{whereText}</TextBodySmall>
      <Dot />
      <TextBodySmall bold>{whenText}</TextBodySmall>
      <Dot />
      <TextBodySmall bold>{whoText}</TextBodySmall>
    </TransparentRow>
  );
}

export function ButtonTextNarrow({
  buttonState,
}: {
  buttonState: Pick<
    ButtonState,
    'location' | 'rangeStart' | 'rangeEnd' | 'who'
  >;
}) {
  const theme = useColorScheme();
  const { whereText, whenText, whoText } =
    useThreeParamSearchButtonText(buttonState);
  return (
    <TransparentView>
      <TextBodySmall style={{ fontSize: 13, lineHeight: 15 }}>
        {whereText}
      </TextBodySmall>
      <TransparentRow style={{ justifyContent: 'flex-start' }}>
        <TextBodySmall
          style={{
            fontSize: 12,
            color: Colors[theme].textSecondary,
            lineHeight: 15,
          }}
        >
          {whenText}
        </TextBodySmall>
        <TransparentView
          style={[styles.dot, { backgroundColor: Colors[theme].textSecondary }]}
        />
        <TextBodySmall
          style={{
            fontSize: 12,
            color: Colors[theme].textSecondary,
            lineHeight: 15,
          }}
        >
          {whoText}
        </TextBodySmall>
      </TransparentRow>
    </TransparentView>
  );
}

function Dot() {
  const theme = useColorScheme();
  return (
    <TransparentView
      style={[styles.bigdot, { backgroundColor: Colors[theme].text }]}
    />
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 24,
    marginHorizontal: 12,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 400,
  },
  button: {
    borderRadius: 24,
    height: 48,
    paddingHorizontal: 8,
    paddingRight: 20,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  searchIcon: {
    width: 34,
    height: 34,
    marginRight: 8,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    marginHorizontal: 8,
  },
  bigdot: {
    width: 6,
    height: 6,
    borderRadius: 3,
  },
});
