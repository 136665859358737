import { useEffect, useState } from 'react';
import { GestureResponderEvent, Pressable, StyleSheet } from 'react-native';

import { Icon } from './Icon';
import { StyleProps } from './Themed';
import { postFavorite, postUnfavorite } from '../api/locations';
import Colors from '../constants/Colors';
import { useAuthenticate } from '../contexts/authFlow';
import { useAuthDispatch, useAuthState } from '../contexts/authentication';
import useColorScheme from '../hooks/useColorScheme';

type FavoriteProps = StyleProps & {
  locationId: number;
};

export default function Favorite({ locationId, style }: FavoriteProps) {
  const { parent: { favorites = [] } = {} } = useAuthState();
  const authDispatch = useAuthDispatch();
  const authenticate = useAuthenticate();
  const [isFavorite, setIsFavorite] = useState<boolean>();
  const theme = useColorScheme();

  useEffect(() => {
    setIsFavorite(favorites?.includes(locationId));
  }, [favorites.length]);

  const onFavoritePress = async (e: GestureResponderEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await authenticate();
    if (isFavorite) {
      setIsFavorite(false);
      postUnfavorite(locationId);
      authDispatch({ type: 'unfavorite', value: locationId });
    } else {
      setIsFavorite(true);
      postFavorite(locationId);
      authDispatch({ type: 'favorite', value: locationId });
    }
  };
  return (
    <Pressable style={[styles.container, style]} onPress={onFavoritePress}>
      {isFavorite ? (
        <Icon name="heartOutlined" backgroundColor={Colors[theme].accent} />
      ) : (
        <Icon
          name="heartOutlined"
          backgroundColor={Colors[theme].backgroundSecondary}
        />
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 10,
  },
});
