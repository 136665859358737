import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const ExploreIcon = (props: SvgProps) => (
  <Svg
    // xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M18.392 19.076c-.04-.025-.091.012-.08.058l1.953 6.681.026.084c.029.112.006.153-.107.182l-1.644.415-1.37.345c-.161.041-.19.03-.234-.13a10236.447 10236.447 0 0 0-1.84-6.816c-.013-.052-.087-.06-.113-.013l-.003.006-3.375 6.232-.02.038c-.075.136-.109.147-.239.068l-1.269-.768-1.382-.834c-.133-.08-.145-.111-.068-.244a6362.588 6362.588 0 0 0 3.75-6.599c.025-.047-.009-.103-.062-.096l-5.343 1.149-2.264.483c-.12.026-.157 0-.181-.122l-.515-2.605c-.03-.153-.012-.187.139-.223.74-.175 1.481-.346 2.222-.52l5.098-1.206c.036-.009.042-.06.011-.08a2.544 2.544 0 0 0-.06-.041l-6.175-4.035-.083-.054c-.079-.052-.092-.112-.038-.194.502-.752 1.003-1.504 1.503-2.258.056-.085.117-.091.202-.039.724.454 1.448.907 2.174 1.358l3.746 2.341c.045.029.102-.015.088-.067l-.143-.54-1.663-6.138c-.04-.147-.023-.176.126-.215l2.557-.663c.13-.034.163-.02.2.108a35938.2 35938.2 0 0 0 2.075 7.09c.015.05.087.056.113.01l.007-.013 3.636-6.4a5.51 5.51 0 0 1 .055-.094c.059-.097.104-.111.204-.052.307.18.613.362.92.545l1.339.799c.158.094.17.117.08.285-.318.59-.64 1.18-.96 1.769-.856 1.576-1.71 3.153-2.565 4.73l-.008.014c-.023.044.015.1.063.09l7.047-1.656c.056-.014.112-.031.169-.042.111-.021.157.01.178.119l.35 1.847c.076.409.152.817.23 1.225.03.154.015.19-.139.223-.672.146-1.345.29-2.018.433l-4.068.866-.659.143c-.039.011-.044.061-.01.083.053.036.101.068.15.099l5.802 3.623c.226.142.227.144.079.363-.562.831-1.124 1.661-1.688 2.491-.095.141-.118.15-.261.056-.878-.572-1.754-1.145-2.63-1.72l-3.002-1.966a.551.551 0 0 0-.013-.008Z"
    />
  </Svg>
);

export const HeartIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M5 13.464C5 10.377 7.708 8 10.887 8c1.638 0 3.08.768 4.115 1.762C16.036 8.768 17.478 8 19.117 8c3.178 0 5.887 2.377 5.887 5.464 0 2.114-.902 3.974-2.152 5.545-1.247 1.57-2.878 2.901-4.44 3.983-.596.414-1.2.795-1.752 1.076-.52.264-1.116.506-1.658.506-.543 0-1.14-.242-1.658-.506a15.79 15.79 0 0 1-1.752-1.076c-1.563-1.082-3.194-2.414-4.441-3.983C5.901 17.438 5 15.58 5 13.464Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const BookingsIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="m23.373 11.693-4.704-.976c-.028.128-.048.214-.065.3-.545 2.657-1.083 5.313-1.638 7.964-.224 1.072-.738 1.954-1.768 2.45-1.22.585-2.617.125-3.188-1.06-.308-.638-.362-1.325-.229-2.006.468-2.38.962-4.755 1.446-7.131l.316-1.57-4.488-.918c-.096.465-.19.904-.277 1.344-.55 2.745-1.12 5.485-1.636 8.234a7.631 7.631 0 0 0 .107 3.4c.332 1.25 1.033 2.223 2.157 2.873 1.063.612 2.217.893 3.432.943 1.13.048 2.168-.229 3.076-.928a.87.87 0 0 1 .145-.069 93.879 93.879 0 0 0-.3 1.51l4.489.917c1.04-5.095 2.08-10.176 3.125-15.277Zm-12.13-6.269c0 1.343 1.102 2.401 2.503 2.405 1.393.005 2.475-1.072 2.485-2.474.01-1.322-1.133-2.347-2.627-2.355-1.358-.007-2.361 1.024-2.36 2.424Zm9.51-.966c-1.631.01-2.38 1.324-2.386 2.314a2.506 2.506 0 0 0 2.512 2.511c1.376-.006 2.468-1.086 2.466-2.438-.002-1.352-1.13-2.394-2.592-2.385v-.002Z"
    />
  </Svg>
);

export const ProfileIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M9.909 8.909a4.909 4.909 0 1 1 9.817 0 4.909 4.909 0 0 1-9.817 0ZM12.363 15.33A7.363 7.363 0 0 0 5 22.692a2.454 2.454 0 0 0 2.454 2.455H22.18a2.454 2.454 0 0 0 2.454-2.455 7.363 7.363 0 0 0-7.363-7.362h-4.908Z"
    />
  </Svg>
);

export const SearchIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M18.23 7.27a7.75 7.75 0 1 0-1.378 12.057l4.56 4.56a1.75 1.75 0 0 0 2.475-2.475l-4.56-4.56A7.752 7.752 0 0 0 18.23 7.27ZM9.745 9.745a4.25 4.25 0 0 1 6.014 6.007l-.004.003-.004.004a4.25 4.25 0 0 1-6.006-6.014Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ArrowForwardIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M16.837 10.663a.938.938 0 0 1 1.326-1.326l5 5a.934.934 0 0 1 0 1.326l-5 5a.937.937 0 0 1-1.326-1.326l3.4-3.4H8.125a.937.937 0 1 1 0-1.874h12.112l-3.4-3.4Z"
    />
  </Svg>
);

export const PlusIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M17 4.644a1.75 1.75 0 1 0-3.5 0V13.5H4.644a1.75 1.75 0 1 0 0 3.5H13.5v8.857a1.75 1.75 0 1 0 3.5 0V17h8.858a1.75 1.75 0 1 0 0-3.5H17V4.644Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const CheckIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M24.887 8.513a1.75 1.75 0 0 1 0 2.474l-11 11a1.75 1.75 0 0 1-2.475 0l-5.9-5.899a1.75 1.75 0 0 1 2.475-2.475l4.662 4.662 9.763-9.762a1.75 1.75 0 0 1 2.475 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const RefreshIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      stroke={props.fill || '#000'}
      strokeWidth={0.8}
      d="M8.847 8.124c-.224-.403-.138-.911.233-1.184A9.961 9.961 0 0 1 15 5c5.523 0 10 4.477 10 10 0 1.565-.36 3.047-1 4.366-.323.664-1.227.624-1.585-.021l-1.59-2.86A1 1 0 0 1 21.7 15h.3c.552 0 1.006-.45.938-.997a8 8 0 0 0-12.501-5.574c-.525.364-1.28.253-1.59-.305Zm12.306 13.752c.224.403.138.911-.233 1.184A9.96 9.96 0 0 1 15 25C9.477 25 5 20.523 5 15c0-1.565.36-3.047 1-4.366.323-.664 1.227-.624 1.585.021l1.59 2.86A1 1 0 0 1 8.3 15H8c-.552 0-1.006.45-.938.997a8 8 0 0 0 12.502 5.574c.524-.364 1.28-.253 1.59.305Z"
    />
  </Svg>
);

export const CalendarIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={20}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 2 16 25"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M13.833 2.333H13V.667h-1.667v1.666H4.667V.667H3v1.666h-.833A1.66 1.66 0 0 0 .508 4L.5 15.667c0 .916.742 1.666 1.667 1.666h11.666c.917 0 1.667-.75 1.667-1.666V4c0-.917-.75-1.667-1.667-1.667Zm0 13.334H2.167V7.333h11.666v8.334Zm-11.666-10h11.666V4H2.167v1.667Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ArrowBackIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M13.788 10.663a.938.938 0 0 0-1.326-1.326l-5 5a.934.934 0 0 0 0 1.326l5 5a.937.937 0 0 0 1.326-1.326l-3.4-3.4H22.5a.937.937 0 1 0 0-1.874H10.388l3.4-3.4Z"
    />
  </Svg>
);

export const ArrowLeftIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M20.247 4.52a1.777 1.777 0 0 0-2.514 0l-9.212 9.213a1.777 1.777 0 0 0 0 2.514l9.212 9.212a1.777 1.777 0 0 0 2.514-2.513L12.29 14.99l7.956-7.956a1.777 1.777 0 0 0 0-2.513Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const HeartOutlinedIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={31}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 31"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#00000000'}
      stroke={props.stroke || '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.75 13.964c0-2.61 2.306-4.714 5.137-4.714 1.4 0 2.664.658 3.595 1.553a.75.75 0 0 0 1.04 0c.931-.895 2.195-1.553 3.595-1.553 2.83 0 5.137 2.104 5.137 4.714 0 1.89-.806 3.59-1.989 5.079-1.182 1.488-2.747 2.771-4.28 3.833-.583.404-1.156.764-1.666 1.024-.505.257-.965.424-1.317.424-.353 0-.813-.167-1.318-.424-.51-.26-1.082-.62-1.665-1.024-1.533-1.062-3.098-2.345-4.28-3.833-1.184-1.489-1.989-3.188-1.989-5.08Z"
    />
  </Svg>
);

export const ShareIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M14 10.353v3.765H2v-3.765H0V16h16v-5.647h-2Z"
    />
    <Path
      fill={props.fill || '#000'}
      d="M4 3.765h3v9.412h2V3.764h3L8 0 4 3.765Z"
    />
  </Svg>
);

export const ArrowUpIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M20.663 18.788a.937.937 0 0 0 0-1.326l-5-5a.937.937 0 0 0-1.326 0l-5 5a.937.937 0 1 0 1.326 1.326L15 14.45l4.337 4.337a.937.937 0 0 0 1.326 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ArrowDownIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M20.663 11.212a.937.937 0 0 1 0 1.326l-5 5a.937.937 0 0 1-1.326 0l-5-5a.937.937 0 1 1 1.326-1.326L15 15.55l4.337-4.337a.937.937 0 0 1 1.326 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const WarningIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="2 2 26 26"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M15 27.5C8.096 27.5 2.5 21.904 2.5 15S8.096 2.5 15 2.5 27.5 8.096 27.5 15 21.904 27.5 15 27.5Zm-1.25-8.75v2.5h2.5v-2.5h-2.5Zm0-10v7.5h2.5v-7.5h-2.5Z"
    />
  </Svg>
);

export const RemoveCircleIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M2.5 15C2.5 8.1 8.1 2.5 15 2.5S27.5 8.1 27.5 15 21.9 27.5 15 27.5 2.5 21.9 2.5 15Zm6.25 1.25h12.5v-2.5H8.75v2.5Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const LoaderIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      stroke={props.fill || '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 2.5v5 M15 22.5v5 M6.163 6.162 9.7 9.7 M20.3 20.3l3.537 3.537 M2.5 15h5 M22.5 15h5 M6.163 23.837 9.7 20.3 M20.3 9.7l3.537-3.538"
    />
  </Svg>
);

export const ParentIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M8.75 13.75a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25Zm13.125 5a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0 1.25a5.625 5.625 0 0 1 5.625 5.625v.625H16.25v-.625A5.625 5.625 0 0 1 21.875 20ZM8.75 15A6.25 6.25 0 0 1 15 21.25v5H2.5v-5A6.25 6.25 0 0 1 8.75 15Z"
    />
  </Svg>
);

export const DateRangeIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={18}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 18 20"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      strokeWidth={0.3}
      fillRule="evenodd"
      d="M15.25 15.667V4c0-.916-.75-1.666-1.667-1.666h-.833V.667h-1.667v1.667H4.417V.667H2.75v1.667h-.833A1.66 1.66 0 0 0 .258 4L.25 15.667c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667Zm-10-5.833V8.167H3.583v1.667H5.25ZM1.917 5.667h11.666V4H1.917v1.667Zm11.666 1.667v8.333H1.917V7.334h11.666Zm-3.333 2.5h1.667V8.167H10.25v1.667Zm-1.667 0H6.917V8.167h1.666v1.667Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const DateBlackoutIcon = (props: SvgProps) => (
  <Svg width={18} height={20} fill="none" viewBox="0 -4 19 25" {...props}>
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M15 2h1c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H2a2 2 0 0 1-2-2L.01 4C.01 2.9.89 2 2 2h1V0h2v2h8V0h2v2ZM2 8v10h14V8H2Zm14-2H2V4h14v2Zm-2 5H9v5h5v-5Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const FileEditIcon = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <Path
      fill={props.fill || '#000'}
      d="m26.25 8.446-2.5 2.5V5H12.5v6.25H6.25V25h17.5v-3.446l2.5-2.5v7.206a1.24 1.24 0 0 1-1.241 1.24H4.99a1.249 1.249 0 0 1-1.241-1.259V10l7.504-7.5h13.743c.69 0 1.253.569 1.253 1.24v4.706Zm.973 2.563 1.767 1.768-9.723 9.723-1.77-.003.003-1.764 9.723-9.723v-.001Z"
    />
  </Svg>
);

export const CallIcon = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <Path
      fill={props.fill || '#000'}
      d="M6.25 12.326A23.083 23.083 0 0 0 18.208 23.94l.85.378a4.375 4.375 0 0 0 5.413-1.559l1.11-1.654a1.25 1.25 0 0 0-.253-1.67l-3.765-3.037a1.25 1.25 0 0 0-1.79.229l-1.164 1.572a15.174 15.174 0 0 1-6.889-6.89l1.572-1.164a1.25 1.25 0 0 0 .228-1.79l-3.037-3.764a1.25 1.25 0 0 0-1.67-.254L7.149 5.455a4.375 4.375 0 0 0-1.546 5.444l.647 1.425.001.002Z"
    />
  </Svg>
);

export const MapIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      d="M12.3323 25L6 21.8673V6L12.3323 9.13273V25Z"
      stroke={props.fill || '#000'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M18.6699 21.8673L12.3345 25V9.13273L18.6699 6V21.8673Z"
      stroke={props.fill || '#000'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      d="M24.9978 25L18.6655 21.8673V6L24.9978 9.13273V25Z"
      stroke={props.fill || '#000'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

export const PriceTagIcon = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="m12.41 2.58 9 9c.36.36.59.87.59 1.42 0 .55-.22 1.05-.59 1.41l-7 7c-.36.37-.86.59-1.41.59-.55 0-1.05-.22-1.41-.58l-9-9C2.22 12.05 2 11.55 2 11V4c0-1.1.9-2 2-2h7c.55 0 1.05.22 1.41.58ZM4 11l9 9.01 7-7.02-9-9V4H4v7Zm2.5-6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm3.74 4.875c0 .475.365.78 1.5 1.075 1.135.295 2.35.78 2.35 2.2 0 1.025-.775 1.59-1.75 1.775V16h-1.5v-1.085C9.88 14.71 9.06 14.095 9 13h1.1c.055.59.46 1.05 1.49 1.05 1.105 0 1.35-.55 1.35-.895 0-.465-.25-.905-1.5-1.205-1.395-.335-2.35-.91-2.35-2.065 0-.965.78-1.595 1.75-1.805V7h1.5v1.095c1.045.255 1.57 1.045 1.605 1.905H12.84c-.03-.625-.36-1.05-1.25-1.05-.845 0-1.35.38-1.35.925Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const CardIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M5 10.882v2.57h20.556v-2.57H5ZM25.501 9.34H5.054A4.001 4.001 0 0 1 9 6h12.556A4.001 4.001 0 0 1 25.5 9.34ZM5 14.993h20.556V20.5a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4v-5.507Zm4.11 4.646a.75.75 0 0 0 0 1.5h2.055a.75.75 0 0 0 0-1.5H9.11Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ClockIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M4.375 15C4.375 9.132 9.132 4.375 15 4.375S25.625 9.132 25.625 15 20.868 25.625 15 25.625 4.375 20.868 4.375 15Zm11.563-6.25a.937.937 0 1 0-1.876 0V15c0 .323.167.624.441.795l3.75 2.344a.937.937 0 1 0 .994-1.59l-3.31-2.069V8.75Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const LocationIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M14.857 4a8.854 8.854 0 0 0-8.825 8.127 9.555 9.555 0 0 0 2.126 6.833l5.12 6.262a2.336 2.336 0 0 0 3.618 0l5.121-6.262a9.555 9.555 0 0 0 2.126-6.833A8.854 8.854 0 0 0 15.318 4h-.461Zm-3.261 8.356a3.472 3.472 0 1 1 6.944 0 3.472 3.472 0 0 1-6.944 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const WhatsHotIcon = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 20 20"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M11.25 1.113s.617 2.208.617 4c0 1.716-1.125 3.108-2.842 3.108C7.3 8.22 6 6.829 6 5.113l.025-.3a11.47 11.47 0 0 0-2.692 7.408A6.665 6.665 0 0 0 10 18.888a6.665 6.665 0 0 0 6.667-6.667c0-4.492-2.159-8.5-5.417-11.108M9.642 11.52c-1.134.233-1.809.966-1.809 2.008 0 1.117.925 2.017 2.075 2.017A3.09 3.09 0 0 0 13 12.454c0-.892-.125-1.767-.383-2.6-.659.892-1.834 1.434-2.975 1.667M5 12.22c0 2.758 2.242 5 5 5s5-2.242 5-5c0-2.133-.55-4.192-1.575-6.025-.442 2.167-2.183 3.692-4.4 3.692-1.3 0-2.467-.517-3.308-1.359A9.9 9.9 0 0 0 5 12.221"
      clipRule="evenodd"
    />
  </Svg>
);

export const HomeIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M25 25a1.25 1.25 0 0 1-1.25 1.25H6.25A1.25 1.25 0 0 1 5 25V13.75H1.25L14.159 2.015a1.25 1.25 0 0 1 1.682 0L28.75 13.75H25zm-10-3.75 4.199-4.199a2.811 2.811 0 0 0-3.065-4.587 2.8 2.8 0 0 0-.913.61l-.221.221-.221-.221a2.81 2.81 0 0 0-4.587.912 2.81 2.81 0 0 0 .61 3.065z"
    />
  </Svg>
);

export const WalletIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M27.5 7.5h-8.75a7.5 7.5 0 0 0 0 15h8.75V25a1.25 1.25 0 0 1-1.25 1.25H3.75A1.25 1.25 0 0 1 2.5 25V5a1.25 1.25 0 0 1 1.25-1.25h22.5A1.25 1.25 0 0 1 27.5 5zM18.75 10h10v10h-10a5 5 0 1 1 0-10m0 3.75v2.5h3.75v-2.5z"
    />
  </Svg>
);

export const GroupIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M2.5 27.5a10 10 0 0 1 20 0zm10-11.25A7.5 7.5 0 0 1 5 8.75c0-4.144 3.356-7.5 7.5-7.5S20 4.606 20 8.75s-3.356 7.5-7.5 7.5m9.204 2.791a9.38 9.38 0 0 1 7.025 8.459H25c0-3.262-1.25-6.233-3.296-8.459m-2.529-2.845A9.97 9.97 0 0 0 22.5 8.75a9.96 9.96 0 0 0-1.269-4.879 6.25 6.25 0 0 1-2.056 12.325"
    />
  </Svg>
);

export const StarIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M13.066 4.114c.862-1.485 3.006-1.485 3.868 0l2.638 4.547c.243.419.664.705 1.143.777l4.381.662c1.91.288 2.58 2.7 1.092 3.932l-3.192 2.645a1.6 1.6 0 0 0-.55 1.528l.894 4.696c.35 1.84-1.58 3.269-3.237 2.396l-4.359-2.295a1.6 1.6 0 0 0-1.488 0l-4.359 2.295c-1.657.873-3.588-.556-3.237-2.396l.894-4.696a1.6 1.6 0 0 0-.55-1.528l-3.192-2.645C2.324 12.8 2.994 10.388 4.904 10.1l4.381-.662c.48-.072.9-.358 1.143-.777z"
      clipRule="evenodd"
    />
  </Svg>
);
export const FoodIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M11.018 4.001a.81.81 0 0 0-.846.949 5.8 5.8 0 0 0 1.92 3.382c-2.375.504-5.212 1.808-5.088 5.194.23 5.835 6.077 13.096 8.226 13.096 2.731 0 8.95-8.895 8.446-13.348-.214-1.906-1.588-3.383-3.602-4.29 1.39-.922 2.085-2.674 2.411-3.867.171-.625-.358-1.158-.992-1.023-1.606.342-4.312 1.14-5.38 2.768-1.027-1.64-2.876-2.766-5.095-2.861"
      clipRule="evenodd"
    />
  </Svg>
);

export const BathroomIcon = (props: SvgProps) => (
  <Svg
    width={31}
    height={32}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 31 32"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      stroke={props.fill || '#000'}
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1 2a1 1 0 0 1 1-1h7.405a.99.99 0 0 1 .947.69c2.161 6.865 1.993 10.468-.222 16.11H1z"
    />
    <Path
      fill={props.fill || '#000'}
      stroke={props.fill || '#000'}
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.609 17.8V31h13.043c-.39-1.583-.602-2.971-.612-4.283 1.197.44 2.504.683 3.873.683 4.872 0 8.964-3.071 10.113-7.224.355-1.285-.846-2.376-2.287-2.376z"
    />
  </Svg>
);

export const CloudIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M16.165 5.885a6.76 6.76 0 0 0-5.69 3.108 7.344 7.344 0 1 0-1.882 14.445h14.561a5.597 5.597 0 1 0-.239-11.19 6.76 6.76 0 0 0-6.75-6.363"
    />
  </Svg>
);

export const ShieldIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M15.78 4.209a1.46 1.46 0 0 0-1.504 0l-.885.533a19.1 19.1 0 0 1-8.066 2.655L5 7.427v2.584a15.17 15.17 0 0 0 4.626 10.907l4.707 4.55a1 1 0 0 0 1.39 0l4.706-4.55a15.17 15.17 0 0 0 4.627-10.907V7.427l-.325-.03a19.1 19.1 0 0 1-8.067-2.655z"
      clipRule="evenodd"
    />
  </Svg>
);

export const LaptopIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M27.5 23.125V4.375h-25v18.75H0v2.5h30v-2.5zm-10 0h-5v-1.25h5zM5 19.375h20v-12.5H5z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ComputerIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M16.25 22.5V25h5v2.5H8.75V25h5v-2.5H3.74a1.247 1.247 0 0 1-1.24-1.259V5.01c0-.695.569-1.259 1.24-1.259h22.52c.685 0 1.24.561 1.24 1.259V21.24c0 .695-.569 1.259-1.24 1.259z"
    />
  </Svg>
);

export const LocationOutlinedIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M15 2.5a8.744 8.744 0 0 0-8.75 8.75C6.25 17.813 15 27.5 15 27.5s8.75-9.688 8.75-16.25A8.744 8.744 0 0 0 15 2.5Zm-6.25 8.75C8.75 7.8 11.55 5 15 5s6.25 2.8 6.25 6.25c0 3.6-3.6 8.988-6.25 12.35-2.6-3.338-6.25-8.788-6.25-12.35Zm3.125 0a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const HouseIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M17.245 5.17a3.125 3.125 0 0 0-4.49 0l-5.998 6.195a2.509 2.509 0 0 0-.666 1.292 35.945 35.945 0 0 0-.159 12.03l.221 1.413c.07.446.454.775.906.775h4.19c.346 0 .626-.28.626-.625V17.5h6.25v8.75c0 .345.28.625.625.625h4.191a.917.917 0 0 0 .906-.775l.22-1.413c.623-3.99.57-8.057-.159-12.03a2.51 2.51 0 0 0-.665-1.292L17.245 5.17Z"
    />
  </Svg>
);

export const BumoIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="m23.373 11.693-4.704-.976c-.028.128-.048.214-.065.3-.545 2.657-1.083 5.313-1.638 7.964-.224 1.072-.738 1.954-1.768 2.45-1.22.585-2.617.125-3.188-1.06-.308-.638-.362-1.325-.229-2.006.468-2.38.962-4.755 1.446-7.131l.316-1.57-4.488-.918c-.096.465-.19.904-.277 1.344-.55 2.745-1.12 5.485-1.636 8.234a7.631 7.631 0 0 0 .107 3.4c.332 1.25 1.033 2.223 2.157 2.873 1.063.612 2.217.893 3.432.943 1.13.048 2.168-.229 3.076-.928a.87.87 0 0 1 .145-.069 93.879 93.879 0 0 0-.3 1.51l4.489.917c1.04-5.095 2.08-10.176 3.125-15.277Zm-12.13-6.269c0 1.343 1.102 2.401 2.503 2.405 1.393.005 2.475-1.072 2.485-2.474.01-1.322-1.133-2.347-2.627-2.355-1.358-.007-2.361 1.024-2.36 2.424Zm9.51-.966c-1.631.01-2.38 1.324-2.386 2.314a2.506 2.506 0 0 0 2.512 2.511c1.376-.006 2.468-1.086 2.466-2.438-.002-1.352-1.13-2.394-2.592-2.385v-.002Z"
    />
  </Svg>
);

export const ShieldOutlinedIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={31}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 31"
    {...props}
  >
    <Path
      stroke={props.fill || '#000'}
      fill="transparent"
      strokeWidth={1.5}
      d="M14.663 5.037a.707.707 0 0 1 .73 0l.885.533a19.863 19.863 0 0 0 8.028 2.722v1.905c0 3.909-1.587 7.65-4.398 10.368l-4.706 4.55a.25.25 0 0 1-.348 0l-4.706-4.55A14.42 14.42 0 0 1 5.75 10.197V8.292a19.863 19.863 0 0 0 8.028-2.722l.885-.533Z"
    />
  </Svg>
);

export const StarOutlinedIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill="#00000000"
      fillRule="evenodd"
      stroke={props.fill || '#000'}
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.066 4.114c.862-1.485 3.006-1.485 3.868 0l2.638 4.547c.243.419.664.705 1.143.777l4.381.662c1.91.288 2.58 2.7 1.092 3.932l-3.192 2.645a1.597 1.597 0 0 0-.55 1.528l.894 4.696c.35 1.84-1.58 3.269-3.237 2.396l-4.359-2.295a1.597 1.597 0 0 0-1.488 0l-4.359 2.295c-1.657.873-3.588-.556-3.237-2.396l.894-4.696a1.597 1.597 0 0 0-.55-1.528l-3.192-2.645C2.324 12.8 2.994 10.388 4.904 10.1l4.381-.662c.48-.072.9-.358 1.143-.777l2.638-4.547Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const MenuIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M7.5 13.125a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75ZM13.125 15a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0ZM20.625 15a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Z"
    />
  </Svg>
);

export const CloseIcon = (props: SvgProps) => (
  <Svg
    width={props.width || 30}
    height={props.height || 30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M23.75 8.012 21.988 6.25 15 13.238 8.012 6.25 6.25 8.012 13.238 15 6.25 21.988l1.762 1.762L15 16.762l6.988 6.988 1.762-1.762L16.762 15l6.988-6.988Z"
    />
  </Svg>
);

export const EmailIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M9.112 6.134c3.918-.34 7.859-.34 11.777 0l1.888.165a3.61 3.61 0 0 1 2.89 1.93.294.294 0 0 1-.113.385l-7.833 4.849a5.313 5.313 0 0 1-5.498.057L4.338 8.86a.295.295 0 0 1-.129-.366 3.61 3.61 0 0 1 3.015-2.195l1.888-.165Z"
    />
    <Path
      fill={props.fill || '#000'}
      d="M4.203 10.959a.31.31 0 0 0-.467.234 37.94 37.94 0 0 0 .23 9.449 3.61 3.61 0 0 0 3.258 3.06l1.888.163c3.918.341 7.859.341 11.777 0l1.888-.164a3.61 3.61 0 0 0 3.257-3.06c.485-3.22.552-6.489.202-9.723a.31.31 0 0 0-.472-.229l-7.056 4.368a7.187 7.187 0 0 1-7.44.077l-7.065-4.175Z"
    />
  </Svg>
);

export const FacebookIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M17.75 3.594a5.781 5.781 0 0 0-5.781 5.781v3.219H8.875a.281.281 0 0 0-.281.281v4.25c0 .155.126.281.281.281h3.094v8.719c0 .155.126.281.281.281h4.25a.281.281 0 0 0 .281-.281v-8.719h3.121c.13 0 .242-.088.273-.213l1.063-4.25a.281.281 0 0 0-.273-.35H16.78V9.376a.969.969 0 0 1 .969-.969H21a.281.281 0 0 0 .281-.281v-4.25A.281.281 0 0 0 21 3.594h-3.25Z"
    />
  </Svg>
);

export const LinkedInIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      d="M5.938 2.344a2.656 2.656 0 1 0 0 5.312 2.656 2.656 0 0 0 0-5.312ZM3.438 9.844A.156.156 0 0 0 3.28 10v16.25c0 .086.07.156.156.156h5c.087 0 .157-.07.157-.156V10a.156.156 0 0 0-.156-.156h-5ZM11.563 9.844a.156.156 0 0 0-.157.156v16.25c0 .086.07.156.156.156h5c.087 0 .157-.07.157-.156V17.5a2.344 2.344 0 0 1 4.687 0v8.75c0 .086.07.156.157.156h5c.086 0 .156-.07.156-.156V15.475c0-3.033-2.639-5.406-5.657-5.132a8.96 8.96 0 0 0-2.71.684l-1.633.7V10a.156.156 0 0 0-.157-.156h-5Z"
    />
  </Svg>
);

export const CarOutlinedIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M23.65 6.263A1.868 1.868 0 0 0 21.875 5H8.125A1.88 1.88 0 0 0 6.35 6.263l-2.6 7.487v10c0 .688.563 1.25 1.25 1.25h1.25c.688 0 1.25-.563 1.25-1.25V22.5h15v1.25c0 .688.563 1.25 1.25 1.25H25c.688 0 1.25-.563 1.25-1.25v-10l-2.6-7.487ZM8.563 7.5h12.862l1.35 3.887H7.213L8.563 7.5ZM6.25 20h17.5v-6.25H6.25V20Zm3.125-5a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm9.375 1.875a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export const ListIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G id="icon/action/list_24px">
      <G id="Vector">
        <Path d="M3 13H5V11H3V13Z" fill={props.fill || '#000'} />
        <Path d="M3 17H5V15H3V17Z" fill={props.fill || '#000'} />
        <Path d="M3 9H5V7H3V9Z" fill={props.fill || '#000'} />
        <Path d="M7 13H21V11H7V13Z" fill={props.fill || '#000'} />
        <Path d="M7 17H21V15H7V17Z" fill={props.fill || '#000'} />
        <Path d="M7 7V9H21V7H7Z" fill={props.fill || '#000'} />
      </G>
    </G>
  </Svg>
);

export const InfoOutlinedIcon = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <Path
      fill={props.fill || '#000'}
      d="M15 27.5C8.096 27.5 2.5 21.904 2.5 15S8.096 2.5 15 2.5 27.5 8.096 27.5 15 21.904 27.5 15 27.5Zm0-2.5a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM13.75 8.75h2.5v2.5h-2.5v-2.5Zm0 5h2.5v7.5h-2.5v-7.5Z"
    />
  </Svg>
);

export const LightBulbIcon = (props: SvgProps) => (
  <Svg width={14} height={20} {...props}>
    <Path
      fill={props.fill || '#000'}
      fillRule="evenodd"
      d="M7 0C3.14 0 0 3.14 0 7c0 2.38 1.19 4.47 3 5.74V15c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7ZM4 19c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H4v1Zm5-7.3.85-.6A4.997 4.997 0 0 0 12 7c0-2.76-2.24-5-5-5S2 4.24 2 7c0 1.63.8 3.16 2.15 4.1l.85.6V14h4v-2.3Z"
      clipRule="evenodd"
    />
  </Svg>
);
