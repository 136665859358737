import { useState } from 'react';
import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { TransparentRow } from '../Row';
import { TextBodySmall, TextH3 } from '../StyledText';
import Tabs, { TabItem } from '../Tabs';
import { TransparentView, View, ViewProps } from '../Themed';

type SchedulesArray = {
  label: string;
  activities: {
    time: string;
    description: string;
  }[];
}[];

export default function DailySchedule({
  schedules,
  style,
}: {
  schedules: SchedulesArray;
  style?: ViewProps['style'];
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const hasMultipleSchedules = schedules.length > 1;
  const titleSuffix = hasMultipleSchedules ? 's' : '';

  const currentVisibleSchedule = schedules[activeIndex];

  const isMobileDevice = useMaxWidth(470);

  return (
    <View style={style}>
      <TextH3 style={{ marginBottom: !isMobileDevice ? 30 : 15 }}>
        Daily Schedule{titleSuffix}
      </TextH3>

      {hasMultipleSchedules ? (
        <SchedulesTabMenu
          schedules={schedules}
          onTabPress={setActiveIndex}
          active={activeIndex}
        />
      ) : null}

      {currentVisibleSchedule ? (
        <ScheduleTable activities={currentVisibleSchedule.activities} />
      ) : null}
    </View>
  );
}

function SchedulesTabMenu({
  schedules,
  active,
  onTabPress,
}: {
  schedules: SchedulesArray;
  active: number;
  onTabPress: (idx: number) => void;
}) {
  return (
    <Tabs
      active={active}
      onChange={onTabPress}
      style={styles.schedulesMenuContainer}
      innerStyles={{
        minWidth: '100%',
      }}
    >
      {schedules.map((s, idx) => (
        <TabItem key={idx}>{s.label}</TabItem>
      ))}
    </Tabs>
  );
}

function ScheduleTable({
  activities,
}: {
  activities: { time: string; description: string }[];
}) {
  const theme = useColorScheme();

  return (
    <TransparentView
      style={{
        borderWidth: 2,
        borderColor: Colors[theme].lines,
        borderRadius: 10,
      }}
    >
      <TransparentRow
        style={{
          borderBottomColor: Colors[theme].lines,
          borderBottomWidth: 1,
        }}
      >
        <TransparentView
          style={[
            styles.column,
            { borderRightWidth: 1, borderRightColor: Colors[theme].lines },
          ]}
        >
          <TextBodySmall>Time</TextBodySmall>
        </TransparentView>
        <TransparentView style={styles.columnWide}>
          <TextBodySmall>Activity</TextBodySmall>
        </TransparentView>
      </TransparentRow>
      {activities.map((scheduleItem, idx) => (
        <TransparentRow
          key={`sch-item-${idx}`}
          style={{
            borderBottomColor: Colors[theme].lines,
            borderBottomWidth: 1,
          }}
        >
          <TransparentView
            style={[
              styles.column,
              { borderRightWidth: 1, borderRightColor: Colors[theme].lines },
            ]}
          >
            <TextBodySmall bold>{scheduleItem.time}</TextBodySmall>
          </TransparentView>
          <TransparentView style={styles.columnWide}>
            <TextBodySmall style={{ color: Colors[theme].textSecondary }}>
              {scheduleItem.description}
            </TextBodySmall>
          </TransparentView>
        </TransparentRow>
      ))}
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  schedulesMenuContainer: { marginBottom: 15, minWidth: '100%' },
  column: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  columnWide: {
    flex: 2,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
});
