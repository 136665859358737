import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FlatList, Pressable, StyleSheet } from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { BottomSheetModalContainer } from './BottomSheetModal';
import { Icon } from './Icon';
import { TransparentRow } from './Row';
import { TextBodySmall } from './StyledText';
import { FormInput, FormInputProps } from './StyledTextInput';
import { StyleProps, TransparentView, View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import countries from '../utils/countries';

const Flag = React.lazy(() => import('./Flag'));

type CountryEntry = (typeof countries)[number];

const findCountryEntry = (value: string | undefined) => {
  if (!value) {
    return {
      prefix: countriesUsFirst[0]!,
    };
  }
  const countriesCopy = [...countries].sort(
    (a, b) => (b.areaCodes || []).length - (a.areaCodes || []).length
  );
  const possiblePrefixes = countriesCopy.map((e) => ({
    codes: (e.areaCodes || ['']).map((p) => `${e.dialCode}${p}`),
    iso: e.iso2,
    dial: e.dialCode,
  }));

  const numberPrefix = possiblePrefixes.find((prefix) =>
    prefix.codes.some((areaCode) => value.startsWith(areaCode))
  );

  return {
    prefix: numberPrefix
      ? countries.find((c) => c.iso2 === numberPrefix.iso)!
      : countriesUsFirst[0]!,
    value: numberPrefix ? value.replace(`${numberPrefix.dial}`, '') : value,
  };
};

export function PhoneNumberInput({
  value,
  onChangeText,
  ...restProps
}: FormInputProps) {
  const isSmallDevice = useMaxWidth(500);
  const [numberValue, setNumberValue] = useState(
    findCountryEntry(value).value || value
  );
  const [prefix, setPrefix] = useState(findCountryEntry(value).prefix);
  useEffect(() => {
    if (value === `${prefix.dialCode}${numberValue}`) {
      return;
    }
    onChangeText?.(`${prefix.dialCode}${numberValue}`);
  }, [onChangeText, prefix, numberValue, value]);

  const PhonePrefixChooser = isSmallDevice
    ? PhonePrefixChooserMobile
    : PhonePrefixChooserDesktop;

  const onNumberUpdate = useCallback((value: string) => {
    const filteredValue = value.replaceAll(/[^0-9]/gm, '');
    setNumberValue(filteredValue);
  }, []);

  return (
    <FormInput
      leftComponent={
        <PhonePrefixChooser
          prefix={prefix}
          onChange={setPrefix}
          valuePresent={(numberValue || '').length > 0}
          disabled={restProps.disabled}
        />
      }
      {...restProps}
      disabled={restProps.disabled}
      value={numberValue}
      onChangeText={onNumberUpdate}
      keyboardType="numeric"
      textContentType="telephoneNumber"
    />
  );
}

const usFilter = (c: { iso2: string }) => c.iso2 === 'us';
const countriesUsFirst = [
  countries.find(usFilter)!,
  ...countries.filter((c) => !usFilter(c)),
];

type PhonePrefixChooserProps = {
  prefix: CountryEntry;
  onChange: (value: CountryEntry) => void;
  valuePresent: boolean;
  disabled?: boolean;
};

function PhonePrefixButton({
  prefix,
  valuePresent,
}: Omit<PhonePrefixChooserProps, 'onChange'>) {
  return (
    <TransparentView
      style={[
        {
          paddingTop: valuePresent ? 5 : 0,
          flexDirection: 'row',
          alignItems: 'center',
          // @ts-ignore
          cursor: 'pointer',
        },
      ]}
    >
      <TextBodySmall style={{ fontSize: 14 }}>
        +{prefix?.dialCode}
      </TextBodySmall>
      <Icon name="arrowDown" style={{ width: 15, height: 15 }} />
    </TransparentView>
  );
}

function PhonePrefixChooserMobile({
  prefix,
  onChange,
  valuePresent,
  disabled,
}: PhonePrefixChooserProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Pressable disabled={disabled} onPress={() => setIsOpen(true)}>
        <PhonePrefixButton prefix={prefix} valuePresent={valuePresent} />
      </Pressable>
      <BottomSheetModalContainer
        onClose={() => setIsOpen(false)}
        isVisible={isOpen}
      >
        <View
          style={{ height: '100%', overflow: 'scroll' }}
          ref={(el) => {
            const container = el as unknown as HTMLDivElement | null;
            if (!container) {
              return;
            }
            // requestAnimationFrame to fix scroll in next tick
            requestAnimationFrame(() => {
              // console.log('fixing scroll?')
              container.scrollTop =
                44 * (countriesUsFirst.findIndex((e) => e === prefix) - 2);
            });
          }}
        >
          <FlatList
            data={countriesUsFirst}
            keyExtractor={(item) => item.iso2}
            renderItem={({ item }) => (
              <PhonePrefixItem
                dialCode={item.dialCode}
                name={item.name}
                key={item.iso2}
                iso2={item.iso2.toUpperCase()}
                onPress={() => {
                  setIsOpen(false);
                  onChange(item);
                }}
                active={item === prefix}
                style={{ height: 44 }}
              />
            )}
          />
        </View>
      </BottomSheetModalContainer>
    </>
  );
}

function PhonePrefixChooserDesktop({
  prefix,
  onChange,
  valuePresent,
  disabled,
}: PhonePrefixChooserProps) {
  const theme = useColorScheme();
  const popupRef = useRef<PopupActions>(null);
  useEffect(() => {
    popupRef.current?.close();
  }, [prefix]);

  // calling as function here to reuse TransparentView forwardRef
  const Trigger = PhonePrefixButton({ prefix, valuePresent });
  return (
    <Popup
      ref={popupRef}
      contentStyle={{ zIndex: 10000 }}
      trigger={Trigger}
      disabled={disabled}
      position="bottom left"
      offsetX={-10}
    >
      <View
        style={{
          width: 400,
          height: 200,
          borderWidth: 1,
          borderRadius: 10,
          borderColor: Colors[theme].lines,
          overflow: 'scroll',
        }}
        ref={(el) => {
          const container = el as unknown as HTMLDivElement | null;
          if (!container) {
            return;
          }
          // requestAnimationFrame to fix scroll in next tick
          requestAnimationFrame(() => {
            container.scrollTop =
              styles.phonePrefixChoice.height *
              (countriesUsFirst.findIndex((e) => e === prefix) - 2);
          });
        }}
      >
        <FlatList
          data={countriesUsFirst}
          keyExtractor={(item) => item.iso2}
          renderItem={({ item }) => (
            <PhonePrefixItem
              dialCode={item.dialCode}
              name={item.name}
              key={item.iso2}
              iso2={item.iso2.toUpperCase()}
              onPress={() => onChange(item)}
              active={item === prefix}
            />
          )}
        />
      </View>
    </Popup>
  );
}
function PhonePrefixItem({
  dialCode,
  iso2,
  name,
  onPress,
  active,
  style,
}: {
  dialCode: string;
  iso2: string;
  name: string;
  onPress: () => void;
  active: boolean;
  style?: StyleProps['style'];
}) {
  const theme = useColorScheme();
  const [hover, setHover] = useState(false);

  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.phonePrefixChoice,
        (active || hover) && { backgroundColor: '#eee' },
        style,
      ]}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <TransparentRow style={{ flex: 1, gap: 10 }}>
        <TextBodySmall
          style={{
            color: Colors[theme].textSecondary,
            flexBasis: 'auto',
            width: 50,
            textAlign: 'right',
          }}
        >
          +{dialCode}
        </TextBodySmall>
        <TextBodySmall style={{ flex: 1 }}>{name}</TextBodySmall>
      </TransparentRow>
      <Suspense>
        <Flag code={iso2} />
      </Suspense>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  phonePrefixChoice: {
    height: 30,
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
