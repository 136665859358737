import { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { StyleSheet, Platform } from 'react-native';

import { failBooking } from '../api/bookings';
import { TextButton } from '../components/Button';
import { Footer } from '../components/Footer';
import GtmPurchase from '../components/GtmPurchase';
import { Icon } from '../components/Icon';
import {
  AvochatoIcon,
  Container,
  ExpandedMenu,
  Header,
  InnerContainer,
  MenuElement,
} from '../components/Layout';
import { BookingSection } from '../components/LocationSection';
import { LogoCare } from '../components/Logo';
import PeriodicalMessageBar from '../components/PeriodicalMessageBar';
import { TransparentRow } from '../components/Row';
import { TextBodySmall, TextH3 } from '../components/StyledText';
import TextWithLinks from '../components/TextWithLinks';
import { TransparentView, View } from '../components/Themed';
import { ThreeParamsSearchHeader } from '../components/ThreeParamSearch';
import UpcomingBooking from '../components/UpcomingBooking';
import BookAgain from '../components/explore/BookAgain';
import ComingSoon from '../components/explore/ComingSoon';
import Content, { TopLocations } from '../components/explore/Content';
import GetStarted from '../components/explore/GetStarted';
import HowItWorks from '../components/explore/HowItWorks';
import HowItWorksFor from '../components/explore/HowItWorksFor';
import IntroSection from '../components/explore/IntroSection';
import Logos from '../components/explore/Logos';
import MarketingBanner from '../components/explore/MarketingBanner';
import MoreAmazingCare from '../components/explore/MoreAmazingCareIn';
import NewlyAdded from '../components/explore/NewlyAdded';
import OurTopPicksForYou from '../components/explore/OurTopPicksForYou';
import Testimonials from '../components/explore/Testimonials';
import HeaderContainer from '../components/layouts/HeaderContainer';
import { SearchIcon } from '../components/search/SearchIcon';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import { useAuthenticate, useIsAuthEstablished } from '../contexts/authFlow';
import { useAuthState, useIsLoggedIn } from '../contexts/authentication';
import { shouldShowPeriodicMessageBar } from '../contexts/campaigns';
import { useHdyHau } from '../contexts/hdyhau';
import { useSnackbarDispatch } from '../contexts/snackbar';
import useAppNavigation from '../hooks/useAppNavigation';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';
import { getBumoCreditAppliedSnackbar } from '../snackbars';
import { useUpcomingBookings } from '../store/bookings';
import { RootStackScreenProps } from '../types';
import { formatPrice } from '../utils/locations';

const codeMap = {
  '10': (navigate: (value: string) => void, sessionId?: string) => ({
    message: (
      <>
        <TextWithLinks
          text='Your booking is being processed. View details "here|0".'
          links={['Bookings']}
          clickFn={navigate}
          textComponent={TextBodySmall}
          linkComponent={TextButton}
        />
        {FeatureFlags.GTM && sessionId && <GtmPurchase sessionId={sessionId} />}
      </>
    ),
  }),
  '11': (_: (value: string) => void, sessionId?: string) => {
    if (!sessionId) {
      throw new Error(
        'Missing session Id on failed payment. This should not happen.'
      );
    }
    failBooking(sessionId);
    return null;
  },
  '20': (
    navigate: (value: string) => void,
    sessionId?: string,
    reward?: string
  ) => ({
    message: (
      <TextBodySmall style={{ textAlign: 'center' }}>
        Yay! You'll get an email confirmation and your recipient will get gift
        card details too.
        {reward ? (
          <>
            {' '}
            You also just received {formatPrice(Number(reward))} in{' '}
            <TextButton onPress={() => navigate('Profile')}>
              BumoCredit
            </TextButton>
            !
          </>
        ) : null}
      </TextBodySmall>
    ),
  }),
} as const;

export default function Explore({
  route,
  noContent,
}: RootStackScreenProps<'Explore'> & { noContent?: boolean }) {
  const dispatchSnackbar = useSnackbarDispatch();
  const isLoggedIn = useIsLoggedIn();
  const { parent } = useAuthState();

  const authenticate = useAuthenticate();
  const isAuthEstablished = useIsAuthEstablished();

  const {
    code,
    sessionId,
    reward,
    credit_token: creditToken,
  } = route.params || {};
  const { navigate, setParams } = useAppNavigation();
  const tryHdyHau = useHdyHau();

  useEffect(() => {
    if (!isAuthEstablished) {
      return;
    }

    if (code === '10') {
      if (parent && !parent.submittedHdyhau) {
        tryHdyHau(sessionId);
      }
    }

    if (code) {
      if (code === '30') {
        if (parent && reward) {
          dispatchSnackbar({
            message: getBumoCreditAppliedSnackbar(Number(reward)),
          });
        } else if (!parent && creditToken) {
          dispatchSnackbar({
            message: (
              <TransparentRow style={{ width: '100%' }}>
                <Icon name="explore" style={{ marginRight: 10 }} />
                <TextBodySmall>
                  Yay!{' '}
                  <TextButton
                    TextComponent={TextBodySmall}
                    onPress={() =>
                      authenticate(undefined, {
                        source: 'BUMO_PACKAGE_BUY',
                        creditToken,
                      })
                    }
                    testID="open-auth-modal"
                  >
                    Log in/Create an account now
                  </TextButton>{' '}
                  and apply the BumoCredit you just purchased. We've also sent a
                  confirmation email on how to do this.
                </TextBodySmall>
              </TransparentRow>
            ),
          });
        }
      } else {
        const fn = codeMap[code];
        fn && dispatchSnackbar(fn(navigate, sessionId, reward));
      }
      setParams({
        code: undefined,
        reward: undefined,
        sessionId: undefined,
        session_id: undefined,
        credit_token: undefined,
      });
    }
  }, [isAuthEstablished, parent, code, sessionId, reward, creditToken]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://book.bumo.com/find-care" />
      </Helmet>
      {!isAuthEstablished || noContent ? null : isLoggedIn ? (
        <LoggedInContent />
      ) : (
        <LoggedOutContent />
      )}
    </>
  );
}

function HomepageHeader() {
  const scroll = useScrollInfo();
  const isSmallDevice = useMaxWidth(1024);

  return (
    <HeaderContainer shadow={scroll}>
      <InnerContainer>
        <TransparentRow>
          <TransparentView style={{ flex: 1 }}>
            <LogoCare />
          </TransparentView>
          <TransparentRow>
            {isSmallDevice ? (
              <>
                <AvochatoIcon style={{ marginRight: 10 }} />
                <SearchIcon style={{ marginRight: 10 }} />
                <MenuElement />
              </>
            ) : (
              <ExpandedMenu />
            )}
          </TransparentRow>
        </TransparentRow>
      </InnerContainer>
    </HeaderContainer>
  );
}

// hooks
function useScrollInfo() {
  const [scroll, setScroll] = useState(false);
  const handleScroll = useCallback(() => {
    if (!scroll && document.documentElement.scrollTop > 0) {
      setScroll(true);
    } else if (scroll && document.documentElement.scrollTop === 0) {
      setScroll(false);
    }
  }, [scroll, setScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return scroll;
}

function LoggedOutContent() {
  const shouldDisplayTPSHeader = useMinWidth(900);

  return (
    <>
      {shouldDisplayTPSHeader ? (
        <ThreeParamsSearchHeader />
      ) : (
        <HomepageHeader />
      )}
      <Container>
        <View>
          <InnerContainer style={styles.introInnerContainer}>
            <IntroSection />
          </InnerContainer>
        </View>
        {shouldShowPeriodicMessageBar() ? <PeriodicalMessageBar /> : <Logos />}
        <InnerContainer>
          <Content />
        </InnerContainer>
        <HowItWorks />
        <GetStarted />
        <Testimonials />
        <HowItWorksFor />
        <MarketingBanner />
        <MoreAmazingCare />
        <Footer />
      </Container>
    </>
  );
}

function LoggedInContent() {
  const theme = useColorScheme();
  const { navigate } = useAppNavigation();
  const { firstName } = useParent();

  const scroll = useScrollInfo();
  const bookings = useUpcomingBookings();

  const shouldDisplayTPSHeader = useMinWidth(900);
  const isMobileDevice = useMaxWidth(900);

  return (
    <>
      {shouldDisplayTPSHeader ? (
        <ThreeParamsSearchHeader />
      ) : (
        <Header
          shadow={scroll}
          style={[{ backgroundColor: Colors[theme].background }]}
        />
      )}
      <Container>
        <InnerContainer>
          <TransparentView
            style={[
              {
                alignItems: 'center',
              },
              FeatureFlags.NEW_LOGGED_IN_LANDING && !bookings.length
                ? {
                    paddingVertical: 50,
                  }
                : { paddingTop: 50 },
              !shouldDisplayTPSHeader && {
                paddingBottom: 20,
                paddingTop: 20,
              },
            ]}
          >
            <TextH3>Welcome, {firstName}!</TextH3>
          </TransparentView>
          {bookings.length ? (
            <BookingSection
              title="Upcoming Bookings"
              Block={UpcomingBooking}
              bookings={bookings}
              onPress={() => navigate('Bookings', {})}
              style={styles.fullWidth}
            />
          ) : null}
        </InnerContainer>
        {FeatureFlags.NEW_LOGGED_IN_LANDING ? (
          <>
            <BookAgain />
            <InnerContainer>
              <TopPicksWithFallback />
            </InnerContainer>
            <MarketingBanner
              style={{ marginBottom: isMobileDevice ? 30 : 80 }}
            />
            <InnerContainer>
              <NewlyAdded />
              <ComingSoon />
            </InnerContainer>
          </>
        ) : (
          <InnerContainer>
            <Content />
          </InnerContainer>
        )}
        <Footer />
      </Container>
    </>
  );
}

function TopPicksWithFallback() {
  const [fallback, setFallback] = useState(false);

  if (fallback) {
    return <TopLocations />;
  }
  return <OurTopPicksForYou onEmptyLocations={() => setFallback(true)} />;
}

const styles = StyleSheet.create({
  searchInput: {
    marginTop: -30,
    marginBottom: 15,
  },
  searchInputWidth: {
    width: '100%',
    maxWidth: 500,
  },
  fullWidth: {
    width: '100%',
  },
  intro: {
    alignItems: 'center',
    ...(Layout.isLargeDevice && {
      height: 235,
      paddingTop: 20,
      paddingHorizontal: 30,
    }),
    ...(Layout.isMobileDevice && {
      paddingTop: 15,
    }),
  },
  introInnerContainer: { maxWidth: 1440, paddingHorizontal: 0 },
  welcome: {
    backgroundColor: 'transparent',

    ...(Layout.isLargeDevice && {
      alignItems: 'center',
    }),
    ...(Layout.isMobileDevice && {
      width: 250,
    }),
  },
  inner: {
    paddingBottom: FeatureFlags.SEARCH ? 35 : 15,
  },
  spring: {
    position: 'absolute',
    width: 76,
    height: 221,
    left: '1%',
    top: -60,
    ...Platform.select({
      web: {
        transform: [
          {
            rotate: '126.69deg',
          },
        ],
      },
    }),
  },
  star: {
    position: 'absolute',
    transform: [
      {
        rotate: '-4.29deg',
      },
    ],
    ...(Layout.isLargeDevice && {
      width: 203,
      height: 200,
      right: '10%',
      bottom: -55,
    }),
    ...(Layout.isMobileDevice && {
      width: 143,
      height: 142,
      top: -20,
      right: -70,
    }),
  },
});
