import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { useAuthenticate } from '../../contexts/authFlow';
import { useAuthState } from '../../contexts/authentication';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { PrimaryButton } from '../Button';

export default function StartFreeAccountButton() {
  const theme = useColorScheme();
  const { isLoggedIn } = useAuthState();
  const authenticate = useAuthenticate();

  const isMobileDevice = useMaxWidth(430);
  return !isLoggedIn ? (
    <PrimaryButton
      title="Start Free Account"
      onPress={() => authenticate()}
      style={[
        { backgroundColor: Colors[theme].accent },
        styles.joinButton,
        isMobileDevice && styles.joinButtonMobile,
      ]}
    />
  ) : null;
}

const styles = StyleSheet.create({
  joinButton: {
    alignSelf: 'center',
    marginBottom: 60,
  },
  joinButtonMobile: {
    marginBottom: 30,
  },
});
