import { API_V1_URL } from './config';

const PASSWORDS_V1_URL = `${API_V1_URL}passwords/`;

interface ForgotPayload {
  email: string;
}

export async function postForgotPassword({ email }: ForgotPayload) {
  const requestInfo = {
    method: 'POST',
    credentials: 'include' as const,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  };
  const response = await fetch(`${PASSWORDS_V1_URL}forgot`, requestInfo);
  return await response.json();
}

interface ResetPayload {
  token: string;
  password: string;
  passwordConfirmation: string;
}

export async function postResetPassword({
  token,
  password,
  passwordConfirmation,
}: ResetPayload) {
  const requestInfo = {
    method: 'POST',
    credentials: 'include' as const,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: token,
    }),
  };
  const response = await fetch(`${PASSWORDS_V1_URL}reset`, requestInfo);
  return await response.json();
}
