import React from 'react';

import { MobileCarouselContainer, NumberItem } from '../BumoInNumbers';
import { TextH3 } from '../StyledText';
import { StyleProps } from '../Themed';

export default function FSAInfo({
  icons,
  ItemsWrapper = MobileCarouselContainer,
  numberItemStyle,
  titleStyle,
}: {
  icons: {
    FSAValue: React.ComponentType<{ style: { width: number; height: number } }>;
    FSAPayments: React.ComponentType<{
      style: { width: number; height: number };
    }>;
    FSAEasy: React.ComponentType<{ style: { width: number; height: number } }>;
  };
  ItemsWrapper?: React.ComponentType<{ children: React.ReactNode }>;
  numberItemStyle?: StyleProps['style'];
  titleStyle?: StyleProps['style'];
}) {
  return (
    <>
      <TextH3 style={[{ textAlign: 'center', marginBottom: 30 }, titleStyle]}>
        Use BumoCredit for your Dependent Care Flexible Spending Account (FSA)
      </TextH3>
      <ItemsWrapper>
        <NumberItem
          primaryText={'Savings'}
          primaryTextComponent={TextH3}
          secondaryText={null}
          contentText={
            'With BumoCredit, families get up to $2000 in additional child care credit, providing financial relief.'
          }
          style={numberItemStyle}
        >
          <icons.FSAValue style={{ width: 80, height: 80 }} />
        </NumberItem>

        <NumberItem
          primaryText={'Convenient Payments'}
          primaryTextComponent={TextH3}
          secondaryText={null}
          contentText={
            'Easily pay for daycare, preschool, summer camps, and more using pre-tax dollars.'
          }
        >
          <icons.FSAPayments style={{ width: 80, height: 80 }} />
        </NumberItem>

        <NumberItem
          primaryText={'Maximize Benefits'}
          primaryTextComponent={TextH3}
          secondaryText={null}
          contentText={
            "Make the most of your Dependent Care FSA by utilizing Bumo's seamless reimbursement process."
          }
        >
          <icons.FSAEasy style={{ width: 95, height: 80 }} />
        </NumberItem>
      </ItemsWrapper>
    </>
  );
}
