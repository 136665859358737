import { API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultRequest,
  defaultAuthedRequest,
} from './headers';
import { LocationReviews, Review } from '../types/Reviews';

export async function getReviewsForLocation(locationId: number): Promise<
  | {
      ok: true;
      reviews: LocationReviews;
    }
  | {
      ok: false;
    }
> {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  try {
    const response = await fetch(
      `${API_V1_URL}reviews/?location_id=${locationId}`,
      request
    );
    if (response.ok) {
      const body = (await response.json()) as {
        summary: {
          count: number;
          rating: number;
          features: FeatureRatings;
        };
        reviews: ReviewResponseObject[];
      };
      return {
        ok: true,
        reviews: {
          summary: {
            ...body.summary,
            rating: Math.floor(body.summary.rating * 100) / 100,
          },
          reviews: parseReviews(body.reviews),
        },
      };
    }
    return {
      ok: false,
    };
  } catch (err) {
    console.error(err);
    return {
      ok: false,
    };
  }
}

type ReviewResponseObject = {
  author_id: number;
  author_name: string;
  features: Partial<{
    cleanliness: number;
    curriculum: number;
    caregivers: number;
    professionalism: number;
    indoor: number;
    outdoor: number;
  }>;
  id: number;
  location_id: number;
  rating: 0;
  text: string;
  updated_at: string;
  location?: { name: string };
};

function parseReview({
  author_name,
  author_id,
  ...reviewProps
}: ReviewResponseObject): Review {
  return {
    author: {
      id: author_id,
      initial: author_name[0] || 'A',
      name: author_name,
    },
    ...reviewProps,
  };
}

function parseReviews(arr: ReviewResponseObject[]) {
  return arr.map((el) => parseReview(el));
}

export type FeatureRatings = {
  cleanliness: number;
  curriculum: number;
  caregivers: number;
  professionalism: number;
  indoor: number;
  outdoor: number;
};

export const addLocationReview = async (
  locationId: number,
  reviewBody: {
    features?: FeatureRatings;
    text?: string;
  }
) => {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      ...reviewBody,
      rating: 1,
      location_id: locationId,
    }),
  };
  const response = await fetch(`${API_V1_URL}/reviews`, requestInfo);

  if (response.ok) {
    return {
      ok: true,
      body: await response.json(),
    };
  }

  return {
    ok: false,
    status: response.status,
    body: await response.json(),
  };
};

export const updateReview = async (
  reviewId: number,
  reviewBody: {
    features?: FeatureRatings;
    text?: string;
  }
): Promise<
  | { ok: true; review: Review }
  | {
      ok: false;
      error: string;
    }
> => {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PUT',
    body: JSON.stringify(reviewBody),
  };
  const response = await fetch(`${API_V1_URL}reviews/${reviewId}`, requestInfo);

  if (response.ok) {
    return {
      ok: true,
      review: parseReview(await response.json()),
    };
  }

  return {
    ok: false,
    error: JSON.stringify(await response.json()),
  };
};

export const getMyReviews = async () => {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${API_V1_URL}reviews/my`, requestInfo);

  if (response.ok) {
    return {
      ok: true,
      body: parseReviews((await response.json()) as ReviewResponseObject[]),
    };
  }

  return {
    ok: false,
    status: response.status,
    body: await response.json(),
  };
};

export const deleteReview = async (id: number) => {
  const requestInfo = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'DELETE',
  };
  const response = await fetch(`${API_V1_URL}reviews/${id}`, requestInfo);

  if (response.ok) {
    return { ok: true };
  }

  return { ok: false };
};
