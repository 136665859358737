import { ImageBackground } from 'react-native';

import { Image } from './Image';
import { TransparentRow } from './Row';
import { TextCtaButton } from './StyledText';
import { TransparentView } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { CdnImageName, getImageUrl } from '../utils/common';

const wynItems: { imageFilename: CdnImageName; title: string }[] = [
  {
    imageFilename: 'paid-ad-1.webp',
    title: 'Work from Office',
  },
  {
    imageFilename: 'paid-ad-2.webp',
    title: 'School Closures',
  },
  {
    imageFilename: 'paid-ad-3.webp',
    title: 'Nanny calls in sick',
  },
  {
    imageFilename: 'paid-ad-4.webp',
    title: 'Business Trips',
  },
  {
    imageFilename: 'paid-ad-5.webp',
    title: 'Running Errands',
  },
  {
    imageFilename: 'paid-ad-6.webp',
    title: 'Grandparents on vacation',
  },
];

export default function WhyYouNeedBumo() {
  const isMobileDevice = useMaxWidth(560);
  const isTwoColumnsActive = useMaxWidth(900);
  const theme = useColorScheme();
  return (
    <TransparentRow
      style={{
        position: 'relative',
        flexWrap: 'wrap',
        rowGap: 24,
        paddingVertical: 24,
        alignItems: 'flex-start',
        paddingBottom: 80,
      }}
    >
      {wynItems.map((el, idx) => (
        <TransparentView
          key={`care-${idx}`}
          style={{
            flexGrow: 1,
            width: isTwoColumnsActive ? '50%' : '33.3%',
            alignItems: 'center',
          }}
        >
          <ImageBackground
            source={{ uri: getImageUrl(el.imageFilename) }}
            style={{
              width: isMobileDevice ? 150 : 250,
              height: isMobileDevice ? 150 : 250,
              backgroundColor: '#eee',
              borderRadius: 125,
            }}
          />
          <TextCtaButton style={{ marginTop: 24, textAlign: 'center' }}>
            {el.title}
          </TextCtaButton>
        </TransparentView>
      ))}
      <Image
        source={require('../assets/images/thread.png')}
        style={{
          width: 189,
          height: 220,
          position: 'absolute',
          bottom: -130,
          left: -100,
          tintColor: Colors[theme].accent,
          transform: [{ rotate: '24.688deg' }],
        }}
      />

      <Image
        source={require('../assets/images/spring2.png')}
        style={{
          width: 259,
          height: 306,
          position: 'absolute',
          bottom: -160,
          right: -170,
          tintColor: Colors[theme].accentSecondary,
          transform: [{ rotate: '-27.688deg' }],
        }}
      />
    </TransparentRow>
  );
}
