import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { LocationReviews } from '../../types/Reviews';
import { pluralize } from '../../utils/common';
import { formatRating } from '../../utils/locations';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextBodySmall, TextH2 } from '../StyledText';

export default function ReviewsSummary({
  reviews,
  centered,
  showAllPress,
}: {
  reviews?: LocationReviews | null;
  centered?: boolean;
  showAllPress?: () => void;
}) {
  const theme = useColorScheme();
  if (!reviews) {
    return null;
  }
  return (
    <TransparentRow
      style={[styles.reviewsSummary, centered && styles.reviewsSummaryCentered]}
    >
      <TransparentRow>
        <Icon name="star" color={Colors[theme].accent} />
        <TextH2 style={{ marginHorizontal: 8 }}>
          {formatRating(reviews.summary.rating)}
        </TextH2>
      </TransparentRow>
      <TextBodySmall
        style={{
          marginLeft: 8,
          fontSize: 16,
          lineHeight: 20,
          color: Colors[theme].textSecondary,
          textDecorationLine: showAllPress ? 'underline' : 'none',
        }}
        onPress={showAllPress}
      >
        {reviews.reviews.length} {pluralize(reviews.reviews.length, 'review')}
      </TextBodySmall>
    </TransparentRow>
  );
}
const styles = StyleSheet.create({
  reviewsSummary: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  reviewsSummaryCentered: {
    justifyContent: 'center',
  },
});
