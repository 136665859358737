import { StyleSheet } from 'react-native';

import { Icon, IconName, IconProps } from './Icon';
import Row from './Row';
import { TextBodySmall } from './StyledText';
import { StyleProps, View } from './Themed';

type StatusProps = StyleProps & {
  text: string;
  icon: IconName;
  backgroundStyle?: StyleProps['style'];
  iconProps?: Partial<IconProps>;
};

export function Status({
  style,
  icon,
  text,
  backgroundStyle,
  iconProps = {},
}: StatusProps) {
  return (
    <Row style={style}>
      <View style={[styles.iconBackground, backgroundStyle]}>
        <Icon style={styles.icon} name={icon} {...iconProps} />
      </View>
      <TextBodySmall style={styles.text}>{text}</TextBodySmall>
    </Row>
  );
}

const styles = StyleSheet.create({
  iconBackground: {
    borderRadius: 10,
    marginRight: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  text: {
    flex: 1,
  },
});
