import { API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultGetRequest,
  defaultAuthedRequest,
} from './headers';

interface BumoCreditPackagesResponse {
  packages: {
    name: string;
    stripe_price_id: string;
    price: number;
    original_price?: number;
    amount_received: number;
    badge?: 'popular' | 'best-deal';
  }[];
}

export interface BumoCreditPackage {
  name: string;
  price: number;
  originalPrice: number | null;
  receiveAmount: number;
  link: string;
  badge?: 'popular' | 'best-deal';
}

const BUMO_CREDITS_API_URL = `${API_V1_URL}bumo_credits`;

export async function getBumoCreditsPackages(): Promise<BumoCreditPackage[]> {
  const request = {
    ...defaultGetRequest,
  };
  const response = await fetch(`${BUMO_CREDITS_API_URL}/settings`, request);
  const body = (await response.json()) as BumoCreditPackagesResponse;
  return parseBumoCreditSettingsPackages(body.packages);
}

function parseBumoCreditSettingsPackages(
  packages: BumoCreditPackagesResponse['packages']
): BumoCreditPackage[] {
  return packages.map((p) => {
    const {
      name,
      price,
      amount_received: receiveAmount,
      original_price,
      stripe_price_id,
      badge,
    } = p;
    return {
      name,
      price,
      originalPrice:
        original_price && original_price !== price ? original_price : null,
      link: stripe_price_id,
      receiveAmount,
      badge,
    };
  });
}

interface BumoCreditBuyResponse {
  url: string;
}

interface BumoCreditErrorResponse {
  error: string;
}

export async function buyPackage(
  packageLinkId: string,
  source: 'landing' | 'list'
) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
  };

  const response = await fetch(
    `${BUMO_CREDITS_API_URL}/buy?price_id=${packageLinkId}&source_page=${
      source === 'landing' ? 'bumo-credit' : 'bumo-credit/packages'
    }`,
    request
  );
  const body = (await response.json()) as
    | BumoCreditBuyResponse
    | BumoCreditErrorResponse;

  if ('url' in body) {
    return { ok: true as const, url: body.url };
  }

  return {
    ok: false as const,
    error: body.error,
  };
}

interface BumoCreditApplyResponse {
  balance: string;
  reward: number;
}

export async function applyCreditToken(token: string) {
  const request: RequestInit = {
    ...defaultGetRequest,
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      credit_token: token,
    }),
  };
  const url = `${BUMO_CREDITS_API_URL}/apply`;

  const response = await fetch(url, request);

  const body = (await response.json()) as
    | BumoCreditApplyResponse
    | BumoCreditErrorResponse;

  const getCode = (body: BumoCreditErrorResponse) =>
    body.error === 'Already applied'
      ? ('ALREADY_APPLIED' as const)
      : ('UNKNOWN' as const);

  if ('error' in body) {
    return { ok: false as const, code: getCode(body), body };
  }

  return {
    ok: true as const,
    body: {
      balance: parseFloat(body.balance),
      reward: body.reward,
    },
  };
}
