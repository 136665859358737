import React, { useState } from 'react';
import { DimensionValue, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import SmallRays from '../assets/images/small-rays.svg';
import BumoInNumbers from '../components/BumoInNumbers';
import { PrimaryButton } from '../components/Button';
import { Footer } from '../components/Footer';
import { Icon } from '../components/Icon';
import { Container, InnerContainer } from '../components/Layout';
import ModalCloseButton from '../components/ModalCloseButton';
import { TransparentRow } from '../components/Row';
import {
  QuincyRegularText,
  TextBody,
  TextFinePrint,
  TextH1,
  TextH2,
} from '../components/StyledText';
import { StyleProps, TransparentView, View } from '../components/Themed';
import WhyYouNeedBumo from '../components/WhyYouNeedBumo';
import { TopLocations } from '../components/explore/Content';
import GetStarted from '../components/explore/GetStarted';
import { HeroContent } from '../components/explore/HeroWithImage';
import HowItWorks from '../components/explore/HowItWorks';
import Logos from '../components/explore/Logos';
import Testimonials from '../components/explore/Testimonials';
import CommonHeader from '../components/layouts/CommonHeader';
import Colors from '../constants/Colors';
import FeatureFlags from '../constants/FeatureFlags';
import { useAuthenticate } from '../contexts/authFlow';
import { useIsLoggedIn } from '../contexts/authentication';
import useAppNavigation from '../hooks/useAppNavigation';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';

export default function StartFreeAccountPage() {
  const theme = useColorScheme();
  return (
    <>
      <CommonHeader />
      <Container>
        <InnerContainer style={{ maxWidth: 1440, paddingHorizontal: 0 }}>
          <TransparentRow>
            <HeroContent.Text wrapperStyle={{ justifyContent: 'center' }}>
              <TextH1 style={{ marginBottom: 10 }}>
                {FeatureFlags.FLEXING ? 'The Most Flexible' : 'Book Trusted'}{' '}
                <br />
                Child Care <br />
                When You Need It
              </TextH1>
              <QuincyRegularText
                style={{
                  fontSize: 25,
                  marginBottom: 25,
                  maxWidth: 500,
                  marginTop: 10,
                }}
              >
                Reliable, trusted, on-demand child care at your fingertips.
                Start a free account and get $50 in Child Care Credit!*
              </QuincyRegularText>

              <CTAGetCreditSection
                buttonColor={Colors[theme].cta}
                style={{ alignItems: 'center', alignSelf: 'flex-start' }}
              />
            </HeroContent.Text>
            <HeroContent.Image imageFileName="hero-image-sfa.webp" />
          </TransparentRow>
        </InnerContainer>
        <Logos />
        <InnerContainer style={{ paddingTop: 40 }}>
          <SmallRaysTop />

          <TransparentView
            style={{ alignItems: 'center', paddingVertical: 40 }}
          >
            <TextH2>Why you need Bumo</TextH2>
            <QuincyRegularText
              style={{
                fontSize: 25,
                maxWidth: 1000,
                textAlign: 'center',
                marginTop: 8,
              }}
            >
              The modern, working parent needs both fixed and on-demand care for
              peace of mind. If you've ever found yourself scrambling for care,
              we see you and we're here for you.
            </QuincyRegularText>

            <TransparentRow
              style={{
                marginTop: 8,
              }}
            >
              <HeartIcon />
              <HeartIcon />
              <HeartIcon />
            </TransparentRow>
          </TransparentView>

          <WhyYouNeedBumo />
          <SmallRaysBottom />
          <CTAGetCreditSection />
        </InnerContainer>
        <InnerContainer>
          <TopLocations limit={8} />
          <CTAGetCreditSection />
          <BumoInNumbers />
        </InnerContainer>
        <HowItWorks
          style={{ backgroundColor: Colors[theme].accentSecondary }}
        />
        <Testimonials
          endAdornment={<CTAGetCreditSection buttonColor={Colors[theme].cta} />}
        />
        <GetStarted
          headerText="Get Started Today!"
          contentText="When you start a free account with us, you get $50 in Child Care Credit that you can use to book child care right away. "
          buttonContent={
            <CTAGetCreditSection
              buttonColor={Colors[theme].cta}
              style={{ marginTop: 40 }}
            />
          }
        />
        <Footer />
      </Container>
    </>
  );
}

function HeartIcon() {
  const theme = useColorScheme();
  return (
    <Icon
      name="heart"
      style={{
        width: 50,
        height: 50,
        tintColor: Colors[theme].accent,
      }}
    />
  );
}

function SmallRaysTop() {
  const isMobile = useMaxWidth(768);
  return (
    <TransparentView style={{ alignItems: 'center' }}>
      <SmallRays
        style={{
          width: isMobile ? 137.19 : 185.567,
          height: isMobile ? 36 : 48.693,
        }}
      />
    </TransparentView>
  );
}

function SmallRaysBottom() {
  const isMobile = useMaxWidth(768);
  if (isMobile) {
    return null;
  }
  return (
    <TransparentView
      style={{
        transform: [{ rotate: '180deg' }],
        alignItems: 'center',
        marginVertical: 40,
      }}
    >
      <SmallRays
        style={{
          width: 185.567,
          height: 48.693,
        }}
      />
    </TransparentView>
  );
}

function CTAGetCreditSection({
  style,
  buttonColor,
}: {
  style?: StyleProps['style'];
  buttonColor?: string;
}) {
  const [alreadyLoggedInModal, setAlreadyLoggedInModal] = useState(false);
  const theme = useColorScheme();
  const authenticate = useAuthenticate();

  const { navigate } = useAppNavigation();

  const hide = () => setAlreadyLoggedInModal(false);

  const isLoggedIn = useIsLoggedIn();

  const onPress = () => {
    if (isLoggedIn) {
      setAlreadyLoggedInModal(true);
      return;
    }
    authenticate(undefined, { source: 'PAID_AD' });
  };
  return (
    <TransparentView style={[{ alignItems: 'center' }, style]}>
      <PrimaryButton
        style={{
          backgroundColor: buttonColor || Colors[theme].accent,
          paddingHorizontal: 30,
        }}
        title="Get $50 in Child Care Credit"
        onPress={onPress}
      />
      <TextFinePrint
        bold
        style={{ color: Colors[theme].textLowContrast, marginTop: 8 }}
      >
        *Offer only applies to new accounts created
      </TextFinePrint>

      <Modal
        useNativeDriver
        style={{ margin: 0 }}
        isVisible={alreadyLoggedInModal}
        onBackdropPress={hide}
        hideModalContentWhileAnimating
        testID="login-modal-container"
      >
        <TransparentView
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <View style={styles.alreadyLoggedInModalWrapper}>
            <ModalCloseButton absolute onPress={hide} />
            <TextBody style={styles.tc}>
              Yay! 🎉 You already have an account with us.
            </TextBody>
            <PrimaryButton
              title="Book Child Care Today"
              style={{ marginTop: 24 }}
              onPress={() => {
                hide();
                navigate('Explore');
              }}
            />
          </View>
        </TransparentView>
      </Modal>
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  tc: { textAlign: 'center' },
  alreadyLoggedInModalWrapper: {
    width: 'calc(100% - 20px)' as DimensionValue,
    maxWidth: 510,
    height: '100%',
    maxHeight: 364,
    borderRadius: 30,
    padding: 40,
    paddingTop: 70,
    position: 'relative',
  },
});
