import Checkbox from 'expo-checkbox';
import React, { useState } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';

import { TextButton } from './Button';
import { Icon } from './Icon';
import Row from './Row';
import { TextCta, TextLargeTag } from './StyledText';
import { Divider, StyleProps, View } from './Themed';
import {
  BOOKING_CALENDAR_ERROR_STATUS,
  ErrorMessageRenderer,
} from './booking_calendar/BookingCalendarError';
import { useCalendarElementsHeights } from './booking_calendar/HeightProvider';
import { useLocationPricesHelpers } from './location/Prices';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ProfileIcon,
  WarningIcon,
} from '../native/icons';
import { getAgeInMonths } from '../utils/date';

export interface Kid {
  firstName: string;
  id: number;
  birthdate: string;
  isEligible: boolean;
  isChosen: boolean;
}

type KidsInputProps = StyleProps & {
  onChange: (index: number) => void;
  onLoggedOutEdit?: () => void;
  kids: Kid[];
  isFetching: boolean;
  errorStatus: BOOKING_CALENDAR_ERROR_STATUS;
  onPricePress?: () => void;
};

const cacheKidsGetter = (() => {
  let cached: KidsInputProps['kids'] = [];
  return (isLoaded: boolean, kids: KidsInputProps['kids']) => {
    if (!isLoaded) return cached;
    cached = kids;
    return cached;
  };
})();

export default function KidsInput({
  kids,
  onChange,
  onLoggedOutEdit,
  style,
  isFetching,
  errorStatus,
  onPricePress,
}: KidsInputProps) {
  const theme = useColorScheme();
  const [isModalVisible, setModalVisible] = useState(false);

  const isLoggedIn = kids.length > 0;

  const chosen = cacheKidsGetter(
    !isFetching,
    kids.filter((kid) => kid.isChosen && kid.isEligible)
  );

  const locationPrices = useLocationPricesHelpers();

  const { kidsInputHeight } = useCalendarElementsHeights();

  const chosenKidsPrefix = `${chosen.length} kid${
    chosen.length !== 1 ? 's' : ''
  }${chosen.length > 0 ? ': ' : ' '}`;

  const chosenKidsNames = chosen.map(
    ({ firstName }, idx) => `${firstName}${idx < chosen.length - 1 ? ', ' : ''}`
  );

  return (
    <View style={[styles.placeholder, style, { height: kidsInputHeight }]}>
      <View
        style={[
          styles.container,
          !isModalVisible && styles.collapsed,
          { borderColor: Colors[theme].lines },
          isModalVisible && styles.containerOpened,
          { paddingVertical: (kidsInputHeight - 34) / 2 },
        ]}
      >
        <Row style={styles.row}>
          <Row style={[styles.innerRow, { flex: 1 }]}>
            <ProfileIcon fill={Colors[theme].accent} />
            <View style={{ flex: 1, overflow: 'hidden' }}>
              <TextLargeTag>Kids</TextLargeTag>
              {isLoggedIn ? (
                <Row style={styles.innerRow}>
                  <TextCta>{chosenKidsPrefix}</TextCta>
                  {chosen.length > 0 ? (
                    <TextCta
                      style={[
                        {
                          flex: 1,
                          overflow: 'hidden',
                        },
                        {
                          color: Colors[theme].textSecondary,
                          // @ts-expect-error
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      ]}
                      numberOfLines={1}
                    >
                      {chosenKidsNames}
                    </TextCta>
                  ) : null}
                </Row>
              ) : (
                <TextCta>1 kid</TextCta>
              )}
            </View>
          </Row>
          {errorStatus && !isModalVisible ? (
            <ExclamationMark size={24} />
          ) : null}
          <Pressable
            onPress={() => {
              if (!isLoggedIn) {
                onLoggedOutEdit?.();
                return;
              }

              return isModalVisible
                ? setModalVisible(false)
                : setModalVisible(true);
            }}
            style={{ flex: 0, flexBasis: 30 }}
          >
            {isModalVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Pressable>
        </Row>
        {isModalVisible && (
          <>
            <Divider style={styles.divider} />
            <View>
              {kids.map((kid, index) => {
                const minPrice = locationPrices.getLowestPriceForAge(
                  getAgeInMonths(kid.birthdate)
                );
                return (
                  <Row
                    key={kid.id}
                    style={[styles.innerRow, styles.checkboxRow]}
                  >
                    <Checkbox
                      disabled={!kid.isEligible}
                      value={kid.isChosen}
                      onValueChange={() => {
                        onChange(index);
                      }}
                      color={Colors[theme].accent}
                      style={styles.checkbox}
                    />
                    <TextCta style={[!kid.isEligible && styles.disabled]}>
                      {`${kid.firstName}: `}
                    </TextCta>
                    <TextCta
                      style={[
                        !kid.isEligible && styles.disabled,
                        { color: Colors[theme].textSecondary, flex: 1 },
                      ]}
                    >
                      {kid.birthdate}
                    </TextCta>
                    {minPrice ? (
                      <TextButton
                        TextComponent={TextCta}
                        textStyle={{ color: Colors[theme].textSecondary }}
                        onPress={() => {
                          onPricePress?.();
                          setModalVisible(false);
                        }}
                      >
                        ${minPrice}+/hr
                      </TextButton>
                    ) : null}
                  </Row>
                );
              })}
            </View>
            {errorStatus ? (
              <Row
                style={{
                  gap: 6,
                  paddingHorizontal: 4,
                  justifyContent: 'flex-start',
                }}
              >
                <ExclamationMark />
                <View style={{ flex: 1 }}>
                  <ErrorMessageRenderer status={errorStatus} />
                </View>
              </Row>
            ) : null}
          </>
        )}
      </View>
    </View>
  );
}

function ExclamationMark({ size = 20 }: { size?: number }) {
  const theme = useColorScheme();
  return Platform.OS === 'web' ? (
    <Icon
      name="warning"
      style={{ width: size, height: size, tintColor: Colors[theme].error }}
    />
  ) : (
    <WarningIcon width={size} height={size} fill={Colors[theme].error} />
  );
}

const styles = StyleSheet.create({
  placeholder: {
    height: 55,
    position: 'relative',
    zIndex: 20,
  },
  container: {
    width: '100%',
    position: 'absolute',
    top: 0,
    // minHeight: 50,
    paddingHorizontal: 10,
    // paddingVertical: 10,
    borderRadius: 20,
    borderWidth: 1,
    justifyContent: 'center',
  },
  containerOpened: {},
  divider: {
    marginVertical: 16,
  },
  collapsed: {
    borderRadius: 100,
  },
  disabled: {
    textDecorationLine: 'line-through',
  },
  row: {
    justifyContent: 'space-between',
  },
  innerRow: {
    justifyContent: 'flex-start',
  },
  checkboxRow: {
    marginBottom: 10,
  },
  checkbox: {
    height: 24,
    width: 24,
    borderRadius: 5,
    marginRight: 15,
  },
  finePrint: {
    textAlign: 'center',
    marginTop: 10,
  },
});
