import { useCallback, useEffect } from 'react';

export default function useHideAvochato() {
  useEffect(() => {
    // hide/show Avochato widget on map visibility change
    // @ts-ignore
    window.Avochato?.widgets?.qR95m0a9wX?.hide();
    // show Avochato widget on page change
    return () => {
      // @ts-ignore
      window.Avochato?.widgets?.qR95m0a9wX?.show();
    };
  }, []);
}

export function useOpenAvochatoAction() {
  return useCallback(() => {
    // @ts-ignore
    window.Avochato?.widgets?.qR95m0a9wX?.openWithOption();
  }, []);
}
