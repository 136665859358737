import { API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultAuthedRequest,
  defaultRequest,
} from './headers';
import { BookingSlot, Location, VerificationStatus } from './search';
import {
  parseBookingSlot,
  parseLocation,
  parseLocations,
  parsePrice,
} from '../utils/api';

const LOCATIONS_V1_URL = `${API_V1_URL}locations/`;

export async function postFavorite(id: number) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${id}/favorite`, request);
  return await response.json();
}

export async function postUnfavorite(id: number) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${id}/unfavorite`, request);
  return await response.json();
}

export async function getFavorites(
  latitude: number | undefined,
  longitude: number | undefined
): Promise<Location[]> {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(
    `${LOCATIONS_V1_URL}favorites?lat=${latitude}&lng=${longitude}`,
    request
  );
  return parseLocations(await response.json());
}

export async function getLocations(): Promise<Location[]> {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${LOCATIONS_V1_URL}`, request);
  return parseLocations(await response.json());
}

type SimpleLocationResponse = {
  id: number;
  name: string;
  name_id: string;
  latitude: number;
  longitude: number;
  verification_status: VerificationStatus;
};

export async function getLocationsCoords() {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${LOCATIONS_V1_URL}coordinates`, request);
  const data = (await response.json()) as SimpleLocationResponse[];
  return data.map((location) => {
    const { verification_status, name_id, ...rest } = location;
    return {
      verificationStatus: verification_status,
      nameId: name_id,
      ...rest,
    };
  });
}

export async function getLocation(
  nameId: string
): Promise<{ ok: true; location: Location } | { ok: false; error: string }> {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${nameId}`, request);
  if (response.status === 404) {
    return { ok: false, error: 'NOT_FOUND' };
  } else if (response.status === 200) {
    return { ok: true, location: parseLocation(await response.json()) };
  } else {
    return { ok: false, error: 'UNKNOWN' };
  }
}

export async function getLocationSlots(id: number): Promise<BookingSlot[]> {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${id}/slots`, request);
  const responseJson = await response.json();
  return responseJson.map(parseBookingSlot);
}

export async function postLead(id: number, telephone: string) {
  const request = {
    ...defaultAuthedRequest,
    body: JSON.stringify({ telephone }),
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${id}/lead`, request);
  return await response.json();
}

export interface PriceResponse {
  id: number;
  price: string;
  age_from: number;
  age_to: number;
  name: string;
  category: string;
}

export interface Price {
  id: number;
  price: number;
  ageFrom: number;
  ageTo: number;
  name: string;
  category: string;
}

export async function getPrices(id: number): Promise<Price[]> {
  const request = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'GET',
  };
  const response = await fetch(`${LOCATIONS_V1_URL}${id}/prices`, request);
  const responseJson = await response.json();
  return responseJson.map(parsePrice);
}
