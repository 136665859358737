import { useRoute } from '@react-navigation/native';
import { useEffect, useRef, useState, Fragment } from 'react';
import { Platform, Pressable, StyleSheet, TextInput } from 'react-native';
import Popup from 'reactjs-popup';
import { PopupActions, PopupProps } from 'reactjs-popup/dist/types';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import { IconButton } from './Button';
import { ShouldIgnoreCampaignHeaderFn } from './HelloMessageBar';
import { Icon } from './Icon';
import { ExpandableHeader } from './Layout';
import ModalCloseButton from './ModalCloseButton';
import { RangeCalendar } from './RangeCalendar';
import { TransparentRow } from './Row';
import { TextBodySmall, TextFinePrint, TextH3 } from './StyledText';
import { StyleProps, TransparentView, View } from './Themed';
import KidsChooser from './search/KidsChooser';
import { useThreeParamSearchButtonText } from './search/useThreeParamSearchButtonText';
import Colors from '../constants/Colors';
import { useIsLoggedIn } from '../contexts/authentication';
import { LocationFilter } from '../contexts/search';
import {
  useSearchButtonState,
  useSearchInputState,
  useUpdateSearchState,
} from '../contexts/threeparamsearch';
import useAppNavigation from '../hooks/useAppNavigation';
import useBlockBodyScroll from '../hooks/useBlockBodyScroll';
import useColorScheme from '../hooks/useColorScheme';
import { formatRanges } from '../utils/date';

export function ThreeParamsSearchHeader({
  customHelloBar,
  ignoreCampaignHeader,
}: {
  customHelloBar?: React.ReactNode;
  ignoreCampaignHeader?: ShouldIgnoreCampaignHeaderFn;
}) {
  const [isOpen, setIsOpen] = useState(false);
  useBlockBodyScroll(isOpen);

  const onSearchPress = () => {
    setIsOpen(false);
  };

  const navigation = useAppNavigation();

  const route = useRoute();
  const isSearchScreenActive = route.name === 'Search';

  useEffect(() => {
    return navigation.addListener('state', () => {
      setIsOpen(false);
    });
  }, []);

  return (
    <ExpandableHeader
      customHelloBar={customHelloBar}
      ignoreCampaignHeader={ignoreCampaignHeader}
      shadow
      expanded={isOpen}
      center={
        isOpen ? (
          <TextBodySmall bold style={{ fontSize: 18, alignSelf: 'center' }}>
            Find Child Care
          </TextBodySmall>
        ) : (
          <ThreeParamSearchButton onPress={() => setIsOpen(true)} />
        )
      }
      onBackgroundPress={() => setIsOpen(false)}
    >
      {isOpen ? (
        <ThreeParamSearchInput
          onSearchPress={onSearchPress}
          isSearchScreenActive={isSearchScreenActive}
        />
      ) : null}
    </ExpandableHeader>
  );
}

export function ThreeParamSearchButton({
  onPress,
  style,
}: {
  onPress: () => void;
  style?: StyleProps['style'];
}) {
  const theme = useColorScheme();
  const buttonState = useSearchButtonState();
  const { whereText, whenText, whoText } =
    useThreeParamSearchButtonText(buttonState);
  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          alignSelf: 'center',
          borderRadius: 24,
          height: 48,
        },
        style,
      ]}
    >
      <TransparentRow
        style={[
          styles.buttonContainer,
          { shadowRadius: 12, shadowColor: Colors[theme].lines },
        ]}
      >
        <Icon
          name="search"
          style={styles.searchIcon}
          color={Colors[theme].accentTertiary}
        />
        <TextBodySmall style={styles.buttonTextWrapper}>
          {whereText}
        </TextBodySmall>
        <Dot />
        <TextBodySmall style={styles.buttonTextWrapper}>
          {whenText}
        </TextBodySmall>
        <Dot />
        <TextBodySmall>{whoText || 'Who'}</TextBodySmall>
      </TransparentRow>
    </Pressable>
  );
}

export function ThreeParamSearchInput({
  onSearchPress,
  isSearchScreenActive,
}: {
  onSearchPress: () => void;
  isSearchScreenActive: boolean;
}) {
  const theme = useColorScheme();
  const navigation = useAppNavigation();
  const { location } = useSearchInputState();

  const [openState, setCurrentOpenState] = useState<
    null | 'WHERE' | 'WHEN' | 'WHO'
  >(null);

  const isAnyOpen = openState !== null;

  const getCommonProps = (value: 'WHERE' | 'WHEN' | 'WHO') => ({
    onOpen: () => setCurrentOpenState(value),
    onClose: () => (openState === value ? setCurrentOpenState(null) : null),
  });

  const { triggerSearchParamsUpdate } = useUpdateSearchState();
  const currentSearchState = useSearchInputState();

  const goToSearchPage = () => {
    setCurrentOpenState(null);
    triggerSearchParamsUpdate();
    onSearchPress();
    if (isSearchScreenActive) {
      navigation.setParams({
        ...(location.description ? { location: location.description } : {}),
        ...(currentSearchState.rangeStart
          ? {
              dateFrom: currentSearchState.rangeStart,
              dateTo: currentSearchState.rangeEnd || undefined,
            }
          : {
              dateFrom: undefined,
              dateTo: undefined,
            }),
      });
      return;
    }
    navigation.navigate('Search', {
      ...(location.description ? { location: location.description } : {}),
      ...(currentSearchState.rangeStart
        ? {
            dateFrom: currentSearchState.rangeStart,
            dateTo: currentSearchState.rangeEnd || undefined,
          }
        : {}),
    });
  };

  return (
    <TransparentView style={[styles.inputContainer]}>
      <TransparentRow
        style={[
          styles.input,
          { shadowRadius: 12, shadowColor: Colors[theme].lines },
          isAnyOpen && styles.inputOpened,
        ]}
      >
        <WhereInput
          active={openState === 'WHERE'}
          {...getCommonProps('WHERE')}
        />
        <Divider invisible={openState === 'WHERE' || openState === 'WHEN'} />
        <WhenInput active={openState === 'WHEN'} {...getCommonProps('WHEN')} />
        <Divider invisible={openState === 'WHEN' || openState === 'WHO'} />
        <WhoInput active={openState === 'WHO'} {...getCommonProps('WHO')} />
        <IconButton
          icon={<Icon name="search" color={Colors[theme].accentTertiary} />}
          text={
            <TextBodySmall
              bold
              style={{
                fontSize: 18,
                color: Colors[theme].accentTertiary,
                marginHorizontal: 12,
              }}
            >
              Search
            </TextBodySmall>
          }
          style={[
            styles.searchButton,
            {
              borderColor: Colors[theme].accentTertiary,
              borderWidth: 2,
              backgroundColor: Colors[theme].background,
            },
          ]}
          onPress={goToSearchPage}
        />
      </TransparentRow>
    </TransparentView>
  );
}

function WhereInput({
  active,
  onOpen,
  onClose,
}: {
  active: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const theme = useColorScheme();
  const inputRef = useRef<TextInput | null>(null);
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (active) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [active]);

  const currentSearchState = useSearchInputState();
  const { setInputState } = useUpdateSearchState();

  const [chosen, setChosen] = useState<LocationFilter>(
    currentSearchState.location
  );

  useEffect(() => {
    setInputState({ location: chosen });
  }, [chosen]);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ['us'],
      },
    },
    callbackName: 'initAutocomplete',
  });

  useEffect(() => {
    if (currentSearchState.location.description) {
      setValue(currentSearchState.location.description, false);
    }
  }, []);

  const handleSelect =
    ({
      description,
      structured_formatting: { main_text },
      terms,
    }: google.maps.places.AutocompletePrediction) =>
    () => {
      const displayValue = `${main_text}${
        terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
      }`;
      setValue(displayValue, false);
      clearSuggestions();
      onClose?.();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        if (!results[0]) {
          return;
        }
        const { lat, lng } = getLatLng(results[0]);
        setChosen({ latitude: lat, longitude: lng, description: displayValue });
      });
    };

  return (
    <SingleInputContainer
      label="Where"
      placeholder="Search location"
      placeholderComponent={
        <TextInput
          placeholder="Search location"
          style={styles.textInput}
          placeholderTextColor={Colors[theme].textSecondary}
          ref={inputRef}
          value={value}
          onChangeText={(value) => setValue(value)}
        />
      }
      active={active}
      onOpen={onOpen}
      onClose={onClose}
      clearable={value.length > 0}
      onClear={() => {
        setValue('', false);
        setChosen({});
      }}
      popupProps={{
        contentStyle: {
          visibility: data.length === 0 ? 'hidden' : 'visible',
        },
      }}
    >
      <TextH3 bold style={{ marginBottom: 12 }}>
        Where do you need care?
      </TextH3>
      {status === 'OK' && (
        <View>
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text },
              terms,
            } = suggestion;
            const description = `${main_text}${
              terms.length > 2 ? `, ${terms[terms.length - 2]?.value}` : ''
            }`;
            return (
              <Fragment key={place_id}>
                <Pressable onPress={handleSelect(suggestion)}>
                  <TransparentView style={styles.suggestionResult}>
                    <TextBodySmall>{description}</TextBodySmall>
                  </TransparentView>
                </Pressable>
              </Fragment>
            );
          })}
        </View>
      )}
    </SingleInputContainer>
  );
}

function WhenInput({
  active,
  onOpen,
  onClose,
}: {
  active: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const currentSearchState = useSearchInputState();
  const { setInputState } = useUpdateSearchState();
  const [ranges, setRanges] = useState<{
    rangeStart: string | null;
    rangeEnd: string | null;
  }>({
    rangeStart: currentSearchState.rangeStart,
    rangeEnd: currentSearchState.rangeEnd,
  });
  useEffect(() => {
    setInputState({
      ...ranges,
    });
  }, [ranges]);
  return (
    <SingleInputContainer
      label="When"
      placeholder="Select dates"
      value={formatRanges(ranges)}
      active={active}
      onOpen={onOpen}
      onClose={onClose}
      popupStyle={{ width: 'auto', height: 'auto', padding: 32 }}
      clearable={ranges.rangeStart !== null}
      onClear={() => setRanges({ rangeStart: null, rangeEnd: null })}
    >
      <TextH3 bold style={{ marginBottom: 32, alignSelf: 'center' }}>
        When do you need care?
      </TextH3>
      <RangeCalendar onChange={setRanges} {...ranges} />
    </SingleInputContainer>
  );
}

function WhoInput({
  active,
  onOpen,
  onClose,
}: {
  active: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const isLoggedIn = useIsLoggedIn();

  const { who } = useSearchInputState();

  return (
    <SingleInputContainer
      label="Who"
      placeholder="Choose kids"
      value={
        who.filter((k) => k.enabled).length > 0
          ? isLoggedIn
            ? who
                .filter((k) => k.enabled)
                .map((k) => k.name)
                .join(', ')
            : who
                .filter((k) => k.enabled)
                .map((k) => k.age)
                .join(', ')
          : null
      }
      active={active}
      onOpen={onOpen}
      onClose={onClose}
      popupProps={{
        closeOnDocumentClick: false,
      }}
    >
      <TextH3 bold style={{ marginBottom: 32 }}>
        Who needs care?
      </TextH3>
      <KidsChooser />
    </SingleInputContainer>
  );
}

function SingleInputContainer({
  label,
  placeholder,
  value,
  children,
  onOpen,
  onClose,
  active,
  placeholderComponent,
  popupStyle,
  clearable,
  onClear,
  popupProps,
}: {
  label: string;
  placeholder: string;
  value?: null | string;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  active: boolean;
  clearable?: boolean;
  onClear?: () => void;
  placeholderComponent?: React.ReactNode;
  popupStyle?: StyleProps['style'];
  popupProps?: Partial<PopupProps>;
}) {
  const theme = useColorScheme();
  const ref = useRef<PopupActions | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (!active) {
      ref.current.close();
    }
  }, [active]);
  return (
    <Popup
      closeOnDocumentClick
      {...popupProps}
      ref={ref}
      arrow={false}
      position="bottom center"
      offsetX={100}
      offsetY={10}
      trigger={
        <TransparentView
          style={[
            styles.singleInputContainer,
            active && styles.singleInputActive,
          ]}
        >
          <TextFinePrint bold>{label}</TextFinePrint>
          {value ? (
            <TextFinePrint numberOfLines={1}>{value}</TextFinePrint>
          ) : placeholderComponent ? (
            placeholderComponent
          ) : (
            <TextFinePrint style={{ color: Colors[theme].textSecondary }}>
              {placeholder}
            </TextFinePrint>
          )}
          {clearable && active ? (
            <Pressable style={styles.clearIcon} onPress={onClear}>
              <Icon
                name="close"
                color={Colors[theme].textSecondary}
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            </Pressable>
          ) : null}
        </TransparentView>
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      <View
        style={[
          styles.popupContainer,
          { shadowRadius: 12, shadowColor: Colors[theme].lines },
          popupStyle,
        ]}
      >
        <ModalCloseButton
          onPress={() => ref.current?.close()}
          absolute
          style={{ top: 32, right: 32 }}
        />
        {children}
      </View>
    </Popup>
  );
}

export function Dot() {
  return <TransparentView style={styles.dot} />;
}

function Divider({ invisible }: { invisible: boolean }) {
  const theme = useColorScheme();
  return (
    <TransparentView
      style={[
        styles.divider,
        {
          backgroundColor: invisible
            ? 'transparent'
            : Colors[theme].textSecondary,
        },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  header: {},
  headerExpanded: {
    height: 150,
  },
  buttonContainer: {
    alignSelf: 'center',
    borderRadius: 24,
    height: 48,
    alignContent: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingRight: 36,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginHorizontal: 22,
    backgroundColor: '#000',
  },
  inputContainer: {
    width: '100%',
    height: 70,
    zIndex: 101,
  },
  input: {
    height: 56,
    paddingHorizontal: 8,
    paddingLeft: 0,
    borderRadius: 28,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextWrapper: {
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
    maxWidth: 200,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
  },
  inputOpened: {
    backgroundColor: '#e7e7e7',
  },
  divider: {
    height: 37,
    width: 1,
  },
  singleInputContainer: {
    width: 236,
    paddingHorizontal: 22,
    paddingVertical: 13,
    cursor: 'pointer',
    borderRadius: 28,
  },
  singleInputActive: {
    backgroundColor: '#fff',
  },
  popupContainer: {
    width: 435,
    minHeight: 360,
    borderRadius: 28,
    padding: 32,
  },
  textInput: {
    borderWidth: 0,
    fontSize: 12,
    fontFamily: 'Mont',
    ...Platform.select({
      web: {
        outlineColor: 'transparent',
      },
    }),
  },
  searchButton: {
    borderRadius: 20,
    height: 40,
    paddingHorizontal: 8,
  },
  suggestionResult: {
    paddingBottom: 10,
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    marginBottom: 15,
  },
  childSelectionContainer: {
    height: 50,
    justifyContent: 'flex-start',
  },
  clearIcon: {
    position: 'absolute',
    right: 12,
    top: '50%',
    marginTop: -8,
  },
  searchIcon: {
    marginRight: 22,
  },
});
