import { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';

import Chip from './Chip';
import LocationAutoCompleteModal from './LocationAutocompleteModal';
import Row from './Row';
import { StyleProps } from './Themed';
import {
  LocationFilter,
  useSearchState,
  useSearchStateUpdate,
} from '../contexts/search';

type FiltersProps = StyleProps & {
  refreshResults?: boolean;
};

export function FiltersPure({
  style,
  onFiltersChange,
}: FiltersProps & {
  onFiltersChange?: (
    v: { location: LocationFilter } | { ages: number[] }
  ) => void;
}) {
  const onLocationUpdate = useCallback(
    (location: LocationFilter) => {
      onFiltersChange?.({ location });
    },
    [onFiltersChange]
  );

  return (
    <Row style={[styles.container, style]}>
      <LocationsFilter onLocationSelected={onLocationUpdate} />
    </Row>
  );
}

function LocationsFilter({
  onLocationSelected,
  disabled,
}: {
  onLocationSelected: (loc: LocationFilter) => void;
  disabled?: boolean;
}) {
  const [isLocationModalVisible, setLocationModalVisible] = useState(false);
  const { location } = useSearchState();
  const updateSearchState = useSearchStateUpdate();
  const onLocationUpdate = useCallback(
    (loc: LocationFilter) => {
      updateSearchState({ location: loc });
      onLocationSelected(loc);
    },
    [onLocationSelected]
  );
  return (
    <>
      <LocationAutoCompleteModal
        isVisible={isLocationModalVisible}
        close={() => setLocationModalVisible(false)}
        onSubmit={onLocationUpdate}
      />
      <Chip
        icon="location"
        style={styles.chipBottom}
        disabled={disabled}
        label={location.description || 'All locations'}
        onPress={() => {
          setLocationModalVisible(true);
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    // @ts-expect-error
    scrollbarWidth: 'none',
    overflow: 'scroll',
  },
  chip: {
    marginRight: 10,
  },
  chipBottom: {
    marginBottom: 10,
  },
});
