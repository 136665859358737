import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  DimensionValue,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  ViewStyle,
} from 'react-native';
import Modal from 'react-native-modal';

import ReviewFeaturesRatings from './ReviewFeaturesRatings';
import {
  FeatureRatings,
  addLocationReview,
  updateReview,
} from '../../api/reviews';
import Colors from '../../constants/Colors';
import { StaticTexts } from '../../constants/StaticTexts';
import useBlockBodyScroll from '../../hooks/useBlockBodyScroll';
import { useParent } from '../../hooks/useChild';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { LocationReviews, Review } from '../../types/Reviews';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
  UnderlinedButton,
} from '../Button';
import ModalCloseButton from '../ModalCloseButton';
import { TransparentRow } from '../Row';
import {
  QuincyRegularText,
  TextBody,
  TextBodySmall,
  TextH3,
  TextLargeTag,
} from '../StyledText';
import { Divider, TransparentView, View } from '../Themed';
import { ReviewBlock } from '../reviews/ReviewBlock';
import ReviewsRatingsSummary from '../reviews/ReviewsRatingsSummary';
import ReviewsSummary from '../reviews/ReviewsSummary';

export const getFilteredRatings = (ratings: FeatureRatings) => {
  const filteredRatings = Object.entries(ratings).filter(
    ([key, value]) => value > 0
  );
  return Object.fromEntries(filteredRatings) as FeatureRatings;
};

const useIsMobileDevice = () => useMaxWidth(500);

export function ShowMoreModal({
  isVisible,
  hide,
  reviews,
  onMyReviewClick,
  outdoor,
}: {
  isVisible: boolean;
  outdoor: boolean;
  hide: () => void;
  reviews?: LocationReviews | null;
  onMyReviewClick?: () => void;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useIsMobileDevice();
  useBlockBodyScroll(isVisible);

  const parent = useParent();
  const userReview = reviews?.reviews.find(
    (reviewItem) => parent.id === reviewItem.author.id
  );

  return (
    <Modal
      useNativeDriver
      style={{ margin: 0 }}
      isVisible={isVisible}
      onBackdropPress={hide}
      hideModalContentWhileAnimating
    >
      <View
        style={[
          styles.modalContainer,
          isMobileDevice && styles.modalContainerMobile,
        ]}
      >
        {isMobileDevice ? (
          <TransparentView style={{ paddingHorizontal: 20, paddingTop: 20 }}>
            <UnderlinedButton
              title="Back"
              onPress={hide}
              style={{ alignSelf: 'flex-start' }}
            />
          </TransparentView>
        ) : (
          <ModalCloseButton onPress={hide} absolute />
        )}
        <TransparentRow
          style={[
            !isMobileDevice && {
              borderBottomColor: Colors[theme].lines,
              borderBottomWidth: 1,
              alignItems: 'center',
              paddingVertical: 20,
            },
          ]}
        >
          <TextH3>Reviews</TextH3>
        </TransparentRow>
        <MoreReviewsContent
          reviews={reviews}
          outdoor={outdoor}
          onMyReviewClick={onMyReviewClick}
          userReview={userReview}
        />
      </View>
    </Modal>
  );
}

export function MoreReviewsContent({
  reviews,
  userReview,
  outdoor,
  onMyReviewClick,
  onReviewRender,
}: {
  reviews?: LocationReviews | null;
  userReview?: Review;
  outdoor: boolean;
  onMyReviewClick?: (review: Review) => void;
  onReviewRender?: (id: number, yOffset: number) => void;
}) {
  const hasUserReview = !!userReview;
  return (
    <>
      <TransparentView style={{ padding: 20 }}>
        <ReviewsSummary reviews={reviews} centered />
      </TransparentView>
      <TransparentView style={styles.reviewsList}>
        <TransparentView style={{ flexShrink: 0 }}>
          <ReviewsRatingsSummary
            reviews={reviews}
            shouldBeFullWidth
            outdoor={outdoor}
            notGrow={false}
          />
        </TransparentView>
        {hasUserReview ? (
          <TransparentView style={{ flexShrink: 0 }}>
            <ReviewBlock
              outdoor={outdoor}
              review={userReview}
              shouldBeFullWidth
              isUserReview
              setEditingReview={onMyReviewClick}
              fullText
            />
          </TransparentView>
        ) : null}
        {reviews?.reviews
          .filter((r) => r !== userReview)
          .map((review) => (
            <TransparentView
              key={review.id}
              nativeID={`review-${review.id}`}
              onLayout={(e) =>
                onReviewRender?.(review.id, e.nativeEvent.layout.y)
              }
            >
              <ReviewBlock
                outdoor={outdoor}
                review={review}
                key={review.id}
                shouldBeFullWidth
                fullText
              />
            </TransparentView>
          ))}
      </TransparentView>
    </>
  );
}

export function WriteReviewModal({
  locationId,
  isVisible,
  hide,
  onReviewAdded,
  outdoor,
}: {
  locationId: number;
  isVisible: boolean;
  outdoor: boolean;
  hide: () => void;
  onReviewAdded?: () => void;
}) {
  useBlockBodyScroll(isVisible);
  const isMobileDevice = useIsMobileDevice();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { bookedLocations = [] } = useParent();

  useEffect(() => {
    if (!isVisible) {
      setSubmitting(false);
      setSubmitError(null);
    }
  }, [isVisible]);

  const addReview = async (text: string, featuresRatings: FeatureRatings) => {
    setSubmitting(true);
    const response = await addLocationReview(locationId, {
      text,
      features: getFilteredRatings(featuresRatings),
    });
    setSubmitting(false);
    if (!response.ok) {
      setSubmitError(
        `Error ${response.status}, ${JSON.stringify(response.body)}`
      );
      return;
    }
    onReviewAdded?.();
    hide();
  };

  const hasBookedBefore = bookedLocations.includes(locationId);

  return (
    <Modal
      useNativeDriver
      style={{ margin: !hasBookedBefore ? 10 : 0 }}
      isVisible={isVisible}
      onBackdropPress={hide}
      hideModalContentWhileAnimating
    >
      <View
        style={[
          styles.modalContainer,
          hasBookedBefore && isMobileDevice && styles.modalContainerMobile,
        ]}
      >
        {isMobileDevice && hasBookedBefore ? (
          <TransparentView style={{ paddingHorizontal: 20, paddingTop: 20 }}>
            <UnderlinedButton
              title="Back"
              onPress={hide}
              style={{ alignSelf: 'flex-start' }}
            />
          </TransparentView>
        ) : (
          <ModalCloseButton onPress={hide} absolute />
        )}
        {!hasBookedBefore ? (
          <NeedToBookFirstContent onPress={hide} />
        ) : (
          <ReviewModalContent
            outdoor={outdoor}
            onSubmit={addReview}
            isVisible={isVisible}
            title="Write a Review"
            isSubmitting={submitting}
            error={submitError}
          />
        )}
      </View>
    </Modal>
  );
}

export function EditReviewModal({
  isVisible,
  hide,
  review,
  onReviewUpdated,
  onDelete,
  outdoor,
}: {
  isVisible: boolean;
  outdoor: boolean;
  review: Review | null;
  hide: () => void;
  onDelete: () => void;
  onReviewUpdated?: (updateAction: {
    action: 'EDITED';
    review: Review;
  }) => void;
}) {
  useBlockBodyScroll(isVisible);
  const isMobileDevice = useIsMobileDevice();

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setIsUpdating(false);
    }
  }, [isVisible]);

  const editReview = async (text: string, featuresRatings: FeatureRatings) => {
    if (!review) {
      return;
    }
    setIsUpdating(true);
    const response = await updateReview(review.id, {
      text,
      features: getFilteredRatings(featuresRatings),
    });
    setIsUpdating(false);
    if (!response.ok) {
      alert('Something went wrong. ' + response.error);
      return;
    }

    onReviewUpdated?.({ action: 'EDITED', review: response.review });
    hide();
  };

  if (!review) {
    return null;
  }
  return (
    <>
      <Modal
        useNativeDriver
        style={{ margin: 0 }}
        isVisible={isVisible}
        onBackdropPress={hide}
        hideModalContentWhileAnimating
      >
        <View
          style={[
            styles.modalContainer,
            isMobileDevice && styles.modalContainerMobile,
            { padding: 0 },
          ]}
        >
          {isMobileDevice ? (
            <TransparentView style={{ paddingHorizontal: 20, paddingTop: 20 }}>
              <UnderlinedButton
                title="Back"
                onPress={hide}
                style={{ alignSelf: 'flex-start' }}
              />
            </TransparentView>
          ) : (
            <ModalCloseButton onPress={hide} absolute />
          )}
          <ReviewModalContent
            outdoor={outdoor}
            title="Edit Review"
            onSubmit={editReview}
            initialText={review.text}
            ratings={review.features}
            primaryButtonText="Update"
            isSubmitting={isUpdating}
          >
            <TextButton
              style={{ alignSelf: 'center', marginTop: 20 }}
              onPress={onDelete}
            >
              Delete review
            </TextButton>
          </ReviewModalContent>
        </View>
      </Modal>
    </>
  );
}

export function NeedToBookFirstContent({
  onPress,
  style,
  textStyle,
}: {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}) {
  const isMobileDevice = useIsMobileDevice();
  return (
    <TransparentView
      style={[
        styles.modalContent,
        isMobileDevice && styles.modalContentMobile,
        style,
      ]}
    >
      <TransparentView
        style={[{ padding: 20 }, isMobileDevice && { paddingVertical: 14 }]}
      >
        <TextH3 style={{ alignSelf: 'center' }}>Write a Review</TextH3>
      </TransparentView>
      <Divider style={{ marginBottom: 28 }} />

      <TextBody
        style={[
          {
            maxWidth: 384,
            textAlign: 'center',
            alignSelf: 'center',
          },
          textStyle,
        ]}
      >
        {StaticTexts.reviews.needToBookFirst}
      </TextBody>

      <PrimaryButton
        title="Book Now"
        style={{ alignSelf: 'center', marginTop: 32 }}
        onPress={onPress}
      />
    </TransparentView>
  );
}

export function ReviewModalContent({
  title,
  onSubmit,
  isVisible,
  initialText,
  ratings,
  primaryButtonText = 'Submit',
  isSubmitting,
  error,
  children,
  outdoor,
}: {
  title: string | null;
  onSubmit?: (text: string, featuresRatings: FeatureRatings) => void;
  initialText?: string;
  ratings?: Partial<FeatureRatings>;
  isVisible?: boolean;
  isSubmitting?: boolean;
  primaryButtonText?: string;
  error?: string | null;
  children?: React.ReactNode;
  outdoor: boolean;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useIsMobileDevice();
  const [reviewText, setReviewText] = useState(initialText || '');
  const [dirty, setDirty] = useState(false);

  const [featuresRatings, setFeaturesRatings] = useReducer(
    (state: FeatureRatings, update: Partial<FeatureRatings>) => ({
      ...state,
      ...update,
    }),
    {
      cleanliness: 0,
      curriculum: 0,
      caregivers: 0,
      professionalism: 0,
      indoor: 0,
      outdoor: 0,
      ...ratings,
    }
  );

  const resetRatings = useCallback(() => {
    setFeaturesRatings({
      cleanliness: 0,
      curriculum: 0,
      caregivers: 0,
      professionalism: 0,
      indoor: 0,
      outdoor: 0,
    });
  }, []);

  const setRatings = useCallback((ratings: Partial<FeatureRatings>) => {
    setDirty(true);
    setFeaturesRatings(ratings);
  }, []);

  useEffect(() => {
    if (!isVisible && !ratings) {
      resetRatings();
    }
  }, [isVisible]);

  useEffect(() => {
    setRequiredMessageVisible(false);
  }, [featuresRatings, reviewText]);

  const [requiredMessageVisible, setRequiredMessageVisible] = useState(false);

  const textTooLong = reviewText.trim().length > 1000;

  const onSubmitPress = useCallback(() => {
    if (textTooLong) {
      return;
    }
    if (
      Object.values(featuresRatings).reduce((a, b) => a + b, 0) === 0 &&
      reviewText.trim().length === 0
    ) {
      setRequiredMessageVisible(true);
      return;
    }
    onSubmit?.(reviewText, featuresRatings);
  }, [reviewText, featuresRatings]);

  const errorMessage = textTooLong
    ? 'Exceeds 1000 character limit.'
    : requiredMessageVisible
      ? 'You must fill out at least one rating or provide a text review.'
      : error;

  return (
    <TransparentView
      style={[styles.modalContent, isMobileDevice && styles.modalContentMobile]}
    >
      {title ? (
        <TransparentView
          style={[{ padding: 20 }, isMobileDevice && { paddingVertical: 14 }]}
        >
          <TextH3 style={{ alignSelf: 'center' }}>{title}</TextH3>
        </TransparentView>
      ) : null}
      <Divider style={{ marginBottom: 2 }} />
      <QuincyRegularText
        style={[
          styles.sectionTitle,
          isMobileDevice && styles.mobileSectionTitle,
        ]}
      >
        Rate your experience
      </QuincyRegularText>

      <ReviewFeaturesRatings
        isMobileDevice={isMobileDevice}
        featuresRatings={featuresRatings}
        setRatings={setRatings}
        outdoor={outdoor}
      />
      <TransparentRow style={{ justifyContent: 'flex-end' }}>
        <Pressable onPress={resetRatings}>
          <TextLargeTag>Reset</TextLargeTag>
        </Pressable>
      </TransparentRow>

      <QuincyRegularText
        style={[
          styles.sectionTitle,
          isMobileDevice && styles.mobileSectionTitle,
        ]}
      >
        Tell us more!
      </QuincyRegularText>

      <TextInput
        multiline={true}
        numberOfLines={6}
        onChangeText={(text) => {
          setDirty(true);
          setReviewText(text);
        }}
        value={reviewText}
        style={[
          {
            borderColor: Colors[theme].lines,
            color: Colors[theme].text,
          },
          styles.textarea,
        ]}
        placeholderTextColor={Colors[theme].textSecondary}
        placeholder="What did you like about the facility? How was the care provided? Any tips for other parents or families to help them have a successful experience?"
      />

      <View style={styles.errorMessageContainer}>
        {errorMessage ? (
          <TextBodySmall
            style={[
              styles.errorMessage,
              {
                color: Colors[theme].error,
              },
            ]}
          >
            {errorMessage}
          </TextBodySmall>
        ) : null}
      </View>

      <PrimaryButton
        title={primaryButtonText}
        onPress={onSubmitPress}
        loading={isSubmitting}
        disabled={!dirty || textTooLong || requiredMessageVisible}
      />
      {children}
    </TransparentView>
  );
}

export function DeleteReviewModal({
  isVisible,
  hide,
  onConfirm,
}: {
  isVisible: boolean;
  hide: () => void;
  onConfirm: () => void;
}) {
  const isMobileDevice = useIsMobileDevice();
  return (
    <Modal
      useNativeDriver
      style={{ margin: 0 }}
      isVisible={isVisible}
      onBackdropPress={hide}
      hideModalContentWhileAnimating
    >
      <View style={styles.modalContainer}>
        <ModalCloseButton onPress={hide} absolute />

        <TransparentView style={{ paddingVertical: 16 }}>
          <TextH3 style={{ alignSelf: 'center' }}>Delete Review</TextH3>
        </TransparentView>
        <Divider />
        <TransparentView style={{ padding: 24 }}>
          <TextBody style={{ textAlign: 'center' }}>
            Your reviews are very important to us. Are you sure you would like
            to delete your review?
          </TextBody>

          <TransparentRow
            style={[
              styles.buttonsContainer,
              isMobileDevice && styles.mobileButtonsContainer,
            ]}
          >
            <SecondaryButton title="Yes, delete" onPress={onConfirm} />
            <PrimaryButton title="No, don't delete" onPress={hide} />
          </TransparentRow>
        </TransparentView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    borderRadius: 10,
    width: '100%',
    maxHeight: 'calc(100% - 40px)' as DimensionValue,
    maxWidth: 580,
    alignSelf: 'center',
  },
  modalContainerMobile: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: 0,
    flex: 1,
    paddingBottom: 20,
    margin: 0,
  },
  modalContent: {
    paddingHorizontal: 40,
    paddingBottom: 30,
    flex: 1,
    ...Platform.select({
      web: {
        overflowY: 'auto',
      },
    }),
  },
  modalContentMobile: {
    paddingHorizontal: 20,
  },
  reviewsList: {
    ...Platform.select({
      web: {
        overflowY: 'auto',
      },
    }),
    flex: 1,
    padding: 20,
    paddingTop: 0,
    paddingHorizontal: 30,
    gap: 16,
  },
  sectionTitle: { fontSize: 25, alignSelf: 'center', marginVertical: 16 },
  mobileSectionTitle: {
    alignSelf: 'flex-start',
    fontSize: 20,
    marginTop: 12,
  },
  errorMessageContainer: { minHeight: 15, marginTop: 24, marginBottom: 8 },
  errorMessage: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  buttonsContainer: { gap: 36, marginTop: 24 },
  mobileButtonsContainer: { flexDirection: 'column', gap: 5 },
  textarea: {
    borderWidth: 1,
    borderRadius: 10,
    padding: 15,
    height: 120,
    minHeight: 120,
    fontFamily: 'Mont',
  },
});
