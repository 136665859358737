import { useIsFocused } from '@react-navigation/native';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Platform, StyleSheet } from 'react-native';

import { getLocation } from '../api/locations';
import { VerificationStatus } from '../api/search';
import BookingCalendarContainer from '../components/BookingCalendarContainer';
import Favorite from '../components/Favorite';
import { Footer } from '../components/Footer';
import { useHelloMessageBarHeight } from '../components/HelloMessageBar';
import { Container, InnerContainer } from '../components/Layout';
import PDPSkeleton from '../components/PDPSkeleton';
import Row, { TransparentRow } from '../components/Row';
import { TextH4Big } from '../components/StyledText';
import { View, Divider } from '../components/Themed';
import { ToggleProvider } from '../components/booking_calendar/CalendarTypeToggler';
import MarketingBanner from '../components/explore/MarketingBanner';
import CommonHeader from '../components/layouts/CommonHeader';
import About from '../components/location/About';
import Buttons from '../components/location/Buttons';
import DailySchedule from '../components/location/DailySchedule';
import Details from '../components/location/Details';
import Director from '../components/location/Director';
import Images from '../components/location/Images';
import InterestedInBooking from '../components/location/InterestedInBooking';
import Links from '../components/location/Links';
import Map from '../components/location/Map';
import Prices, {
  MappedPrices,
  PricesHelperContext,
} from '../components/location/Prices';
import LocationReviews from '../components/location/Reviews';
import { RightColumn } from '../components/location/RightColumn';
import ShareLocationButton from '../components/location/ShareLocationButton';
import SimilarLocations from '../components/location/SimilarLocations';
import TabbedMenu, {
  TabbedMenuRef,
  useScrollToIndicator,
} from '../components/location/TabbedMenu';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import { PDPTabId } from '../constants/PDPTabs';
import { useLocation, useLocationsUpdate } from '../contexts/locations';
import useHideAvochato from '../hooks/useAvochato';
import { useMaxWidth } from '../hooks/useResponsive';
import useScrollManager, { PositionIndicator } from '../hooks/useScrollManager';
import { RootStackScreenProps } from '../types';
import { createTitle } from '../utils/navigation';

export default function LocationScreen({
  route,
  navigation,
}: RootStackScreenProps<'Location'>) {
  const { nameId, weekly } = route.params;
  const defaultLocation = useLocation(nameId);
  const [location, setLocation] = useState(defaultLocation);
  const updateLocations = useLocationsUpdate();
  useHideAvochato();
  const isMobileDevice = useMaxWidth(430);

  const helloBarHeight = useHelloMessageBarHeight();
  const navHeight = Layout.navbar.height[isMobileDevice ? 'mobile' : 'desktop'];
  const subNavHeight = 70;

  const { currentActive } = useScrollManager(
    {
      'booking-indicator': 0,
    },
    { containerOffset: helloBarHeight + navHeight + subNavHeight }
  );

  useEffect(() => {
    if (!location) {
      getLocation(nameId).then((response) => {
        if (!response.ok) {
          navigation.navigate('Explore');
        } else {
          setLocation(response.location);
          navigation.setOptions({
            title: createTitle([response.location.name]),
          });
          updateLocations([response.location]);
        }
      });
    }
  }, [location, nameId]);

  useEffect(() => {
    setLocation(defaultLocation);
  }, [defaultLocation]);

  const isMediumDevice = useMaxWidth(800);

  const [reviewsInfo, setReviewsInfo] = useState<
    | {
        rating: number;
        count: number;
      }
    | undefined
  >(undefined);

  const [pricesInfo, setPricesInfo] = useState<MappedPrices | null>(null);

  const scrollIndicatorIntoView = useScrollToIndicator();
  const scrollToPrices = () => scrollIndicatorIntoView('price-indicator');

  const tabbedMenuRef = useRef<TabbedMenuRef>(null);

  const isFocused = useIsFocused();
  if (!isFocused) {
    return null;
  }

  if (!location) {
    return (
      <>
        <CommonHeader />
        <Container>
          <InnerContainer>
            <View style={styles.inner}>
              <PDPSkeleton />
            </View>
          </InnerContainer>
          <Footer />
        </Container>
      </>
    );
  }
  const {
    id,
    name,
    ageFrom,
    ageTo,
    price,
    pricing,
    address,
    hours,
    about,
    features,
    latitude,
    longitude,
    bookTourUrl,
    scheduleCallUrl,
    media,
    director,
    verificationStatus,
    requiresLicensingForms,
    outdoor,
    dailySchedules,
    customCareEnabled,
    fixedCareEnabled,
  } = location;

  return (
    <PricesHelperContext.Provider value={pricesInfo}>
      <Helmet>
        <link
          rel="canonical"
          href={window.location.href.replace(window.location.search, '')}
        />
        <meta
          property="og:title"
          content={`BUMO | Book childcare at ${name}`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <CommonHeader />
      <ToggleProvider
        bookingMode={location.bookingMode}
        force={weekly ? 'weekly' : undefined}
      >
        <Container>
          <InnerContainer>
            <View style={styles.inner}>
              <Images
                locationId={id}
                media={media}
                verificationStatus={verificationStatus}
                forceOpen={route.params.photos}
              />
              <Row style={[styles.row, isMediumDevice && styles.rowMobile]}>
                <View style={styles.column}>
                  <Row style={styles.titleContainer}>
                    <TextH4Big style={styles.title}>{name}</TextH4Big>
                    <TransparentRow>
                      <ShareLocationButton title={name} />
                      <Favorite style={styles.favorite} locationId={id} />
                    </TransparentRow>
                  </Row>
                  <Details
                    ageFrom={ageFrom}
                    ageTo={ageTo}
                    price={price}
                    pricing={pricing}
                    hours={hours}
                    address={address}
                    reviews={
                      verificationStatus !== VerificationStatus.VERIFIED
                        ? null
                        : reviewsInfo
                    }
                    onRatingPress={() =>
                      scrollIndicatorIntoView('reviews-indicator')
                    }
                    hasWeeklyDiscount={
                      location.bookingMode.includes('weekly') &&
                      location.availableDiscounts.includes('weekly')
                    }
                  />
                  <Buttons
                    scheduleCallUrl={scheduleCallUrl}
                    bookTourUrl={bookTourUrl}
                    verificationStatus={verificationStatus}
                    requiresLicensingForms={requiresLicensingForms}
                  />
                  <TabbedMenu
                    currentActive={currentActive}
                    location={location}
                    ref={tabbedMenuRef}
                  />

                  {isMediumDevice ? (
                    <View>
                      <View style={[styles.section, styles.bookingSection]}>
                        <PositionIndicator id="booking-indicator" />
                        <View
                          style={{
                            paddingTop: 35,
                          }}
                        />
                        {verificationStatus === VerificationStatus.VERIFIED ||
                        verificationStatus === VerificationStatus.HIDDEN ? (
                          <BookingCalendarContainer
                            scroll={true}
                            locationId={id}
                            locationNameId={location.nameId}
                            onPricePress={scrollToPrices}
                            hasWeeklyDiscount={location.availableDiscounts.includes(
                              'weekly'
                            )}
                          />
                        ) : (
                          <InterestedInBooking
                            style={{ borderRadius: 10 }}
                            locationId={id}
                            verificationStatus={verificationStatus}
                          />
                        )}
                      </View>
                      <Divider />
                    </View>
                  ) : null}
                  <PositionIndicator id="about-indicator" />
                  <About
                    style={styles.section}
                    name={name}
                    about={about}
                    features={features}
                    customCareEnabled={customCareEnabled}
                    fixedCareEnabled={fixedCareEnabled}
                  />
                  {verificationStatus !== VerificationStatus.UNVERIFIED && (
                    <>
                      {director && Object.keys(director).length > 0 && (
                        <>
                          <Divider />
                          <PositionIndicator id="director-indicator" />
                          <Director
                            style={styles.section}
                            director={director}
                          />
                        </>
                      )}
                    </>
                  )}
                  <Divider />
                  <PositionIndicator id="price-indicator" />
                  {FeatureFlags.PRICE_TIERS &&
                  verificationStatus === VerificationStatus.VERIFIED ? (
                    <Prices
                      style={styles.section}
                      locationId={id}
                      onFetched={(prices) => {
                        setPricesInfo(prices);
                        tabbedMenuRef.current?.triggerItem(PDPTabId.PRICE);
                      }}
                      penalty={location.pricing.penalty}
                      hasWeeklyDiscount={
                        location.bookingMode.includes('weekly') &&
                        location.availableDiscounts.includes('weekly')
                      }
                    />
                  ) : null}

                  {Array.isArray(dailySchedules) &&
                  dailySchedules.length > 0 ? (
                    <>
                      <Divider />
                      <PositionIndicator id="daily-schedule-indicator" />
                      <DailySchedule
                        schedules={dailySchedules}
                        style={styles.section}
                      />
                    </>
                  ) : null}
                  {latitude && longitude ? (
                    <>
                      <Divider />
                      <PositionIndicator id="location-indicator" />
                      <Map
                        style={styles.section}
                        latitude={latitude}
                        longitude={longitude}
                        {...(verificationStatus !==
                          VerificationStatus.UNVERIFIED && {
                          address,
                        })}
                      />
                    </>
                  ) : null}

                  {FeatureFlags.REVIEWS &&
                  verificationStatus === VerificationStatus.VERIFIED ? (
                    <>
                      <PositionIndicator id="reviews-indicator" />

                      <LocationReviews
                        style={styles.section}
                        onReviewsFetched={(reviews) => {
                          if (!reviews) {
                            return;
                          }
                          setReviewsInfo({
                            count: reviews.reviews.length,
                            rating: reviews.summary.rating,
                          });
                        }}
                        outdoor={!!outdoor}
                        locationId={id}
                        forceReviewsModal={route.params.reviews}
                        forceWriteReview={route.params.writeReview}
                      />
                    </>
                  ) : null}

                  {verificationStatus !== VerificationStatus.UNVERIFIED && (
                    <>
                      <PositionIndicator id="more-indicator" />
                      <Divider />
                      <Links
                        hasWeekly={location.bookingMode.includes('weekly')}
                        cancelationThreshold={location.cancellationThreshold}
                      />
                    </>
                  )}
                </View>
                {!isMediumDevice && (
                  <RightColumn
                    verificationStatus={verificationStatus}
                    id={id}
                    nameId={location.nameId}
                    onPricePress={scrollToPrices}
                    hasWeeklyDiscount={location.availableDiscounts.includes(
                      'weekly'
                    )}
                  />
                )}
              </Row>
              <Divider style={styles.divider} />

              <PositionIndicator id="other-locations-indicator" />
              <SimilarLocations
                lat={latitude}
                lng={longitude}
                nameId={nameId}
                onLocationsFetched={() =>
                  tabbedMenuRef.current?.triggerItem(PDPTabId.OTHER)
                }
              />
            </View>
          </InnerContainer>
          {verificationStatus !== VerificationStatus.VERIFIED && (
            <MarketingBanner />
          )}
          <Footer />
        </Container>
      </ToggleProvider>
    </PricesHelperContext.Provider>
  );
}

const styles = StyleSheet.create({
  inner: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 30,
          marginBottom: 100,
        }),
      },
    }),
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    ...Platform.select({
      web: {
        gap: 55,
        ...(Layout.isLargeDevice && {
          marginTop: 30,
        }),
        ...(Layout.isMobileDevice && {}),
      },
    }),
  },
  rowMobile: {
    flexDirection: 'column-reverse',
  },
  column: {
    width: '100%',
    maxWidth: 700,
    flex: 1,
  },
  title: {
    marginRight: 10,
  },
  titleContainer: {
    marginTop: 15,
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  section: {
    marginVertical: 35,
  },
  bookingSection: {
    marginTop: 10,
  },
  favorite: {
    position: 'relative',
    right: 0,
    marginLeft: 10,
  },
  divider: {
    ...Platform.select({
      web: {
        gap: 55,
        ...(Layout.isLargeDevice && {
          marginVertical: 40,
        }),
      },
    }),
  },
});
