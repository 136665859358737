import { useCallback, useEffect, useState } from 'react';

import { Location, availableNow } from '../../api/search';
import Colors from '../../constants/Colors';
import { useParent } from '../../hooks/useChild';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { UnderlinedButton } from '../Button';
import { Icon } from '../Icon';
import { LocationSection } from '../LocationSection';
import LocationSectionHeader from '../LocationSectionHeader';
import { SkeletonLoaderLarge, SkeletonLoaderMedium } from '../LocationSkeleton';
import { useNavigateToSearch } from '../NavigateToSearchButton';
import Tag from '../Tag';
import { TransparentView } from '../Themed';

const useTopPicksLocations = () => {
  const { latitude, longitude } = useParent();
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);

  useEffect(() => {
    availableNow(latitude || undefined, longitude || undefined).then((data) => {
      setLocations(data.data);
    });
  }, []);

  return { locations };
};

export default function OurTopPicksForYou({
  onEmptyLocations,
}: {
  onEmptyLocations?: () => void;
}) {
  const theme = useColorScheme();
  const { latitude, longitude } = useParent();
  const navigateToSearch = useNavigateToSearch();

  const { locations: topPicksLocations } = useTopPicksLocations();
  const isMobileDevice = useMaxWidth(600);

  useEffect(() => {
    if (topPicksLocations && topPicksLocations.length === 0) {
      onEmptyLocations?.();
    }
  }, [topPicksLocations, onEmptyLocations]);

  const onViewAllPress = useCallback(() => {
    const locationOpts =
      latitude && longitude
        ? {
            lat: latitude,
            lng: longitude,
          }
        : undefined;
    navigateToSearch(locationOpts);
  }, []);

  if (topPicksLocations && topPicksLocations.length === 0) {
    return null;
  }
  return (
    <>
      <LocationSectionHeader
        title="Our Top Picks For You"
        subtitle={
          <Tag
            icon={<Icon name="flashOn" style={{ width: 24, height: 24 }} />}
            text="Most Relevant"
            color={Colors[theme].cta}
          />
        }
      >
        <UnderlinedButton title="View All" onPress={onViewAllPress} />
      </LocationSectionHeader>
      <LocationSection
        locations={topPicksLocations || []}
        amountDisplayed={4}
        Block={isMobileDevice ? SkeletonLoaderMedium : SizedSkeletonLarge}
        carousel
        withSkeleton
        style={{ marginVertical: 0, marginBottom: isMobileDevice ? 30 : 60 }}
        contentStyle={{ gap: isMobileDevice ? 10 : 40 }}
      />
    </>
  );
}

function SizedSkeletonLarge(
  props: React.ComponentProps<typeof SkeletonLoaderLarge>
) {
  return (
    <TransparentView style={{ width: 270 }}>
      <SkeletonLoaderLarge {...props} width={270} />
    </TransparentView>
  );
}
