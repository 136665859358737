const CAMPAIGNS = {
  BLACK_FRIDAY: {
    startDate: '2023-11-15T08:00:00.000Z',
    endDate: '2023-11-27T07:59:59.000Z',
  },
  CYBER_MONDAY: {
    startDate: '2023-11-27T08:00:00.000Z',
    endDate: '2023-11-28T07:59:59.000Z',
  },
  CYBER_EXTENDED: {
    startDate: '2023-11-28T08:00:00.000Z',
    endDate: '2023-12-01T07:59:59.000Z',
  },
  WINTER_WONDERLAND: {
    startDate: '2023-12-07T08:00:00.000Z',
    endDate: '2023-12-18T07:59:59.000Z',
  },
  WINTER_WONDERLAND_COUPON: {
    startDate: '2023-12-18T08:00:00.000Z',
    endDate: '2024-01-01T07:59:59.000Z',
  },
  SUMMER_CAMPS_SPRING: {
    startDate: '2024-03-01T08:00:00.000Z',
    endDate: '2024-04-20T07:59:59.000Z',
  },
  SUMMER_CAMPS: {
    startDate: '2024-04-20T08:00:00.000Z',
    endDate: '2024-07-01T07:59:59.000Z',
  },
  LABOR_DAY: {
    startDate: '2024-08-19T07:00:00.000Z',
    endDate: '2024-09-03T06:59:59.000Z',
  },
  PNO: {
    startDate: '2024-09-24T07:00:00.000Z',
    endDate: '2024-10-03T06:59:59.000Z',
  },
  VOTE: {
    startDate: '2024-10-28T07:00:00.000Z',
    endDate: '2024-11-05T07:59:59.000Z',
  },
  BLACK_FRIDAY_2024: {
    startDate: '2024-11-20T08:00:00.000Z',
    endDate: '2024-11-29T07:59:59.000Z',
  },
  CYBER_MONDAY_2024: {
    startDate: '2024-11-29T08:00:00.000Z',
    endDate: '2024-12-04T07:59:59.000Z',
  },
  WINTER_WONDERLAND_2024_COUPON: {
    startDate: '2024-12-05T08:00:00.000Z',
    endDate: '2024-12-20T07:59:59.000Z',
  },
  WINTER_WONDERLAND_2024: {
    startDate: '2024-12-20T08:00:00.000Z',
    endDate: '2025-01-03T07:59:59.000Z',
  },
  LA_FAMILY_SUPPORT: {
    startDate: '2025-01-10T00:00:00.000Z',
    endDate: '2025-01-22T01:00:00.000Z',
  },
  SUMMER_CAMPS_2025: {
    startDate: '2025-03-10T00:00:00.000Z',
    endDate: '2025-08-16T07:59:59.000Z',
  },
};

export type CAMPAIGN_KEY = keyof typeof CAMPAIGNS;

export function findCurrentCampaignKey(): CAMPAIGN_KEY | null {
  const lsCampaign =
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem('FORCE_CAMPAIGN')
      : null;
  if (lsCampaign && CAMPAIGNS[lsCampaign as CAMPAIGN_KEY]) {
    return lsCampaign as CAMPAIGN_KEY;
  }

  const now = new Date();
  const nowISO = now.toISOString();

  const activeCampaingKey = (Object.keys(CAMPAIGNS) as CAMPAIGN_KEY[]).find(
    (key) =>
      CAMPAIGNS[key].startDate <= nowISO && CAMPAIGNS[key].endDate >= nowISO
  );
  return activeCampaingKey || null;
}

export function isWithinDatesRange(dates: {
  startDate: string;
  endDate: string;
}) {
  const now = new Date();
  const nowISO = now.toISOString();
  return nowISO >= dates.startDate && nowISO <= dates.endDate;
}
