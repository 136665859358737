.rating-icon-holder {
  width: 24px;
  height: 24px;
  overflow: hidden;
  position: relative;
}

.rating-icon-holder > div {
  position: absolute;
}

.rating-icon-holder > div:nth-child(2) {
  top: 100%;
}


.rating-input-container:hover .rating-icon-holder > div:nth-child(2), /* current hover */
.rating-input-container .rating-icon-holder.active > div:nth-child(2), /* current active */
.rating-input-container:has(~.rating-input-container > .active) .rating-icon-holder > div:nth-child(2), /* all before active */
.rating-input-container:has(~:hover) .rating-icon-holder  > div:nth-child(2) {
  top: 0
}
.rating-input-container:hover ~ .rating-icon-holder {
  background: grey;
}
