import { useCallback } from 'react';
import {
  View,
  Platform,
  StyleSheet,
  ImageStyle,
  DimensionValue,
} from 'react-native';

import SearchLeadForm from './SearchLeadForm';
import { postSearchLead } from '../../api/search';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { useAuthState } from '../../contexts/authentication';
import { useSearchButtonState } from '../../contexts/threeparamsearch';
import useColorScheme from '../../hooks/useColorScheme';
import ChildrenProps from '../../types/ChildrenProps';
import { getSelectedKidsAges } from '../../utils/children';
import { Icon } from '../Icon';
import { Image } from '../Image';
import OurLocations from '../OurLocations';
import { TextH2, TextBody } from '../StyledText';
import { Divider } from '../Themed';
import { OnFiltersChange } from '../layouts/LocationsListWithMap';

interface NoResultsProps {
  title?: string;
  onFiltersChange?: OnFiltersChange;
}

export function NoResults({
  title,
  description,
  children,
  onFiltersChange,
}: NoResultsProps & { description: string } & ChildrenProps) {
  const theme = useColorScheme();

  return (
    <View>
      <View style={[styles.container]}>
        <View style={styles.images}>
          <Image
            style={[
              styles.sun as ImageStyle,
              { tintColor: Colors[theme].accent },
            ]}
            source={require('../../assets/images/sun.png')}
          />
          <Icon
            name="search"
            style={styles.search}
            color={Colors[theme].background}
          />
          {Platform.OS === 'web' && (
            <>
              <Image
                style={[
                  styles.sparkles as ImageStyle,
                  { tintColor: Colors[theme].accentSecondary },
                ]}
                source={require('../../assets/images/sparkles.png')}
              />
              <Image
                style={[
                  styles.spring as ImageStyle,
                  { tintColor: Colors[theme].backgroundSecondary },
                ]}
                source={require('../../assets/images/spring2.png')}
              />
            </>
          )}
        </View>
        <TextH2 style={styles.title}>{title || 'No results'}</TextH2>
        {/* <FiltersPure style={styles.filters} onFiltersChange={onFiltersChange} /> */}
        <TextBody style={styles.description}>{description}</TextBody>
        {children}
      </View>
      <Divider style={styles.divider} />
      <OurLocations
        onViewAllPress={() => onFiltersChange?.({ location: {} })}
      />
    </View>
  );
}

export function NoSlotResults(props: NoResultsProps) {
  return (
    <NoResults {...props} description="Try changing your search parameters." />
  );
}

export function NoLocationResults(props: NoResultsProps) {
  const { parent: { children = [] } = {} } = useAuthState();
  const { location, rangeStart, rangeEnd, who, isLoggedIn } =
    useSearchButtonState();
  const kids = isLoggedIn ? who.map((c) => c.id) : [];
  const handleLeadSubmit = useCallback((telephone: string) => {
    const ages = getSelectedKidsAges(kids, children);
    postSearchLead({
      telephone,
      location,
      rangeStart,
      rangeEnd,
      kids,
      ages,
    });
  }, []);

  return (
    <NoResults
      {...props}
      description="We’re not in your city yet. Add your phone number below to get notified when Bumo is available in your city."
    >
      <SearchLeadForm key={location.description} onSubmit={handleLeadSubmit} />
    </NoResults>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          alignSelf: 'center',
          width: 360,
        }),
      },
    }),
  },
  images: {
    marginTop: 60,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 360,
        }),
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
      },
    }),
  },
  sun: {
    alignSelf: 'center',
    ...Platform.select({
      web: {
        width: 100,
        height: 100,
      },
    }),
  },
  search: {
    position: 'absolute',
    width: 40,
    height: 40,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          left: 160,
          top: 30,
        }),
        ...(Layout.isMobileDevice && {
          left: 'calc(50% - 20px)' as DimensionValue,
          top: 'calc(50% - 20px)' as DimensionValue,
        }),
      },
    }),
  },
  spring: {
    position: 'absolute',
    ...Platform.select({
      web: {
        transform: [
          {
            rotate: '69.42deg',
          },
        ],
        ...(Layout.isLargeDevice && {
          width: 202,
          height: 232,
          top: 20,
          left: -210,
        }),
        ...(Layout.isMobileDevice && {
          width: 78,
          height: 90,
          top: 60,
          left: 40,
        }),
      },
    }),
  },
  sparkles: {
    position: 'absolute',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 100,
          height: 100,
          bottom: -180,
          right: -220,
        }),
        ...(Layout.isMobileDevice && {
          width: 60,
          height: 60,
          top: 100,
          right: 30,
        }),
      },
    }),
  },
  title: {
    marginTop: 20,
  },
  filters: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginVertical: 12,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 7,
          marginBottom: 12,
        }),
      },
    }),
  },
  description: {
    marginBottom: 20,
    textAlign: 'center',
  },
  divider: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginVertical: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 20,
        }),
      },
    }),
  },
});
