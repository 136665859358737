import { useRef, useCallback, useEffect } from 'react';
import { DimensionValue, View } from 'react-native';
import Modal from 'react-native-modal';

import { StyleProps, TransparentView } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

interface ModalContainerProps {
  children: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
  snapPoints?: number[];
  style?: StyleProps['style'];
}

const touchPosition = (event: TouchEvent | MouseEvent) =>
  'touches' in event ? event.touches[0]! : event;

const getDefaultSnapPoint = (snapPoints?: number[]) =>
  snapPoints && snapPoints[0] ? (snapPoints[0] / window.innerHeight) * 100 : 50;

const disableTransition = (el: unknown) => {
  (el as HTMLDivElement).style.transition = '';
};

const enableTransition = (el: unknown, value: string) =>
  ((el as HTMLDivElement).style.transition = value);

export function BottomSheetModalContainer({
  children,
  onClose,
  isVisible,
  snapPoints,
  style,
}: ModalContainerProps) {
  const theme = useColorScheme();
  const containerRef = useRef<View>(null);
  const draggerRef = useRef<View>(null);

  const sheetHeight = useRef<number>(getDefaultSnapPoint(snapPoints)); // height in vh

  const setSheetHeight = useCallback((value: number) => {
    if (!containerRef.current) return;

    const containerDiv = containerRef.current as unknown as HTMLDivElement;
    sheetHeight.current = Math.max(0, Math.min(100, value));
    containerDiv.style.height = `${sheetHeight.current}vh`;
  }, []);

  useEffect(() => {
    if (!isVisible) {
      sheetHeight.current = getDefaultSnapPoint(snapPoints);
      return;
    }

    if (!draggerRef.current || !containerRef.current) {
      return;
    }
    enableTransition(containerRef.current, 'height 0.3s');

    const draggerDiv = draggerRef.current as unknown as HTMLDivElement;
    draggerDiv.addEventListener('mousedown', (event) => {
      onDragStart(event);
    });
    draggerDiv.addEventListener('touchstart', onDragStart);

    window.addEventListener('mousemove', onDragMove);
    window.addEventListener('touchmove', onDragMove);
    window.addEventListener('mouseup', onDragEnd);
    window.addEventListener('touchend', onDragEnd);
    return () => {
      window.removeEventListener('mousemove', onDragMove);
      window.removeEventListener('touchmove', onDragMove);
      window.removeEventListener('mouseup', onDragEnd);
      window.removeEventListener('touchend', onDragEnd);
    };
  }, [isVisible]);
  const dragPosition = useRef<number | undefined>(undefined); // height in vh

  const onDragStart = useCallback((event: TouchEvent | MouseEvent) => {
    disableTransition(containerRef.current);
    dragPosition.current = touchPosition(event).pageY;
  }, []);
  const onDragMove = useCallback((event: TouchEvent | MouseEvent) => {
    if (!dragPosition.current) return;

    const y = touchPosition(event).pageY;
    const deltaY = dragPosition.current - y;
    const deltaHeight = (deltaY / window.innerHeight) * 100;

    setSheetHeight(sheetHeight.current + deltaHeight);
    dragPosition.current = y;
  }, []);
  const onDragEnd = useCallback(() => {
    if (!dragPosition.current) {
      return;
    }
    dragPosition.current = undefined;
    enableTransition(containerRef.current, 'height 0.3s');

    if (sheetHeight.current < 40) {
      onClose();
    } else if (sheetHeight.current > 75) {
      setSheetHeight(100);
    } else {
      setSheetHeight(getDefaultSnapPoint(snapPoints));
    }
  }, []);

  return (
    <Modal
      useNativeDriver
      isVisible={isVisible}
      onBackdropPress={onClose}
      hideModalContentWhileAnimating
      style={{ padding: 0, margin: 0, justifyContent: 'flex-end' }}
    >
      <TransparentView
        style={[
          {
            backgroundColor: Colors[theme].background,
            height: `${sheetHeight.current}vh` as DimensionValue,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          style,
        ]}
        ref={containerRef}
      >
        <TransparentView
          style={{ alignItems: 'center', paddingVertical: 5 }}
          ref={draggerRef}
        >
          <TransparentView
            style={{
              backgroundColor: '#aaa',
              borderRadius: 2,
              height: 4,
              width: 50,
            }}
          />
        </TransparentView>
        {children}
      </TransparentView>
    </Modal>
  );
}
