import { useLayoutEffect, useRef } from 'react';
import { View } from 'react-native';

import { useCheckoutState } from '../../contexts/checkout';

const ScrollToMeIfSelected = ({
  children,
  nameId,
  scroll,
}: {
  children: React.ReactNode;
  nameId: string;
  scroll?: boolean;
}) => {
  const containerRef = useRef(null);

  const { dailyState: defaultChosenSlots } = useCheckoutState();

  const alreadyRun = useRef(false);
  useLayoutEffect(() => {
    if (alreadyRun.current) return;
    alreadyRun.current = true;
    if (
      scroll &&
      Object.keys(defaultChosenSlots?.[nameId] || {}).length > 0 &&
      containerRef.current
    ) {
      const position = (
        containerRef.current as HTMLElement
      ).getBoundingClientRect();

      setTimeout(() => {
        window.scrollTo({
          top: position.top + window.scrollY - 110,
        });
      }, 200);
    }
  }, [
    Object.keys(defaultChosenSlots?.[nameId] || {}).length,
    containerRef.current,
    scroll,
  ]);

  return (
    <>
      <View ref={containerRef} testID="booking-calendar-container-ref" />
      {children}
    </>
  );
};

export default ScrollToMeIfSelected;
