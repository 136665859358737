import { StyleSheet, DimensionValue, TouchableOpacity } from 'react-native';

import Rating from './Rating';
import { FeatureRatings } from '../../api/reviews';
import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import { Review } from '../../types/Reviews';
import { dateToNumeric } from '../../utils/date';
import { formatRating } from '../../utils/locations';
import { TextButton } from '../Button';
import { Icon } from '../Icon';
import { TransparentRow } from '../Row';
import { TextTag, TextBodySmall, TextBody, TextFinePrint } from '../StyledText';
import { TransparentView, View } from '../Themed';

const FeatureTitles: Record<keyof FeatureRatings, string> = {
  cleanliness: 'Cleanliness',
  caregivers: 'Teachers & Caregivers',
  curriculum: 'Curriculum & Activities',
  professionalism: 'Professionalism',
  indoor: 'Indoor Space',
  outdoor: 'Outdoor Space',
};

export function ReviewBlock({
  review,
  isUserReview,
  setEditingReview,
  shouldBeFullWidth,
  onReviewRendered,
  showMoreAndFocus,
  fullText,
  outdoor,
}: {
  review: Review;
  setEditingReview?: (r: Review) => void;
  showMoreAndFocus?: (id: number) => void;
  isUserReview?: boolean;
  shouldBeFullWidth?: boolean;
  onReviewRendered?: () => void;
  fullText?: boolean;
  outdoor: boolean;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(500);
  const isMissingText = review.text.length === 0;
  const textTooLong = !fullText && !isMissingText && review.text.length > 200;
  return (
    <View
      style={[
        styles.reviewBlock,
        shouldBeFullWidth && styles.reviewBlockFullWidth,
        isMobileDevice && styles.carouselItem,
        { borderColor: Colors[theme].lines },
        fullText && {
          height: 'auto',
        },
      ]}
      onLayout={onReviewRendered}
    >
      {isUserReview ? (
        <View
          style={{
            backgroundColor: Colors[theme].accent,
            position: 'absolute',
            top: 0,
            right: 0,
            paddingHorizontal: 10,
            paddingVertical: 5,
          }}
        >
          <TextTag>Your Review</TextTag>
        </View>
      ) : null}

      <TransparentRow style={styles.authorSection}>
        <View
          style={[
            styles.authorInitial,
            { backgroundColor: Colors[theme].accentSecondary },
          ]}
        >
          <TextBodySmall style={{ fontSize: 30, lineHeight: 50 }}>
            {review.author.initial}
          </TextBodySmall>
        </View>
        <View style={{ flex: 1 }}>
          <TextBody style={styles.authorInfo}>{review.author.name}</TextBody>
          <TextFinePrint
            style={{
              color: Colors[theme].textSecondary,
              lineHeight: 12,
            }}
          >
            {dateToNumeric(new Date(review.updated_at), {
              month: 'long',
              year: 'numeric',
            })}
          </TextFinePrint>
        </View>
        <View>
          {isUserReview ? (
            <TouchableOpacity onPress={() => setEditingReview?.(review)}>
              <Icon name="menu" />
            </TouchableOpacity>
          ) : null}
        </View>
      </TransparentRow>
      {isMissingText ? (
        <TransparentView>
          {Object.keys(review.features).map((featureKey) =>
            featureKey === 'outdoor' && !outdoor ? null : (
              <TransparentRow
                key={`${review.id}-${featureKey}`}
                style={[
                  styles.featureItem,
                  { marginBottom: 8 },
                  isMobileDevice && { marginBottom: 4 },
                ]}
              >
                <TextBodySmall style={{ lineHeight: 14 }}>
                  {FeatureTitles[featureKey as keyof FeatureRatings]}
                </TextBodySmall>
                <Rating
                  rate={
                    review.features[featureKey as keyof typeof review.features]
                  }
                />
              </TransparentRow>
            )
          )}
        </TransparentView>
      ) : (
        <>
          {review.rating > 0 ? (
            <TransparentRow style={{ justifyContent: 'flex-start' }}>
              <Rating rate={review.rating} />
              <TextBodySmall>{formatRating(review.rating)}</TextBodySmall>
            </TransparentRow>
          ) : null}
          <TextBodySmall style={{ marginTop: 10 }}>
            {textTooLong ? review.text.slice(0, 200) : review.text}
            {textTooLong ? '...' : null}
            {textTooLong ? (
              <>
                &nbsp;
                <TextButton onPress={() => showMoreAndFocus?.(review.id)}>
                  Show more
                </TextButton>
              </>
            ) : null}
          </TextBodySmall>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  reviewBlock: {
    overflow: 'hidden',
    borderWidth: 2,
    borderRadius: 10,
    padding: 20,
    minWidth: 'calc(50% - 20px / 2)' as DimensionValue,
    height: 283,
    flex: 0,
  },
  reviewBlockFullWidth: {
    width: '100%',
    flexBasis: 'auto',
    height: 'auto',
  },
  authorSection: {
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  authorInfo: {
    lineHeight: 12,
  },
  authorInitial: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  carouselItem: {
    minWidth: 'calc(100% - 40px)' as DimensionValue,
    height: 244,
  },

  featureItem: {
    minWidth: 'calc(50% - 36px / 2)' as DimensionValue,
    flex: 1,
    justifyContent: 'space-between',
  },
});
