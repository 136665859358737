import { useEffect, useState } from 'react';
import { DimensionValue, Platform, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { Location, previouslyBooked } from '../../api/search';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useMaxWidth } from '../../hooks/useResponsive';
import { IconButton, UnderlinedButton } from '../Button';
import HorizontalLocationBlockLarge from '../HorizontalLocationBlockLarge';
import { Icon } from '../Icon';
import { InnerContainer } from '../Layout';
import { LocationBlockLarge, LocationBlockMedium } from '../LocationBlocks';
import LocationSectionHeader from '../LocationSectionHeader';
import { SkeletonBlockLarge } from '../LocationSkeleton';
import { TransparentRow } from '../Row';
import { TransparentView } from '../Themed';
import SkeletonBlockMedium from '../location_blocks/SkeletonBlockMedium';

const usePastBookings = () => {
  const [pastBookings, setPastBookings] = useState<Location[] | undefined>();
  useEffect(() => {
    previouslyBooked().then((data) => {
      setPastBookings(data.data);
    });
  }, []);
  return pastBookings;
};

export default function BookAgain() {
  const navigation = useAppNavigation();
  const pastBookings = usePastBookings();

  const onViewAllPress = () => {
    navigation.push('BookAgain');
  };

  const isMediumDevice = useMaxWidth(1140);
  const isMobileDevice = useMaxWidth(600);

  const shouldShowSmallerImage = useMediaQuery({
    maxWidth: 1100,
    minWidth: 1001,
  });

  const [imageWidth, setImageWidth] = useState(270);
  if (pastBookings && pastBookings.length === 0) {
    return null;
  }

  if (isMobileDevice) {
    return (
      <InnerContainer>
        <LocationSectionHeader title="Book again">
          <IconButton
            icon={<Icon name="arrowForward" />}
            onPress={onViewAllPress}
            text={null}
          />
        </LocationSectionHeader>
        <TransparentRow
          style={[
            {
              width: '100%',
              justifyContent: 'flex-start',
              marginBottom: 30,
            },
            styles.carousel,
          ]}
        >
          {!pastBookings ? (
            <>
              <SkeletonBlockMedium />
              <SkeletonBlockMedium />
            </>
          ) : (
            pastBookings.map((location) => (
              <LocationBlockMedium
                key={`book-again-${location.id}`}
                location={location}
              />
            ))
          )}
        </TransparentRow>
      </InnerContainer>
    );
  }

  const SkeletonBlock = isMediumDevice
    ? SkeletonBlockLarge
    : HorizontalSkeleton;

  if (!pastBookings) {
    return (
      <InnerContainer>
        <LocationSectionHeader title="Book again" />
        <TransparentRow style={{ gap: 40, paddingBottom: 40 }}>
          <TransparentView style={{ flex: 1 }}>
            <SkeletonBlock />
          </TransparentView>
          <TransparentView style={{ flex: 1 }}>
            <SkeletonBlock />
          </TransparentView>
        </TransparentRow>
      </InnerContainer>
    );
  }

  const Block = isMediumDevice
    ? LocationBlockLarge
    : HorizontalLocationBlockLarge;

  return (
    <InnerContainer>
      <LocationSectionHeader title="Book again">
        <UnderlinedButton title="View All" onPress={onViewAllPress} />
      </LocationSectionHeader>
      <TransparentRow
        style={[
          { gap: 40, paddingBottom: 32, alignItems: 'flex-start' },
          isMobileDevice && { gap: 10 },
          isMobileDevice && styles.carousel,
        ]}
      >
        {pastBookings.map((location) => (
          <TransparentView
            key={`book-again-${location.id}`}
            style={{ flex: 1 }}
            onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
          >
            <Block
              location={location}
              style={[
                { width: 'auto', maxWidth: 580 },
                !isMobileDevice && { borderBottomWidth: 0 },
              ]}
              imageDimensions={
                shouldShowSmallerImage ? { width: 200, height: 148 } : undefined
              }
              width={imageWidth}
            />
          </TransparentView>
        ))}
      </TransparentRow>
    </InnerContainer>
  );
}

function HorizontalSkeleton() {
  return (
    <SkeletonBlockLarge
      horizontal
      photoStyle={{ width: 'calc(50% - 30px)' as DimensionValue }}
      detailsStyle={{ width: '50%' }}
    />
  );
}

const styles = StyleSheet.create({
  carousel: {
    ...Platform.select({
      web: {
        scrollbarWidth: 'none',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        gap: 10,
      },
    }),
  },
});
