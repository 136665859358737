import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { getBookings, getUpcomingBookings } from '../api/bookings';
import { Booking } from '../utils/api';
import { makeState, useStoreState } from '../utils/state';

const bookingsState = makeState<{
  loading: boolean;
  upcoming: Booking[];
  past: Booking[];
}>({
  loading: true,
  upcoming: [],
  past: [],
});

export function useFetchBookings() {
  useFocusEffect(
    useCallback(() => {
      if (!bookingsState.getValue().loading) {
        return;
      }

      getBookings().then((response) => {
        bookingsState.setValue({
          loading: false,
          ...response,
        });
      });
    }, [])
  );
}

export function useFetchBookingsCall() {
  return useCallback(() => {
    if (!bookingsState.getValue().loading) {
      return Promise.resolve();
    }

    return getBookings().then((response) => {
      bookingsState.setValue({
        loading: false,
        ...response,
      });
    });
  }, []);
}

export function useUpcomingBookings() {
  const [upcomingBookings, setUpcomingBookings] = useState<Booking[]>([]);
  useFocusEffect(
    useCallback(() => {
      getUpcomingBookings().then((bookings) => {
        setUpcomingBookings(bookings);
      });
    }, [])
  );
  return upcomingBookings;
}

export const invalidateBookings = () =>
  bookingsState.setValue((state) => ({ ...state, loading: true }));

export const clearBookingsState = () =>
  bookingsState.setValue({ past: [], upcoming: [], loading: true });

export const useBookingStoreState = () => useStoreState(bookingsState);
