import { useCalendarElementsHeights } from './HeightProvider';
import { TextButton } from '../Button';
import { TextCta } from '../StyledText';
import { TransparentView } from '../Themed';

export function ViewSelectedButton({
  showSelected,
  amount,
}: {
  showSelected: () => void;
  amount: number;
}) {
  const { height } = useCalendarElementsHeights();
  const shouldShowExtended = height >= 500;

  const text = shouldShowExtended ? (
    `View Selected Dates (${amount})`
  ) : (
    <>
      Dates:{'\n'}
      {amount}
    </>
  );
  return (
    <TextButton
      TextComponent={TextCta}
      style={[
        {
          textAlign: 'center',
          alignSelf: 'center',
          marginTop: 8,
        },
        !shouldShowExtended && {
          borderWidth: 1,
          borderColor: '#ccc',
          borderRadius: 25,
          paddingVertical: 5,
          paddingHorizontal: 10,
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 20,
        },
      ]}
      onPress={showSelected}
      testID="view-selected-button"
    >
      {text}
    </TextButton>
  );
}

export function ViewSelectedWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { height } = useCalendarElementsHeights();
  const shouldShowExtended = height >= 500;
  return (
    <TransparentView
      style={!shouldShowExtended && { flexDirection: 'row', gap: 10 }}
    >
      {children}
    </TransparentView>
  );
}
