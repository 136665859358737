import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, Platform, DimensionValue } from 'react-native';
import Modal from 'react-native-modal';

import { ReportPhotoButton } from './ReportPhoto';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import useBlockBodyScroll from '../../hooks/useBlockBodyScroll';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth } from '../../hooks/useResponsive';
import useScrollInfo from '../../hooks/useScrollInfo';
import { pauseAllWistiaVideos } from '../../utils/common';
import BumoCarousel, { BumoCarouselInstance } from '../BumoCarousel';
import { PrimaryButton } from '../Button';
import { Media } from '../Media';
import ModalCloseButton from '../ModalCloseButton';
import { PressableBackIcon, PressableForwardIcon } from '../PressableIcon';
import Row from '../Row';
import { TextBodySmall } from '../StyledText';
import { TransparentView, View } from '../Themed';

type ImageGalleryProps = {
  locationId: number;
  isVisible: boolean;
  images: string[];
  close: () => void;
};

function renderImages(images: string[], onClick: (imageIndex: number) => void) {
  const rendered = [];

  for (let i = 0; i < images.length; i += 3) {
    const currentImage = images[i];
    if (currentImage) {
      rendered.push(
        <Media
          key={i}
          style={styles.mainImage}
          source={currentImage}
          onPress={() => onClick(i)}
        />
      );
    }

    const nextImage = images[i + 1];
    const nextSecondImage = images[i + 2];

    if (nextImage && nextSecondImage) {
      rendered.push(
        <Row key={i + 1} style={styles.row}>
          <Media
            key={i + 1}
            wrapperStyle={{ flex: 1, maxWidth: '100%' }}
            style={[styles.smallImage]}
            source={nextImage}
            onPress={() => onClick(i + 1)}
          />
          <Media
            key={i + 2}
            wrapperStyle={{ flex: 1, maxWidth: '100%' }}
            style={styles.smallImage}
            source={nextSecondImage}
            onPress={() => onClick(i + 2)}
          />
        </Row>
      );
    } else if (nextImage) {
      rendered.push(
        <Media
          key={i + 1}
          style={styles.mainImage}
          source={nextImage}
          onPress={() => onClick(i + 1)}
        />
      );
    }
  }
  return rendered;
}

export default function ImageGallery({
  locationId,
  isVisible,
  close,
  images,
}: ImageGalleryProps) {
  const theme = useColorScheme();
  const [focusImage, setFocusImage] = useState<number | null>(null);
  const [activeIndex, setIndex] = useState(0);

  const ref = useRef<BumoCarouselInstance>(null);

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (focusImage !== null) {
          setFocusImage(null);
        } else close();
      }
    },
    [focusImage]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useBlockBodyScroll(isVisible);

  const scrollContainerRef = useRef(null);
  const hasScrolled = useScrollInfo(
    scrollContainerRef.current as unknown as HTMLElement
  );
  const isMobileDevice = useMaxWidth(430);

  const renderedImages = useMemo(
    () =>
      renderImages(images, (index) => {
        setFocusImage(index);
        setIndex(index);
        pauseAllWistiaVideos();
      }),
    [images]
  );

  if (!isVisible) {
    return null;
  }

  const previewImage = focusImage !== null ? images[focusImage] : null;

  const onArrowRight = () => {
    if (focusImage === null) return;
    if (focusImage === images.length - 1) return;
    setFocusImage(focusImage + 1);
  };
  const onArrowLeft = () => {
    if (focusImage === null) return;
    if (focusImage === 0) return;
    setFocusImage(focusImage - 1);
  };

  return (
    <Modal
      isVisible={isVisible}
      backdropColor="white"
      backdropOpacity={1}
      useNativeDriver={true}
      style={{ margin: 0 }}
      hideModalContentWhileAnimating={true}
    >
      <View style={styles.container} ref={scrollContainerRef}>
        <View
          style={[
            styles.header,
            hasScrolled && {
              shadowColor: Colors[theme].shadow,
              shadowRadius: 12,
              shadowOpacity: 0.8,
            },
          ]}
        >
          <Row style={{ paddingVertical: 10 }}>
            <PrimaryButton
              title="View More Details"
              onPress={close}
              style={[isMobileDevice && styles.headerButtonMobile]}
            />
          </Row>
          <ModalCloseButton
            absolute
            onPress={close}
            style={[styles.close, isMobileDevice && { top: 14 }]}
          />
        </View>
        <Modal
          isVisible={focusImage !== null}
          backdropColor="black"
          backdropOpacity={1}
          style={{ margin: 0 }}
          hideModalContentWhileAnimating={true}
        >
          <TransparentView style={styles.modalImageContainer}>
            {previewImage ? (
              <ReportPhotoButton
                locationId={locationId}
                photoUrl={isMobileDevice ? images[activeIndex]! : previewImage}
              />
            ) : null}
            <ModalCloseButton
              absolute
              onPress={() => {
                setFocusImage(null);
              }}
              style={styles.close}
              color={Colors[theme].background}
            />
            {previewImage ? (
              isMobileDevice ? (
                <>
                  <BumoCarousel
                    activeIndex={activeIndex}
                    renderedElements={1}
                    showArrows={false}
                    containerStyle={{ height: 210, width: '100%' }}
                    itemContainerStyle={{ height: 210 }}
                    ref={ref}
                    onIndexChange={setIndex}
                  >
                    {images.map((item, index) => (
                      <TransparentView
                        style={{
                          width: '100%',
                          height: 210,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Media
                          style={styles.mainImage}
                          source={item}
                          key={index}
                        />
                      </TransparentView>
                    ))}
                  </BumoCarousel>
                  <PressableForwardIcon
                    disabled={activeIndex === images.length - 1}
                    onPress={() => {
                      pauseAllWistiaVideos();
                      ref.current?.goToNext();
                    }}
                  />
                  <PressableBackIcon
                    disabled={activeIndex === 0}
                    onPress={() => {
                      pauseAllWistiaVideos();
                      ref.current?.goToPrev();
                    }}
                  />
                </>
              ) : (
                <DesktopMediaSlider
                  onArrowRight={onArrowRight}
                  onArrowLeft={onArrowLeft}
                  title={
                    focusImage !== null
                      ? `${focusImage + 1} / ${images.length}`
                      : undefined
                  }
                >
                  <Media
                    resizeMode="contain"
                    style={styles.modalImage}
                    source={previewImage}
                  />
                </DesktopMediaSlider>
              )
            ) : null}
          </TransparentView>
        </Modal>
        <View style={styles.gallery}>{renderedImages}</View>
      </View>
    </Modal>
  );
}

function DesktopMediaSlider({
  children,
  onArrowRight,
  onArrowLeft,
  title,
}: {
  children: React.ReactNode;
  onArrowRight: () => void;
  onArrowLeft: () => void;
  title?: string;
}) {
  const ref = useRef(null);
  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const element = ref.current;
    if (!element) return;
    function keyListener(event: KeyboardEvent) {
      if (event.key === 'ArrowRight') {
        onArrowRight();
      }
      if (event.key === 'ArrowLeft') {
        onArrowLeft();
      }
    }

    window.addEventListener('keydown', keyListener);
    return () => {
      window.removeEventListener('keydown', keyListener);
    };
  }, [onArrowRight, onArrowLeft]);
  return (
    <TransparentView
      style={[
        styles.modalImage,
        { justifyContent: 'center', alignItems: 'center' },
      ]}
      ref={ref}
    >
      {children}
      {title ? (
        <TextBodySmall style={styles.desktopPreviewTitle}>
          {title}
        </TextBodySmall>
      ) : null}
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',

    ...Platform.select({
      web: {
        overflowY: 'scroll',
        ...(Layout.isLargeDevice && {
          alignSelf: 'center',
        }),
      },
    }),
  },
  // @ts-ignore
  header: {
    top: 0,
    zIndex: 1,
    width: '100%',
    ...Platform.select({
      web: {
        position: 'sticky',
      },
    }),
  },
  headerButtonMobile: {
    paddingVertical: 7.5,
  },
  gallery: {
    alignItems: 'center',
    marginBottom: 100,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: '100%',
          marginTop: 50,
        }),
      },
    }),
  },
  row: {
    width: '100%',
    maxWidth: 700,
    justifyContent: 'space-between',
    gap: 10,
    ...(Layout.isLargeDevice && { gap: 20 }),
  },
  close: {
    top: 18,
  },
  mainImage: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          maxWidth: '100%',
          width: 700,
          height: 394,
          marginBottom: 20,
          backgroundColor: '#111',
        }),
        ...(Layout.isMobileDevice && {
          width: 375,
          height: 210,
          marginBottom: 10,
        }),
      },
    }),
  },
  smallImage: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          flex: 1,
          maxWidth: '100%',
          width: 340,
          height: 191,
          marginBottom: 20,
        }),
        ...(Layout.isMobileDevice && {
          width: 183,
          height: 103,
          marginBottom: 10,
        }),
      },
    }),
  },
  modalImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    width: '100%',
    height: 'calc(100% - 100px)' as DimensionValue,
  },
  desktopPreviewTitle: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'rgba(255,255,255,0.1)',
    bottom: 0,
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
  },
});
