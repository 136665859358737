import { Pressable, StyleSheet } from 'react-native';

import { Icon, IconName } from './Icon';
import { View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { hexToRgba } from '../utils/common';

export interface MarkerProps {
  lat: number;
  lng: number;
  active?: boolean;
  onPress?: () => void;
  iconName: IconName;
  backgroundColor: string;
  iconColor: string;
}

export default function Marker({
  onPress,
  active,
  iconName,
  backgroundColor,
  iconColor,
}: Omit<MarkerProps, 'lat' | 'lng'>) {
  return (
    <Pressable onPress={onPress}>
      <View
        style={[
          styles.dot,
          styles.outerDot,
          {
            backgroundColor: hexToRgba(backgroundColor, active ? 0.5 : 0.2),
          },
        ]}
      >
        <View style={[styles.dot, styles.innerDot, { backgroundColor }]}>
          <Icon name={iconName} color={iconColor} />
        </View>
      </View>
    </Pressable>
  );
}

export function LocationMarker(
  props: Omit<MarkerProps, 'iconName' | 'backgroundColor' | 'iconColor'> & {
    backgroundColorName?: keyof typeof Colors.light;
  }
) {
  const theme = useColorScheme();

  return (
    <Marker
      {...props}
      backgroundColor={Colors[theme][props.backgroundColorName || 'accent']}
      iconName="house"
      iconColor={Colors[theme].text}
    />
  );
}

export function SearchLocationMarker(
  props: Omit<MarkerProps, 'iconName' | 'backgroundColor' | 'iconColor'>
) {
  const theme = useColorScheme();

  return (
    <Marker
      {...props}
      iconName="location"
      backgroundColor={Colors[theme].accentTertiary}
      iconColor={Colors[theme].background}
    />
  );
}

const styles = StyleSheet.create({
  dot: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerDot: {
    width: 70,
    height: 70,
    borderRadius: 35,
    // @ts-ignore
    transform: 'translate(-50%, -50%)',
  },
  innerDot: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
});
