import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
} from 'react-native';

import BumoVerified from './BumoVerified';
import { LocationCtaButton } from './Button';
import { Image } from './Image';
import InfoPopup from './InfoPopup';
import LocationDetail from './LocationDetail';
import Row from './Row';
import { TextH4 } from './StyledText';
import { StyleProps, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { Booking } from '../utils/api';
import { formatBlackoutDates, isBookByWeek } from '../utils/bookings';
import { dateRangeToNumeric } from '../utils/date';
import { getHourFromDateTime } from '../utils/locations';

export type BookingBlockProps = StyleProps & {
  booking: Booking;
};

export default function UpcomingBooking({ booking }: BookingBlockProps) {
  const {
    id,
    dropoff,
    pickup,
    children: childIds,
    location: { name, verificationStatus, timezone, mainImage },
  } = booking;
  const dropoffDate = new Date(dropoff);
  const pickupDate = new Date(pickup);
  const { children = [] } = useParent();
  const now = new Date();
  const isNow = now >= dropoffDate && now <= new Date(pickup);
  const theme = useColorScheme();
  const { navigate } = useNavigation();
  const goToDetails = () => navigate('BookingDetails', { id: id.toString() });

  const isWeekly = isBookByWeek(booking);

  const bookingDateFormatted = isWeekly
    ? `${dateRangeToNumeric([dropoffDate, pickupDate], {
        month: 'short',
        day: 'numeric',
        timeZone: timezone,
      })} at ${getHourFromDateTime(dropoff)}`
    : dropoffDate.toLocaleString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

  const blackoutDayFormatted = formatBlackoutDates(booking);

  return (
    <Pressable onPress={goToDetails} style={styles.fullWidth}>
      <Row
        style={[
          styles.container,
          { borderColor: Colors[theme].lines },
          isNow && [styles.now, { backgroundColor: Colors[theme].accent }],
        ]}
      >
        <View style={[styles.imageSection, isNow && styles.transparent]}>
          <Image
            style={styles.image}
            source={mainImage as ImageSourcePropType}
          />
          {Layout.isLargeDevice && (
            <BumoVerified style={styles.verified} status={verificationStatus} />
          )}
        </View>
        <View
          style={[
            styles.details,
            isNow && styles.transparent,
            { overflow: 'hidden' },
          ]}
        >
          <TextH4>{name}</TextH4>
          <Row style={[styles.locationDetails, isNow && styles.transparent]}>
            <LocationDetail
              style={styles.locationDetail}
              icon="profile"
              text={children
                .filter((child) => childIds.includes(child.id))
                .map((child) => child.firstName)
                .join(', ')}
              {...(isNow && {
                iconStyle: { tintColor: Colors[theme].background },
              })}
            />
            <LocationDetail
              style={styles.locationDetail}
              icon="calendar"
              text={`${isNow ? 'Now, ' : ``} ${bookingDateFormatted}`}
              {...(isNow && {
                iconStyle: { tintColor: Colors[theme].background },
              })}
            />
            {blackoutDayFormatted ? (
              <>
                <LocationDetail
                  style={styles.locationDetail}
                  icon="dateBlackout"
                  text={`Blackout Date: ${blackoutDayFormatted}`}
                  iconStyle={{ tintColor: Colors[theme].error }}
                />
                <InfoPopup
                  useModalOnMobile
                  text="This date is not included in this weeklong booking and therefore you were not charged."
                  iconStyle={{ width: 20, height: 20, margin: 0 }}
                />
              </>
            ) : null}
          </Row>
          <Row style={[styles.buttons, isNow && styles.transparent]}>
            <LocationCtaButton
              style={styles.button}
              left="View Details"
              onPress={goToDetails}
            />
          </Row>
        </View>
      </Row>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
    maxWidth: 580,
  },
  container: {
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'flex-start',
    maxWidth: 580,
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {}),
        ...(Layout.isMobileDevice && {}),
      },
    }),
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  now: {
    borderWidth: 0,
  },
  imageSection: {
    margin: 10,
  },
  image: {
    borderRadius: 10,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 270,
          height: 200,
        }),
        ...(Layout.isMobileDevice && {
          width: 100,
          height: 100,
        }),
      },
    }),
  },
  verified: {
    left: 10,
    top: 10,
    position: 'absolute',
  },
  details: {
    flex: 1,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          alignSelf: 'center',
          marginLeft: 10,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 20,
        }),
      },
    }),
  },
  locationDetails: {
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 10,
        }),
      },
    }),
  },
  locationDetail: {
    marginRight: 10,
  },
  buttons: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 5,
          marginBottom: 10,
        }),
      },
    }),
  },
  button: {
    marginTop: 5,
    marginRight: 5,
  },
});
