import { dateStringToDate } from './dateStringToDate';
import getNewDate from './getNewDate';

export function getAgeInMonths(
  birthdate: string,
  testDate: Date = getNewDate()
) {
  const birth = new Date(birthdate);
  let months = (testDate.getFullYear() - birth.getFullYear()) * 12;
  months -= birth.getMonth();
  months += testDate.getMonth();
  if (testDate.getDate() < birth.getDate()) {
    months -= 1;
  }
  return months;
}

export function datestringUSFormatToCommonFormat(dateString: string) {
  // Split the date string into components
  const [month, day, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
}

export function getAge(birthdate: string) {
  const months = getAgeInMonths(birthdate);
  return months > 12
    ? `${Math.floor(months / 12)}y ${months % 12}mo`
    : `${months}mo`;
}

function isToday(date: Date) {
  return date.toDateString() === new Date().toDateString();
}

function isTomorrow(date: Date) {
  return date.toDateString() === tomorrow().toDateString();
}

function tomorrow() {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export function todayOrTomorrow(date: Date) {
  if (isToday(date)) return 'Today';
  else if (isTomorrow(date)) return 'Tomorrow';
  else return null;
}

export function dateStringToHuman(dateString: string) {
  const date = dateStringToDate(dateString);
  return todayOrTomorrow(date) || dateToNumeric(date);
}

export function dateStringToNumeric(
  dateString: string,
  options?: Intl.DateTimeFormatOptions
) {
  const date = dateStringToDate(dateString);
  return dateToNumeric(date, options);
}

export function dateStringRangeToNumeric(
  dateStrings: [string, string],
  options?: Intl.DateTimeFormatOptions
) {
  const [from, to] = dateStrings;
  // const dateFrom = new Date(`${from.replace(/-/g, '/')} 00:00`);
  // const dateTo = new Date(`${to.replace(/-/g, '/')} 00:00`);
  const dateFrom = dateStringToDate(from);
  const dateTo = dateStringToDate(to);

  if (from === to) {
    return dateToNumeric(dateFrom, options);
  }

  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const parts = dateTimeFormat.formatRangeToParts(dateFrom, dateTo);
  const formatted = parts
    .filter(
      (a) =>
        !(a.value.length === 4 && !Number.isNaN(a.value)) && a.value !== ', '
    )
    .map((a) => a.value)
    .join('');
  return formatted;
}

export function dateRangeToNumeric(
  dates: [Date, Date],
  options?: Intl.DateTimeFormatOptions
) {
  const [dateFrom, dateTo] = dates;

  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  return dateTimeFormat.formatRange(dateFrom, dateTo);
}

export function dateToNumeric(
  date: Date,
  options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' }
) {
  return date.toLocaleString('en-US', options);
}

export function dateToUSFormat(date: Date) {
  const localeString = date.toLocaleDateString('en-US');
  return localeString
    .split('/')
    .map((s) => s.padStart(2, '0'))
    .join('/');
}
export function getPrevMonthDate(d: Date) {
  const prevMonthDate = new Date(d);
  prevMonthDate.setMonth(d.getMonth() - 1);
  return prevMonthDate;
}

export function getNextMonthDate(d: Date) {
  const nextMonthDate = new Date(d);
  nextMonthDate.setMonth(d.getMonth() + 1);
  return nextMonthDate;
}

// used by react-calendar
export function formatShortWeekday(_: string | undefined, date: Date) {
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  return days[date.getDay()] as string;
}

export const DAYS = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as const;

export function getTodayAsDatestring(date = getNewDate()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatRanges(
  ranges: {
    rangeStart: string | null;
    rangeEnd: string | null;
  },
  opts: { short?: true } = {}
) {
  const { rangeStart, rangeEnd } = ranges;
  if (!rangeStart && !rangeEnd) {
    return null;
  }
  let valueString = ``;

  if (!rangeStart) {
    return null;
  }
  valueString += dateStringToNumeric(
    rangeStart,
    opts.short ? { month: 'short', day: 'numeric' } : undefined
  );
  if (rangeEnd && rangeEnd !== rangeStart) {
    valueString += ` - `;
    valueString +=
      new Date(rangeEnd).getMonth() === new Date(rangeStart).getMonth()
        ? dateStringToNumeric(rangeEnd, { day: 'numeric' })
        : dateStringToNumeric(
            rangeEnd,
            opts.short ? { month: 'short', day: 'numeric' } : undefined
          );
  }

  return valueString;
}
