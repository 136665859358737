import { decode } from 'blurhash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ImageSourcePropType, ImageStyle } from 'react-native';

import { Image as ImageComponent } from './Image';

const loadImage = (url: string) =>
  new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = url;
  });

const ImageQueue = (() => {
  const imagesLoaded = new Set();
  return {
    isImageLoaded: (source: string) => imagesLoaded.has(source),
    async loadImage(source: string) {
      if (imagesLoaded.has(source)) {
        return;
      }
      try {
        await loadImage(source);
        imagesLoaded.add(source);
      } catch (err) {
        console.error(err);
      }
    },
  };
})();

const placeholderHash = 'VUOp3v?GE0t7IV.TNwRixuaxxst7juR%kCjFM|kBRij?';
const pixels = decode(placeholderHash, 270, 200);

function PlaceholderImage() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useLayoutEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }
    const imageData = ctx.createImageData(270, 200);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
  }, []);
  return (
    <canvas
      ref={canvasRef}
      width={270}
      height={200}
      style={{ borderRadius: 10, width: '100%', height: '100%' }}
    />
  );
}

export default function DelayedImage(props: {
  src: string;
  visible?: boolean;
  style?: ImageStyle | ImageStyle[];
  width: number;
  height: number;
}) {
  const { src, visible } = props;
  const [loaded, setLoaded] = useState(ImageQueue.isImageLoaded(src));
  useEffect(() => {
    if (visible) {
      ImageQueue.loadImage(src).then(() => {
        setLoaded(true);
      });
    }
  }, [visible]);
  return loaded ? (
    <ImageComponent source={src as ImageSourcePropType} {...props} />
  ) : (
    <PlaceholderImage />
  );
}
