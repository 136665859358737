import React, { useState, useEffect } from 'react';

import ProgramPageFactory from './ProgramPage';
import { Tab, programPageSettings } from '../api/program_pages';
import SummerCampHeader from '../components/SummerCampHeader';
import SummerCampsPageHellobar, {
  isSummerCampVIPPackageAvailable,
} from '../components/hellobars/SummerCampsPageHellobar';
import { findCurrentCampaignKey } from '../utils/campaigns';

const SummerCampsPage = ProgramPageFactory({
  sectionTitle: 'Summer Sessions',
  HeaderComponent: SummerCampHeader,
  additionalSections: ['REVIEWS'],
  customHelloBar:
    findCurrentCampaignKey() === 'SUMMER_CAMPS_2025' &&
    isSummerCampVIPPackageAvailable() ? (
      <SummerCampsPageHellobar />
    ) : null,
  ignoreCampaignHeaderFn: (campaign) => campaign === 'SUMMER_CAMPS_2025',
});

const SummerCamps = () => {
  const [tabs, setTabs] = useState<Tab[]>([{ title: '', ids: [] }]);
  useEffect(() => {
    programPageSettings('summer_camps').then((settings) =>
      setTabs(settings.tabs)
    );
  }, []);

  return <SummerCampsPage tabs={tabs} />;
};

export default SummerCamps;
