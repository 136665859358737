import { getAuthorizationHeader } from './authorization';
import { getDeviceHeader } from './deviceInfo';

export const defaultRequest = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...getDeviceHeader(),
    ...getAuthorizationHeader(),
  },
};

export const defaultGetRequest = {
  ...defaultRequest,
  method: 'GET',
};

export const defaultAuthedRequest = {
  ...defaultRequest,
  credentials: 'include',
} as const;

export const defaultAuthedGetRequest = {
  ...defaultAuthedRequest,
  method: 'GET',
}; // TODO use env

export const getAuthedRequestHeaders = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...getDeviceHeader(),
    ...getAuthorizationHeader(),
  },
});
