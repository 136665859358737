.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-right-enter-active, .slide-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-right-exit, .slide-left-exit {
  opacity: 1;
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.rotate-infinite {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

}

@keyframes spin { 
  from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(360deg); 
  }
}