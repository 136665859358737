import throttle from 'lodash.throttle';
import { useEffect, useRef, useState } from 'react';

import { TransparentView } from '../components/Themed';
import { indicatorIDs } from '../components/location/TabbedMenu';
import { notNull } from '../utils/notnull';
import { makeState } from '../utils/state';

function getCurrentPositionID(
  positionsObject: Record<string, number>,
  containerOffset: number,
  OFFSET_TRESHOLD = 20
): string | null {
  const availablePositions = Object.entries(positionsObject).filter(
    ([, offsetY]) =>
      window.scrollY + containerOffset - OFFSET_TRESHOLD >= offsetY
  );
  const matchingPosition = availablePositions[availablePositions.length - 1];
  if (!matchingPosition) {
    return null;
  }
  return matchingPosition[0];
}

export const PositionIndicator = ({ id }: { id: string }) => {
  return <TransparentView nativeID={id} />;
};

const getCachedPositions = throttle(
  (initialPositions: { [key: string]: number }) => {
    const elements = indicatorIDs
      .map((id) => document.getElementById(id))
      .filter(notNull)
      .map(
        (el) => [el.id, el.getBoundingClientRect().y + window.scrollY] as const
      );

    const positionsCache = Object.fromEntries(elements);
    return {
      ...initialPositions,
      ...positionsCache,
    };
  },
  1000
);

export default function useScrollManager(
  initialValues: Record<string, number> = {},
  { containerOffset }: { containerOffset: number } = { containerOffset: 0 }
) {
  const mainOffset = useRef(containerOffset);
  const currentPositionManager = useRef(makeState<string | null>(null));

  const [currentActive, setCurrentActive] = useState<string | null>(
    'booking-indicator'
  );
  useEffect(() => {
    const unsubscribe =
      currentPositionManager.current.onChange(setCurrentActive);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    mainOffset.current = containerOffset;
  }, [containerOffset]);

  useEffect(() => {
    function listener() {
      const positionsC = getCachedPositions(initialValues);
      const positionID = getCurrentPositionID(positionsC, mainOffset.current);
      if (!positionID) {
        return;
      }
      currentPositionManager.current.setValue(positionID);
    }
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return {
    currentActive,
  };
}
