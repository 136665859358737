import { useEffect, useState } from 'react';

import { Confirmation, getConfirmation } from '../api/bookings';
import { useAuthState } from '../contexts/authentication';

const customerType = (ordersNumber: number) => {
  switch (ordersNumber) {
    case 1:
      return 'first_time_customer';
    case 2:
      return 'second_time_customer';
    default:
      return 'returning_customer';
  }
};

export default function GtmPurchase({ sessionId }: { sessionId: string }) {
  const [summary, setSummary] = useState<Confirmation | null>(null);
  const { parent } = useAuthState();

  useEffect(() => {
    const fetchSummary = async () => {
      const response = await getConfirmation(sessionId);
      if (!('status' in response)) {
        setSummary(response as Confirmation);
      }
    };
    if (sessionId) {
      fetchSummary().catch(console.error);
    }
  }, [sessionId]);

  useEffect(() => {
    if (summary && parent) {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'purchase',
        currency: 'USD',
        coupon: '',
        transaction_id: summary.id,
        payment_type: summary.paymentType,
        affiliation: 'Bumo',
        value: parseFloat(summary.amount),
        user_type: customerType(summary.ordersNumber),
        email: parent.email,
        first_name: parent.firstName,
        last_name: parent.lastName,
        items: [
          {
            item_id: summary.location.id,
            item_name: summary.location.name,
            currency: 'USD',
            coupon: '',
            discount: 0,
            item_brand: 'Bumo',
            item_category: 'Childcare',
            price: summary.location.pricing.group,
            quantity: parseFloat(summary.hours),
          },
        ],
      });
    }
  }, [summary, sessionId, parent]);

  return null;
}
