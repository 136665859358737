import { groupSlots } from './weekly';
import { PriceResponse } from '../api/locations';
import {
  BookingSlot,
  BookingSlotResponse,
  HoursResponse,
  Location,
  LocationResponse,
} from '../api/search';
import {
  ParentResponseObject,
  ChildResponseObject,
  LocationFormResponseObject,
  ContactResponseObject,
} from '../api/types';

export const snakeToCamel = (s: string) =>
  s.replace(/(_\w)/g, (k) => k[1]?.toUpperCase() || '');

export const snakeToTitle = (s: string) => capitalize(s).replace(/(_)/g, ' ');

export const capitalize = (s: string) =>
  (s[0]?.toUpperCase() || '') + s.slice(1);

export const titleize = (s: string) =>
  s.toLowerCase().split(' ').map(capitalize).join(' ');

export function parseContact({
  id,
  first_name,
  last_name,
  telephone,
  relationship,
}: ContactResponseObject) {
  return {
    id,
    firstName: first_name,
    lastName: last_name,
    telephone,
    relationship,
  };
}

export type Contact = ReturnType<typeof parseContact>;

function parsePhysician(contact: ContactResponseObject) {
  const { relationship, ...rest } = parseContact(contact);
  return rest;
}

export type Physician = ReturnType<typeof parsePhysician>;

export function parseChild({
  first_name,
  last_name,
  birthdate,
  potty_trained,
  forms_by_location = [],
  generic_form_submission_urls = {},
  contacts = [],
  ...rest
}: ChildResponseObject) {
  const physician = contacts.find((c) => c.contact_type === 'physician');
  return {
    firstName: first_name,
    lastName: last_name,
    birthdate,
    potty: potty_trained,
    locationForms: forms_by_location.map(({ name, forms }) => {
      return { name, forms: forms.map(parseLocationForm) };
    }),
    genericFormSubmissionUrls: generic_form_submission_urls,
    pickups: contacts
      .filter((c) => c.contact_type === 'pickup')
      .map(parseContact),
    emergencyContacts: contacts
      .filter((c) => c.contact_type === 'emergency')
      .map(parseContact),
    physician: physician ? parsePhysician(physician) : undefined,
    ...rest,
  };
}

export type Child = ReturnType<typeof parseChild>;

export function parseLocationForm({
  location_id,
  child_id,
  form_type,
  updated_at,
  ...rest
}: LocationFormResponseObject) {
  return {
    locationId: location_id,
    childId: child_id,
    formType: form_type,
    updated: updated_at,
    ...rest,
  };
}

export function parseParent({
  first_name,
  last_name,
  created_at,
  preferred_locations,
  care_credit_balance,
  children,
  submitted_hdyhau,
  referral_code,
  booked_locations,
  is_being_deleted,
  forms_reuse_consent,
  ...rest
}: ParentResponseObject) {
  return {
    firstName: first_name,
    registered: created_at,
    lastName: last_name,
    preferredLocations: preferred_locations,
    careCreditBalance: parseFloat(care_credit_balance),
    children: children?.map(parseChild) || [],
    submittedHdyhau: !!submitted_hdyhau,
    referralCode: referral_code,
    bookedLocations: booked_locations,
    isBeingDeleted: !!is_being_deleted,
    formsReuseConsent: !!forms_reuse_consent,
    ...rest,
  };
}
export type Parent = ReturnType<typeof parseParent>;

export type BookingStatusType =
  | 'intent'
  | 'paid'
  | 'payment_failed'
  | 'cancelled'
  | 'completed'
  | 'fixed_care'
  | 'custom_care';

export type BookingResponseObject = {
  location_id: number;
  payment_url: string | null;
  location: LocationResponse;
  child_ids: number[];
  id: number;
  status: BookingStatusType;
  parent_id: number;
  created_at: string;
  updated_at: string;
  dropoff: string;
  pickup: string;
  booking_slots: number[];
  stripe_session_id: string;
  stripe_payment_intent_id: string;
  'cancellable?': boolean | undefined;
  'refundable?': boolean | undefined;
  children?: number[];
  blackout_dates?: string[];
};

export function parseBooking(booking: BookingResponseObject) {
  const {
    location_id,
    location,
    children,
    child_ids,
    payment_url,
    blackout_dates,
    ...rest
  } = booking;

  return {
    locationId: location_id,
    paymentUrl: payment_url,
    location: parseLocation(location),
    isCancellable: rest['cancellable?'],
    isRefundable: rest['refundable?'],
    children: children || child_ids,
    blackoutDates: blackout_dates,
    ...rest,
  };
}

export type Booking = ReturnType<typeof parseBooking>;

export function parseBookingSlot(bookingSlot: BookingSlotResponse) {
  const { day_time, age_from, age_to, dependent, ...rest } = bookingSlot;
  return {
    dayTime: day_time,
    ageFrom: age_from,
    ageTo: age_to,
    dependent: dependent || ('independent' as const),
    ...rest,
  };
}

function parseHours(hoursObject: HoursResponse) {
  const { full_day, ...rest } = hoursObject;
  return {
    fullDay: full_day,
    ...rest,
  };
}

export function parsePrice(priceObject: PriceResponse) {
  const { age_from, age_to, price, ...rest } = priceObject;
  return {
    ageFrom: age_from,
    ageTo: age_to,
    price: parseFloat(price),
    ...rest,
  };
}

export function parseLocations(locations: LocationResponse[]) {
  return locations.map(parseLocation);
}

const dayTimeScores = {
  'all day': 0,
  morning: 1,
  afternoon: 2,
  evening: 3,
} as const;

export function parseLocation(location: LocationResponse): Location {
  const {
    age_from,
    age_to,
    name_id,
    slots = [],
    what_to_bring,
    schedule_call_url,
    book_tour_url,
    verification_status,
    hours,
    images = [],
    rating,
    daily_schedules,
    booking_modes = [],
    available_discounts = [],
    hourly_cancellation_threshold,
    price,
    main_image,
    custom_care_enabled,
    fixed_care_enabled,
    ...rest
  } = location;

  const slotsGrouped = slots
    .map(parseBookingSlot)
    .filter((s) => s.id !== null)
    .reduce(
      (acc, slot) => {
        const { id, ageFrom, ageTo, capacity } = slot;
        const accKey = slot.date + slot.dayTime;
        const existing = acc[accKey];
        if (existing) {
          existing.capacities.push({ id, ageFrom, ageTo, capacity });
        } else {
          acc[accKey] = {
            ...slot,
            capacities: [{ id, ageFrom, ageTo, capacity }],
          };
        }
        return acc;
      },
      {} as Record<
        string,
        BookingSlot & {
          capacities: {
            id: number;
            ageFrom: number;
            ageTo: number;
            capacity: number;
          }[];
        }
      >
    );

  const slotsWeekly =
    booking_modes.includes('weekly') && booking_modes.length === 1
      ? groupSlots(slots.map(parseBookingSlot))
      : null;
  return {
    ageFrom: age_from,
    ageTo: age_to,
    nameId: name_id,
    hours: (Array.isArray(hours) ? hours : []).map(parseHours),
    whatToBring: what_to_bring,
    verificationStatus: verification_status,
    scheduleCallUrl: schedule_call_url,
    videos: images?.filter((url) => url && !url.includes('http')),
    images: images?.filter((url) => url.includes('http')),
    media: images,
    bookTourUrl: book_tour_url,
    bookingMode: booking_modes,
    availableDiscounts: available_discounts,
    customCareEnabled: custom_care_enabled,
    fixedCareEnabled: fixed_care_enabled,
    slots: Object.values<BookingSlot>(slotsGrouped).sort(
      (slot1: BookingSlot, slot2: BookingSlot) => {
        return (
          new Date(slot1.date).valueOf() - new Date(slot2.date).valueOf() ||
          dayTimeScores[slot1.dayTime as keyof typeof dayTimeScores] -
            dayTimeScores[slot2.dayTime as keyof typeof dayTimeScores]
        );
      }
    ),
    slotsWeekly,
    cancellationThreshold: hourly_cancellation_threshold,
    reviews: {
      rating: rating || null,
    },
    dailySchedules: daily_schedules,
    price: price ? parseFloat(price) : null,
    mainImage: main_image,
    requiresLicensingForms: rest['requires_licensing_forms?'],
    ...rest,
  };
}

type GiftCardResponse = {
  giver_name: string | null;
  amount: number;
  code: string;
} | null;

export function parseGiftCard(giftCard: GiftCardResponse) {
  if (!giftCard) {
    return null;
  }
  const { giver_name, ...rest } = giftCard;
  return {
    giverName: giver_name,
    ...rest,
  };
}

export type GiftCard = ReturnType<typeof parseGiftCard>;

export function parseRedeemedGiftCard(
  giftCard: GiftCardResponse & { care_credit_balance: string }
) {
  const { care_credit_balance, ...rest } = giftCard || {};
  return {
    careCreditBalance: parseFloat(care_credit_balance),
    ...rest,
  };
}
