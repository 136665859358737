import { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { useShowHideButton } from './shared';
import { logIn, LogInSuccessful } from '../../api/authentication';
import { registerV2 } from '../../api/onboardingV2';
import { fetchParentLead } from '../../api/parents';
import { PrimaryButton, TextButton } from '../../components/Button';
import ModalCloseButton from '../../components/ModalCloseButton';
import {
  TextBodySmall,
  TextCtaButton,
  TextH3,
} from '../../components/StyledText';
import { FormInput } from '../../components/StyledTextInput';
import { SecondaryView, StyleProps, View } from '../../components/Themed';
import Colors from '../../constants/Colors';
import Links from '../../constants/Links';
import type { AuthenticateFnParams } from '../../contexts/authFlow';
import { useAuthDispatch } from '../../contexts/authentication';
import useColorScheme from '../../hooks/useColorScheme';
import { useForm } from '../../hooks/useForm';
import { useMaxWidth } from '../../hooks/useResponsive';
import { Parent } from '../../utils/api';
import { capitalizeFirstLetter, handleLink } from '../../utils/common';
import { pushAccountCreatedEvent } from '../../utils/gtm';
import { passwordValidation } from '../../utils/validations';

function getSourceFromParams({
  source,
}: {
  source?: AuthenticateFnParams['source'];
} = {}) {
  if (source === 'PAID_AD') {
    return source;
  }
  if (source === 'BUMO_PACKAGE_BUY') {
    return source;
  }
  return undefined;
}
const useRegistrationFlow = (tryLogin = true) => {
  const [loading, setLoading] = useState(false);

  const register = useCallback(
    async (email: string, password: string, params?: AuthenticateFnParams) => {
      setLoading(true);
      const source = getSourceFromParams(params);

      const queryParams = source
        ? { source }
        : params?.refferalCode
          ? { referral: params.refferalCode }
          : undefined;

      const result = await registerV2({
        email,
        password,
        queryParams,
      });
      setLoading(false);

      if (!result.ok) {
        if (
          result.error.email?.includes('has already been taken') &&
          tryLogin
        ) {
          const loginResult = await logIn({ email, password });
          if (loginResult.ok) {
            return {
              ok: true,
              login: true,
              result: loginResult,
            } as const;
          }
        }
        return {
          ok: false,
          errors: {
            email: result.error.email?.[0],
            password: result.error.password?.[0],
          },
        } as const;
      }
      const { parent, refresh_token, access_token } = result;
      return {
        ok: true,
        result: 'CREATED',
        parent,
        refresh_token,
        access_token,
      } as const;
    },
    []
  );

  return { loading, register };
};

function getHeaderText(source: AuthenticateFnParams['source']) {
  if (source === 'PAID_AD') {
    return 'Create a new Bumo account and get $50 in Child Care Credit*';
  }
  if (source === 'BUMO_PACKAGE_BUY') {
    return 'Apply BumoCredit to My Account';
  }
  return 'Create your Bumo account';
}

interface JoinNowModalConfig {
  onlyJoinAction: boolean;
  headerText: string;
  subText?: string;
  buttonText?: string;
  helperText?: string;
}

function extractNameFromCode(code?: string) {
  if (!code) {
    return '';
  }

  return code.replaceAll(/(\d)+$/g, '');
}

function getModalConfig(
  source: AuthenticateFnParams['source'],
  params?: AuthenticateFnParams
): JoinNowModalConfig {
  if (source === 'REFERRAL') {
    const name = extractNameFromCode(params?.refferalCode);
    return {
      onlyJoinAction: true,
      headerText: `Congrats! ${capitalizeFirstLetter(
        name.toLowerCase()
      )} Gave You $25 in Child Care Credit* 🎉`,
      subText:
        'Create your account to claim $25 in BumoCredit which you can use to book on-demand child care on Bumo right away!',
      buttonText: 'Claim $25 in Child Care Credit',
      helperText: '*Referral gift only applies to new accounts created.',
    };
  }

  return {
    headerText: getHeaderText(source),
    onlyJoinAction: false,
  };
}

export function JoinNowContent({
  style,

  showLoginModal,
  onSuccess,
  onForgotPasswordClick,
  params,
  onLeadUpdate,
  onFallbackLogin,
}: StyleProps & {
  showLoginModal: () => void;
  onForgotPasswordClick: () => void;
  onSuccess: (
    parent: Parent,
    tokens: { refreshToken: string; accessToken: string }
  ) => void;
  onFallbackLogin: (loginResult: LogInSuccessful) => void;
  params?: AuthenticateFnParams;
  onLeadUpdate?: (v: {
    email: string;
    name: string;
    telephone: string;
  }) => void;
}) {
  const theme = useColorScheme();
  const { state, dispatch, validateFields } = useForm<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  const [isLead, setIsLead] = useState(false);

  useEffect(() => {
    if (params?.leadToken) {
      fetchParentLead(params.leadToken).then((response) => {
        if (response.ok) {
          dispatch({
            type: 'field',
            field: 'email',
            value: response.body.email,
          });
          setIsLead(true);
          onLeadUpdate?.(response.body);
        }
      });
    }
  }, []);

  const { loading, register } = useRegistrationFlow(
    params?.source !== 'REFERRAL'
  );

  const tryRegister = useCallback(async () => {
    const v = validateFields(['email', 'password']);
    if (!v.ok) {
      return;
    }
    const result = await register(
      state.values.email,
      state.values.password,
      params
    );

    if (!result.ok) {
      if (result.errors.email && result.errors.email.length > 0) {
        dispatch({
          type: 'error',
          field: 'email',
          value: `Email ${result.errors.email}`,
        });
      }
      if (result.errors.password && result.errors.password.length > 0) {
        dispatch({
          type: 'error',
          field: 'password',
          value: `Password ${result.errors.password}`,
        });
      }
      return;
    }

    if (result.login) {
      onFallbackLogin(result.result);
      return;
    }

    if (result.ok) {
      onSuccess(result.parent, {
        refreshToken: result.refresh_token,
        accessToken: result.access_token,
      });
    }
  }, [state.values]);

  const { showing: showingPassword, Button: ShowHideButton } =
    useShowHideButton();

  const modalConfig = getModalConfig(params?.source, params);

  const { buttonText = 'Start Free Account', headerText } = modalConfig;

  return (
    <>
      <SecondaryView style={[styles.container, style]}>
        <TextH3 style={styles.headerText}>{headerText}</TextH3>
        {modalConfig.subText ? (
          <TextBodySmall style={styles.headerText}>
            {modalConfig.subText}
          </TextBodySmall>
        ) : null}

        <FormInput
          testID="email-address"
          placeholder="Email"
          value={state.values.email}
          error={state.errors.email}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          disabled={isLead}
          inputStyles={isLead ? { borderWidth: 0 } : {}}
          onChangeText={(value) =>
            dispatch({ type: 'field', field: 'email', value })
          }
        />
        <FormInput
          testID="password"
          placeholder="Password"
          value={state.values.password}
          error={state.errors.password}
          message={passwordValidation[1]?.message}
          onChangeText={(value) =>
            dispatch({ type: 'field', field: 'password', value })
          }
          secureTextEntry={!showingPassword}
          inputAction={ShowHideButton}
          textContentType="newPassword"
        />
        <PrimaryButton
          testID="register-action-button"
          title={buttonText}
          loading={loading}
          onPress={tryRegister}
          style={styles.registerButton}
        />
        {modalConfig.helperText ? (
          <TextBodySmall
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              marginTop: 10,
              color: Colors[theme].textSecondary,
            }}
          >
            {modalConfig.helperText}
          </TextBodySmall>
        ) : null}
        <TextBodySmall
          style={{
            marginTop: modalConfig.helperText ? 0 : 10,
            marginBottom: modalConfig.onlyJoinAction ? 0 : 50,
            textAlign: 'center',
            color: Colors[theme].textSecondary,
          }}
        >
          By selecting{' '}
          <TextBodySmall
            bold
            style={{
              color: Colors[theme].textSecondary,
            }}
          >
            {buttonText}
          </TextBodySmall>{' '}
          I agree to Bumo's{' '}
          <TextButton
            onPress={() => handleLink(Links.toc)}
            textStyle={{
              color: Colors[theme].textSecondary,
            }}
          >
            Terms of Use
          </TextButton>{' '}
          and acknowledge the{' '}
          <TextButton
            onPress={() => handleLink(Links.privacy)}
            textStyle={{
              color: Colors[theme].textSecondary,
            }}
          >
            Privacy Policy
          </TextButton>
          {params?.source === 'PAID_AD' ? (
            <>
              {'\n'}
              <TextBodySmall
                style={{
                  color: Colors[theme].textSecondary,
                }}
              >
                *Offer only applies to new accounts created
              </TextBodySmall>
            </>
          ) : null}
        </TextBodySmall>
        {modalConfig.onlyJoinAction ? null : (
          <>
            <TextCtaButton style={{ alignSelf: 'center' }}>
              Already have an account?
            </TextCtaButton>
            <PrimaryButton
              testID="login-button"
              title="Log In"
              style={{
                marginVertical: 15,
                backgroundColor: 'transparent',
                borderColor: Colors[theme].cta,
                borderWidth: 2,
                width: '100%',
              }}
              onPress={showLoginModal}
            />
            <TextButton
              style={{ alignSelf: 'center' }}
              onPress={onForgotPasswordClick}
            >
              Forgot password?
            </TextButton>
          </>
        )}
      </SecondaryView>
    </>
  );
}

export function JoinNowModal({
  isVisible,
  hide,
  showLoginModal,
  onForgotPasswordClick,
  onSuccess,
  params,
  onLeadUpdate,
  onFallbackLogin,
}: {
  isVisible: boolean;
  hide: () => void;
  showLoginModal: () => void;
  onForgotPasswordClick: () => void;
  onSuccess: () => void;
  onFallbackLogin: () => void;
  params?: Partial<AuthenticateFnParams>;
  onLeadUpdate: (v: { email: string; name: string; telephone: string }) => void;
}) {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(430);

  const authDispatch = useAuthDispatch();

  return (
    <Modal
      useNativeDriver
      isVisible={isVisible}
      onBackdropPress={hide}
      // @ts-expect-error react-native-web supports this to close the modal when hitting Escape
      onRequestClose={hide}
      style={{ margin: 0 }}
      hideModalContentWhileAnimating
      testID="join-now-modal-container"
    >
      <View
        style={[
          styles.modalContainer,
          isMobileDevice && styles.modalContainerMobile,
        ]}
        testID="join-now-modal"
      >
        <ModalCloseButton onPress={hide} absolute />
        <JoinNowContent
          onSuccess={(parent) => {
            authDispatch({
              type: 'login_success',
              value: { parent, refresh_token: '' },
            });
            pushAccountCreatedEvent(parent);
            onSuccess();
          }}
          onFallbackLogin={(result) => {
            authDispatch({
              type: 'login_success',
              value: { parent: result.parent, refresh_token: '' },
            });
            onFallbackLogin();
          }}
          showLoginModal={showLoginModal}
          onForgotPasswordClick={onForgotPasswordClick}
          style={{ backgroundColor: Colors[theme].background }}
          params={params}
          onLeadUpdate={onLeadUpdate}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 500 }),
  },
  modalContainer: {
    padding: 40,
    borderRadius: 10,
    alignSelf: 'center',
  },
  modalContainerMobile: {
    padding: 20,
    paddingTop: 60,
    flex: 1,
    width: '100%',
  },
  registerButton: {
    width: '100%',
    marginTop: 10,
  },
  headerText: { marginBottom: 20, alignSelf: 'center', textAlign: 'center' },
});
