import React, { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';

import InterestedInBooking from './InterestedInBooking';
import { Location, VerificationStatus } from '../../api/search';
import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import BookingCalendarContainer from '../BookingCalendarContainer';
import { useHelloMessageBarHeight } from '../HelloMessageBar';
import { View } from '../Themed';
import { useCurrentVisibleDay } from '../booking_calendar/CalendarTypeToggler';
import { useHeaderHeight } from '../layouts/HeaderContainer';

type RightColumnProps = Pick<
  Location,
  'verificationStatus' | 'id' | 'nameId'
> & {
  onPricePress?: () => void;
  hasWeeklyDiscount: boolean;
};

export function RightColumn({
  verificationStatus,
  id,
  nameId,
  onPricePress,
  hasWeeklyDiscount,
}: RightColumnProps) {
  const theme = useColorScheme();
  const currentVisibleDay = useCurrentVisibleDay();

  const shadowStyle = useMemo(
    () => ({
      shadowOpacity: 0.8,
      shadowRadius: 12,
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowColor: Colors[theme].shadow,
    }),
    [theme]
  );

  const headerHeight = useHeaderHeight();
  const helloBarHeight = useHelloMessageBarHeight(false);

  const columnStyle = {
    top: headerHeight + helloBarHeight + 20,
  };

  const calendarComponentMaxHeight =
    window.innerHeight - headerHeight - helloBarHeight - 40;

  const calendarHeight = Math.min(calendarComponentMaxHeight, 530);

  if (
    verificationStatus === VerificationStatus.VERIFIED ||
    verificationStatus === VerificationStatus.HIDDEN
  ) {
    return (
      <View style={[styles.calendarContainer, styles.column, columnStyle]}>
        <BookingCalendarContainer
          style={[
            styles.calendar,
            shadowStyle,
            { height: calendarHeight > 510 ? 'auto' : calendarHeight },
          ]}
          scroll={true}
          locationId={id}
          locationNameId={nameId}
          activeStartDate={currentVisibleDay}
          calendarHeight={calendarHeight}
          onPricePress={onPricePress}
          hasWeeklyDiscount={hasWeeklyDiscount}
        />
      </View>
    );
  } else {
    return (
      <View style={[styles.interested, styles.column, columnStyle]}>
        <InterestedInBooking
          style={[styles.calendar, shadowStyle]}
          locationId={id}
          verificationStatus={verificationStatus}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  calendarContainer: {
    width: 375,
  },
  interested: {
    width: 425,
  },
  // @ts-ignore
  column: Platform.select({
    web: {
      display: 'block',
      position: 'sticky',
    },
  }),
  calendar: {
    borderRadius: 10,
  },
});
