import { useEffect, useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { Platform, Pressable, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import { PrimaryButton } from './Button';
import { Icon } from './Icon';
import { TextH3 } from './StyledText';
import { FormInput, FormInputProps } from './StyledTextInput';
import { View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import {
  datestringUSFormatToCommonFormat,
  dateToUSFormat,
} from '../utils/date';

interface DataPickerModalProps {
  isVisible: boolean;
  close: () => void;
  value?: string | undefined;
  onSave: (newValue: string) => void;
}

function DatePickerModal({
  isVisible,
  close,
  value,
  onSave,
}: DataPickerModalProps) {
  const theme = useColorScheme();
  const [date, setDate] = useState<Date>(value ? new Date(value) : new Date());

  const checkTileActive = ({
    activeStartDate,
    date: cellDate,
  }: CalendarTileProperties) => {
    return dateToUSFormat(date) === dateToUSFormat(cellDate)
      ? 'react-calendar__tile--selected'
      : '';
  };

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={close}
      useNativeDriver={true}
      hideModalContentWhileAnimating={true}
    >
      <View
        testID="birthdate-input-modal"
        style={[
          styles.modalBody,
          { backgroundColor: Colors[theme].backgroundSecondary },
        ]}
      >
        <Pressable
          onPress={close}
          style={({ pressed }) => [
            {
              opacity: pressed ? 0.8 : 1,
            },
            styles.modalClose,
          ]}
        >
          <Icon name="close" color={Colors[theme].text} />
        </Pressable>
        <TextH3 style={styles.modalHeader}>Child's birthdate</TextH3>
        <Calendar
          next2Label={null}
          nextLabel={<Icon name="arrowForward" />}
          prevLabel={<Icon name="arrowBack" />}
          prev2Label={null}
          value={date}
          tileClassName={checkTileActive}
          onChange={setDate}
          locale="en-US"
        />
        <PrimaryButton
          style={styles.modalButton}
          onPress={() => onSave(dateToUSFormat(date))}
          title="Save"
          testID="birthdate-modal-save-button"
        />
      </View>
    </Modal>
  );
}

interface BirthdateInputProps {
  value?: string | undefined;
  error?: string;
  testID?: string;
  onChange: (value: string) => void;
  onCalendarChange: (value: string) => void;
  onEndEditing?: FormInputProps['onEndEditing'];
  onFocus?: () => void;
  style?: FormInputProps['style'];
  placeholder?: string;
}

const isValidUSDate = (value: string) => {
  const [month] = value.split('/');
  return (
    value.match(/\d{2}\/\d{2}\/[1-9]\d{3}/) !== null &&
    Number(month) <= 12 &&
    Number(month) > 0
  );
};

export default function BirthdateInput({
  value,
  error,
  style,
  onChange,
  onEndEditing,
  onFocus,
  onCalendarChange,
  testID,
  placeholder = "Child's Date of Birth",
}: BirthdateInputProps) {
  const [isModalVisible, setModalVisible] = useState(false);
  const closeModal = () => setModalVisible(false);

  const [innerValue, setInnerValue] = useState(
    dateToUSFormat(value ? new Date(value) : new Date())
  );
  const [internalErrorMessage, setInternalErrorMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    setInternalErrorMessage(null);
    if (isValidUSDate(innerValue)) {
      if (datestringUSFormatToCommonFormat(innerValue) === value) return;
      onChange(datestringUSFormatToCommonFormat(innerValue));
    } else if (innerValue.length >= 10) setInternalErrorMessage('Invalid date');
  }, [innerValue]);

  const onBlur = () => {
    setInnerValue(
      dateToUSFormat(value ? new Date(`${value}T00:00:00`) : new Date())
    );
  };

  return (
    <>
      <Pressable
        focusable={false}
        onPress={() => {
          onFocus?.();
          setModalVisible(true);
        }}
        testID={`${testID}-modal-trigger`}
      >
        <FormInput
          testID={testID}
          onEndEditing={onEndEditing}
          onFocus={onFocus}
          onBlur={onBlur}
          maskType="date"
          mask="99/99/9999"
          style={style}
          placeholder={placeholder}
          hint="MM/DD/YYYY"
          onChangeText={setInnerValue}
          value={innerValue}
          error={internalErrorMessage || error}
        />
        <Icon
          name="calendar"
          style={{
            position: 'absolute',
            top: 12.5,
            right: 8,
          }}
        />
      </Pressable>
      <DatePickerModal
        isVisible={isModalVisible}
        value={value}
        close={closeModal}
        onSave={(value) => {
          setInnerValue(value);
          onCalendarChange(datestringUSFormatToCommonFormat(value));
          onEndEditing?.({} as any);
          closeModal();
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  modalBody: {
    ...Platform.select({
      web: {
        width: 500,
        minHeight: 570,
        paddingHorizontal: 100,
        paddingVertical: 70,
        maxHeight: 550,
      },
      native: {
        paddingHorizontal: 40,
        paddingVertical: 50,
        maxHeight: 256,
      },
    }),
    alignSelf: 'center',
    alignItems: 'center',
    flex: 1,
  },
  modalHeader: {
    marginBottom: 26,
  },
  modalButton: {
    ...Platform.select({
      web: {
        height: 60,
        width: 300,
        marginTop: 20,
      },
    }),
    marginBottom: 12,
  },
  modalClose: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
});
