import { Kid } from '../components/KidsInput';
import { useCheckoutPropUpdate } from '../contexts/checkout';
import { useSearchStateUpdate } from '../contexts/search';

export const useUpdateKids = () => {
  const updateSearchState = useSearchStateUpdate();
  const updateCheckoutProp = useCheckoutPropUpdate();

  return (kids: Kid[], searchKids?: number[]) => {
    updateSearchState({
      kids:
        searchKids || kids.filter((kid) => kid.isChosen).map((kid) => kid.id),
      page: 1,
    });
    updateCheckoutProp(kids, 'kids');
  };
};
