import { Ref, useEffect, useLayoutEffect, useRef } from 'react';
import {
  ImageProps,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import DelayedImage from './DelayedImage';
import { Image } from './Image';
import { StyleProps, TransparentView } from './Themed';
import DEFAULT_IMAGE from '../assets/images/location_default.png';
import useOnScreen from '../hooks/useOnScreen';
import { pauseAllWistiaVideos } from '../utils/common';
import { Loader } from '../utils/wistia';

type MediaProps = (StyleProps & {
  source?: string;
  onPress?: () => void;
  wrapperStyle?: StyleProp<ViewStyle>;
  resizeMode?: ImageProps['resizeMode'];
}) &
  Partial<{ visible: boolean; width: number; height: number }>;

export const Video = ({ source, style }: { source: string } & StyleProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);
  useEffect(() => {
    if (!isOnScreen) {
      pauseAllWistiaVideos();
    }
  }, [isOnScreen]);

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }
    if (ref.current) {
      Loader.load();
      const el = ref.current as unknown as HTMLDivElement;
      el.classList.add(
        'wistia_embed',
        'wistia_async_' + source,
        'playerColor=2E5394'
      );
    }
  }, []);
  return <TransparentView ref={ref as unknown as Ref<View>} style={style} />;
};

export const Media = ({
  style,
  source,
  visible,
  onPress,
  wrapperStyle,
  ...props
}: MediaProps) => {
  if (source && !source.includes('http')) {
    return <Video style={style} source={source} />;
  }
  const imageSource = source || DEFAULT_IMAGE;

  const image =
    visible !== undefined && !!(props.width && props.height) ? (
      <DelayedImage
        src={imageSource}
        {...{
          visible,
          width: props.width,
          height: props.height,
        }}
        visible={visible}
        style={style as ImageStyle}
      />
    ) : (
      <Image
        style={style as ImageStyle}
        source={imageSource as ImageSourcePropType}
        {...props}
      />
    );

  if (onPress) {
    return (
      <Pressable onPress={onPress} style={wrapperStyle}>
        {image}
      </Pressable>
    );
  }
  return image;
};
