import { useEffect, useState } from 'react';

import { searchSlots, Location, comingSoon } from '../api/search';

export type ResultsObject = {
  data: Location[];
  hasErrored: boolean;
};

export type SearchParams = {
  rangeStart?: string | null;
  rangeEnd?: string | null;
  ages?: number[];
  age_ranges?: [number, number][];
  kids?: number[];
  lat?: number;
  lng?: number;
  radius?: number;
  location?: string;
  page: number;
  per_page?: number;
  dirty: boolean;
  type?: 'coming_soon';
};

const DEFAULT_PER_PAGE_AMOUNT = 10;

async function fetchComingSoon(
  page: number,
  latitude: number | undefined,
  longitude: number | undefined,
  ages?: number[]
) {
  const response = await comingSoon(latitude, longitude, page, ages);
  return {
    ...response,
    totalPages: Math.ceil(response.total / 10),
  };
}

async function searchLocationsSlots(params: Omit<SearchParams, 'dirty'>) {
  const response = await searchSlots({ ...params, v: '2' });
  if ('error' in response) {
    return response;
  }

  return {
    ...response,
    totalPages: Math.ceil(
      response.total / (params.per_page || DEFAULT_PER_PAGE_AMOUNT)
    ),
  };
}

export const useFetchSearchPage = (searchParams: SearchParams) => {
  const [isInitialRequest, setInitialRequest] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  const [results, setResults] = useState<
    ResultsObject & {
      totalPages: number;
      totalElements: number;
    }
  >({
    data: [],
    hasErrored: false,
    totalPages: -1,
    totalElements: -1,
  });

  useEffect(() => {
    const { dirty, type, ...allSearchParams } = searchParams;
    if (dirty) {
      return;
    }
    if (searchParams.location && (!searchParams.lat || !searchParams.lng)) {
      return;
    }

    let inactive = false;

    const runFetch = async () => {
      setIsFetching(true);
      const result =
        type === 'coming_soon'
          ? await fetchComingSoon(
              allSearchParams.page,
              allSearchParams.lat,
              allSearchParams.lng,
              allSearchParams.ages
            )
          : await searchLocationsSlots(allSearchParams);
      if (inactive) {
        return;
      }
      if ('error' in result) {
        setResults({
          data: [],
          hasErrored: true,
          totalElements: -1,
          totalPages: -1,
        });
      } else {
        const { data, total, totalPages } = result;
        if (searchParams.page > totalPages) {
          console.error('current page exceeds total pages number');
          // setSearchParams((s) => ({ ...s, page: 1 }));
          return;
        }
        setResults({
          data,
          hasErrored: false,
          totalPages,
          totalElements: total,
        });
      }
      setIsFetching(false);
      setInitialRequest(false);
    };

    runFetch();

    return () => {
      inactive = true;
    };
  }, [searchParams]);

  return {
    results,
    isFetching,
    isInitialRequest,
  };
};
