import { Fragment, useEffect, useState } from 'react';

interface TextWithLinksProps {
  text: string;
  links: string[];
  textStyle?: unknown;
  linkTextStyle?: unknown;
  linkComponent: React.ElementType;
  textComponent: React.ElementType;
  onPress?: () => void;
  clickFn: (value: string) => void;
}

export default function TextWithLinks({
  text,
  links,
  textStyle,
  linkTextStyle,
  linkComponent: LinkComponent,
  textComponent: TextComponent,
  onPress,
  clickFn,
}: TextWithLinksProps) {
  const [strings, setStrings] = useState<string[]>([]);

  useEffect(() => {
    setStrings(text.match(/(".*?"|[^"\s]+)(?=\s*|\s*$)/g) || []);
  }, [text]);

  return (
    <>
      {strings.map((str, index) => {
        if (str.includes('|')) {
          const [text, linkIndex] = str.replaceAll('"', '').split('|');
          const isDotNext = strings[index + 1] === '.';
          return (
            <Fragment key={index}>
              <LinkComponent
                style={[textStyle, linkTextStyle]}
                onPress={() => {
                  onPress?.();
                  if (linkIndex) {
                    clickFn(links[parseInt(linkIndex, 10)]!);
                  }
                }}
              >
                {text}
              </LinkComponent>
              {!isDotNext && <TextComponent style={textStyle}> </TextComponent>}
            </Fragment>
          );
        } else {
          return (
            <TextComponent
              style={textStyle}
              key={index}
            >{`${str} `}</TextComponent>
          );
        }
      })}
    </>
  );
}
