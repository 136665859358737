import React from 'react';
import { StyleSheet } from 'react-native';

import { Address } from '../../api/search';
import { TextBody, TextH3 } from '../StyledText';
import { StyleProps, View } from '../Themed';

export type MapProps = StyleProps & {
  address?: Address;
  latitude?: number;
  longitude?: number;
  details?: boolean;
  map: React.ReactNode;
  append?: React.ReactNode;
};

export default function MapContainer({
  latitude,
  longitude,
  address,
  details,
  style,
  map,
  append,
}: MapProps) {
  if (!latitude && !longitude && !address) return null;

  return (
    <View style={[styles.container, style]}>
      <TextH3>The Location</TextH3>
      {latitude && longitude && <View style={styles.map}>{map}</View>}
      {address && (
        <View style={styles.address}>
          {details ? (
            <TextBody style={styles.address}>{address.street}</TextBody>
          ) : null}
          <TextBody>{`${address.city}, ${address.state}`}</TextBody>
        </View>
      )}
      {append}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  map: {
    height: 200,
    marginTop: 15,
  },
  address: {
    marginTop: 15,
  },
});
