import { Location, VerificationStatus } from '../api/search';
import { DEFAULT_ROLE } from '../components/location/Director';
import { titleize } from '../utils/api';

export enum PDPTabId {
  GALLERY = 'GALLERY',
  ABOUT = 'ABOUT',
  DIRECTOR = 'DIRECTOR',
  PRICE = 'PRICE',
  SCHEDULE = 'SCHEDULE',
  LOCATION = 'LOCATION',
  REVIEWS = 'REVIEWS',
  MORE = 'MORE',
  OTHER = 'OTHER',
}

export const PDPTabs: {
  tabText: (location: Location, isMobileDevice: boolean) => string;
  id?: PDPTabId;
  pointerID: string;
  requiresTrigger?: boolean;
  forcedOffset?: number;
  mobileStrategy?: 'pointer';
  shouldBeVisible?: (location: Location) => boolean;
}[] = [
  {
    tabText: (_, isMobileDevice) => (isMobileDevice ? 'Booking' : 'Gallery'),
    pointerID: 'booking-indicator',
    forcedOffset: 0,
    mobileStrategy: 'pointer',
    id: PDPTabId.GALLERY,
  },
  {
    tabText: () => 'About',
    pointerID: 'about-indicator',
    id: PDPTabId.ABOUT,
    shouldBeVisible: (location: Location) =>
      !!location.about || (!!location.features && location.features.length > 0),
  },
  {
    tabText: (location) => {
      const role = location?.director?.role;
      return `${role ? titleize(role) : DEFAULT_ROLE} Bio`;
    },
    pointerID: 'director-indicator',
    shouldBeVisible: (location: Location) =>
      location.director && Object.keys(location.director).length > 0,
    id: PDPTabId.DIRECTOR,
  },
  {
    tabText: () => 'Price',
    pointerID: 'price-indicator',
    id: PDPTabId.PRICE,
    requiresTrigger: true,
  },
  {
    tabText: (location) =>
      `Daily Schedule${(location.dailySchedules?.length || 0) > 1 ? 's' : ''}`,
    id: PDPTabId.SCHEDULE,
    pointerID: 'daily-schedule-indicator',
    shouldBeVisible: (location: Location) =>
      !!location.dailySchedules && location.dailySchedules.length > 0,
  },
  {
    tabText: () => 'Location',
    pointerID: 'location-indicator',
    shouldBeVisible: (location: Location) =>
      !!location.latitude && !!location.longitude && !!location.address,
    id: PDPTabId.LOCATION,
  },
  {
    tabText: () => 'Reviews',
    id: PDPTabId.REVIEWS,
    pointerID: 'reviews-indicator',
    shouldBeVisible: (location: Location) =>
      location.verificationStatus === VerificationStatus.VERIFIED,
  },
  {
    tabText: () => 'More',
    id: PDPTabId.MORE,
    pointerID: 'more-indicator',
    shouldBeVisible: (location: Location) =>
      location.verificationStatus !== VerificationStatus.UNVERIFIED,
  },
  {
    tabText: () => 'Other Locations Nearby',
    id: PDPTabId.OTHER,
    pointerID: 'other-locations-indicator',
    requiresTrigger: true,
  },
];
