import { StyleSheet } from 'react-native';

import { StyleProps, View } from './Themed';
import Colors from '../constants/Colors';
import { findCurrentCampaign } from '../contexts/campaigns';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { CAMPAIGN_KEY, findCurrentCampaignKey } from '../utils/campaigns';

const currentCampaign = findCurrentCampaign();
const currentCampaignKey = findCurrentCampaignKey();
const shouldShowCampaignMessageBar =
  currentCampaign !== null && currentCampaign.helloBarMessage !== null;

export type ShouldIgnoreCampaignHeaderFn = (campaign: CAMPAIGN_KEY) => boolean;

export const useHelloMessageBarHeight = (
  force = true,
  shouldIgnoreCampaignHeaderFn?: ShouldIgnoreCampaignHeaderFn
) => {
  const isSmallDevice = useMaxWidth(600);
  const ignoring =
    shouldIgnoreCampaignHeaderFn === undefined
      ? false
      : currentCampaignKey !== null
        ? shouldIgnoreCampaignHeaderFn(currentCampaignKey)
        : false;
  if ((ignoring || !shouldShowCampaignMessageBar) && !force) {
    return 0;
  }
  return isSmallDevice ? 50 : 35;
};

export default function HelloMessageBar() {
  if (!shouldShowCampaignMessageBar || !currentCampaign.helloBarMessage) {
    return null;
  }

  const HelloMessageComponent = currentCampaign.helloBarMessage;
  return (
    <HelloMessageBarContainer force={false}>
      <HelloMessageComponent style={{ textAlign: 'center' }} />
    </HelloMessageBarContainer>
  );
}

export function HelloMessageBarContainer({
  children,
  style,
  force = true,
}: {
  children: React.ReactNode;
  force?: boolean;
} & StyleProps) {
  const theme = useColorScheme();
  const helloMessageBarHeight = useHelloMessageBarHeight(force);
  return (
    <View
      style={[
        {
          backgroundColor: Colors[theme].accent,
          height: helloMessageBarHeight,
        },
        styles.bar,
        style,
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  bar: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 101,
    paddingHorizontal: 10,
  },
});
