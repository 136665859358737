import Constants from 'expo-constants';
import GoogleMapReact from 'google-map-react';

import ChildrenProps from '../types/ChildrenProps';

type GoogleMapProps = ChildrenProps & GoogleMapReact.Props;

export default function GoogleMap({ children, ...otherProps }: GoogleMapProps) {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: Constants.expoConfig?.extra?.MAPS_KEY,
        libraries: ['places', 'geocoding'],
      }}
      {...otherProps}
    >
      {children}
    </GoogleMapReact>
  );
}
