import { StyleSheet, Pressable, Platform } from 'react-native';

import { OPACITY } from './Button';
import { Icon } from './Icon';
import { LogoCare } from './Logo';
import { SafeAreaView, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';

// TODO style props
export default function AuthHeader({ onBackPress, back }: any) {
  const theme = useColorScheme();
  return (
    <SafeAreaView
      style={[
        styles.container,
        {
          borderBottomColor: Colors[theme].lines,
          backgroundColor: Colors[theme].backgroundSecondary,
        },
      ]}
      edges={['top']}
    >
      {back && (
        <Pressable
          onPress={onBackPress}
          style={({ pressed }) => ({
            opacity: pressed ? OPACITY : 1,
          })}
        >
          <Icon
            name="arrowBack"
            color={Colors[theme].textSecondary}
            style={styles.icon}
          />
        </Pressable>
      )}
      {!back && <View style={styles.icon}></View>}
      <LogoCare />
      <View style={styles.icon}></View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingVertical: 20,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          paddingHorizontal: 120,
        }),
      },
    }),
    justifyContent: 'space-between',
  },
  icon: {
    backgroundColor: 'transparent',
    marginHorizontal: 26,
    width: 30,
    height: 30,
  },
});
