import { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { LocationBlockLarge, LocationBlockMedium } from './LocationBlocks';
import { LocationSection } from './LocationSection';
import { Location, availableNow } from '../api/search';
import Layout from '../constants/Layout';

export default function OurLocations({
  onViewAllPress,
}: {
  onViewAllPress: () => void;
}) {
  const [ourLocations, setOurLocations] = useState<Location[]>([]);

  useEffect(() => {
    availableNow().then((locations) => {
      setOurLocations(locations.data);
    });
  }, []);

  const Block = Layout.isMobileDevice
    ? LocationBlockMedium
    : LocationBlockLarge;

  return (
    <LocationSection
      title="Our Locations"
      Block={Block}
      locations={ourLocations}
      style={styles.section}
      contentStyle={styles.contentSection}
      amountDisplayed={4}
      onViewAllPress={onViewAllPress}
    />
  );
}

const styles = StyleSheet.create({
  section: {
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 20,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 30,
        }),
      },
    }),
  },
  contentSection: {
    ...Platform.select({
      web: {
        ...(Layout.isMobileDevice && {
          scrollbarWidth: 'none',
          flexWrap: 'nowrap',
          overflow: 'scroll',
          gap: 10,
        }),
      },
    }),
  },
});
