import { ImageStyle, Pressable, StyleProp, StyleSheet } from 'react-native';

import { Icon } from './Icon';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type ModalCloseButtonProps = StyleProps & {
  onPress: () => void;
  absolute?: boolean;
  iconStyle?: StyleProp<ImageStyle>;
  testID?: string;
  color?: string;
};

export default function ModalCloseButton({
  onPress,
  absolute,
  style,
  iconStyle,
  testID,
  color,
}: ModalCloseButtonProps) {
  const theme = useColorScheme();

  return (
    <Pressable
      testID={testID}
      onPress={onPress}
      accessibilityRole="button"
      accessibilityLabel="Close"
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.8 : 1,
        },
        absolute && styles.close,
        style,
      ]}
    >
      <Icon
        name="close"
        color={color || Colors[theme].text}
        style={iconStyle as ImageStyle}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  close: {
    zIndex: 1,
    position: 'absolute',
    top: 15,
    right: 25,
  },
});
