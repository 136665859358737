export async function copyToClipboard(valueToCopy: string) {
  if (navigator && navigator.clipboard) {
    await navigator.clipboard.writeText(valueToCopy);
  } else {
    const el = document.createElement('textarea');
    el.value = valueToCopy;
    document.body.append(el);
    try {
      el.select();
      document.execCommand('copy');
      el.remove();
    } catch (err) {
      console.error(err);
    }
  }
}
