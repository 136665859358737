import { useCallback, useEffect, useMemo, useState } from 'react';

import { comingSoon } from '../api/search';
import { ThreeParamsSearchHeader } from '../components/ThreeParamSearch';
import LocationsListWithMapLayout from '../components/layouts/LocationsListWithMap';
import { useSearchState } from '../contexts/search';
import useHideAvochato from '../hooks/useAvochato';
import { ResultsObject } from '../hooks/useFetchSearchPage';
import { useMaxWidth } from '../hooks/useResponsive';
import { pluralize } from '../utils/common';
import { resultsBucket } from '../utils/number';

async function fetchLocationsPage(
  page: number,
  latitude: number | undefined,
  longitude: number | undefined,
  ages?: number[]
) {
  const response = await comingSoon(latitude, longitude, page, ages);
  return {
    // total: 129,
    ...response,
    totalPages: Math.ceil(response.total / 10),
  };
}

export default function ComingSoon() {
  useHideAvochato();

  const {
    location: { latitude, longitude },
  } = useSearchState();

  const [total, setTotal] = useState(1);
  const [totalPages, setCurrentTotalPages] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState<ResultsObject>({
    data: [],
    hasErrored: false,
  });

  const [searchParams, setSearchParams] = useState({
    currentPage: 1,
    ages: [] as number[],
    location: {
      latitude,
      longitude,
    },
  });

  useEffect(() => {
    const fetch = async () => {
      setIsFetching(true);
      const { data, total } = await fetchLocationsPage(
        searchParams.currentPage,
        searchParams.location.latitude,
        searchParams.location.longitude,
        searchParams.ages
      );
      setIsFetching(false);
      setResults({
        data,
        hasErrored: false,
      });
      setTotal(total);
      setCurrentTotalPages(Math.ceil(total / 10));
    };
    fetch();
  }, [searchParams]);

  const goToNextPage = useCallback(() => {
    setSearchParams((s) => ({ ...s, currentPage: s.currentPage + 1 }));
  }, []);
  const goToPrevPage = useCallback(() => {
    setSearchParams((s) => ({ ...s, currentPage: s.currentPage - 1 }));
  }, []);
  const setCurrentPage = useCallback(
    (currentPage: number) =>
      setSearchParams((s) => ({
        ...s,
        currentPage,
      })),
    []
  );

  const pagination = useMemo(
    () => ({
      current: searchParams.currentPage,
      total: totalPages,
      goToNextPage,
      goToPrevPage,
      onPageClick: setCurrentPage,
    }),
    [searchParams.currentPage, totalPages]
  );

  const shouldDisplayMobileSearch = useMaxWidth(900);
  return (
    <>
      {shouldDisplayMobileSearch ? null : <ThreeParamsSearchHeader />}
      <LocationsListWithMapLayout
        isFetching={isFetching}
        results={results}
        pagination={pagination}
        title={
          total > 0
            ? pluralize(total, `${resultsBucket(total)} Result`)
            : 'Coming Soon'
        }
        withPureFilters
        onFiltersChange={(filters) =>
          setSearchParams((s) => ({
            ...s,
            ...filters,
            currentPage: 1,
          }))
        }
      />
    </>
  );
}
