import { useMemo, useState, Fragment } from 'react';
import { GestureResponderEvent, ImageStyle, StyleSheet } from 'react-native';
import { Carousel } from 'react-responsive-carousel';

import { TertiaryButton } from './Button';
import DelayedImage from './DelayedImage';
import Dots from './Dots';
import { PressableBackIcon, PressableForwardIcon } from './PressableIcon';
import { TransparentRow } from './Row';
import { ViewProps } from './Themed';

export type ImageCarouselProps = {
  style: ViewProps['style'];
  images?: string[];
  showMaximum: number;
  dimensions: {
    width: number;
    height: number;
  };
  onMoreButtonPress?: (e: GestureResponderEvent) => void;
};

export default function ImageCarousel({
  images = [],
  showMaximum,
  style,
  dimensions,
  onMoreButtonPress,
}: ImageCarouselProps) {
  const slicedImages = useMemo(() => images.slice(0, showMaximum), [images]);
  const [activeIndex, setIndex] = useState(0);
  return (
    <TransparentRow style={style}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        onChange={(index) => setIndex(index)}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        className="fill-image-carousel"
      >
        {slicedImages.map((img, index) => (
          <Fragment key={`${img}-${index}`}>
            <DelayedImage
              key={`${img}-${index}`}
              style={[style as ImageStyle, dimensions as ImageStyle]}
              visible={Math.abs(index - activeIndex) <= 1}
              src={img}
              {...dimensions}
            />
            {index === slicedImages.length - 1 ? (
              <TransparentRow style={styles.seeMoreButtonContainer}>
                <TertiaryButton title="See More" onPress={onMoreButtonPress} />
              </TransparentRow>
            ) : null}
          </Fragment>
        ))}
      </Carousel>
      <Dots
        activeIndex={activeIndex}
        amount={slicedImages.length}
        containerStyle={styles.dotsContainer}
      />
    </TransparentRow>
  );
}

function renderArrowPrev(clickHandler: () => void, hasPrev: boolean) {
  return (
    <PressableBackIcon
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clickHandler();
      }}
      disabled={!hasPrev}
    />
  );
}

function renderArrowNext(clickHandler: () => void, hasNext: boolean) {
  return (
    <PressableForwardIcon
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clickHandler();
      }}
      disabled={!hasNext}
    />
  );
}

const styles = StyleSheet.create({
  seeMoreButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  dotsContainer: {
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    bottom: 6,
  },
});
