import { StyleSheet, View } from 'react-native';

import { Icon } from './Icon';
import { ViewWithClassname } from './Themed';

export function LoadingCircle({ size = 32 }: { size?: number }) {
  return (
    <ViewWithClassname
      className="rotate-infinite"
      style={[
        {
          width: size,
          height: size,
          borderColor: '#000',
          opacity: 0.5,
          backgroundColor: 'transparent',
          borderWidth: 3,
          borderRadius: size / 2,
          borderTopColor: 'transparent',
        },
      ]}
    />
  );
}

export default function Loader({ size = 30 }: { size?: number }) {
  return (
    <View style={styles.loaderContainer} testID="Loader">
      <Icon name="loader" style={{ width: size, height: size }} />
      <ViewWithClassname
        className="rotate-infinite"
        style={[styles.spinnerContainer]}
      >
        <View style={[styles.loadingSpinner]} />
      </ViewWithClassname>
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'relative',
    width: 30,
    height: 30,
  },
  spinnerContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  loadingSpinner: {
    position: 'absolute',
    width: 4,
    height: 10,
    top: 0,
    backgroundColor: '#fff',
    left: '50%',
    transform: [{ translateX: -2 }],
  },
});
