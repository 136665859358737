import getNewDate from './getNewDate';
import { Hours, HourRange, DayHours, Address } from '../api/search';

export function getReadableAgeString(age: number) {
  return age > 12 ? `${Math.round((10 * age) / 12) / 10}yr` : `${age}mo`;
}

export function getAgeRangeText(ageFrom: number, ageTo: number) {
  const from = getReadableAgeString(ageFrom);
  const to = getReadableAgeString(ageTo);
  return `${from}-${to}`;
}

export function formatPricePerPeriod(
  price: number,
  period: 'hr' | 'hour',
  suffix = ''
) {
  const priceString = (price * 100) % 100 === 0 ? price : price.toFixed(2);
  return `$${priceString}${suffix}/${period}`;
}

export function formatPrice(price: number) {
  if (price < 0) {
    return `-$${(-1 * price).toFixed(2)}`;
  }
  return `$${price.toFixed(2)}`;
}

export function standardizeHours(hourString: string) {
  const [hour, minute] = hourString.split(':');
  if (!hour) {
    return null;
  }

  const period = hourString.toLowerCase().includes('am')
    ? ('am' as const)
    : ('pm' as const);

  return {
    hour: parseInt(hour, 10),
    minute: parseInt(minute || '0', 10),
    period,
  };
}

export function formatHours(hours: Hours | null) {
  if (!hours) return '';
  const todayHours = (getTodaysHours(hours) || {}).fullDay;
  const [start, end] = todayHours || [];
  if (!start || !end) return '';
  const startHour = standardizeHours(start);
  const endHour = standardizeHours(end);
  if (!startHour || !endHour) return '';
  return `${formatStandardHour(startHour)} - ${formatStandardHour(endHour)}`;
}

function formatStandardHour(hour: {
  hour: number;
  minute: number;
  period: 'am' | 'pm';
}) {
  const minuteString = hour.minute === 0 ? '' : `:${hour.minute}`;
  return `${hour.hour}${minuteString}${hour.period}`;
}

export function formatFullDayHours(hoursObject: DayHours) {
  if (hoursObject.fullDay?.length) {
    return formatHourRange(hoursObject.fullDay);
  } else return 'Closed';
}

export function formatHourRange(hourRange: HourRange) {
  if (hourRange?.length) {
    return `${hourRange[0]} - ${hourRange[1]}`;
  }
}

// should it be
export function dateToString(date?: Date | null) {
  if (!date) return null;
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
}

export function getHourFromDateTime(
  dateTime: string,
  timeZone: string = 'America/Los_Angeles'
) {
  // TODO figure this out. adds minutes but also 10:00
  return new Date(dateTime).toLocaleString('en-US', {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatDateHoursRange(since: string, to: string) {
  return formatHourRange([getHourFromDateTime(since), getHourFromDateTime(to)]);
}

export function getTodaysHours(hours: Hours) {
  const day = getNewDate().getDay();
  return hours[day];
}

const HOUR_REGEX = /(\d\d?):?(\d\d)?\s?(am|pm)/;
export function countHours(since: string, to: string) {
  const [, sinceHours, sinceMinutes = 0, sincePeriod] =
    since.toLowerCase().match(HOUR_REGEX) || [];
  const [, toHours, toMinutes = 0, toPeriod] =
    to.toLowerCase().match(HOUR_REGEX) || [];
  const shouldAddTwelve =
    (sincePeriod === 'am' && toPeriod === 'pm' && toHours !== '12') ||
    (sincePeriod === 'pm' && toPeriod === 'pm' && sinceHours === '12');
  const component = (shouldAddTwelve && 12) || 0;
  return (
    Number(toHours) +
    component -
    Number(sinceHours) +
    (Number(toMinutes) - Number(sinceMinutes)) / 60
  );
}

export function getDestinationName(
  address: Address = {
    street: '',
    city: '',
    neighbourhood: '',
    state: '',
    country: '',
    zip: '',
  }
) {
  return `${address.street}+${address.city}`.replace(/\s+/g, '+');
}

export function formatRating(rating: number | null) {
  return rating ? `${(Math.floor(rating * 100) / 100).toFixed(2)}` : '';
}

const stateLabelValues: Record<string, string> = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  'States of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
export function getStateAbbr(stateName: string | undefined) {
  return stateLabelValues[stateName || ''] || stateName;
}
