import { useCallback, useState } from 'react';

export const useModalState = (defaultState = false) => {
  const [isModalVisible, setIsModalVisible] = useState(defaultState);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  return {
    isModalVisible,
    showModal,
    closeModal,
  };
};
