import { lazy } from 'react';

import ProgramPageFactory from './ProgramPage';

const MusicLogo = lazy(() => import('../assets/images/music.svg'));

export default ProgramPageFactory({
  title:
    'Special sessions just for you and your family. Find a session and book today!',
  sectionTitle: 'Locations With Music',
  heroImageFilename: 'program-music.webp',
  heroImageAltText:
    'Teacher playing various instruments including drums, guitar, and maracas with a group of children',
  ids: [2925, 2926, 2927, 2928],
  LogoImage: MusicLogo,
});
