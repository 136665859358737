import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

export function handleLink(url: string, newTab: boolean = false) {
  return Platform.OS === 'web'
    ? newTab
      ? window.open(url, '_blank')
      : window.open(url, undefined)
    : WebBrowser.openBrowserAsync(url);
}

export function openLinkInTheSameTab(url: string) {
  if (Platform.OS === 'web') {
    window.location.href = url;
    return;
  }
  WebBrowser.openBrowserAsync(url);
}

export function round(amount: number) {
  // @ts-ignore
  return +(Math.round(amount + 'e+2') + 'e-2');
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function hexToRgba(hex: string, alpha = 1) {
  const matchResult = hex.match(/\w\w/g);
  if (!matchResult) {
    throw new Error('wrong hex value');
  }
  const [r, g, b] = matchResult.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function pluralize(count: number, singular: string, plural?: string) {
  return count === 1 ? singular : plural || singular + 's';
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export type CdnImageName =
  // deprecated
  | '1-review-avatar.webp'
  | '2-review-avatar.webp'
  | '3-review-avatar.webp'
  | '4-review-avatar.webp'
  | '5-review-avatar.webp'
  | '6-review-avatar.webp'
  // deprecated
  | 'bumo-credit-hero-image.webp'
  | 'BFCM-promo-hero-image.webp'
  | 'bumo-video-thumb.webp'
  | 'business-insider.webp'
  | 'city-burbank.webp'
  | 'city-century-city.webp'
  | 'city-glendale.webp'
  | 'city-los-angeles.webp'
  | 'city-pasadena.webp'
  | 'city-san-francisco.webp'
  | 'city-santa-monica.webp'
  | 'cloud.webp'
  | 'fast-company.webp'
  | 'forbes.webp'
  | 'gift1.webp'
  | 'gift2.webp'
  | 'gift3.webp'
  | 'gift4.webp'
  | 'hero-image-2.webp'
  | 'hero-image-3.webp'
  | 'hero-image-4.webp'
  | 'hero-image-sfa.webp'
  | 'los-angeles-daily-news.webp'
  | 'paid-ad-1.webp'
  | 'paid-ad-2.webp'
  | 'paid-ad-3.webp'
  | 'paid-ad-4.webp'
  | 'paid-ad-5.webp'
  | 'paid-ad-6.webp'
  | 'program-music.webp'
  | 'program-winter-wonderland.webp'
  | 'summer24-1.webp'
  | 'summer24-2.webp'
  | 'summer24-3.webp'
  | 'summer24-4.webp'
  | 'testimonial-1-maeve-c.webp'
  | 'testimonial-2-nina-s.webp'
  | 'testimonial-3-faimie-f.webp'
  | 'testimonial-4-whitney-k.webp'
  | 'testimonial-5-melinda-l.webp'
  | 'testimonial-6-onelia-m.webp'
  | 'testimonial-7-kristin-k.webp'
  | 'testimonial-8-alyssa-s.webp'
  | 'vogue.webp'
  | 'winter-wonderland-2024.webp'
  | 'winter-camps-intro-photo.webp'
  | 'works-for-employers.webp'
  | 'works-for-families.webp'
  | 'works-for-providers.webp'
  | JUT;

export type JUT =
  | 'IMG_2002.jpg'
  | 'IMG_0772.jpg'
  | 'IMG_2931.jpg'
  | 'IMG_0626.jpg'
  | 'IMG_6323.jpg';

export function getImageUrl(image: CdnImageName) {
  return `https://bumo-care.s3.us-west-1.amazonaws.com/0images/${image}`;
}

export function pauseAllWistiaVideos() {
  if (window.Wistia) {
    window.Wistia.api.all().forEach((video) => video.pause());
  }
}

export function getUnion(a: string[], b: string[]) {
  return Array.from(new Set(a.filter((value) => b.includes(value))));
}
