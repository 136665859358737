/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import { forwardRef, LegacyRef, Ref, useEffect, useRef } from 'react';
import {
  Text as DefaultText,
  View as DefaultView,
  TextInput as DefaultTextInput,
  ScrollView as DefaultScrollView,
  StyleSheet,
  Platform,
} from 'react-native';
import type { TextInput as TextInputRef } from 'react-native';
import {
  SafeAreaView as DefaultSafeAreaView,
  NativeSafeAreaViewProps,
} from 'react-native-safe-area-context';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
  bold?: boolean;
};

export type TextProps = ThemeProps &
  DefaultText['props'] & { forceStyles?: boolean };
export type TextInputProps = ThemeProps & DefaultTextInput['props'];
export type ViewProps = ThemeProps & DefaultView['props'];
export type ScrollViewProps = ThemeProps & DefaultScrollView['props'];
export type SafeAreaViewProps = ThemeProps & NativeSafeAreaViewProps;
export interface StyleProps {
  style?: ViewProps['style'];
}

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

// TODO add more styling
export const TextInput = forwardRef<TextInputRef, TextInputProps>(
  function TextInput(props, ref) {
    const { style, lightColor, darkColor, onEndEditing, ...otherProps } = props;
    const backgroundColor = useThemeColor(
      { light: lightColor, dark: darkColor },
      'background'
    );
    const theme = useColorScheme();

    return (
      <DefaultTextInput
        placeholderTextColor={Colors[theme].textSecondary}
        style={[
          {
            backgroundColor,
            borderColor: Colors[theme].lines,
          },
          style,
        ]}
        {...{
          [Platform.OS === 'web' ? 'onBlur' : 'onEndEditing']: onEndEditing,
        }}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export const View = forwardRef(
  (props: ViewProps, ref: LegacyRef<DefaultView>) => {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor(
      { light: lightColor, dark: darkColor },
      'background'
    );

    return (
      <DefaultView
        ref={ref}
        style={[{ backgroundColor }, style]}
        {...otherProps}
      />
    );
  }
);

export function SecondaryView(props: ViewProps) {
  return (
    <View
      lightColor={Colors.light.backgroundSecondary}
      darkColor={Colors.light.backgroundSecondary}
      {...props}
    />
  );
}

export const TransparentView = forwardRef(
  (props: ViewProps, ref: Ref<DefaultView>) => {
    const { style, ...otherProps } = props;
    return (
      <View
        ref={ref}
        style={[{ backgroundColor: 'transparent' }, style]}
        {...otherProps}
      />
    );
  }
);

export function ScrollView(props: ScrollViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );

  return (
    <DefaultScrollView style={[{ backgroundColor }, style]} {...otherProps} />
  );
}

export function SafeAreaView(props: SafeAreaViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );

  return (
    <DefaultSafeAreaView style={[{ backgroundColor }, style]} {...otherProps} />
  );
}

export function Divider({ style }: StyleProps) {
  const theme = useColorScheme();
  return (
    <View
      style={[
        {
          height: StyleSheet.hairlineWidth,
          backgroundColor: Colors[theme].lines,
        },
        style,
      ]}
    />
  );
}

// Hackaround for div's with classnames
// TODO refactor to HoC
export function ViewWithClassname(props: ViewProps & { className?: string }) {
  const ref = useRef<DefaultView | null>(null);
  const { className, ...rest } = props;
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }
    if (ref.current && className) {
      const el = ref.current as unknown as HTMLDivElement;
      className.split(' ').forEach((c) => el.classList.add(c));
    }
  }, []);
  return <TransparentView ref={ref} {...rest} />;
}
