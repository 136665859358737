import { StyleSheet } from 'react-native';

import { TransparentRow } from './Row';
import { TextH4 } from './StyledText';
import { StyleProps } from './Themed';
import { useMinWidth } from '../hooks/useResponsive';

export default function LocationSectionHeader({
  title,
  subtitle = null,
  children,
  style,
}: {
  title: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  style?: StyleProps['style'];
}) {
  const isLargeDevice = useMinWidth(430);

  return (
    <TransparentRow
      style={[styles.header, !isLargeDevice && styles.headerMobile, style]}
    >
      <TransparentRow>
        <TextH4 style={[isLargeDevice && styles.titleBig, { marginRight: 16 }]}>
          {title}
        </TextH4>
        {subtitle}
      </TransparentRow>
      {children}
    </TransparentRow>
  );
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 32,
    justifyContent: 'space-between',
  },
  headerMobile: {
    marginBottom: 10,
  },
  titleBig: {
    fontSize: 25,
    lineHeight: 32.5,
  },
});
