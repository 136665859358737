import { useMaxWidth, useMinWidth } from './useResponsive';
import FeatureFlags from '../constants/FeatureFlags';

export const useShouldDisplayMobileSearch = () => {
  const isMobileDevice = useMaxWidth(899);
  const shouldDisplayNewSearch = FeatureFlags.NEW_SEARCH;
  return isMobileDevice && shouldDisplayNewSearch;
};

export const useShouldDisplayNewSearch = () => {
  const isDesktopDevice = useMinWidth(900);
  const shouldDisplayNewSearch = FeatureFlags.NEW_SEARCH;
  return isDesktopDevice && shouldDisplayNewSearch;
};
