import { useIsFocused } from '@react-navigation/native';
import { useEffect, useMemo, useReducer } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import {
  GeocodingLibraryState,
  MapLibraryState,
} from '../components/GoogleMapLoader';
import { InnerContainer } from '../components/Layout';
import { SkeletonBlockLarge } from '../components/LocationSkeleton';
import { TextH2 } from '../components/StyledText';
import { ThreeParamsSearchHeader } from '../components/ThreeParamSearch';
import LocationsListWithMapLayout from '../components/layouts/LocationsListWithMap';
import { mapToMonthsRange } from '../components/search/KidsChooser';
import {
  ButtonState,
  useSearchButtonState,
  useUpdateSearchState,
} from '../contexts/threeparamsearch';
import useAppNavigation from '../hooks/useAppNavigation';
import { useOpenAvochatoAction } from '../hooks/useAvochato';
import { useParent } from '../hooks/useChild';
import { SearchParams, useFetchSearchPage } from '../hooks/useFetchSearchPage';
import { useMaxWidth } from '../hooks/useResponsive';
import { RootStackScreenProps } from '../types';
import { pluralize } from '../utils/common';
import { getAgeInMonths } from '../utils/date';
import { resultsBucket } from '../utils/number';

export default function Search(props: RootStackScreenProps<'Search'>) {
  const isFocused = useIsFocused();

  return !isFocused ? null : <SearchContent {...props} />;
}

async function getLocationParams(address: SearchParams['location']) {
  if (!address) {
    return null;
  }
  await MapLibraryState.loaderPromise;

  const locationInfo = await getGeocode({ address });
  return locationInfo[0] ? getLatLng(locationInfo[0]) : null;
}

function mapButtonStateToSearchParams(
  buttonState: ButtonState
): Partial<SearchParams> {
  return {
    rangeStart: buttonState.rangeStart,
    rangeEnd: buttonState.rangeEnd,
    location: buttonState.location.description,
    lat: buttonState.location.latitude,
    lng: buttonState.location.longitude,
    ...(buttonState.isLoggedIn
      ? {
          ages: buttonState.who
            .filter((child) => child.enabled)
            .map((child) => Number(child.ageInMonths)),
        }
      : {
          ages: undefined,
          age_ranges: Array.from(
            new Set(
              buttonState.who
                .filter((child) => child.enabled)
                .map((child) => child.age)
                .filter((a): a is string => a !== null)
            )
          ).map((v) => mapToMonthsRange(v)),
        }),
  };
}

const useSearchParamsReducer = (initialParams: Partial<SearchParams>) => {
  const buttonState = useSearchButtonState();
  const [searchParams, dispatch] = useReducer(
    (
      state: SearchParams,
      action:
        | {
            type: 'NEXT_PAGE';
          }
        | {
            type: 'PREV_PAGE';
          }
        | {
            type: 'SET_PAGE';
            page: number;
          }
        | {
            type: 'SET_PARAMS';
            params: Partial<SearchParams>;
          }
    ) => {
      switch (action.type) {
        case 'NEXT_PAGE':
          return {
            ...state,
            page: state.page + 1,
          };
        case 'PREV_PAGE':
          return {
            ...state,
            page: state.page - 1,
          };
        case 'SET_PAGE':
          return {
            ...state,
            page: action.page,
          };
        case 'SET_PARAMS':
          return {
            ...state,
            ...action.params,
            dirty: false,
          };
        default:
          return state;
      }
    },
    {
      page: 1,
      ...initialParams,
      dirty: buttonState.dirty,
    }
  );

  const { setButtonState } = useUpdateSearchState();
  const navigation = useAppNavigation();

  const update = (buttonState: ButtonState) =>
    dispatch({
      type: 'SET_PARAMS',
      params: {
        ...mapButtonStateToSearchParams(buttonState),
        page: 1,
      },
    });

  useEffect(() => {
    if (!buttonState.dirty) {
      return;
    }

    update(buttonState);

    navigation.setParams({
      location: buttonState.location.description
        ? encodeURIComponent(buttonState.location.description)
        : undefined,
    });
    setButtonState({ dirty: false });
  }, [buttonState.dirty]);

  return {
    searchParams,
    updateSearchParams: dispatch,
  };
};

function safeDecodeUrlParam(location: string | undefined) {
  try {
    return location ? decodeURIComponent(location) : undefined;
  } catch {
    return undefined;
  }
}

function safeDecodeCoords({ lat, lng }: Partial<{ lat: string; lng: string }>) {
  const latAsNumber = Number(lat);
  const lngAsNumber = Number(lng);
  if (isNaN(latAsNumber) || isNaN(lngAsNumber)) {
    return null;
  }
  return { lat: latAsNumber, lng: lngAsNumber };
}

function safeDecodeDates({
  dateFrom,
  dateTo,
}: Partial<{ dateFrom: string; dateTo: string }>) {
  const rangeStart = Date.parse(dateFrom || '');
  const rangeEnd = Date.parse(dateTo || '');

  if (isNaN(rangeStart)) return null;

  return {
    rangeStart: dateFrom,
    rangeEnd: isNaN(rangeEnd) ? undefined : dateTo,
  };
}

async function geocodeCoords(location: { lat: number; lng: number }) {
  const library = await GeocodingLibraryState.loaderPromise;
  const coder = new library.Geocoder();
  const { results } = await coder.geocode({
    location,
  });
  const sublocality = results.find(
    (r) => r.types.includes('political') && r.types.includes('sublocality')
  );
  if (sublocality) {
    return sublocality.formatted_address;
  }
  const locality = results.find(
    (r) => r.types.includes('political') && r.types.includes('locality')
  );
  if (!locality) {
    throw new Error('Could not find address');
  }
  return locality?.formatted_address;
}

function SearchContent({ route }: RootStackScreenProps<'Search'>) {
  const navigation = useAppNavigation();
  const { children: kids = [] } = useParent();

  const openAvochato = useOpenAvochatoAction();

  useEffect(() => {
    const openAvochatoAction = () => {
      const now = new Date();
      const day = now.getDay();
      const hours = now.getHours();

      if (day === 0 || day === 6) return; // sunday, saturday
      if (hours < 8 || hours >= 18) return;

      openAvochato();
    };
    const timeoutId = setTimeout(openAvochatoAction, 20000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const { page, location, lat, lng, dateFrom, dateTo } = route.params || {};

  const routeParams = {
    page: Number(page) || 1,
    location: safeDecodeUrlParam(location),
    ...safeDecodeCoords({ lat, lng }),
    ...safeDecodeDates({ dateFrom, dateTo }),
  };

  const { searchParams, updateSearchParams } = useSearchParamsReducer({
    ...routeParams,
    ...(kids.length > 0
      ? { ages: kids.map((kid) => getAgeInMonths(kid.birthdate)) }
      : {}),
  });

  const { setInputState, setButtonState } = useUpdateSearchState();

  const finalSearchParams = useMemo(
    () => ({
      ...searchParams,
      per_page: 30,
    }),
    [searchParams]
  );
  const { results, isFetching, isInitialRequest } =
    useFetchSearchPage(finalSearchParams);

  useEffect(() => {
    if (searchParams.location && (!searchParams.lat || !searchParams.lng)) {
      getLocationParams(searchParams.location)
        .then((coordinates) => {
          updateSearchParams({
            type: 'SET_PARAMS',
            params: {
              lat: coordinates?.lat,
              lng: coordinates?.lng,
            },
          });
          const searchStateUpdate = {
            location: {
              description: searchParams.location,
              latitude: coordinates?.lat,
              longitude: coordinates?.lng,
            },
            ...{
              rangeStart: searchParams.rangeStart,
              rangeEnd: searchParams.rangeEnd,
            },
          };
          setInputState(searchStateUpdate);
          setButtonState(searchStateUpdate);
        })
        .catch(() => {
          navigation.setParams({
            location: undefined,
          });
        });
    } else if (
      route.params?.lat &&
      route.params?.lng &&
      !searchParams.location
    ) {
      const locationCoords = safeDecodeCoords({
        lat: route.params.lat,
        lng: route.params.lng,
      });

      if (!locationCoords) {
        return;
      }
      geocodeCoords(locationCoords).then((locationAsString) => {
        setButtonState({
          location: {
            description: locationAsString,
            latitude: locationCoords.lat,
            longitude: locationCoords.lng,
          },
        });
        setInputState({
          location: {
            description: locationAsString,
            latitude: locationCoords.lat,
            longitude: locationCoords.lng,
          },
        });
        navigation.setParams({
          location: encodeURIComponent(locationAsString),
          lat: undefined,
          lng: undefined,
        });
      });
    }
  }, []);

  const customContent = isInitialRequest ? (
    <InnerContainer
      style={{
        overflow: 'hidden',
        height: '100%',
        justifyContent: 'flex-start',
        paddingVertical: 70,
      }}
    >
      <TextH2
        style={{
          marginBottom: 100,
        }}
        testID="initial-loading-text"
      >
        Searching locations...
      </TextH2>
      <SkeletonBlockLarge horizontal />
      <SkeletonBlockLarge horizontal />
      <SkeletonBlockLarge horizontal />
      <SkeletonBlockLarge horizontal />
    </InnerContainer>
  ) : null;

  const pagination = useMemo(
    () => ({
      total: results.totalPages,
      current: searchParams.page,
      onPageClick: (page: number) =>
        updateSearchParams({ type: 'SET_PAGE', page }),
      goToNextPage: () => updateSearchParams({ type: 'NEXT_PAGE' }),
      goToPrevPage: () => updateSearchParams({ type: 'PREV_PAGE' }),
    }),
    [searchParams, results]
  );

  useEffect(() => {
    if (String(pagination.current) !== String(route.params?.page)) {
      navigation.setParams({
        page: pagination.current,
      });
    }
  }, [pagination, route.params]);

  const title = useMemo(
    () =>
      results?.hasErrored
        ? 'No results'
        : pluralize(
            results.totalElements || 0,
            `${resultsBucket(results.totalElements)} Result`
          ),
    [results]
  );

  const shouldDisplayMobileSearch = useMaxWidth(900);

  return (
    <>
      {shouldDisplayMobileSearch ? null : <ThreeParamsSearchHeader />}
      <LocationsListWithMapLayout
        customContent={customContent}
        results={results}
        pagination={pagination}
        title={title}
        isFetching={isFetching}
      />
    </>
  );
}
