export const Loader = {
  loaded: false,
  load() {
    if (this.loaded) {
      return;
    }

    addScript('https://fast.wistia.net/assets/external/E-v1.js');
    this.loaded = true;
  },
};

function addScript(src: string) {
  const s = document.createElement('script');
  s.setAttribute('src', src);
  s.setAttribute('defer', 'defer');
  document.body.appendChild(s);
}
