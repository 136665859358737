import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Pricing } from '../../api/search';
import FeatureFlags from '../../constants/FeatureFlags';
import { useMaxWidth } from '../../hooks/useResponsive';
import {
  formatPricePerPeriod,
  formatRating,
  getAgeRangeText,
} from '../../utils/locations';
import LocationDetail from '../LocationDetail';
import { TransparentRow } from '../Row';

interface DetailsProps {
  ageFrom: number;
  ageTo: number;
  city: string;
  neighbourhood: string;
  distance: number;
  containerStyle?: StyleProp<ViewStyle>;
  detailStyle?: StyleProp<ViewStyle>;
  reviews?: {
    rating: null | number;
  };
  bookByWeek?: boolean;
  price?: number | null;
  pricing?: Pricing;
}

export default function Details(props: DetailsProps) {
  const {
    ageFrom,
    ageTo,
    city,
    neighbourhood,
    distance,
    detailStyle,
    reviews,
    containerStyle,
    bookByWeek,
    price,
    pricing,
  } = props;
  const isMobileDevice = useMaxWidth(430);

  const priceInfo = price ? (
    <LocationDetail
      icon="priceTag"
      text={formatPricePerPeriod(price, 'hr', '+')}
    />
  ) : pricing?.group ? (
    <LocationDetail
      icon="priceTag"
      text={formatPricePerPeriod(pricing.group, 'hr')}
    />
  ) : null;

  return (
    <TransparentRow
      style={[
        styles.container,
        isMobileDevice && styles.containerMobile,
        containerStyle,
      ]}
    >
      {reviews?.rating ? (
        <LocationDetail
          style={detailStyle}
          icon="star"
          text={formatRating(reviews.rating)}
        />
      ) : null}
      {FeatureFlags.GEOLOCATION && (
        <LocationDetail
          style={detailStyle}
          icon="location"
          text={`${distance.toFixed(1)} mi`}
        />
      )}
      {!FeatureFlags.GEOLOCATION && (
        <LocationDetail
          style={detailStyle}
          icon="location"
          text={`${distance ? `${distance.toFixed(1)} mi, ` : ''}${
            neighbourhood || city
          }`}
        />
      )}
      <LocationDetail
        style={detailStyle}
        icon="profile"
        text={getAgeRangeText(ageFrom, ageTo)}
      />
      {bookByWeek ? (
        <LocationDetail
          style={detailStyle}
          icon="dateRange"
          text="Book by Week"
        />
      ) : null}
      {priceInfo}
    </TransparentRow>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    minHeight: 40,
    alignItems: 'flex-start',
  },
  containerMobile: {
    minHeight: 1,
  },
});
