import { Platform, StyleSheet } from 'react-native';

import { UnderlinedButton } from './Button';
import { LocationBlockProps } from './LocationBlocks';
import Row from './Row';
import { TextH4 } from './StyledText';
import { StyleProps, View } from './Themed';
import { BookingBlockProps } from './UpcomingBooking';
import { Location } from '../api/search';
import FeatureFlags from '../constants/FeatureFlags';
import Layout from '../constants/Layout';
import { Booking } from '../utils/api';

type BookingSectionProps = StyleProps & {
  bookings: Booking[];
  title: string;
  Block: (props: BookingBlockProps) => JSX.Element;
  onPress: () => void;
};

export function BookingSection({
  bookings,
  title,
  Block,
  style,
  onPress,
}: BookingSectionProps) {
  return (
    <View style={[styles.container, style]}>
      <Row style={styles.header}>
        <TextH4 style={styles.title}>{title}</TextH4>
        {Platform.OS === 'web' && (
          <UnderlinedButton onPress={onPress} title="View All" />
        )}
      </Row>
      <Row style={styles.content}>
        {bookings.slice(0, 2).map((booking) => (
          <Block key={booking.id} booking={booking} />
        ))}
      </Row>
    </View>
  );
}

type LocationSectionProps = StyleProps & {
  locations: Location[] | undefined;
  title?: string;
  Block: (props: LocationBlockProps) => JSX.Element;
  contentStyle?: StyleProps['style'];
  amountDisplayed?: number;
  withSkeleton?: boolean;
  carousel?: boolean;
  onViewAllPress?: () => void;
  skipViewAll?: boolean;
};

export function LocationSection({
  locations,
  title,
  Block,
  style,
  contentStyle,
  amountDisplayed,
  onViewAllPress,
  withSkeleton,
  carousel,
  skipViewAll,
}: LocationSectionProps) {
  const locationsArray =
    withSkeleton && locations === undefined
      ? Array(amountDisplayed).fill({})
      : locations || [];

  return (
    <View style={[styles.container, style]}>
      {title ? (
        <Row style={styles.header}>
          <TextH4 style={styles.title}>{title}</TextH4>
          {FeatureFlags.SEARCH && Platform.OS === 'web' && !skipViewAll && (
            <UnderlinedButton title="View All" onPress={onViewAllPress} />
          )}
        </Row>
      ) : null}
      <Row style={[styles.content, carousel && styles.carousel, contentStyle]}>
        {locationsArray
          .slice(0, amountDisplayed || (locations || []).length)
          .map((location, idx) => (
            <Block key={`${idx}-${location?.id}`} location={location} />
          ))}
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    ...(Layout.isLargeDevice && {
      fontSize: 25,
      lineHeight: 32.5,
    }),
  },
  container: {
    backgroundColor: 'transparent',
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 40,
          marginBottom: 50,
        }),
        ...(Layout.isMobileDevice && {
          marginVertical: 20,
        }),
      },
      native: {
        marginTop: 20,
        marginHorizontal: 20,
      },
    }),
  },
  header: {
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginBottom: 26,
        }),
        ...(Layout.isMobileDevice && {
          marginBottom: 10,
        }),
      },
    }),
  },
  content: {
    // TODO style for mobile
    ...Platform.select({
      web: {
        // TODO doesn't work for vertical
        gap: 40,
      },
    }),
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  },
  carousel: {
    ...Platform.select({
      web: {
        scrollbarWidth: 'none',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        gap: 10,
      },
    }),
  },
});
