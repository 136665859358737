import { Pressable, StyleSheet, View } from 'react-native';

import { Icon } from './Icon';
import { TransparentRow } from './Row';
import { TextCta } from './StyledText';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

interface PaginationProps {
  total: number;
  current: number;
  onPageClick: (page: number) => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
}

const range = (start: number, end?: number, step = 1) => {
  const output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i <= end; i += step) {
    output.push(i);
  }
  return output;
};

function prepareItems(current: number, total: number) {
  if (total <= 6) {
    return range(1, total);
  }

  const neighbours = range(current - 1, current + 1).filter(
    (a) => a >= 1 && a <= total
  );

  let final: (null | number)[] = [total];

  /* check right side */
  const last = neighbours[neighbours.length - 1];
  if (last === total) {
    final = [...neighbours];
  } else if (last !== total - 1) {
    final = [...neighbours, null, total];
  } else {
    final = [...neighbours, total];
  }

  if (final[0] === 1) {
    return final;
  }

  /* check left side */
  final = neighbours[0] !== 2 ? [1, null, ...final] : [1, ...final];

  return final;
}

export default function Pagination({
  total,
  current,
  onPageClick,
  goToNextPage,
  goToPrevPage,
}: PaginationProps) {
  const theme = useColorScheme();

  const prevDisabled = current === 1;
  const nextDisabled = current === total;

  const items = prepareItems(current, total);

  return (
    <TransparentRow>
      <Pressable
        onPress={() => {
          if (prevDisabled) {
            return;
          }
          goToPrevPage();
        }}
        testID="prev-page-trigger"
      >
        <View style={[styles.paginationItem, prevDisabled && styles.disabled]}>
          <Icon name="arrowLeft" style={styles.paginationIcon} />
        </View>
      </Pressable>
      {items.map((page, idx) =>
        page === null ? (
          <View key={`page-${idx}`} style={styles.paginationItem}>
            <TextCta>...</TextCta>
          </View>
        ) : (
          <Pressable
            onPress={() => onPageClick(page)}
            key={`page-${idx}`}
            testID={
              idx === 0
                ? 'first-page-trigger'
                : idx === items.length - 1
                  ? 'last-page-trigger'
                  : 'page-trigger'
            }
          >
            <View
              style={[
                styles.paginationItem,
                current === page && {
                  borderColor: Colors[theme].accentTertiary,
                },
              ]}
            >
              <TextCta>{page}</TextCta>
            </View>
          </Pressable>
        )
      )}
      <Pressable
        onPress={() => {
          if (nextDisabled) {
            return;
          }
          goToNextPage();
        }}
        testID="next-page-trigger"
      >
        <View style={[styles.paginationItem, nextDisabled && styles.disabled]}>
          <Icon name="arrowRight" style={styles.paginationIcon} />
        </View>
      </Pressable>
    </TransparentRow>
  );
}

const styles = StyleSheet.create({
  paginationItem: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: '#eee',
    opacity: 0.3,
  },
  paginationIcon: {
    width: 12,
    height: 12,
  },
});
