import { API_V1_URL, CLIENT_ID } from './config';
import { getDeviceHeader } from './deviceInfo';
import { getAuthedRequestHeaders } from './headers';
import { ChildResponseObject, ParentResponseObject } from './types';
import { buildURLQuery } from './utils';
import { Parent, parseChild, parseParent } from '../utils/api';

export const shouldAskForData = (parent: Parent) => {
  return [parent.zip, parent.telephone].some((v) => !v || v === '');
};

type RegistrationFailed = {
  error: {
    email?: string[];
    password?: string[];
  };
};

type RegistrationSuccessful = {
  parent: ParentResponseObject;
  created_at: number;
  expires_in: number;
  refresh_token: string;
  access_token: string;
};

const hasRegistrationErrored = (
  body: unknown,
  status: number
): body is RegistrationFailed => status === 422;

export async function registerV2({
  queryParams,
  ...payload
}: {
  email: string;
  password: string;
  queryParams?:
    | {
        source?: 'PAID_AD' | 'BUMO_PACKAGE_BUY';
      }
    | {
        referral: string;
      };
}): Promise<
  | {
      ok: true;
      parent: Parent;
      refresh_token: string;
      access_token: string;
    }
  | {
      ok: false;
      error: RegistrationFailed['error'];
    }
> {
  const requestInfo: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getDeviceHeader(),
    },
    body: JSON.stringify({
      ...payload,
      client_id: CLIENT_ID,
    }),
  };

  const queryParam = queryParams ? buildURLQuery(queryParams) : null;

  const url = `${API_V1_URL}parents` + (queryParam ? '?' + queryParam : '');

  const response = await fetch(url, requestInfo);
  const responseBody: RegistrationSuccessful | RegistrationFailed =
    await response.json();

  if (hasRegistrationErrored(responseBody, response.status)) {
    return {
      ok: false,
      error: responseBody.error,
    };
  } else {
    const { parent, refresh_token, access_token } = responseBody;
    return {
      ok: true,
      parent: parseParent(parent),
      refresh_token,
      access_token,
    };
  }
}

type OnboardSuccesful = ParentResponseObject;

type OnboardFailed = {
  errors: {
    phone?: string[];
    firstName?: string[];
    lastName?: string[];
    telephone?: string[];
    zip?: string[];
  };
};

const hasOnboardingErrored = (
  body: unknown,
  status: number
): body is OnboardFailed => status === 422;

export async function onboardV2({
  firstName: first_name,
  lastName: last_name,
  ...payload
}: {
  firstName: string;
  lastName: string;
  telephone: string;
  zip: string;
}): Promise<
  | {
      ok: true;
      parent: Parent;
    }
  | {
      ok: false;
      errors: OnboardFailed['errors'];
    }
> {
  const requestInfo: RequestInit = {
    method: 'POST',
    credentials: 'include',
    ...getAuthedRequestHeaders(),
    body: JSON.stringify({
      first_name,
      last_name,
      ...payload,
    }),
  };
  const response = await fetch(`${API_V1_URL}parents/onboard`, requestInfo);
  const responseBody: OnboardSuccesful | OnboardFailed = await response.json();

  if (response.status === 500) {
    return {
      ok: false,
      errors: {
        firstName: ['Unknown error happened'],
      },
    };
  }

  if (hasOnboardingErrored(responseBody, response.status)) {
    return {
      ok: false,
      errors: responseBody.errors,
    };
  }
  return {
    ok: true,
    parent: parseParent(responseBody),
  };
}

export async function childrenOnboard(
  payload: {
    firstName: string;
    birthdate: string;
  }[]
) {
  const requestInfo: RequestInit = {
    method: 'POST',
    credentials: 'include',
    ...getAuthedRequestHeaders(),
    body: JSON.stringify({
      children_attributes: payload.map((entry) => ({
        first_name: entry.firstName,
        birthdate: new Date(entry.birthdate).toDateString(),
      })),
    }),
  };

  const response = await fetch(`${API_V1_URL}children/onboard`, requestInfo);
  const responseBody: ChildResponseObject[] = await response.json();

  if (response.status === 200) {
    return { ok: true, children: responseBody.map(parseChild) };
  }
  return {
    ok: false,
    // TODO add error info
  };
}
