import { useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { deleteReview } from '../api/reviews';
import { Icon } from '../components/Icon';
import { TransparentRow } from '../components/Row';
import { TextBodySmall } from '../components/StyledText';
import { useSnackbarDispatch } from '../contexts/snackbar';

export function useReviewsApi() {
  const snackbarDispatch = useSnackbarDispatch();
  const deleteReviewAction = useCallback(async (reviewId: number) => {
    const response = await deleteReview(reviewId);
    if (response.ok) {
      snackbarDispatch({
        message: (
          <TransparentRow style={styles.notification}>
            <Icon name="explore" style={styles.icon} />
            <TextBodySmall>
              Your review has been deleted. You can write a review again at any
              time.
            </TextBodySmall>
          </TransparentRow>
        ),
      });
      return true;
    } else {
      return false;
    }
  }, []);

  return {
    deleteReview: deleteReviewAction,
  };
}

const styles = StyleSheet.create({
  notification: {
    width: '100%',
  },
  icon: {
    marginRight: 6,
  },
});
