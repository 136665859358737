import { useNavigation } from '@react-navigation/native';
import { ImageSourcePropType, Pressable, StyleSheet } from 'react-native';

import BookingSlotButton from './BookingSlotButton';
import BumoVerified from './BumoVerified';
import { LocationCtaButton } from './Button';
import CallOut from './CallOut';
import Favorite from './Favorite';
import { Image } from './Image';
import InfoPopup from './InfoPopup';
import { LocationBlockProps } from './LocationBlocks';
import LocationDetail from './LocationDetail';
import Row, { TransparentRow } from './Row';
import SeeLocationButton from './SeeLocationButton';
import { Status } from './Status';
import { TextH4 } from './StyledText';
import { StyleProps, View } from './Themed';
import BookingByWeekTag from './booking_details/BookingByWeekTag';
import Details from './location_blocks/Details';
import HoursDetails from './location_blocks/HoursPriceDetails';
import Colors from '../constants/Colors';
import { useParent } from '../hooks/useChild';
import useColorScheme from '../hooks/useColorScheme';
import { Booking, BookingStatusType } from '../utils/api';
import { formatBlackoutDates, isBookByWeek } from '../utils/bookings';
import { handleLink } from '../utils/common';
import { dateRangeToNumeric, todayOrTomorrow } from '../utils/date';
import { formatDateHoursRange } from '../utils/locations';

export type BookingBlockProps = StyleProps & {
  booking: Booking;
  type: 'upcoming' | 'past';
};

type BookingStatusProps = StyleProps & {
  status: BookingStatusType;
};

export function BookingStatus({ status, style }: BookingStatusProps) {
  const theme = useColorScheme();
  if (
    status === 'paid' ||
    status === 'fixed_care' ||
    status === 'custom_care'
  ) {
    return (
      <Status
        text="Booking Confirmed"
        icon="check"
        style={style}
        backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
      />
    );
  } else if (status === 'completed') {
    return (
      <Status
        text="Booking Completed"
        icon="check"
        style={style}
        backgroundStyle={{ backgroundColor: Colors[theme].accentSecondary }}
      />
    );
  } else if (status === 'intent') {
    return (
      <Status
        text="Payment incomplete"
        icon="close"
        style={style}
        backgroundStyle={{ backgroundColor: Colors[theme].error }}
      />
    );
  } else if (status === 'payment_failed') {
    return (
      <Status
        text="Payment failed"
        icon="close"
        style={style}
        backgroundStyle={{ backgroundColor: Colors[theme].error }}
      />
    );
  } else if (status === 'cancelled') {
    return (
      <Status
        text="Booking Cancelled"
        icon="close"
        style={style}
        backgroundStyle={{ backgroundColor: Colors[theme].error }}
      />
    );
  }
  return null;
}

export function BookingBlockXL({ booking, style, type }: BookingBlockProps) {
  const theme = useColorScheme();
  const { navigate } = useNavigation();
  const {
    id,
    dropoff,
    pickup,
    children: childIds,
    status,
    paymentUrl,
    location: {
      id: locationId,
      timezone,
      name,
      nameId,
      verificationStatus,
      images = [],
    },
  } = booking;
  const { children = [] } = useParent();
  const dropoffDate = new Date(dropoff);
  const pickupDate = new Date(pickup);
  const soon = todayOrTomorrow(dropoffDate);

  const bookByWeek = isBookByWeek(booking);

  const bookingDateFormatted = bookByWeek
    ? `${dateRangeToNumeric([dropoffDate, pickupDate], {
        month: 'short',
        day: 'numeric',
        timeZone: timezone,
      })} at ${formatDateHoursRange(dropoff, pickup)}`
    : dropoffDate.toLocaleString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

  const blackoutDatesFormatted = formatBlackoutDates(booking);

  return (
    <Row
      style={[
        styles.container,
        { borderBottomColor: Colors[theme].lines },
        style,
      ]}
    >
      <View style={{ width: '50%' }}>
        <Image style={styles.image} source={images[0] as ImageSourcePropType} />
        <BumoVerified style={styles.verified} status={verificationStatus} />
      </View>
      <View style={styles.data}>
        {bookByWeek ? (
          <Row style={{ justifyContent: 'flex-start', marginBottom: 4 }}>
            <BookingByWeekTag />
          </Row>
        ) : null}
        <Row style={styles.header}>
          <Pressable
            onPress={() => navigate('BookingDetails', { id: id.toString() })}
          >
            <TextH4>{name}</TextH4>
          </Pressable>
          <Favorite locationId={locationId} />
        </Row>
        <Row style={styles.details}>
          <BookingStatus status={status} />
        </Row>
        <View style={[styles.details, { gap: 10 }]}>
          <LocationDetail
            icon="profile"
            text={children
              .filter((child) => childIds.includes(child.id))
              .map((child) => child.firstName)
              .join(', ')}
          />
          <Row
            style={{
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              rowGap: 10,
            }}
          >
            <LocationDetail
              icon="calendar"
              text={`${soon ? `${soon}, ` : ''}${bookingDateFormatted}`}
            />

            {blackoutDatesFormatted ? (
              <Row>
                <LocationDetail
                  icon="dateBlackout"
                  iconStyle={{ tintColor: Colors[theme].error }}
                  text={`Blackout Date: ${blackoutDatesFormatted}`}
                  style={{ marginRight: 10 }}
                />

                <InfoPopup
                  useModalOnMobile
                  text="This date is not included in this weeklong booking and therefore you were not charged."
                  iconStyle={{ width: 20, height: 20, margin: 0 }}
                />
              </Row>
            ) : null}
          </Row>
        </View>
        <Row style={styles.buttons}>
          <LocationCtaButton
            style={styles.button}
            left="View Details"
            onPress={() => navigate('BookingDetails', { id: id.toString() })}
          />
          {paymentUrl ? (
            <LocationCtaButton
              style={styles.button}
              left="Pay Now"
              onPress={() => handleLink(paymentUrl)}
            />
          ) : null}
          {type === 'past' && (
            <LocationCtaButton
              style={styles.button}
              left="Book again"
              onPress={() => navigate('Location', { nameId })}
            />
          )}
        </Row>
      </View>
    </Row>
  );
}

export function LocationBlockXL({ location, style }: LocationBlockProps) {
  const theme = useColorScheme();
  const { navigate } = useNavigation();
  const {
    id,
    name,
    distance,
    ageFrom,
    ageTo,
    hours,
    pricing,
    slots,
    nameId,
    callouts,
    verificationStatus,
    images = [],
    address: { city, neighbourhood } = { city: '', neighbourhood: '' },
    price,
  } = location;
  const handlePress = () => navigate('Location', { nameId });

  return (
    <Pressable onPress={handlePress} style={styles.fullWidth}>
      <Row
        style={[
          styles.container,
          { borderBottomColor: Colors[theme].lines },
          style,
        ]}
      >
        <View style={{ width: '50%' }}>
          <Image
            style={styles.image}
            source={images[0] as ImageSourcePropType}
          />
          <BumoVerified style={styles.verified} status={verificationStatus} />
        </View>
        <View style={styles.data}>
          <Row style={styles.header}>
            <TextH4 style={styles.name}>{name}</TextH4>
            <Favorite locationId={id} />
          </Row>
          <Details
            {...{
              ageFrom,
              ageTo,
              city,
              neighbourhood,
              distance,
              price,
              pricing,
            }}
          />
          <HoursDetails hours={hours} />
          <Row style={styles.callouts}>
            {callouts.map((callout, idx) => (
              <CallOut key={idx} style={styles.callout} title={callout} />
            ))}
          </Row>
          {slots && (
            <Row style={styles.buttons}>
              {slots.slice(0, 7).map((slot) => (
                <TransparentRow style={styles.buttonContainer} key={slot.id}>
                  <BookingSlotButton slot={slot} locationNameId={nameId} />
                </TransparentRow>
              ))}

              <TransparentRow style={styles.buttonContainer}>
                <SeeLocationButton locationNameId={nameId} />
              </TransparentRow>
            </Row>
          )}
        </View>
      </Row>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  container: {
    width: '100%',
    paddingBottom: 20,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingRight: 50,
  },
  name: {
    maxWidth: 550,
  },
  data: {
    flex: 1,
    marginTop: 25,
    marginLeft: 40,
  },
  details: {
    marginTop: 10,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  image: {
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    height: 316,
  },
  verified: {
    left: 10,
    top: 10,
    position: 'absolute',
  },
  callouts: {
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  callout: {
    marginRight: 15,
  },
  buttons: {
    marginTop: 20,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    padding: 2,
    width: '50%',
  },
  button: {
    marginTop: 5,
    marginRight: 5,
  },
});
