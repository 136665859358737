import { useState } from 'react';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import FeatureFlags from '../../constants/FeatureFlags';
import { useIsButtonEmpty } from '../../contexts/threeparamsearch';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useGetGeolocation } from '../../hooks/useGeolocation';
import { useMaxWidth, useMinWidth } from '../../hooks/useResponsive';
import { NavigateToSearchButton } from '../NavigateToSearchButton';
import { TextH1, TextH4 } from '../StyledText';
import { TransparentView, View } from '../Themed';
import { SearchButtonMobile } from '../search/SearchButtonMobile';

export function IntroText({
  title,
  description,
  button,
  descriptionStyle,
  buttonContainerStyle,
}: {
  title?: React.ReactNode;
  description?: string;
  button?: React.ReactNode;
  descriptionStyle?: StyleProp<TextStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
}) {
  const navigation = useAppNavigation();
  const shouldDisplayTPSHeader = useMinWidth(900);
  const isMobileDevice = useMaxWidth(768);
  const isSearchButtonEmpty = useIsButtonEmpty();

  const getGeolocation = useGetGeolocation();
  const [geolocationLoading, setGeolocationLoading] = useState(false);

  const findNearMe = async () => {
    setGeolocationLoading(true);
    let position;
    try {
      position = await getGeolocation();
    } catch {}
    setGeolocationLoading(false);

    navigation.push('Search', {
      lat: position?.coords.latitude.toString(),
      lng: position?.coords.longitude.toString(),
    });
  };

  return (
    <View
      style={[
        styles.introTextContainer,
        !isMobileDevice && styles.introTextContainerDesktop,
      ]}
    >
      <View
        style={[
          styles.introTextInner,
          !isMobileDevice && styles.introTextInnerDesktop,
        ]}
      >
        {title || (
          <TextH1 style={{ marginBottom: 10 }}>
            {FeatureFlags.FLEXING ? 'The Most Flexible' : 'Book Trusted'} <br />
            Child Care <br />
            When You Need It
          </TextH1>
        )}
        <TextH4 style={[{ marginBottom: 25 }, descriptionStyle]}>
          {description ||
            'Reliable, trusted, on-demand child care at your fingertips'}
        </TextH4>
        <TransparentView
          style={[
            {
              width: '100%',
              maxWidth: 500,
              alignItems: 'center',
              marginTop: 16,
            },
            shouldDisplayTPSHeader && {
              alignItems: 'flex-start',
            },
            buttonContainerStyle,
          ]}
        >
          {button ? (
            button
          ) : !shouldDisplayTPSHeader ? (
            <SearchButtonMobile wide={isSearchButtonEmpty} />
          ) : (
            <NavigateToSearchButton
              onPress={findNearMe}
              loading={geolocationLoading}
              loadingText="Getting your location"
            />
          )}
        </TransparentView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  introTextContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    padding: 24,
  },
  introTextContainerDesktop: {
    maxWidth: '50%',
  },
  introTextInner: {
    backgroundColor: 'transparent',
  },
  introTextInnerDesktop: {
    maxWidth: 580,
    width: '100%',
    alignSelf: 'flex-end',
  },
});
