import { lazy, Suspense } from 'react';
import { DimensionValue, Image } from 'react-native';

import { HeroContent } from './HeroWithImage';
import { IntroText } from './IntroText';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useMaxWidth } from '../../hooks/useResponsive';
import { getImageUrl } from '../../utils/common';
import { PrimaryButton } from '../Button';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';
import { TransparentView } from '../Themed';

const displayDatesConfig = {
  FALL_WINTER_CAMPS: {
    startDate: '2024-11-28T08:00:00.000Z',
    endDate: '2024-12-05T07:59:59.000Z',
  },
  FALL_WINTER_CAMPS_WITH_BADGE: {
    startDate: '2024-12-05T08:00:00.000Z',
    endDate: '2025-01-03T07:59:59.000Z',
  },
};

type INTRO_KEY = keyof typeof displayDatesConfig;

export default function IntroSection() {
  const currentKey = getCurrentIntroKey();

  switch (currentKey) {
    case 'FALL_WINTER_CAMPS':
      return <FallWinterIntroSection />;
    case 'FALL_WINTER_CAMPS_WITH_BADGE':
      return <FallWinterIntroSection />;
  }
  return <DefaultIntroSection />;
}

export function getCurrentIntroKey(): INTRO_KEY | null {
  const lsIntroKey =
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem('FORCE_INTRO')
      : null;
  if (lsIntroKey && displayDatesConfig[lsIntroKey as INTRO_KEY]) {
    return lsIntroKey as INTRO_KEY;
  }

  const now = new Date();
  const nowISO = now.toISOString();

  const activeCampaingKey = (
    Object.keys(displayDatesConfig) as INTRO_KEY[]
  ).find(
    (key) =>
      displayDatesConfig[key].startDate <= nowISO &&
      displayDatesConfig[key].endDate >= nowISO
  );
  return activeCampaingKey || null;
}

const FallWinterLogoDesktop = lazy(
  () => import('../../assets/images/fall-winter-logo-desktop.svg')
);
const FallWinterLogoMobile = lazy(
  () => import('../../assets/images/fall-winter-logo-mobile.svg')
);

function FallWinterIntroSection({ badge = false }: { badge?: boolean }) {
  const navigation = useAppNavigation();
  const isMobileDevice = useMaxWidth(768);

  const Logo = isMobileDevice ? (
    <FallWinterLogoMobile
      height={96}
      style={{ marginBottom: 10, alignSelf: 'center' }}
    />
  ) : (
    <FallWinterLogoDesktop height={288} style={{ marginBottom: 10 }} />
  );
  return (
    <TransparentRow
      style={isMobileDevice && { flexDirection: 'column-reverse' }}
    >
      <IntroText
        title={<Suspense>{Logo}</Suspense>}
        description="Reliable, trusted, on-demand child care at your fingertips, including camps!"
        descriptionStyle={
          isMobileDevice && {
            alignSelf: 'center',
            textAlign: 'center',
            marginBottom: 0,
          }
        }
        button={
          <PrimaryButton
            onPress={() => navigation.push('ProgramWinterWonderland')}
            title="Book fall & winter camps"
            style={isMobileDevice && { alignSelf: 'center' }}
          />
        }
        buttonContainerStyle={isMobileDevice && { alignSelf: 'center' }}
      />
      <HeroContent.Image
        imageFileName="winter-camps-intro-photo.webp"
        forceImage
        style={
          isMobileDevice
            ? {
                width: '100%',
                height: 'min(calc(100vw * 375/272), 280px)' as DimensionValue,
              }
            : undefined
        }
      />

      {/* decorations */}
      <Image
        source={require('../../assets/images/snow.png')}
        style={[
          {
            position: 'absolute',
            left: '50%',
            top: 30,
            width: 160,
            height: 160,
            zIndex: 10,
            marginLeft: -180,
          },
          isMobileDevice && {
            left: 'auto',
            right: 10,
            top: 20,
            width: 80,
            height: 80,
          },
        ]}
      />

      <Image
        source={require('../../assets/images/leaf.png')}
        style={[
          {
            position: 'absolute',
            left: '50%',
            bottom: 30,
            width: 160,
            height: 160,
            marginLeft: -110,
            zIndex: 10,
          },
          isMobileDevice && {
            display: 'none',
          },
        ]}
      />

      <Image
        source={require('../../assets/images/leaf.png')}
        style={[
          {
            position: 'absolute',
            right: 20,
            top: 10,
            width: 160,
            height: 160,
            zIndex: 10,
            transform: [{ rotate: '90deg' }],
          },
          isMobileDevice && {
            top: 180,
            left: 20,
            width: 80,
            height: 80,
          },
        ]}
      />

      {badge ? (
        <TransparentView
          style={[
            {
              position: 'absolute',
              right: 20,
              bottom: 20,
              width: 180,
              height: 180,
              zIndex: 20,
              justifyContent: 'center',
              alignItems: 'center',
            },
            isMobileDevice && {
              top: 170,
              right: 20,
              width: 130,
              height: 130,
            },
          ]}
        >
          <Image
            source={require('../../assets/images/sun.png')}
            tintColor="green"
            style={[
              { position: 'absolute', width: 180, height: 180 },
              isMobileDevice && {
                width: 130,
                height: 130,
              },
            ]}
          />
          <TextBodySmall
            bold
            style={[
              {
                width: 130,
                textAlign: 'center',
                color: '#fff',
                fontSize: 20,
              },
              isMobileDevice && {
                width: 110,
                fontSize: 15,
              },
            ]}
          >
            15% off with code WINTER15
          </TextBodySmall>
        </TransparentView>
      ) : null}
    </TransparentRow>
  );
}

function DefaultIntroSection() {
  const isMobileDevice = useMaxWidth(768);
  return (
    <TransparentRow>
      <IntroText />
      {isMobileDevice ? null : (
        <>
          <HeroContent.ImagesCarousel
            images={[
              'hero-image-sfa.webp',
              'hero-image-2.webp',
              'hero-image-3.webp',
              'hero-image-4.webp',
            ]}
            imageUrlFn={getImageUrl}
            altText=""
          />
          <Image
            source={require('../../assets/images/la_patch.png')}
            style={{
              position: 'absolute',
              right: 15,
              bottom: 15,
              width: 107,
              height: 123,
            }}
          />
        </>
      )}
    </TransparentRow>
  );
}
