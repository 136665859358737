import { ImageBackground } from 'react-native';

import { Icon } from './Icon';
import { InnerContainer } from './Layout';
import { LogoCare } from './Logo';
import PDPSkeleton from './PDPSkeleton';
import { View } from './Themed';
import { IntroText } from './explore/IntroText';
import Colors from '../constants/Colors';
import { useMaxWidth } from '../hooks/useResponsive';

export function Splash({
  campaignKey,
  pageType = 'unknown',
  isLoggedIn,
}: {
  campaignKey?: string | null;
  pageType?: string;
  isLoggedIn?: boolean;
}) {
  const isMobileDevice = useMaxWidth(768);
  return (
    <View style={{ height: '100%' }}>
      {campaignKey ? <div className="campaign-placeholder" /> : null}
      <div className="header-placeholder shd">
        <InnerContainer
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
          }}
        >
          <div className="logo">
            <LogoCare />
          </div>
          <div className="search shd">
            <Icon name="search" color={Colors.dark.accentTertiary} />
          </div>
          <div className="menu">
            <Icon name="hamburger" />
          </div>
        </InnerContainer>
      </div>

      {pageType === 'home' && !isLoggedIn ? (
        <>
          <InnerContainer
            style={{
              maxWidth: 1440,
              paddingHorizontal: 0,
              height: isMobileDevice ? 'auto' : 577,
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <IntroText />
            {isMobileDevice ? null : (
              <ImageBackground
                source={{
                  uri: 'https://bumo-care.s3.us-west-1.amazonaws.com/0images/hero-image-sfa.webp',
                }}
                style={{ height: 577, flex: 1 }}
              />
            )}
          </InnerContainer>
          <div className="banner-placeholder" />
        </>
      ) : null}
      {pageType === 'location' ? (
        <InnerContainer>
          <PDPSkeleton />
        </InnerContainer>
      ) : null}
    </View>
  );
}
