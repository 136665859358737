import { forwardRef } from 'react';
import { ImageStyle, StyleSheet, View } from 'react-native';

import { TransparentView } from './Themed';
import ArrowBackIcon from '../assets/icons/arrow_back.svg';
import ArrowDownIcon from '../assets/icons/arrow_down.svg';
import ArrowForwardIcon from '../assets/icons/arrow_forward.svg';
import ArrowLeftIcon from '../assets/icons/arrow_left.svg';
import ArrowRightIcon from '../assets/icons/arrow_right.svg';
import ArrowUpIcon from '../assets/icons/arrow_up.svg';
import BathroomIcon from '../assets/icons/bathroom.svg';
import BumoIcon from '../assets/icons/bumo.svg';
import CalendarIcon from '../assets/icons/calendar.svg';
import CallIcon from '../assets/icons/call.svg';
import CameraIcon from '../assets/icons/camera.svg';
import CarOutlinedIcon from '../assets/icons/car_outlined.svg';
import CardIcon from '../assets/icons/card.svg';
import ChatIcon from '../assets/icons/chat.svg';
import CheckIcon from '../assets/icons/check.svg';
import ClockIcon from '../assets/icons/clock.svg';
import CloseIcon from '../assets/icons/close.svg';
import CloudIcon from '../assets/icons/cloud.svg';
import ComputerIcon from '../assets/icons/computer.svg';
import DateBlackoutIcon from '../assets/icons/date_blackout.svg';
import DateRangeIcon from '../assets/icons/date_range.svg';
import DollarIcon from '../assets/icons/dollar.svg';
import EmailIcon from '../assets/icons/email.svg';
import ExploreIcon from '../assets/icons/explore.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import FileEditIcon from '../assets/icons/file_edit.svg';
import FlashOnIcon from '../assets/icons/flash_on.svg';
import FoodIcon from '../assets/icons/food.svg';
import GroupIcon from '../assets/icons/group.svg';
import HamburgerIcon from '../assets/icons/hamburger.svg';
import HeartIcon from '../assets/icons/heart.svg';
import HeartOutlinedIcon from '../assets/icons/heart_outlined.svg';
import HomeIcon from '../assets/icons/home.svg';
import HouseIcon from '../assets/icons/house.svg';
import InfoOutlinedIcon from '../assets/icons/info_outlined.svg';
import LaptopIcon from '../assets/icons/laptop.svg';
import LightbulbIcon from '../assets/icons/lightbulb.svg';
import LinkedInIcon from '../assets/icons/linkedin.svg';
import ListIcon from '../assets/icons/list_outlined.svg';
import LoaderIcon from '../assets/icons/loader.svg';
import LocationIcon from '../assets/icons/location.svg';
import LocationOutlinedIcon from '../assets/icons/location_outlined.svg';
import LogOutIcon from '../assets/icons/log_out.svg';
import MapIcon from '../assets/icons/map.svg';
import MenuIcon from '../assets/icons/menu.svg';
import ParentIcon from '../assets/icons/parent.svg';
import PlayIcon from '../assets/icons/play.svg';
import PlusIcon from '../assets/icons/plus.svg';
import PriceTagIcon from '../assets/icons/price-tag.svg';
import ProfileIcon from '../assets/icons/profile.svg';
import RefreshIcon from '../assets/icons/refresh.svg';
import RemoveCircleIcon from '../assets/icons/remove_circle.svg';
import SearchIcon from '../assets/icons/search.svg';
import ShareIcon from '../assets/icons/share.svg';
import ShieldIcon from '../assets/icons/shield.svg';
import ShieldOutlinedIcon from '../assets/icons/shield_outlined.svg';
import SnowIcon from '../assets/icons/snow.svg';
import StarIcon from '../assets/icons/star.svg';
import StarOutlinedIcon from '../assets/icons/star_outlined.svg';
import WalletIcon from '../assets/icons/wallet.svg';
import WarningIcon from '../assets/icons/warning.svg';
import WhatsHotIcon from '../assets/icons/whats_hot.svg';
import WorkIcon from '../assets/icons/work.svg';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

const icons = {
  arrowUp: ArrowUpIcon,
  arrowDown: ArrowDownIcon,
  arrowBack: ArrowBackIcon,
  arrowForward: ArrowForwardIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  bathroom: BathroomIcon,
  bumo: BumoIcon,
  calendar: CalendarIcon,
  call: CallIcon,
  camera: CameraIcon,
  card: CardIcon,
  carOutlined: CarOutlinedIcon,
  chat: ChatIcon,
  check: CheckIcon,
  clock: ClockIcon,
  close: CloseIcon,
  cloud: CloudIcon,
  computer: ComputerIcon,
  dateRange: DateRangeIcon,
  dateBlackout: DateBlackoutIcon,
  dollar: DollarIcon,
  email: EmailIcon,
  explore: ExploreIcon,
  facebook: FacebookIcon,
  flashOn: FlashOnIcon,
  fileEdit: FileEditIcon,
  food: FoodIcon,
  group: GroupIcon,
  hamburger: HamburgerIcon,
  heart: HeartIcon,
  heartOutlined: HeartOutlinedIcon,
  home: HomeIcon,
  house: HouseIcon,
  infoOutlined: InfoOutlinedIcon,
  laptop: LaptopIcon,
  lightbulb: LightbulbIcon,
  linkedin: LinkedInIcon,
  list: ListIcon,
  loader: LoaderIcon,
  location: LocationIcon,
  locationOutlined: LocationOutlinedIcon,
  logout: LogOutIcon,
  map: MapIcon,
  menu: MenuIcon,
  parent: ParentIcon,
  play: PlayIcon,
  plus: PlusIcon,
  priceTag: PriceTagIcon,
  profile: ProfileIcon,
  refresh: RefreshIcon,
  removeCircle: RemoveCircleIcon,
  search: SearchIcon,
  share: ShareIcon,
  shield: ShieldIcon,
  shieldOutlined: ShieldOutlinedIcon,
  snow: SnowIcon,
  star: StarIcon,
  starOutlined: StarOutlinedIcon,
  wallet: WalletIcon,
  warning: WarningIcon,
  whatsHot: WhatsHotIcon,
  work: WorkIcon,
} as const;

export type IconName = keyof typeof icons;
export type IconStyle = ImageStyle | ImageStyle[];

export interface IconProps {
  name: IconName;
  color?: string;
  backgroundColor?: string;
  style?: IconStyle;
  testID?: string;
}

export const Icon = forwardRef<View, IconProps>((props, ref) => {
  const { name, style, ...rest } = props;
  const theme = useColorScheme();
  const IconSpecific = name in icons ? icons[name] : icons.check;
  const styleObject = Array.isArray(style)
    ? Object.assign({}, ...style.flat())
    : style;

  return (
    <TransparentView ref={ref} style={styles.iconContainer}>
      <IconSpecific
        style={{
          ...styles.icon,
          ...styleObject,
        }}
        color={styleObject?.tintColor || rest.color || Colors[theme].text}
        {...rest}
      />
    </TransparentView>
  );
});

const styles = StyleSheet.create({
  iconContainer: {
    // @ts-ignore
    position: 'static',
  },
  icon: {
    width: 30,
    height: 30,
  },
});
