import { Pressable, StyleSheet } from 'react-native';

import { IconName, Icon } from './Icon';
import Row from './Row';
import { TextBodySmall } from './StyledText';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type ChipProps = StyleProps & {
  label: string;
  color?: string;
  onPress?: () => void;
  icon: IconName;
  disabled?: boolean;
};

export default function Chip({
  label,
  color,
  onPress,
  icon,
  style,
  disabled,
}: ChipProps) {
  const theme = useColorScheme();

  return (
    <Pressable style={style} onPress={onPress} disabled={disabled}>
      <Row
        style={[
          styles.chip,
          { backgroundColor: color || Colors[theme].backgroundSecondary },
        ]}
      >
        <Icon
          name={icon}
          style={[styles.chipIcon, { tintColor: Colors[theme].textSecondary }]}
        />
        <TextBodySmall>{label}</TextBodySmall>
      </Row>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  chip: {
    paddingHorizontal: 15,
    paddingVertical: 3,
    borderRadius: 100,
  },
  chipIcon: {
    width: 25,
    height: 25,
    marginRight: 2,
  },
});
