import MapContainer, { MapProps } from './MapContainer';
import GoogleMap from '../GoogleMap';
import { LocationMarker } from '../Marker';

function DefaultGoogleMap(props: {
  latitude: number;
  longitude: number;
  allowZooming: boolean;
}) {
  const { latitude, longitude, allowZooming } = props;
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: latitude, lng: longitude }}
      options={{ ...(!allowZooming && { maxZoom: 13 }) }}
    >
      <LocationMarker lat={latitude} lng={longitude} />
    </GoogleMap>
  );
}

export default function Map(props: Omit<MapProps, 'map'>) {
  const { latitude, longitude, address } = props;
  if (!latitude && !longitude && !address) return null;

  return (
    <MapContainer
      {...props}
      map={
        latitude &&
        longitude && (
          <DefaultGoogleMap
            latitude={latitude}
            longitude={longitude}
            allowZooming={!!props.details}
          />
        )
      }
    />
  );
}
