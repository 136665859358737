// https://github.com/jackocnr/intl-tel-input/blob/master/src/js/data.js

// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code (it is not a sub-region of another country): https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber (it must be listed on this page): https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Order (if >1 country with same dial code),
//    Area codes
// ]

type CountryEntry = [string, string, string, number?, string[]?];
const allCountries: CountryEntry[] = [
  ['Afghanistan', 'af', '93'],
  ['Albania', 'al', '355'],
  ['Algeria', 'dz', '213'],
  ['American Samoa', 'as', '1', 5, ['684']],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Anguilla', 'ai', '1', 6, ['264']],
  ['Antigua & Barbuda', 'ag', '1', 7, ['268']],
  ['Argentina', 'ar', '54'],
  ['Armenia', 'am', '374'],
  ['Aruba', 'aw', '297'],
  ['Ascension Island', 'ac', '247'],
  ['Australia', 'au', '61', 0],
  ['Austria', 'at', '43'],
  ['Azerbaijan', 'az', '994'],
  ['Bahamas', 'bs', '1', 8, ['242']],
  ['Bahrain', 'bh', '973'],
  ['Bangladesh', 'bd', '880'],
  ['Barbados', 'bb', '1', 9, ['246']],
  ['Belarus', 'by', '375'],
  ['Belgium', 'be', '32'],
  ['Belize', 'bz', '501'],
  ['Benin', 'bj', '229'],
  ['Bermuda', 'bm', '1', 10, ['441']],
  ['Bhutan', 'bt', '975'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia & Herzegovina', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazil', 'br', '55'],
  ['British Indian Ocean Territory', 'io', '246'],
  ['British Virgin Islands', 'vg', '1', 11, ['284']],
  ['Brunei', 'bn', '673'],
  ['Bulgaria', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi', 'bi', '257'],
  ['Cambodia', 'kh', '855'],
  ['Cameroon', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '263',
      '289',
      '306',
      '343',
      '354',
      '365',
      '367',
      '368',
      '382',
      '387',
      '403',
      '416',
      '418',
      '428',
      '431',
      '437',
      '438',
      '450',
      '584',
      '468',
      '474',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '584',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '683',
      '705',
      '709',
      '742',
      '753',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde', 'cv', '238'],
  ['Caribbean Netherlands', 'bq', '599', 1, ['3', '4', '7']],
  ['Cayman Islands', 'ky', '1', 12, ['345']],
  ['Central African Republic', 'cf', '236'],
  ['Chad', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China', 'cn', '86'],
  ['Christmas Island', 'cx', '61', 2, ['89164']],
  ['Cocos (Keeling) Islands', 'cc', '61', 1, ['89162']],
  ['Colombia', 'co', '57'],
  ['Comoros', 'km', '269'],
  ['Congo - Brazzaville', 'cg', '242'],
  ['Congo - Kinshasa', 'cd', '243'],
  ['Cook Islands', 'ck', '682'],
  ['Costa Rica', 'cr', '506'],
  ['Côte d’Ivoire', 'ci', '225'],
  ['Croatia', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', 0],
  ['Cyprus', 'cy', '357'],
  ['Czech Republic', 'cz', '420'],
  ['Denmark', 'dk', '45'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1', 13, ['767']],
  ['Dominican Republic', 'do', '1', 2, ['809', '829', '849']],
  ['Ecuador', 'ec', '593'],
  ['Egypt', 'eg', '20'],
  ['El Salvador', 'sv', '503'],
  ['Equatorial Guinea', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estonia', 'ee', '372'],
  ['Eswatini', 'sz', '268'],
  ['Ethiopia', 'et', '251'],
  ['Falkland Islands', 'fk', '500'],
  ['Faroe Islands', 'fo', '298'],
  ['Fiji', 'fj', '679'],
  ['Finland', 'fi', '358', 0],
  ['France', 'fr', '33'],
  ['French Guiana', 'gf', '594'],
  ['French Polynesia', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia', 'ge', '995'],
  ['Germany', 'de', '49'],
  ['Ghana', 'gh', '233'],
  ['Gibraltar', 'gi', '350'],
  ['Greece', 'gr', '30'],
  ['Greenland', 'gl', '299'],
  ['Grenada', 'gd', '1', 14, ['473']],
  ['Guadeloupe', 'gp', '590', 0],
  ['Guam', 'gu', '1', 15, ['671']],
  ['Guatemala', 'gt', '502'],
  ['Guernsey', 'gg', '44', 1, ['1481', '7781', '7839', '7911']],
  ['Guinea', 'gn', '224'],
  ['Guinea-Bissau', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haiti', 'ht', '509'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong', 'hk', '852'],
  ['Hungary', 'hu', '36'],
  ['Iceland', 'is', '354'],
  ['India', 'in', '91'],
  ['Indonesia', 'id', '62'],
  ['Iran', 'ir', '98'],
  ['Iraq', 'iq', '964'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44', 2, ['1624', '74576', '7524', '7924', '7624']],
  ['Israel', 'il', '972'],
  ['Italy', 'it', '39', 0],
  ['Jamaica', 'jm', '1', 4, ['876', '658']],
  ['Japan', 'jp', '81'],
  ['Jersey', 'je', '44', 3, ['1534', '7509', '7700', '7797', '7829', '7937']],
  ['Jordan', 'jo', '962'],
  ['Kazakhstan', 'kz', '7', 1, ['33', '7']],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait', 'kw', '965'],
  ['Kyrgyzstan', 'kg', '996'],
  ['Laos', 'la', '856'],
  ['Latvia', 'lv', '371'],
  ['Lebanon', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libya', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macau', 'mo', '853'],
  ['Madagascar', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaysia', 'my', '60'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshall Islands', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritania', 'mr', '222'],
  ['Mauritius', 'mu', '230'],
  ['Mayotte', 'yt', '262', 1, ['269', '639']],
  ['Mexico', 'mx', '52'],
  ['Micronesia', 'fm', '691'],
  ['Moldova', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Mongolia', 'mn', '976'],
  ['Montenegro', 'me', '382'],
  ['Montserrat', 'ms', '1', 16, ['664']],
  ['Morocco', 'ma', '212', 0],
  ['Mozambique', 'mz', '258'],
  ['Myanmar (Burma)', 'mm', '95'],
  ['Namibia', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal', 'np', '977'],
  ['Netherlands', 'nl', '31'],
  ['New Caledonia', 'nc', '687'],
  ['New Zealand', 'nz', '64'],
  ['Nicaragua', 'ni', '505'],
  ['Niger', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Niue', 'nu', '683'],
  ['Norfolk Island', 'nf', '672'],
  ['North Korea', 'kp', '850'],
  ['North Macedonia', 'mk', '389'],
  ['Northern Mariana Islands', 'mp', '1', 17, ['670']],
  ['Norway', 'no', '47', 0],
  ['Oman', 'om', '968'],
  ['Pakistan', 'pk', '92'],
  ['Palau', 'pw', '680'],
  ['Palestine', 'ps', '970'],
  ['Panama', 'pa', '507'],
  ['Papua New Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru', 'pe', '51'],
  ['Philippines', 'ph', '63'],
  ['Poland', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
  ['Qatar', 'qa', '974'],
  ['Réunion', 're', '262', 0],
  ['Romania', 'ro', '40'],
  ['Russia', 'ru', '7', 0],
  ['Rwanda', 'rw', '250'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé & Príncipe', 'st', '239'],
  ['Saudi Arabia', 'sa', '966'],
  ['Senegal', 'sn', '221'],
  ['Serbia', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65'],
  ['Sint Maarten', 'sx', '1', 21, ['721']],
  ['Slovakia', 'sk', '421'],
  ['Slovenia', 'si', '386'],
  ['Solomon Islands', 'sb', '677'],
  ['Somalia', 'so', '252'],
  ['South Africa', 'za', '27'],
  ['South Korea', 'kr', '82'],
  ['South Sudan', 'ss', '211'],
  ['Spain', 'es', '34'],
  ['Sri Lanka', 'lk', '94'],
  ['St Barthélemy', 'bl', '590', 1],
  ['St Helena', 'sh', '290'],
  ['St Kitts & Nevis', 'kn', '1', 18, ['869']],
  ['St Lucia', 'lc', '1', 19, ['758']],
  ['St Martin', 'mf', '590', 2],
  ['St Pierre & Miquelon', 'pm', '508'],
  ['St Vincent & Grenadines', 'vc', '1', 20, ['784']],
  ['Sudan', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Svalbard & Jan Mayen', 'sj', '47', 1, ['79']],
  ['Sweden', 'se', '46'],
  ['Switzerland', 'ch', '41'],
  ['Syria', 'sy', '963'],
  ['Taiwan', 'tw', '886'],
  ['Tajikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tokelau', 'tk', '690'],
  ['Tonga', 'to', '676'],
  ['Trinidad & Tobago', 'tt', '1', 22, ['868']],
  ['Tunisia', 'tn', '216'],
  ['Turkey', 'tr', '90'],
  ['Turkmenistan', 'tm', '993'],
  ['Turks & Caicos Islands', 'tc', '1', 23, ['649']],
  ['Tuvalu', 'tv', '688'],
  ['Uganda', 'ug', '256'],
  ['Ukraine', 'ua', '380'],
  ['United Arab Emirates', 'ae', '971'],
  ['United Kingdom', 'gb', '44', 0],
  ['United States', 'us', '1', 0],
  ['Uruguay', 'uy', '598'],
  ['US Virgin Islands', 'vi', '1', 24, ['340']],
  ['Uzbekistan', 'uz', '998'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican City', 'va', '39', 1, ['06698']],
  ['Venezuela', 've', '58'],
  ['Vietnam', 'vn', '84'],
  ['Wallis & Futuna', 'wf', '681'],
  ['Western Sahara', 'eh', '212', 1, ['5288', '5289']],
  ['Yemen', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
  ['Åland Islands', 'ax', '358', 1, ['18']],
];

export default allCountries.map((currentCountry) => {
  const [name, iso2, dialCode, priority = 0, areaCodes = null] = currentCountry;
  return {
    name,
    iso2,
    dialCode,
    priority,
    areaCodes,
  };
});
