import { CLIENT_ID, API_V1_URL } from './config';
import {
  getAuthedRequestHeaders,
  defaultAuthedRequest,
  defaultRequest,
  defaultGetRequest,
} from './headers';
import { ParentResponseObject } from './types';
import { parseParent, snakeToCamel, snakeToTitle } from '../utils/api';

const PARENTS_V1_URL = `${API_V1_URL}parents/`;

export async function getMe() {
  const requestInfo = {
    method: 'GET',
    credentials: 'include',
    ...getAuthedRequestHeaders(),
  } as const;
  const response = await fetch(`${API_V1_URL}parents/me/`, requestInfo);
  if (response.status === 401) {
    return undefined;
  }

  const parent = await response.json();
  return parseParent(parent as ParentResponseObject);
}

interface PersonalInfoPayload {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  zip: string;
}

export async function patchPersonalInfo(payload: PersonalInfoPayload) {
  const { firstName, lastName, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      ...rest,
    }),
  };
  const response = await fetch(`${PARENTS_V1_URL}personal`, request);
  return await response.json();
}

export async function deleteAccount(id: number) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'DELETE',
  };
  const response = await fetch(`${PARENTS_V1_URL}${id}`, request);
  return await response.json();
}

interface PasswordPayload {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

export async function patchPassword(payload: PasswordPayload) {
  const { currentPassword, newPassword, passwordConfirmation } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: passwordConfirmation,
    }),
  };
  const response = await fetch(`${PARENTS_V1_URL}password`, request);
  return parsePatchResponse(await response.json());
}

function parsePatchResponse(
  patchResponse: { ok: true } | { error: Record<string, string[]> }
) {
  if ('error' in patchResponse) {
    const keys = Object.keys(patchResponse.error);
    return {
      ok: false as const,
      errors: keys.reduce(
        (acc, key) => ({
          ...acc,
          [snakeToCamel(key)]: `${snakeToTitle(key)} ${patchResponse.error[
            key
          ]![0]?.toLowerCase()}`,
        }),
        {} as Partial<PasswordPayload>
      ),
    };
  }
  return { ok: true } as const;
}

export async function patchFormsReuse(consent: boolean) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      forms_reuse_consent: consent,
    }),
  };

  const response = await fetch(`${PARENTS_V1_URL}forms_reuse`, request);
  return await response.json();
}

export async function logOut() {
  const requestInfo = {
    ...defaultAuthedRequest,
    body: JSON.stringify({
      client_id: CLIENT_ID,
    }),
  };
  const response = await fetch(`${PARENTS_V1_URL}logout`, requestInfo);
  return await response.json();
}

type PostParentPayload = {
  email: string;
  name: string;
  telephone?: string;
};
export async function postParentLead(payload: PostParentPayload) {
  const requestInfo = {
    ...defaultRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${PARENTS_V1_URL}leads`, requestInfo);
  if (response.ok) {
    return {
      ok: true as const,
      body: (await response.json()) as { token: string },
    };
  }
  return {
    ok: false as const,
    body: await response.json(),
  };
}

type ParentLeadResponse =
  | {
      ok: true;
      body: {
        email: string;
        name: string;
        telephone: string;
      };
    }
  | {
      ok: false;
      body: any;
    };
export async function fetchParentLead(
  token: string
): Promise<ParentLeadResponse> {
  const response = await fetch(
    `${PARENTS_V1_URL}leads/${encodeURIComponent(token)}`,
    defaultGetRequest
  );
  if (response.ok) {
    return {
      ok: true,
      body: (await response.json()) as {
        email: string;
        name: string;
        telephone: string;
      },
    };
  }
  return {
    ok: false,
    body: await response.json(),
  };
}

type LeadStatusResponse =
  | {
      token: string;
      status: 'active';
    }
  | {
      status: 'claimed';
    }
  | {
      status: 'empty';
    }
  | {
      status: 'unknown';
    }
  | {
      status: 'registered';
    };

export async function getLeadStatus(
  email: string
): Promise<LeadStatusResponse> {
  const response = await fetch(
    `${PARENTS_V1_URL}leads/status?email=${encodeURIComponent(email)}`,
    defaultGetRequest
  );
  if (response.ok) {
    return (await response.json()) as LeadStatusResponse;
  }
  if (response.status === 404) {
    return { status: 'empty' };
  }
  return {
    status: 'unknown',
  };
}
