import { StyleProp, TextStyle } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { TextFinePrint } from '../StyledText';

export const WeekBookingDiscountInfo = ({
  onlyWeekly,
  style,
}: {
  onlyWeekly?: boolean;
  style?: StyleProp<TextStyle>;
}) => {
  const theme = useColorScheme();

  const message = onlyWeekly
    ? 'This location only allows booking by week which comes with a 20% discount'
    : '*Book by Week comes with a 20% discount';
  return (
    <TextFinePrint
      style={[
        {
          marginTop: 6,
          textAlign: 'center',
          color: Colors[theme].accentTertiary,
        },
        style,
      ]}
    >
      {message}
    </TextFinePrint>
  );
};
