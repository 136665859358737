import { useQuery } from '@tanstack/react-query';

import { getLocationSlots } from '../api/locations';

export default function useLocationSlotsQuery(locationId: number | null) {
  const { data, isFetching } = useQuery({
    queryKey: ['location_slots', locationId],
    queryFn: () => (locationId ? getLocationSlots(locationId) : []),
    initialData: [],
    enabled: locationId !== null,
  });

  return {
    data,
    isFetching,
    firstSlotDate: data.map((s) => s.date).sort()[0],
  } as const;
}
