import { CSSTransition } from 'react-transition-group';

import { TransparentView } from '../../../components/Themed';

export default function AnimatedContainer({
  visible,
  children,
  animation,
}: {
  children: React.ReactNode;
  visible: boolean;
  animation: 'slide-right' | 'slide-left';
}) {
  return (
    <TransparentView
      style={[
        {
          width: '100%',
          height: '100%',
          zIndex: 2,
          justifyContent: 'flex-start',
          flex: 1,
        },
        !visible && {
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
        },
      ]}
    >
      <CSSTransition
        timeout={500}
        in={visible}
        classNames={animation}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransparentView>
  );
}
