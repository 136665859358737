import { useCallback, useMemo, useReducer } from 'react';

import { getEmptyStrings, BaseState } from './useForm';
import { isValidArrayV2, validateFieldV2 } from '../utils/validations';

type KeyOf<T extends object> = Extract<keyof T, string>;

type Action<V, F extends Exclude<keyof V, 'id'>> =
  | { type: 'entryField'; index: number; field: F; value: V[F] }
  | { type: 'validate'; index: number; field: F }
  | { type: 'error'; index: number; field: F; value: string }
  | { type: 'add' }
  | { type: 'remove'; index: number }
  | { type: 'requestSent' };

export const useFormArray = <
  ValuesType extends { readonly id: number | undefined } & Record<K, any>,
  K extends string = KeyOf<ValuesType>,
>(
  initialValues: ValuesType[]
) => {
  const emptyErrorsObject = getEmptyStrings(initialValues[0]!);
  const initialValuesState = useMemo(
    () => ({
      values: initialValues,
      errors: initialValues.map(() => ({ ...emptyErrorsObject })),
    }),
    []
  );

  type ValuesState = typeof initialValuesState;

  const [state, dispatch] = useReducer(
    (
      state: ValuesState & BaseState,
      action: Action<ValuesType, Exclude<keyof ValuesType, 'id'>>
    ) => {
      switch (action.type) {
        case 'add': {
          return {
            ...state,
            values: [...state.values, {} as ValuesType],
            errors: [...state.errors, { ...emptyErrorsObject }],
          };
        }
        case 'remove': {
          return {
            ...state,
            values: [...state.values.filter((_, idx) => idx !== action.index)],
            errors: [...state.errors.filter((_, idx) => idx !== action.index)],
          };
        }
        case 'entryField': {
          const values = [...state.values];
          const v = values[action.index];
          if (!v) {
            return state;
          }
          v[action.field] = action.value;
          const errors = [...state.errors];
          errors[action.index]![action.field] = '';
          return {
            ...state,
            dirty: true,
            isInvalid: !isValidArrayV2(values),
            values,
          };
        }
        case 'requestSent': {
          return {
            ...state,
            dirty: false,
            isInvalid: false,
          };
        }
        case 'error': {
          const values = [...state.values];
          const v = values[action.index];
          if (!v) {
            return state;
          }
          const errors = [...state.errors];
          errors[action.index]![action.field] = action.value;
          return {
            ...state,
            errors,
          };
        }
        case 'validate': {
          const values = [...state.values];
          const c = values[action.index];
          if (!c) {
            return state;
          }

          const validationResult = validateFieldV2(
            action.field as string,
            c[action.field]
          );
          if (!validationResult) {
            return state;
          }
          const errors = [...state.errors];
          errors[action.index]![action.field] = validationResult.message;
          return {
            ...state,
            errors,
            isInvalid: !isValidArrayV2(values),
            dirty: true,
          };
        }
        default:
          return state;
      }
    },
    {
      ...initialValuesState,
      dirty: false,
      isInvalid: false,
    }
  );

  const getSerializedEntries = () => state.values;

  const validateFieldsForObject = useCallback(
    (index: number, fields: Exclude<KeyOf<ValuesType>, 'id'>[]) => {
      let ok = true;
      fields.forEach((field) => {
        const validationResult = validateFieldV2(
          field,
          state.values[index]?.[field] || ''
        );
        if (!validationResult) {
          return;
        }
        ok = false;
        dispatch({
          type: 'error',
          field,
          index,
          value: validationResult.message,
        });
      });
      return {
        ok,
      };
    },
    [state]
  );

  return {
    state,
    dispatch,
    getSerializedEntries,
    validateFieldsForObject,
  };
};
