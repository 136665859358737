export const makeCached = <T extends any[], R>(
  fn: (...args: T) => Promise<R>,
  opts: { cacheExpirationTimeInMs: number }
) => {
  const cache: Record<
    string,
    {
      result: R;
      time: number;
    }
  > = {};

  return async (...argsInner: T) => {
    const cacheKey = JSON.stringify(argsInner);
    const cacheEntry = cache[cacheKey];
    if (
      cacheEntry &&
      Date.now() - cacheEntry.time < opts.cacheExpirationTimeInMs
    ) {
      return cacheEntry.result;
    }
    return fn(...argsInner).then((result) => {
      cache[cacheKey] = {
        result,
        time: Date.now(),
      };
      return result;
    });
  };
};
