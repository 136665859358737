import { StyleSheet } from 'react-native';

import { TransparentRow } from './Row';
import { View, ViewProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

export interface DotsProps {
  activeIndex: number;
  amount: number;
  containerStyle?: ViewProps['style'];
  dotProps?: Partial<DotProps>;
}

export default function Dots({
  activeIndex = 0,
  amount,
  containerStyle,
  dotProps,
}: DotsProps) {
  return (
    <TransparentRow style={containerStyle}>
      {Array(amount)
        .fill(1)
        .map((r, idx) => (
          <Dot
            selected={idx === activeIndex}
            idx={idx}
            key={idx}
            {...dotProps}
          />
        ))}
    </TransparentRow>
  );
}

export type DotProps = {
  selected: boolean;
  idx: number;
  activeColor?: string;
  inactiveColor?: string;
  style?: ViewProps['style'];
};

export function Dot({
  selected,
  idx,
  activeColor,
  inactiveColor,
  style,
}: DotProps) {
  const theme = useColorScheme();
  return (
    <View
      role="presentation"
      style={[
        styles.dot,
        { backgroundColor: inactiveColor || Colors[theme].background },
        selected && {
          backgroundColor: activeColor || Colors[theme].accentTertiary,
        },
        style,
      ]}
      key={`dot-${idx}`}
    />
  );
}

const styles = StyleSheet.create({
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    marginHorizontal: 3,
  },
});
