import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isMobileDevice: width <= 430,
  isLargeDevice: width > 430,
  navbar: {
    height: {
      mobile: 55,
      desktop: 80,
    },
  },
  // isLargeDevice: width >= 1280
};
