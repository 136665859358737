import { useState, useCallback, useEffect } from 'react';

export default function useScrollInfo(el: HTMLElement | null) {
  const [scroll, setScroll] = useState(false);
  const handleScroll = useCallback(() => {
    if (!el) {
      return;
    }
    if (!scroll && el.scrollTop > 0) {
      setScroll(true);
    } else if (scroll && el.scrollTop === 0) {
      setScroll(false);
    }
  }, [el, scroll, setScroll]);

  useEffect(() => {
    if (!el) {
      return;
    }
    el.addEventListener('scroll', handleScroll);
    return () => {
      el.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return scroll;
}
