import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import { Icon } from './Icon';
import { CalendarPlaceholder } from './booking_calendar/CalendarPlaceholder';
import {
  useCurrentVisibleDay,
  useUpdateCurrentVisibleDay,
} from './booking_calendar/CalendarTypeToggler';
import {
  appendWeekItemAttr,
  appendWeekItemStartAttr,
  appendWeekItemEndAttr,
  removeDisabledAttr,
  appendDisabledAttr,
  appendUnavailableAttr,
  removeUnavailableAttr,
  removeWeekItemAttr,
  clearAllSelectedWeekAttr,
  appendSelectedWeekAttr,
  attachMouseEnterLeaveListeners,
  appendDateAttributes,
} from './booking_calendar/weekly-dom-utils';
import { DateSlots } from '../contexts/checkout';
import { formatShortWeekday } from '../utils/date';
import { dateToString } from '../utils/locations';

function setWeekItems(week: WeekConfig, slots: string[]) {
  const allDaysDisabled = week.entries.every(
    (dayString) => !slots.includes(dayString)
  );
  week.entries.forEach((dayString, idx, arr) => {
    const btn = document.querySelector(`button[data-date="${dayString}"]`);
    if (!btn) {
      return;
    }
    (btn as HTMLElement).dataset.weekid = week.id;
    appendWeekItemAttr(btn);
    if (idx === 0) {
      appendWeekItemStartAttr(btn);
    }
    if (idx === arr.length - 1) {
      appendWeekItemEndAttr(btn);
    }

    if (!allDaysDisabled) {
      attachMouseEnterLeaveListeners(btn, week.id);
      removeDisabledAttr(btn);
    } else {
      appendDisabledAttr(btn);
    }

    if (!slots.includes(dayString)) {
      appendUnavailableAttr(btn);
    } else {
      removeUnavailableAttr(btn);
    }
  });
}

function clearAllWeekButtons() {
  const buttons = document.querySelectorAll('button[data-week_item]');
  buttons.forEach((el) => {
    const btn = el as HTMLElement;
    removeWeekItemAttr(btn);
    appendUnavailableAttr(btn);
    delete btn.dataset['weekid'];
  });
}

function updateSelectedWeeks(chosenWeeks: Record<string, string[]>) {
  clearAllSelectedWeekAttr();
  Object.keys(chosenWeeks).forEach((weekid) => {
    const weekRowButtons = Array.from(
      document.querySelectorAll(`button[data-weekid="${weekid}"]`)
    );

    weekRowButtons.forEach(appendSelectedWeekAttr);
  });
}

type WeekConfig = {
  id: string;
  entries: string[];
};

function WeekCalendar({
  weeks: availableWeeks,
  dateSlots,
  today,
  chosenWeekDaytimes,
  onCandidateWeekSelected,
}: {
  weeks: WeekConfig[];
  today?: Date;
  dateSlots: Record<string, DateSlots>;
  chosenWeekDaytimes: Record<string, string[]>;
  onCandidateWeekSelected: (weekId: string) => void;
}) {
  const [startDate, setStartDate] = useState(today || new Date());

  const currentVisibleDay = useCurrentVisibleDay();
  useEffect(() => {
    if (!currentVisibleDay) return;
    if (
      [
        currentVisibleDay?.getFullYear() !== startDate.getFullYear(),
        currentVisibleDay?.getMonth() !== startDate.getMonth(),
      ].some((v) => !!v)
    ) {
      setStartDate(currentVisibleDay);
    }
  }, [currentVisibleDay, startDate]);

  const syncVisibleDay = useUpdateCurrentVisibleDay();
  const changeMonth = (activeStartDate: Date) => {
    syncVisibleDay(activeStartDate);
    setStartDate(activeStartDate);
  };

  useEffect(() => {
    if (today) {
      return;
    }
    const weeks = Object.keys(dateSlots);
    if (weeks.length === 0) {
      return;
    }

    const firstAvailableDate = Object.keys(dateSlots[weeks[0]!]!)[0];
    if (!firstAvailableDate) {
      return;
    }
    setStartDate(new Date(`${firstAvailableDate.replace(/-/g, '/')} 00:00`));
  }, [dateSlots, today]);

  const availableDays = availableWeeks.map((w) => w.entries).flat();

  useEffect(() => {
    appendDateAttributes(startDate);

    availableWeeks.forEach((week) => {
      const slots = Object.keys(dateSlots[week.id] || {});
      setWeekItems(week, slots);
    });

    if (availableWeeks.length === 0) {
      clearAllWeekButtons();
    }

    updateSelectedWeeks(chosenWeekDaytimes);
  }, [startDate, availableWeeks, chosenWeekDaytimes, dateSlots]);

  const onClickHandler = (weekId: string | undefined) => {
    if (!weekId) {
      return;
    }
    const selectedWeek = availableWeeks.find((w) => w.id === weekId);
    if (!selectedWeek) {
      throw new Error('missing week. this should not happen');
    }

    if (Object.keys(dateSlots[weekId] || {}).length === 0) {
      return;
    }

    onCandidateWeekSelected(selectedWeek.id);
  };

  return (
    <CalendarPlaceholder>
      <Calendar
        className="week-calendar"
        selectRange={false}
        locale="en-US"
        view="month"
        prevLabel={<Icon name="arrowBack" style={{ width: 25, height: 25 }} />}
        prevAriaLabel="Previous month"
        nextAriaLabel="Next month"
        nextLabel={
          <Icon name="arrowForward" style={{ width: 25, height: 25 }} />
        }
        prev2Label={null}
        next2Label={null}
        onActiveStartDateChange={(props) => {
          if (props.action === 'next' || props.action === 'prev') {
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
            changeMonth(props.activeStartDate);
          }
        }}
        activeStartDate={startDate}
        formatShortWeekday={formatShortWeekday}
        onClickDay={(value, e) => {
          e.preventDefault();
          e.stopPropagation();
          (document.activeElement as HTMLElement | undefined)?.blur();

          onClickHandler?.(e.currentTarget.dataset.weekid);
        }}
        tileDisabled={({ date }) => {
          const d = dateToString(date);
          return !d || !availableDays.includes(d);
        }}
      />
    </CalendarPlaceholder>
  );
}

export default WeekCalendar;
