import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Swipe from 'react-easy-swipe';
import { StyleSheet } from 'react-native';

import { IconButton } from './Button';
import { Icon } from './Icon';
import { TransparentRow } from './Row';
import { TransparentView, ViewProps, ViewWithClassname } from './Themed';

export type BumoCarouselInstance = {
  goToPrev: () => void;
  goToNext: () => void;
};

export type BumoCarouselProps = {
  children: React.ReactNode;
  renderedElements: number;
  onIndexChange?: (index: number) => void;
  containerStyle?: ViewProps['style'];
  itemContainerStyle?: ViewProps['style'];
  showArrows?: boolean;
  activeIndex?: number;
};

export default forwardRef<BumoCarouselInstance, BumoCarouselProps>(
  function BumoCarousel(
    {
      children,
      renderedElements: show,
      onIndexChange,
      containerStyle,
      itemContainerStyle,
      showArrows = true,
      activeIndex = 0,
    },
    ref
  ) {
    const [active, setActive] = useState(activeIndex);
    const count = React.Children.count(children);

    useEffect(() => {
      if (activeIndex === undefined || activeIndex === active) {
        return;
      }

      setActive(activeIndex);
    }, [activeIndex]);

    useEffect(() => {
      onIndexChange?.(active);
    }, [active]);

    const widthPercentage = 100 / show;
    const getTranslateModifier = () => -1 * active * (1 / show);

    const isLeftEnabled = active > 0;
    const isRightEnabled = active < count - show;

    const bothDisabled = !isLeftEnabled && !isRightEnabled;

    const showingArrows = !bothDisabled && showArrows;

    const outerContainerWidthRef = useRef<number>();

    const divRef = useRef<HTMLDivElement | null>();
    const swipePositionRef = useRef<number>();

    const goToPrev = () => isLeftEnabled && setActive((s) => s - 1);
    const goToNext = () => isRightEnabled && setActive((s) => s + 1);

    useImperativeHandle(ref, () => ({
      goToPrev,
      goToNext,
    }));

    return (
      <TransparentRow style={containerStyle}>
        {showingArrows ? (
          <IconButton
            icon={<Icon name="arrowLeft" />}
            text={null}
            onPress={goToPrev}
            style={[
              {
                opacity: !isLeftEnabled ? 0.25 : 1,
                left: 0,
              },
              styles.arrow,
            ]}
          />
        ) : null}
        <TransparentView
          style={{
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          }}
          onLayout={(e) =>
            (outerContainerWidthRef.current = e.nativeEvent.layout.width)
          }
        >
          <Swipe
            style={{
              width: '100%',
              transition: '.3s transform',
              transform: `translateX(calc(${getTranslateModifier() * 100}%))`,
            }}
            innerRef={(ref) => {
              divRef.current = ref as HTMLDivElement;
            }}
            onSwipeStart={() => {
              if (!divRef.current) {
                return;
              }
              // we need to disable animations to prevent "lag" on swipe move
              divRef.current.style.transitionDuration = '0s';
            }}
            onSwipeMove={(position, event) => {
              event.preventDefault();
              if (!divRef.current) {
                return;
              }

              divRef.current.style.transform = `translateX(calc(${
                getTranslateModifier() * 100
              }% + ${position.x}px))`;
              swipePositionRef.current = position.x;
            }}
            onSwipeEnd={(e) => {
              if (!divRef.current) {
                return;
              }
              // restore transition animation
              divRef.current.style.transitionDuration = '.3s';
              if (
                !outerContainerWidthRef.current ||
                !swipePositionRef.current
              ) {
                return;
              }
              e.nativeEvent.preventDefault();

              const slideUpdate = Math.floor(
                swipePositionRef.current /
                  (outerContainerWidthRef.current / show)
              );
              const slideModifier =
                slideUpdate >= 0 ? slideUpdate + 1 : slideUpdate;

              const nextActiveValue = Math.max(
                0,
                Math.min(active - slideModifier, count - show)
              );

              if (active === nextActiveValue) {
                divRef.current.style.transform = `translateX(calc(${
                  getTranslateModifier() * 100
                }%))`;
              }

              setActive(() => nextActiveValue);
              swipePositionRef.current = undefined;
            }}
          >
            <TransparentView
              style={{
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              {React.Children.map(children, (child, index) => (
                <ViewWithClassname
                  key={index}
                  style={[
                    {
                      backgroundColor: 'transparent',
                      width: `${widthPercentage}%`,
                      position: 'relative',
                    },
                    itemContainerStyle,
                  ]}
                >
                  {child}
                </ViewWithClassname>
              ))}
            </TransparentView>
          </Swipe>
        </TransparentView>
        {showingArrows ? (
          <IconButton
            icon={<Icon name="arrowRight" />}
            text={null}
            onPress={goToNext}
            style={[
              {
                opacity: !isRightEnabled ? 0.25 : 1,
                right: 0,
              },
              styles.arrow,
            ]}
          />
        ) : null}
      </TransparentRow>
    );
  }
);

const styles = StyleSheet.create({
  arrow: {
    position: 'absolute',
    top: '50%',
    marginTop: -20,
    zIndex: 1,
    backgroundColor: 'white',
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    shadowColor: '#000000bf',
    shadowRadius: 15,
  },
});
