import { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { StyleSheet } from 'react-native';

import { PrimaryButton } from './Button';
import CalendarSessionsProvider from './CalendarSessionsProvider';
import { Icon } from './Icon';
import KidsInput, { Kid } from './KidsInput';
import { findDependencies, getDayTimesToRemove } from './PickTimesPopup';
import { StyleProps, TransparentView } from './Themed';
import { getCachedStatus } from './booking_calendar/BookingCalendarError';
import { CalendarPlaceholder } from './booking_calendar/CalendarPlaceholder';
import CalendarTypeToggler, {
  useCurrentVisibleDay,
  useIsToggleAvailable,
  useUpdateCurrentVisibleDay,
} from './booking_calendar/CalendarTypeToggler';
import ChosenDailyViewer from './booking_calendar/ChosenDailyViewer';
import {
  ViewSelectedButton,
  ViewSelectedWrapper,
} from './booking_calendar/ViewSelectedButton';
import { WeekBookingDiscountInfo } from './booking_calendar/WeeklyDiscountInfo';
import {
  parseDailyParam,
  prepareSharedDailyParam,
  useShareLinkParam,
} from './booking_calendar/sharedParams';
import { mergeSameDateSlots } from './booking_calendar/utils';
import { getLocationSlots } from '../api/locations';
import { BookingSlot } from '../api/search';
import { useAuthenticate } from '../contexts/authFlow';
import { useIsLoggedIn } from '../contexts/authentication';
import {
  useCheckoutState,
  DayTime,
  useCheckoutStateUpdate,
  ChosenSlots,
  useCheckoutPropUpdate,
} from '../contexts/checkout';
import { prepareChosenSlotsRows } from '../hooks/daily/prepareChosenSlotsRows';
import useAppNavigation from '../hooks/useAppNavigation';
import { setEligibleKid } from '../utils/children';
import { dateToNumeric, formatShortWeekday } from '../utils/date';
import { dateToString } from '../utils/locations';
import { notNull } from '../utils/notnull';
import { transformSlots } from '../utils/slots';

export type BookingCalendarProps = StyleProps & {
  locationId: number;
  locationNameId: string;
  kids: Kid[];
  onKidChosenChange: (index: number) => void;
  onSubmit?: () => void;
  submitLabel?: string;
  scroll?: boolean;
  today?: Date;
  activeStartDate?: Date;
  onPricePress?: () => void;
  hasWeeklyDiscount: boolean;
};

export default function BookingCalendar({
  locationId,
  locationNameId,
  kids,
  onKidChosenChange,
  submitLabel,
  scroll,
  onSubmit,
  today = new Date(), // using new Date() here to allow injection in tests
  activeStartDate,
  onPricePress,
  hasWeeklyDiscount,
}: BookingCalendarProps) {
  const authenticate = useAuthenticate();
  const isLoggedIn = useIsLoggedIn();
  const { navigate } = useAppNavigation();

  const { dailyState: defaultChosenSlots } = useCheckoutState();

  const [slotsObject, setSlots] = useState<{
    slots: BookingSlot[];
    isFetching: boolean;
  }>({ slots: [], isFetching: true });
  const { slots } = slotsObject;

  const currentVisibleDay = useCurrentVisibleDay();
  const updateVisibleDay = useUpdateCurrentVisibleDay();

  useEffect(() => {
    const { slots, isFetching } = slotsObject;
    if (currentVisibleDay || isFetching) {
      return;
    }
    // const today = new Date();
    const todayPrefix = `${today.toLocaleDateString(undefined, {
      year: 'numeric',
    })}-${today.toLocaleDateString(undefined, {
      month: '2-digit',
    })}`;

    const firstSlotDate = slots.map((s) => s.date).sort()[0];

    if (
      firstSlotDate &&
      new Date(`${firstSlotDate.replace(/-/g, '/')} 00:00`) >= today &&
      !firstSlotDate.startsWith(todayPrefix)
    ) {
      updateVisibleDay(new Date(`${firstSlotDate.replace(/-/g, '/')} 00:00`));
    } else {
      updateVisibleDay(today);
    }
  }, [slotsObject, currentVisibleDay]);

  // choosing slot via button saves the value in defaultChosenSlots and currently persists between locations
  // since the default value ({ [locationNameId]: {} }) only works when defaultChosenSlots is not defined
  // hence it needs a fallback here
  // also changed the defaultChosenSlots structure to contain location name id
  const [chosenSlots, setChosenSlots] = useState<ChosenSlots>(
    defaultChosenSlots?.[locationNameId] || {}
  );
  const updateCheckoutProp = useCheckoutPropUpdate();
  useEffect(() => {
    updateCheckoutProp(
      {
        [locationNameId]: chosenSlots,
      },
      'dailyState'
    );
  }, [chosenSlots]);

  const [startDate, setStartDate] = useState(activeStartDate || today);

  const syncVisibleDay = useUpdateCurrentVisibleDay();
  const changeMonth = (newActiveDate: Date) => {
    syncVisibleDay(newActiveDate);
    setStartDate(newActiveDate);
  };

  const [shareLinkParam, resetShareLinkParam] = useShareLinkParam();
  useEffect(() => {
    if (!shareLinkParam || slotsObject.isFetching) return;
    const parsedParam = parseDailyParam(shareLinkParam);
    const allDateStrings = new Set(slotsObject.slots.map((e) => e.date));
    const allDaytimes = new Set([
      ...slotsObject.slots.map((e) => e.dayTime),
      'fullDay',
    ]);
    const filteredChosenFromParam = parsedParam.filter(
      (e) => allDateStrings.has(e.dateString) && allDaytimes.has(e.dayTime)
    );
    const readyChosenSlots = filteredChosenFromParam.reduce(
      (acc, cur) => {
        acc[cur.dateString] ||= [];
        acc[cur.dateString]?.push(cur.dayTime);
        return acc;
      },
      {} as Record<string, string[]>
    );

    setChosenSlots(readyChosenSlots);
    resetShareLinkParam();
  }, [slotsObject, shareLinkParam]);

  const [dateCandidate, setDateCandidate] = useState<Date | null>(null);

  const dateString = dateCandidate ? dateToString(dateCandidate) : null;

  const updateCheckoutState = useCheckoutStateUpdate();

  useEffect(() => {
    setSlots((s) => ({ ...s, isFetching: true }));
    getLocationSlots(locationId).then((response) => {
      setSlots({ slots: response, isFetching: false });
    });
    setChosenSlots(defaultChosenSlots?.[locationNameId] || {});
  }, [locationId]);

  const kidsWithEligibility = kids.map((kid) => setEligibleKid(kid, slots));
  const dateSlots = transformSlots(slots, kidsWithEligibility, isLoggedIn);

  // navigate to first available date
  useEffect(() => {
    if (activeStartDate) {
      // do not change the date if sync date was set
      return;
    }
    const dates = Object.keys(dateSlots);
    if (dates.length > 0) {
      setStartDate(new Date(dates.sort()[0]!));
      return;
    }
    if (slots.length > 0) {
      setStartDate(new Date(slots[0]?.date!));
    }
  }, [slots, activeStartDate]);

  const removeSlot =
    (onEmptyList: () => void) =>
    (dateString: string, dayTime: DayTime, compound = false) => {
      const itemsToRemove = getDayTimesToRemove(
        dayTime,
        chosenSlots[dateString] || [],
        findDependencies(dateSlots[dateString] || {})
      );
      const newDateSlots = (chosenSlots[dateString] || []).filter(
        (slot) => !itemsToRemove.includes(slot)
      );
      const newChosenSlots = {
        ...chosenSlots,
        [dateString]: newDateSlots,
      };
      if (!newDateSlots.length || compound) {
        delete newChosenSlots[dateString];
      }
      setChosenSlots(newChosenSlots);
      if (Object.keys(newChosenSlots).length === 0) {
        onEmptyList();
      }
    };

  const checkTileDisabled = ({ date }: { date: Date }) => {
    const availableDates = Object.keys(dateSlots).map((dateString) =>
      new Date(`${dateString.replace(/-/g, '/')} 00:00`).getTime()
    );
    const nowDate = new Date(today);
    // date is midnight in browser timezone so setting it the same here
    nowDate.setHours(0, 0, 0, 0);
    const maxDate = new Date(today);
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate() + 365);
    return (
      // two sources of truth - now date doesn't allow today but availableDates will have it
      date < nowDate ||
      date > maxDate ||
      !availableDates.includes(date.getTime())
    );
  };

  const checkTileActive = ({ date }: { date: Date }) => {
    if (Object.keys(chosenSlots).includes(dateToString(date)!)) {
      return 'react-calendar__tile--chosen';
    }
    return null;
  };

  const anyAvailableChosen = useCallback(() => {
    return Object.keys(chosenSlots).some((dateString) => {
      return chosenSlots[dateString]?.some((dayTime: DayTime) => {
        return !!dateSlots[dateString]?.[dayTime];
      });
    });
  }, [dateSlots, chosenSlots]);

  const handleSubmit = () => {
    const availableSlots = Object.keys(chosenSlots).reduce(
      (acc, dateString) => {
        const dayTimes = chosenSlots[dateString]!.filter((dayTime: DayTime) => {
          const availableSlot = dateSlots[dateString]?.[dayTime];
          return !!availableSlot;
        });
        if (dayTimes.length > 0) {
          acc[dateString] = dayTimes;
        }
        return acc;
      },
      {} as ChosenSlots
    );
    updateCheckoutState({
      locationId,
      locationNameId,
      slots,
      dateSlots,
      chosenSlots: {
        [locationNameId]: availableSlots,
      },
      kids: kidsWithEligibility,
      bookingMode: 'date_range',
    });
    if (onSubmit) {
      onSubmit();
    } else {
      navigate('ConfirmBooking');
    }
  };

  const onLoggedOutEdit = () => authenticate();

  const isToggleAvailable = useIsToggleAvailable();

  const periodSessionProps =
    dateString && dateCandidate
      ? {
          dateString: dateToNumeric(dateCandidate),
          chosenSlots: chosenSlots[dateString] || [],
          slots: dateSlots[dateString] || {},
        }
      : null;

  const onSessionSelect = (dayTimes: DayTime[]) => {
    if (!dateString) {
      return;
    }
    setChosenSlots({
      ...chosenSlots,
      [dateString]: [...dayTimes],
    });
    setDateCandidate(null);
  };

  const chosenSlotsEntries = prepareChosenSlotsRows(
    chosenSlots,
    dateSlots,
    slots
  );

  const mergedSlotsEntries = mergeSameDateSlots(chosenSlotsEntries);

  return (
    <CalendarSessionsProvider
      showingPeriodSessions={!!dateCandidate}
      onClosePeriodSessions={() => setDateCandidate(null)}
      periodSessionProps={periodSessionProps}
      onSessionsSelect={onSessionSelect}
      getSharedParam={() =>
        prepareSharedDailyParam(
          chosenSlotsEntries
            .map((e) =>
              e
                ? {
                    dateString: e.dateString,
                    dayTime: e.dayTime,
                  }
                : null
            )
            .filter(notNull)
        )
      }
      selectedEntriesComponent={(onEmptyList) => (
        <ChosenDailyViewer
          chosenSlotsEntries={chosenSlotsEntries}
          onRemove={removeSlot(onEmptyList)}
        />
      )}
    >
      {(showSelected) => (
        <>
          <KidsInput
            kids={kidsWithEligibility}
            onChange={onKidChosenChange}
            onLoggedOutEdit={onLoggedOutEdit}
            style={{ marginBottom: 5 }}
            isFetching={slotsObject.isFetching}
            errorStatus={
              isLoggedIn
                ? getCachedStatus({
                    isLoaded: isLoggedIn && !slotsObject.isFetching,
                    kids: kidsWithEligibility,
                    dateSlots,
                  })
                : null
            }
            onPricePress={onPricePress}
          />

          {isToggleAvailable ? (
            <CalendarTypeToggler type="date_range" />
          ) : (
            <TransparentView style={{ height: 10 }} />
          )}

          <CalendarPlaceholder>
            <Calendar
              locale="en-US"
              view="month"
              prevLabel={
                <Icon name="arrowBack" style={{ width: 25, height: 25 }} />
              }
              prevAriaLabel="Previous month"
              nextAriaLabel="Next month"
              nextLabel={
                <Icon name="arrowForward" style={{ width: 25, height: 25 }} />
              }
              prev2Label={null}
              next2Label={null}
              onActiveStartDateChange={({ activeStartDate }) => {
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
                changeMonth(activeStartDate);
              }}
              activeStartDate={startDate}
              formatShortWeekday={formatShortWeekday}
              onClickDay={(date) => setDateCandidate(date)}
              tileClassName={checkTileActive}
              tileDisabled={checkTileDisabled}
            />
          </CalendarPlaceholder>
          <TransparentView
            style={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: 'auto',
              justifyContent: 'flex-end',
              marginTop: 5,
            }}
          >
            {isToggleAvailable && hasWeeklyDiscount ? (
              <WeekBookingDiscountInfo />
            ) : null}
            <ViewSelectedWrapper>
              <PrimaryButton
                title={submitLabel || 'Book'}
                style={styles.button}
                disabled={
                  (isLoggedIn && kids.length === 0) ||
                  !Object.keys(chosenSlots).length ||
                  !anyAvailableChosen()
                }
                onPress={isLoggedIn ? handleSubmit : onLoggedOutEdit}
                testID="book-button"
              />

              {chosenSlotsEntries.length > 0 ? (
                <ViewSelectedButton
                  amount={mergedSlotsEntries.length}
                  showSelected={showSelected}
                />
              ) : null}
            </ViewSelectedWrapper>
          </TransparentView>
        </>
      )}
    </CalendarSessionsProvider>
  );
}

const styles = StyleSheet.create({
  divider: {
    marginTop: 15,
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
    height: 50,
    flex: 1,
  },
  kids: {
    marginBottom: 20,
  },
});
