import { Pressable } from 'react-native';

import { Icon } from '../../../components/Icon';
import { TextCtaButton } from '../../../components/StyledText';
import { TransparentView } from '../../../components/Themed';
import Colors from '../../../constants/Colors';
import useColorScheme from '../../../hooks/useColorScheme';

export default function PickTimesHeader({
  onBack,
  title,
}: {
  onBack: () => void;
  title: string;
}) {
  const theme = useColorScheme();
  return (
    <TransparentView
      style={{
        width: '100%',
        position: 'relative',
        borderBottomWidth: 1,
        borderBottomColor: Colors[theme].lines,
        paddingBottom: 16,
        marginBottom: 16,
      }}
      testID="pick-times-header"
    >
      <Pressable
        onPress={onBack}
        style={{ position: 'absolute', left: 0, zIndex: 10 }}
        testID="go-back"
      >
        <Icon name="arrowLeft" style={{ width: 24, height: 24 }} />
      </Pressable>
      <TextCtaButton style={{ textAlign: 'center', paddingHorizontal: 30 }}>
        {title}
      </TextCtaButton>
    </TransparentView>
  );
}
