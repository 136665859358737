import { ImageStyle, Platform, StyleSheet } from 'react-native';

import Logo from './Logo';
import { TransparentRow } from './Row';
import { MontRegularText } from './StyledText';
import { TransparentView, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';

export default function BumoGiftCard({
  width,
  mediumCardSize,
  calculateSize,
}: {
  width?: number;
  mediumCardSize?: boolean;
  calculateSize?: boolean;
}) {
  const theme = useColorScheme();

  const isMediumDevice = useMaxWidth(1100);
  const mediumCardSizeFlag = mediumCardSize || isMediumDevice;
  const isSmallDevice = useMaxWidth(800);
  return (
    <View
      accessibilityRole="none"
      style={[
        styles.card,
        isSmallDevice && styles.cardMobile,
        {
          shadowRadius: 12,
          shadowOpacity: 0.8,
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowColor: Colors[theme].shadow,
        },
        width ? { width, height: width / 1.58 } : {},
      ]}
      lightColor={Colors.light.accentTertiary}
      darkColor={Colors.dark.accentTertiary}
    >
      <TransparentView
        style={[styles.cardContent, isSmallDevice && styles.cardContentMobile]}
      >
        <TransparentRow>
          <Logo
            color
            style={[
              styles.logo as ImageStyle,
              mediumCardSizeFlag && (styles.logoMedium as ImageStyle),
              calculateSize && width
                ? { width: width / 1.5, height: width / 1.5 / 2.666 }
                : {},
            ]}
          />
        </TransparentRow>
        <MontRegularText
          style={[
            styles.cardText,
            { color: Colors[theme].background },
            width && width < 500 ? { marginTop: 20 } : {},
            calculateSize && width
              ? { marginTop: width / 1.58 / 8, fontSize: width / 1.58 / 8 }
              : {},
          ]}
        >
          Gift Card
        </MontRegularText>
      </TransparentView>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    aspectRatio: 1.6,
    borderRadius: 20,
  },
  cardMobile: {
    width: 316,
    height: 202,
    paddingBottom: 0,
  },
  cardContentMobile: {
    position: 'relative',
    height: '100%',
  },
  cardContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardText: {
    fontSize: 25,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 45,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 30,
        }),
      },
    }),
  },
  logo: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 300,
          height: 112,
        }),
        ...(Layout.isMobileDevice && {
          width: 130,
          height: 48,
        }),
      },
    }),
  },
  logoMedium: {
    width: 200,
    height: (200 * 112) / 300,
  },
  logoSmall: {
    width: 130,
    height: 48,
  },
});
