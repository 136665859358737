import { useLayoutEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { View } from '../Themed';

export default function SkeletonBlockMedium() {
  const theme = useColorScheme();
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    (containerRef.current as unknown as HTMLElement).classList.add(
      'skeleton-container'
    );
  }, []);
  return (
    <View
      style={[styles.container, { borderColor: Colors[theme].lines }]}
      ref={containerRef}
    >
      <View style={[styles.skeleton, styles.image]} />
      <View style={{ padding: 10 }}>
        <View style={[styles.skeleton, styles.title]} />
        <View style={[styles.skeleton, styles.details]} />
        <View style={[styles.skeleton, styles.button]} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 183,
    height: 246,
    borderRadius: 10,
    borderWidth: 1,
  },
  skeleton: {
    backgroundColor: '#eee',
    borderRadius: 5,
  },
  image: {
    height: 100,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    height: 42,
  },
  details: {
    height: 40,
    marginTop: 5,
  },
  button: {
    width: 102,
    height: 27,
    marginTop: 5,
  },
});
