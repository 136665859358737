/* eslint-disable no-extend-native */

if (!String.prototype.replaceAll) {
  // @ts-expect-error
  String.prototype.replaceAll = function (
    searchValue: string | RegExp,
    replaceValue: string
  ) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(searchValue).toLowerCase() ===
      '[object regexp]'
    ) {
      return this.replace(searchValue, replaceValue);
    }

    // If a string
    return this.replace(new RegExp(searchValue, 'g'), replaceValue);
  };
}

if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (callback, thisArg) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return i;
    }
    return -1;
  };
}
