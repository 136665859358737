import ChosenSlotRow, { ChosenSlotRowProps } from './ChosenSlotRow';
import { mergeSameDateSlots } from './utils';

export default function ChosenDailyViewer({
  chosenSlotsEntries,
  onRemove,
}: {
  chosenSlotsEntries: (undefined | ChosenSlotRowProps)[];
  onRemove: (dateString: string, dayTime: string, compound?: boolean) => void;
}) {
  const mergedSlotsEntries = mergeSameDateSlots(chosenSlotsEntries);

  return (
    <>
      {mergedSlotsEntries.map((props) =>
        props ? (
          <ChosenSlotRow
            key={props.dateString + props.dayTime}
            {...props}
            removeSlot={onRemove}
          />
        ) : null
      )}
    </>
  );
}
