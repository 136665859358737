import { useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { CSSTransition } from 'react-transition-group';

import OnboardingBonusModal from './OnboardingBonusModal';
import { OnboardingChildrenModal } from './OnboardingChildrenModal';
import { OnboardingProfileModal } from './OnboardingProfileModal';
import Colors from '../../constants/Colors';
import FeatureFlags from '../../constants/FeatureFlags';
import { useAuthDispatch, useAuthState } from '../../contexts/authentication';
import { useSnackbarDispatch } from '../../contexts/snackbar';
import useBlockBodyScroll from '../../hooks/useBlockBodyScroll';
import useColorScheme from '../../hooks/useColorScheme';
import { useMinWidth } from '../../hooks/useResponsive';
import ModalCloseButton from '../ModalCloseButton';
import ProgressBar from '../ProgressBar';
import { TextBodySmall, TextTag } from '../StyledText';
import { Divider, TransparentView, View } from '../Themed';

function AnimatedContainer({
  visible,
  children,
  animation,
}: {
  children: React.ReactNode;
  visible: boolean;
  animation: 'slide-right' | 'slide-left';
}) {
  return (
    <CSSTransition
      timeout={500}
      in={visible}
      classNames={animation}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
}

export default function OnboardingModal({
  isVisible,
  step,
  onSuccess,
  goBack,
  lead,
  continueToReferral,
}: {
  step: number;
  isVisible: boolean;
  onSuccess: () => void;
  goBack: () => void;
  continueToReferral: () => void;
  lead: null | {
    email: string;
    name: string;
    telephone: string;
  };
}) {
  const theme = useColorScheme();
  const isDesktop = useMinWidth(560);
  useBlockBodyScroll(isVisible);

  const dispatchSnackbar = useSnackbarDispatch();

  const { parent } = useAuthState();
  const authDispatch = useAuthDispatch();

  const [cachedChildrenFormValues, setCachedChildrenFormValues] = useState<
    {
      id: undefined;
      firstName: string;
      birthdate: string;
    }[]
  >([
    {
      id: undefined,
      firstName: '',
      birthdate: '',
    },
  ]);

  const isBonusStep = step === 3;

  return (
    <Modal
      useNativeDriver
      isVisible={isVisible}
      hideModalContentWhileAnimating
      style={{ margin: 0 }}
      testID="onboarding-modal"
    >
      <View
        style={[
          styles.modalContainer,
          !isDesktop && styles.modalContainerMobile,
        ]}
      >
        {isBonusStep ? (
          <ModalCloseButton
            absolute
            style={{ top: 30, right: 35 }}
            onPress={onSuccess}
          />
        ) : null}
        <TransparentView style={styles.header}>
          <TransparentView
            style={[
              styles.headerText,
              isBonusStep && { alignItems: 'flex-start' },
            ]}
          >
            {isBonusStep ? (
              <TextTag>Bonus</TextTag>
            ) : (
              <TextTag>Step {step} of 2</TextTag>
            )}
          </TransparentView>
          <ProgressBar
            progress={step / 2 / 1.08}
            color={Colors[theme].accent}
            style={{ backgroundColor: Colors[theme].background, width: '100%' }}
          />
          <Divider />
        </TransparentView>
        <TransparentView style={styles.contentContainer}>
          <TransparentView
            style={[
              step !== 1 && styles.absolute,
              styles.content,
              isDesktop && styles.desktopContent,
            ]}
          >
            <AnimatedContainer visible={step === 1} animation="slide-right">
              <OnboardingProfileModal
                parent={parent}
                onSuccess={(updatedParent) => {
                  if (updatedParent) {
                    authDispatch({
                      type: 'login_success',
                      value: {
                        parent: updatedParent,
                        refresh_token: '',
                      },
                    });

                    dispatchSnackbar({
                      message: (
                        <TextBodySmall>
                          Your profile has been updated.
                        </TextBodySmall>
                      ),
                    });
                  }
                  onSuccess();
                }}
                lead={lead}
              />
            </AnimatedContainer>
          </TransparentView>
          <TransparentView
            style={[
              step !== 2 && styles.absolute,
              styles.content,
              isDesktop && styles.desktopContent,
            ]}
          >
            <AnimatedContainer visible={step === 2} animation="slide-left">
              <OnboardingChildrenModal
                onSuccess={(children) => {
                  if (parent && children) {
                    authDispatch({
                      type: 'login_success',
                      value: {
                        parent: {
                          ...parent,
                          children,
                        },
                        refresh_token: '',
                      },
                    });
                  }
                  (FeatureFlags.REFERRALS ? continueToReferral : onSuccess)();
                }}
                goBack={goBack}
                initialValues={cachedChildrenFormValues}
                onFormChange={setCachedChildrenFormValues}
              />
            </AnimatedContainer>
          </TransparentView>
          <TransparentView
            style={[
              step !== 3 && styles.absolute,
              styles.content,
              isDesktop && styles.desktopContent,
            ]}
          >
            <AnimatedContainer visible={step === 3} animation="slide-left">
              <OnboardingBonusModal
                parent={parent}
                onSuccess={onSuccess}
                onLinkCopied={() =>
                  dispatchSnackbar({
                    message: (
                      <TextBodySmall>Link copied to clipboard!</TextBodySmall>
                    ),
                  })
                }
              />
            </AnimatedContainer>
          </TransparentView>
        </TransparentView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 10,
    maxHeight: '100%',
  },
  modalContainerMobile: {
    flex: 1,
    padding: 0,
    borderRadius: 0,
  },
  contentContainer: {
    padding: 40,
    backgroundColor: 'transparent',
    flex: 1,
    ...Platform.select({
      web: {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    }),
  },
  absolute: {
    position: 'absolute',
    overflow: 'hidden',
  },
  content: {
    maxWidth: 500,
    width: '100%',
  },
  desktopContent: {
    width: 500,
  },
  header: {
    paddingHorizontal: 40,
    paddingTop: 40,
    width: '100%',
    flexBasis: 90,
  },
  headerText: {
    width: '100%',
    height: 40,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
});
