import { StyleSheet } from 'react-native';

import Layout from '../../constants/Layout';
import useAppNavigation from '../../hooks/useAppNavigation';
import { UnderlinedButton } from '../Button';
import { View, TransparentView } from '../Themed';

export default function BackToProfileButton() {
  const { navigate } = useAppNavigation();
  return (
    <UnderlinedButton
      title="Back"
      testID="back-button"
      onPress={() => navigate('Profile')}
    />
  );
}

export function BackToProfileButtonView() {
  return (
    <View style={styles.actions}>
      <TransparentView style={styles.actionsInner}>
        <BackToProfileButton />
      </TransparentView>
    </View>
  );
}

const styles = StyleSheet.create({
  actions: {
    padding: 40,
    ...(Layout.isMobileDevice && {
      padding: 20,
    }),
    paddingBottom: 0,
    alignItems: 'center',
  },
  actionsInner: {
    maxWidth: 1200,
    width: '100%',
    alignItems: 'flex-start',
  },
});
