import { TransparentRow } from './Row';
import { TextTag, TextTagBold } from './StyledText';
import { StyleProps } from './Themed';
import { useMinWidth } from '../hooks/useResponsive';

export default function Tag({
  icon,
  text,
  color,
  style,
  ignoreResponsive = false,
  bold,
}: {
  icon: React.ReactNode;
  text: string;
  color: string;
  style?: StyleProps['style'];
  ignoreResponsive?: boolean;
  bold?: boolean;
}) {
  const isLargeDevice = useMinWidth(600);
  const TextComponent = bold ? TextTagBold : TextTag;
  return (
    <TransparentRow
      style={[
        {
          height: 32,
          borderRadius: 16,
          backgroundColor: color,
          paddingHorizontal: 10,
        },
        !isLargeDevice && {
          height: 26,
        },
        style,
      ]}
    >
      {icon}
      {(isLargeDevice || ignoreResponsive) && text ? (
        <TextComponent bold={bold} style={bold && { fontWeight: '700' }}>
          {text}
        </TextComponent>
      ) : null}
    </TransparentRow>
  );
}
