import Constants from 'expo-constants';

type AppEnvironment = 'dev' | 'staging' | 'production';

export const ENVIRONMENT: AppEnvironment =
  Constants.expoConfig?.extra?.ENV ?? 'dev';

export const getSpecificConfig = <T, F = null>(
  config: Partial<Record<AppEnvironment, T>>,
  fallbackValue: F
) => config[ENVIRONMENT] || fallbackValue;
