import { useCallback, useEffect, useState } from 'react';
import { ImageStyle, Platform, StyleSheet } from 'react-native';

import {
  WriteReviewModal,
  EditReviewModal,
  ShowMoreModal,
  DeleteReviewModal,
} from './ReviewModals';
import { getReviewsForLocation } from '../../api/reviews';
import FeatureFlags from '../../constants/FeatureFlags';
import { useAuthenticate } from '../../contexts/authFlow';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useParent } from '../../hooks/useChild';
import { useMaxWidth } from '../../hooks/useResponsive';
import { useReviewsApi } from '../../hooks/useReviewsApi';
import {
  LocationReviews as LocationReviewsType,
  Review,
} from '../../types/Reviews';
import { PrimaryButton, SecondaryButton } from '../Button';
import { Image } from '../Image';
import { TextBody, TextH3 } from '../StyledText';
import { TransparentView, View, ViewProps } from '../Themed';
import { ReviewBlock } from '../reviews/ReviewBlock';
import ReviewsRatingsSummary from '../reviews/ReviewsRatingsSummary';
import ReviewsSummary from '../reviews/ReviewsSummary';

function useLocationReviews(
  locationId: number,
  onReviewsFetched?: (reviews: LocationReviewsType) => void
) {
  const [fetching, setFetching] = useState(true);
  const [fetchedReviews, setFetchedReviews] =
    useState<LocationReviewsType | null>(null);

  const fetchReviews = useCallback(async () => {
    const response = await getReviewsForLocation(locationId);
    setFetching(false);
    if (!response.ok) {
      console.log('there was a problem while fetching reviews.');
      return;
    }
    setFetchedReviews(response.reviews);
    onReviewsFetched?.(response.reviews);
  }, [locationId]);
  useEffect(() => {
    if (!FeatureFlags.REVIEWS) {
      return;
    }

    fetchReviews();
  }, [locationId]);
  return [fetchedReviews, fetching, fetchReviews] as const;
}

export default function LocationReviews({
  locationId,
  style,
  onReviewsFetched,
  outdoor,
  forceReviewsModal,
  forceWriteReview,
}: {
  locationId: number;
  forceReviewsModal?: boolean;
  forceWriteReview?: boolean;
  outdoor: boolean;
  style: ViewProps['style'];
  onReviewsFetched?: (reviews: LocationReviewsType | null) => void;
}) {
  const navigation = useAppNavigation();
  const authenticate = useAuthenticate();
  const { deleteReview } = useReviewsApi();

  const [reviews, fetchingReviews, refetchReviews] = useLocationReviews(
    locationId,
    onReviewsFetched
  );

  const shouldBeFullWidth = useMaxWidth(1024);
  const isMobileDevice = useMaxWidth(500);

  const [writingReview, setWritingReview] = useState(!!forceWriteReview);
  const [editingReview, setEditingReview] = useState<Review | null>(null);
  const [deletingReview, setDeletingReview] = useState(false);

  useEffect(() => {
    if (writingReview) {
      navigation.setParams({ writeReview: true });
    } else {
      navigation.setParams({ writeReview: undefined });
    }
  }, [writingReview]);

  const [showingAll, setShowingAll] = useState(!!forceReviewsModal);

  useEffect(() => {
    if (showingAll) {
      navigation.setParams({ reviews: true });
    } else {
      navigation.setParams({ reviews: undefined });
    }
  }, [showingAll]);

  const hasAnyReviews = !fetchingReviews && (reviews?.reviews.length || 0) > 0;

  const parent = useParent();
  const userReview = reviews?.reviews.find(
    (reviewItem) => parent.id === reviewItem.author.id
  );
  const hasUserReview = !!userReview;

  const deleteCurrentReview = async () => {
    if (!editingReview) {
      return;
    }
    const successful = await deleteReview(editingReview.id);
    if (!successful) {
      return;
    }
    refetchReviews();
    setEditingReview(null);
    setDeletingReview(false);
  };

  if (!FeatureFlags.REVIEWS) {
    return null;
  }

  return (
    <>
      <View style={style}>
        <Image
          source={require('../../assets/images/green-snowflake.png')}
          style={styles.snowflake as ImageStyle}
        />
        <TextH3>Reviews</TextH3>

        {hasAnyReviews ? (
          <>
            <ReviewsSummary reviews={reviews} />
            <TransparentView style={styles.featuresContainer}>
              <ReviewsRatingsSummary
                outdoor={outdoor}
                reviews={reviews}
                shouldBeFullWidth={shouldBeFullWidth}
              />
            </TransparentView>
          </>
        ) : null}

        <View
          style={[styles.reviewsSection, isMobileDevice && styles.carousel]}
        >
          {hasUserReview ? (
            <ReviewBlock
              outdoor={outdoor}
              review={userReview}
              setEditingReview={setEditingReview}
              isUserReview
              shouldBeFullWidth={shouldBeFullWidth}
              onReviewRendered={() => onReviewsFetched?.(reviews)}
              showMoreAndFocus={() => setShowingAll(true)}
            />
          ) : null}
          {reviews?.reviews
            .filter((r) => r !== userReview)
            .slice(0, hasUserReview ? 3 : 4)
            .map((reviewItem, idx) => (
              <ReviewBlock
                outdoor={outdoor}
                review={reviewItem}
                key={`${reviewItem.id}-${idx}`}
                shouldBeFullWidth={shouldBeFullWidth}
                onReviewRendered={() => onReviewsFetched?.(reviews)}
                showMoreAndFocus={(reviewId) => {
                  setShowingAll(true);
                  // scroll to the review in ShowMoreModal
                  setTimeout(() => {
                    const reviewEl = document.getElementById(
                      `review-${reviewId}`
                    );
                    if (!reviewEl) {
                      return;
                    }
                    reviewEl.scrollIntoView();
                  }, 100);
                }}
              />
            ))}
        </View>
        {!hasAnyReviews ? (
          <TextBody
            style={{ zIndex: 100, marginBottom: isMobileDevice ? 20 : 16 }}
          >
            Be the first to review this provider!
          </TextBody>
        ) : null}

        {hasAnyReviews ? (
          <PrimaryButton title="See All" onPress={() => setShowingAll(true)} />
        ) : null}

        {!hasUserReview ? (
          <SecondaryButton
            title="Write a Review"
            onPress={async () => {
              try {
                await authenticate('LOGIN');
                setWritingReview(true);
              } catch {}
            }}
            style={styles.actionButton}
          />
        ) : (
          <SecondaryButton
            title="Edit My Review"
            onPress={async () => {
              try {
                setEditingReview(userReview);
              } catch {}
            }}
            style={styles.actionButton}
          />
        )}
      </View>
      <ShowMoreModal
        outdoor={outdoor}
        reviews={reviews}
        isVisible={showingAll}
        hide={() => setShowingAll(false)}
        onMyReviewClick={() => {
          if (!userReview) {
            return;
          }
          setEditingReview(userReview);
          setShowingAll(false);
        }}
      />
      <WriteReviewModal
        outdoor={outdoor}
        locationId={locationId}
        isVisible={writingReview}
        hide={() => setWritingReview(false)}
        onReviewAdded={() => refetchReviews()}
      />
      <EditReviewModal
        outdoor={outdoor}
        isVisible={!!editingReview && !deletingReview}
        review={editingReview}
        hide={() => setEditingReview(null)}
        onReviewUpdated={() => refetchReviews()}
        onDelete={() => setDeletingReview(true)}
      />
      {editingReview ? (
        <DeleteReviewModal
          isVisible={deletingReview}
          hide={() => {
            setDeletingReview(false);
          }}
          onConfirm={deleteCurrentReview}
        />
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  snowflake: {
    position: 'absolute',
    width: 156,
    height: 156,
    right: 0,
    top: -50,
  },
  featuresContainer: {
    marginTop: 24,
    marginBottom: 16,
  },
  reviewsSection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: 20,
    marginBottom: 20,
  },
  reviewSectionFullWidth: {
    flexDirection: 'column',
  },
  carousel: {
    ...Platform.select({
      web: {
        scrollbarWidth: 'none',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        gap: 10,
      },
    }),
  },
  actionButton: { backgroundColor: 'transparent', marginTop: 20 },
});
