import { StyleSheet } from 'react-native';

import { Icon } from './Icon';
import Row, { TransparentRow } from './Row';
import { View, ViewWithClassname } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth, useMinWidth } from '../hooks/useResponsive';

function PDPSkeleton() {
  const theme = useColorScheme();
  const isMobileDevice = useMaxWidth(768);
  return (
    <View style={{ minHeight: 800 }}>
      <Images />
      <Row
        style={{
          marginTop: 30,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: isMobileDevice ? 'column' : 'row',
        }}
      >
        <View
          style={{
            maxWidth: isMobileDevice ? '100%' : 700,
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <ViewWithClassname
            className="skeleton-container"
            style={{ width: '100%' }}
          >
            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: isMobileDevice ? '100%' : 400, height: 52 },
              ]}
            />
            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: 200, height: 30, marginTop: 5 },
              ]}
            />
            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: 100, height: 20, marginTop: 5 },
              ]}
            />
            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: 100, height: 20, marginTop: 5 },
              ]}
            />

            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: '100%', height: 40, marginTop: 100 },
              ]}
            />

            <View
              style={[
                styles.textSkeleton,
                styles.skeleton,
                { width: '100%', maxWidth: 150, height: 32.5, marginTop: 35 },
              ]}
            />
            {Array(10)
              .fill(1)
              .map((c, idx) => (
                <View
                  key={`content-${idx}`}
                  style={[
                    styles.textSkeleton,
                    styles.skeleton,
                    {
                      width: '100%',
                      height: 32.5,
                      marginTop: idx === 0 ? 15 : 3,
                    },
                  ]}
                />
              ))}
          </ViewWithClassname>
        </View>
        {isMobileDevice ? null : (
          <View>
            <View
              style={{
                width: 375,
                height: 522,
                shadowOpacity: 0.8,
                shadowRadius: 12,
                shadowOffset: {
                  width: 0,
                  height: 4,
                },
                shadowColor: Colors[theme].shadow,
                borderRadius: 10,
                justifyContent: 'space-between',
                padding: 20,
              }}
            >
              <TransparentRow
                style={{
                  height: 55,
                  borderColor: Colors[theme].lines,
                  borderWidth: 1,
                  borderRadius: 100,
                  padding: 10,
                  paddingLeft: 10,
                  justifyContent: 'flex-start',
                }}
              >
                <Icon name="profile" color={Colors[theme].accent} />
                <View>
                  <View
                    style={{
                      backgroundColor: '#eee',
                      borderRadius: 4,
                      height: 15,
                      width: 50,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: '#eee',
                      borderRadius: 4,
                      height: 20,
                      width: 80,
                      marginTop: 2,
                    }}
                  />
                </View>
              </TransparentRow>
              <TransparentRow style={{ flexWrap: 'wrap', width: '100%' }}>
                {Array(35)
                  .fill(1)
                  .map((el, idx) => (
                    <View
                      key={`day-${idx}`}
                      style={{
                        flexGrow: 1,
                        width: '14.2%',
                        height: 35,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: 12,
                          backgroundColor: '#eee',
                        }}
                      />
                    </View>
                  ))}
              </TransparentRow>

              <View
                style={{
                  height: 50,
                  borderRadius: 100,
                  backgroundColor: Colors[theme].cta,
                  opacity: 0.5,
                }}
              />
            </View>
          </View>
        )}
      </Row>
    </View>
  );
}

function Images() {
  const isMobileDevice = useMaxWidth(768);
  const isLargeDevice = useMinWidth(1100);

  if (isMobileDevice) {
    return (
      <ViewWithClassname
        className="skeleton-container"
        style={{ flexDirection: 'row' }}
      >
        <View style={[styles.skeleton, { width: '100%', height: 210 }]} />
      </ViewWithClassname>
    );
  }
  return (
    <ViewWithClassname
      className="skeleton-container"
      style={{ flexDirection: 'row', justifyContent: 'center' }}
    >
      <View style={[styles.skeleton, styles.mainImage]} />
      <View>
        <TransparentRow style={isLargeDevice ? styles.row : styles.column}>
          <View style={[styles.skeleton, styles.image]} />
          <View style={[styles.skeleton, styles.image]} />
        </TransparentRow>
        {isLargeDevice ? (
          <TransparentRow style={[styles.row, { marginBottom: 0 }]}>
            <View style={[styles.skeleton, styles.image]} />
            <View style={[styles.skeleton, styles.image]} />
          </TransparentRow>
        ) : null}
      </View>
    </ViewWithClassname>
  );
}

export default PDPSkeleton;

const styles = StyleSheet.create({
  skeleton: {
    backgroundColor: '#fefefe',
  },
  textSkeleton: {
    borderRadius: 4,
  },
  mainImage: {
    borderRadius: 10,
    width: 590,
    height: 332,
    marginRight: 20,
  },
  image: {
    borderRadius: 10,
    width: 285,
    height: 160,
    overflow: 'hidden',
  },
  row: {
    marginBottom: 12,
    gap: 20,
  },
  column: {
    flexDirection: 'column',
    gap: 12,
  },
});
