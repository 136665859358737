import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import Links from '../../constants/Links';
import useColorScheme from '../../hooks/useColorScheme';
import { handleLink } from '../../utils/common';
import { LocationCtaButton } from '../Button';
import InfoPopup from '../InfoPopup';

export default function StartRequiredForms() {
  const theme = useColorScheme();
  return (
    <>
      <LocationCtaButton
        style={[
          styles.button,
          styles.licensingButton,
          { backgroundColor: Colors[theme].accent },
        ]}
        left="Start Required Forms Now"
        onPress={() => handleLink(Links.forms.getAll)}
      />
      <InfoPopup
        text="Important! Required forms must be submitted BEFORE your first session. However, you may book without them. Forms may include licensing forms, including one completed by your pediatrician. We suggest you complete forms even if you're not yet ready to book."
        pressableProps={{
          accessibilityRole: 'alert',
          accessibilityLabel: 'Information about Required Forms',
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  buttons: {
    marginTop: 10,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: 10,
    marginBottom: 10,
  },
  licensingButtons: {
    marginTop: 0,
  },
  licensingButton: {
    marginRight: 5,
  },
});
