import React, { useLayoutEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import AnimatedContainer from './AnimatedContainer';
import { TransparentView } from '../../../components/Themed';

function getElementHeight(el: HTMLDivElement) {
  if (Platform.OS !== 'web') return 400;
  return el.getBoundingClientRect().height;
}

export default function ComponentAnimator({
  left,
  right,
  visibleMode,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  visibleMode: 'LEFT' | 'RIGHT';
}) {
  const divRef = useRef(null);
  const [height, setHeight] = useState(400);
  useLayoutEffect(() => {
    if (!divRef.current) return;
    const height = getElementHeight(divRef.current);
    setHeight(height);
  }, []);
  return (
    <TransparentView
      ref={divRef}
      style={[
        { width: '100%', alignItems: 'flex-start', flexGrow: 1 },
        visibleMode === 'RIGHT' && { flexBasis: height },
      ]}
    >
      <AnimatedContainer
        animation="slide-right"
        visible={visibleMode === 'LEFT'}
      >
        {left}
      </AnimatedContainer>

      <AnimatedContainer
        animation="slide-left"
        visible={visibleMode === 'RIGHT'}
      >
        {right}
      </AnimatedContainer>
    </TransparentView>
  );
}
