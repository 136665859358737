import { useState } from 'react';
import { ImageSourcePropType, StyleSheet } from 'react-native';

import Links from '../../constants/Links';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useMaxWidth, useMinWidth } from '../../hooks/useResponsive';
import { getImageUrl, handleLink } from '../../utils/common';
import { PrimaryButton } from '../Button';
import HowItWorksModal from '../HowItWorksModal';
import { Image } from '../Image';
import { InnerContainer } from '../Layout';
import { TransparentRow } from '../Row';
import { TextH2, TextBody } from '../StyledText';
import { View } from '../Themed';

export default function HowItWorksFor() {
  const isMobile = useMaxWidth(767);
  const isTabletDevice = useMinWidth(768);
  const isDesktop = useMinWidth(1024);

  const { navigate } = useAppNavigation();

  const [isModalVisible, setModalVisible] = useState(false);

  const handleFamiliesLink = () => {
    setModalVisible(true);
  };
  const handleProvidersLink = () => {
    handleLink(Links.providers);
  };

  const familiesContent = (
    <>
      {isModalVisible ? (
        <HowItWorksModal
          close={() => setModalVisible(false)}
          isVisible={isModalVisible}
        />
      ) : null}
      <TextH2 style={{ marginBottom: 20 }}>For Families</TextH2>
      <TextBody>
        <TextBody bold>
          Book quality, vetted child care as quickly as booking a restaurant
          reservation.
        </TextBody>{' '}
        Bumo understands that parents often need access to back-up care that can
        be booked instantly. Schedule child care by the half-day or full-day,
        when you need it, as much as you need it through Bumo's network of
        trusted child care centers.
      </TextBody>
      <PrimaryButton
        title="Learn More"
        style={[
          styles.learnMoreButton,
          isMobile && styles.learnMoreButtonMobile,
        ]}
        onPress={handleFamiliesLink}
      />
    </>
  );

  const providersContent = (
    <>
      <TextH2 style={{ marginBottom: 20 }}>For Providers</TextH2>
      <TextBody>
        <TextBody bold>
          Partner with us for free and start earning up to thousands in
          additional revenue a month!
        </TextBody>{' '}
        Bumo partners with child care providers, takes their unfilled sessions,
        and makes them bookable on our website so they become available to
        anyone who needs them. Bumo will handle licensing paperwork and payments
        to make it a stress-free experience for providers.
      </TextBody>
      <PrimaryButton
        title="Learn More"
        style={[
          styles.learnMoreButton,
          isMobile && styles.learnMoreButtonMobile,
        ]}
        onPress={handleProvidersLink}
      />
    </>
  );

  const handleEmployersLink = () => {
    navigate('EmployerLead');
  };

  const employersContent = (
    <>
      <TextH2 style={{ marginBottom: 20 }}>For Employers</TextH2>
      <TextBody>
        Reliable child care increases working parents' happiness and
        productivity. Be part of the solution by partnering with Bumo. Bumo
        comes to the rescue when regular child care arrangements fall through
        because school is closed or the nanny is sick, allowing your team to
        proceed with their workday, uninterrupted.
      </TextBody>
      <PrimaryButton
        title="Learn More"
        style={[
          styles.learnMoreButton,
          isMobile && styles.learnMoreButtonMobile,
        ]}
        onPress={handleEmployersLink}
      />
    </>
  );

  if (!isDesktop) {
    return (
      <View>
        <InnerContainer style={styles.howItWorksForOuter}>
          <TransparentRow style={[!isTabletDevice && styles.directionColumn]}>
            <Image
              source={
                getImageUrl('works-for-families.webp') as ImageSourcePropType
              }
              accessibilityLabel="Family embracing lovingly posing together with a smile."
              style={{
                width: 328,
                height: 300,
                borderRadius: 10,
                marginBottom: 40,
              }}
            />
            <View
              style={[
                { flex: 1, marginBottom: 44 },
                isTabletDevice && { padding: 24 },
              ]}
            >
              {familiesContent}
            </View>
          </TransparentRow>
          <TransparentRow
            style={[
              !isTabletDevice && styles.directionColumn,
              isTabletDevice && styles.directionRowReverse,
            ]}
          >
            <Image
              source={
                getImageUrl('works-for-providers.webp') as ImageSourcePropType
              }
              accessibilityLabel="Child care center director."
              style={{
                width: 328,
                height: 300,
                borderRadius: 10,
                marginBottom: 40,
              }}
            />
            <View
              style={[
                { flex: 1, marginBottom: 44 },
                isTabletDevice && { padding: 24 },
              ]}
            >
              {providersContent}
            </View>
          </TransparentRow>
          <TransparentRow
            style={[
              !isTabletDevice && styles.directionColumn,
              isTabletDevice && styles.directionRowReverse,
            ]}
          >
            <Image
              source={
                getImageUrl('works-for-employers.webp') as ImageSourcePropType
              }
              accessibilityLabel="Parents talking to a child with a smile."
              style={{
                width: 328,
                height: 300,
                borderRadius: 10,
                marginBottom: 40,
              }}
            />
            <View style={[{ flex: 1 }, isTabletDevice && { padding: 24 }]}>
              {employersContent}
            </View>
          </TransparentRow>
        </InnerContainer>
      </View>
    );
  }
  return (
    <View>
      <InnerContainer style={styles.howItWorksForOuter}>
        <TransparentRow>
          <Image
            source={
              getImageUrl('works-for-families.webp') as ImageSourcePropType
            }
            accessibilityLabel="Family embracing lovingly while mom pats her pregnant belly."
            style={{
              width: 580,
              height: 400,
              borderRadius: 10,
            }}
          />
          <View
            style={{
              padding: 50,
              marginLeft: 40,
              flex: 1,
            }}
          >
            {familiesContent}
          </View>
        </TransparentRow>
        <TransparentRow style={{ marginTop: 75 }}>
          <View
            style={{
              padding: 50,
              marginRight: 40,
              flex: 1,
            }}
          >
            {providersContent}
          </View>
          <Image
            source={
              getImageUrl('works-for-providers.webp') as ImageSourcePropType
            }
            accessibilityLabel="Child care center director talking to a family with a smile."
            style={{
              width: 580,
              height: 400,
              borderRadius: 10,
            }}
          />
        </TransparentRow>

        <TransparentRow style={{ marginTop: 75 }}>
          <Image
            source={
              getImageUrl('works-for-employers.webp') as ImageSourcePropType
            }
            accessibilityLabel="Family embracing lovingly while mom pats her pregnant belly."
            style={{
              width: 580,
              height: 400,
              borderRadius: 10,
            }}
          />
          <View
            style={{
              padding: 50,
              marginLeft: 40,
              flex: 1,
            }}
          >
            {employersContent}
          </View>
        </TransparentRow>
      </InnerContainer>
    </View>
  );
}
const styles = StyleSheet.create({
  howItWorksForOuter: {
    padding: 30,
    paddingVertical: 62,
  },
  directionColumn: {
    flexDirection: 'column',
  },
  directionRowReverse: {
    flexDirection: 'row-reverse',
  },
  learnMoreButton: {
    alignSelf: 'flex-start',
    marginTop: 30,
  },
  learnMoreButtonMobile: {
    width: '100%',
  },
});
