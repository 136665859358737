.carousel-root {
  width: 100%;
}

.reviews-carousel .slide {
  padding: 15px 30px;
}

.carousel .control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}


.fill-image-carousel {
  flex: 1;
  width: 100%;
  height: 100%;
}

.fill-image-carousel .carousel {
  flex: 1;
  width: 100%;
  height: 100%;
}

.additions-carousel .slide {
  display: flex;
}
.additions-carousel .slide > div {
  flex: 1;
}
.additions-carousel .control-dots {
  padding-bottom: 20px!important;
}

@media all and (max-width: 900px) {
  .additions-carousel .control-dots {
    padding-bottom: 12px!important;
  }
}

/* .my-carousel-item {
  transition: transform .5s;
} */