import { useCallback } from 'react';
import { ViewProps, Pressable } from 'react-native';

import Colors from '../../constants/Colors';
import { useUpdateSearchState } from '../../contexts/threeparamsearch';
import useColorScheme from '../../hooks/useColorScheme';
import { useShouldDisplayMobileSearch } from '../../hooks/useShouldDisplayNewSearch';
import { Icon } from '../Icon';

export function SearchIcon({ style }: { style?: ViewProps['style'] }) {
  const { setPopupState } = useUpdateSearchState();
  const theme = useColorScheme();
  const open = useCallback(() => {
    setPopupState({ open: true });
  }, [setPopupState]);
  const shouldDisplay = useShouldDisplayMobileSearch();
  if (!shouldDisplay) {
    return null;
  }
  return (
    <Pressable onPress={open} style={style}>
      <Icon name="search" color={Colors[theme].text} />
    </Pressable>
  );
}
