import { FeatureRatings } from '../api/reviews';

const FeatureTitles: Record<keyof FeatureRatings, string> = {
  cleanliness: 'Cleanliness',
  caregivers: 'Teachers & Caregivers',
  curriculum: 'Curriculum & Activities',
  professionalism: 'Professionalism',
  indoor: 'Indoor Space',
  outdoor: 'Outdoor Space',
};

export const StaticTexts = {
  'search.header.title': '{} Result||pluralize',
  'search.header.subtitle': 'Child Care Reimagined, Near You',
  'search.header.no_locations':
    "We're not in your city yet. Add your phone number below to get notified when Bumo is available in your city.",
  giftCard: {
    title: 'Give the Gift of Child Care',
    uniqueCodeGiftInfo: `Delivered directly to your gift recipient's email address, the Bumo Gift Card includes a unique gift code they can apply to purchase child care session(s) at any Bumo-verified location.`,
    asteriskMessage: `*When you give a gift card, you get 20% of the amount in BumoCredit to use for yourself!`,
    payButton: `Confirm and Pay`,
    reasonsTitle: 'The gift of child care is the gift of time',
    reasonsSubTitle: 'Just a few ways your gift can be used...',
    terms: `"Terms of Use|0" Apply`,
    waysToUse: [
      'Date Night',
      '"Me" Time',
      "Mom's Night Out",
      'Running Errands',
    ],
  },
  pdp: {
    FeatureTitles,
    bookByWeekDiscountInfo: `This location offers a 20% Book by Week discount. The discount applies to full-week bookings (Monday through Friday) even if blackout dates reduce available days to a minimum of four. Discount does not apply to partial-week bookings.`,
  },
  searchLeadForm: {
    kindOfCare: {
      fixed: 'Fixed, consistent care',
      flexible: 'Flexible, on-demand care',
      weekend: 'Weekend care',
      evening: 'Evening care',
    },
  },
  reviews: {
    needToBookFirst:
      'Your reviews are very important to us. In order to write a review for this location, you need to have booked and experienced a session here before. Please book first.',
  },
  formsReuseConsent: {
    enabledSnackbar:
      'Your forms will now be shared across the Bumo network, saving you time on future bookings.',
    disabledSnackbar: 'Your forms will not be shared across the Bumo network.',
  },
};
