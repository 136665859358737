import { useState, useSyncExternalStore } from 'react';
import { StyleSheet } from 'react-native';

import { BumoCreditPackage, buyPackage } from '../../api/bumoCredits';
import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import { formatPrice } from '../../utils/locations';
import { makeState } from '../../utils/state';
import { PrimaryButton } from '../Button';
import { TransparentRow } from '../Row';
import { TextH3, TextH4, TextH2, TextTag, TextCta } from '../StyledText';
import { TransparentView, View, ViewWithClassname } from '../Themed';

const fetchingPackageState = makeState(false);

export default function PackageItem({
  bumoPackage,
  source,
  isLoading,
  onLinkFetched,
}: {
  bumoPackage: BumoCreditPackage;
  source: 'landing' | 'list';
  isLoading?: boolean;
  onLinkFetched: (url: string) => void;
}) {
  const theme = useColorScheme();

  const [fetchingLinkUrl, setFetchingLinkUrl] = useState(false);

  const finalPrice = bumoPackage.price;
  const savingsAmount = bumoPackage.receiveAmount - finalPrice;
  const handlePackageBuy = async () => {
    const { link } = bumoPackage;

    fetchingPackageState.setValue(true);
    setFetchingLinkUrl(true);
    buyPackage(link, source).then((result) => {
      if (result.ok) {
        onLinkFetched(result.url);
        fetchingPackageState.setValue(false);
        setFetchingLinkUrl(false);
      } else {
        window.alert(result.error);
      }
    });
  };

  const fetchingAnyPackage = useSyncExternalStore(
    fetchingPackageState.onChange,
    fetchingPackageState.getValue
  );
  if (isLoading) {
    return (
      <ViewWithClassname
        className="skeleton-container-inside"
        style={[
          {
            backgroundColor: Colors[theme].background,
            shadowOpacity: 1,
            shadowColor: Colors[theme].shadow,
            shadowRadius: 12,
            height: 330,
          },
          styles.packageContainer,
        ]}
      >
        <View
          style={[
            styles.skeleton,
            {
              height: 70,
              width: '100%',
              marginBottom: 20,
            },
          ]}
        />

        <View
          style={[styles.skeleton, { height: 25, width: 50, marginBottom: 5 }]}
        />
        <View
          style={[styles.skeleton, { height: 30, width: 100, marginBottom: 5 }]}
        />
        <View
          style={[styles.skeleton, { height: 25, width: 50, marginBottom: 5 }]}
        />
        <View
          style={[styles.skeleton, { height: 30, width: 100, marginBottom: 5 }]}
        />
        <PrimaryButton
          title=" "
          disabled
          style={{ backgroundColor: Colors[theme].accent, marginTop: 20 }}
        />
      </ViewWithClassname>
    );
  }
  return (
    <TransparentView
      style={[
        {
          backgroundColor: Colors[theme].background,
          shadowColor: Colors[theme].shadow,
          shadowRadius: 12,
          shadowOpacity: 1,
          elevation: 4,
        },
        styles.packageContainer,
      ]}
    >
      <Savings amount={savingsAmount} />
      <Badge type={bumoPackage.badge} />
      <TextH3
        style={{
          padding: 16,
          paddingTop: 20,
          borderBottomColor: '#000',
          borderBottomWidth: 1,
          textAlign: 'center',
          width: '100%',
          marginBottom: 20,
        }}
      >
        {bumoPackage.name}
      </TextH3>
      <TextH4>You pay</TextH4>
      <TransparentRow style={{ marginBottom: 5 }}>
        {bumoPackage.originalPrice ? (
          <>
            <TextH3
              style={{
                marginRight: 5,
                color: Colors[theme].textSecondary,
                textDecorationLine: 'line-through',
              }}
            >
              {formatPrice(bumoPackage.originalPrice)}
            </TextH3>
          </>
        ) : null}
        <TextH3>{formatPrice(finalPrice)}</TextH3>
      </TransparentRow>
      <TextH4>You get</TextH4>
      <TextH2>{formatPrice(bumoPackage.receiveAmount)}</TextH2>
      <PrimaryButton
        disabled={fetchingAnyPackage}
        loading={fetchingLinkUrl}
        title="Buy Now & Save"
        style={{ backgroundColor: Colors[theme].accent, marginTop: 20 }}
        onPress={handlePackageBuy}
      />
    </TransparentView>
  );
}

function Badge({ type }: { type: BumoCreditPackage['badge'] }) {
  const theme = useColorScheme();
  if (type === 'best-deal') {
    return (
      <View
        style={[
          styles.badge,
          { backgroundColor: Colors[theme].accentSecondary },
        ]}
      >
        <TextTag>Best Deal</TextTag>
      </View>
    );
  }

  if (type === 'popular') {
    return (
      <View style={[styles.badge, { backgroundColor: Colors[theme].cta }]}>
        <TextTag>Popular</TextTag>
      </View>
    );
  }
  return null;
}

function Savings({ amount }: { amount: number }) {
  const theme = useColorScheme();
  return (
    <View
      style={{
        position: 'absolute',
        top: -46,
        right: -19,
        backgroundColor: Colors[theme].accentTertiary,
        width: 92,
        height: 92,
        borderRadius: 46,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TextCta style={{ color: Colors[theme].background, textAlign: 'center' }}>
        {formatPrice(amount)} in Savings
      </TextCta>
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: 16,
    borderRadius: 10,
    paddingHorizontal: 10,
    height: 20,
    lineHeight: 20,
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  packageContainer: {
    borderRadius: 30,
    width: 300,
    maxWidth: '100%',
    paddingTop: 17,
    padding: 16,
    alignItems: 'center',
  },
  skeleton: {
    backgroundColor: '#eee',
    borderRadius: 5,
  },
});
