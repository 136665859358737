import { StyleSheet } from 'react-native';

import { Icon } from './Icon';
import Row from './Row';
import { TextTag } from './StyledText';
import { StyleProps } from './Themed';
import { VerificationStatus } from '../api/search';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

function getConfig(theme: 'light' | 'dark', status: VerificationStatus) {
  switch (status) {
    case VerificationStatus.VERIFIED:
      return {
        color: Colors[theme].text,
        backgroundColor: Colors[theme].accent,
        text: 'Bumo Verified',
      };
    case VerificationStatus.UNVERIFIED:
      return {
        color: Colors[theme].text,
        backgroundColor: Colors[theme].accentSecondary,
        text: 'Verification Pending',
      };
    case VerificationStatus.COMING_SOON:
      return {
        color: Colors[theme].text,
        backgroundColor: Colors[theme].cta,
        text: 'Coming Soon',
      };
  }
}

export default function BumoVerified({
  style,
  status,
}: StyleProps & { status: VerificationStatus }) {
  const theme = useColorScheme();
  const config = getConfig(theme, status);
  if (!config) return null;
  return (
    <Row
      style={[
        styles.container,
        { backgroundColor: config.backgroundColor },
        style,
      ]}
    >
      <Icon name="shieldOutlined" color={config.color} style={styles.icon} />
      <TextTag forceStyles style={{ color: config.color, lineHeight: 24 }}>
        {config.text}
      </TextTag>
    </Row>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 24,
    borderRadius: 100,
    paddingHorizontal: 10,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
});
