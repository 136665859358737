import { StyleSheet } from 'react-native';

interface WebLinkProps {
  href: string;
  target?: string;
  rel?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export function WebLink({
  href,
  target,
  rel,
  children,
  ...otherProps
}: WebLinkProps) {
  return (
    <a
      href={href}
      target={target}
      rel={rel}
      style={styles.link}
      {...otherProps}
    >
      {children}
    </a>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'none',
  },
});
