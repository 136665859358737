import React, { createContext, useContext, useEffect, useState } from 'react';
import { ColorSchemeName, Platform, StyleSheet, View } from 'react-native';

import ModalCloseButton from '../components/ModalCloseButton';
import { TransparentRow } from '../components/Row';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';

const SnackbarStateContext = createContext<Snackbar>(null);
const SnackbarDispatchContext = createContext<React.Dispatch<
  React.SetStateAction<Snackbar>
> | null>(null);

let timer: number;

type SnackbarType = 'success' | 'error';

type Snackbar = null | {
  type?: SnackbarType;
  message: React.ReactNode;
};

function backgroundColor(
  type: SnackbarType = 'success',
  theme: NonNullable<ColorSchemeName>
) {
  switch (type) {
    case 'success':
      return Colors[theme].accentSecondary;
    case 'error':
      return Colors[theme].error;
  }
}

function iconColor(
  type: SnackbarType = 'success',
  theme: NonNullable<ColorSchemeName>
) {
  switch (type) {
    case 'success':
      return Colors[theme].text;
    case 'error':
      return Colors[theme].background;
  }
}

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const theme = useColorScheme();
  const [snackbar, setSnackbar] = useState<Snackbar>(null);

  const timeout = 7000;

  const handleClose = () => {
    setSnackbar(null);
    clearTimeout(timer);
  };

  useEffect(() => {
    if (snackbar) {
      timer = window.setTimeout(() => {
        setSnackbar(null);
      }, timeout);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [snackbar]);

  return (
    <SnackbarStateContext.Provider value={snackbar}>
      <SnackbarDispatchContext.Provider value={setSnackbar}>
        {children}
        {!!snackbar && (
          <View style={styles.toastContainer} testID="snackbar-toast">
            <View
              style={[
                styles.toast,
                {
                  shadowRadius: 12,
                  shadowOpacity: 0.8,
                  shadowOffset: {
                    width: 0,
                    height: 4,
                  },
                  shadowColor: Colors[theme].shadow,
                },
                { backgroundColor: backgroundColor(snackbar.type, theme) },
              ]}
            >
              <TransparentRow>
                <TransparentRow style={styles.row}>
                  {snackbar.message}
                </TransparentRow>
                <ModalCloseButton
                  testID="snackbar-close-toast"
                  onPress={handleClose}
                  style={styles.close}
                  iconStyle={[
                    {
                      width: 24,
                      height: 24,
                    },
                    { tintColor: iconColor(snackbar.type, theme) },
                  ]}
                />
              </TransparentRow>
            </View>
          </View>
        )}
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
};

export function useSnackbarState() {
  const context = useContext(SnackbarStateContext);
  if (context === undefined) {
    throw new Error('useSnackbarState must be used within a SnackbarProvider');
  }

  return context;
}

export function useSnackbarDispatch() {
  const context = useContext(SnackbarDispatchContext);
  if (!context) {
    throw new Error(
      'useSnackbarDispatch must be used within a SnackbarProvider'
    );
  }

  return context;
}

const styles = StyleSheet.create({
  toastContainer: {
    zIndex: 100000,
    width: '100%',
    // @ts-ignore
    position: 'fixed',
    alignItems: 'center',
    backgroundColor: 'transparent',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          bottom: 35,
        }),
        ...(Layout.isMobileDevice && {
          bottom: 10,
        }),
      },
    }),
  },
  toast: {
    marginHorizontal: 10,
    maxWidth: 900,
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 100,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          paddingHorizontal: 25,
        }),
        ...(Layout.isMobileDevice && {
          paddingHorizontal: 15,
        }),
      },
    }),
  },
  close: {
    marginLeft: 20,
  },
  row: {
    flex: 1,
    flexWrap: 'wrap',
  },
});
