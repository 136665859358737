import { ImageStyle, Platform, StyleSheet } from 'react-native';

import { Icon, IconName } from './Icon';
import Row from './Row';
import { TextBodySmall } from './StyledText';
import { StyleProps } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

type LocationDetailProps = StyleProps & {
  icon: IconName;
  text: string | React.ReactNode;
  iconStyle?: ImageStyle;
};

export function DetailsIcon({
  icon,
  style = {},
}: {
  icon: IconName;
  style?: ImageStyle;
}) {
  const theme = useColorScheme();
  return (
    <Icon
      name={icon}
      color={(style?.tintColor as string) || Colors[theme].accent}
      style={[styles.icon, style]}
    />
  );
}

export default function LocationDetail({
  style,
  icon,
  text,
  iconStyle = {},
}: LocationDetailProps) {
  return (
    <Row style={[styles.container, style]}>
      <DetailsIcon icon={icon} style={iconStyle} />
      <TextBodySmall style={styles.text}>{text}</TextBodySmall>
    </Row>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    marginRight: 14,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  text: {
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
    overflow: 'hidden',
  },
});
