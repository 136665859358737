import React, { useCallback, useState } from 'react';
import { Platform, StyleSheet, ImageStyle } from 'react-native';

import Colors from '../../constants/Colors';
import { useAuthenticate } from '../../contexts/authFlow';
import useColorScheme from '../../hooks/useColorScheme';
import { useMaxWidth, useMinWidth } from '../../hooks/useResponsive';
import { CdnImageName, getImageUrl } from '../../utils/common';
import BumoCarousel from '../BumoCarousel';
import { PrimaryButton } from '../Button';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { InnerContainer } from '../Layout';
import { TransparentRow } from '../Row';
import { TextBody, TextH2, TextTag } from '../StyledText';
import { StyleProps, TransparentView, View } from '../Themed';

const reviewHeight = 530;

const reviewsDataV2: {
  text: string;
  author: string;
  avatar: CdnImageName;
}[] = [
  {
    text: `As first time parents Bumo met all our needs with the flexibility we were looking for. Our 2 year old loves it there, the staff are super nice and friendly and so good with the kids. Cooper runs in the door, what more could you want!`,
    author: 'Maeve C., Mama to Cooper',
    avatar: 'testimonial-1-maeve-c.webp',
  },
  {
    text: `My schedule can be unpredictable at times so I love that there are many locations & times for me to drop my daughters off for last minute child care! One time I booked Bumo 30 minutes before the start time - it's that easy! By seeing the way the staff interact with & care for my daughters I can honestly say I would recommend Bumo to every mom that needs child care.`,
    author: 'nina S., mama to aivy & haly',
    avatar: 'testimonial-2-nina-s.webp',
  },
  {
    text: `As first time parents, we wanted to make sure we got our daughter's child care situation right the first time — luckily for us, we found BUMO. The staff has been so caring to our child from day one, and we feel that the vibrant community they've cultivated has allowed her to thrive.`,
    author: 'faimie f., mama to lennon',
    avatar: 'testimonial-3-faimie-f.webp',
  },
  {
    text: 'Booking on-demand child care through Bumo was a lifesaver for my family, especially when our nanny unexpectedly quit. Thanks to their easy-to-use platform, I was able to quickly find reliable care for my children. Bumo truly made the process stress-free and convenient!',
    author: 'Whitney k., mama to bear & georgie',
    avatar: 'testimonial-4-whitney-k.webp',
  },
  {
    text: 'With Bumo, our son has learned how to be more independent so he can enjoy life to the fullest!',
    author: 'melinda l., mama to carlo',
    avatar: 'testimonial-5-melinda-l.webp',
  },
  {
    text: `Where do I even start raving about Bumo? They're like the extended family I never knew I needed. Bumo isn't just a daycare- it's the Mary Poppins bag of child care magic. If you're a busy parent who wants your child loved and understood while learning new things, these guys are it.`,
    author: 'onelia m., mama to isla',
    avatar: 'testimonial-6-onelia-m.webp',
  },
  {
    text: `I have been using Bumo for over a year and have had the best experience. Clea is now 2.5 years old and is the happiest when she is at Bumo. She has never cried at drop off and doesn't want to leave when I pick her up. The staff at every location is amazing. I recommend Bumo to all parents. Choosing childcare is so hard but you're in good hands with Bumo!`,
    author: `kristin k., mama to clea`,
    avatar: 'testimonial-7-kristin-k.webp',
  },
  {
    text: `Bumo has truly been such a gift for our family! It's not easy to find quality and trustworthy child care, but with Bumo you can be rest assured that your little ones are in great hands. We are so thankful to have found Bumo and highly recommend them to all parents looking for safe and trustworthy child care!`,
    author: `alyssa s., mama to kai & noa`,
    avatar: 'testimonial-8-alyssa-s.webp',
  },
];

export default function Testimonials({
  endAdornment,
  style,
}: {
  endAdornment?: React.ReactNode;
  style?: StyleProps['style'];
}) {
  const isLargeDevice = useMinWidth(768);
  const theme = useColorScheme();
  const authenticate = useAuthenticate();

  const data = reviewsDataV2;
  return (
    <View style={[{ backgroundColor: Colors[useColorScheme()].accent }, style]}>
      <InnerContainer style={styles.container}>
        {Platform.OS === 'web' && isLargeDevice && (
          <Image
            style={styles.spring as ImageStyle}
            source={require('../../assets/images/spring.png')}
          />
        )}
        {Platform.OS === 'web' && isLargeDevice && (
          <Image
            style={[
              styles.ribbon as ImageStyle,
              { tintColor: Colors[theme].accentSecondary },
            ]}
            source={require('../../assets/images/ribbon.png')}
          />
        )}
        <TransparentView style={[styles.reviewsOuter, { paddingBottom: 10 }]}>
          <TextH2 style={{ alignSelf: 'center' }}>Parents love Bumo!</TextH2>
        </TransparentView>
        <ResponsiveCarouselContainer forcedHeight={reviewHeight + 50}>
          {data.map((r, index) => (
            <ReviewBlock
              {...r}
              key={`review-${index}`}
              forcedHeight={reviewHeight}
            />
          ))}
        </ResponsiveCarouselContainer>
        <TransparentView style={styles.reviewsOuter}>
          {endAdornment ? (
            endAdornment
          ) : (
            <PrimaryButton
              title="Start Free Account"
              style={{ alignSelf: 'center' }}
              onPress={() => authenticate()}
            />
          )}
        </TransparentView>
      </InnerContainer>
    </View>
  );
}

function ResponsiveCarouselContainer({
  children,
  forcedHeight,
}: {
  children: React.ReactNode;
  forcedHeight?: number;
}) {
  const isSmallDevice = useMaxWidth(850);
  const isLargeDevice = useMinWidth(1280);

  const [index, setIndex] = useState(0);
  const onIndexChange = useCallback((index: number) => {
    setIndex(index);
  }, []);

  return (
    <>
      <TransparentView
        style={[
          {
            position: 'relative',
            height: forcedHeight || 450,
            justifyContent: 'center',
          },
          !isSmallDevice && {
            paddingHorizontal: 15,
          },
        ]}
      >
        <BumoCarousel
          renderedElements={isLargeDevice ? 3 : isSmallDevice ? 1 : 2}
          onIndexChange={onIndexChange}
          itemContainerStyle={{ paddingHorizontal: 15 }}
          showArrows={!isSmallDevice}
          containerStyle={!isSmallDevice && { paddingHorizontal: 30 }}
        >
          {children}
        </BumoCarousel>
      </TransparentView>

      {isSmallDevice ? <Dots activeIndex={index} /> : null}
    </>
  );
}

function Dots({ activeIndex = 0 }: { activeIndex: number }) {
  const theme = useColorScheme();
  return (
    <TransparentRow>
      {reviewsDataV2.map((r, idx) => (
        <View
          style={[
            styles.dot,
            { backgroundColor: Colors[theme].background },
            idx === activeIndex && {
              backgroundColor: Colors[theme].accentTertiary,
            },
          ]}
          key={`dot-${idx}`}
        />
      ))}
    </TransparentRow>
  );
}

function ReviewBlock({
  text,
  author,
  avatar,
  forcedHeight,
}: {
  text: string | undefined;
  author: string;
  avatar: CdnImageName;
  forcedHeight?: number;
}) {
  const theme = useColorScheme();

  return (
    <View
      style={[
        styles.reviewBlock,
        { height: forcedHeight || styles.reviewBlock.height },
      ]}
    >
      <Image
        source={{ uri: getImageUrl(avatar) }}
        style={styles.reviewAvatar as ImageStyle}
      />
      <TransparentRow>
        <Icon name="star" style={{ tintColor: Colors[theme].cta }} />
        <Icon name="star" style={{ tintColor: Colors[theme].cta }} />
        <Icon name="star" style={{ tintColor: Colors[theme].cta }} />
        <Icon name="star" style={{ tintColor: Colors[theme].cta }} />
        <Icon name="star" style={{ tintColor: Colors[theme].cta }} />
      </TransparentRow>
      <View style={{ padding: 10 }}>
        <TextBody style={{ textAlign: 'center' }}>
          &ldquo;{text}&rdquo;
        </TextBody>
      </View>

      <View style={{ alignSelf: 'center', paddingTop: 10 }}>
        <TextTag>{author}</TextTag>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  reviewsOuter: {
    padding: 30,
  },
  reviewsOuterDesktop: {
    paddingVertical: 45,
    paddingHorizontal: 120,
  },
  reviewsDesktop: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    paddingVertical: 30,
    ...Platform.select({
      web: {
        gap: 30,
      },
    }),
  },
  carouselItem: {
    paddingHorizontal: 15,
    borderWidth: 0,
    flex: 1,
    justifyContent: 'center',
  },
  reviewBlock: {
    display: 'flex',
    height: 400,
    paddingHorizontal: 35,
    paddingVertical: 20,
    borderRadius: 10,
    ...Platform.select({
      web: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      },
    }),
  },
  reviewAvatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    alignSelf: 'center',
    marginBottom: 20,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    marginHorizontal: 3,
  },
  spring: {
    position: 'absolute',
    width: 76,
    height: 221,
    right: '1%',
    top: 0,
    transform: [
      {
        rotate: '126.69deg',
      },
    ],
  },
  ribbon: {
    position: 'absolute',
    width: 219,
    height: 217,
    left: -33,
    bottom: 40,
    transform: [
      {
        rotate: '-4.29deg',
      },
    ],
  },
});
