import { useCallback, useEffect, useRef, useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import {
  Pressable,
  StyleSheet,
  TextInput,
  LayoutChangeEvent,
  Platform,
  ViewStyle,
  DimensionValue,
} from 'react-native';
import Modal from 'react-native-modal';

import { InputContainer } from './InputContainer';
import KidsChooser from './KidsChooser';
import LocationSearchResults from './LocationSearchResults';
import { useLocationSearch } from './useLocationSearch';
import Colors from '../../constants/Colors';
import { useIsLoggedIn } from '../../contexts/authentication';
import {
  useSearchInputState,
  useSearchPopupState,
  useUpdateSearchState,
} from '../../contexts/threeparamsearch';
import useAppNavigation, {
  useCurrentRouteName,
} from '../../hooks/useAppNavigation';
import useBlockBodyScroll from '../../hooks/useBlockBodyScroll';
import useColorScheme from '../../hooks/useColorScheme';
import { formatRanges, formatShortWeekday } from '../../utils/date';
import { dateToString } from '../../utils/locations';
import { PrimaryButton, SecondaryButton, UnderlinedButton } from '../Button';
import { MapLibraryState } from '../GoogleMapLoader';
import { Icon } from '../Icon';
import ModalCloseButton from '../ModalCloseButton';
import Row, { TransparentRow } from '../Row';
import { TextH3 } from '../StyledText';
import { TransparentView } from '../Themed';

export default function SearchPopup() {
  const { open: popupOpen } = useSearchPopupState();
  useBlockBodyScroll(popupOpen);
  const { setPopupState, triggerSearchParamsUpdate, clearAllInputs } =
    useUpdateSearchState();
  const theme = useColorScheme();
  const onClose = useCallback(() => {
    setPopupState({
      open: false,
    });
  }, [setPopupState]);

  const navigation = useAppNavigation();
  const routeName = useCurrentRouteName();

  const goToSearchPage = useCallback(() => {
    triggerSearchParamsUpdate();
    if (routeName !== 'Search') {
      navigation.navigate('Search');
    }
    onClose();
  }, []);

  return (
    <Modal isVisible={popupOpen} useNativeDriver style={{ margin: 0 }}>
      <TransparentView
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: Colors[theme].background,
        }}
      >
        <TransparentView
          style={{
            padding: 10,
            paddingTop: 58,
            flex: 1,
          }}
        >
          <ModalCloseButton
            onPress={onClose}
            absolute
            style={{ width: 24, height: 24, top: 18, right: 12 }}
            iconStyle={{ width: 24, height: 24 }}
          />
          <WhereInputWrapper />
          <WhenInput />
          <WhoInput />
          <Row
            style={{
              padding: 10,
              paddingHorizontal: 24,
              shadowRadius: 12,
              shadowColor: Colors[theme].text,
              shadowOpacity: 0.25,
              justifyContent: 'space-between',
              zIndex: 9999,
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
            }}
          >
            <UnderlinedButton title="Clear all" onPress={clearAllInputs} />
            <PrimaryButton title="Search" onPress={goToSearchPage} />
          </Row>
        </TransparentView>
      </TransparentView>
    </Modal>
  );
}

const WhereInputWrapper = (props: {
  onActiveChange?: (v: boolean) => void;
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const run = async () => {
      await MapLibraryState.loaderPromise;
      setLoaded(true);
    };
    run();
  }, []);

  if (!loaded) {
    return (
      <InputContainer primaryText="Where" secondaryText="Search location" />
    );
  }

  return <WhereInput {...props} />;
};

function WhereInput(props: { onActiveChange?: (v: boolean) => void }) {
  const theme = useColorScheme();

  const inputState = useSearchInputState();
  const { setInputState } = useUpdateSearchState();

  const { value, setValue, data, chosen } = useLocationSearch(
    inputState.location
  );

  const [active, setActive] = useState(false);
  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    if (inputState.location.description) {
      setValue(inputState.location.description, false);
    }
  }, []);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    props.onActiveChange?.(active);
  }, [active]);

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const fixChooserHeight = useCallback((ev: LayoutChangeEvent) => {
    if (Platform.OS !== 'web') {
      return;
    }

    const { target } = ev.nativeEvent as LayoutChangeEvent['nativeEvent'] & {
      target: HTMLDivElement;
    };

    target.style.height = `${
      (window.visualViewport?.height || window.innerHeight) - 130
    }px`;
  }, []);

  useEffect(() => {
    setInputState({ location: chosen });
  }, [chosen]);

  useEffect(() => {
    if (
      !inputState.location.description &&
      inputState.location.description !== chosen.description
    ) {
      setValue('', false);
    }
  }, [inputState.location]);

  return (
    <>
      <Pressable onPress={focusInput}>
        <InputContainer
          primaryText="Where"
          secondaryText={
            <TextInput
              style={[
                Platform.select({
                  // @ts-expect-error
                  web: {
                    outline: 'none',
                    maxWidth: 'calc(100% - 48px)',
                  } as ViewStyle,
                }),
                styles.textInput,
              ]}
              placeholder="Search location"
              placeholderTextColor={Colors[theme].textSecondary}
              value={value}
              onChangeText={(v) => setValue(v)}
              onFocus={() => setActive(true)}
              ref={inputRef}
            />
          }
          clearable={value.length > 0}
          onClear={() => {
            setValue('');
            setInputState({
              location: {},
            });
          }}
        />
      </Pressable>
      {active && data && data.length > 0 ? (
        <TransparentView
          style={[
            styles.inputOpenedWrapper,
            {
              height: 'calc(100vh - 130px)' as DimensionValue,
              top: 130,
            },
          ]}
          onLayout={fixChooserHeight}
        >
          <TransparentView
            style={[
              {
                shadowColor: Colors[theme].lines,
                shadowRadius: 12,
                backgroundColor: Colors[theme].background,
              },
              styles.locationSearchContent,
            ]}
          >
            <TextH3 style={styles.locationSearchTitle}>
              Where do you need care?
            </TextH3>
            <LocationSearchResults data={data} />
          </TransparentView>
        </TransparentView>
      ) : null}
    </>
  );
}

const checkTileDisabled = ({ date }: { date: Date }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

function WhenInput() {
  const theme = useColorScheme();
  const [active, setActive] = useState(false);

  const { rangeStart, rangeEnd } = useSearchInputState();
  const { setInputState } = useUpdateSearchState();

  const checkTileActive = ({
    activeStartDate,
    date,
  }: CalendarTileProperties) => {
    const firstMonth = activeStartDate.getMonth();

    if (firstMonth !== date.getMonth()) {
      return 'react-calendar__tile--hidden';
    } else {
      const dateString: string = dateToString(date)!;
      if (
        rangeStart &&
        rangeEnd &&
        dateString > rangeStart &&
        dateString <= rangeEnd
      ) {
        return 'react-calendar__tile--chosen';
      } else if (rangeStart && dateString === rangeStart) {
        return 'react-calendar__tile--chosen';
      }
    }
    return null;
  };

  const secondaryText =
    formatRanges({ rangeStart, rangeEnd }, { short: true }) || 'Select dates';

  const onClear = () => {
    setInputState({
      rangeStart: null,
      rangeEnd: null,
    });
  };

  return (
    <>
      <Pressable onPress={() => setActive((s) => !s)}>
        <InputContainer
          primaryText="When"
          secondaryText={secondaryText}
          clearable={!!rangeStart || !!rangeEnd}
          onClear={onClear}
        />
      </Pressable>
      {active ? (
        <Pressable
          onPress={() => setActive((s) => !s)}
          style={styles.backdrop}
        />
      ) : null}
      {active ? (
        <TransparentView
          style={[
            styles.inputOpenedWrapper,
            { top: 202, height: 'calc(100vh - 202px)' as DimensionValue },
          ]}
        >
          <TransparentView
            style={[
              {
                shadowColor: Colors[theme].lines,
                shadowRadius: 12,
                backgroundColor: Colors[theme].background,
              },
              styles.locationSearchContent,
            ]}
          >
            <Calendar
              className="tps-calendar"
              selectRange
              next2Label={null}
              nextLabel={<Icon name="arrowForward" />}
              prevLabel={<Icon name="arrowBack" />}
              prev2Label={null}
              tileClassName={checkTileActive}
              tileDisabled={checkTileDisabled}
              minDetail="month"
              maxDetail="month"
              locale="en-US"
              allowPartialRange
              formatShortWeekday={formatShortWeekday}
              onChange={(date: any) => {
                const [start, end] = date as [Date, Date];
                const startString = dateToString(start);
                const endString = dateToString(end);
                if (end) {
                  setInputState({
                    rangeStart: startString,
                    rangeEnd: endString,
                  });
                } else {
                  setInputState({
                    rangeStart: startString,
                    rangeEnd: null,
                  });
                }
              }}
            />
            <TransparentRow style={{ paddingVertical: 16 }}>
              <SecondaryButton title="Next" onPress={() => setActive(false)} />
            </TransparentRow>
          </TransparentView>
        </TransparentView>
      ) : null}
    </>
  );
}

function WhoInput() {
  const theme = useColorScheme();
  const [active, setActive] = useState(false);
  const isLoggedIn = useIsLoggedIn();

  const { who } = useSearchInputState();
  const { setInputState } = useUpdateSearchState();
  const whoText =
    who.filter((k) => k.enabled && !!k.age).length > 0
      ? isLoggedIn
        ? who
            .filter((k) => k.enabled)
            .map((k) => k.name)
            .join(', ')
        : who
            .filter((k) => k.enabled)
            .map((k) => k.age)
            .join(', ')
      : 'Choose kids';

  const toggleActive = useCallback(() => setActive((s) => !s), []);
  const clearWhoInput = () => {
    setInputState({
      who: [
        {
          id: 1,
          name: 'Child 1',
          age: null,
          enabled: false,
        },
      ],
    });
  };
  return (
    <>
      <Pressable onPress={toggleActive}>
        <InputContainer
          primaryText="Who"
          secondaryText={whoText}
          clearable={
            !isLoggedIn && who.filter((k) => k.enabled && !!k.age).length > 0
          }
          onClear={clearWhoInput}
        />
      </Pressable>
      {active ? (
        <Pressable style={styles.backdrop} onPress={toggleActive} />
      ) : null}
      {active ? (
        <TransparentView
          style={[
            styles.inputOpenedWrapper,
            { top: 274, height: 'calc(100vh - 274px)' as DimensionValue },
          ]}
        >
          <TransparentView
            style={[
              {
                shadowColor: Colors[theme].lines,
                shadowRadius: 12,
                backgroundColor: Colors[theme].background,
              },
              styles.locationSearchContent,
            ]}
          >
            <KidsChooser useSheetSelector />

            <TransparentRow style={{ paddingVertical: 16 }}>
              <SecondaryButton title="Next" onPress={toggleActive} />
            </TransparentRow>
          </TransparentView>
        </TransparentView>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 24,
    marginHorizontal: 12,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 400,
  },
  button: {
    borderRadius: 28,
    height: 56,
    paddingHorizontal: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  searchIcon: {
    width: 34,
    height: 34,
    marginRight: 8,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    marginHorizontal: 8,
  },
  textInput: {
    marginTop: 3,
    width: '100%',
    borderWidth: 0,
    fontSize: 16,
    fontFamily: 'Mont',
    // @ts-expect-error
    outlineColor: 'transparent',
  },
  clearIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 48,
    height: 48,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputOpenedWrapper: {
    position: 'absolute',
    width: '100%',
    paddingHorizontal: 10,
    left: 0,
    zIndex: 10000,
  },
  locationSearchContent: {
    borderRadius: 24,
    padding: 24,
    height: 'calc(100% - 10px)' as DimensionValue,
  },
  locationSearchTitle: { alignSelf: 'center', marginBottom: 18, flex: 0 },
  backdrop: {
    opacity: 0.5,
    // backgroundColor: '#329da8',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
