const authHeader: { Authorization: null | string } = {
  Authorization: null,
};

export function setAuthorizationToken(token: null | string) {
  if (!token) {
    authHeader.Authorization = null;
    return;
  }
  authHeader.Authorization = `Bearer ${token}`;
}

export function getAuthorizationHeader() {
  if (!authHeader.Authorization) return {};
  return authHeader;
}
