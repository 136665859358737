import { Link } from '@react-navigation/native';
import { forwardRef } from 'react';
import { ImageProps, StyleSheet, Platform } from 'react-native';

import { Image } from './Image';
import Layout from '../constants/Layout';

type LogoProps = Omit<ImageProps, 'source'> & {
  white?: boolean;
  color?: boolean;
  colorBig?: boolean;
};

const logo = {
  default: require('../assets/images/logo.png'),
  white: require('../assets/images/logo_white.png'),
  color: require('../assets/images/logo_color.png'),
  colorBig: require('../assets/images/logo_color_big.png'),
};

const Logo = forwardRef<Image, LogoProps>((props, ref) => {
  const { white, color, colorBig, style, ...rest } = props;
  return (
    <Image
      {...rest}
      ref={ref}
      style={[styles.logo, style]}
      source={
        logo[
          white ? 'white' : color ? 'color' : colorBig ? 'colorBig' : 'default'
        ]
      }
      accessibilityLabel="Bumo logo"
    />
  );
});

export default Logo;

export function Care({ style }: LogoProps) {
  return (
    <Image
      style={[styles.care, style]}
      source={require('../assets/images/care.png')}
    />
  );
}

export function LogoCare({ white, style }: LogoProps) {
  return (
    <Link
      style={style}
      to={{ screen: 'Explore' }}
      accessibilityLabel="Link to homepage"
    >
      <Logo white={white} />
    </Link>
  );
}

export function LogoCareImage({ white, style }: LogoProps) {
  return <Logo white={white} />;
}

const styles = StyleSheet.create({
  logo: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 120,
          height: 45,
        }),
        ...(Layout.isMobileDevice && {
          width: 72,
          height: 27,
        }),
      },
    }),
  },
  container: {
    backgroundColor: 'transparent',
  },
  care: {
    marginLeft: 10,
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 53,
          height: 25,
        }),
        ...(Layout.isMobileDevice && {
          width: 42,
          height: 21,
        }),
      },
    }),
  },
});
