import { useEffect } from 'react';

import { useAuthenticate, useIsAuthEstablished } from '../contexts/authFlow';
import useAppNavigation from '../hooks/useAppNavigation';
import { RootStackScreenProps } from '../types';

export default function ReferralLink({
  route,
}: RootStackScreenProps<'ReferralLink'>) {
  const isAuthEstablished = useIsAuthEstablished();
  const authenticate = useAuthenticate();
  const { replace } = useAppNavigation();

  const { refferalCode } = route.params;

  useEffect(() => {
    if (!isAuthEstablished) {
      return;
    }
    authenticate(undefined, { refferalCode, source: 'REFERRAL' });
    replace('Explore');
  }, [isAuthEstablished]);

  return null;
}
