import { useEffect } from 'react';

import { useAuthenticate, useIsAuthEstablished } from '../contexts/authFlow';
import useAppNavigation from '../hooks/useAppNavigation';
import { RootStackScreenProps } from '../types';

export default function JoinNow({
  route: { params = {} },
}: RootStackScreenProps<'Onboarding'>) {
  const { replace } = useAppNavigation();
  const isAuthEstablished = useIsAuthEstablished();
  const authenticate = useAuthenticate();
  useEffect(() => {
    if (!isAuthEstablished) {
      return;
    }
    if (params['credit_token']) {
      authenticate(undefined, {
        source: 'BUMO_PACKAGE_BUY',
        creditToken: params['credit_token'],
      });
    } else {
      authenticate(undefined, { leadToken: params['lead-token'] });
    }
    replace('Explore');
  }, [isAuthEstablished]);

  return null;
}
