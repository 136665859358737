import {
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { useAuthState } from './authentication';
import { BookingMode, BookingSlot } from '../api/search';
import { Kid } from '../components/KidsInput';
import { Action, reducer } from '../reducers/genericContext';
import { getKidFromChild } from '../utils/children';
import { WeekEntry } from '../utils/weekly';

interface State {
  kids: Kid[];
  locationId?: number;
  locationNameId?: string;
  dateSlots?: DateSlots;
  slots: BookingSlot[];
  chosenSlots?: {
    [locationNameId: string]: ChosenSlots;
  };
  weeklyState?: {
    [locationNameId: string]: Record<string, string[]>;
  };
  weeklyInfo?: Record<string, DateSlots>;
  weekEntries?: WeekEntry[];
  dailyState?: {
    [locationNameId: string]: Record<string, string[]>;
  };
  bookingMode?: BookingMode;
}

const CheckoutStateContext = createContext<State>({ kids: [], slots: [] });
const CheckoutDispatchContext = createContext<React.Dispatch<Action<State>>>(
  () => {}
);

export const CheckoutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { parent: { children: kids = [] } = {} } = useAuthState();
  const [checkout, dispatch] = useReducer<Reducer<State, Action<State>>>(
    reducer,
    {
      kids: kids.map((child) => ({
        ...getKidFromChild(child),
        isChosen: true,
      })),
      slots: [],
    }
  );

  useEffect(() => {
    dispatch({
      type: 'field',
      field: 'kids',
      value: kids.map((child) => ({
        ...getKidFromChild(child),
        isChosen: true,
      })),
    });
    // kids.length to prevent infinite loop
  }, [kids.length]);

  return (
    <CheckoutStateContext.Provider value={checkout}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
};

export function useCheckoutState() {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error('useCheckoutState must be used within a CheckoutProvider');
  }

  return context;
}

export function useCheckoutDispatch() {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCheckoutDispatch must be used within a CheckoutProvider'
    );
  }

  return context;
}

export function useCheckoutPropUpdate() {
  const dispatch = useCheckoutDispatch();
  return <F extends keyof State>(value: State[F], field: F) => {
    dispatch({
      type: 'field',
      field,
      value,
    });
  };
}

export function useCheckoutStateUpdate() {
  const dispatch = useCheckoutDispatch();
  return (value: Partial<State>) => {
    dispatch({
      type: 'state',
      value,
    });
  };
}

export type DayTime = string; // (typeof DAY_TIMES)[number];

export interface Capacity {
  ids: number[];
  ageFrom: number;
  ageTo: number;
  capacity: number;
  taken: number;
}

export interface DayTimeSlot {
  dropoff: string;
  pickup: string;
  dependent: 'independent' | 'before' | 'after';
  capacities: Capacity[];
}

export interface DayTimeSlots {
  [dayTime: DayTime]: DayTimeSlot;
}

export interface DateSlots {
  [dateString: string]: DayTimeSlots;
}

export interface ChosenSlots {
  [dateString: string]: DayTime[];
}
