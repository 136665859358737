import { useEffect } from 'react';
import { Platform } from 'react-native';

const activeBlocksAmountRef = {
  current: 0,
};

export default function useBlockBodyScroll(isVisible: boolean) {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    if (isVisible) {
      activeBlocksAmountRef.current += 1;
      setTimeout(() => {
        document.documentElement.style.overflow = 'hidden';
      }, 0);
    }

    return () => {
      if (isVisible) activeBlocksAmountRef.current -= 1;
      if (activeBlocksAmountRef.current > 0) return;

      document.documentElement.style.overflow = 'unset';
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);
}
