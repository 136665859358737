
.skeleton-container div, .skeleton-container-inside > div {
	background-color: #e2e5e7;
	background-image:			
			linear-gradient(
				90deg, 
				rgba(255,255,255, 0), 
				rgba(255,255,255, 0.5),
				rgba(255,255,255, 0)
			);
	background-size: 100px 100%; 
	background-repeat: no-repeat;
	background-position: left -100px top 0;
	animation: shine 1s ease infinite;
}

@keyframes shine {
	to {
		background-position: right -100px top 0;
	}
}
