export default {
  light: {
    text: '#000000',
    textSecondary: '#7D746E',
    textLowContrast: '#898279',
    background: '#FFFFFF',
    backgroundSecondary: '#FFF5E7',
    backgroundTertiary: '#B3D3D6',
    accent: '#F8C1D9',
    accentSecondary: '#D0E5CC',
    accentTertiary: '#2E5394',
    cta: '#FEEB73',
    lines: '#E3D9CB',
    error: '#F46234',
    shadow: '#C3BBB0',
    warning: '#F4CC39',
  },
  dark: {
    text: '#000000',
    textSecondary: '#7D746E',
    textLowContrast: '#898279',
    background: '#FFFFFF',
    backgroundSecondary: '#FFF5E7',
    backgroundTertiary: '#B3D3D6',
    accent: '#F8C1D9',
    accentSecondary: '#D0E5CC',
    accentTertiary: '#2E5394',
    cta: '#FEEB73',
    lines: '#E3D9CB',
    error: '#F46234',
    shadow: '#C3BBB0',
    warning: '#F4CC39',
  },
} as const;
