import { useEffect } from 'react';
import { StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';

import ModalCloseButton from './ModalCloseButton';
import { TransparentView } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';

type HowItWorksModalProps = {
  isVisible: boolean;
  close: () => void;
};

export default function HowItWorksModal({
  isVisible,
  close,
}: HowItWorksModalProps) {
  const theme = useColorScheme();
  useEffect(() => {
    isVisible && (document.body.style.overflow = 'hidden');
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={close}
      useNativeDriver={true}
      style={{ margin: 20 }}
      hideModalContentWhileAnimating={true}
    >
      <TransparentView style={[styles.modalBody]}>
        <TransparentView style={styles.wrapper}>
          <ModalCloseButton
            absolute
            onPress={close}
            style={styles.close}
            iconStyle={{ tintColor: Colors[theme].background }}
          />
          <iframe
            src="https://fast.wistia.net/embed/iframe/vrahb9nxyz?videoFoam=true"
            title="How it Works Video"
            allow="autoplay; fullscreen"
            frameBorder="0"
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />
        </TransparentView>
      </TransparentView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalBody: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: '85%',
        }),
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
      },
    }),
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  wrapper: {
    width: '100%',
    position: 'relative',
    paddingBottom: '56%',
  },
  close: {
    top: -40,
    right: 10,
  },
});
