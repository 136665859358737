import {
  createNavigationContainerRef,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { NativeRootStackParamList } from '../native/types';
import { RootStackParamList } from '../types';

export const navigationRef = createNavigationContainerRef();

export function useNativeAppNavigation() {
  const navigation =
    useNavigation<StackNavigationProp<NativeRootStackParamList>>();
  return navigation;
}

export default function useAppNavigation() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  return navigation;
}

export const useCurrentRouteName = () => {
  const navigation = useAppNavigation();
  const state = navigation.getState();
  if (!state) {
    return '';
  }
  return state.routes[state.index]?.name;
};

type Nav = StackNavigationProp<RootStackParamList>;
type PushParams = Parameters<Nav['push']>;

export const navigateFromRef = (...params: PushParams) => {
  if (navigationRef.isReady()) {
    // @ts-expect-error
    return navigationRef.navigate(...params);
  }
};
