import { Platform } from 'react-native';

export function appendWeekItemAttr(el: Element) {
  (el as HTMLElement).dataset['week_item'] = '';
}
export function removeWeekItemAttr(el: Element) {
  delete (el as HTMLElement).dataset['week_item'];
}

export function appendWeekItemStartAttr(el: Element) {
  (el as HTMLElement).dataset['week_start'] = 'true';
}

export function appendWeekItemEndAttr(el: Element) {
  (el as HTMLElement).dataset['week_end'] = 'true';
}

export function appendDataDateAttr(el: HTMLElement, dateString: string) {
  el.dataset.date = dateString;
}

export function appendSelectedWeekAttr(el: Element) {
  (el as HTMLElement).dataset['week_item_selected'] = '';
}

export function appendUnavailableAttr(el: Element) {
  (el as HTMLElement).dataset['week_item_unavailable'] = '';
}

export function removeUnavailableAttr(el: Element) {
  delete (el as HTMLElement).dataset['week_item_unavailable'];
}

export function appendDisabledAttr(el: Element) {
  (el as HTMLElement).dataset['week_item_disabled'] = '';
}

export function removeDisabledAttr(el: Element) {
  delete (el as HTMLElement).dataset['week_item_disabled'];
}

export function clearAllSelectedWeekAttr() {
  Array.from(document.querySelectorAll('[data-week_item_selected]')).forEach(
    (el) => {
      delete (el as HTMLElement).dataset['week_item_selected'];
    }
  );
}

function setHoverForId(id: string, isHovered: boolean) {
  const elements = Array.from(
    document.querySelectorAll(`button[data-weekid="${id}"]`)
  );
  elements.forEach((el) => {
    if (isHovered) {
      (el as HTMLElement).dataset['week_item_hover'] = '';
    } else {
      delete (el as HTMLElement).dataset['week_item_hover'];
    }
  });
}

export function attachMouseEnterLeaveListeners(btn: Element, id: string) {
  (btn as HTMLElement).addEventListener('mouseenter', () => {
    setHoverForId(id, true);
  });
  (btn as HTMLElement).addEventListener('mouseleave', () => {
    setHoverForId(id, false);
  });
}

function getDateStringForMonth(month: Date, day: string) {
  const d = `${month.getFullYear()}-${(month.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${day.padStart(2, '0')}`;
  return d;
}

export function appendDateAttributes(startDate: Date) {
  if (Platform.OS === 'web') {
    const buttons = Array.from(
      document.querySelectorAll<HTMLElement>('button.react-calendar__tile')
    );
    if (buttons.length === 0) {
      return;
    }
    const firstDayOfMonthIndex = buttons.findIndex(
      (btn) => btn.textContent === '1'
    );

    if (firstDayOfMonthIndex < 0) {
      throw new Error('could not find first day of current month');
    }

    const firstDayOfNextMonthIndex = buttons.findLastIndex(
      (btn) => btn.textContent === '1'
    );

    const currentMonth = new Date(startDate);
    currentMonth.setDate(1);

    const previousMonth = new Date(currentMonth);
    previousMonth.setDate(0);

    const nextMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      1
    );

    buttons.forEach((btn, idx) => {
      let m = currentMonth;
      if (idx < firstDayOfMonthIndex) {
        m = previousMonth;
      }
      if (
        firstDayOfMonthIndex !== firstDayOfNextMonthIndex &&
        idx >= firstDayOfNextMonthIndex
      ) {
        m = nextMonth;
      }
      appendDataDateAttr(btn, getDateStringForMonth(m, btn.textContent!));
    });
  }
}
