import { ButtonState } from '../../contexts/threeparamsearch';
import { pluralize } from '../../utils/common';
import { formatRanges } from '../../utils/date';

export const useThreeParamSearchButtonText = (
  state: Pick<ButtonState, 'location' | 'rangeStart' | 'rangeEnd' | 'who'>
) => {
  const { location, rangeStart, rangeEnd, who } = state;
  const whereText = location.description || 'Where';
  const whenText =
    formatRanges({ rangeStart, rangeEnd }, { short: true }) || 'When';

  const activeKidsFilterLength = who.filter((w) => w.enabled).length;
  const whoText = activeKidsFilterLength
    ? `${activeKidsFilterLength} ${pluralize(activeKidsFilterLength, 'kid')}`
    : 'Who';
  return {
    whereText,
    whenText,
    whoText,
  };
};
