import { API_V1_URL } from './config';
import { getAuthedRequestHeaders, defaultAuthedRequest } from './headers';
import { parseChild } from '../utils/api';

const CHILDREN_V1_URL = `${API_V1_URL}children/`;

interface ChildBasicsPayload {
  id: number;
  firstName: string;
  lastName: string;
  nickname: string;
  // sex: string;
  birthdate: string;
}

export async function createChild(
  payload: Omit<ChildBasicsPayload, 'id'>,
  opts = { doNotFormatBirthdate: false }
) {
  const { firstName, lastName, birthdate, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      birthdate: opts.doNotFormatBirthdate
        ? birthdate
        : new Date(birthdate).toDateString(),
      ...rest,
    }),
  };
  const response = await fetch(`${CHILDREN_V1_URL}`, request);

  const json = await response.json();
  if (json.error) {
    throw new Error(json.error);
  }

  return parseChild(json);
}

export async function patchBasics(payload: ChildBasicsPayload) {
  const { id, firstName, lastName, birthdate, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      birthdate: new Date(`${birthdate}T00:00:00`).toDateString(),
      ...rest,
    }),
  };
  const response = await fetch(`${CHILDREN_V1_URL}${id}/basics`, request);
  return await response.json();
}

interface ChildDetailsPayload {
  id: number;
  potty: boolean;
  allergies: string;
  sleep: string;
  notes: string;
}

export async function patchDetails(payload: ChildDetailsPayload) {
  const { id, potty, ...rest } = payload;
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      potty_trained: potty,
      ...rest,
    }),
  };
  const response = await fetch(`${CHILDREN_V1_URL}${id}/details`, request);
  return await response.json();
}

interface Contact {
  id: number | undefined;
  firstName: string;
  lastName: string;
  telephone: string;
  relationship: string;
}

interface PickupsPayload {
  childId: number;
  pickups: Contact[];
}

function serializeContact(contact: Contact | Omit<Contact, 'relationship'>) {
  const { firstName, lastName } = contact;
  return {
    ...contact,
    first_name: firstName,
    last_name: lastName,
  };
}

export async function postPickups({ childId, pickups }: PickupsPayload) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      pickups: pickups.map(serializeContact),
    }),
  };
  const response = await fetch(
    `${CHILDREN_V1_URL}${childId}/update_approved_pickups`,
    request
  );
  return await response.json();
}

interface EmergencyContactPayload {
  childId: number;
  emergency: Contact[];
  physician: Omit<Contact, 'relationship'>;
}

export async function postEmergencyContacts({
  childId,
  emergency,
  physician,
}: EmergencyContactPayload) {
  const request = {
    ...defaultAuthedRequest,
    ...getAuthedRequestHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      emergency: emergency.map(serializeContact),
      physician: serializeContact(physician),
    }),
  };
  const response = await fetch(
    `${CHILDREN_V1_URL}${childId}/update_emergency_contacts`,
    request
  );
  return await response.json();
}
