import React from 'react';
import { Image, ImageStyle, StyleSheet } from 'react-native';

import { TextH2 } from './StyledText';
import { View } from './Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useMinWidth } from '../hooks/useResponsive';

export default function DecoratedTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useColorScheme();
  const isLargeDevice = useMinWidth(430);
  return (
    <View>
      <TextH2 style={styles.title}>{children}</TextH2>
      {isLargeDevice ? (
        <>
          <Image
            style={[
              styles.leftSparkles as ImageStyle,
              { tintColor: Colors[theme].accent },
            ]}
            source={require('../assets/images/sparkles.png')}
          />
          <Image
            style={[
              styles.rightSparkles as ImageStyle,
              { tintColor: Colors[theme].accent },
            ]}
            source={require('../assets/images/sparkles.png')}
          />
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    alignSelf: 'center',
  },
  leftSparkles: {
    position: 'absolute',
    width: 50,
    height: 50,
    left: 0,
    top: 0,
    transform: [
      {
        rotate: '124.89deg',
      },
    ],
  },
  rightSparkles: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: 0,
    top: 0,
    transform: [
      {
        rotate: '-55.11deg',
      },
    ],
  },
});
