import HeartImage from '../assets/images/heart-pink.svg';
import { PrimaryButton } from '../components/Button';
import { Footer } from '../components/Footer';
import { Container, InnerContainer } from '../components/Layout';
import { TextBody, TextH2 } from '../components/StyledText';
import CommonHeader from '../components/layouts/CommonHeader';
import useAppNavigation from '../hooks/useAppNavigation';

export default function NotFoundScreen() {
  const navigation = useAppNavigation();
  const goToHomepage = () => navigation.navigate('Explore');
  return (
    <>
      <CommonHeader />
      <Container>
        <InnerContainer
          style={{
            paddingVertical: 80,
            maxWidth: 420,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <HeartImage style={{ width: 160, height: 160, marginBottom: 20 }} />
          <TextH2 style={{ marginBottom: 10 }}>Uh oh...</TextH2>
          <TextBody style={{ marginBottom: 20 }}>
            We couldn’t find the page you were looking for. But you can always
            count on Bumo to find childcare.
          </TextBody>
          <PrimaryButton title="Find Care" onPress={goToHomepage} />
        </InnerContainer>
        <Footer />
      </Container>
    </>
  );
}
