import Checkbox from 'expo-checkbox';
import { useState } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import FeatureFlags from '../../constants/FeatureFlags';
import Layout from '../../constants/Layout';
import { StaticTexts } from '../../constants/StaticTexts';
import useColorScheme from '../../hooks/useColorScheme';
import { useForm } from '../../hooks/useForm';
import { useFormArray } from '../../hooks/useFormArray';
import BirthdateInput from '../BirthdateInput';
import { PrimaryButton, UnderlinedButton } from '../Button';
import { Icon } from '../Icon';
import { PhoneNumberInput } from '../PhoneInput';
import { TransparentRow } from '../Row';
import SelectInput from '../SelectInput';
import { TextBody, TextBodySmall } from '../StyledText';
import { FormInput } from '../StyledTextInput';
import { TransparentView } from '../Themed';

interface SearchLeadFormProps {
  onSubmit: (phone: string) => void;
}

export default function SearchLeadForm({ onSubmit }: SearchLeadFormProps) {
  const { state, dispatch } = useForm({ phone: '' });
  const [submitted, setSubmitted] = useState(false);

  if (FeatureFlags.LEAD_FORM_V2) {
    return (
      <TransparentView style={styles.container}>
        <SearchLeadFormContent />
      </TransparentView>
    );
  }

  return (
    <TransparentView style={styles.container} testID="v1">
      {submitted ? (
        <TextBody style={styles.description}>
          Thank you. We will text you when we have locations available in this
          city!
        </TextBody>
      ) : (
        <>
          <PhoneNumberInput
            testID="phone-input"
            style={styles.input}
            placeholder="Add your phone number and get notified"
            value={state.values.phone}
            error={state.errors.phone}
            onChangeText={(value) =>
              dispatch({ type: 'field', field: 'phone', value })
            }
            onBlur={() => dispatch({ type: 'validate', field: 'phone' })}
          />

          <PrimaryButton
            testID="submit-button"
            style={styles.button}
            title="Submit"
            disabled={!state.dirty || state.isInvalid}
            onPress={() => {
              onSubmit(state.values.phone);
              setSubmitted(true);
            }}
          />
        </>
      )}
    </TransparentView>
  );
}

type LeadFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  whenDoYouNeedCare: string;
  fixedCare: boolean;
  flexibleCare: boolean;
  weekendCare: boolean;
  eveningCare: boolean;
  childrensBirths: string[];
};

const SearchLeadFormContent = () => {
  const { state, dispatch } = useForm<LeadFormValues>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    whenDoYouNeedCare: '',
    childrensBirths: [] as string[],
  });

  const [kindsSelected, setKindsSelected] = useState({
    fixed: false,
    flexible: false,
    weekend: false,
    evening: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [childrensBirths, setChildrensBirths] = useState<string[]>([]);

  const updateKindSelection = (
    type: 'fixed' | 'flexible' | 'weekend' | 'evening',
    value: boolean
  ) => setKindsSelected((s) => ({ ...s, [type]: value }));

  return (
    <>
      <TextBodySmall>Your information</TextBodySmall>
      <TransparentRow style={{ gap: 20 }}>
        <FormInput placeholder="First Name" style={{ flex: 1 }} />
        <FormInput placeholder="Last Name" style={{ flex: 1 }} />
      </TransparentRow>
      <FormInput placeholder="Email" />
      <PhoneNumberInput placeholder="Phone Number" />
      <SelectInput
        label={'When do you need care?'}
        value={state.values.whenDoYouNeedCare}
        placeholder="Please make a selection"
        options={[
          'As soon as possible',
          'In a few weeks',
          'In a few months',
          'Not sure',
        ]}
        onChange={(value) =>
          dispatch({ type: 'field', field: 'whenDoYouNeedCare', value })
        }
      />
      <TextBodySmall>
        What kind of care do you need? (Check all that apply.)
      </TextBodySmall>
      <TransparentRow
        style={{
          justifyContent: 'flex-start',
          marginTop: 10,
          flexWrap: 'wrap',
          gap: 10,
          marginBottom: 20,
        }}
      >
        <CheckboxInput
          selected={kindsSelected.fixed}
          label={StaticTexts.searchLeadForm.kindOfCare.fixed}
          testID={'fixed-care'}
          onChange={(value) => updateKindSelection('fixed', value)}
        />
        <CheckboxInput
          selected={kindsSelected.flexible}
          label={StaticTexts.searchLeadForm.kindOfCare.flexible}
          testID={'flexible-care'}
          onChange={(value) => updateKindSelection('flexible', value)}
        />
        <CheckboxInput
          selected={kindsSelected.weekend}
          label={StaticTexts.searchLeadForm.kindOfCare.weekend}
          testID={'weekend-care'}
          onChange={(value) => updateKindSelection('weekend', value)}
        />
        <CheckboxInput
          selected={kindsSelected.evening}
          label={StaticTexts.searchLeadForm.kindOfCare.evening}
          testID={'evening-care'}
          onChange={(value) => updateKindSelection('evening', value)}
        />
      </TransparentRow>
      <ChildrensBirths />
    </>
  );
};

function CheckboxInput({
  testID,
  selected,
  onChange,
  label,
}: {
  testID: string;
  label: string;
  selected: boolean;
  onChange: (value: boolean) => void;
}) {
  const theme = useColorScheme();
  return (
    <TransparentRow style={{ justifyContent: 'flex-start' }}>
      <Checkbox
        style={{
          height: 24,
          width: 24,
          marginRight: 5,
        }}
        testID={testID}
        onValueChange={() => onChange(!selected)}
        color={Colors[theme].accent}
        value={selected}
      />
      <TextBodySmall>{label}</TextBodySmall>
    </TransparentRow>
  );
}

function ChildrensBirths() {
  const theme = useColorScheme();
  const { state, dispatch } = useFormArray<{
    id: undefined;
    birthdate: string;
  }>([{ id: undefined, birthdate: '' }]);

  return (
    <>
      <TextBodySmall
        style={
          state.values.length === 1
            ? {
                marginBottom: 15,
              }
            : {}
        }
      >
        Child(ren)'s Dates of Birth
      </TextBodySmall>
      {state.values.map((child, index) => (
        <>
          {state.values.length > 1 ? (
            <Pressable
              style={{ alignSelf: 'flex-end' }}
              onPress={() => dispatch({ type: 'remove', index })}
              testID={`remove-child-${index}`}
            >
              <Icon
                name="close"
                color={Colors[theme].text}
                style={{
                  width: 15,
                  height: 15,
                  ...Platform.select({
                    native: {
                      width: 20,
                      height: 20,
                    },
                  }),
                }}
              />
            </Pressable>
          ) : null}
          <BirthdateInput
            value={child.birthdate}
            placeholder=""
            onChange={(value) =>
              dispatch({
                type: 'entryField',
                field: 'birthdate',
                value,
                index,
              })
            }
            onCalendarChange={(value) =>
              dispatch({
                type: 'entryField',
                field: 'birthdate',
                value,
                index,
              })
            }
          />
        </>
      ))}

      <UnderlinedButton
        style={{ alignSelf: 'flex-start' }}
        testID="add-child-button"
        title="+ Add a child"
        onPress={() => dispatch({ type: 'add' })}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          width: 360,
        }),
        ...(Layout.isMobileDevice && {
          width: '100%',
        }),
      },
    }),
  },
  description: {
    textAlign: 'center',
  },
  input: {
    width: '100%',
    marginTop: 12,
  },
  button: {
    marginTop: 10,
    width: '100%',
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          height: 60,
        }),
        ...(Layout.isMobileDevice && {
          height: 50,
        }),
      },
    }),
  },
});
