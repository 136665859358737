import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import Layout from '../../constants/Layout';
import ChildrenProps from '../../types/ChildrenProps';
import { TransparentRow } from '../Row';
import { TextH4 } from '../StyledText';
import { StyleProps, TransparentView } from '../Themed';

type SettingSectionProps = ChildrenProps & {
  title: string;
  headerStyle?: StyleProps['style'];
  actionButton?: React.ReactNode;
} & StyleProps;

export default function SettingSection({
  title,
  children,
  style,
  headerStyle,
  actionButton,
}: SettingSectionProps) {
  return (
    <TransparentView style={[styles.container, style]}>
      <TransparentRow
        style={{ justifyContent: 'space-between', paddingRight: 20 }}
      >
        <TextH4 style={[styles.title, headerStyle]}>{title}</TextH4>
        {actionButton}
      </TransparentRow>
      <TransparentView>{children}</TransparentView>
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        ...(Layout.isLargeDevice && {
          marginTop: 30,
        }),
        ...(Layout.isMobileDevice && {
          marginTop: 20,
        }),
      },
    }),
  },
  title: {
    ...Platform.select({
      web: {
        marginBottom: 10,
        ...(Layout.isMobileDevice && {
          marginHorizontal: 20,
        }),
      },
    }),
  },
});
