import React from 'react';
import { DimensionValue } from 'react-native';

import BumoCarousel from '../components/BumoCarousel';
import { PrimaryButton } from '../components/Button';
import { Footer } from '../components/Footer';
import { Image } from '../components/Image';
import { Container, InnerContainer } from '../components/Layout';
import { TransparentRow } from '../components/Row';
import BumoCreditHeaderTextSection, {
  BumoCreditHeaderImage,
} from '../components/bumo_credit/BumoCreditHeaderTextSection';
import FSAInfo from '../components/bumo_credit/FSAInfo';
import Packages from '../components/bumo_credit/Packages';
import Recommendation from '../components/bumo_credit/Recommendation';
import { HeroContent } from '../components/explore/HeroWithImage';
import {
  HowItWorksContainer,
  HIWBlockItem,
} from '../components/explore/HowItWorks';
import CommonHeader from '../components/layouts/CommonHeader';
import Colors from '../constants/Colors';
import useAppNavigation from '../hooks/useAppNavigation';
import useColorScheme from '../hooks/useColorScheme';
import { useMaxWidth } from '../hooks/useResponsive';
import { openLinkInTheSameTab } from '../utils/common';

const FSAEasy = React.lazy(() => import('../assets/fsa/fsa-easy.svg'));
const FSAPayments = React.lazy(() => import('../assets/fsa/fsa-payments.svg'));
const FSAValue = React.lazy(() => import('../assets/fsa/fsa-value.svg'));

export default function BumoCreditLanding() {
  const { navigate } = useAppNavigation();
  const theme = useColorScheme();

  const isMobileDevice = useMaxWidth(920);
  const isSmallDevice = useMaxWidth(768);

  return (
    <>
      <CommonHeader />
      <Container>
        <HeroContent.Wrapper>
          <BumoCreditHeaderTextSection isMobileDevice={isMobileDevice}>
            <PrimaryButton
              title="Get BumoCredit"
              style={{ alignSelf: 'flex-start', marginTop: 35 }}
              onPress={() => navigate('BumoCreditPackagesList')}
            />
          </BumoCreditHeaderTextSection>
          {isSmallDevice ? null : <BumoCreditHeaderImage />}
        </HeroContent.Wrapper>
        {isSmallDevice ? (
          <BumoCreditHeaderImage
            style={
              isSmallDevice
                ? {
                    width: '100%',
                    height:
                      'min(calc(100vw * 375/272), 400px)' as DimensionValue,
                  }
                : undefined
            }
          />
        ) : null}
        <HowItWorks />
        <Packages
          ItemsWrapper={PackagesCarousel}
          source="landing"
          onLinkFetched={openLinkInTheSameTab}
        >
          {isMobileDevice ? null : (
            <Image
              source={require('../assets/images/sparkles.png')}
              style={{
                width: 150,
                height: 149,
                position: 'absolute',
                left: -100,
                bottom: 0,
                transform: [{ rotateY: '180deg' }],
              }}
            />
          )}
          {isMobileDevice ? null : (
            <Image
              source={require('../assets/images/sparkles.png')}
              style={{
                width: 150,
                height: 149,
                position: 'absolute',
                right: -50,
                bottom: 0,
              }}
            />
          )}
        </Packages>

        <InnerContainer style={{ paddingVertical: 50 }}>
          <FSAInfo icons={{ FSAEasy, FSAPayments, FSAValue }} />
        </InnerContainer>

        <InnerContainer>
          <TransparentRow
            style={{ flexDirection: isMobileDevice ? 'column-reverse' : 'row' }}
          >
            <Recommendation
              imageStyle={{
                width: 'min(497.132px, 100%)' as DimensionValue,
                height: 'min(calc(100vw * 497 / 520), 520px)' as DimensionValue,
                marginBottom: 20,
              }}
              actionSlot={
                <PrimaryButton
                  title="Get BumoCredit"
                  style={{
                    marginTop: 36,
                    paddingVertical: 20,
                    backgroundColor: Colors[theme].accent,
                    alignSelf: isMobileDevice ? 'center' : 'flex-start',
                  }}
                  onPress={() => navigate('BumoCreditPackagesList')}
                />
              }
            />
          </TransparentRow>
        </InnerContainer>
        <Footer />
      </Container>
    </>
  );
}

export function BumoCreditPackagesListScreen() {
  const theme = useColorScheme();

  const isMobileDevice = useMaxWidth(920);
  return (
    <>
      <CommonHeader />
      <Container>
        <Packages fullWidth source="list" onLinkFetched={openLinkInTheSameTab}>
          {isMobileDevice ? null : (
            <Image
              source={require('../assets/images/star-green.png')}
              style={{
                width: 130,
                height: 130,
                position: 'absolute',
                top: 0,
                right: 25,
              }}
            />
          )}

          {isMobileDevice ? null : (
            <Image
              source={require('../assets/images/spring2.png')}
              style={{
                width: 240,
                height: 275,
                position: 'absolute',
                tintColor: Colors[theme].accent,
                bottom: 20,
                left: 25,
              }}
            />
          )}
        </Packages>
        <Footer />
      </Container>
    </>
  );
}

function HowItWorks() {
  const theme = useColorScheme();
  return (
    <HowItWorksContainer
      style={{
        backgroundColor: Colors[theme].accentSecondary,
      }}
    >
      <HIWBlockItem
        index="1"
        title="Buy a BumoCredit Package"
        content="Purchase a BumoCredit package that works best for your family."
      />
      <HIWBlockItem
        index="2"
        title="BumoCredit goes into your account"
        content="The BumoCredit you purchased, automatically gets loaded into your Bumo account. "
      />
      <HIWBlockItem
        index="3"
        title="Pay for child care with your BumoCredit funds"
        content="When you book child care, use your BumoCredit to complete your purchase until your funds run out. "
      />
    </HowItWorksContainer>
  );
}

function PackagesCarousel({ children }: { children: React.ReactNode }) {
  const isMobileDevice = useMaxWidth(700);
  const isMediumDevice = useMaxWidth(1000);
  return (
    <BumoCarousel
      renderedElements={isMobileDevice ? 1 : isMediumDevice ? 2 : 3}
      itemContainerStyle={{
        height: 440,
        paddingHorizontal: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </BumoCarousel>
  );
}
