import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import ChildrenProps from '../../types/ChildrenProps';
import Row from '../Row';
import { TextBodySmall } from '../StyledText';
import { StyleProps, View } from '../Themed';

export default function ProfileSectionBox({
  children,
  style,
}: ChildrenProps & StyleProps) {
  const theme = useColorScheme();
  return (
    <View style={[styles.section, { borderColor: Colors[theme].lines }, style]}>
      {children}
    </View>
  );
}

export function EntryRow({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const theme = useColorScheme();
  return (
    <Row style={styles.entryRow}>
      <View style={styles.titles}>
        <TextBodySmall
          style={[styles.text, { color: Colors[theme].textSecondary }]}
        >
          {title}
        </TextBodySmall>
      </View>
      <View style={{ flex: 1 }}>
        {typeof children === 'string' ? (
          <TextBodySmall style={{ paddingTop: 5 }}>{children}</TextBodySmall>
        ) : (
          children
        )}
      </View>
    </Row>
  );
}

export function ProfileSingleBox({
  children,
  style,
}: ChildrenProps & StyleProps) {
  const theme = useColorScheme();
  return (
    <Row style={[styles.single, { borderColor: Colors[theme].lines }, style]}>
      {children}
    </Row>
  );
}

const styles = StyleSheet.create({
  section: {
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderWidth: 1,
  },
  single: {
    paddingHorizontal: 20,
    height: 60,
    borderWidth: 1,
    justifyContent: 'space-between',
  },

  entryRow: { width: '100%', alignItems: 'flex-start', marginBottom: 0 },
  titles: {
    width: 120,
  },
  text: {
    lineHeight: 30,
  },
});
