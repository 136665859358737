import { StyleSheet } from 'react-native';

import RatingInput from './RatingInput';
import { FeatureRatings } from '../../api/reviews';
import { TransparentRow } from '../Row';
import { TextBodySmall } from '../StyledText';
import { TransparentView } from '../Themed';

const FeatureTitle = ({ title }: { title: string }) => (
  <TextBodySmall>{title}</TextBodySmall>
);

export default function ReviewFeaturesRatings({
  isMobileDevice,
  outdoor,
  featuresRatings,
  setRatings,
}: {
  isMobileDevice: boolean;
  outdoor: boolean;
  featuresRatings: FeatureRatings;
  setRatings: (v: Partial<FeatureRatings>) => void;
}) {
  return (
    <TransparentView>
      <TransparentRow
        style={[
          styles.featureInput,
          isMobileDevice && styles.featureInputMobile,
        ]}
      >
        <FeatureTitle title="Cleanliness" />
        <RatingInput
          rate={featuresRatings.cleanliness}
          onChange={(cleanliness) => setRatings({ cleanliness })}
        />
      </TransparentRow>
      <TransparentRow
        style={[
          styles.featureInput,
          isMobileDevice && styles.featureInputMobile,
        ]}
      >
        <FeatureTitle title="Teachers & Caregivers" />
        <RatingInput
          rate={featuresRatings.caregivers}
          onChange={(caregivers) => setRatings({ caregivers })}
        />
      </TransparentRow>
      <TransparentRow
        style={[
          styles.featureInput,
          isMobileDevice && styles.featureInputMobile,
        ]}
      >
        <FeatureTitle title="Curriculum & Activities" />
        <RatingInput
          rate={featuresRatings.curriculum}
          onChange={(curriculum) => setRatings({ curriculum })}
        />
      </TransparentRow>
      <TransparentRow
        style={[
          styles.featureInput,
          isMobileDevice && styles.featureInputMobile,
        ]}
      >
        <FeatureTitle title="Professionalism" />
        <RatingInput
          rate={featuresRatings.professionalism}
          onChange={(professionalism) => setRatings({ professionalism })}
        />
      </TransparentRow>
      <TransparentRow
        style={[
          styles.featureInput,
          isMobileDevice && styles.featureInputMobile,
        ]}
      >
        <FeatureTitle title="Indoor Space" />
        <RatingInput
          rate={featuresRatings.indoor}
          onChange={(indoor) => setRatings({ indoor })}
        />
      </TransparentRow>
      {outdoor ? (
        <TransparentRow
          style={[
            styles.featureInput,
            isMobileDevice && styles.featureInputMobile,
          ]}
        >
          <FeatureTitle title="Outdoor Space" />
          <RatingInput
            rate={featuresRatings.outdoor}
            onChange={(outdoor) => setRatings({ outdoor })}
          />
        </TransparentRow>
      ) : null}
    </TransparentView>
  );
}

const styles = StyleSheet.create({
  featureInput: { justifyContent: 'space-between', marginBottom: 12 },
  featureInputMobile: { justifyContent: 'space-between', marginVertical: 0 },
});
