import { Platform } from 'react-native';

import { CLIENT_ID, API_V1_URL } from './config';
import { getDeviceHeader } from './deviceInfo';
import { Parent, parseParent } from '../utils/api';

interface LogInPayload {
  email: string;
  password: string;
}

interface TokenPayload {
  token: string;
}

export type LogInSuccessful = {
  ok: true;
  refreshToken: string;
  accessToken: string;
  parent: Parent;
};

type LogInFailed = {
  ok: false;
  code: 'WRONG_CREDENTIALS' | 'UNKNOWN_ERROR';
};

export async function logIn(
  payload: LogInPayload
): Promise<LogInSuccessful | LogInFailed> {
  const requestInfo: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getDeviceHeader(),
    },
    body: JSON.stringify({
      client_id: CLIENT_ID,
      grant_type: 'password',
      ...payload,
    }),
  };
  try {
    const response = await fetch(`${API_V1_URL}auth/token`, requestInfo);

    if (response.status === 200) {
      const { parent, refresh_token, access_token } = await response.json();
      // Platform.OS === 'web' && localStorage?.setItem('token', data.refresh_token);
      // dispatch({ type: 'login_success', value: { parent: parseParent(parent), ...data } });

      return {
        ok: true,
        refreshToken: refresh_token,
        accessToken: access_token,
        parent: parseParent(parent),
      };
    } else if (response.status === 400) {
      return {
        ok: false,
        code: 'WRONG_CREDENTIALS',
      };
      // dispatch({ type: 'login_error', value: 'Email or password are incorrect' });
    }
    return {
      ok: false,
      code: 'UNKNOWN_ERROR',
    };
  } catch (e) {
    console.error(e);
    return {
      ok: false,
      code: 'UNKNOWN_ERROR',
    };
    // dispatch({ type: 'login_error', value: 'Please try again' });
  }
}

export async function logOut(payload: TokenPayload) {
  const requestInfo = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getDeviceHeader(),
    },
    body: JSON.stringify({
      client_id: CLIENT_ID,
      token: payload.token,
    }),
  };
  const response = await fetch(`${API_V1_URL}auth/revoke`, requestInfo);
  Platform.OS === 'web' && localStorage?.removeItem('token');
  return await response.json();
}

export async function refreshToken(payload: TokenPayload) {
  const requestInfo = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getDeviceHeader(),
    },
    body: JSON.stringify({
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: payload.token,
    }),
  };

  const response = await fetch(`${API_V1_URL}auth/token`, requestInfo);
  const responseBody = await response.json();
  return responseBody;
}
